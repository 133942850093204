import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Button, Form, message, Modal, Select } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'

import { showErrors } from '../../helpers'
import { LoadingBlock } from '../common'
import { CONTENT_LANGUAGE_OPTIONS } from '../../constants/languages'

const { Option } = Select

const trOpt = { scope: 'modals.updateLanguagesModal' }

const UpdateLanguagesModal = React.forwardRef(({
  mutation, addTitleKey, removeTitleKey, refetchQueries = [], afterClose: afterCloseProp = () => {}
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('add')
  const [ids, setIds] = useState([])
  const [locales, setLocales] = useState([])
  const [localesError, setLocalesError] = useState(null)

  useImperativeHandle(ref, () => ({
    open: (ids, action) => {
      setIds(ids)
      setAction(action)
      setLocales([])
      setLocalesError(null)
      setVisible(true)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = useCallback(() => {
    setVisible(false)
    setIds([])
    setAction('add')
    setLocales([])
    setLocalesError(null)
    afterCloseProp()
  }, [afterCloseProp])

  const onLocalesChange = value => {
    setLocales(value)
    setLocalesError(null)
  }

  const [updateLangauges] = useMutation(mutation)

  const handleSubmit = useCallback(async e => {
    e.preventDefault()
    // Set error state if no languages have been picked
    if (locales.length === 0) {
      const localesErrorMsg = I18n.t('common.fields.languagesRequiredError')
      setLocalesError(localesErrorMsg)
      return
    }

    setLoading(true)
    try {
      await updateLangauges({
        variables: { ids, action, locales },
        refetchQueries
      })
      message.success(I18n.t(`${action}SuccessMessage`, { count: locales.length, ...trOpt }))
      closeModal()
    } catch (e) {
      showErrors(e, I18n.t(`${action}ErrorMessage`, { count: locales.length, ...trOpt }))
    }
    setLoading(false)
  }, [updateLangauges, refetchQueries, closeModal, ids, action, locales])

  return (
    <Modal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      title={I18n.t(action === 'remove' ? removeTitleKey : addTitleKey, { count: ids.length })}
      afterClose={afterClose}
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      <Form onSubmit={handleSubmit}>
        <Form.Item
          label={I18n.t('common.languagesParenPlural')}
          validateStatus={localesError ? 'error' : undefined}
          help={localesError}
        >
          <Select
            value={locales}
            onChange={onLocalesChange}
            mode='multiple'
            placeholder={I18n.t('common.fields.languagesPlaceHolder')}
            optionFilterProp='label'
          >
            {
              CONTENT_LANGUAGE_OPTIONS
                .sort((a, b) => a.label.localeCompare(b.label))
                .map(({ value, label }) => <Option key={value} value={value} label={label}>{label}</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {I18n.t(`${action}Button`, trOpt)}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
})

export default UpdateLanguagesModal
