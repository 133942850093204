import React, { useState, useMemo } from 'react'
import { Card, Button } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { GET_USERS } from '../../components/Queries/Users'
import UsersTable from '../../components/Users/UsersTable'
import { connect } from '../../hocs'
import { getSessionAndSettings } from '../../state/selectors'
import AddUserModal from '../../components/Modals/AddUserModal'
import { LoadingBlock, ErrorAlerts, ListHeader, ContentWrap } from '../../components/common'
import { COMMS_TYPE_SHORT_LABELS, ALL_ROLE_LABELS } from '../../constants/users'
import IntercomHeader from '../../components/IntercomHeader'

const Team = (props) => {
  const { session = {} } = props
  const { userId: sessionUserId, isUsecure: sessionUserIsUsecure = false, planValid } = session || {}
  const [showAddUserModal, updateShowAddUserModal] = useState(false)
  const [activeUserId, updateActiveUserId] = useState(null)
  const openAddUserModal = () => updateShowAddUserModal(true)
  const afterAddUserClose = () => updateActiveUserId(null)
  const onEditClick = id => {
    updateActiveUserId(id)
    updateShowAddUserModal(true)
  }

  const { loading, error, refetch, data } = useQuery(GET_USERS)

  const users = useMemo(() =>
    (_get(data, 'users') || []).map((user) => {
      let { role } = user
      // Downgrade usecure-admin to admin visually to match back end behaviour when this user logs in
      if (role === 'usecure-admin' && !sessionUserIsUsecure) {
        role = 'admin'
      }
      return {
        key: user.id,
        name: user.name,
        email: user.email,
        commsType: COMMS_TYPE_SHORT_LABELS[user.commsType] || user.commsType,
        role,
        roleStr: ALL_ROLE_LABELS[role] || '',
        currentUser: sessionUserId === user.id,
        is2FAEnabled: user.is2FAEnabled
      }
    }),
  [data, sessionUserId, sessionUserIsUsecure])

  return (
    <ContentWrap>
      <AddUserModal id={activeUserId} setVisible={updateShowAddUserModal} visible={showAddUserModal} afterClose={afterAddUserClose} />
      <Card>
        <IntercomHeader Size='h1' id='manage-admin-users-header'>{I18n.t('users.title')}</IntercomHeader>
        <LoadingBlock fullScreen loading={loading} />
        {error && <ErrorAlerts {...{ error }} defaultError={I18n.t('users.error')} />}
        {!error && !loading && (
          <>
            <ListHeader align='right'>
              <Button disabled={!planValid} type='primary' icon='user-add' onClick={openAddUserModal}>{I18n.t('users.addUser')}</Button>
            </ListHeader>
            <UsersTable users={users} partner={session.partner} role={session.role} onEdit={onEditClick} showActions={(session.role === 'admin' || session.role === 'usecure-admin') && planValid} refetchUsers={refetch} />
          </>
        )}
      </Card>
    </ContentWrap>
  )
}

export default compose(
  connect(
    getSessionAndSettings
  )
)(Team)
