import React, { useCallback } from 'react'
import { Card, Checkbox, Tag, TreeSelect, Tooltip } from 'antd'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'

import { withRenderDelay } from '../../hocs'

export const SyncSetupGroupCard = styled.div`
  .ant-card {
    height: 100%;
  }

  .sync-setup-group__header {
    display: flex;
    justify-content: space-between;
  }
  .sync-setup-group__title {
    hyphens: auto;
    padding-right: 3px;
  }
  .sync-setup-group__group-memb-container {
    margin-top: 5px;
  }
`

const GroupTypeTag = withTheme(({ theme, type, groupTypes }) => (
  <div>
    <Tag color={theme.primary}>
      {groupTypes[type] || type}
    </Tag>
  </div>
))

const SyncSetupGroup = ({
  group,
  onChange = () => {},
  usecureGroupTreeOptions = [],
  trOpt = { scope: 'syncSetup.syncSetupGroup' },
  includeGroups = false, includeGroupMembMapping = false, includeGroupMembWhitelist = false,
  hiddenClassName, groupTypes
}) => {
  const { id, name, type, uniqueName, inGroups = false, inGroupMemb = false, groupMembMap = [] } = group
  const inGroupMembChecked = inGroupMemb || inGroups
  const inGroupMembDisabled = inGroups
  const groupMembMapDisabled = inGroupMembDisabled || !inGroupMemb

  const onGroupChange = useCallback((prop, value) => onChange(id, prop, value), [id, onChange])
  const onCheckboxChange = useCallback((prop, e) => onGroupChange(prop, e.target.checked), [onGroupChange])
  const onInGroupsChange = useCallback(e => onCheckboxChange('inGroups', e), [onCheckboxChange])
  const onInGroupMembChange = useCallback(e => onCheckboxChange('inGroupMemb', e), [onCheckboxChange])
  const onGroupMembMapChange = useCallback((value = []) => onGroupChange('groupMembMap', value), [onGroupChange])

  return (
    <SyncSetupGroupCard className={hiddenClassName}>
      <Card size='small'>
        <div className='sync-setup-group__header'>
          <h4 className='sync-setup-group__title'>
            <Tooltip title={uniqueName}>{name}</Tooltip>
          </h4>
          <GroupTypeTag {...{ type, groupTypes }} />
        </div>
        {
          includeGroups
            ? (
              <div>
                <Checkbox onChange={onInGroupsChange} checked={inGroups}>{I18n.t('includeThisGroup', trOpt)}</Checkbox>
              </div>
            ) : null
        }
        {
          includeGroupMembWhitelist
            ? (
              <div>
                <Checkbox onChange={onInGroupMembChange} checked={inGroupMembChecked} disabled={inGroupMembDisabled}>{I18n.t('includeThisGroupsUsers', trOpt)}</Checkbox>
              </div>
            ) : null
        }
        {
          includeGroupMembMapping
            ? (
              <div className='sync-setup-group__group-memb-container'>
                <label>{I18n.t('sync.common.mapToExistingGroups')}</label>
                <div>
                  <TreeSelect
                    multiple
                    treeData={usecureGroupTreeOptions}
                    showSearch
                    treeNodeFilterProp='title'
                    value={groupMembMapDisabled ? [] : groupMembMap}
                    treeDefaultExpandAll
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    onChange={onGroupMembMapChange}
                    allowClear
                    disabled={groupMembMapDisabled}
                  />
                </div>
              </div>
            ) : null
        }
      </Card>
    </SyncSetupGroupCard>
  )
}

export default withRenderDelay(SyncSetupGroup)
