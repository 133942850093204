/* global localStorage */
import React, { Component, useCallback, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, message, Modal } from 'antd'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import {
  CHECK_SIMULATION_EMAIL_TEMPLATE_ACCESS,
  CHECK_SIMULATION_EMAIL_TEMPLATE_LOCALES,
  CREATE_SIMULATION_EMAIL_TEMPLATE,
  GET_SIMULATION_DOMAINS,
  GET_SIMULATION_EMAIL_TEMPLATE,
  GET_SIMULATION_EMAIL_TEMPLATE_LAYOUTS,
  UPDATE_SIMULATION_EMAIL_TEMPLATE
} from '../Queries/uPhish'
import { GET_COMPANIES } from '../Queries/Companies'
import { MutationFormFooter } from '../../components/MutationForm'
import MutationForm from '../MutationForm/MutationForm'
import { ErrorAlerts, LoadingBlock } from '../common'
import { ContentLinkAlert } from './ValidationAlert'
import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import { invalidateEmailTemplateQueryCache, modalConfirmAsync, showErrors, validateDomain, validateEmail } from '../../helpers'
import {
  buildCompanyOptions,
  getCompanyAccessExtra,
  getCompanyAccessLabel,
  getCompanyAccessPlaceholder,
  getGlobalExtra,
  getGlobalLabel,
  managedByUsecure
} from '../../helpers/company'
import { unLayerEmailFieldConfig, CATEGORY_OPTIONS } from '../../constants/uPhish'
import { CONTENT_LANGUAGE_NAMES_BY_CODE, CONTENT_LANGUAGE_OPTIONS } from '../../constants/languages'
import { isEmailTemplateContentValid } from '../../helpers/uPhish'

const trOpt = { scope: 'uPhish.emailTemplateForm' }
const commonTrOpt = { scope: 'uPhish.common' }
const formItemStyle = { maxWidth: 800 }

const defaultState = {
  name: null,
  category: null,
  tile: null,
  subject: null,
  senderName: null,
  senderPrefix: null,
  senderDomain: null,
  customSender: false,
  senderDomains: [],
  content: null,
  legacy: false,
  companyId: null,
  global: false,
  companies: []
}

class EmailTemplateForm extends Component {
  constructor (props) {
    super(props)

    this.form = React.createRef()

    this.load = this.load.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFailure = this.handleFailure.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.getGlobalLabel = this.getGlobalLabel.bind(this)
    this.getGlobalExtra = this.getGlobalExtra.bind(this)
    this.isGlobalAllowed = this.isGlobalAllowed.bind(this)
    this.getCompanyAccessLabel = this.getCompanyAccessLabel.bind(this)
    this.getCompanyAccessPlaceholder = this.getCompanyAccessPlaceholder.bind(this)
    this.getCompanyAccessExtra = this.getCompanyAccessExtra.bind(this)
    this.isCompanyAccessAllowed = this.isCompanyAccessAllowed.bind(this)
    this.reset = this.reset.bind(this)
    this.validateContent = this.validateContent.bind(this)
    this.validateSenderField = this.validateSenderField.bind(this)
    this.mutateValues = this.mutateValues.bind(this)

    this.state = {
      ...defaultState,
      contentValid: false,
      failed: false,
      showLegacyEditor: _get(this.props, 'template.legacy') === true,
      companyOptions: [],
      companyMap: {}
    }
  }

  get id () {
    return this.props.id
  }

  get isCreate () {
    return _isNil(this.id)
  }

  get isClone () {
    return this.props.clone === true && !_isNil(this.id)
  }

  get isUpdate () {
    return this.props.clone !== true && !_isNil(this.id)
  }

  get useStorage () {
    return this.isCreate || this.isClone
  }

  get storageId () {
    const { userId, companyId, id, embedded } = this.props
    let prefix = 'create'
    if (this.isUpdate) {
      prefix = 'update'
    } else if (this.isClone) {
      prefix = 'clone'
    }

    // Local storage isn't used when updating but there's a different storage id just in case this changes
    return `${prefix}SimEmailTemplate|${embedded ? '|embedded' : ''}${this.isCreate ? '' : `|${id}`}|${companyId}|${userId}`
  }

  async handleChange (name, value) {
    if (
      this.useStorage && (name === 'content' && !this.state.showLegacyEditor ? value && value.initialised : true)
    ) {
      await this.updateState({ [name]: value })
    }

    if (name === 'content') {
      this.updateContentValid(value)
    } else if (name === 'legacy') {
      this.changeEditorMode(value)
    }
  }

  async changeEditorMode (legacy) {
    await this.form.current.setField('content', null)
    this.setState({ showLegacyEditor: legacy })
  }

  updateContentValid (rawContent) {
    this.setState({ contentValid: isEmailTemplateContentValid(rawContent, { legacy: this.state.showLegacyEditor }) })
  }

  validateContent (field, value, errors) {
    if (!this.state.contentValid) {
      errors.push(I18n.t('phishingLinkMissingError', commonTrOpt))
    }
  }

  validateSender ({ customSender: custom, senderEmail: email, senderDomain: domain, senderPrefix: prefix } = {}) {
    // Sender is not mandatory and should only validated when the value should be a complete email address
    let senderEmail
    if (custom && _isString(email) && !_isEmpty(email)) {
      senderEmail = (email || '').trim()
    } else if (!custom && _isString(prefix) && !_isEmpty(prefix) && _isString(domain) && !_isEmpty(domain)) {
      senderEmail = `${(prefix || '').trim()}@${(domain || '').trim()}`.trim()
    }
    return senderEmail ? validateEmail(senderEmail) : true
  }

  validateSenderField (field, value, errors, opt, values) {
    if (!this.validateSender(values)) {
      if (values.customSender) {
        errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
      } else {
        errors.push(I18n.t('uPhish.common.errors.invalidSenderPrefix'))
      }
    }
  }

  handleSuccess (result) {
    message.success(I18n.t('savedMessage', trOpt))
    this.props.onSuccess(result)
    this.resetStorage()
  }

  async checkAccess (values) {
    if (!this.allowAccessControl) return

    const { companies, companyId, global } = values
    const result = await this.props.client.query({
      query: CHECK_SIMULATION_EMAIL_TEMPLATE_ACCESS,
      variables: {
        id: this.id,
        companyId: companyId === 'usecure' ? null : companyId,
        companies,
        global
      },
      fetchPolicy: 'no-cache'
    })

    const hasConflict = _get(result, 'data.checkEmailTemplateAccess.conflict') === true
    const conflictLandingPages = _get(result, 'data.checkEmailTemplateAccess.landingPages', [])
    if (hasConflict) {
      const accessType = this.getAccessType(values)
      const continueSubmit = await modalConfirmAsync({
        title: I18n.t('common.accessControl.tabTitle'),
        content: (
          <div>
            <p className='base-font'>{I18n.t(accessType === 'distributor' ? 'accessControlIntroDistributor' : 'accessControlIntro', trOpt)}</p>
            <p className='base-font'>{I18n.t('affectedLandingPagesList', commonTrOpt)}</p>
            <ul>
              {conflictLandingPages.map(l => <li key={l.id}>{l.name}</li>)}
            </ul>
            <p className='base-font'>{I18n.t('accessControlInfo', trOpt)}</p>
          </div>
        ),
        width: 600
      })
      if (!continueSubmit) {
        throw new Error('cancelSubmit')
      }
    }
  }

  async checkLocales (values) {
    const { companyId, locales } = values

    const localesResult = await this.props.client.query({
      query: CHECK_SIMULATION_EMAIL_TEMPLATE_LOCALES,
      variables: {
        id: this.id,
        companyId: companyId === 'usecure' ? null : companyId,
        locales
      },
      fetchPolicy: 'no-cache'
    })
    const hasLocalesMismatch = _get(localesResult, 'data.checkEmailTemplateLocales.mismatch') === true
    const mismatchedLandingPages = _get(localesResult, 'data.checkEmailTemplateLocales.landingPages', [])

    if (hasLocalesMismatch) {
      const continueSubmit = await modalConfirmAsync({
        title: I18n.t('localesMismatchTitle', commonTrOpt),
        content: (
          <div>
            <p className='base-font'>{I18n.t('localesMismatchIntro', trOpt)}</p>
            <p className='base-font'>{I18n.t('affectedLandingPagesList', commonTrOpt)}</p>
            <ul>
              {mismatchedLandingPages.map(({ id, name, locales }) => (
                <li key={id}>
                  <div>{name}</div>
                  <ul>
                    {
                      locales?.length > 0
                        ? locales.map(locale => <li key={locale}>{CONTENT_LANGUAGE_NAMES_BY_CODE[locale]}</li>)
                        : <li>{I18n.t('common.noLanguagesSet')}</li>
                    }
                  </ul>
                </li>
              ))}
            </ul>
            <p className='base-font'>{I18n.t('localesMismatchInfo', trOpt)}</p>
          </div>
        ),
        width: 600
      })
      if (!continueSubmit) {
        throw new Error('cancelSubmit')
      }
    }
  }

  async handleSubmit (values) {
    if (!this.isUpdate) return

    await this.checkAccess(values)
    await this.checkLocales(values)
  }

  handleFailure (e) {
    if (_get(e, 'message') === 'cancelSubmit') {
      return
    }

    const { onFailure, failureMessage } = this.props
    if (_isFunction(onFailure)) {
      onFailure(e)
    } else {
      showErrors(e, _isString(onFailure) ? onFailure : failureMessage)
    }
  }

  handleCancelClick () {
    Modal.confirm({
      title: I18n.t('common.cancelConfirmDialog'),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk: () => {
        this.props.onCancel()
        this.resetStorage()
      }
    })
  }

  get isUsecureAdmin () {
    return this.props.role === 'usecure-admin'
  }

  get allowAccessControl () {
    return this.isUsecureAdmin || ['distributor', 'msp'].includes(this.props.accountType)
  }

  setCompanyData () {
    const { companies = [] } = this.props

    const companyMap = _keyBy(companies, 'id')
    let companyOptions = []
    if (this.isUsecureAdmin) {
      companyOptions = buildCompanyOptions(
        companies.map(company => {
          const linkFieldValue = ['usecure', null]
          if (company.parentCompanyId) {
            linkFieldValue.push(company.parentCompanyId)
            const parentCompany = companyMap[company.parentCompanyId]
            if (parentCompany && parentCompany.accountType === 'msp' && parentCompany.parentCompanyId) {
              linkFieldValue.push(parentCompany.parentCompanyId)
            }
          }
          return { ...company, linkFieldValue }
        })
      )
    } else {
      companyOptions = buildCompanyOptions(companies)
    }

    this.setState({
      companyMap,
      companyOptions
    })
  }

  getAccessType (values) {
    const { isUsecureAdmin, props: { accountType }, state: { companyMap } } = this
    if (isUsecureAdmin && managedByUsecure(values)) {
      return 'usecure'
    }
    const viewAccountType = isUsecureAdmin ? _get(companyMap, `${values.companyId}.accountType`) : accountType
    return viewAccountType || null
  }

  isGlobalAllowed (values) {
    return this.allowAccessControl && ['usecure', 'distributor', 'msp'].includes(this.getAccessType(values))
  }

  getGlobalLabel (values) {
    return getGlobalLabel(this.getAccessType(values), I18n.t('emailTemplate', trOpt), this.isUsecureAdmin)
  }

  getGlobalExtra (values) {
    return getGlobalExtra(this.getAccessType(values), I18n.t('emailTemplate', trOpt), this.isUsecureAdmin)
  }

  isCompanyAccessAllowed (values) {
    return this.allowAccessControl && values.global !== true && ['usecure', 'distributor', 'msp'].includes(this.getAccessType(values))
  }

  getCompanyAccessLabel (values) {
    return getCompanyAccessLabel(this.getAccessType(values))
  }

  getCompanyAccessPlaceholder (values) {
    return getCompanyAccessPlaceholder(this.getAccessType(values))
  }

  getCompanyAccessExtra (values) {
    return getCompanyAccessExtra(this.getAccessType(values), I18n.t('emailTemplate', trOpt))
  }

  get templateValues () {
    const { template } = this.props
    const { name, locales, tile, subject, content, legacy, customSender, senderPrefix, senderDomain, senderName, senderEmail, companyId, senderDomains, category: templateCategory } = template || {}

    // Category is left blank on create to encourage the setting of a meaningful category
    let category = null
    if (this.isUpdate) {
      // Set category to "No Category" on update when the template's category is empty
      category = templateCategory ?? 'none'
    } else if (this.isClone) {
      // Use original category (if populated) when cloning a landing page
      category = templateCategory
    }

    return {
      name,
      locales,
      tile,
      category,
      subject,
      content,
      legacy,
      customSender,
      senderPrefix,
      senderDomain,
      senderName,
      senderEmail,
      senderDomains: senderDomains || [],
      companyId: companyId === null ? 'usecure' : companyId,
      global: _get(template, 'global', false),
      companies: _get(template, 'companies', []).map(company => _isString(company) ? company : _get(company, 'id', null)).filter(id => !_isNil(id))
    }
  }

  async componentDidMount () {
    this.setCompanyData()
    if (this.isUpdate) {
      await this.load(this.templateValues)
    }
    if (this.useStorage) {
      await this.loadPrompt()
    }
  }

  async componentDidUpdate (prevProps) {
    const { companies = [], template } = this.props
    const { companies: prevCompanies = [], template: prevTemplate } = prevProps
    if (!_isEqual(companies, prevCompanies)) {
      this.setCompanyData()
    }
    if (this.isUpdate && (!prevTemplate || !_isEqual(template, prevTemplate))) {
      await this.load(this.templateValues)
    }
  }

  getStoredState () {
    let storedState
    try {
      const storedStateString = localStorage.getItem(this.storageId)
      if (storedStateString) {
        storedState = JSON.parse(storedStateString)
      }
      if (this.isUsecureAdmin && storedState?.companyId === null) {
        storedState.companyId = 'usecure'
      }
    } catch (e) { }
    return storedState
  }

  async loadPrompt () {
    if (this.props.embedded) {
      return
    }

    const storedState = this.getStoredState()
    if (storedState) {
      // Set state using previous creation attempt
      await this.load(storedState)
      Modal.confirm({
        title: I18n.t('common.resumeConfirmDialog.title'),
        okText: I18n.t('common.resumeConfirmDialog.ok'),
        cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
        onCancel: this.reset
      })
    } else if (this.isClone) {
      await this.load(this.templateValues)
    }
  }

  async load (storedState = this.getStoredState()) {
    if (storedState) {
      await this.updateState({ ...storedState, showLegacyEditor: storedState.legacy === true })
      const { current: form } = this.form
      if (form) {
        // Splitting the value setting like this prevents a blank editor
        await form.setInitialValues(_omit(storedState, ['legacy', 'content']))
        await form.replaceValues(_pick(storedState, ['legacy', 'content']))
        await form.refreshFieldElements()
      }
    }
  }

  updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        this.updateLocalStorage()
        resolve()
      })
    })
  }

  updateLocalStorage () {
    if (!this.useStorage) return
    const values = _omit(this.state, [
      'contentValid', 'failed', 'companyOptions', 'companyMap'
    ])
    localStorage.setItem(this.storageId, JSON.stringify(values))
  }

  async reset () {
    await this.updateState({
      ...defaultState,
      showLegacyEditor: false
    })
    this.resetStorage()
    if (this.isClone) {
      return this.load(this.templateValues)
    }
    await this.form.current.resetFields()
    return this.form.current.refreshFieldElements()
  }

  resetStorage () {
    localStorage.removeItem(this.storageId)
  }

  renderFooter ({ submitLabel, valid, loading, footerAlign, disabled, submitIcon }) {
    return (
      <MutationFormFooter footerAlign={footerAlign}>
        <Button
          type='primary' ghost disabled={disabled} loading={loading}
          htmlType='submit' icon={submitIcon}
        >{submitLabel}
        </Button>
        {this.props.showCancel && <Button ghost type='danger' disabled={loading} onClick={this.handleCancelClick}>{I18n.t('common.cancel')}</Button>}
      </MutationFormFooter>
    )
  }

  mutateValues ({ category, ...values }) {
    return {
      ...values,
      category: category === 'none' ? null : category
    }
  }

  render () {
    const mutation = this.isUpdate ? UPDATE_SIMULATION_EMAIL_TEMPLATE : CREATE_SIMULATION_EMAIL_TEMPLATE
    const { contentValid, showLegacyEditor, companyOptions } = this.state
    const { domains, layouts, companyId, embedded, contentLocales: defaultContentLocales, role } = this.props
    const { isUsecureAdmin } = this

    const tabs = []
    tabs.push({
      id: 'content',
      title: I18n.t('emailTemplate', commonTrOpt)
    })
    if (this.allowAccessControl) {
      tabs.push({
        id: 'access',
        title: I18n.t('common.accessControl.tabTitle')
      })
    }

    const fields = [
      {
        id: 'name',
        label: I18n.t('templateName', commonTrOpt),
        type: 'text',
        required: true,
        formItemStyle,
        tab: 'content'
      }, {
        id: 'locales',
        label: I18n.t('common.languagesParenPlural'),
        type: 'multiSelect',
        options: CONTENT_LANGUAGE_OPTIONS.sort((a, b) => a.label.localeCompare(b.label)),
        defaultValue: (this.isCreate && role !== 'usecure-admin' ? defaultContentLocales : []),
        placeholder: I18n.t('common.fields.languagesPlaceHolder'),
        required: true,
        requiredError: I18n.t('common.fields.languagesRequiredError'),
        formItemStyle,
        tab: 'content'
      }, {
        id: 'category',
        label: I18n.t('common.category'),
        type: 'select',
        options: CATEGORY_OPTIONS.sort((a, b) => {
          if (a.value === 'none') return -1
          if (b.value === 'none') return 1
          return a.label.localeCompare(b.label)
        }),
        required: true,
        formItemStyle,
        tab: 'content'
      }, {
        id: 'tile',
        label: I18n.t('tileImage', commonTrOpt),
        type: 'image',
        required: false,
        formItemStyle,
        tab: 'content'
      }, {
        id: 'subject',
        label: I18n.t('common.fields.emailSubject'),
        type: 'text',
        formItemStyle,
        tab: 'content'
      }, {
        id: 'senderName',
        type: 'text',
        label: I18n.t('senderName', commonTrOpt),
        extra: I18n.t('senderNameInfo', commonTrOpt),
        formItemStyle,
        tab: 'content'
      }, {
        id: 'sender',
        label: I18n.t('sender', trOpt),
        type: 'textWithSelect',
        divider: '@',
        formItemStyle: { maxWidth: 475 },
        input: {
          id: 'senderPrefix',
          removeSpaces: true,
          validate: this.validateSenderField,
          style: { width: '50%' }
        },
        select: {
          id: 'senderDomain',
          options: domains.map(domain => ({ value: domain, label: domain })),
          allowClear: true,
          showSearch: true,
          validate: this.validateSenderField,
          style: { width: 'calc(50% - 15px)' }
        },
        visible: values => values.customSender !== true,
        tab: 'content'
      }, {
        id: 'senderEmail',
        type: 'email',
        label: I18n.t('senderEmailAddress', commonTrOpt),
        visible: values => values.customSender === true,
        extra: I18n.t('emailSpamWarning', commonTrOpt),
        validate: this.validateSenderField,
        formItemStyle,
        tab: 'content'
      }, {
        id: 'customSender',
        label: I18n.t('customSender', commonTrOpt),
        type: 'switch',
        formItemStyle,
        tab: 'content'
      }, {
        id: 'senderDomains',
        label: I18n.t('senderDomains', commonTrOpt),
        type: 'textTags',
        extra: (
          <p className='base-font'>
            {I18n.t('senderDomainsInfo.shownInCreateSimulation', trOpt)}
            <br />
            {I18n.t('senderDomainsInfo.spamWarning', trOpt)}
          </p>
        ),
        tab: 'content',
        emptyError: I18n.t('common.invalidDomainError'),
        duplicateError: I18n.t('senderDomainsDuplicateError', trOpt),
        validateTag: value => {
          if (!validateDomain(value)) {
            return I18n.t('common.invalidDomainError')
          }
        }
      }, {
        id: 'content',
        type: showLegacyEditor ? 'emailBody' : 'unlayer',
        ...unLayerEmailFieldConfig,
        required: true,
        validate: this.validateContent,
        layouts,
        tab: 'content'
      }, {
        type: 'custom',
        component: ContentLinkAlert,
        visible: contentValid !== true,
        tab: 'content'
      }, {
        // This hidden switch field drives which editor is shown in conjunction with this.state.showLegacyEditor
        // The reason it's a field is in case we receive feedback asking for the slate editor back
        id: 'legacy',
        type: 'switch',
        label: I18n.t('legacy', trOpt),
        defaultValue: false,
        visible: false,
        formItemStyle,
        tab: 'content'
      }, {
        id: 'companyId',
        label: I18n.t('common.accessControl.ownedBy'),
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...companyOptions
        ],
        defaultValue: isUsecureAdmin && !embedded ? 'usecure' : companyId,
        mutateValue: value => value === 'usecure' ? null : value,
        visible: isUsecureAdmin,
        formItemStyle: { maxWidth: 400 },
        tab: 'access'
      }, {
        id: 'global',
        label: this.getGlobalLabel,
        required: false,
        type: 'checkbox',
        mutateValue: (value, values) => this.isGlobalAllowed(values) ? value : false,
        visible: this.isGlobalAllowed,
        extra: this.getGlobalExtra,
        tab: 'access'
      }, {
        id: 'companies',
        label: this.getCompanyAccessLabel,
        type: 'multiSelect',
        required: false,
        placeholder: this.getCompanyAccessPlaceholder,
        extra: this.getCompanyAccessExtra,
        options: companyOptions,
        mutateValue: (value, values) => this.isCompanyAccessAllowed(values) ? (value || []) : [],
        visible: this.isCompanyAccessAllowed,
        formItemStyle,
        linkField: isUsecureAdmin ? 'companyId' : null,
        linkFieldValue: isUsecureAdmin ? 'ancestors' : null,
        tab: 'access'
      }
    ]

    return (
      <MutationForm
        ref={this.form}
        {...{ mutation, tabs, fields }}
        submitLabel={I18n.t('common.save')}
        variables={{ id: this.id }}
        skipResetFieldsOnSubmit
        disableSubmitIfInvalid={false}
        onSuccess={this.handleSuccess}
        onSubmit={this.handleSubmit}
        onFailure={this.handleFailure}
        onChange={this.handleChange}
        update={invalidateEmailTemplateQueryCache}
        footer={this.renderFooter}
        mutateValues={this.mutateValues}
        disableSubmitOnEnter
      />
    )
  }
}

EmailTemplateForm.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onFailure: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onLoadError: PropTypes.func,
  onSuccess: PropTypes.func
}
EmailTemplateForm.defaultProps = {
  id: null,
  onCancel: () => { },
  onLoadError: () => { },
  onSuccess: () => { }
}

const EmailTemplateFormQuery = React.forwardRef((props, ref) => {
  const { id, role, accountType, onLoadError: onLoadErrorProp } = props
  const isUsecureAdmin = role === 'usecure-admin'

  const [failed, updateFailed] = useState(false)
  const isCreate = !id
  const onLoadError = useCallback((error, defaultError) => {
    updateFailed(true)
    showErrors(error, defaultError)
    if (_isFunction(onLoadErrorProp)) {
      onLoadErrorProp(error)
    }
  }, [onLoadErrorProp])
  const onDomainsError = useCallback(error => onLoadError(error, I18n.t('domainsError', commonTrOpt)), [onLoadError])
  const onEmailTemplateError = useCallback(error => onLoadError(error, I18n.t('emailTemplateError', trOpt)), [onLoadError])
  const onCompaniesError = useCallback(error => onLoadError(error, I18n.t('errors.companiesError', commonTrOpt)), [onLoadError])

  const { loading: companiesLoading, error: companiesError, data: companiesData } = useQuery(GET_COMPANIES, {
    skip: isUsecureAdmin ? false : accountType === 'tenant' || accountType === 'prospect',
    variables: {
      withAccountType: true,
      withParentCompanyId: true,
      descendants: !isUsecureAdmin
    },
    onError: onCompaniesError
  })
  const { companies = [] } = companiesData || {}

  const { loading: domainsLoading, error: domainsError, data: { simulationDomains: domains = [] } = {} } = useQuery(GET_SIMULATION_DOMAINS, {
    onError: onDomainsError
  })
  const { loading: layoutsLoading, data: { emailTemplateLayouts: layouts = [] } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE_LAYOUTS)
  const { loading: emailTemplateLoading, error: emailTemplateError, data: { simulationEmailTemplate: template } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE, {
    fetchPolicy: 'no-cache',
    variables: { id },
    skip: isCreate,
    onError: onEmailTemplateError
  })

  if (companiesError) return <ErrorAlerts error={companiesError} defaultError={I18n.t('errors.companiesError', commonTrOpt)} />
  if (domainsError) return <ErrorAlerts error={domainsError} defaultError={I18n.t('domainsError', commonTrOpt)} />
  if (failed || emailTemplateError) return <ErrorAlerts error={emailTemplateError} defaultError={I18n.t('emailTemplateError', trOpt)} />

  return (
    <>
      <LoadingBlock fullScreen loading={domainsLoading || layoutsLoading || emailTemplateLoading || companiesLoading} />
      {
        (!id || template) && (
          <EmailTemplateForm {...{ ref, id, template, domains, layouts, companies, onLoadError, ...props }} />
        )
      }
    </>
  )
})

export default compose(
  withConsumer,
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'role', 'companyId', 'accountType', 'contentLocales'])
  )
)(EmailTemplateFormQuery)
