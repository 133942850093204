import React, { useState, useEffect, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'

import { GET_COURSE_SLIDES, GET_COURSE_SLIDE, UPDATE_COURSE_SLIDE } from '../components/Queries/Courses'
import { LoadingBlock, ErrorAlerts } from '../components/common'
import { CourseSlideEditor } from '../components/EditCourse'
import { generatePath } from 'react-router-dom'
import routes from '../constants/routes'
import { creators as viewActions } from '../state/actions/view'
import { connect } from '../hocs'
import { LANGUAGES } from '../constants/languages'

const trOpt = { scope: 'editCourseContent' }

const EditCourseSlide = ({ match, history, setLoadingVisible }) => {
  const { course_id: courseId, slide_id: slideId, locale: routeLocale } = match.params
  const [locale, setLocale] = useState(null)
  const [dropdownLanguages, setDropdownLanguages] = useState([])
  const [slides, setSlides] = useState([])
  const [slide, setSlide] = useState(null)
  const [prevSlide, setPrevSlide] = useState(null)
  const [nextSlide, setNextSlide] = useState(null)

  const { loading, error, data, refetch: refetchSlide } = useQuery(GET_COURSE_SLIDE, {
    variables: { courseId, slideId, locale },
    fetchPolicy: 'no-cache',
    skip: !locale,
    notifyOnNetworkStatusChange: true
  })
  const { loading: slidesLoading, error: slidesError, data: slidesData, refetch: refetchSlides } = useQuery(GET_COURSE_SLIDES, {
    variables: { courseId, slideId, locale: routeLocale, includeSiblingSlides: true },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const [saveSlide] = useMutation(UPDATE_COURSE_SLIDE)
  useEffect(() => {
    if (slidesLoading || !slidesData) return

    const { course } = slidesData || {}
    if (course) {
      const { locale: sourceLocale, slides, siblingSlides } = course
      const locale = routeLocale || sourceLocale
      setLocale(locale)
      setSlides(slides)
      // Restrict language dropdown to translations for this slide
      if (_isArray(siblingSlides) && !_isEmpty(siblingSlides)) {
        const slideLocales = siblingSlides.map(s => s.locale)
        setDropdownLanguages(LANGUAGES.filter(l => slideLocales.includes(l.code)))
      } else {
        setDropdownLanguages([])
      }
    }
  }, [slidesLoading, slidesData, routeLocale])
  useEffect(() => {
    if (slidesLoading || loading || !slidesData || !data) return

    const { courseSlide } = data || {}
    const slideIndex = slides.findIndex(slide => slide.slideId === slideId)
    let prevSlide = null
    let nextSlide = null
    if (slideIndex !== -1) {
      if (slides[slideIndex - 1]) {
        prevSlide = slides[slideIndex - 1].slideId
      }
      if (slides[slideIndex + 1]) {
        nextSlide = slides[slideIndex + 1].slideId
      }
    }

    setSlide(_pick(courseSlide, ['id', 'slideId', 'type', 'title', 'content']))
    setPrevSlide(prevSlide)
    setNextSlide(nextSlide)
  }, [slidesLoading, slidesData, loading, data, slides, slideId])

  const onLocaleChange = useCallback(locale => {
    history.push(generatePath(routes.BUILDER_EDIT_SLIDE_LOCALE, { course_id: courseId, slide_id: slideId, locale }))
  }, [history, courseId, slideId])

  if (slidesError) return <ErrorAlerts error={slidesError} defaultError={I18n.t('theCourseCouldNotBeLoaded', trOpt)} />
  if (error) return <ErrorAlerts {...{ error }} defaultError={I18n.t('courseSlideLoadError', trOpt)} />

  return (
    <>
      <LoadingBlock fullScreen loading={!slide || slidesLoading || loading} />
      <CourseSlideEditor
        {...{ courseId, slideId, locale, onLocaleChange, slide, prevSlide, nextSlide, setSlide, saveSlide, setLoadingVisible, refetchSlide, refetchSlides, dropdownLanguages }}
        slideList={slides}
      />
    </>
  )
}

export default connect(
  null,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading))
  })
)(EditCourseSlide)
