import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import I18n from 'i18n-js'

// TODO Generate list of actions based upon leaners selected
const LearnersActions = ({ selectedLearnerIds = [], actions = [], performAction = () => {}, renderSingleActionAsButton = false }) => {
  if (renderSingleActionAsButton && actions.length === 1) {
    const action = actions[0]
    const handleButtonClick = () => performAction(action.key)
    return (
      <Button type='primary' disabled={Boolean(action.disabled) || selectedLearnerIds.length === 0} onClick={handleButtonClick}>
        {action.label}
      </Button>
    )
  }

  const handleMenuClick = e => performAction(e.key)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.label}</Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={selectedLearnerIds.length === 0}>
      <Button>
        {I18n.t('common.actions')} <Icon type='down' />
      </Button>
    </Dropdown>
  )
}

export default LearnersActions
