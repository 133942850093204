import _pick from 'lodash/pick'

import { createAction } from '../../helpers/state'

export const types = {
  SESSION_FAIL: 'SESSION_FAIL',
  SESSION_UPDATE: 'SESSION_UPDATE',
  SESSION_LOCALE: 'SESSION_LOCALE',
  SESSION_RESET: 'SESSION_RESET',
  SESSION_INIT: 'SESSION_INIT'
}

const filterAuthSessionPayload = session =>
  _pick(session, [
    'userId',
    'name',
    'firstName',
    'lastName',
    'role',
    'companyId',
    'companyName',
    'accountType',
    'paymentProvided',
    'billingType',
    'partner',
    'email',
    'expires',
    'planName',
    'planValid',
    'inDisguise',
    'intercomHash',
    'originalRole',
    'multipleDisguises',
    'parentCompanyId',
    'parentCompany',
    'currentLearners',
    'activeLearnerCount',
    'billingStartDate',
    'managerTenant',
    'distributorId',
    'distributorName',
    'distributorUBreachProEnabled',
    'mspName',
    'mspId',
    'mspUBreachProEnabled',
    'commsType',
    'prospectStatus',
    'prospectSimulation',
    'locale',
    'companyLocale',
    'companyTimezone',
    'contentLocales',
    'isUsecure',
    'type',
    'passwordAuthAllowed'
  ])

export const creators = {
  fail: createAction(types.SESSION_FAIL),
  // SESSION_INIT is an action that combines the initialisation of session and settings states into 1 update
  // It effectively SESSION_UPDATE + SETTINGS_UPDATE without the race conditions that can occur between the 2 dispatches
  initialise: createAction(types.SESSION_INIT, ({ session, settings, view } = {}) => ({
    session: filterAuthSessionPayload(session),
    settings,
    view
  })),
  update: createAction(types.SESSION_UPDATE, session => filterAuthSessionPayload(session)),
  noAuthUpdate: createAction(types.SESSION_UPDATE, session => _pick(session, [
    'mspName',
    'locale'
  ])),
  updateLocale: createAction(types.SESSION_UPDATE, locale => ({ locale })),
  updateLocaleOnly: createAction(types.SESSION_LOCALE),
  updateLearnerId: createAction(types.SESSION_UPDATE, learnerId => ({ learnerId })),
  updateMFAToken: createAction(types.SESSION_UPDATE, mfaToken => ({ mfaToken })),
  endUserUpdate: createAction(types.SESSION_UPDATE, session => ({
    type: 'endUser',
    ..._pick(session, [
      'learnerId',
      'name',
      'firstName',
      'lastName',
      'companyId',
      'companyName',
      'locale',
      'expires',
      'role'
    ])
  })),
  reset: createAction(types.SESSION_RESET)
}

export default {
  types,
  creators
}
