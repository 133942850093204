/* global localStorage */
import React, { useEffect, useState } from 'react'
import { Icon, message, Alert as _Alert } from 'antd'
import I18n from 'i18n-js'
import { generatePath } from 'react-router-dom'
import _get from 'lodash/get'
import { compose } from 'recompose'
import styled from 'styled-components'

import { connect, withAppTitleAndIcons } from '../../hocs'
import { getSessionAndSettings } from '../../state/selectors'
import { creators as settingsActions } from '../../state/actions/settings'
import { creators as sessionActions } from '../../state/actions/session'
import { creators as viewActions } from '../../state/actions/view'
import MutationForm from '../../components/MutationForm/MutationForm'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { SignInHeader } from '../../components/SignIn'
import { USER_ID_ACCESS_REQUEST } from '../../components/Queries/Learners'
import { HoldingContent } from '../Holding'
import { getSessionAndSettingsFromLearnerMe } from '../../helpers/getLearnerMe'
import endUserClient from '../../apollo-client/endUserClient'
import routes from '../../constants/routes'
import useRouteCompany from '../../hooks/useRouteCompany'
import { captureSentryError } from '../../helpers/sentry'

const trOpt = { scope: 'endUserPortal.outstandingActivities' }
const trCommonOpt = { scope: 'common' }

const Alert = styled(_Alert)`
  margin-bottom: 25px;

  .ant-alert-message {
    position: relative;
    top: 2px;
  }
`

const OutstandingActivitiesLogin = ({ session, setLoadingVisible, endUserSessionUpdate, setTheme, updateSettings, match, history }) => {
  const [learnerId, updateLearnerId] = useState(false)
  const { loading: queryLoader, error, company, isPlanValid, settings, accountType } = useRouteCompany()
  const cantGrantAccess = !isPlanValid || accountType === 'prospect'
  useEffect(() => {
    if (session.initialised && !session.ready) {
      setLoadingVisible(true)
    } else if (session.role === 'full') {
      history.push(generatePath(routes.PORTAL_HOME, { companyId: session.companyId }))
    } else if (session.role === 'limited') {
      history.push(generatePath(routes.OUTSTANDING_ACTIVITIES, { companyId: session.companyId }))
    }
  }, [history, session, setLoadingVisible])

  useEffect(() => {
    if (settings && (company !== null)) {
      setTheme({
        ...(settings || {}),
        parentDefaultSettings: _get(company, 'parentCompany.settings.defaultTenantSettings')
      })
    }
  }, [settings, company, isPlanValid, setTheme])

  useEffect(() => {
    setLoadingVisible(queryLoader)
  }, [queryLoader, setLoadingVisible])

  const onSubmit = (data) => {
    updateLearnerId(data.learnerId)
    setLoadingVisible(true)
  }
  const onSuccess = async (queryResult) => {
    const path = 'data.userIdAccessRequest.token'
    localStorage.setItem('endUserToken', _get(queryResult, path))
    const { session, settings } = await getSessionAndSettingsFromLearnerMe(endUserClient, false, 'limited')
    endUserSessionUpdate(session)
    updateSettings(settings)
    history.push(generatePath(routes.OUTSTANDING_ACTIVITIES, { companyId: company.id }))
    setLoadingVisible(false)
  }
  const onFailure = (error) => {
    captureSentryError(error, { msg: 'User ID Access - Login - ERROR' })
    setLoadingVisible(false)
    message.error(I18n.t('noAccountFoundMatching', { ...trOpt, account: learnerId }))
  }
  if (queryLoader) return null
  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em'>
          {I18n.t('common.login')}
        </SignInHeader>
        {(company && !cantGrantAccess) && (
          <MutationForm
            mutation={USER_ID_ACCESS_REQUEST}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onFailure={onFailure}
            btnBlock
            submitLabel={I18n.t('common.login')}
            variables={{ companyId: company.id }}
            fields={[
              {
                id: 'learnerId',
                placeholder: I18n.t('enterYourEmailOrUserId', trOpt),
                type: 'text',
                prefix: <Icon type='user' />,
                required: true,
                allowClear: true
              }
            ]}
          />
        )}
        {company === null && (
          <Alert type='info' showIcon message={I18n.t('noCompanyWithTheSuppliedIdExists', trCommonOpt)} />
        )}
        {((company && cantGrantAccess) || error !== null) && <HoldingContent />}
      </Column>
    </Row>
  )
}
export const learnerIdLoginDispatcher = dispatch => ({
  setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
  setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
  setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale)),
  updateSettings: settings => dispatch(settingsActions.update(settings)),
  endUserSessionUpdate: session => {
    dispatch(sessionActions.endUserUpdate(session))
    dispatch(viewActions.loading(false))
  }
})
export default compose(
  withAppTitleAndIcons({ isSignInPage: true }),
  connect(
    getSessionAndSettings,
    learnerIdLoginDispatcher
  )
)(OutstandingActivitiesLogin)
