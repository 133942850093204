import React, { useCallback, useMemo } from 'react'
import { message, Table, Tag } from 'antd'
import moment from 'moment'
import CourseActions from './CourseActions'
import UnenrolFromCourseModal from '../Modals/UnenrolFromCourseModal'
import SendCourseReminderModal from '../Modals/SendCourseReminderModal'
import sendGapAnalysisReminderConfirm from '../Modals/SendGapAnalysisReminderConfirm'
import I18n from 'i18n-js'
import { renderDifficultyTagCell } from './common'
import { LIST_PAGINATION_PROPS } from '../../constants/list'

const trOpt = { scope: 'learner.courseTable' }

const CourseTable = ({ data, downloadCertificate = () => {}, openUnenrolGapAnalysis = () => {} }) => {
  const performAction = useCallback(async (action, courseResult) => {
    const { courseId = null, learnerId } = courseResult || {}
    switch (action) {
      case 'sendReminder':
        return SendCourseReminderModal(courseId, learnerId)
      case 'unenrolCourse':
        return UnenrolFromCourseModal(courseId, learnerId)
      case 'downloadCertificate':
        return downloadCertificate(courseResult)
      case 'unenrolGapAnalysis':
        return openUnenrolGapAnalysis(learnerId)
      case 'sendGapAnalysisReminder':
        return sendGapAnalysisReminderConfirm(learnerId)
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [downloadCertificate, openUnenrolGapAnalysis])

  const renderActionsCell = useCallback((actions, courseResult) => (
    <CourseActions actions={actions} courseResult={courseResult} performAction={performAction} />
  ), [performAction])

  const columns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('common.courseTitle'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: I18n.t('level', trOpt),
        dataIndex: 'level',
        key: 'level',
        sorter: (a, b) => a.level - b.level,
        render: renderDifficultyTagCell
      },
      {
        title: I18n.t('enrolled', trOpt),
        dataIndex: 'enrollDateStr',
        key: 'enrollDate',
        sorter: (a, b) => {
          return (a.enrollDate ? moment(a.enrollDate).unix() : 0) - (b.enrollDate ? moment(b.enrollDate).unix() : 0)
        },
        defaultSortOrder: 'descend'
      },
      {
        title: I18n.t('common.started'),
        dataIndex: 'startDateStr',
        key: 'startDate',
        sorter: (a, b) => {
          return (a.startDate ? moment(a.startDate).unix() : 0) - (b.startDate ? moment(b.startDate).unix() : 0)
        }
      },
      {
        title: I18n.t('completion', trOpt),
        dataIndex: 'finishDateStr',
        key: 'finishDate',
        sorter: (a, b) => {
          return (a.finishDate ? moment(a.finishDate).unix() : 0) - (b.finishDate ? moment(b.finishDate).unix() : 0)
        }
      },
      {
        title: I18n.t('score', trOpt),
        dataIndex: 'score',
        key: 'score',
        render: value => value ? I18n.t('common.percentage', { value }) : null
      },
      {
        title: I18n.t('common.actions'),
        dataIndex: 'actions',
        key: 'actions',
        render: renderActionsCell
      }
    ]
    if (data.some(({ auto }) => auto === true)) {
      columns.splice(3, 0, {
        dataIndex: 'auto',
        key: 'auto',
        render: auto => (auto === true ? (<Tag color='#61c34a'>{I18n.t('auto', trOpt)}</Tag>) : null),
        width: 30
      })
    }
    return columns
  }, [data, renderActionsCell])

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={LIST_PAGINATION_PROPS}
    />
  )
}

export default CourseTable
