import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import { withTheme } from 'styled-components'

const PolicyActions = ({ policyId, actions = [], performAction = () => {}, theme }) => {
  const handleMenuClick = e => performAction(e.key, policyId)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.icon && <Icon type={action.icon} theme={action.theme || 'twoTone'} twoToneColor={theme.primary} style={{ color: theme.primary }} />}  {action.label}</Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <Icon type='right' />
      </Button>
    </Dropdown>
  )
}

export default withTheme(PolicyActions)
