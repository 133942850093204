import gql from 'graphql-tag'

export const COURSE_PARTICIPATION = gql`
  query {
    courseParticipation {
      groupTree
      courses {
        id
        name
        icon
        subject
        difficulty
      }
      learners {
        id
        name
        groups
        courseResults
      }
    }
  }
`

export const COURSE_PARTICIPATION_COURSES = gql`
  query {
    courseParticipationCourses {
      id
      name
      icon
      subject
      difficulty
    }
  }
`

export const COURSE_PARTICIPATION_LEARNER_COUNT = gql`
  query {
    courseParticipationLearnerCount
  }
`

export const COURSE_PARTICIPATION_LEARNERS = gql`
  query ($offset: Int, $limit: Int) {
    courseParticipationLearners(offset: $offset, limit: $limit) {
      id
      name
      groups
      courseResults
    }
  }
`

export const ULEARN_REPORT = gql`
query ($learnerId: ID, $courseId: ID, $isGapAnalysis: Boolean, $excludeInactiveUsers: Boolean) {
  uLearnReport(courseId: $courseId, learnerId: $learnerId, isGapAnalysis: $isGapAnalysis, excludeInactiveUsers: $excludeInactiveUsers)
}
`

export const SIMULATION_PERFORMANCE = gql`
  query {
    simulationPerformance {
      groupTree
      simulations {
        id,
        name
        startDate
        landingPageId
        emailTemplateId
        overall
        byGroup
      }
    }
  }
`

export const UPHISH_SIMULATION_PERFORMANCE = gql`
 query($excludeInactiveUsers: Boolean) {
    simulationReport(excludeInactiveUsers: $excludeInactiveUsers) {
      simulations {
        id,
        name
        startDate
        landingPageId
        emailTemplateId
        # overall
        # byGroup
        status
        endDate
        autoPhish
        recipientCount
        sentCount
        openCount
        visitCount
        compromiseCount
        reportCount
        excludesInactiveUsers
        deliveryMethod
      }
      totalMetrics {
        opened
        visited
        compromised
        reported
        total
      }
    }
  }
`

export const SIMULATION_REPORT = gql`
 query {
    simulationReport {
      simulations {
        id,
        name
        startDate
        landingPageId
        emailTemplateId
        overall
        byGroup
      }
      totalMetrics
    }
  }
`

export const ULEARN_PERFORMANCE = gql`
  query ($snapshotLimit: Int) {
    ulearnPerformance ( snapshotLimit: $snapshotLimit ) {
      categories
      snapshots {
        dateTime
        overall
        byGroup
        byDifficulty
        latest
      }
    }
  }
`

export const SEND_WEEKLY_SUMMARY_NOW = gql`
  mutation ($reportTypes: [WeeklySummaryReportTypes!]) {
    sendWeeklySummaryNow (reportTypes: $reportTypes)
  }
`

export const RISK_SCORE_CONFIG = gql`
  query {
    riskScoreConfig
  }
`

export const DASHBOARD_REPORT = gql`
  query($excludeInactiveUsers: Boolean) {
    dashboardReport(excludeInactiveUsers: $excludeInactiveUsers)
  }
`

export const DASHBOARD_CHART_DATA = gql`
query($fromDate: Date, $toDate: Date) {
  dashboardChartData(fromDate: $fromDate, toDate: $toDate)
}
`

const REPORT_FRAGMENTS = gql`
fragment timeSeriesReportData on TimeSeriesData {
  id
  key
  __typename
  overall {
    value
    previousValue
    trend {
      direction
      description
    }
  }
  trend {
    direction
    description
  }
  values {
    date
    value
  }
}

fragment categoricalReportData on CategoricalData {
  id
  key
  __typename
  values {
    label
    id
    recordType
    values {
      key
      value
    }
  }
}
`

const PERFORMANCE_REPORT_FRAGMENTS = gql`
fragment performanceReportFields on PerformanceReport {
  overallRiskScore { ...timeSeriesReportData }
  uBreachRiskScore { ...timeSeriesReportData }
  uPhishRiskScore { ...timeSeriesReportData }
  uLearnRiskScore { ...timeSeriesReportData }
  uPhishCompromiseRate { ...timeSeriesReportData }
  uPhishSentNew { ...timeSeriesReportData }
  uPhishOpensNew { ...timeSeriesReportData }
  uPhishVisitsNew { ...timeSeriesReportData }
  uPhishCompromisesNew { ...timeSeriesReportData }
  uPhishReportsNew { ...timeSeriesReportData }
  uPhishSimulations { ...categoricalReportData }
  uBreachBreaches { ...timeSeriesReportData }
  uBreachBreachedLearners { ...timeSeriesReportData }
  uBreachBreachesNew { ...timeSeriesReportData }
  usersTotal { ...timeSeriesReportData }
  usersNew { ...timeSeriesReportData }
  usersRemoved { ...timeSeriesReportData }
  usersGroupManagers { ...timeSeriesReportData }
  usersLearnerManagers { ...timeSeriesReportData }
  usersInactive { ...timeSeriesReportData }
  usersActive { ...timeSeriesReportData }
  groupsTotal { ...timeSeriesReportData }
  groupsWithManager { ...timeSeriesReportData }
  uLearnAverageScore { ...timeSeriesReportData }
  uLearnCompletionRate { ...timeSeriesReportData }
  uLearnCompletedNew { ...timeSeriesReportData }
  uLearnCompleted { ...timeSeriesReportData }
  uLearnEnrolledNew { ...timeSeriesReportData }
  uLearnEnrolled { ...timeSeriesReportData }
  uPolicyPolicies { ...categoricalReportData }
  uPolicyNewPolicies { ...timeSeriesReportData }
  uPolicyUpdatedPolicies { ...timeSeriesReportData }
  uPolicySent { ...timeSeriesReportData }
  uPolicyVisited { ...timeSeriesReportData }
  uPolicySigned { ...timeSeriesReportData }
  uPolicySignedRequestRate { ...timeSeriesReportData }
  uPolicyOutstanding { ...timeSeriesReportData }
  visibility {
    uBreachEnabled
    uPhishEnabled
    uLearnEnabled
    uPolicyEnabled
    riskScoreEnabled
    uPhishReportsEnabled
  }
  granularity
  periodType
  periodValue
  periodYear
}
`

const BREACH_REPORT_FRAGMENTS = gql`
fragment breachReportUsersDataBreaches on BreachReportUsersDataBreaches {
  total
  lowSeverity
  mediumSeverity
  highSeverity
}

fragment breachReportUsersData on BreachReportUsersData {
  affected
  breachedServices
  breaches { ...breachReportUsersDataBreaches}
  breachesResolved
}

fragment breachReportServiceUsers on BreachReportServiceUsers {
  affected
  resolved
}

fragment breachReportService on BreachReportService {
    name
    title
    addedDate
    severity
    description
    dataClasses
    learners { ...breachReportServiceUsers }
    domainEmails { ...breachReportServiceUsers }
  }

fragment breachReportFields on BreachReport {
  periodType
  periodValue
  periodYear
  learnersOverall { ...breachReportUsersData }
  learnersThisPeriod { ...breachReportUsersData }
  domainEmailsOverall { ...breachReportUsersData }
  domainEmailsThisPeriod { ...breachReportUsersData }
  newBreachedServices { ...breachReportService }
  existingBreachedServices { ...breachReportService }
}
`

export const PERFORMANCE_REPORT = gql`
  ${REPORT_FRAGMENTS}
  ${PERFORMANCE_REPORT_FRAGMENTS}

  query($companyId: ID, $fromDate: Date!, $toDate: Date!, $granularity: ReportGranularity) {
    performanceReport (companyId: $companyId, fromDate: $fromDate, toDate: $toDate, granularity: $granularity) {
      ...performanceReportFields
    }
  }
`

export const PERFORMANCE_REPORT_ACCESS = gql`
  ${REPORT_FRAGMENTS}
  ${PERFORMANCE_REPORT_FRAGMENTS}

  query{
    performanceReportAccess{
      fromDate
      toDate
      period
      performanceReport {
        ...performanceReportFields
      }
    }
  }
`

export const BREACH_REPORT = gql`
  ${BREACH_REPORT_FRAGMENTS}

  query($companyId: ID, $fromDate: Date!, $toDate: Date!) {
    breachReport (companyId: $companyId, fromDate: $fromDate, toDate: $toDate) {
      ...breachReportFields
    }
  }
`

export const BREACH_REPORT_ACCESS = gql`
  ${BREACH_REPORT_FRAGMENTS}

  query{
    breachReportAccess{
      fromDate
      toDate
      period
      breachReport {
        ...breachReportFields
      }
    }
  }
`

export const GET_BREACH_REPORT_START_DATE = gql`
  query($companyId: ID) {
    breachReportStartDate (companyId: $companyId)
  }
`

export const GET_BREACH_REPORT_VIEWS = gql`
  query($companyId: ID) {
    breachReportViews (companyId: $companyId) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const GET_PERFORMANCE_REPORT_START_DATE = gql`
  query($companyId: ID) {
    performanceReportStartDate (companyId: $companyId)
  }
`

export const GET_PERFORMANCE_REPORT_VIEWS = gql`
  query($companyId: ID) {
    performanceReportViews (companyId: $companyId) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      granularity
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const CREATE_CUSTOM_PERFORMANCE_REPORT = gql`
  mutation ($companyId: ID, $name: String!, $fromDate: Date!, $toDate: Date!, $granularity: ReportGranularity) {
    createCustomPerformanceReport(companyId: $companyId, name: $name, fromDate: $fromDate, toDate: $toDate, granularity: $granularity) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      granularity
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const UPDATE_CUSTOM_PERFORMANCE_REPORT = gql`
  mutation ($companyId: ID, $id: ID!, $name: String, $fromDate: Date, $toDate: Date, $granularity: ReportGranularity) {
    updateCustomPerformanceReport(companyId: $companyId, id: $id, name: $name, fromDate: $fromDate, toDate: $toDate, granularity: $granularity) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      granularity
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const DELETE_CUSTOM_PERFORMANCE_REPORTS = gql`
  mutation ($companyId: ID, $recordIds: [ID!]!) {
    deleteCustomPerformanceReports(companyId: $companyId, recordIds: $recordIds)
  }
`

export const INVALIDATE_PERFORMANCE_REPORT_CACHE = gql`
  mutation ($companyId: ID, $recordIds: [ID!]!) {
    invalidatePerformanceReportCache(companyId: $companyId, recordIds: $recordIds)
  }
`

export const CREATE_CUSTOM_BREACH_REPORT = gql`
  mutation ($companyId: ID, $name: String!, $fromDate: Date!, $toDate: Date!) {
    createCustomBreachReport(companyId: $companyId, name: $name, fromDate: $fromDate, toDate: $toDate) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const UPDATE_CUSTOM_BREACH_REPORT = gql`
  mutation ($companyId: ID, $id: ID!, $name: String, $fromDate: Date, $toDate: Date) {
    updateCustomBreachReport(companyId: $companyId, id: $id, name: $name, fromDate: $fromDate, toDate: $toDate) {
      id
      name
      periodType
      periodValue
      periodYear
      fromDate
      toDate
      custom
      recordIds
      final
      hasCachedData
    }
  }
`

export const DELETE_CUSTOM_BREACH_REPORTS = gql`
  mutation ($companyId: ID, $recordIds: [ID!]!) {
    deleteCustomBreachReports(companyId: $companyId, recordIds: $recordIds)
  }
`

export const INVALIDATE_BREACH_REPORT_CACHE = gql`
  mutation ($companyId: ID, $recordIds: [ID!]!) {
    invalidateBreachReportCache(companyId: $companyId, recordIds: $recordIds)
  }
`

export const SCHEDULED_REPORTS = gql`
  query ($companyId: ID) {
    scheduledReports (companyId: $companyId) {
      id
      companyId
      name
      reportType
      frequency
      enabled
      recipients
      parameters
    }
  }
`

export const GET_SCHEDULED_REPORT = gql`
  query ($id: ID!, $companyId: ID) {
    scheduledReport (id: $id, companyId: $companyId) {
      id
      companyId
      name
      reportType
      frequency
      enabled
      recipients
      parameters
    }
  }
`

export const DELETE_SCHEDULED_REPORTS = gql`
  mutation($ids: [ID!]!, $companyId: ID) {
    deleteScheduledReports(ids: $ids, companyId: $companyId)
  }
`

export const CREATE_SCHEDULED_REPORT = gql`
  mutation($name: String!, $companyId: ID, $reportType: ScheduledReportType!, $frequency: ScheduledReportFrequency!, $enabled: Boolean!, $recipients: [String!]!, $parameters: JSON!) {
    createScheduledReport(name: $name, companyId: $companyId, reportType: $reportType, frequency: $frequency, enabled: $enabled, recipients: $recipients, parameters: $parameters) {
      id
      companyId
      name
      reportType
      frequency
      enabled
      recipients
      parameters
    }
  }
`

export const UPDATE_SCHEDULED_REPORT = gql`
  mutation($id: ID!, $companyId: ID, $name: String!, $reportType: ScheduledReportType!, $frequency: ScheduledReportFrequency!, $enabled: Boolean!, $recipients: [String!]!, $parameters: JSON!) {
    updateScheduledReport(id: $id, companyId: $companyId, name: $name, reportType: $reportType, frequency: $frequency, enabled: $enabled, recipients: $recipients, parameters: $parameters) {
      id
      companyId
      name
      reportType
      frequency
      enabled
      recipients
      parameters
    }
  }
`

export const GET_SCHEDULED_REPORT_REPORTING_PERIODS = gql`
  query($id: ID!, $companyId: ID) {
    scheduledReportReportingPeriods(id: $id, companyId: $companyId) {
      fromDate
      toDate
    }
  }
`

export const SEND_SCHEDULED_REPORT = gql`
  mutation($id: ID!, $companyId: ID, $recipients: [String!], $fromDate: Date!, $toDate: Date!) {
    sendScheduledReport(id: $id, companyId: $companyId, recipients: $recipients, fromDate: $fromDate, toDate: $toDate)
  }
`

export const SEND_REPORT = gql`
  mutation($companyId: ID, $reportId: ID, $reportType: ScheduledReportType!, $recipients: [String!], $fromDate: Date!, $toDate: Date!) {
    sendReport(companyId: $companyId, reportId: $reportId, reportType: $reportType, recipients: $recipients, fromDate: $fromDate, toDate: $toDate)
  }
`

export const REPORT_ME = gql`
  query{
    reportMe{
      company {
        id
        name
        domain
        accountType
        planValid
        settings
        locale
        parentCompany {
          name
          settings
        }
      }
      reportType
      fromDate
      toDate
      period
      domain
    }
  }
`

export const GET_REPORT_ACCESS_TOKEN = gql`
  mutation($companyId: ID, $scheduledReportId: ID, $fromDate: Date, $toDate: Date, $period: ScheduledReportFrequency, $reportType: ScheduledReportType!) {
    reportAccessToken(companyId: $companyId, scheduledReportId: $scheduledReportId, fromDate: $fromDate, toDate: $toDate, period: $period, reportType: $reportType)
  }
`

export const GET_RISK_REPORT_DOWNLOAD_TOKEN = gql`
  mutation($companyId: ID!) {
    riskReportDownloadToken(companyId: $companyId)
  }
`

export const GET_DOMAIN_SCAN_REPORT_ACCESS_TOKEN = gql`
  mutation($name: String!, $domain: String!, $logo: String!, $targetEmails: [String!]) {
    domainScanReportAccessToken(name: $name, domain: $domain, logo: $logo, targetEmails: $targetEmails)
  }
`

export const DOMAIN_SCAN_REPORT_ACCESS = gql`
  query{
    domainScanReportAccess {
      domain
      name
      logo
      services {
        name
        title
        addedDate
        severity
        description
        dataClasses
        affected
      }
      exposedInformation {
        email
        password
        breach
      }
      uniqueEmails
    }
  }
`

export const UBREACH_PRO_USAGE_REPORT = gql`
  query {
    uBreachProUsageReport {
      company {
        id
        name
        parentCompanyId
        accountType
        deleted
      }
      domains
      domainCount
      uBreachProEnabled
      moved
      exemptFromBilling
      history {
        startDate
        endDate
        domainCount
        uBreachProEnabled
      }
    }
  }
`
