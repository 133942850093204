import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.courseEmail' }
class CourseEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)
    const { uPhishEnabled } = this.props

    this.settingId = 'course'
    this.showSentTestButton = true

    const hideSimSubject = window.__USECURE_CONFIG__.REACT_APP_DISABLE_SIMULATION_COURSE_EMAIL_COPY === 'true'
    const subjectFieldIds = uPhishEnabled && !hideSimSubject ? ['inviteSubject', 'simSubject', 'reminderSubject'] : ['inviteSubject', 'reminderSubject']
    this.generateSubjectFields(subjectFieldIds, trOpt)
  }

  get headerId () {
    return 'settings-course-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(CourseEmail)
