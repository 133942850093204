import gql from 'graphql-tag'

export const GET_GROUP_TREE = gql`
  query($excludeGroupId: ID, $includeLearnerCount: Boolean, $inactive: Boolean, $isManager: Boolean, $includeNoGroup: Boolean) {
    groupTree(excludeGroupId: $excludeGroupId, includeLearnerCount: $includeLearnerCount, inactive: $inactive, isManager: $isManager, includeNoGroup: $includeNoGroup)
  }
`

export const GET_GROUP = gql`
  query($groupId: ID!) {
    group(id: $groupId) {
      id,
      name
      syncRecordId
      syncType
      parent {
       id
      },
      managers {
        id
        name
      }
    }
  }
`

export const CREATE_GROUP = gql`
mutation($name:String!, $parentGroupId:ID, $managerIds: [ID]) {
  createGroup(name: $name, parentGroupId: $parentGroupId, managerIds: $managerIds) {
    id
    name
  }
}
`

export const GET_GROUPS_AUTOCOMPLETE = gql`
  query {
    groups {
      id
      name
    }
  }
`

export const EDIT_GROUP = gql`
  mutation($groupId: ID!, $name: String!, $parentGroupId: ID, $managerIds: [ID]) {
    editGroup(groupId: $groupId, parentGroupId: $parentGroupId, name: $name, managerIds: $managerIds) {
      id
      name
      parent {
        id
      },
      managers {
        id
        name
      }
    }
  }
`

export const DELETE_GROUP = gql`
  mutation($groupId: ID!) {
    deleteGroup(groupId: $groupId)
  }
`

export const DETACH_GROUPS_FROM_SYNC = gql`
  mutation($groupIds: [ID!]!) {
    detachGroupsFromSync(groupIds: $groupIds)
  }
`
