/* global localStorage */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Card, Table, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import _debounce from 'lodash/debounce'
import _isInteger from 'lodash/isInteger'
import _get from 'lodash/get'
import { compose } from 'recompose'
import moment from 'moment'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { USERVICE_INVOICES, GET_COMPANY_FOR_INVOICE } from '../components/Queries/Companies'
import { ContentWrap, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts } from '../components/common'
import { connect, withConsumer } from '../hocs'
import selectors from '../state/selectors'
import AddBillingMessage from '../components/uService/AddBillingDetailsMessage'
import UServiceInvoiceDownload from '../components/uService/uServiceInvoiceDownload'
import UServiceInvoiceSummary from '../components/uService/uServiceInvoiceSummary'
import { displayMoney } from '../helpers'
import { CURRENCIES } from '../constants/billing'
import { LIST_PAGINATION_PROPS } from '../constants/list'
import { formatDate, formatDateTime } from '../helpers/datetime'
import IntercomHeader from '../components/IntercomHeader'

const trOpt = { scope: 'uServiceInvoice' }

const { Option } = Select

const PeriodField = styled.div`
  label {
    margin-right: 10px;
  }

  .ant-select {
    width: 200px;
  }
`

const BillingBanner = ({ userAccountType, paymentProvided, billingType, companyId, companiesMap }) => {
  if (userAccountType === 'msp' && !paymentProvided && billingType === 'auto') {
    const companies = Object.keys(companiesMap).map(id => ({ id, ...companiesMap[id] }))
    const payingTenantCount = companies.filter(company => company.id !== companyId && !company.freeTrial).length
    return payingTenantCount > 0 ? <AddBillingMessage /> : null
  }
  return null
}

const CompaniesTable = ({
  companies = [], loading = false,
  sorter, updateSorter,
  updateFilters,
  pagination, updatePagination,
  cost,
  costIsEstimate = false,
  currency,
  tax,
  totalUsers = 0,
  isDistiMSP,
  totalPlatformCost
}) => {
  const onTableChange = useCallback((pagination, filters, sorter) => {
    updatePagination(pagination)
    updateFilters(filters)
    updateSorter(sorter)
  }, [updateFilters, updateSorter, updatePagination])
  const hasCost = !isDistiMSP && _isInteger(cost) && CURRENCIES.includes(currency)
  const hasTax = hasCost && _isInteger(tax)

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    const columns = [
      {
        title: I18n.t('common.fields.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortColumnKey === 'name' && order
      }, {
        title: I18n.t('maxUsers', trOpt),
        key: 'maxUsers',
        dataIndex: 'maxUsers',
        sorter: (a, b) => a.maxUsers - b.maxUsers,
        sortOrder: sortColumnKey === 'maxUsers' && order
      }
    ]
    if (hasCost) {
      columns.push({
        title: hasTax ? I18n.t('costExcludingTax', trOpt) : I18n.t('cost', trOpt),
        key: 'cost',
        dataIndex: 'costStr',
        sorter: (a, b) => a.cost - b.cost,
        sortOrder: sortColumnKey === 'cost' && order
      })
    }
    if (hasTax) {
      columns.push({
        title: I18n.t('costIncludingTax', trOpt),
        key: 'costInclTax',
        dataIndex: 'costInclTaxStr',
        sorter: (a, b) => a.costInclTax - b.costInclTax,
        sortOrder: sortColumnKey === 'costInclTax' && order
      })
    }

    return columns
  }, [sorter, hasCost, hasTax])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel>
          <h4>{I18n.t('uService.companyCount', { count: companies.length })}</h4>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          {hasCost ? <h4>{I18n.t('costPerUser', trOpt)}{costIsEstimate && '*'}: {displayMoney(cost, currency)}</h4> : null}
          <h4>{I18n.t('totalUsers', trOpt)}: {totalUsers}</h4>
          {hasCost ? <h4>{I18n.t('totalCost', trOpt)}: {displayMoney(totalPlatformCost, currency)}</h4> : null}
        </ListHeaderPanel>

      </ListHeader>
      <Table
        columns={columns} dataSource={companies} loading={loading}
        onChange={onTableChange}
        pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
      />
      {costIsEstimate && <div>{I18n.t('costPerUserIsApproximatelyEqual', { ...trOpt, total: hasTax ? 'subtotal' : 'total' })}</div>}
    </>
  )
}

const CompaniesView = ({
  client,
  loading = false, error,
  periods = [], companiesMap = {}, invoicePeriods = {},
  searchFilterText = '',
  updateSearchFilterText = () => { },
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  companyId,
  userId,
  accountType,
  userAccountType,
  period,
  updatePeriod = () => {},
  company,
  isDistiMSP,
  parentCompanyName
}) => {
  const editAccountType = useMemo(() => {
    if (userAccountType === 'distributor') {
      return 'msp'
    } else if (userAccountType === 'msp') {
      return 'tenant'
    }

    return accountType
  }, [userAccountType, accountType])
  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const onPeriodChange = useCallback(period => updatePeriod(period), [updatePeriod])
  const periodOptions = useMemo(() => {
    return periods.map(({ startDate, endDate }) => ({
      value: startDate,
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`
    }))
  }, [periods])

  const { currency, subTotal, tax, totalCost, totalUsers, currentPeriod, allCompanies, invoiceCompanies, lastUpdatedAt, cost, costIsEstimate, totalBreachDomains, totalBreachProCost, totalPlatformCost } = useMemo(() => {
    const { subTotal = null, tax = null, totalCost = null, totalUsers = null, currency = null, updatedAt = null, usage = [], costPerUser: cost = null, costPerUserIsEstimate: costIsEstimate = false, totalBreachDomains = null, totalBreachProCost = null, totalPlatformCost = null } = invoicePeriods[period] || {}

    const now = moment()
    let { value: currentPeriod = null } = periodOptions.find(({ value }) => now.isBetween(value, moment(value).add(1, 'months'), 'day', '[]')) || {}
    if (!currentPeriod) {
      currentPeriod = _get(periodOptions, '0.value', null)
    }

    const hasCost = !isDistiMSP && _isInteger(cost) && CURRENCIES.includes(currency)
    const hasTax = hasCost && _isInteger(tax)
    const allCompanies = usage.map(usage => {
      const { companyId: id, users: maxUsers, cost, costInclTax, updatedAt } = usage
      const { name } = companiesMap[id]
      const costStr = hasCost ? displayMoney(cost, currency) : I18n.t('common.na')
      const costInclTaxStr = hasTax ? displayMoney(costInclTax, currency) : I18n.t('common.na')

      return {
        key: id,
        id,
        name,
        maxUsers,
        cost,
        costStr,
        costInclTax,
        costInclTaxStr,
        lastUpdatedAt: updatedAt ? moment(updatedAt) : null
      }
    })
    allCompanies.sort((a, b) => a.name.localeCompare(b.name))

    const companyUpdatedAt = updatedAt ? moment(updatedAt) : null
    const childLastUpdatedAt = allCompanies.reduce((childLastUpdatedAt, { lastUpdatedAt }) => {
      if (lastUpdatedAt && (!childLastUpdatedAt || lastUpdatedAt.isAfter(childLastUpdatedAt))) {
        childLastUpdatedAt = lastUpdatedAt
      }
      return childLastUpdatedAt
    }, null)

    const lastUpdatedAt = companyUpdatedAt && childLastUpdatedAt ? moment.max(companyUpdatedAt, childLastUpdatedAt) : (companyUpdatedAt || childLastUpdatedAt)

    const invoiceCompanies = [...allCompanies]

    return { currency, subTotal, tax, totalCost, totalUsers, currentPeriod, allCompanies, invoiceCompanies, lastUpdatedAt, cost, costIsEstimate, totalBreachDomains, totalBreachProCost, totalPlatformCost }
  }, [invoicePeriods, periodOptions, period, companiesMap, isDistiMSP])

  const [companies, setCompanies] = useState([])
  const applyCompanyFilters = useCallback((searchFilterText) => {
    let companies = allCompanies

    if (searchFilterText) {
      companies = companies.filter(({ name }) =>
        name && String(name).toLowerCase().includes(searchFilterText.toLowerCase())
      )
    }

    setCompanies(companies)
  }, [allCompanies])
  const debouncedApplyCompanyFilters = useCallback(_debounce(applyCompanyFilters, 500), [applyCompanyFilters])
  useEffect(() => {
    debouncedApplyCompanyFilters(searchFilterText)
  }, [debouncedApplyCompanyFilters, searchFilterText])

  useEffect(() => {
    if (currentPeriod) {
      if (period && periodOptions.find(({ value }) => value === period)) {
        return
      }
      updatePeriod(currentPeriod)
    }
  }, [currentPeriod, period, periodOptions, updatePeriod])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel align='left'>
          <IntercomHeader Size='h1' id='uService-invoice-header'>{I18n.t(isDistiMSP ? 'titleDistiMSP' : 'title', { ...trOpt, product: I18n.t('common.uService') })}</IntercomHeader>
        </ListHeaderPanel>
        <ListHeaderPanel>
          <PeriodField>
            <label>{I18n.t('period', trOpt)}:</label>
            <Select value={periodOptions.length === 0 ? null : period} onChange={onPeriodChange} disabled={periodOptions.length === 0}>
              {
                periodOptions.map(({ value, label }, optIndex) => (<Option key={optIndex} value={value}>{label}</Option>))
              }
            </Select>
          </PeriodField>
          <UServiceInvoiceDownload {...{ company, companies: invoiceCompanies, period, currency, cost, costIsEstimate, totalUsers, subTotal, tax, totalCost, lastUpdatedAt, isDistiMSP, parentCompanyName, totalBreachDomains, totalBreachProCost, totalPlatformCost }} />
        </ListHeaderPanel>
      </ListHeader>
      <div style={{ margin: '20px 0 40px' }}>
        <UServiceInvoiceSummary
          {...{
            loading,
            totalBreachDomains,
            totalBreachProCost,
            totalPlatformCost,
            platformCost: cost,
            platformUsage: totalUsers,
            currency,
            subTotal,
            tax,
            total: totalCost
          }}
        />
      </div>
      <ListHeader>
        <ListHeaderPanel align='left'>
          <h2>{I18n.t('platformUsage', trOpt)}</h2>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <SearchBar
            placeholder={I18n.t('uService.searchForACompany')}
            value={searchFilterText}
            allowClear
            onChange={onSearchChange}
          />
        </ListHeaderPanel>
      </ListHeader>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('theInvoiceDataCouldNotBeLoaded', { ...trOpt, editAccountType })} />
          : (
            <CompaniesTable
              {...{
                loading,
                companies,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                client,
                companyId,
                userId,
                accountType,
                listAccountType: editAccountType,
                cost,
                costIsEstimate,
                currency,
                totalUsers,
                tax,
                totalPlatformCost,
                isDistiMSP
              }}
            />
          )
      }
      {lastUpdatedAt ? <div>{I18n.t('lastUpdated', { ...trOpt, datetime: formatDateTime(lastUpdatedAt) })}</div> : null}
    </>
  )
}

const ServiceInvoice = ({ match, userId, companyId, client, userAccountType, paymentProvided, billingType, parentCompanyId, parentCompanyName }) => {
  const { params: { accountType } = {} } = match || {}
  const [period, updatePeriod] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const storageId = useMemo(() => `uServiceInvoice|${companyId}|${userId}${accountType ? `|${accountType}` : ''}`, [userId, companyId, accountType])
  useEffect(() => {
    try {
      let listFilter = {
        searchFilterText: '',
        filters: null,
        pagination: undefined
      }
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        listFilter = { ...listFilter, ...JSON.parse(listFilterString) }
      }
      Object.keys(listFilter).forEach(key => {
        const value = listFilter[key]
        switch (key) {
          case 'searchFilterText':
            updateSearchFilterText(value)
            break
          case 'period':
            updatePeriod(value)
            break
          case 'filters':
            updateFilters(value)
            break
          case 'sorter':
            updateSorter(value)
            break
          case 'pagination':
            updatePagination(value)
            break
          default:
            break
        }
      })
    } catch (e) { }
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    let listFilter = {}
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        listFilter = JSON.parse(listFilterString)
      }
      listFilter = { ...listFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(listFilter))
    } catch (e) { }
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      period,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, period, filters, sorter, pagination])

  const [companyError, setCompanyError] = useState(null)
  const [invoiceError, setInvoiceError] = useState(null)

  const { loading: companyLoading, data: { company } = {} } = useQuery(GET_COMPANY_FOR_INVOICE, { onError: setCompanyError })
  const { loading: invoiceLoading, data: { uServiceInvoices } = {} } = useQuery(USERVICE_INVOICES, { onError: setInvoiceError })
  const { periods = [], companiesMap = {}, invoicePeriods = {} } = uServiceInvoices || {}
  const isDistiMSP = userAccountType === 'msp' && parentCompanyId

  return (
    <>
      <BillingBanner {...{ userAccountType, paymentProvided, billingType, companyId, companiesMap }} />
      <ContentWrap>
        <Card>
          <CompaniesView
            {...{
              client,
              loading: companyLoading || invoiceLoading,
              error: ((companyError || invoiceError) ? [companyError, invoiceError] : null),
              periods,
              companiesMap,
              invoicePeriods,
              companyId,
              userId,
              searchFilterText,
              updateSearchFilterText,
              sorter,
              updateSorter,
              filters,
              updateFilters,
              pagination,
              updatePagination,
              accountType,
              userAccountType,
              period,
              updatePeriod,
              company,
              isDistiMSP,
              parentCompanyName
            }}
          />
        </Card>
      </ContentWrap>
    </>
  )
}

export default compose(
  withConsumer,
  connect(state => {
    const { userId, companyId, accountType: userAccountType, paymentProvided, billingType, parentCompanyId, distributorName } = selectors.session.get(state)
    let parentCompanyName
    if (userAccountType === 'msp' && parentCompanyId) {
      parentCompanyName = distributorName
    }
    return { userId, companyId, userAccountType, paymentProvided, billingType, parentCompanyId, parentCompanyName }
  })
)(ServiceInvoice)
