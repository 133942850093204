import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, generatePath } from 'react-router-dom'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

import { connect } from '../hocs'
import routes from '../constants/routes'
import { isRouteAllowed } from '../helpers'
import { getSessionAndSettings } from '../state/selectors'
import { COURSE_BUILDER_ONLY_ROLES } from '../constants/roles'

const ProtectedRoute = ({ path, session, settings, component: Component, fallbackComponent: FallbackComponent, redirect = routes.HOME, debugLogger, sessionType = 'admin', ...rest }) => {
  if (!(session.planValid || (!session.planValid && session.inDisguise) || (_isArray(path) && path.includes(routes.HOLDING)))) {
    if (_isFunction(debugLogger)) debugLogger({ path, holding: true, session, settings })
    return <Redirect to={routes.HOLDING} />
  }

  const allowed = isRouteAllowed(session, settings, path, sessionType)

  // Redirect all protected routes requiring platform access to uservice page if msp or distributor's platformAccess flag is false
  if (!allowed && session.partner && !settings.platformAccess) {
    redirect = session.accountType === 'msp' && settings.enableProspects === true ? generatePath(routes.USERVICE_MSP_TYPE, { accountType: 'customers' }) : routes.USERVICE
  }
  // Redirect course builder only user to course builder as they don't have access to the homepage
  if (!allowed && COURSE_BUILDER_ONLY_ROLES.includes(session.role)) {
    redirect = routes.BUILDER
  }
  // Redirect prospect admin to risk report preview as they don't have access to the homepage
  if (!allowed && session.accountType === 'prospect') {
    redirect = routes.RISK_REPORT_PREVIEW
  }

  if (_isFunction(debugLogger)) debugLogger({ path, holding: false, allowed, session, settings })
  const RouteComponent = allowed ? Component : FallbackComponent
  return (
    (RouteComponent || (allowed && _isFunction(rest.render)))
      ? <Route path={path} {...rest} component={RouteComponent} />
      : <Redirect to={redirect} />
  )
}

ProtectedRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  settings: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  // TODO component or render is required
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  fallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  redirect: PropTypes.string
}

export default connect(
  getSessionAndSettings
)(ProtectedRoute)
