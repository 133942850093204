/* global localStorage */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Card, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { generatePath, Link } from 'react-router-dom'
import compose from 'recompose/compose'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import LandingPageBuilderTable from '../../components/uPhish/LandingPageBuilderTable'
import { ContentWrap, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts, ListViewActions } from '../../components/common'
import { GET_SIMULATION_LANDING_PAGE_TEMPLATES } from '../../components/Queries/uPhish'
import routes from '../../constants/routes'
import DeleteLandingPageConfirm from '../../components/Modals/DeleteLandingPageConfirm'
import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import { NONE_FILTER_VALUE } from '../../components/uPhish/TemplateHeader'
import { updateSelectedIdsOnRecordSetUpdate, disableActions } from '../../helpers/listPages'
import UpdateLandingPageLanguagesModal from '../../components/Modals/UpdateLandingPageLanguagesModal'
import { WRITABLE_LANDING_PAGE_BUILDER_ACTIONS } from '../../constants/actions'
import { CATEGORY_NAMES } from '../../constants/uPhish'
import IntercomHeader from '../../components/IntercomHeader'
const trOpt = { scope: 'uPhish.landingPageBuilder' }

const landingPageURL = window.__USECURE_CONFIG__.REACT_APP_LANDING_PAGE_URL

const getActions = (record, disabledActions) => disableActions([
  { key: 'edit', label: I18n.t('editLandingPage', trOpt), icon: 'edit' },
  { key: 'preview', label: I18n.t('previewLandingPage', trOpt), icon: 'eye' },
  { key: 'clone', label: I18n.t('uPhish.common.cloneLandingPage'), icon: 'copy' },
  { key: 'addLanguages', label: I18n.t('common.addLanguages'), icon: 'global', theme: 'outlined' },
  { key: 'removeLanguages', label: I18n.t('common.removeLanguages'), icon: 'global', theme: 'outlined' },
  { key: 'delete', label: I18n.t('deleteLandingPage', trOpt), icon: 'delete' }
], disabledActions)

export const LandingPageView = ({
  loading = false, error,
  records: rawRecords = [],
  searchFilterText = '',
  updateSearchFilterText = () => { },
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  history,
  showOwner = false,
  refetchQueries,
  routeId,
  role,
  companyId: userCompanyId,
  viewStorageId,
  planValid
}) => {
  const [showDeleteModal, updateShowDeleteModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [activeIds, setActiveIds] = useState([])
  const [selectedIds, updateSelectedIds] = useState([])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])

  const disabledActions = useMemo(() => !planValid ? WRITABLE_LANDING_PAGE_BUILDER_ACTIONS : null, [planValid])

  useEffect(() => {
    const allRecords = rawRecords.map((record) => {
      const { id, name, locales, companyId, category } = record

      return {
        id,
        key: id,
        name,
        locales,
        companyId: _get(record, 'company.id') || 'usecure',
        companyName: _get(record, 'company.name') || I18n.t('common.managedByUsecure'),
        isOwner: (role === 'usecure-admin' || userCompanyId === companyId),
        actions: getActions(record, disabledActions),
        categoryValue: category || NONE_FILTER_VALUE,
        category: CATEGORY_NAMES[category] || CATEGORY_NAMES.none
      }
    })

    allRecords.sort((a, b) => a.name.localeCompare(b.name))

    setAllRecords(allRecords)
  }, [rawRecords, role, userCompanyId, disabledActions])

  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedIds, allRecords),
  [allRecords])

  const applyRecordFilters = useCallback(searchFilterText => {
    let records = [...allRecords]

    if (searchFilterText) {
      const filterFields = ['name']
      records = records.filter(record =>
        filterFields.some(field => {
          const value = record[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setRecords(records)
  }, [allRecords])
  const debouncedApplyRecordFilters = useCallback(_debounce(applyRecordFilters, 500), [applyRecordFilters])
  useEffect(() => {
    debouncedApplyRecordFilters(searchFilterText)
  }, [debouncedApplyRecordFilters, searchFilterText])

  const updateActiveRecord = useCallback(ids => {
    ids = ids || []
    const [id] = ids
    setActiveId(id || null)
    setActiveIds(ids)
  }, [])
  const updateActiveId = useCallback(id => updateActiveRecord(id ? [id] : undefined), [updateActiveRecord])

  const openDeleteModal = useCallback(ids => {
    updateActiveRecord(ids)
    updateShowDeleteModal(true)
  }, [updateShowDeleteModal, updateActiveRecord])

  const afterModalClose = useCallback(() => updateActiveId(null), [updateActiveId])

  const openPreview = useCallback(templateId => {
    if (landingPageURL && templateId && userCompanyId) {
      window.open(`${landingPageURL}?t=${templateId}&s=-1&l=-1&sc=${userCompanyId}`, '_blank')
    }
  }, [userCompanyId])

  const updateLanguagesModalRef = useRef(null)
  const openUpdateLanguagesModal = useCallback((ids, action) => {
    if (updateLanguagesModalRef.current) {
      updateLanguagesModalRef.current.open(ids, action)
    }
  }, [updateLanguagesModalRef])

  const performAction = useCallback(async (action, ids) => {
    updateActiveRecord(ids)

    switch (action) {
      case 'edit':
        history.push(generatePath(routes.UPHISH_LANDING_PAGE_UPDATE, { landing_page_id: ids[0] }))
        break
      case 'clone':
        history.push(generatePath(routes.UPHISH_LANDING_PAGE_CLONE, { landing_page_id: ids[0] }))
        break
      case 'delete':
        await openDeleteModal(ids)
        break
      case 'preview':
        openPreview(ids[0])
        break
      case 'addLanguages':
        openUpdateLanguagesModal(ids, 'add')
        break
      case 'removeLanguages':
        openUpdateLanguagesModal(ids, 'remove')
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [history, openDeleteModal, updateActiveRecord, openPreview, openUpdateLanguagesModal])

  const actions = useMemo(() => {
    let actions = []

    if (selectedIds.length === 1) {
      const record = rawRecords.find(({ id }) => id === selectedIds[0])
      if (record) {
        actions = getActions(record, disabledActions)
      }
    } else if (selectedIds.length > 1) {
      return disableActions([
        { key: 'delete', label: I18n.t('deleteLandingPages', trOpt), icon: 'delete' },
        { key: 'addLanguages', label: I18n.t('common.addLanguages'), icon: 'global', theme: 'outlined' },
        { key: 'removeLanguages', label: I18n.t('common.removeLanguages'), icon: 'global', theme: 'outlined' }
      ], disabledActions)
    }

    return actions
  }, [selectedIds, rawRecords, disabledActions])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForALandingPage', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                    style={{ width: 200 }}
                  />
                )

              case 'actions':
                return (
                  <ListViewActions
                    key={index}
                    actions={actions} selectedIds={selectedIds}
                    performAction={performAction}
                  />
                )
              case 'add':
                return (
                  <Link to={routes.UPHISH_LANDING_PAGE_CREATE} key={index}>
                    <Button disabled={!planValid} type='primary' icon='plus'>{I18n.t('uPhish.common.createLandingPage')}</Button>
                  </Link>
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [
    searchFilterText, onSearchChange, performAction, selectedIds, actions, planValid
  ])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <ListHeader>
        {panelLeftComponent}
        {panelRightComponent}
        <>
          <DeleteLandingPageConfirm
            ids={activeIds}
            {...{ refetchQueries }}
            visible={showDeleteModal} setVisible={updateShowDeleteModal}
            afterClose={afterModalClose}
          />
          <UpdateLandingPageLanguagesModal
            ref={updateLanguagesModalRef}
            {...{ refetchQueries }}
            afterClose={afterModalClose}
          />
        </>
      </ListHeader>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('yourLandingPagesCouldNotBeLoaded', trOpt)} />
          : (
            <LandingPageBuilderTable
              {...{
                loading,
                records,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                selectedIds,
                updateSelectedIds,
                showOwner,
                performAction,
                activeId,
                updateActiveId,
                routeId,
                history,
                viewStorageId
              }}
            />
          )
      }
    </>
  )
}

const LandingBuilder = ({ history, role, userId, companyId, match, planValid }) => {
  const isUsecureAdmin = role === 'usecure-admin'
  const routeId = _get(match, 'params.landing_page_id')

  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [filters, updateFilters] = useState(isUsecureAdmin ? { companyName: ['usecure'] } : null)

  // Use localStorage to cache pagination and filters
  const viewStorageId = 'uPhishLandingPageBuilder'
  const storageId = useMemo(() => viewStorageId ? `${viewStorageId}|${companyId}|${userId}` : null, [viewStorageId, userId, companyId])
  useEffect(() => {
    try {
      if (!storageId) {
        return
      }
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const query = GET_SIMULATION_LANDING_PAGE_TEMPLATES
  const variables = {
    restrictToOwn: !isUsecureAdmin
  }
  const { loading, error, data } = useQuery(query, { variables })

  const records = _get(data, 'simulationLandingPages', [])

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='uPhish-landing-page-builder-header'>{I18n.t('common.uPhish')} - {I18n.t('landingPageBuilder', trOpt)}</IntercomHeader>
        <LandingPageView
          loading={loading}
          error={error}
          {...{
            loading,
            error,
            records,
            history,
            searchFilterText,
            updateSearchFilterText,
            sorter,
            updateSorter,
            pagination,
            updatePagination,
            filters,
            updateFilters,
            routeId,
            role,
            companyId,
            viewStorageId,
            planValid
          }}
          showOwner={isUsecureAdmin}
          refetchQueries={[{ query, variables }]}
          panelRight={['search', 'actions', 'add']}
        />
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withConsumer,
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'companyId', 'role', 'planValid'])
  )
)(LandingBuilder)
