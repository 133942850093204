import React, { useCallback } from 'react'
import styled, { css, withTheme } from 'styled-components'
import { Input, Button, Icon, Dropdown, Menu } from 'antd'
import I18n from 'i18n-js'

const { Search } = Input

const getAlign = (align, defaultValue = 'flex-start') => {
  switch (align) {
    case 'left': return 'flex-start'
    case 'right': return 'flex-end'
    default: return align || defaultValue
  }
}

const getVAlign = (vAlign, defaultValue = 'flex-end') => {
  switch (vAlign) {
    case 'top': return 'flex-start'
    case 'bottom': return 'flex-end'
    default: return vAlign || defaultValue
  }
}

export const ListHeader = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: ${({ align }) => getAlign(align, 'space-between')};
  margin-bottom: 15px;
`
export const ListHeaderPanel = styled.div`
  align-items: ${({ vAlign }) => getVAlign(vAlign)};
  display: flex;
  justify-content: ${({ align }) => getAlign(align)};
  ${({ width }) => width ? css`width: ${width}px;` : ''}

  ${({ align }) => {
    if (align === 'right') {
      return css`
        & > * {
          margin-left: 10px;
          &:first-child {
            margin-left: 0;
          }
        }
      `
    }

    return css`
      & > * {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    `
  }}
`
export const SearchBar = styled(Search)`
  max-height: 32px;

  .ant-input:focus {
    border-color: ${({ theme }) => theme.cta}!important
  }
   .ant-input:hover {
    border-color: ${({ theme }) => theme.cta}!important
  }
`

const ListButtonActions = styled.div`
  text-align: right;
  &> * {
    margin: 0 0 10px 10px;
    &:first-child {
      margin-left: 0px;
    }
  }
`

const ListActionButton = ({ id, action, performAction = () => {} }) => {
  const onClick = useCallback(() => performAction(action.key, id), [id, action, performAction])

  return (
    <Button
      icon={action.icon || action.buttonIcon}
      disabled={action.disabled}
      onClick={onClick}
    >{action.buttonLabel || action.label}
    </Button>
  )
}

export const ListActions = withTheme(({
  id, selectedIds = [], multiple = false, actions = [], performAction = () => {}, theme, onEmptyActions,
  buttonActionKeys = []
}) => {
  const handleMenuClick = useCallback(e => {
    if (multiple) {
      performAction(e.key, selectedIds)
    } else {
      performAction(e.key, id)
    }
  }, [multiple, performAction, id, selectedIds])

  let menuActions
  let buttonActions
  if (buttonActionKeys?.length > 0 && !multiple) {
    ({ menuActions, buttonActions } = actions.reduce((acc, action) => {
      if (buttonActionKeys.includes(action.key)) {
        acc.buttonActions.push(action)
      } else {
        acc.menuActions.push(action)
      }
      return acc
    }, { menuActions: [], buttonActions: [] }))
  } else {
    menuActions = actions
  }

  let dropdown = null
  if (menuActions?.length > 0 || onEmptyActions === 'disable' || onEmptyActions === 'hide') {
    let style
    let disabled = multiple && selectedIds.length === 0
    if (menuActions.length === 0) {
      if (onEmptyActions === 'skip') {
        return null
      } else if (onEmptyActions === 'disable' && !disabled) {
        disabled = true
      } else if (onEmptyActions === 'hide') {
        style = { visibility: 'hidden' }
      }
    }

    const menu = (
      <Menu onClick={handleMenuClick}>
        {
          menuActions.map(action => (
            <Menu.Item
              key={action.key}
              disabled={Boolean(action.disabled)}
            >
              {
                action.icon &&
                  <Icon
                    type={action.icon} theme={action.theme || 'twoTone'}
                    twoToneColor={theme.primary} style={{ color: theme.primary }}
                  />
              }
              {action.label}
            </Menu.Item>
          ))
        }
      </Menu>
    )

    dropdown = (
      <Dropdown
        overlay={menu} trigger={['click']}
        disabled={disabled}
      >
        {
          multiple
            ? <Button {...{ style }}>{I18n.t('common.actions')} <Icon type='down' /></Button>
            : (
              <Button {...{ style }}>
                <Icon type='right' />
              </Button>
            )
        }
      </Dropdown>
    )
  }

  if (buttonActions?.length > 0) {
    return (
      <ListButtonActions>
        {buttonActions.map(action => (
          <ListActionButton key={action.key} {...{ id, action, performAction }} />
        ))}
        {dropdown}
      </ListButtonActions>
    )
  }
  return dropdown
})

export const ListViewActions = ({ selectedIds = [], actions = [], performAction = () => {} }) => (
  <ListActions {...{ selectedIds, actions, performAction }} multiple />
)

export const ListTableActions = ({ id, actions = [], performAction = () => {}, onEmptyActions, buttonActionKeys }) => (
  <ListActions {...{ id, actions, performAction, onEmptyActions, buttonActionKeys }} />
)

export const ListHeaderTitle = styled.h1`
  margin-bottom: 0;
`
