import React, { useCallback, useMemo } from 'react'
import { message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import MutationForm from '../MutationForm/MutationForm'
import { CREATE_USER, GET_USER, GET_USERS, UPDATE_USER } from '../Queries/Users'
import { showErrors } from '../../helpers'
import { COMMS_TYPE_OPTIONS, ALL_ROLE_OPTIONS, ROLES_OPTIONS } from '../../constants/users'
import { connect } from '../../hocs'
import { session } from '../../state/selectors'
import { ErrorAlerts, LoadingBlock } from '../common'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'
import ModalHeaderId from './ModalHeaderId'
import { OverflowModal, ModalFormScrollContainer } from './common'

const trOpt = { scope: 'modals.addUserModal' }
const SHOW_USER_ROLE_TO_ALL = window.__USECURE_CONFIG__.REACT_APP_SHOW_USER_ROLE_TO_ALL === 'true'

const AddUserModal = ({ id, session, visible = false, setVisible = () => {}, afterClose = () => {} }) => {
  const form = React.createRef()
  const closeModal = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [setVisible, form])

  const onSuccess = useCallback(() => {
    message.success(id ? I18n.t('common.updateUserSuccess') : I18n.t('common.createUserSuccess'))
    closeModal()
  }, [id, closeModal])

  const onFailure = useCallback(error => {
    showErrors(error,
      id ? I18n.t('updateErrorMessage', trOpt) : I18n.t('common.createUserError'),
      function () {
        this.errors = this.errors.map(error => {
          if (error.type === 'field_value_exists' && error.original.path === 'email') {
            error.message = I18n.t('common.userEmailExistsError')
          }
          return error
        })
      }
    )
  }, [id])

  const { loading, error, data } = useQuery(GET_USER, { variables: { id }, skip: !id })
  const user = _get(data || {}, 'user')
  const fields = useMemo(() => {
    let locale
    const { companyLocale: companyDefaultLocale, companyTimezone } = session

    if (visible) {
      if (user && user.locale) {
        locale = user.locale
      } else if (companyDefaultLocale) {
        locale = companyDefaultLocale
      }
    }
    const isUsecureAdmin = session.role === 'usecure-admin'
    const fields = [
      { id: 'firstName', placeholder: I18n.t('common.fields.firstName'), required: true, label: I18n.t('common.fields.firstName'), defaultValue: _get(user, 'firstName') },
      { id: 'lastName', placeholder: I18n.t('common.fields.lastName'), required: true, label: I18n.t('common.fields.lastName'), defaultValue: _get(user, 'lastName') },
      { id: 'email', placeholder: I18n.t('common.fields.email'), type: 'email', required: true, autofill: false, label: I18n.t('common.fields.email'), defaultValue: _get(user, 'email') },
      {
        id: 'locale',
        label: I18n.t('common.preferredLanguage'),
        defaultValue: locale || DEFAULT_LANGUAGE,
        type: 'select',
        required: true,
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true
      },
      {
        id: 'timezone',
        label: I18n.t('common.timezone'),
        defaultValue: _get(user, 'timezone') || companyTimezone || DEFAULT_TIMEZONE,
        type: 'timezone',
        required: true
      }
    ]
    // Role is visible if:
    // - The current user is usecure-admin unless REACT_APP_SHOW_USER_ROLE_TO_ALL is true
    // - A user is being created
    // - The current user is not updating themselves
    // - The current user is a usecure admin updating another usecure admin
    // Downgrade usecure-admin to admin visually to match back end behaviour when this user logs in
    let role = _get(user, 'role')
    const { isUsecure: sessionUserIsUsecure = false } = session || {}
    if (role === 'usecure-admin' && !sessionUserIsUsecure) {
      role = 'admin'
    }
    if ((!id || id !== session.userId) && ((SHOW_USER_ROLE_TO_ALL && role !== 'usecure-admin') || isUsecureAdmin)) {
      fields.push({
        id: 'role',
        label: I18n.t('users.common.role'),
        type: 'select',
        options: isUsecureAdmin ? ALL_ROLE_OPTIONS : ROLES_OPTIONS,
        required: true,
        defaultValue: role
      })
    }
    if (session.partner || isUsecureAdmin) {
      fields.push({ id: 'commsType', label: I18n.t('commsType', trOpt), type: 'select', options: COMMS_TYPE_OPTIONS, required: true, defaultValue: _get(user, 'commsType') })
    }
    return fields
  }, [id, user, session, visible])
  if (error) return <ErrorAlerts error={error} defaultError={I18n.t('userError', trOpt)} />
  if (loading) return <LoadingBlock {...{ loading }} fullScreen={false} />
  return (
    <OverflowModal
      visible={visible}
      title={
        <ModalHeaderId
          idText={`${user && user.name ? 'update' : 'create'}-admin-user-header`}
          text={user && user.name
            ? I18n.t('editTitleName', { ...trOpt, name: user.name })
            : I18n.t(id ? 'editTitle' : 'addTitle', trOpt)}
        />
      }
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
      destroyOnClose
    >
      {
        visible && !loading &&
          <MutationForm
            mutation={id ? UPDATE_USER : CREATE_USER}
            onSuccess={onSuccess}
            onFailure={onFailure}
            submitLabel={id ? I18n.t('updateUser', trOpt) : I18n.t('common.createUser')}
            ref={form}
            fields={fields}
            variables={id ? { id } : undefined}
            refetchQueries={[{
              query: GET_USERS
            }]}
            scrollContainer={ModalFormScrollContainer}
          />
      }
    </OverflowModal>
  )
}

export default connect(
  state => ({ session: session.get(state) })
)(AddUserModal)
