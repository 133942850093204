import React, { useMemo } from 'react'
import { useQuery } from 'react-apollo'
import styled from 'styled-components'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'
import _times from 'lodash/times'

import { ContentWrap, LoadingBlock } from '../../components/common'
import DashboardStatistic from '../../components/Dashboard/DashboardStatistic'
import { DASHBOARD_REPORT } from '../../components/Queries/Reports'
import routes from '../../constants/routes'
import DashboardChart from './DashboardChart'
import DashboardHeader from './DashboardHeader'
import DashboardRiskScore from './DashboardRiskScore'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'

const trOpt = { scope: 'reports.dashboard' }

// There's probably a better way to handle adapting a CSS grid to missing items but will do for now
const getGridTemplateArea = ({ uPolicy, uBreachEnabled: uBreach, uPhish, uLearn, showRiskScore }) => {
  if (uPolicy && uBreach && uPhish && uLearn && showRiskScore) {
    return `"topbar topbar topbar topbar"
    "uLearn uPhish risk-score risk-score"
    "uPolicy uBreach risk-score risk-score"
    "chart chart chart chart"
    "chart chart chart chart"`
  } else {
    const activeProducts = Object.entries({ uLearn, uPhish, uPolicy, uBreach })
      .reduce((acc, [key, value]) => value ? [...acc, key] : acc, [])
    if (showRiskScore) {
      let gridItems = []
      // Zero active products isn't handled as there will be nothing to display
      if (activeProducts.length === 1) {
        // Single product item same size a risk score tile
        gridItems = [activeProducts[0], activeProducts[0], activeProducts[0], activeProducts[0]]
      } else if (activeProducts.length === 2) {
        // 2 product items same height a risk score tile
        gridItems = [activeProducts[0], activeProducts[1], activeProducts[0], activeProducts[1]]
      } else if (activeProducts.length === 3) {
        // 3 product items - make first item double width
        gridItems = [activeProducts[0], activeProducts[0], activeProducts[1], activeProducts[2]]
      } else {
        // 4 products items - full grid as per design
        gridItems = _times(4, i => activeProducts[i] || 'empty')
      }
      return `"topbar topbar topbar topbar"
      "${gridItems[0]} ${gridItems[1]} risk-score risk-score"
      "${gridItems[2]} ${gridItems[3]} risk-score risk-score"
      "chart chart chart chart"
      "chart chart chart chart"`
    } else {
      return `"topbar topbar topbar topbar"
      "${_times(4, i => activeProducts[i] || 'empty').join(' ')}"`
    }
  }
}

const DashboardGrid = styled.div`
    display: grid;
    grid-template-areas: ${getGridTemplateArea};
    /* grid-template-areas:
            "topbar topbar topbar topbar"
            "uLearn uPhish risk-score risk-score"
            "uPolicy uBreach risk-score risk-score"
            "chart chart chart chart"
            "chart chart chart chart"; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-gap: 25px;
`

const DashboardItem = styled.div`
background-color: white;
padding: 40px;
`

const Dashboard = ({ uPolicy, uBreachEnabled, uPhish, uLearn, enableRiskScore, excludeInactiveUsersInReports }) => {
  const {
    loading, data: {
      dashboardReport: {
        companyDashboardDetails,
        dashboardRiskScore,
        uBreachDashboardDetails,
        uLearnDashboardDetails,
        uPhishDashboardDetails,
        uPolicyDashboardDetails
      } = {}
    } = {}
  } = useQuery(DASHBOARD_REPORT, {
    variables: {
      excludeInactiveUsers: excludeInactiveUsersInReports
    }
  })

  const { companyDetails, totalGroups, totalUsers } = useMemo(() => companyDashboardDetails || {}, [companyDashboardDetails])
  const { averageScore, totalCoursesFinished } = useMemo(() => uLearnDashboardDetails || {}, [uLearnDashboardDetails])
  const { totalCompromises, totalSimulations } = useMemo(() => uPhishDashboardDetails || {}, [uPhishDashboardDetails])
  const { totalSentPolicies, totalSignedPolicies } = useMemo(() => uPolicyDashboardDetails || {}, [uPolicyDashboardDetails])
  const { totalLearners, totalBreachedLearners } = useMemo(() => uBreachDashboardDetails || {}, [uBreachDashboardDetails])
  const showRiskScore = useMemo(() => enableRiskScore && (uLearn || uPhish || uBreachEnabled), [enableRiskScore, uLearn, uPhish, uBreachEnabled])

  return (
    <>
      {loading && <LoadingBlock fullScreen loading={loading} />}
      <ContentWrap>
        <DashboardGrid {...{ uPolicy, uBreachEnabled, uPhish, uLearn, showRiskScore }}>
          {companyDetails && <DashboardHeader companyName={companyDetails.name} companyDomain={companyDetails.domain} totalGroups={totalGroups || 0} totalUsers={totalUsers || 0} />}
          {uLearn && (
            <DashboardItem style={{ gridArea: 'uLearn' }}>
              <DashboardStatistic
                id='uLearn-header'
                isEnabled={uLearn}
                hasLink
                title={I18n.t('common.uLearn')}
                link={routes.ULEARN_REPORT}
                percentage={Math.floor(averageScore || 0)}
                percentageDescription={I18n.t('averageScore', trOpt)}
                extraTitle={I18n.t('totalCoursesCompleted', trOpt)}
                extraData={totalCoursesFinished || 0}
              />
            </DashboardItem>
          )}
          {uPhish && (
            <DashboardItem style={{ gridArea: 'uPhish' }}>
              <DashboardStatistic
                id='uPhish-header'
                isEnabled={uPhish}
                hasLink
                title={I18n.t('common.uPhish')}
                link={routes.UPHISH_SIMS}
                percentage={Math.floor(totalCompromises * 100 / totalSimulations)}
                extraTitle={I18n.t('simulationsCompromised', trOpt)}
                extraData={`${totalCompromises} / ${totalSimulations}`}
                isGauge
              />
            </DashboardItem>
          )}
          {uPolicy && (
            <DashboardItem style={{ gridArea: 'uPolicy' }}>
              <DashboardStatistic
                id='uPolicy-header'
                isEnabled={uPolicy}
                hasLink
                title={I18n.t('common.uPolicy')}
                link={routes.UPOLICY}
                percentage={Math.floor(totalSignedPolicies * 100 / totalSentPolicies)}
                percentageDescription={I18n.t('ofPoliciesSigned', trOpt)}
                extraTitle={I18n.t('totalPoliciesSent', trOpt)}
                extraData={totalSentPolicies || 0}
              />
            </DashboardItem>
          )}
          {uBreachEnabled && (
            <DashboardItem style={{ gridArea: 'uBreach' }}>
              <DashboardStatistic
                id='uBreach-header'
                isEnabled={uBreachEnabled}
                hasLink
                title={I18n.t('common.uBreach')}
                link={routes.UBREACH}
                percentage={Math.floor(totalBreachedLearners * 100 / totalLearners)}
                extraTitle={I18n.t('usersBreaches', trOpt)}
                extraData={`${totalBreachedLearners} / ${totalLearners}`}
                isGauge
              />
            </DashboardItem>
          )}
          {showRiskScore && dashboardRiskScore && (
            <DashboardItem style={{ gridArea: 'risk-score' }}>
              <DashboardRiskScore
                currentRiskScore={dashboardRiskScore?.currentRiskScore}
                previousRiskScore={dashboardRiskScore?.previousRiskScore}
              />
            </DashboardItem>
          )}
          {showRiskScore && dashboardRiskScore && dashboardRiskScore.currentRiskScore && dashboardRiskScore.currentRiskScore.riskScore && (
            <DashboardItem style={{ gridArea: 'chart' }}>
              <DashboardChart />
            </DashboardItem>
          )}
        </DashboardGrid>
      </ContentWrap>
    </>
  )
}
export default

connect(
  state => ({
    ..._pick(selectors.session.get(state), ['accountType', 'prospectStatus']),
    ..._pick(selectors.settings.get(state), ['uLearn', 'uPhish', 'uBreachEnabled', 'uPolicy', 'enableRiskScore']),
    ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  })

)(Dashboard)
