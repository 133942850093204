import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.riskReportEmail' }

class RiskReportEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'riskReport'
    this.showSentTestButton = true
    this.usingWhiteTheme = true
    this.generateSubjectFields(['domainCompleteSubject', 'uBreachCompleteSubject', 'uPhishCompleteSubject'], trOpt)
  }

  get headerId () {
    return 'settings-risk-report-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    let key = 'description'
    if (this.props.defaultTenant) {
      const { enableProspects, enableRiskReportTenants } = this.props
      key = 'defaultTenantDescription'
      if (enableProspects && enableRiskReportTenants) {
        key = 'defaultTenantDescriptionBoth'
      } else if (enableRiskReportTenants) {
        key = 'defaultTenantDescriptionCustomer'
      }
    } else if (this.props.accountType === 'tenant') {
      key = 'descriptionCustomer'
    }

    return I18n.t(key, trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(RiskReportEmail)
