import React, { useCallback, useRef } from 'react'
import { Button, Divider as _Divider } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _pick from 'lodash/pick'

import SettingsForm from './SettingsForm'
import { DescriptionContainer, ActionContainer } from './common'
import { renderParagraphsFragmentFromArray } from '../../helpers'
import SendWeeklySummaryNowModal from '../Modals/SendWeeklySummaryNowModal'

const trOpt = { scope: 'settings.weeklySummary' }

const Divider = styled(_Divider)`
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.primary};
  }
`

const HeaderDescriptionContainer = styled(DescriptionContainer)`
  max-width: 1100px;
  padding-bottom: 0;
`

const AudienceDivider = React.forwardRef(({ visible }, ref) => {
  if (!visible) {
    return null
  }

  return (
    <div>
      <Divider>{I18n.t('audienceConfigTitle', trOpt)}</Divider>
      <p>{I18n.t('audienceConfigIntro', trOpt)}</p>
    </div>
  )
})

const SendWeeklySummaryNow = React.forwardRef(({ visible, disabled }, ref) => {
  const modalRef = useRef(null)

  const sendWeeklySummary = useCallback(async () => {
    if (modalRef.current) {
      await modalRef.current.open()
    }
  }, [modalRef])

  if (!visible) {
    return null
  }

  return (
    <>
      <Divider>{I18n.t('sendWeeklySummaryTitle', trOpt)}</Divider>
      <DescriptionContainer>
        <p>{I18n.t('sendWeeklySummaryInfo', trOpt)}</p>
      </DescriptionContainer>
      <ActionContainer>
        <Button disabled={disabled} icon='mail' onClick={sendWeeklySummary} type='primary'>{I18n.t('sendWeeklySummary', trOpt)}</Button>
      </ActionContainer>
      <SendWeeklySummaryNowModal ref={modalRef} />
    </>
  )
})

class WeeklySummary extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'weeklySummary'
    this.defaultValue = {
      enabled: false,
      admin: false,
      groupManager: false,
      learnerManager: false
    }
    this.setInitialFormValuesOnMount = true
  }

  get headerId () {
    return 'settings-weekly-summary-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return (
      <HeaderDescriptionContainer>{renderParagraphsFragmentFromArray(I18n.t('description', trOpt))}</HeaderDescriptionContainer>
    )
  }

  get successMsg () {
    return I18n.t('formSuccessMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('formErrorMessage', trOpt)
  }

  get _fields () {
    return [{
      id: 'enabled',
      type: 'switch',
      label: I18n.t('enabled', trOpt),
      defaultValue: this.defaultValue.enabled
    }, {
      id: 'audienceDivider',
      type: 'custom',
      component: AudienceDivider,
      visible: values => values.enabled === true
    }, {
      id: 'admin',
      type: 'switch',
      label: I18n.t('admin', trOpt),
      defaultValue: this.defaultValue.admin,
      visible: values => values.enabled === true
    }, {
      id: 'groupManager',
      type: 'switch',
      label: I18n.t('groupManager', trOpt),
      defaultValue: this.defaultValue.groupManager,
      visible: values => values.enabled === true
    }, {
      id: 'learnerManager',
      type: 'switch',
      label: I18n.t('userManager', trOpt),
      defaultValue: this.defaultValue.learnerManager,
      visible: values => values.enabled === true
    }, {
      id: 'sendWeeklySummaryNow',
      type: 'custom',
      component: SendWeeklySummaryNow,
      disabled: this.props.disableSubmit,
      visible: !this.defaultTenant
    }]
  }

  mutateValues (formValues) {
    const values = _pick(formValues, ['enabled', 'admin', 'groupManager', 'learnerManager'])
    if (values.enabled === false) {
      values.admin = false
      values.groupManager = false
      values.learnerManager = false
    }
    return values
  }

  onSubmit (values) {
    if (values.enabled && !(values.admin || values.groupManager || values.learnerManager)) {
      throw new Error(I18n.t('noAudienceSelectedError', trOpt))
    }
  }
}

export default WeeklySummary
