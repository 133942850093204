import { message, Modal } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { DELETE_GROUP } from '../Queries/Groups'
import { invalidateGroupsQueryCache, showErrors } from '../../helpers'

const confirm = Modal.confirm
const trOpt = { scope: 'modals.deleteGroupConfirm' }

const DeleteGroupConfirm = async (groupId, onOk = () => {}, refetchQueries, client = authenticatedClient) => {
  confirm({
    title: I18n.t('title', trOpt),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        await client.mutate({
          mutation: DELETE_GROUP,
          variables: { groupId },
          refetchQueries,
          update: invalidateGroupsQueryCache
        })

        message.success(I18n.t('successMessage', trOpt))
        onOk()
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      }
    }
  })
}

export default DeleteGroupConfirm
