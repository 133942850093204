import React from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'

import DeleteConfirm from './DeleteConfirm'
import { DELETE_COURSES } from '../Queries/Courses'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { getCoursesRefetchQueries } from '../../helpers/courses'

const trOpt = { scope: 'modals.deleteCourseConfirm' }

const DeleteCourseConfirm = ({ visible = false, setVisible = () => {}, courseIds = [], role }) => {
  const count = courseIds.length
  return (
    <DeleteConfirm
      {...{ visible, setVisible, trOpt }}
      mutation={DELETE_COURSES}
      refetchQueries={getCoursesRefetchQueries(role === 'usecure-admin')}
      variableName='courseIds'
      ids={courseIds}
      deletedItems={[
        <li key={0}>{I18n.t('deletedItem1', { ...trOpt, count })}</li>,
        <li key={1}>{I18n.t('deletedItem2', { ...trOpt, count })}</li>
      ]}
    />
  )
}

export default connect(
  state => _pick(selectors.session.get(state), ['role'])
)(DeleteCourseConfirm)
