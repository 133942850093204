import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import { ContentWrap, LoadingBlock, ErrorAlerts as _ErrorAlerts, ListHeader, ListHeaderPanel } from '../../components/common'
import PolicyForm from '../../components/Policies/PolicyForm'
import { GET_POLICY, UPDATE_POLICY, GET_POLICY_TEMPLATE, UPDATE_POLICY_TEMPLATE } from '../../components/Queries/uPolicy'
import routes from '../../constants/routes'
import selectors from '../../state/selectors'
import IntercomHeader from '../../components/IntercomHeader'
import useGlobalState from '../../hooks/useGlobalState'

const trOpt = { scope: 'uPolicy.editPolicy' }
const commonTrOpt = { scope: 'uPolicy.common' }

const ErrorAlerts = styled(_ErrorAlerts)`
  margin-top: 15px;
`

const EditPolicy = ({ history, type = 'policy', match, draft = false }) => {
  const { role, accountType } = useGlobalState(
    useCallback(state => _pick(selectors.session.get(state), ['role', 'accountType']), [])
  )
  const [policy, setPolicy] = useState(null)
  const form = useRef(null)

  const { params: { policyId = null } = {} } = match
  const isTemplate = type === 'template'
  const backPath = isTemplate ? routes.UPOLICY_TEMPLATE_BUILDER : routes.UPOLICY

  const onSuccess = useCallback(() => {
    message.success(I18n.t(isTemplate ? 'successfullyUpdatedTemplate' : 'successfullyUpdatedPolicy', trOpt))
    history.push(backPath)
  }, [history, backPath, isTemplate])

  const { loading, error, data } = useQuery(
    isTemplate ? GET_POLICY_TEMPLATE : GET_POLICY,
    {
      variables: {
        id: policyId,
        forUpdate: true,
        withAccess: role === 'usecure-admin' || (accountType === 'msp' || accountType === 'distributor')
      }
    }
  )

  useEffect(() => {
    if (!loading && !error && data) {
      setPolicy(
        data[type === 'template' ? 'policyTemplate' : 'policy'] ?? null
      )
    } else {
      setPolicy(null)
    }
  }, [loading, error, type, data])

  useEffect(() => {
    if (!(policy && form?.current)) return

    const formValues = {
      ...policy,
      category: policy.category ?? 'none',
      type: policy[draft ? 'draftType' : 'type'] ?? 'editor',
      file: policy[draft ? 'draftFile' : 'file'],
      document: policy[draft ? 'draftDocument' : 'document'],
      companies: (
        (policy.companies ?? []).reduce((acc, company) => {
          if (_isString(company)) {
            acc.push(company)
          } else if (company.id) {
            acc.push(company.id)
          }
          return acc
        }, [])
      )
    }

    form.current.setInitialValues(formValues)
  }, [policy, form, draft])

  let body = null
  if (loading) {
    body = <LoadingBlock fullScreen={false} loading={loading} />
  } else if (error) {
    body = <ErrorAlerts {...{ error }} defaultError={I18n.t(isTemplate ? 'templateLoadError' : 'policyLoadError', { ...trOpt, support: I18n.t('pleaseContactSupport', trOpt) })} />
  } else if (policy) {
    let saveActions = ['saveDraft', 'update', 'publish']
    if (draft) {
      saveActions = ['updateDraft', 'publishDraft']
    } else if (isTemplate) {
      saveActions = ['saveDraft', 'update']
    }
    body = (
      <PolicyForm
        ref={form}
        mutation={isTemplate ? UPDATE_POLICY_TEMPLATE : UPDATE_POLICY}
        onFailure={I18n.t(isTemplate ? 'failedToUpdateTemplate' : 'failedToUpdatePolicy', trOpt)}
        submitLabel={I18n.t(`update${isTemplate ? 'Template' : 'Policy'}`, trOpt)}
        {...{ policy, isTemplate, onSuccess, saveActions }}
        editDraft={draft}
        id={policyId}
      />
    )
  }

  const backLabel = isTemplate ? I18n.t('backToTemplateBuilder', trOpt) : I18n.t('backToPolicies', commonTrOpt)

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='uPolicy-template-update-header'>{I18n.t(`edit${draft ? 'Draft' : ''}${isTemplate ? 'Template' : 'Policy'}`, commonTrOpt)}</IntercomHeader>
        <ListHeader>
          <ListHeaderPanel>
            <Link to={backPath}>
              <Button type='primary'>{backLabel}</Button>
            </Link>
          </ListHeaderPanel>
        </ListHeader>
        {body}
      </Card>
    </ContentWrap>
  )
}

export default withRouter(EditPolicy)
