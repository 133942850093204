import React, { useCallback, useMemo, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import I18n from 'i18n-js'
import mime from 'mime-types'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _keyBy from 'lodash/keyBy'

import MutationForm from '../MutationForm/MutationForm'
import { base64EncodeJSON, invalidatePoliciesQueryCache } from '../../helpers'
import { GET_COMPANIES } from '../Queries/Companies'
import selectors from '../../state/selectors'
import { LoadingBlock, ErrorAlerts } from '../common'
import { CATEGORY_OPTIONS } from '../../constants/uPolicy'
import SavePolicyModal from '../Modals/SavePolicyModal'
import PolicySignatureSettingsField, { PolicySignatureSettingsValidator, PolicySignatureSettingsMutateValue } from './PolicySignatureSettingsField'
import { managedByUsecure, buildCompanyOptions, accessControlHelpers } from '../../helpers/company'
import PolicyPDFViewer from './PolicyPDFViewer'
import { apiUrl } from '../../apollo-client/common'
import useGlobalState from '../../hooks/useGlobalState'
import { CONTENT_LANGUAGE_OPTIONS } from '../../constants/languages'

const trOpt = { scope: 'uPolicy.policyForm' }

const PolicyFilePreviewContainer = styled.div`
  margin-bottom: 24px;
`

const PolicyFilePreview = React.forwardRef(({ visible, file }, ref) =>
  visible ? (
    <PolicyFilePreviewContainer>
      <PolicyPDFViewer {...{ file }} noData={I18n.t('emptyUploadMessage', trOpt)} />
    </PolicyFilePreviewContainer>
  ) : null
)

export const PolicyForm = React.forwardRef(
  ({
    policy, mutation, isTemplate = false, templatePolicyId, id, onChange: onChangeProp, onFailure, onSuccess, refetchQueries, submitLabel,
    saveActions, editDraft = false
  }, ref) => {
    const { role, companyId, accountType, contentLocales: defaultContentLocales } = useGlobalState(
      useCallback(state => _pick(selectors.session.get(state), ['role', 'companyId', 'accountType', 'contentLocales']), [])
    )

    const [showSavePrompt, setShowSavePrompt] = useState(false)
    const [variables, setVariables] = useState(null)
    const [type, setType] = useState('editor')
    const [file, setFile] = useState(null)
    const form = useRef(null)
    const resetForm = useCallback(async () => {
      if (form && form.current) {
        await form.current.reset()
      }
      setType('editor')
      setFile(null)
    }, [form])
    const setInitialValues = useCallback(async values => {
      if (form && form.current) {
        await form.current.setInitialValues(values)
      }
      setType(values.type || 'editor')
      setFile(values.file || null)
    }, [form])
    const replaceValues = useCallback(async values => {
      if (form && form.current) {
        await form.current.replaceValues(values)
      }
      setType(values.type || 'editor')
      setFile(values.file || null)
    }, [form])
    useImperativeHandle(ref, () => ({
      reset: resetForm,
      replaceValues,
      setInitialValues
    }), [resetForm, replaceValues, setInitialValues])

    const onChange = useCallback((name, value) => {
      if (_isFunction(onChangeProp)) {
        onChangeProp(name, value)
      }

      if (name === 'type') {
        setType(value)
      } else if (name === 'file') {
        setFile(value)
      }
    }, [onChangeProp])
    useEffect(() => {
      setType(_get(policy, editDraft ? 'draftType' : 'type') || 'editor')
      setFile(_get(policy, editDraft ? 'draftFile' : 'file') || null)
    }, [policy, editDraft])

    const isUsecureAdmin = role === 'usecure-admin'
    const { loading: companiesLoading, error: companiesError, data: companiesData } = useQuery(GET_COMPANIES, {
      skip: isUsecureAdmin ? false : accountType === 'tenant',
      variables: {
        withAccountType: true,
        withParentCompanyId: true,
        descendants: !isUsecureAdmin
      }
    })

    const mutateValues = useCallback(({ document, category, ...values }) => ({
      ...values,
      document: base64EncodeJSON(document),
      category: category === 'none' ? null : category
    }), [])

    const typeName = isTemplate ? I18n.t('uPolicy.common.template') : I18n.t('uPolicy.common.policy')
    const typeRecordName = typeName.toLowerCase()

    const loading = companiesLoading
    const { companies = [] } = companiesData || {}

    const { companyMap, companySelect } = useMemo(() => {
      const companyMap = _keyBy(companies, 'id')
      let companySelect = []
      if (isUsecureAdmin) {
        companySelect = buildCompanyOptions(
          companies.map(company => {
            const linkFieldValue = ['usecure', null]
            if (company.parentCompanyId) {
              linkFieldValue.push(company.parentCompanyId)
              const parentCompany = companyMap[company.parentCompanyId]
              if (parentCompany && parentCompany.accountType === 'msp' && parentCompany.parentCompanyId) {
                linkFieldValue.push(parentCompany.parentCompanyId)
              }
            }
            return { ...company, linkFieldValue }
          })
        )
      } else {
        companySelect = buildCompanyOptions(companies)
      }

      return {
        companyMap,
        companySelect
      }
    }, [companies, isUsecureAdmin])

    const allowAccessControl = isUsecureAdmin || (isTemplate && ['distributor', 'msp'].includes(accountType))

    const getAccessType = useCallback(values => {
      if (isUsecureAdmin && managedByUsecure(values)) {
        return 'usecure'
      }
      const viewAccountType = isUsecureAdmin ? _get(companyMap, `${values.companyId}.accountType`) : accountType
      return viewAccountType || null
    }, [companyMap, isUsecureAdmin, accountType])
    const isGlobalAllowed = useCallback(values => {
      return allowAccessControl && ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
    }, [getAccessType, allowAccessControl])
    const getGlobalLabel = useCallback(values => {
      return accessControlHelpers.getGlobalLabel(getAccessType(values), typeRecordName, isUsecureAdmin)
    }, [getAccessType, isUsecureAdmin, typeRecordName])
    const getGlobalExtra = useCallback(values => {
      return accessControlHelpers.getGlobalExtra(getAccessType(values), typeRecordName, isUsecureAdmin)
    }, [getAccessType, isUsecureAdmin, typeRecordName])

    const isCompanyAccessAllowed = useCallback(values => {
      return allowAccessControl && values.global !== true && ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
    }, [getAccessType, allowAccessControl])
    const getCompanyAccessLabel = useCallback(values => {
      return accessControlHelpers.getCompanyAccessLabel(getAccessType(values))
    }, [getAccessType])
    const getCompanyAccessPlaceholder = useCallback(values => {
      return accessControlHelpers.getCompanyAccessPlaceholder(getAccessType(values))
    }, [getAccessType])
    const getCompanyAccessExtra = useCallback(values => {
      return accessControlHelpers.getCompanyAccessExtra(getAccessType(values), typeRecordName)
    }, [getAccessType, typeRecordName])

    const tabs = []
    tabs.push({
      id: 'content',
      title: isTemplate ? I18n.t('uPolicy.common.template') : I18n.t('uPolicy.common.policy')
    })
    if (!isTemplate) {
      tabs.push({
        id: 'signatureSettings',
        title: I18n.t('uPolicy.common.signatureSettings')
      })
    }
    tabs.push({
      id: 'access',
      title: I18n.t('common.accessControl.tabTitle')
    })

    const fields = useMemo(() => {
      // The 2 layer look up in this function caters for languages where the typeName interpolation doesn't work
      // For example, the French for policy and template have different genders so phrases like 'Policy Name' and 'Template Name' need their own keys
      const getFieldLabel = fieldId =>
        I18n.t(`${trOpt.scope}.${isTemplate ? 'templateFields' : 'policyFields'}.${fieldId}`, {
          defaults: [{
            message: I18n.t(fieldId, {
              ...trOpt,
              typeName: ['file', 'document'].includes(fieldId) ? typeRecordName : typeName
            })
          }]
        })

      return [
        {
          id: 'name',
          label: getFieldLabel('name'),
          placeholder: getFieldLabel('name'),
          required: true,
          tab: 'content'
        }, {
          id: 'locales',
          label: I18n.t('common.languagesParenPlural'),
          type: 'multiSelect',
          options: CONTENT_LANGUAGE_OPTIONS.sort((a, b) => a.label.localeCompare(b.label)),
          defaultValue: !policy && role !== 'usecure-admin' ? defaultContentLocales : [],
          placeholder: I18n.t('common.fields.languagesPlaceHolder'),
          required: true,
          requiredError: I18n.t('common.fields.languagesRequiredError'),
          tab: 'content',
          visible: isTemplate
        }, {
          id: 'category',
          required: true,
          label: I18n.t('common.category'),
          type: 'select',
          options: CATEGORY_OPTIONS,
          defaultValue: 'none',
          tab: 'content'
        }, {
          id: 'tile',
          label: I18n.t('common.templateTile'),
          required: false,
          type: 'image',
          tab: 'content',
          visible: isTemplate
        }, {
          id: 'ownerName',
          label: getFieldLabel('ownerName'),
          placeholder: getFieldLabel('ownerName'),
          mutateValue: value => _isString(value) && value.length > 0 ? value : null,
          visible: !isTemplate,
          tab: 'access'
        }, {
          id: 'ownerEmail',
          type: 'email',
          label: getFieldLabel('ownerEmail'),
          placeholder: getFieldLabel('ownerEmail'),
          mutateValue: value => _isString(value) && value.length > 0 ? value : null,
          visible: !isTemplate,
          tab: 'access'
        }, {
          id: 'public',
          label: I18n.t('uPolicy.common.allowExternalAccess'),
          required: false,
          type: 'checkbox',
          defaultValue: true,
          extra: I18n.t('publicExtra', trOpt),
          visible: !isTemplate,
          tab: 'access'
        }, {
          id: 'type',
          label: I18n.t('common.type'),
          type: 'select',
          options: [{
            value: 'editor', label: I18n.t('editor', trOpt)
          }, {
            value: 'pdf', label: I18n.t('pdfUpload', trOpt)
          }],
          defaultValue: 'editor',
          required: true
        }, {
          id: 'file',
          label: getFieldLabel('file'),
          type: 'file',
          required: values => values.type === 'pdf',
          visible: values => values.type === 'pdf',
          accept: mime.types.pdf,
          action: `${apiUrl}/upload-policy`,
          sizeLimit: '5MB',
          tab: 'content'
        }, {
          id: 'document',
          label: getFieldLabel('document'),
          type: 'slate',
          required: values => values.type === 'editor',
          visible: values => values.type === 'editor',
          keyboardShortcutsIntro: I18n.t('keyboardShortcutsIntro', trOpt),
          tab: 'content'
        }, {
          type: 'custom',
          component: PolicyFilePreview,
          visible: values => values.type === 'pdf',
          contentType: type,
          file,
          tab: 'content'
        }, {
          id: 'companyId',
          label: I18n.t('common.accessControl.ownedBy'),
          type: 'select',
          required: true,
          options: [
            { value: 'usecure', label: I18n.t('common.managedByUsecure') },
            ...companySelect
          ],
          defaultValue: isUsecureAdmin ? 'usecure' : companyId,
          mutateValue: value => value === 'usecure' ? null : value,
          visible: isUsecureAdmin,
          formItemStyle: { maxWidth: 400 },
          tab: 'access'
        }, {
          id: 'global',
          label: getGlobalLabel,
          required: false,
          type: 'checkbox',
          defaultValue: false,
          mutateValue: (value, values) => isGlobalAllowed(values) ? value : false,
          visible: isGlobalAllowed,
          extra: getGlobalExtra,
          tab: 'access'
        }, {
          id: 'companies',
          label: getCompanyAccessLabel,
          type: 'multiSelect',
          required: false,
          placeholder: getCompanyAccessPlaceholder,
          extra: getCompanyAccessExtra,
          options: companySelect,
          mutateValue: (value, values) => isCompanyAccessAllowed(values) ? (value || []) : [],
          visible: isCompanyAccessAllowed,
          formItemStyle: { maxWidth: 800 },
          linkField: isUsecureAdmin ? 'companyId' : null,
          linkFieldValue: isUsecureAdmin ? 'ancestors' : null,
          tab: 'access'
        }, {
          id: 'signatureSettings',
          component: PolicySignatureSettingsField,
          type: 'custom',
          defaultValue: { type: 'none', useDefault: false, setDefault: false },
          visible: !isTemplate,
          validate: PolicySignatureSettingsValidator,
          mutateValue: PolicySignatureSettingsMutateValue,
          policy,
          tab: 'signatureSettings'
        }
      ]
    }, [
      typeName, typeRecordName, policy, companySelect, companyId,
      isUsecureAdmin, isGlobalAllowed, getGlobalLabel, getGlobalExtra, isTemplate,
      isCompanyAccessAllowed, getCompanyAccessLabel, getCompanyAccessPlaceholder, getCompanyAccessExtra,
      file, type, defaultContentLocales, role
    ])

    const onSubmit = useCallback((values, errors, variables) => {
      if (errors.length > 0) {
        return
      }

      setShowSavePrompt(true)
      setVariables(variables)
    }, [setShowSavePrompt, setVariables])

    if (companiesError) return <ErrorAlerts error={companiesError} defaultError={I18n.t('common.companiesLoadError')} />

    return (
      <>
        <LoadingBlock {...{ loading }} fullScreen={false} />
        <SavePolicyModal
          {...{ id, mutation, variables, refetchQueries, onFailure, onSuccess, saveActions, isTemplate }}
          visible={showSavePrompt}
          setVisible={setShowSavePrompt}
        />
        <MutationForm
          ref={form}
          variables={{
            id,
            templatePolicyId
          }}
          {...{ tabs, fields, onChange, onSubmit, onFailure, mutateValues, refetchQueries, submitLabel }}
          update={invalidatePoliciesQueryCache}
          skipResetFieldsOnSubmit
          disableSubmitOnEnter
          submitIcon='save'
        />
      </>
    )
  }
)

export default PolicyForm
