import { loadStripe } from '@stripe/stripe-js/pure' // /pure provides a version of loadStripe that does load Stripe JS on import

import { STRIPE_APIKEY } from '../constants/billing'

export class StartStripe {
  constructor (apiKey = STRIPE_APIKEY) {
    this.apiKey = apiKey
    this.stripePromise = null
  }

  getPromise () {
    if (!this.stripePromise) {
      this.stripePromise = loadStripe(STRIPE_APIKEY)
    }
    return this.stripePromise
  }
}
