import I18n from 'i18n-js'
import { createLocaleGetterObject, createLocaleGetterOptions } from '../helpers/locale'
import { apiUrl } from '../apollo-client/common'

const trOpt = { scope: 'uPhish.emailTemplateForm' }
const qrTrOpt = { scope: 'uPhish.emailTemplateForm.qrCodeUnlayerTool' }

export const unLayerEmailFieldConfig = {
  get label () { return I18n.t('common.fields.content') },
  linkTypes: [
    {
      name: 'phishing_link',
      get label () { return I18n.t('phishingLink', trOpt) },
      attrs: {
        href: '%phishing_link%'
      }
    },
    {
      name: 'phone',
      enabled: false
    },
    {
      name: 'email',
      enabled: false
    },
    {
      name: 'sms',
      enabled: false
    }
  ],
  mergeTags: {
    first_name: {
      get name () { return I18n.t('common.fields.firstName') },
      value: '%first_name%'
    },
    last_name: {
      get name () { return I18n.t('common.fields.lastName') },
      value: '%last_name%'
    },
    full_name: {
      get name () { return I18n.t('contentMergeTags.fullName', trOpt) },
      value: '%full_name%'
    },
    date: {
      get name () { return I18n.t('common.fields.date') },
      value: '%date%'
    },
    date_time: {
      get name () { return I18n.t('contentMergeTags.datetime', trOpt) },
      value: '%date_time%'
    },
    ip_address: {
      get name () { return I18n.t('contentMergeTags.ipAddress', trOpt) },
      value: '%ip_address%'
    },
    location: {
      get name () { return I18n.t('contentMergeTags.location', trOpt) },
      value: '%location%'
    },
    email_address: {
      get name () { return I18n.t('common.fields.email') },
      value: '%email_address%'
    }
  },
  tools: {
    'custom#qr_code': {
      get label () { return I18n.t('blockLabel', qrTrOpt) },
      data: {
        apiUrl,
        appUrl: window.location.origin
      },
      properties: {
        align: {
          editor: {
            get label () { return I18n.t('align', qrTrOpt) }
          }
        },
        fixedWidth: {
          editor: {
            get label () { return I18n.t('fixedWidth', qrTrOpt) }
          }
        },
        widthType: {
          editor: {
            get label () { return I18n.t('widthType', qrTrOpt) },
            data: {
              options: [{
                get label () { return I18n.t('widthTypeAuto', qrTrOpt) },
                value: 'auto'
              }, {
                get label () { return I18n.t('widthTypeFixed', qrTrOpt) },
                value: 'fixed'
              }]
            }
          }
        }
      }
    }
  },
  customJS: [
    `${window.location.origin}/unlayer/email.js`
  ]
}

const categoryTrScope = 'uPhish.common.categories'
export const CATEGORY_NAMES = createLocaleGetterObject({
  none: 'common.noCategory',
  finance: `${categoryTrScope}.finance`,
  bills: `${categoryTrScope}.bills`,
  government: `${categoryTrScope}.government`,
  cloud: `${categoryTrScope}.cloud`,
  shopping: `${categoryTrScope}.shopping`,
  social: `${categoryTrScope}.social`,
  travel: `${categoryTrScope}.travel`,
  news: `${categoryTrScope}.news`,
  delivery: `${categoryTrScope}.delivery`,
  internal: `${categoryTrScope}.internal`
})

export const CATEGORY_OPTIONS = createLocaleGetterOptions(CATEGORY_NAMES)

const deliveryMethodTrScope = 'uPhish.common.deliveryMethods'
export const DELIVERY_METHODS = createLocaleGetterObject({
  smtp: `${deliveryMethodTrScope}.smtp`,
  messageInjection: `${deliveryMethodTrScope}.messageInjection`
})

export const DELIVERY_METHOD_OPTIONS = createLocaleGetterOptions(DELIVERY_METHODS)

const simResultDeliveryMethodTrScope = 'uPhish.common.simulationResultDeliveryMethods'
export const SIM_RESULT_DELIVERY_METHOD_IDS = [
  'smtp',
  'googleMessageInjection',
  'microsoftMessageInjection'
]
export const SIM_RESULT_DELIVERY_METHODS = createLocaleGetterObject({
  smtp: `${simResultDeliveryMethodTrScope}.smtp`,
  microsoftMessageInjection: `${simResultDeliveryMethodTrScope}.microsoftMessageInjection`,
  googleMessageInjection: `${simResultDeliveryMethodTrScope}.googleMessageInjection`
})

export const SIM_RESULT_DELIVERY_METHOD_OPTIONS = createLocaleGetterOptions(SIM_RESULT_DELIVERY_METHODS)

export const UNLAYER_LANDING_PAGE_CONTENT_FIELD_CONFIG = {
  linkTypes: [
    {
      name: 'usecure_event_trigger',
      get label () { return I18n.t('triggerSimulationCompromise', trOpt) },
      attrs: {
        href: '%usecure_event_trigger%'
      }
    },
    {
      name: 'phone',
      enabled: false
    },
    {
      name: 'email',
      enabled: false
    },
    {
      name: 'sms',
      enabled: false
    }
  ],
  tools: {
    form: {
      properties: {
        fields: {
          editor: {
            data: {
              defaultFields: [
                {
                  name: 'email',
                  get label () { return I18n.t('common.fields.email') },
                  type: 'email',
                  show_label: true
                },
                {
                  name: 'usecure_password',
                  get label () { return I18n.t('common.fields.password') },
                  type: 'text',
                  show_label: true
                }
              ]
            }
          }
        }
      },
      values: {
        containerPadding: '10px',
        _meta: {
          htmlClassNames: 'u_content_form usecure-event-trigger'
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        fields: [{
          name: 'email',
          type: 'email',
          get label () { return I18n.t('common.fields.email') },
          get placeholder_text () { return I18n.t('common.fields.email') },
          show_label: true,
          required: true
        }, {
          type: 'text',
          name: 'usecure_password',
          get label () { return I18n.t('common.fields.password') },
          get placeholder_text () { return I18n.t('common.fields.password') },
          value: '',
          options: '',
          show_label: true,
          required: true
        }],
        fieldBorder: {
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: '#CCC',
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: '#CCC',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderRightColor: '#CCC',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: '#CCC'
        },
        fieldBorderRadius: '0px',
        fieldPadding: '10px',
        fieldBackgroundColor: '#FFF',
        fieldColor: '#000',
        fieldFontSize: '12px',
        formWidth: {
          autoWidth: false,
          width: '100%'
        },
        formAlign: 'center',
        fieldDistance: '10px',
        labelFontSize: '14px',
        labelColor: '#444',
        labelAlign: 'left',
        labelPadding: '0px 0px 3px',
        buttonText: 'Submit',
        buttonColors: {
          color: '#FFF',
          backgroundColor: '#3AAEE0',
          hoverColor: '#FFF',
          hoverBackgroundColor: '#3AAEE0'
        },
        buttonAlign: 'center',
        buttonWidth: {
          autoWidth: false,
          width: '100%'
        },
        buttonFontSize: '14px',
        buttonBorder: {},
        buttonBorderRadius: '4px',
        buttonPadding: '10px',
        buttonMargin: '5px 0px 0px',
        hideDesktop: false,
        hideMobile: false,
        fieldWidth: '100%'
      }
    }
  },
  customJS: [
    `${window.location.origin}/unlayer/web.js`
  ]
}
