import React, { useEffect } from 'react'
import _get from 'lodash/get'
import _isString from 'lodash/isString'

import { creators as viewActions } from '../state/actions/view'
import { getSettings } from '../state/selectors/settings'
import { getSession } from '../state/selectors/session'
import connect from './connect'

const setFavicon = ({ icon, parentIcon, defaultIcon, setFavicon, appIsReady }) => {
  const favicon = icon || parentIcon
  if (favicon) {
    setFavicon(favicon)
  } else if (appIsReady) {
    setFavicon(defaultIcon)
  }
}

const AppTitleAndIconDispatcher = connect(
  state => {
    const { mspName, ready: sessionReady = false, locale, userId, learnerId, type: sessionType } = getSession(state) || {}
    const { ready: settingsReady = false, appPageTitle, appIcon, useSingleFavicon = true, appFavicon16, appFavicon32, appFavicon180, safariTabIcon, parentDefaultSettings } = getSettings(state) || {}
    const {
      appPageTitle: parentAppPageTitle,
      appIcon: parentAppIcon, useSingleFavicon: parentUseSingleFavicon = true,
      appFavicon16: parentAppFavicon16, appFavicon180: parentAppFavicon180, appFavicon32: parentAppFavicon32,
      safariTabIcon: parentSafariTabIcon
    } = parentDefaultSettings || {}
    return {
      dataReady: sessionReady && settingsReady,
      settingsReady,
      locale,
      mspName,
      sessionType,
      userId,
      learnerId,
      appPageTitle,
      parentAppPageTitle,
      appIcon,
      useSingleFavicon,
      appFavicon16,
      appFavicon32,
      appFavicon180,
      safariTabIcon,
      parentAppIcon,
      parentUseSingleFavicon,
      parentAppFavicon16,
      parentAppFavicon32,
      parentAppFavicon180,
      parentSafariTabIcon
    }
  },
  dispatch => ({
    setPageTitle: title => dispatch(viewActions.title(title)),
    setDefaultPageTitle: title => dispatch(viewActions.defaultTitle(title)),
    setPageTitleFromMspName: mspName => dispatch(viewActions.titleFromCompanyName(mspName)),
    setAppIcon: src => dispatch(viewActions.appIcon(src)),
    setFavicon16: src => dispatch(viewActions.favicon16(src)),
    setFavicon32: src => dispatch(viewActions.favicon32(src)),
    setFavicon180: src => dispatch(viewActions.favicon180(src)),
    setSafariTabIcon: src => dispatch(viewActions.safariTabIcon(src))
  })
)(
  ({
    noAuth = false, isSignInPage = false,
    mspName, locale, dataReady, userId, settingsReady, learnerId, sessionType,
    setPageTitleFromMspName, appPageTitle, parentAppPageTitle, setPageTitle, setDefaultPageTitle,
    appIcon, parentAppIcon, setAppIcon,
    useSingleFavicon, parentUseSingleFavicon,
    appFavicon16, parentAppFavicon16, setFavicon16,
    appFavicon32, parentAppFavicon32, setFavicon32,
    appFavicon180, parentAppFavicon180, setFavicon180,
    safariTabIcon, parentSafariTabIcon, setSafariTabIcon
  }) => {
    useEffect(() => {
      if (isSignInPage ? !settingsReady : !dataReady) return
      const hasUser = _isString(sessionType === 'endUser' ? learnerId : userId)
      const appIsReady = hasUser || noAuth || isSignInPage // The app is in a state where we should display a title and icon

      // ** Page Title **
      const pageTitle = _get(appPageTitle, locale) || _get(parentAppPageTitle, locale)
      if (pageTitle) {
        // Company has a page title set
        setPageTitle(pageTitle)
        setDefaultPageTitle(pageTitle)
      } else if (appIsReady) {
        // Company doesn't have a page title - prepend default page title with MSP name if relevant
        setPageTitleFromMspName(mspName || 'usecure') // Use usecure as fallback
      }

      const usingAppIcon = (parentUseSingleFavicon && parentAppIcon) || useSingleFavicon

      if (usingAppIcon) {
        // ** Single App Icon **
        setFavicon({ icon: appIcon, parentIcon: parentAppIcon, defaultIcon: '/favicon.svg', setFavicon: setAppIcon, appIsReady })
        setFavicon16(null)
        setFavicon32(null)
        setFavicon180(null)
        setSafariTabIcon(null)
      } else {
        // ** Multiple App Icons **
        setAppIcon(null)
        setFavicon({ usingAppIcon, icon: appFavicon16, parentIcon: parentAppFavicon16, defaultIcon: '/favicon-16x16.png', setFavicon: setFavicon16, appIsReady })
        setFavicon({ usingAppIcon, icon: appFavicon32, parentIcon: parentAppFavicon32, defaultIcon: '/favicon-32x32.png', setFavicon: setFavicon32, appIsReady })
        setFavicon({ usingAppIcon, icon: appFavicon180, parentIcon: parentAppFavicon180, defaultIcon: '/apple-touch-icon.png', setFavicon: setFavicon180, appIsReady })
        setFavicon({ usingAppIcon, icon: safariTabIcon, parentIcon: parentSafariTabIcon, defaultIcon: '/safari-pinned-tab.svg', setFavicon: setSafariTabIcon, appIsReady })
      }
    }, [
      noAuth, isSignInPage,
      mspName, locale, dataReady, userId, settingsReady, learnerId, sessionType,
      setPageTitleFromMspName, appPageTitle, parentAppPageTitle, setPageTitle, setDefaultPageTitle,
      parentUseSingleFavicon, parentAppIcon, useSingleFavicon,
      appFavicon16, appFavicon180, appFavicon32, appIcon,
      parentAppFavicon16, parentAppFavicon180, parentAppFavicon32, parentSafariTabIcon, safariTabIcon, setAppIcon, setFavicon16, setFavicon180, setFavicon32, setSafariTabIcon
    ])

    return null
  }
)

const withAppTitleAndIcons = ({ noAuth = false, isSignInPage = false } = {}) => Component =>
  React.forwardRef((props, ref) => (
    <>
      <AppTitleAndIconDispatcher {...{ noAuth, isSignInPage }} />
      <Component ref={ref} {...{ ...props }} />
    </>
  ))

export default withAppTitleAndIcons
