import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Modal, Button, Tag } from 'antd'
import styled from 'styled-components'
import _cloneDeep from 'lodash/cloneDeep'
import _isFunction from 'lodash/isFunction'
import _get from 'lodash/get'
import I18n from 'i18n-js'
import { generatePath } from 'react-router-dom'

import LearnerActions from './LearnerActions'
import Learner from '../../views/Learner'
import { ModalPager, ErrorAlerts } from '../common'
import { processTableData } from '../../helpers'
import { onListTableSelectAll, onListTableChange } from '../../helpers/listPages'
import routes from '../../constants/routes'
import { LIST_PAGINATION_PROPS } from '../../constants/list'

const LearnerModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .ant-modal-content {
    height: 100%;
  }
`

const LearnerModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`
const LearnerModalScroller = styled.div`
  height: 100%;
  overflow: auto;
`
const LearnerNameLinkButton = styled(Button)`
  padding: 0;
`

const LearnerInactiveTag = styled(Tag)`
  margin-left: 5px;
  position: relative;
  top: -1px;
`
const LearnerNameLink = ({ learner, showLink = true, onClick: onClickProp = () => {} }) => {
  const { id, name, inactive } = learner

  const onClick = useCallback(() => onClickProp(id), [onClickProp, id])
  const inactiveTag = inactive ? <LearnerInactiveTag>{I18n.t('common.inactive')}</LearnerInactiveTag> : null

  return (
    showLink
      ? <LearnerNameLinkButton type='link' onClick={onClick}>{name} {inactiveTag}</LearnerNameLinkButton>
      : <>{name} {inactiveTag}</>
  )
}

const LearnersTable = ({
  columns = [], learners = [], showActions = true, selectedLearnerIds = [], updateSelectedLearnerIds = () => {}, performAction = () => {},
  loading = false, error,
  pagination, updatePagination = () => {}, sorter, updateSorter = () => {},
  filters, updateFilters = () => {},
  uLearnEnabled = true,
  useSelection = true,
  size,
  routeId,
  useModalRouting = false,
  history,
  renderSingleActionAsButton = false,
  setPollingEnabled = () => {}
}) => {
  const [showModal, setShowModal] = useState(false)
  const [viewLearner, setViewLearner] = useState(null)

  useEffect(() => {
    setPollingEnabled(!showModal)
  }, [showModal, setPollingEnabled])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedLearnerIds(selectedRowKeys), [updateSelectedLearnerIds])
  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds: updateSelectedLearnerIds, filters, columns, records: learners })
  }, [learners, updateSelectedLearnerIds, filters, columns])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updatePagination, updateSorter, updateFilters, filters, onSelectAll])

  const renderActionsCell = useCallback((actions, learner) => (
    <LearnerActions
      learnerId={learner.key}
      actions={actions}
      performAction={performAction}
      renderSingleActionAsButton={renderSingleActionAsButton}
    />
  ), [performAction, renderSingleActionAsButton])

  const openLearnerModal = useCallback(learnerId => {
    setViewLearner(learnerId)
    setShowModal(true)
  }, [setViewLearner, setShowModal])
  const openRecord = useCallback(learnerId => {
    if (useModalRouting && history) {
      history.push(generatePath(routes.LEARNER, { learnerId }))
    } else {
      openLearnerModal(learnerId)
    }
  }, [openLearnerModal, useModalRouting, history])
  const updateViewLearner = useCallback(learnerId => {
    if (useModalRouting && history) {
      history.push(generatePath(routes.LEARNER, { learnerId }))
    } else {
      setViewLearner(learnerId)
    }
  }, [useModalRouting, history])

  const tableColumns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    let tableColumns = _cloneDeep(columns)

    if (showActions) {
      tableColumns.push({
        title: '',
        dataIndex: 'actions',
        align: 'right',
        width: 50,
        render: renderActionsCell
      })
    }

    tableColumns = tableColumns.map(col => {
      if (sorter) {
        col.sortOrder = col.key === sortColumnKey && order ? order : null
      }
      if (filters) {
        col.filteredValue = _get(filters, col.key, null)
      }
      // Setting LearnerNameLink showLink prop
      // The Learner modal only includes uLearn related content so the link is disabled if uLearn is
      // This will need to be amended when the single user report is complete as that will cover multiple products
      if (col.key === 'name') {
        if (_isFunction(col.render)) {
          // Combine original render with link render
          const originalColumnRender = col.render
          col.render = (text, learner, index) => {
            const nameComponent = (<LearnerNameLink learner={learner} showLink={uLearnEnabled} onClick={openRecord} />)
            return originalColumnRender(text, learner, index, nameComponent)
          }
        } else {
          col.render = (text, learner) => (<LearnerNameLink learner={learner} showLink={uLearnEnabled} onClick={openRecord} />)
        }
      }
      return col
    })

    return tableColumns
  }, [showActions, columns, renderActionsCell, sorter, filters, openRecord, uLearnEnabled])

  const closeModal = useCallback(() => setShowModal(false), [setShowModal])
  const afterClose = useCallback(() => {
    setViewLearner(null)
    if (useModalRouting) {
      history.push(routes.LEARNERS)
    }
  }, [setViewLearner, useModalRouting, history])

  useEffect(() => {
    if (!useModalRouting) return
    if (routeId) {
      openLearnerModal(routeId)
    } else {
      closeModal()
    }
  }, [routeId, useModalRouting, openLearnerModal, closeModal])

  const { learnerCount, currentLearnerNum, prevLearner, nextLearner } = useMemo(() => {
    let currentLearnerNum
    let prevLearner = null
    let nextLearner = null

    const tLearners = processTableData(tableColumns, learners)
    if (viewLearner && tLearners.length > 1) {
      const index = tLearners.findIndex(({ id }) => id === viewLearner)
      if (index !== -1) {
        currentLearnerNum = index + 1
        prevLearner = index - 1
        prevLearner = prevLearner < 0 ? tLearners.length - 1 : prevLearner
        prevLearner = (tLearners[prevLearner] || { id: null }).id
        nextLearner = index + 1
        nextLearner = nextLearner > tLearners.length - 1 ? 0 : nextLearner
        nextLearner = (tLearners[nextLearner] || { id: null }).id
      }
    }

    return {
      learnerCount: tLearners.length,
      currentLearnerNum,
      prevLearner,
      nextLearner
    }
  }, [viewLearner, learners, tableColumns])

  const tableProps = {
    dataSource: learners,
    columns: tableColumns,
    loading,
    onChange: onTableChange,
    pagination: { ...pagination, ...LIST_PAGINATION_PROPS },
    size
  }
  if (useSelection) {
    tableProps.rowSelection = {
      selectedRowKeys: selectedLearnerIds,
      onChange: onSelectChange,
      hideDefaultSelections: true,
      onSelectAll
    }
  }

  return (
    <>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('common.usersLoadError')} />
          : (
            <Table {...tableProps} />
          )
      }
      <LearnerModal
        width='90%'
        bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
        visible={showModal}
        onOk={closeModal}
        onCancel={closeModal}
        afterClose={afterClose}
        footer={null}
        destroyOnClose
        title={<ModalPager count={learnerCount} current={currentLearnerNum} prev={prevLearner} next={nextLearner} onClick={updateViewLearner} />}
      >
        <LearnerModalBody>
          <LearnerModalScroller>
            <Learner id={viewLearner} modal />
          </LearnerModalScroller>
        </LearnerModalBody>
      </LearnerModal>
    </>
  )
}

LearnersTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  learners: PropTypes.arrayOf(PropTypes.object),
  showActions: PropTypes.bool
}

export const defaultLearnerTableColumns = [{
  get title () { return I18n.t('common.fields.name') },
  dataIndex: 'name',
  key: 'name',
  sorter: (a, b) => {
    return a.name.localeCompare(b.name)
  }
}, {
  get title () { return I18n.t('learners.common.emailUserId') },
  dataIndex: 'email',
  key: 'email'
}]

LearnersTable.defaultProps = {
  columns: defaultLearnerTableColumns,
  learners: [],
  showActions: true
}

export default LearnersTable
