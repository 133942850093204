import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import { Button, message } from 'antd'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ListHeader, ListHeaderPanel, ListViewActions, ErrorAlerts, SearchBar } from '../common'
import BreachesTable from './BreachesTable'
import { searchRecords, disableActions } from '../../helpers/listPages'
import useLocalStorage from '../../hooks/useLocalStorage'
import { WRITABLE_UBREACH_ACTIONS } from '../../constants/actions'
import MarkResolvedConfirm from '../Modals/uBreach/MarkResolvedConfirm'

const trOpt = { scope: 'uBreach' }

const getRowActions = (disabledActions) => {
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const getSelectionActions = (selection, disabledActions) => {
  if (selection.length === 1) return getRowActions(disabledActions)
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const BreachesView = ({
  companyId,
  userId, // Current user, from global state
  domain = null, // If set, only show breaches where email domain matches
  breachedUsers = [],
  breachedServices = [],
  error,
  loading,
  refetchQueries,
  handleRefreshClick,
  planValid,
  uBreachProEnabled
}) => {
  const [searchText, setSearchText] = useState('')
  const [allRecords, setAllRecords] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const markResolvedConfirmRef = useRef(null)
  const openMarkResolvedConfirm = useCallback((records) => {
    if (markResolvedConfirmRef.current) {
      markResolvedConfirmRef.current.open(records)
    }
  }, [markResolvedConfirmRef])

  const disabledActions = useMemo(() => !planValid ? WRITABLE_UBREACH_ACTIONS : null, [planValid])

  const storageId = useMemo(() => `uBreach|breachesView|${companyId}|${userId}`, [userId, companyId])
  const tableStorageId = useMemo(() => `uBreach|breachesView|table|${companyId}|${userId}`, [userId, companyId])
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })

  const performAction = useCallback((action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.id === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.id))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }
    switch (action) {
      case 'markResolved':
        openMarkResolvedConfirm(
          actionRecords.reduce((acc, record) => {
            const { id } = record
            const breachedUserRecords = breachedUsers.reduce((acc, user) => {
              const { breaches, email, learnerId } = user
              const breach = breaches.find(breach => breach.name === id)
              if (breach) {
                acc.push({ email, learnerId, breachName: id })
              }
              return acc
            }, [])

            return [
              ...acc,
              ...breachedUserRecords
            ]
          }, [])
        )
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openMarkResolvedConfirm, breachedUsers])

  const listActions = useMemo(() =>
    getSelectionActions(allRecords.filter(record => selectedIds.includes(record.key)), disabledActions),
  [selectedIds, allRecords, disabledActions])

  useEffect(() => {
    if (!updateFromLocalStorage || domain) return
    updateFromLocalStorage({
      searchText: setSearchText
    })
  }, [updateFromLocalStorage, storageId, domain])

  useEffect(() => {
    if (!updateLocalStorage || domain) return
    updateLocalStorage({
      searchText
    })
  }, [updateLocalStorage, searchText, domain])

  useEffect(() => {
    if (!breachedUsers.length || !breachedServices.length) return

    let breaches = breachedUsers.reduce((acc, user) => {
      const { breaches, domain: userDomain } = user
      if (domain && domain !== userDomain) return acc

      for (const breach of breaches) {
        const { name, resolved, title } = breach
        const breachRecord = acc.find(breach => breach.breachName === name)
        if (!breachRecord) {
          acc.push({
            id: name,
            breachName: name,
            breachTitle: title,
            breachCount: 1,
            resolvedCount: resolved ? 1 : 0
          })
        } else {
          breachRecord.breachCount += 1
          if (resolved) breachRecord.resolvedCount += 1
        }
      }
      return acc
    }, [])

    breaches = breaches.map(breach => {
      const breachedService = breachedServices.find(service => service.name === breach.breachName)
      if (breachedService) {
        breach.dataClasses = breachedService.dataClasses
        breach.breachDate = breachedService.breachDate
        breach.severity = breachedService.category
      }
      return breach
    })

    setAllRecords(breaches)
  }, [domain, breachedUsers, breachedServices])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const records = allRecords.map(r => {
      const { id } = r
      const record = {
        ...r,
        key: id
      }
      if (uBreachProEnabled) {
        record.actions = getRowActions([
          ...(disabledActions || []),
          ...(record.breachCount === record.resolvedCount ? ['markResolved'] : [])
        ])
      }
      return record
    })
    return searchRecords({ records, searchText, searchKeys: ['breachTitle'] })
  }, [allRecords, searchText, disabledActions, uBreachProEnabled])

  return (
    <>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourBreachesCouldNotBeLoaded', trOpt)} />
          : (
            <>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
                </ListHeaderPanel>
              </ListHeader>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <SearchBar
                    placeholder={I18n.t('common.searchBreaches', trOpt)}
                    value={searchText}
                    allowClear
                    onChange={onSearchChange}
                  />
                  {uBreachProEnabled && (
                    <ListViewActions
                      actions={listActions}
                      performAction={performAction}
                      selectedIds={selectedIds}
                    />
                  )}
                </ListHeaderPanel>
              </ListHeader>
              <BreachesTable
                {...{
                  breachedServices,
                  breachedUsers,
                  domain,
                  refetchQueries,
                  loading,
                  handleRefreshClick,
                  performAction,
                  selectedIds
                }}
                updateSelectedIds={setSelectedIds}
                breaches={records}
                showActions={uBreachProEnabled}
                // Setting storageId to null when domain is set to prevent saving table state in the modals for domain view
                storageId={!domain && tableStorageId}
              />
            </>
          )
      }
      <MarkResolvedConfirm ref={markResolvedConfirmRef} type='breach' {...{ refetchQueries }} />
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId', 'userId', 'planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(BreachesView)
