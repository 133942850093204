import React, { useCallback, useState, useImperativeHandle, useRef } from 'react'
import { Button, Modal, Radio as _Radio, message } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'

import { showErrors } from '../../helpers'
import { LoadingBlock, ErrorAlerts } from '../common'
// Default export uses the react-apollo consumer not the apollo hooks version
import { withConsumer } from '../../hocs/withConsumer'
import { CONVERT_PROSPECT_TO_FREE_TRIAL, GET_COMPANY_FOR_UPDATE, GET_SUPPORTED_DOMAINS, UPGRADE_PROSPECT_TO_PAID_TENANT, RECORD_PROSPECT_UPGRADE_CANCELLATION } from '..//Queries/Companies'
import { EditCompanyModalForm, EditCompanyConnector } from './EditCompanyModal'
import { MutationFormFooter, MutationFormSubmitButton } from '../MutationForm'
import { CONTENT_LANGUAGE_OPTIONS } from '../../constants/languages'

const trOpt = { scope: 'modals.upgradeProspectModal' }
const companyTrOpt = { scope: 'modals.editCompanyModal' }

const UpgradeModal = styled(Modal)`
  max-width: 1000px;
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const Radio = styled(_Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  text-align: right;

  .ant-btn {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const UpgradeQuestion = ({
  action, setAction = () => {},
  onOk = () => {}, onCancel = () => {}
}) => {
  const onChange = useCallback(e => {
    setAction(e.target.value)
  }, [setAction])

  const finished = action === 'freeTrial'
  const okIcon = finished ? 'check-circle' : 'right-circle'
  const okLabel = finished ? I18n.t('completeUpgrade', trOpt) : I18n.t('common.next')

  return (
    <>
      <h3>{I18n.t('upgradePrompt', trOpt)}</h3>
      <Radio.Group onChange={onChange} value={action}>
        <Radio value='paidPlan'>{I18n.t('paidPlan', trOpt)}</Radio>
        <Radio value='freeTrial'>{I18n.t('freeTrial', companyTrOpt)}</Radio>
      </Radio.Group>
      <ButtonContainer>
        <Button icon='close-circle' type='danger' onClick={onCancel}>{I18n.t('common.cancel')}</Button>
        <Button icon={okIcon} type='primary' onClick={onOk} disabled={!action}>{okLabel}</Button>
      </ButtonContainer>
    </>
  )
}

const UpgradePaidFooter = ({ submitLabel, loading, footerAlign, disabled, submitIcon, footerProps }) => {
  const { onCancel: onCancelProp, setShowForm } = footerProps || {}
  const onCancel = useCallback(() => {
    if (_isFunction(onCancelProp)) {
      onCancelProp()
    }
  }, [onCancelProp])
  const onBack = useCallback(() => {
    if (_isFunction(setShowForm)) {
      setShowForm(false)
    }
  }, [setShowForm])

  return (
    <MutationFormFooter footerAlign={footerAlign}>
      <Button icon='close-circle' type='danger' onClick={onCancel}>{I18n.t('common.cancel')}</Button>
      <Button icon='left-circle' onClick={onBack}>{I18n.t('common.goBack')}</Button>
      <MutationFormSubmitButton {...{ submitLabel, submitIcon, disabled, loading }} ghost={false} />
    </MutationFormFooter>
  )
}
// This function allows UpgradePaidFooter to be functional component with hooks whilst still providing capability with MutationForm.footer prop
const renderUpgradePaidFooter = props => <UpgradePaidFooter {...props} />

const UpgradeProspectModal = React.forwardRef(({ client, refetchQueries, preview = false, refreshSessionState, onClose = () => {}, ...rest }, ref) => {
  const [visible, setVisible] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [company, setCompany] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('paidPlan')

  useImperativeHandle(ref, () => ({
    open: async companyId => {
      setLoading(true)
      setVisible(true)
      let company
      let error
      try {
        const result = await client.query({
          query: GET_COMPANY_FOR_UPDATE,
          variables: { id: companyId }
        })
        company = _get(result, 'data.company')
      } catch (e) {
        error = e
      }
      setLoading(false)
      if (company) {
        setCompanyId(companyId)
        setCompany(company)
      } else {
        setVisible(false)
        showErrors(error, I18n.t('companyError', companyTrOpt))
      }
    }
  }), [client])

  const closeModal = useCallback(() => setVisible(false), [])
  const form = useRef(null)
  const afterClose = useCallback(() => {
    setVisible(false)
    setCompanyId(null)
    setCompany(null)
    setAction('paidPlan')
    if (form && form.current) {
      form.current.reset()
    }
    setShowForm(false)
    onClose()
  }, [onClose])

  const onUpgradeCompletion = useCallback(async () => {
    if (_isFunction(refreshSessionState)) {
      await refreshSessionState()
    }
    closeModal()
  }, [refreshSessionState, closeModal])

  const [executeConvertToFreeTrial] = useMutation(CONVERT_PROSPECT_TO_FREE_TRIAL, { refetchQueries })
  const onQuestionOk = useCallback(async () => {
    if (action === 'paidPlan') {
      setShowForm(true)
    } else if (action === 'freeTrial') {
      setLoading(true)
      try {
        await executeConvertToFreeTrial({ variables: { companyId } })
        message.success(I18n.t('freeTrialSuccess', { ...trOpt, name: company.name }))
        await onUpgradeCompletion()
      } catch (e) {
        showErrors(e, I18n.t('freeTrialError', { ...trOpt, name: company.name }))
      }
      setLoading(false)
    }
  }, [action, companyId, company, executeConvertToFreeTrial, onUpgradeCompletion])

  const { loading: domainsLoading, error: domainError, data: { supportedDomains = [] } = {} } = useQuery(GET_SUPPORTED_DOMAINS, {
    skip: !visible
  })

  const [executeRecordCancellation] = useMutation(RECORD_PROSPECT_UPGRADE_CANCELLATION, { refetchQueries })
  const onCancel = useCallback(async () => {
    // Call Mutation to update risk report history analytics if company.riskReport.status is trial_requested
    if (_get(company, 'riskReport.status') === 'trial_requested') {
      try {
        let eventType = action
        if (action === 'paidPlan') {
          eventType = `${action}Step${showForm ? '2' : '1'}`
        }
        await executeRecordCancellation({ variables: { companyId: company.id, eventType } })
      } catch (e) {
      // This is a silent error for now as the MSP shouldn't know about this
      // Logged so Sentry can pick it up
        console.error('Recording prospect upgrade cancellation could not be recorded', e)
      }
    }
    closeModal()
  }, [action, showForm, company, executeRecordCancellation, closeModal])

  const onPaidSuccess = useCallback(async () => {
    message.success(I18n.t('paidSuccess', { ...trOpt, name: company.name }))
    await onUpgradeCompletion()
  }, [company, onUpgradeCompletion])

  const title = company && company.name ? I18n.t(showForm ? 'titlePaidName' : 'titleName', { ...trOpt, name: company.name }) : I18n.t('title', trOpt)
  const modalWidth = showForm ? 'auto' : 500

  return (
    <UpgradeModal
      visible={visible}
      title={title}
      onCancel={onCancel}
      afterClose={afterClose}
      footer={null}
      width={modalWidth}
    >
      <LoadingBlock fullScreen={false} loading={loading || domainsLoading} />
      {
        showForm
          ? (
            <>
              <ErrorAlerts {...{ companyId }} error={domainError} defaultError={I18n.t('domainsError', companyTrOpt)} />
              <EditCompanyModalForm
                ref={form}
                mutation={UPGRADE_PROSPECT_TO_PAID_TENANT}
                accountType='tenant'
                forceAccountType
                upgrade
                onSuccess={onPaidSuccess}
                failureMessage={I18n.t('paidError', { ...trOpt, name: company.name })}
                submitLabel={I18n.t('completeUpgrade', trOpt)}
                submitIcon='check-circle'
                showTitle={false}
                footer={renderUpgradePaidFooter}
                footerProps={{ setShowForm, onCancel }}
                contentLocaleOptions={CONTENT_LANGUAGE_OPTIONS.sort((a, b) => a.label.localeCompare(b.label))}
                {...{ company, supportedDomains, refetchQueries }}
                {...rest}
              />
            </>
          )
          : (
            <UpgradeQuestion {...{ action, setAction }} onOk={onQuestionOk} onCancel={onCancel} />
          )
      }
    </UpgradeModal>
  )
})

export default compose(
  EditCompanyConnector,
  withConsumer({ useHooks: true })
)(UpgradeProspectModal)
