import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import styled from 'styled-components'
import { generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import ViewEmailTemplateModal from '../../components/Modals/ViewEmailTemplateModal'
import { ModalPager, ListTableActions, LocalesCell } from '../../components/common'
import routes from '../../constants/routes'
import { processTableData } from '../../helpers'
import { NONE_FILTER_VALUE } from './TemplateHeader'
import { onListTableSelectAll, onListTableChange } from '../../helpers/listPages'
import { NO_LANGUAGE_VALUE, CONTENT_LANGUAGE_OPTIONS } from '../../constants/languages'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { CATEGORY_OPTIONS } from '../../constants/uPhish'

const trOpt = { scope: 'uPhish.emailTemplateBuilderTable' }

const NameLinkButton = styled(Button)`
  padding: 0;
`

const NameLink = ({ record, onClick: onClickProp = () => {} }) => {
  const { id, name } = record

  const onClick = useCallback(() => onClickProp(id), [onClickProp, id])

  return (
    <NameLinkButton type='link' onClick={onClick}>{name}</NameLinkButton>
  )
}

const ModalPagerPlaceholder = styled.div`
  height: 32px;
`

const EmailTemplateBuilderTable = ({
  records = [], loading = false,
  selectedIds = [], updateSelectedIds,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination,
  performAction = () => {},
  showOwner,
  activeId,
  updateActiveId,
  routeId,
  history,
  viewStorageId
}) => {
  const [showViewEmailTemplateModal, updateShowViewEmailTemplateModal] = useState(false)

  const openViewModel = useCallback(id => {
    updateActiveId(id)
    updateShowViewEmailTemplateModal(true)
  }, [updateActiveId, updateShowViewEmailTemplateModal])
  const closeModal = useCallback(() => updateShowViewEmailTemplateModal(false), [updateShowViewEmailTemplateModal])
  const afterModalClose = useCallback(() => {
    updateActiveId(null)
    history.push(routes.UPHISH_EMAIL_TEMPLATE_BUILDER)
  }, [updateActiveId, history])

  const openRecord = useCallback((id) => {
    history.push(generatePath(routes.UPHISH_EMAIL_TEMPLATE_BUILDER_VIEW, { email_template_id: id }))
  }, [history])

  const owners = useMemo(() => {
    if (!showOwner || records.length === 0) {
      return []
    }

    return [
      { value: 'usecure', text: I18n.t('common.managedByUsecure') },
      { value: 'client', text: I18n.t('clientEmailTemplates', trOpt) },
      ...records
        .reduce((owners, { companyId, companyName }) => {
          if (companyId !== 'usecure' && companyId !== null &&
            !owners.some(o => o.value === companyId)) {
            owners.push({ value: companyId, text: companyName })
          }
          return owners
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text))
    ]
  }, [records, showOwner])

  const handlePerformAction = useCallback(async (action, id) => {
    updateActiveId(id)
    performAction(action, [id])
  }, [performAction, updateActiveId])

  const renderActionsCell = useCallback((actions, record) => (
    <ListTableActions
      actions={actions}
      id={record.id}
      performAction={handlePerformAction}
    />
  ), [handlePerformAction])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}

    const columns = [{
      title: I18n.t('common.fields.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <NameLink record={record} onClick={openRecord}>{text}</NameLink>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortColumnKey === 'name' && order
    }]

    if (showOwner) {
      columns.push({
        title: I18n.t('common.fields.owner'),
        dataIndex: 'companyName',
        key: 'companyName',
        filters: owners,
        filterMultiple: true,
        onFilter: (value, record) => {
          if (value === 'client') {
            return record.companyId !== 'usecure'
          }
          return record.companyId === value
        },
        filteredValue: _get(filters, 'companyName', null),
        sorter: (a, b) => {
          if (a.companyId === 'usecure' && b.companyId !== 'usecure') {
            return -1
          } else if (a.companyId !== 'usecure' && b.companyId === 'usecure') {
            return 1
          } else if (a.companyId === 'usecure' && b.companyId === 'usecure') {
            return 0
          }
          return a.companyName.localeCompare(b.companyName)
        },
        sortOrder: sortColumnKey === 'companyName' && order
      })
    }

    columns.push({
      title: I18n.t('common.category'),
      dataIndex: 'category',
      key: 'category',
      filters: [
        ...CATEGORY_OPTIONS.map(({ value, label: text }) => {
          return ({
            value: value === 'none' ? NONE_FILTER_VALUE : value,
            text
          })
        }).sort((a, b) => {
          if (a.value === NONE_FILTER_VALUE) return -1
          if (b.value === NONE_FILTER_VALUE) return 1
          return a.text.localeCompare(b.text)
        })
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.categoryValue === value,
      filteredValue: _get(filters, 'category', null),
      sorter: (a, b) => {
        if (a.categoryValue === NONE_FILTER_VALUE && b.categoryValue !== NONE_FILTER_VALUE) {
          return 1
        } else if (a.categoryValue !== NONE_FILTER_VALUE && b.categoryValue === NONE_FILTER_VALUE) {
          return -1
        } else if (a.categoryValue === NONE_FILTER_VALUE && b.categoryValue === NONE_FILTER_VALUE) {
          return 0
        }
        return a.category.localeCompare(b.category)
      },
      sortOrder: sortColumnKey === 'category' && order
    }, {
      title: I18n.t('common.languagesParenPlural'),
      dataIndex: 'locales',
      key: 'locales',
      filters: [
        { value: NO_LANGUAGE_VALUE, text: I18n.t('common.none') },
        ...CONTENT_LANGUAGE_OPTIONS.sort((a, b) => a.label.localeCompare(b.label)).map(({ value, label: text }) => ({ value, text }))
      ],
      filterMultiple: true,
      onFilter: (value, record) => value === NO_LANGUAGE_VALUE
        ? (!record.locales || record.locales.length === 0)
        : (record.locales && record.locales.includes(value)),
      filteredValue: _get(filters, 'locales', null),
      render: locales => <LocalesCell {...{ locales }} />
    }, {
      title: '',
      dataIndex: 'actions',
      align: 'right',
      render: renderActionsCell
    })

    return columns
  }, [sorter, openRecord, renderActionsCell, showOwner, filters, owners])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedIds(selectedRowKeys), [updateSelectedIds])
  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds, filters, columns, records })
  }, [records, updateSelectedIds, filters, columns])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updatePagination, updateSorter, updateFilters, filters, onSelectAll])

  useEffect(() => {
    if (routeId) {
      openViewModel(routeId)
    } else {
      closeModal()
    }
  }, [routeId, openViewModel, closeModal])

  const { rowCount, currentRowNum, prevRow, nextRow } = useMemo(() => {
    let currentRowNum
    let prevRow = null
    let nextRow = null

    const rows = processTableData(columns, records)
    if (activeId && records.length > 1) {
      const index = rows.findIndex(({ id }) => id === activeId)
      if (index !== -1) {
        currentRowNum = index + 1
        prevRow = index - 1
        prevRow = prevRow < 0 ? rows.length - 1 : prevRow
        prevRow = (rows[prevRow] || { id: null }).id
        nextRow = index + 1
        nextRow = nextRow > rows.length - 1 ? 0 : nextRow
        nextRow = (rows[nextRow] || { id: null }).id
      }
    }

    return {
      rowCount: rows.length,
      currentRowNum,
      prevRow,
      nextRow
    }
  }, [activeId, records, columns])

  const viewModalPager = loading
    ? <ModalPagerPlaceholder />
    : (
      <ModalPager
        count={rowCount} current={currentRowNum}
        prev={prevRow} next={nextRow} onClick={openRecord}
      />
    )

  return (
    <>
      <ViewEmailTemplateModal
        id={activeId} visible={showViewEmailTemplateModal} setVisible={updateShowViewEmailTemplateModal}
        afterClose={afterModalClose}
        {...{ viewStorageId }}
        title={viewModalPager}
        builder
      />
      <Table
        columns={columns} dataSource={records} loading={loading} onChange={onTableChange} pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: onSelectChange,
          onSelectAll
        }}
      />
    </>
  )
}

export default EmailTemplateBuilderTable
