/* global localStorage */
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { Card as _Card, Empty, Layout, message, Select, Pagination } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { generatePath } from 'react-router-dom'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'
import _toInteger from 'lodash/toInteger'
import _uniq from 'lodash/uniq'
import I18n from 'i18n-js'
import _intersection from 'lodash/intersection'

import { ContentWrap, LoadingBlock, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts, PopUpContainer as BasePopUpContainer } from '../components/common'
import DuplicateCourseModal from '../components/Courses/DuplicateCourseModal'
import CourseTile, { CourseCard } from '../components/Courses/CourseTile'
import { GET_COURSES, GET_GAP_ANALYSIS_LOCALES } from '../components/Queries/Courses'
import { connect } from '../hocs'
import selectors from '../state/selectors'
import routes from '../constants/routes'
import EnrolLearnersOnCourseModal from '../components/Modals/EnrolLearnersOnCourseModal'
import { COURSE_CATEGORIES, COURSE_DIFFICULTY, COURSE_SUBJECTS } from '../constants/courses'
import { WRITABLE_COURSE_CARD_ACTIONS } from '../constants/actions'
import FilterField, { FilterResetButton } from '../components/common/FilterField'
import LocaleFilterField from '../components/common/LocaleFilterField'
import IntercomHeader from '../components/IntercomHeader'
import EnrolCourseUploadModal from '../components/Modals/EnrolCourseUploadModal'
const trOpt = { scope: 'courseLibrary' }

// The restricts the language filter to those we have content for when true
const RESTRICT_LANGUAGES = window.__USECURE_CONFIG__.REACT_APP_RESTRICT_LANGUAGE_TO_CONTENT === 'true'

const { Content } = Layout
const { Option } = Select

const ALL_FILTER_VALUE = '[[ALL]]'
const GAP_ANALYSIS_COURSE = {
  name: 'gapAnalysisQuestionnaire',
  difficulty: 1,
  subject: 'InfoSec',
  icon: 'check-square'
}
const PAGE_SIZE_OPTIONS = ['50', '100']

const Card = styled(_Card)`
  min-height: 500px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const CourseTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -7.5px;
  width: calc(100% + 15px);

  .ant-empty {
    margin: 50px auto 0;
  }

  ${CourseCard} {
    padding: 0 7.5px 15px;

    @media (max-width: 575px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 992px) {
    width: ${100 / 3}%;
    }
    @media (min-width: 1200px) {
      width: 25%;
    }
    @media (min-width: 1600px) {
      width: 20%;
    }
    @media (min-width: 2000px) {
      width: ${100 / 6}%;
    }
    @media (min-width: 2400px) {
      width: ${100 / 8}%;
    }
  }
`

const PopUpContainer = styled(BasePopUpContainer)`
  .ant-select-tree {
    padding: 0 10px;
  }

  .ant-select-tree li span.ant-select-tree-switcher {
    display: none;
  }
`

const PaginationContainer = styled.div`
margin: 20px 0 5px;
text-align: right;

.ant-pagination-options-size-changer.ant-select {
  margin-right: 7px;
}
`

const getOptions = (courses = [], prop, textObject = {}) =>
  _uniq(courses.map(({ [prop]: value }) => value)
    .filter(v => !_isNil(v)))
    .map(value => ({ value, label: textObject[value] || value }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .map(({ value, label }) => <Option key={value} {...{ value }}>{label}</Option>)

const CourseLibraryTiles = ({
  courses: rawCourses = [], userId, userCompanyId, setCourseCount = () => {}, error, defaultContentLocales,
  showContentLocaleCounts = true, loading, planValid, gaLocales = []
}) => {
  const popupContainerRef = useRef(null)
  const getPopupContainer = useCallback(() => {
    const { current: popupContainer } = popupContainerRef
    return popupContainer || document.body
  }, [popupContainerRef])

  const [activeCourse, setActiveCourse] = useState(null)
  const [enrolVisible, setEnrolVisible] = useState(false)
  const [gapAnalysisId, setGapAnalysisId] = useState(null)
  const [showDuplicateCourseOpen, updateDuplicateCourseModal] = useState(false)
  const [courses, setCourses] = useState([])
  const [contentLocaleCounts, setContentLocaleCounts] = useState({})
  const [coursesReady, setCoursesReady] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [showEnrolCourseUploadModal, setShowEnrolCourseUploadModal] = useState(false)

  const disabledActions = useMemo(() => !planValid ? WRITABLE_COURSE_CARD_ACTIONS : [], [planValid])

  useEffect(() => {
    const { id: gapAnalysisId = null } = rawCourses.find(({ difficulty }) => difficulty === 1) || {}
    const courses = [
      {
        ...GAP_ANALYSIS_COURSE,
        id: gapAnalysisId,
        name: I18n.t('gapAnalysisQuestionnaire', trOpt),
        actions: ['preview', 'enrol', 'bulk-enrol'],
        availableLocales: gaLocales
      },
      ...rawCourses.filter(({ difficulty }) => difficulty !== 1)
    ]
    setGapAnalysisId(gapAnalysisId)
    setCourses(courses)
    if (!loading && (RESTRICT_LANGUAGES || showContentLocaleCounts)) {
      setContentLocaleCounts(courses.reduce((acc, { availableLocales: locales }) => {
        if (_isArray(locales) && !_isEmpty(locales)) {
          locales.forEach(locale => {
            if (_isNil(acc[locale])) {
              acc[locale] = 1
            } else {
              acc[locale] += 1
            }
          })
        }
        return acc
      }, {}))
    } else {
      setContentLocaleCounts({})
    }
    setCoursesReady(true)
  }, [rawCourses, loading, showContentLocaleCounts, gaLocales])

  const performAction = useCallback((action, courseId) => {
    const course = courseId === gapAnalysisId
      ? { ...GAP_ANALYSIS_COURSE, id: gapAnalysisId, isGapAnalysis: true, name: I18n.t('gapAnalysisQuestionnaire', trOpt) }
      : courses.find(c => c.id === courseId)
    if (course) {
      setActiveCourse(course)
    }
    switch (action) {
      case 'preview':
        window.open(generatePath(routes.COURSE_LIBRARY_PREVIEW, { course_id: courseId }), '_blank')
        break
      case 'enrol':
        setEnrolVisible(true)
        break
      case 'bulk-enrol':
        setShowEnrolCourseUploadModal(true)
        break
      case 'duplicateCourse':
        updateDuplicateCourseModal(true)
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [courses, setActiveCourse, setEnrolVisible, setShowEnrolCourseUploadModal, gapAnalysisId])

  const [searchText, setSearchText] = useState('')
  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const [type, setType] = useState(ALL_FILTER_VALUE)
  const onTypeChange = useCallback(type => setType(type), [setType])
  const [difficulty, setDifficulty] = useState(ALL_FILTER_VALUE)
  const onDifficultyChange = useCallback(difficulty => setDifficulty(difficulty), [setDifficulty])
  const [subject, setSubject] = useState(ALL_FILTER_VALUE)
  const onSubjectChange = useCallback(subject => setSubject(subject), [setSubject])
  const [category, setCategory] = useState(ALL_FILTER_VALUE)
  const onCategoryChange = useCallback(category => setCategory(category), [setCategory])
  const [contentLocales, setContentLocales] = useState(defaultContentLocales || [])
  const onContentLocalesChange = useCallback(contentLocales => setContentLocales(contentLocales), [])

  const storageId = useMemo(() => `course-library|${userCompanyId}|${userId}`, [userId, userCompanyId])
  useEffect(() => {
    try {
      const filterString = localStorage.getItem(storageId)
      if (filterString) {
        const filter = JSON.parse(filterString)
        Object.keys(filter).forEach(key => {
          const value = filter[key]
          switch (key) {
            case 'type':
              setType(value)
              break
            case 'difficulty':
              setDifficulty(value)
              break
            case 'subject':
              setSubject(value)
              break
            case 'category':
              setCategory(value)
              break
            case 'searchText':
              setSearchText(value)
              break
            case 'contentLocales':
              setContentLocales(value)
              break
            case 'currentPage':
              setCurrentPage(value)
              break
            case 'pageSize':
              setPageSize(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const resetContentLocales = useCallback(() => setContentLocales(defaultContentLocales || []), [defaultContentLocales])
  const onResetClick = useCallback(() => {
    setType(ALL_FILTER_VALUE)
    setDifficulty(ALL_FILTER_VALUE)
    setSubject(ALL_FILTER_VALUE)
    setCategory(ALL_FILTER_VALUE)
    resetContentLocales()
  }, [resetContentLocales])

  const [hasFilters, setHasFilters] = useState(false)
  const [allowContentLocaleReset, setAllowContentLocaleReset] = useState(false)
  const [viewCourses, setViewCourses] = useState([])
  const [viewCoursesReady, setViewCoursesReady] = useState(false)
  useEffect(() => {
    if (!coursesReady) return
    const searchTerm = searchText.toLowerCase().trim()
    const hasDifficulty = difficulty !== ALL_FILTER_VALUE
    const hasSubject = subject !== ALL_FILTER_VALUE
    const hasCategory = category !== ALL_FILTER_VALUE
    const hasContentLocales = _isArray(contentLocales) && !_isEmpty(contentLocales)
    const viewCourses = courses.filter(course => {
      const { name, availableLocales: locales } = course
      const companyId = _get(course, 'company.id')
      let match = true

      if (searchTerm) {
        match = name && String(name).toLowerCase().includes(searchTerm)
      }

      // Custom to this company, treat partner courses as default
      if (match && type === 'core') {
        match = [1, 2, 3, 4].includes(course.difficulty)
      } else if (match && type === 'default') {
        match = companyId !== userCompanyId
      } else if (match && type === 'custom') {
        match = companyId === userCompanyId
      }

      if (match && hasDifficulty) {
        match = course.difficulty === difficulty
      }
      if (match && hasSubject) {
        match = course.subject === subject
      }
      if (match && hasCategory) {
        match = course.category === category
      }

      if (match && hasContentLocales) {
        match = _isArray(locales) && !_isEmpty(locales) && _intersection(locales, contentLocales).length > 0
      }

      return match
    })

    const allowContentLocaleReset = (
      defaultContentLocales
        ? !(defaultContentLocales.every(locale => contentLocales.includes(locale)) && contentLocales.every(locale => defaultContentLocales.includes(locale))) // Content locales differ from default
        : contentLocales.length > 0 // Has content locales but no default
    )

    setViewCourses(viewCourses)
    setCourseCount(viewCourses.length)
    setHasFilters(allowContentLocaleReset || [type, difficulty, category, subject].some(v => v !== ALL_FILTER_VALUE))
    setAllowContentLocaleReset(allowContentLocaleReset)
    setViewCoursesReady(true)
  }, [coursesReady, courses, searchText, type, userCompanyId, difficulty, category, subject, setCourseCount, gapAnalysisId, contentLocales, defaultContentLocales])

  useEffect(() => {
    try {
      localStorage.setItem(storageId, JSON.stringify({
        searchText, type, difficulty, category, subject, contentLocales, currentPage, pageSize
      }))
    } catch (e) {}
  }, [searchText, type, difficulty, category, subject, contentLocales, currentPage, pageSize, storageId])

  const { courseId, courseName, isGapAnalysis } = useMemo(() => {
    const { id: courseId = null, name = null, difficulty, isGapAnalysis = false } = activeCourse || {}
    let courseName = null
    if (isGapAnalysis) {
      courseName = I18n.t('courses.common.difficulties.gapAnalysis')
    } else if (name) {
      const difficultyName = [2, 3, 4].includes(difficulty) ? COURSE_DIFFICULTY[difficulty] : null
      courseName = `${name} ${difficultyName ? `(${difficultyName})` : ''}`.trim()
    }

    return {
      courseId,
      courseName,
      isGapAnalysis
    }
  }, [activeCourse])

  const { subjects = [], categories = [] } = useMemo(() => ({
    categories: getOptions(courses, 'category', COURSE_CATEGORIES),
    subjects: getOptions(courses, 'subject', COURSE_SUBJECTS)
  }), [courses])

  const [paginationReady, setPaginationReady] = useState(false)
  const onPageChange = useCallback((page) => {
    setCurrentPage(page)
  }, [setCurrentPage])
  const onShowSizeChange = useCallback((current, pageSize) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }, [setCurrentPage, setPageSize])

  const [coursesPage, setCoursesPage] = useState([])
  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const coursesPage = viewCourses.slice(startIndex, endIndex)
    setCoursesPage(coursesPage)
    setPaginationReady(true)
  }, [viewCourses, currentPage, pageSize])
  // Number of pages for current result set and page size
  const pageCount = Math.ceil(viewCourses.length / pageSize)
  // Number of pages for current result set on the smallest page size
  const maxPageCount = Math.ceil(viewCourses.length / _toInteger(PAGE_SIZE_OPTIONS[0]))

  useEffect(() => {
    if (!(viewCoursesReady && paginationReady)) return
    const pageCount = Math.ceil(viewCourses.length / pageSize)
    if (currentPage > pageCount) {
      setCurrentPage(1)
    }
  }, [viewCoursesReady, paginationReady, viewCourses, pageSize, currentPage, setCurrentPage])

  return (
    <>
      <EnrolCourseUploadModal
        courses={courses}
        courseId={courseId}
        visible={showEnrolCourseUploadModal}
        setVisible={() => setShowEnrolCourseUploadModal(v => !v)}
      />
      <PopUpContainer ref={popupContainerRef} />
      <ListHeader>
        <ListHeaderPanel align='left'>
          <FilterField width={100}>
            <label>{I18n.t('common.type')}:</label>
            <Select value={type} onChange={onTypeChange}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              <Option value='core'>{I18n.t('common.core')}</Option>
              <Option value='default'>{I18n.t('common.default')}</Option>
              <Option value='custom'>{I18n.t('common.custom')}</Option>
            </Select>
          </FilterField>
          <FilterField width={120}>
            <label>{I18n.t('courses.common.difficulty')}:</label>
            <Select value={difficulty} onChange={onDifficultyChange}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              {
                [2, 3, 4].map(value => (
                  <Option key={value} {...{ value }}>{COURSE_DIFFICULTY[value]}</Option>
                ))
              }
            </Select>
          </FilterField>
          <FilterField width={100}>
            <label>{I18n.t('common.fields.subject')}:</label>
            <Select value={subject} onChange={onSubjectChange}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              {subjects}
            </Select>
          </FilterField>
          <FilterField width={220}>
            <label>{I18n.t('common.category')}:</label>
            <Select value={category} onChange={onCategoryChange}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              {categories}
            </Select>
          </FilterField>
          <LocaleFilterField
            type='app'
            hasDefaultContentLocales={defaultContentLocales && defaultContentLocales.length > 0}
            {...{
              loading,
              showContentLocaleCounts,
              contentLocales,
              onContentLocalesChange,
              getPopupContainer,
              resetContentLocales,
              contentLocaleCounts,
              allowContentLocaleReset
            }}
          />
          {hasFilters && <FilterResetButton onClick={onResetClick} />}
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <SearchBar
            placeholder={I18n.t('searchCourses', trOpt)}
            value={searchText}
            allowClear
            onChange={onSearchChange}
          />
        </ListHeaderPanel>
      </ListHeader>
      <ErrorAlerts {...{ error }} />
      <CourseTiles>
        {
          _isEmpty(coursesPage)
            ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={I18n.t('noCoursesFound', trOpt)}
              />
            )
            : (
              coursesPage.map(course => (
                <CourseTile key={course.id} {...course} {...{ userCompanyId, performAction, getPopupContainer, disabledActions }} />
              ))
            )
        }
      </CourseTiles>
      {
        maxPageCount > 1 &&
          <PaginationContainer>
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              pageSize={pageSize}
              current={currentPage}
              onChange={onPageChange}
              total={viewCourses.length}
              showQuickJumper={pageCount > 10}
              showTotal={(total, range) => I18n.t('paginationTotal', { ...trOpt, rangeStart: range[0], rangeEnd: range[1], total })}
            />
          </PaginationContainer>
      }
      <>
        <EnrolLearnersOnCourseModal
          visible={enrolVisible}
          setVisible={setEnrolVisible}
          {...{ courseId, courseName, isGapAnalysis }}
        />
        <DuplicateCourseModal
          visible={showDuplicateCourseOpen} setVisible={updateDuplicateCourseModal}
          courses={activeCourse ? [activeCourse] : []}
          showCompanies={false}
        />
      </>
    </>
  )
}

const CourseLibrary = ({ userId, companyId: userCompanyId, locale: sessionLocale, planValid }) => {
  const [courseCount, setCourseCount] = useState(0)
  const { loading: coursesLoading, error: coursesError, data: coursesData } = useQuery(GET_COURSES, {
    variables: { restrictToPlan: true, withCompany: true, inCourseLibrary: true }
  })
  const { loading: gaLocalesLoading, error: gaLocalesError, data: gaLocalesData } = useQuery(GET_GAP_ANALYSIS_LOCALES)

  const { courses = [] } = coursesData || {}
  const { getGapAnalysisLocales: gaLocales = [] } = gaLocalesData || {}
  const loading = coursesLoading || gaLocalesLoading
  const error = []
  if (coursesError) {
    error.push(coursesError)
  }
  if (gaLocalesError) {
    error.push(gaLocalesError)
  }

  return (
    <ContentWrap>
      <Card>
        <Header>
          <IntercomHeader Size='h1' id='course-library-header'>{I18n.t('common.uLearn')} - {I18n.t('courseLibrary', trOpt)}</IntercomHeader>
          {!loading && <h1>{I18n.t('courseCount', { ...trOpt, count: courseCount })}</h1>}
        </Header>
        <Layout>
          <Content>
            <LoadingBlock fullScreen={false} {...{ loading }} />
            {!loading && (
              <CourseLibraryTiles
                defaultContentLocales={sessionLocale ? [sessionLocale] : null}
                {...{ courses, gaLocales, loading, userId, userCompanyId, setCourseCount, error, planValid }}
              />
            )}
          </Content>
        </Layout>
      </Card>
    </ContentWrap>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['userId', 'companyId', 'locale', 'planValid'])
  })
)(CourseLibrary)
