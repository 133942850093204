import React, { Component, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Card, Form, List, Modal, Popover } from 'antd'
import styled from 'styled-components'
import { transparentize } from 'polished'
import I18n from 'i18n-js'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { base64EncodeJSON } from '../../helpers'
import UnlayerEditor from './UnlayerEditor'
import { connect } from '../../hocs'
import { sessionLocale } from '../../state/selectors/session'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const PROJECT_ID = window.__USECURE_CONFIG__.REACT_APP_UNLAYER_PROJECT_ID
const landingPageURL = window.__USECURE_CONFIG__.REACT_APP_LANDING_PAGE_URL
const trOpt = { scope: 'mutationForm.mutationFormUnlayer' }
const getInsertVariableCopy = locale => I18n.t('mutationForm.common.insertVariable', { locale })

const { Meta } = Card

const EditorContainer = styled.div`
  height: 750px;
  position: relative;

  @media (max-height: 800px) {
    height: 500px;
  }

  &> div {
    height: 100%;
  }
`

const ButtonWrap = styled.div`
  height: 0;
  position: relative;
`
const ButtonContainer = styled.div`
  position: absolute;
  top: -40px;
  right: 0px;

  .ant-btn {
    margin-left: 5px;
  }
`

const FailureOverlay = styled.div`
  background-color: ${({ theme }) => transparentize(0.2, theme.white)};
  height: 100%;
  position: absolute;
  width: 100%;
`

const LayoutContainer = styled.div`
  background-color: ${({ theme }) => transparentize(0.2, theme.white)};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 15px 15px;
  position: absolute;
  width: 100%;

  .ant-list {
    flex: 1;
    overflow: auto;
    padding: 0 15px 15px;
  }
`

const LayoutHeader = styled.div`
  position: relative;

  h3 {
    text-align: center;
  }

  .ant-btn {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`

const LayoutCardComponent = styled(Card)`
  &.ant-card-hoverable {
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }
`

const LayoutCardImage = styled.div`
  background-image: url('${({ src }) => src}');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 180px;
  margin-top: 10px;
`

const LayoutCard = ({ disabled = false, layout, onClick: onClickProp = () => {} }) => {
  const { name, icon, design } = layout || {}
  const onClick = useCallback(() => {
    if (!disabled) {
      onClickProp(design)
    }
  }, [onClickProp, design, disabled])
  return (
    <LayoutCardComponent
      hoverable
      cover={<LayoutCardImage src={icon || '/images/templates/custom-template.png'} />}
      {...{ onClick, disabled }}
    >
      <Meta title={name} />
    </LayoutCardComponent>
  )
}

const LayoutGrid = ({ disabled, layouts = [], onClick = () => {}, onCloseClick = () => {} }) => {
  const renderLayout = useCallback(layout => (
    <List.Item>
      <LayoutCard {...{ disabled, layout, onClick }} />
    </List.Item>
  ), [onClick, disabled])

  return (
    <LayoutContainer>
      <LayoutHeader>
        <h3>{I18n.t('chooseYourLayout', trOpt)}</h3>
        <Button onClick={onCloseClick}>{I18n.t('common.close')}</Button>
      </LayoutHeader>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={[
          {
            id: 'no-layout',
            name: I18n.t('createYourOwn', trOpt),
            design: null,
            icon: '/images/icons/uphish/layouts/freeform.png'
          },
          ...layouts
        ]}
        renderItem={renderLayout}
      />
    </LayoutContainer>
  )
}

class MutationFormUnlayer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loaded: false,
      initialised: false,
      failed: false,
      showLayout: _isNil(_get(this, 'props.value.design')),
      switching: false,
      showEditor: true,
      showHelp: false,
      resetRequired: false
    }

    this.editor = React.createRef()

    this.handleCloseLayoutPickerClick = this.handleCloseLayoutPickerClick.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleLayoutClick = this.handleLayoutClick.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.handleOpenLayoutPickerClick = this.handleOpenLayoutPickerClick.bind(this)
    this.refresh = this.refresh.bind(this)
    this.handleOpenHelpClick = this.handleOpenHelpClick.bind(this)
    this.handleHelpVisibleChange = this.handleHelpVisibleChange.bind(this)
  }

  // Unlayer locales
  // Docs: https://docs.unlayer.com/docs/localization
  // Repo : https://github.com/unlayer/translations
  get locale () {
    const { locale = DEFAULT_LANGUAGE } = this.props
    switch (locale) {
      case 'fr':
        return 'fr-FR'
      case 'fr-CA':
        return locale
      case 'de':
        return 'de-DE'
      case 'nl':
        return 'nl-NL'
      case 'it':
        return 'it-IT'
      case 'es':
        return 'es-ES'
      case 'cs':
        return 'cs-CZ'
      case 'zh':
        return 'zh-CN'
      default:
        return 'en-US'
    }
  }

  async waitForUnlayerRender () {
    return new Promise((resolve, reject) => {
      const start = Date.now()
      let rendered = false
      const wait = setInterval(() => {
        const timedOut = (Date.now() - start) > 5000
        rendered = !_isNil(_get(this, 'editor.current'))
        if (rendered || timedOut) {
          clearInterval(wait)
          if (rendered) {
            resolve()
          } else {
            reject(new Error(I18n.t('unlayerHasNotRendered', trOpt)))
          }
        }
      }, 50)
    })
  }

  async handleLoad () {
    try {
      await this.waitForUnlayerRender()
      const { current: editor } = this.editor
      if (!editor) {
        return
      }
      const design = _get(this, 'props.value.design')
      if (design) {
        editor.loadDesign(design)
      }
      editor.addEventListener('design:updated', this.onUpdate)
      const self = this
      editor.addEventListener('design:loaded', function (data) {
        console.log('design:loaded')
        // Delayed onUpdate to catch any issues in the initial HTML export from the editor after design load
        // Resolves an issue where the exporter in a custom tool isn't ready yet
        setTimeout(() => {
          self.onUpdate(data)
        }, 500)
      })

      this.setState({ loaded: true })
    } catch (e) {
      this.setState({ failed: true })
    }
  }

  refresh () {
    const design = _get(this, 'props.value.design')
    if (design) {
      this.loadDesign(design)
      this.closeLayoutPicker()
    } else {
      this.resetEditor()
    }
  }

  // Unlayer doesn't have a reset method so this function triggers a state update
  // to give it a kick by quickly hiding then showing the editor which reloads it in a blank state provided this field has no value
  resetEditor () {
    this.setState({ showEditor: false, initialised: false, resetRequired: false }, () => {
      setTimeout(() => this.setState({ showEditor: true }), 200)
    })
  }

  onChange (data) {
    const { initialised } = this.state
    const value = _pick(data, ['design', 'html'])
    value.html = value.html.replace(/\n|\t/g, '')

    this.props.onChange(this.props.id, {
      ...value,
      encoded: base64EncodeJSON(value),
      initialised
    })
    if (!initialised) {
      this.setState({ initialised: true })
    }
  }

  onUpdate () {
    if (this.editor.current) {
      this.editor.current.exportHtml(this.onChange)
    }
  }

  handleLayoutClick (design) {
    if (this.state.switching) {
      Modal.confirm({
        title: I18n.t('layoutChangeConfirmDialogTitle', trOpt),
        content: I18n.t('layoutChangeConfirmDialogBody', trOpt),
        okText: I18n.t('common.yes'),
        cancelText: I18n.t('common.no'),
        onOk: () => this.updateLayout(design)
      })
    } else {
      this.updateLayout(design)
    }
  }

  updateLayout (design) {
    if (design) {
      this.loadDesign(design)
    } else if (this.state.switching) {
      this.props.onChange(this.props.id, null)
      this.resetEditor()
    }
    this.closeLayoutPicker()
  }

  handleOpenLayoutPickerClick () {
    this.openLayoutPicker()
  }

  openLayoutPicker () {
    this.setState({ showLayout: true, switching: true })
  }

  handleCloseLayoutPickerClick () {
    this.closeLayoutPicker()
  }

  closeLayoutPicker () {
    this.setState({ showLayout: false, switching: false })
  }

  handleOpenHelpClick () {
    this.setShowHelp(true)
  }

  handleHelpVisibleChange (showHelp) {
    this.setState({ showHelp })
  }

  setShowHelp (showHelp) {
    this.setState({ showHelp })
  }

  loadDesign (design) {
    const { current: editor } = this.editor
    if (!editor) {
      return
    }
    // react-email-editor sometimes errors saying unlayer is undefined being instanitated
    try {
      editor.loadDesign(design)
    } catch (e) {}
  }

  componentDidUpdate (prevProps, prevState) {
    const { loaded, resetRequired } = this.state
    const { customFonts } = this.props
    const { customFonts: prevCustomFonts } = prevProps
    const fontsUpdated = !_isEqual(customFonts, prevCustomFonts)
    // Reinitialise editor if this.props.customFonts has changed whilst retaining value
    if (loaded && (resetRequired || fontsUpdated)) {
      this.resetEditor()
    } else if (!loaded && fontsUpdated) {
      this.setState({ resetRequired: true })
    }
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, formItemStyle, required, layouts, helpTitle, helpContent, customFonts } = this.props
    const { failed, loaded, showLayout, showEditor, showHelp } = this.state

    const hasLayouts = !_isEmpty(layouts)
    const hasHelp = Boolean(helpTitle && helpContent)
    const showButtons = (hasHelp || hasLayouts) && !(hasLayouts && showLayout)

    return (
      <Form.Item label={label} style={formItemStyle} required={required}>
        <EditorContainer>
          {
            !failed && loaded && showLayout && hasLayouts && (
              <LayoutGrid
                disabled={!loaded}
                onClick={this.handleLayoutClick}
                onCloseClick={this.handleCloseLayoutPickerClick}
                {...{ layouts }}
              />
            )
          }
          {
            failed && (
              <FailureOverlay>
                <Alert
                  type='error'
                  message={I18n.t('editorStartError', trOpt)}
                />
              </FailureOverlay>
            )
          }
          {
            showEditor &&
              <div>
                {
                  showButtons && (
                    <ButtonWrap>
                      <ButtonContainer>
                        {hasLayouts && <Button icon='swap' onClick={this.handleOpenLayoutPickerClick}>{I18n.t('switchTemplate', trOpt)}</Button>}
                        {
                          hasHelp && (
                            <Popover
                              title={helpTitle}
                              content={helpContent}
                              trigger='click'
                              placement='bottomRight'
                              visible={showHelp}
                              onVisibleChange={this.handleHelpVisibleChange}
                            >
                              <Button icon='question-circle' onClick={this.handleOpenHelpClick}>{I18n.t('common.help')}</Button>
                            </Popover>
                          )
                        }
                      </ButtonContainer>
                    </ButtonWrap>
                  )
                }
                <UnlayerEditor
                  ref={this.editor}
                  minHeight='100%'
                  projectId={PROJECT_ID}
                  displayMode={this.props.displayMode}
                  designMode={this.props.designMode}
                  onLoad={this.handleLoad}
                  options={{
                    tools: {
                      html: {
                        enabled: false
                      },
                      ...(this.props.tools || {})
                    },
                    // [[ & ]] are the default delimiters which removed our link tags as unlayer removes any text that looks like a design tag
                    designTagsConfig: {
                      delimiter: ['{{', '}}']
                    },
                    locale: this.locale,
                    translations: {
                      'en-US': {
                        'labels.merge_tags': getInsertVariableCopy('en')
                      },
                      'fr-FR': {
                        'labels.merge_tags': getInsertVariableCopy('fr')
                      },
                      'fr-CA': {
                        'labels.merge_tags': getInsertVariableCopy('fr-CA')
                      },
                      'de-DE': {
                        'labels.merge_tags': getInsertVariableCopy('de')
                      },
                      'it-IT': {
                        'labels.merge_tags': getInsertVariableCopy('it')
                      },
                      'nl-NL': {
                        'labels.merge_tags': getInsertVariableCopy('nl')
                      },
                      'es-ES': {
                        'labels.merge_tags': getInsertVariableCopy('es')
                      },
                      'cs-CZ': {
                        'labels.merge_tags': getInsertVariableCopy('cs')
                      },
                      'zh-CN': {
                        'labels.merge_tags': getInsertVariableCopy('zh')
                      }
                    },
                    mergeTags: this.props.mergeTags,
                    linkTypes: this.props.linkTypes,
                    fonts: {
                      showDefaultFonts: true,
                      customFonts: [
                        ...(customFonts || []),
                        // The label and font family name of custom fonts must match otherwise imports fail
                        {
                          label: I18n.t('fonts.expertSansBold', trOpt),
                          value: '\'Expert Sans Bold\', Helvetica, sans-serif',
                          url: `${landingPageURL}/css-fonts/ExpertSansBold.css`
                        },
                        {
                          label: I18n.t('fonts.expertSansLight', trOpt),
                          value: '\'Expert Sans Light\', Helvetica, sans-serif',
                          url: `${landingPageURL}/css-fonts/ExpertSansLight.css`
                        },
                        {
                          label: I18n.t('fonts.expertSansRegular', trOpt),
                          value: '\'Expert Sans Regular\', Helvetica, sans-serif',
                          url: `${landingPageURL}/css-fonts/ExpertSansRegular.css`
                        },
                        {
                          label: I18n.t('fonts.frutigerBold', trOpt),
                          value: '\'Frutiger Bold\', Arial, \'Helvetica Neue\', Helvetica, sans-serif',
                          url: `${landingPageURL}/css-fonts/FrutigerBold.css`
                        },
                        {
                          label: I18n.t('fonts.frutigerLight', trOpt),
                          value: '\'Frutiger Light\', Arial, \'Helvetica Neue\', Helvetica, sans-serif',
                          url: `${landingPageURL}/css-fonts/FrutigerLight.css`
                        },
                        {
                          label: I18n.t('fonts.googleSans', trOpt),
                          value: '\'Google Sans\',arial,sans-serif',
                          url: `${landingPageURL}/css-fonts/GoogleSans.css`
                        },
                        {
                          label: I18n.t('fonts.itunesFont', trOpt),
                          value: 'Calibri,Helvetica,sans-serif,EmojiFont,Apple Color Emoji,Segoe UI Emoji,NotoColorEmoji,Segoe UI Symbol,Android Emoji,EmojiSymbols'
                        },
                        {
                          label: I18n.t('fonts.microsoftUIFont', trOpt),
                          value: '\'Segoe UI Webfont\',-apple-system,\'Helvetica Neue\',\'Lucida Grande\',\'Roboto\',\'Ebrima\',\'Nirmala UI\',\'Gadugi\',\'Segoe Xbox Symbol\',\'Segoe UI Symbol\',\'Meiryo UI\',\'Khmer UI\',\'Tunga\',\'Lao UI\',\'Raavi\',\'Iskoola Pota\',\'Latha\',\'Leelawadee\',\'Microsoft YaHei UI\',\'Microsoft JhengHei UI\',\'Malgun Gothic\',\'Estrangelo Edessa\',\'Microsoft Himalaya\',\'Microsoft New Tai Lue\',\'Microsoft PhagsPa\',\'Microsoft Tai Le\',\'Microsoft Yi Baiti\',\'Mongolian Baiti\',\'MV Boli\',\'Myanmar Text\',\'Cambria Math\''
                        },
                        {
                          label: I18n.t('fonts.marketSans', trOpt),
                          value: '\'Market Sans\', Arial, sans-serif',
                          url: `${landingPageURL}/css-fonts/MarketSans.css`
                        },
                        {
                          label: I18n.t('fonts.myriadSetPro', trOpt),
                          value: '\'Myriad Set Pro\',\'Lucida Grande\',Helvetica,Arial,Verdana,sans-serif',
                          url: `${landingPageURL}/css-fonts/MyriadSetPro.css`
                        },
                        {
                          label: I18n.t('fonts.roboto', trOpt),
                          value: 'Roboto',
                          url: `${landingPageURL}/css-fonts/Roboto.css`
                        },
                        {
                          label: I18n.t('fonts.slackLato', trOpt),
                          value: '\'Slack Lato\', \'Lucida Grande\', sans-serif',
                          url: `${landingPageURL}/css-fonts/SlackLato.css`
                        }
                      ]
                    },
                    blocks: this.props.blocks,
                    customJS: this.props.customJS
                  }}
                  columnLayouts={[
                    [2, 2, 2, 2, 2, 2], [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                    [2, 2, 4, 2, 2], [3, 6, 3],
                    [3, 3, 6], [6, 3, 3],
                    [2, 2, 2, 2, 4], [4, 2, 2, 2, 2],
                    [1, 1, 1, 1, 1, 1, 1, 1, 4], [4, 1, 1, 1, 1, 1, 1, 1, 1],
                    [1, 1, 1, 1, 1, 1, 1, 1, 1, 3], [3, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                    [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2], [2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                  ]}
                />
              </div>
          }
        </EditorContainer>
      </Form.Item>
    )
  }
}

MutationFormUnlayer.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  formItemStyle: PropTypes.object
}

MutationFormUnlayer.defaultProps = {
  id: '',
  label: null
}

export default connect(
  state => ({ locale: sessionLocale(state) })
)(MutationFormUnlayer)
