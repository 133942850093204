import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.riskReportParentEmail' }

class RiskReportParentEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'riskReportParent'
    this.showSentTestButton = true
    this.usingWhiteTheme = true
    this.generateSubjectFields([{ id: 'subject', label: 'common.fields.emailSubject' }])
  }

  get headerId () {
    return 'settings-risk-report-parent-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    const { enableProspects, enableRiskReportTenants } = this.props
    let key = 'description'
    if (enableProspects && enableRiskReportTenants) {
      key = 'descriptionBoth'
    } else if (enableRiskReportTenants) {
      key = 'descriptionCustomer'
    }
    return I18n.t(key, trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(RiskReportParentEmail)
