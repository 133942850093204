import React, { useCallback, useMemo, useEffect } from 'react'
import { Button, Dropdown, Icon, Menu } from 'antd'
import I18n from 'i18n-js'
import styled from 'styled-components'

import connect from '../../hocs/connect'
import { creators as sessionActions } from '../../state/actions/session'
import { sessionLocale, sessionInitialised } from '../../state/selectors/session'
import DemoMode from '../../helpers/DemoMode'
import { DEFAULT_LANGUAGE, langTrOpt, LANGUAGES } from '../../constants/languages'

const LanguageMenu = styled(Menu)`
  height: 100vh - 100px;
  max-height: 400px;
  overflow-y: auto;
`

const LanguageMenuItem = ({ code, name, onClick = () => {} }) => {
  const onItemClick = useCallback(() => onClick(code), [code, onClick])

  return (
    <div onClick={onItemClick}>{name}</div>
  )
}

const _LanguageDropdown = ({ className, value, languages = LANGUAGES, onChange, placement = 'bottomCenter', trigger = 'click', disabled = false }) => {
  const selectedLanguage = useMemo(() => (languages.find(l => l.code === value) || {}), [value, languages])
  const { code = DEFAULT_LANGUAGE, name = I18n.t(DEFAULT_LANGUAGE, langTrOpt) } = selectedLanguage

  const onLanguageChange = useCallback(code => onChange(code), [onChange])

  const overlay = useMemo(() => (
    <LanguageMenu
      selectedKeys={[code]}
    >
      {
        [...languages]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ code, name }) => (
            <Menu.Item key={code}>
              <LanguageMenuItem code={code} name={name} onClick={onLanguageChange} />
            </Menu.Item>
          ))
      }
    </LanguageMenu>
  ), [languages, onLanguageChange, code])

  trigger = Array.isArray(trigger) ? trigger : [trigger]

  return (
    <Dropdown {...{ className, overlay, placement, trigger, disabled }}>
      <Button>
        <div className='usecure-language-dropdown-value'>
          <LanguageMenuItem code={code} name={name} />
        </div>
        <Icon type='down' />
      </Button>
    </Dropdown>
  )
}
const LanguageDropdown = styled(_LanguageDropdown)`
  .ant-button {
    padding-right: 10px;
  }
  .usecure-language-dropdown-value {
    display: inline-block;
    margin-right: 5px;
  }
`

const DEBUG_KEYS = { code: 'debugKeys', name: 'Debug Keys' }
// For language switching testing - not intended for final UI use
// Connected to global state so update triggers a re-render for changing language instantaneously
// Useful for components that apply additional logic for different languages (none as yet)
// Issues
// - If this were used on LearnerCourse's language switcher then a course preview would change the main UI. More of a general issue with using one i18n instance
// - Memoized renderers will not be executed when locale changes unless locale is a dependency
// - Unlayer doesn't re-render when the locale is changed
export const LanguageDropdownI18nTest = connect(
  state => ({ locale: sessionLocale(state) }),
  dispatch => ({
    setLocale: locale => dispatch(sessionActions.updateLocale(locale))
  })
)(({ locale, setLocale, includeDebugKeys = false, ...props }) => {
  return (
    <LanguageDropdown
      languages={includeDebugKeys ? [...LANGUAGES, DEBUG_KEYS] : LANGUAGES}
      {...props} onChange={setLocale} value={locale}
    />
  )
})

export const DemoLocaleListener = connect(
  state => ({ locale: sessionLocale(state), initialised: sessionInitialised(state) }),
  dispatch => ({
    setLocale: locale => dispatch(sessionActions.updateLocale(locale))
  })
)(({ locale, setLocale, initialised }) => {
  useEffect(() => {
    DemoMode.setLocale(locale)
  }, [locale])
  useEffect(() => {
    if (!initialised) return
    DemoMode.removeLocaleListener('demoLocaleListener')
    DemoMode.addLocaleListener('demoLocaleListener', function (locale) {
      setLocale(locale)
    })

    return function () {
      DemoMode.removeLocaleListener('demoLocaleListener')
    }
  }, [setLocale, initialised])

  return null
})

export default LanguageDropdown
