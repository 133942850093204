import React, { useCallback, useRef, useMemo, useEffect, useState, useImperativeHandle } from 'react'
import { message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'

import { GET_COURSES } from '../../Queries/Courses'
import { LoadingBlock, ErrorAlerts } from '../../common'
import {
  UPDATE_COMPANY_COMPULSORY_COURSES, GET_COMPULSORY_COURSES,
  UPDATE_DEFAULT_TENANT_COMPULSORY_COURSES, GET_DEFAULT_TENANT_COMPULSORY_COURSES
} from '../../Queries/Companies'
import { SettingsFormElement } from '../SettingsForm'
import { withRefreshSessionState } from '../../../hocs'

const modalTrOpt = { scope: 'learners.enrolOnCourseModal' }
const trOpt = { scope: 'settings.compulsoryCourses' }

const CompulsoryCourses = React.forwardRef(({ defaultTenant, refreshSessionState, disableSubmit }, ref) => {
  const form = useRef(null)
  const [, setForceRender] = useState(null)
  const {
    data: compulsoryCoursesData, loading: compulsoryCoursesLoading, error: compulsoryCoursesError,
    refetch: refetchCompulsoryCourses
  } = useQuery(
    defaultTenant ? GET_DEFAULT_TENANT_COMPULSORY_COURSES : GET_COMPULSORY_COURSES,
    { fetchPolicy: 'network-only' }
  )

  const { data: coursesData, loading: coursesLoading, error: coursesError } = useQuery(GET_COURSES, {
    variables: { excludeGapAnalysis: true, restrictToPlan: true, withCompany: true }
  })

  const onSuccess = useCallback(async () => {
    message.success(I18n.t('successMessage', trOpt))
    await refetchCompulsoryCourses()
    await refreshSessionState()
    resetFields()
  }, [refetchCompulsoryCourses, refreshSessionState])

  const resetFields = () => {
    if (form && form.current) form.current.resetFields()
    setForceRender(Date.now())
  }
  const selectedCourses = useMemo(() => (
    (
      defaultTenant
        ? _get(compulsoryCoursesData, 'getDefaultTenantCompulsoryCourses')
        : _get(compulsoryCoursesData, 'company.compulsoryCourses')
    ) || []
  ), [compulsoryCoursesData, defaultTenant])

  const courseOptions = useMemo(() => {
    const courses = _get(coursesData, 'courses') || []
    return courses
      // Sort by difficulty then sort by displayName
      .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
      .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))
  }, [coursesData])

  useEffect(() => {
    if (form && form.current) {
      form.current.replaceValues({ courseIds: selectedCourses }, true)
    }
  }, [selectedCourses, form])

  const fields = [
    {
      id: 'courseIds',
      label: I18n.t('courses', modalTrOpt),
      type: 'multiSelect',
      allowClear: true,
      placeholder: I18n.t('selectCourses', modalTrOpt),
      options: courseOptions,
      mutateValue: values => values || [],
      defaultValue: selectedCourses
    }
  ]

  const onChange = () => setForceRender(Date.now())
  const hasChanged = useCallback(() => form.current?.hasChanged() === true, [form])

  useImperativeHandle(ref, () => ({
    hasChanged: hasChanged
  }), [hasChanged])

  return (
    <SettingsFormElement
      headerId='settings-compulsory-courses-header'
      title={I18n.t('title', trOpt)}
      desc={<p>{I18n.t('description', trOpt)}</p>}
      headerExtra={
        <>
          <LoadingBlock loading={coursesLoading || compulsoryCoursesLoading} fullScreen={false} />
          <ErrorAlerts error={[coursesError, compulsoryCoursesError]} />
        </>
      }
      formProps={{
        mutation: defaultTenant ? UPDATE_DEFAULT_TENANT_COMPULSORY_COURSES : UPDATE_COMPANY_COMPULSORY_COURSES,
        onSuccess,
        onChange,
        failureMessage: I18n.t('errorMessage', trOpt),
        submitLabel: I18n.t('common.save'),
        disableSubmitIfInvalid: false,
        skipResetFieldsOnSubmit: true,
        fields,
        ref: form,
        refetchQueries: [{
          query: defaultTenant ? GET_DEFAULT_TENANT_COMPULSORY_COURSES : GET_COMPULSORY_COURSES
        }]
      }}
      {...{ disableSubmit }}
      hasChanged={hasChanged()}
    />
  )
})

export default withRefreshSessionState(CompulsoryCourses)
