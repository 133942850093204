import React, { useState, useCallback, useEffect, useMemo } from 'react'
import I18n from 'i18n-js'
import { Button, Empty } from 'antd'
import styled from 'styled-components'
import { NetworkStatus } from 'apollo-client'
import { useLazyQuery } from '@apollo/react-hooks'
import _debounce from 'lodash/debounce'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'

import { ListHeader, ListHeaderPanel, LoadingBlock as _LoadingBlock, SearchBar, LoaderContainer } from '../common'
import CompaniesView from './CompaniesView'
import useLocalStorage from '../../hooks/useLocalStorage'
import { GET_USERVICE_QUICK_SEARCH } from '../Queries/Companies'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'

const trOpt = { scope: 'uService' }
const INVISIBLE_NETWORK_STATUS = [NetworkStatus.poll, NetworkStatus.refetch]
const RESULTS_LIMIT = 50

const SearchBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
  
  ${SearchBar} {
    margin-bottom: 10px;
    max-height: unset;
    max-width: 700px;
  }
`

const SearchContainer = styled.div`
  min-height: 295px;
  position: relative;
`

const LoadingBlock = styled(_LoadingBlock)`
  ${LoaderContainer} {
    align-items: flex-start;
  }
`

const UServiceQuickSearch = ({
  companyId,
  userId,
  accountType,
  userAccountType,
  showExternalId,
  role,
  path,
  theme,
  enableProspects,
  enableRiskReportTenants,
  disableTenantDeletion,
  disableProspectDeletion,
  refetchQueries: refetchQueriesProp,
  upgradeProspectRefetchQueries,
  pollingEnabled,
  setPollingEnabled
}) => {
  // The results area will go into a loading state after a new search or manual refresh.
  // Refetching after a mutation and polling do not trigger loading state. Use of refetch should be restricted accordingly to avoid unexpected loading blips after a company update via this view.
  const [searchTerm, updateSearchTerm] = useState('')
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)
  const [loading, setLoading] = useState(false)
  const [queryVariables, setQueryVariables] = useState(null)
  const searchActive = !_isNil(queryVariables?.searchTerm) && _isString(searchTerm) && searchTerm.length > 0
  const [runQuickSearch, { data, error, loading: queryLoading, refetch, networkStatus }] = useLazyQuery(GET_USERVICE_QUICK_SEARCH, {
    fetchPolicy: 'no-cache',
    // The query should poll using the last set of variables supplied to runQuickSearch
    pollInterval: (searchActive && pollingEnabled) ? QUERY_POLL_INTERVAL : 0,
    notifyOnNetworkStatusChange: false
  })

  const visibleQueryLoading = queryLoading && !INVISIBLE_NETWORK_STATUS.includes(networkStatus)
  const companies = ((searchActive && !queryLoading) ? data?.uServiceCompanyQuickSearch : null) ?? []
  const refetchQueries = useCallback(() => {
    // The quick search query is not cached so a mutation refetchQueries will not affect the useLazyQuery above
    // Calling refetch manually solves the problem with triggering a loading state
    if (queryVariables?.searchTerm) {
      refetch(queryVariables)
    }
    return refetchQueriesProp()
  }, [refetchQueriesProp, queryVariables, refetch])
  useEffect(() => {
    if (!INVISIBLE_NETWORK_STATUS.includes(networkStatus)) {
      if (queryLoading) {
        setLoading(true)
      } else {
        const companies = data?.uServiceCompanyQuickSearch ?? []
        if (companies.length === 0) {
          // No results so display empty state immediately
          setLoading(false)
        } else {
          // Delay setting loading to false to make the display of result less abrupt
          // CompaniesView processes its companies before display which introduces a delay between the query completing and results appearing on screen
          setTimeout(() => setLoading(false), 500)
        }
      }
    }
  }, [queryLoading, data, networkStatus])

  const updateQueryVariables = useCallback(
    _debounce(
      searchTerm => {
        // CL - Decided to go with non empty search term rather than minimum character count to avoid false negatives.
        // Some MSPs use acronyms instead of their customer's full company names which would not be returned if the minimum was too low.
        if (_isString(searchTerm) && searchTerm.length > 0) {
          const queryVariables = { searchTerm, accountType, limit: RESULTS_LIMIT }
          setQueryVariables(queryVariables)
          // Run new search
          runQuickSearch({ variables: queryVariables })
        } else {
          setQueryVariables(null)
        }
      }, 500
    ),
    [accountType, runQuickSearch]
  )
  useEffect(() => {
    if (_isString(searchTerm) && searchTerm.length > 0) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    updateQueryVariables(searchTerm)
  }, [updateQueryVariables, searchTerm])

  const storageId = useMemo(() => `uService|${companyId}|${userId}${accountType ? `|${accountType}` : ''}|quickSearch`, [userId, companyId, accountType])
  const { updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchTerm: updateSearchTerm,
      filters: updateFilters,
      sorter: updateSorter
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      searchTerm, filters, sorter
    })
  }, [updateLocalStorage, searchTerm, filters, sorter])

  const onSearchChange = useCallback(event => {
    updateSearchTerm(event.target.value)
  }, [updateSearchTerm])

  const handleRefreshClick = useCallback(() => {
    if (queryVariables?.searchTerm) {
      // Re-run current search manually
      runQuickSearch({ variables: queryVariables })
    }
  }, [runQuickSearch, queryVariables])
  const handleClearAllFiltersClick = useCallback(() => {
    updateFilters(null)
  }, [])

  return (
    <>
      <SearchBarContainer>
        <SearchBar
          placeholder={I18n.t('searchForACompany', trOpt)}
          value={searchTerm}
          allowClear
          onChange={onSearchChange}
          size='large'
        />
        <div>{I18n.t('quickSearchExtra', { ...trOpt, count: RESULTS_LIMIT })}</div>
      </SearchBarContainer>
      <SearchContainer>
        {!visibleQueryLoading && searchActive && companies.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={I18n.t('noCompaniesFound', trOpt)}
          />
        )}
        {!visibleQueryLoading && searchActive && companies.length > 0 && (
          <ListHeader align='right'>
            <ListHeaderPanel align='right'>
              <Button icon={loading ? 'loading' : 'filter'} ghost type='danger' disabled={loading} onClick={handleClearAllFiltersClick}>{I18n.t('common.clearAllFilters')}</Button>
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </ListHeaderPanel>
          </ListHeader>
        )}
        <CompaniesView
          companies={loading ? null : companies}
          {...{
            loading,
            error,
            companyId,
            userId,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            accountType,
            userAccountType,
            showExternalId,
            role,
            path,
            theme,
            enableProspects,
            enableRiskReportTenants,
            disableTenantDeletion,
            disableProspectDeletion,
            refetchQueries,
            upgradeProspectRefetchQueries,
            setPollingEnabled
          }}
          hideEmptyTable
          skipDefaultCompanySort
          skipAllCompaniesFilter
          showCompanyCount={false}
          disablePagination
          panelLeft={null}
          panelRight={null}
        />
        <LoadingBlock loading={loading} fullScreen={false} />
      </SearchContainer>
    </>
  )
}

export default UServiceQuickSearch
