/* global localStorage */
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { message, Button, Icon, Table } from 'antd'
import I18n from 'i18n-js'
import { useQuery } from 'react-apollo'
import _debounce from 'lodash/debounce'

import { connect } from '../../hocs/'
import { Column } from '../../components/EndUserPortal/GridFlexiLayout'

import { getSession } from '../../state/selectors/session'
import { getSettings } from '../../state/selectors/settings'
import { ListHeader, ListHeaderPanel, ListViewActions, ListTableActions, SearchBar, ErrorAlerts } from '../../components/common'
import { onListTableSelectAll, onListTableChange, textColumnSorter, disableActions } from '../../helpers/listPages'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { SCHEDULED_REPORTS } from '../../components/Queries/Reports'
import EditScheduledReportModal from '../../components/Modals/EditScheduledReportModal'
import ViewScheduledReportModal from '../../components/Modals/ViewScheduledReportModal'
import DeleteScheduledReportConfirm from '../../components/Modals/DeleteScheduledReportConfirm'
import DownloadReportModal from '../../components/Modals/DownloadReportModal'
import { WRITABLE_SCHEDULED_REPORT_ACTIONS } from '../../constants/actions'
import { FREQUENCY_OPTIONS } from '../../constants/reports'

const trOpt = { scope: 'reports.reportCentre.scheduledReports' }

const getReportActions = (disabledActions) => {
  const actions = [
    {
      key: 'viewScheduledReport',
      get label () { return I18n.t('reports.common.viewReport') },
      buttonIcon: 'eye',
      get buttonLabel () { return I18n.t('common.view') }
    }, {
      key: 'downloadScheduledReport',
      get label () { return I18n.t('reports.common.downloadReport') },
      buttonIcon: 'download',
      get buttonLabel () { return I18n.t('common.download') }
    },
    { key: 'sendScheduledReport', label: I18n.t('actions.sendScheduledReport', trOpt) },
    { key: 'editScheduledReport', label: I18n.t('actions.editScheduledReport', trOpt) },
    { key: 'deleteScheduledReport', label: I18n.t('actions.deleteScheduledReport', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const getReportsActions = (reports, disabledActions) => {
  if (reports.length === 1) return getReportActions(disabledActions)
  const actions = [
    { key: 'deleteScheduledReport', label: I18n.t('actions.deleteScheduledReport', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const ScheduledReportsView = ({
  enabledSections,
  planValid,
  companyId,
  accountType,
  sessionCompanyId,
  records: rawRecords = [],
  searchFilterText = '',
  updateSearchFilterText = () => {},
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  refetch,
  loading,
  error,
  refetchQueries,
  uBreachProEnabled
}) => {
  const [selectedIds, setSelectedIds] = useState([])
  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])

  const disabledActions = useMemo(() => !planValid ? WRITABLE_SCHEDULED_REPORT_ACTIONS : null, [planValid])

  const editScheduledReportRef = useRef(null)
  const onAddScheduledReportClick = useCallback(() => {
    if (editScheduledReportRef.current) {
      editScheduledReportRef.current.open()
    }
  }, [editScheduledReportRef])
  const onEditScheduledReportClick = useCallback(scheduledReport => {
    if (editScheduledReportRef.current) {
      editScheduledReportRef.current.open(scheduledReport)
    }
  }, [editScheduledReportRef])

  const viewScheduledReportRef = useRef(null)
  const onViewScheduledReportClick = useCallback(scheduledReport => {
    if (viewScheduledReportRef.current) {
      viewScheduledReportRef.current.open(scheduledReport)
    }
  }, [viewScheduledReportRef])
  const onDownloadScheduledReportClick = useCallback(scheduledReport => {
    if (viewScheduledReportRef.current) {
      viewScheduledReportRef.current.open(scheduledReport, 'download')
    }
  }, [viewScheduledReportRef])
  const onSendScheduledReportClick = useCallback(scheduledReport => {
    if (viewScheduledReportRef.current) {
      viewScheduledReportRef.current.open(scheduledReport, 'send')
    }
  }, [viewScheduledReportRef])

  const downloadReportRef = useRef(null)
  const onDownloadReport = useCallback((report) => {
    if (downloadReportRef.current) {
      downloadReportRef.current.open(report)
    }
  }, [downloadReportRef])

  const deleteScheduledReportRef = useRef(null)
  const onDeleteScheduledReportClick = useCallback(scheduledReports => {
    if (deleteScheduledReportRef.current) {
      deleteScheduledReportRef.current.open(scheduledReports)
    }
  }, [deleteScheduledReportRef])

  useEffect(() => {
    setAllRecords(
      rawRecords.map(rawRecord => {
        const { id } = rawRecord
        const record = {
          ...rawRecord,
          key: id
        }

        record.actions = getReportActions(disabledActions)
        return record
      })
    )
  }, [rawRecords, disabledActions])

  const performAction = useCallback(async (action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.key === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.key))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }

    switch (action) {
      case 'sendScheduledReport':
        onSendScheduledReportClick(actionRecord)
        break
      case 'viewScheduledReport':
        onViewScheduledReportClick(actionRecord)
        break
      case 'downloadScheduledReport':
        onDownloadScheduledReportClick(actionRecord)
        break
      case 'editScheduledReport':
        onEditScheduledReportClick(actionRecord)
        break
      case 'deleteScheduledReport':
        onDeleteScheduledReportClick(actionRecords)
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, onEditScheduledReportClick, onDeleteScheduledReportClick, onSendScheduledReportClick, onViewScheduledReportClick, onDownloadScheduledReportClick])

  const performRowAction = useCallback(async (action, id) => {
    performAction(action, [id])
  }, [performAction])

  const listActions = useMemo(() =>
    getReportsActions(allRecords.filter(record => selectedIds.includes(record.key)), disabledActions),
  [selectedIds, allRecords, disabledActions])

  const renderActionsCell = useCallback((actions, record) => (
    <ListTableActions
      actions={actions}
      id={record.id}
      performAction={performRowAction}
      onEmptyActions='disable'
    />
  ), [performRowAction])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}

    return [
      {
        title: I18n.t('common.reportName', trOpt),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => textColumnSorter('name', a, b),
        sortOrder: sortColumnKey === 'name' && order
      },
      {
        title: I18n.t('common.reportType', trOpt),
        dataIndex: 'reportType',
        key: 'reportType',
        render: (reportType) => I18n.t(`common.reportTypes.${reportType}`, trOpt)
      },
      {
        title: I18n.t('common.frequency', trOpt),
        dataIndex: 'frequency',
        key: 'frequency',
        filters: FREQUENCY_OPTIONS.map(frequency => ({ value: frequency, text: I18n.t(`common.frequencies.${frequency}`, trOpt) })),
        onFilter: (value, record) => record.frequency === value,
        render: (frequency) => I18n.t(`common.frequencies.${frequency}`, trOpt)
      },
      {
        title: I18n.t('common.enabled', trOpt),
        dataIndex: 'enabled',
        key: 'enabled',
        filters: [
          { value: true, text: I18n.t('common.yes') },
          { value: false, text: I18n.t('common.no') }
        ],
        filterMultiple: false,
        render: (text) => text ? I18n.t('common.yes') : I18n.t('common.no')
      }, {
        dataIndex: 'actions',
        key: 'actions',
        render: renderActionsCell
      }
    ]
  }, [renderActionsCell, sorter])

  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds: setSelectedIds, filters, columns, records })
  }, [records, setSelectedIds, filters, columns])
  const onSelectChange = useCallback(selectedRowKeys => setSelectedIds(selectedRowKeys), [setSelectedIds])
  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination: updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updatePagination, updateSorter, updateFilters, filters, onSelectAll])
  const handleRefreshClick = useCallback(() => refetch(), [refetch])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const applyRecordFilters = useCallback(searchFilterText => {
    let records = [...allRecords]
    if (searchFilterText) {
      const filterFields = ['name']
      records = records.filter(record =>
        filterFields.some(field => {
          const value = record[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setRecords(records)
  }, [allRecords])
  const debouncedApplyRecordFilters = useCallback(_debounce(applyRecordFilters, 500), [applyRecordFilters])
  useEffect(() => {
    debouncedApplyRecordFilters(searchFilterText)
  }, [debouncedApplyRecordFilters, searchFilterText])

  return (
    <>
      <>
        <EditScheduledReportModal ref={editScheduledReportRef} {...{ refetchQueries, companyId, enabledSections, uBreachProEnabled }} />
        <ViewScheduledReportModal ref={viewScheduledReportRef} useCompanyId={accountType === 'msp' && companyId !== sessionCompanyId} {...{ companyId, onDownloadReport }} />
        <DeleteScheduledReportConfirm ref={deleteScheduledReportRef} {...{ refetchQueries, companyId }} />
        <DownloadReportModal ref={downloadReportRef} {...{ companyId }} />
      </>
      <ListHeader align='right'>
        <ListHeaderPanel align='right'>
          <SearchBar
            placeholder={I18n.t('searchPlaceholder', trOpt)}
            value={searchFilterText}
            allowClear
            onChange={onSearchChange}
            style={{ width: 250 }}
          />
          <ListViewActions
            actions={listActions}
            performAction={performAction}
            selectedIds={selectedIds}
          />
          <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
          <Button disabled={!planValid} onClick={onAddScheduledReportClick} type='primary'>
            <Icon type='plus' />{I18n.t('common.add')}
          </Button>
        </ListHeaderPanel>
      </ListHeader>
      <ErrorAlerts error={[error]} defaultError={I18n.t('scheduledReportsLoadError', trOpt)} />
      <Table
        loading={loading}
        columns={columns}
        dataSource={records}
        onChange={onTableChange}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: onSelectChange
        }}
        pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
      />
    </>
  )
}

const ScheduledReports = ({ companyId, accountType, planValid, sessionCompanyId, userId, enabledSections, uBreachProEnabled }) => {
  const { loading, error, data: { scheduledReports: records = [] } = {}, refetch } = useQuery(SCHEDULED_REPORTS, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true
  })

  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const storageId = useMemo(() => `reportCentre|scheduledReports|${sessionCompanyId}|${userId}`, [userId, sessionCompanyId])
  useEffect(() => {
    try {
      const listFilterString = localStorage.getItem(storageId)
      if (listFilterString) {
        const listFilter = JSON.parse(listFilterString)
        Object.keys(listFilter).forEach(key => {
          const value = listFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    if (!storageId) {
      return
    }

    let viewFilter = {}
    try {
      const viewFilterString = localStorage.getItem(storageId)
      if (viewFilterString) {
        viewFilter = JSON.parse(viewFilterString)
      }
      viewFilter = { ...viewFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(viewFilter))
    } catch (e) {}
  }, [storageId])

  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  return (
    <Column>
      <h1 style={{ marginBottom: '0px' }}>{I18n.t('title', trOpt)}</h1>
      <p style={{ marginBottom: '0px' }}>{I18n.t('description', trOpt)}</p>
      <ScheduledReportsView
        {...{
          enabledSections, // TODO: this needs to be passed from the ReportCentre view
          planValid,
          records,
          searchFilterText,
          updateSearchFilterText,
          sorter,
          updateSorter,
          filters,
          updateFilters,
          pagination,
          updatePagination,
          refetch,
          loading,
          error,
          companyId,
          accountType,
          uBreachProEnabled
        }}
        refetchQueries={[{ query: SCHEDULED_REPORTS, variables: { companyId } }]}
      />
    </Column>
  )
}

export default connect(
  state => {
    const { companyId: sessionCompanyId, userId } = getSession(state) || {}
    const { uBreachProEnabled } = getSettings(state) || {}
    return { sessionCompanyId, userId, uBreachProEnabled }
  }
)(ScheduledReports)
