import React, { Component } from 'react'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'

import { GET_COURSES } from '../Queries/Courses'
import CreateSimulationForm from './CreateSimulationForm'
import { connect } from '../../hocs'
import { getSettings } from '../../state/selectors/settings'
import { COURSE_SUBJECT_OPTIONS } from '../../constants/courses'

const trOpt = { scope: 'uPhish.createSimulationSchedule' }

const CreateSimulationScheduleContainer = styled.div`
  max-width: 800px;
`

const CourseHeader = React.forwardRef(({ visible }, ref) => (
  visible
    ? (
      <div>
        <h4>{I18n.t('inlineTrainingTitle', trOpt)}</h4>
        <p className='base-font'>{I18n.t('inlineTrainingInfo', trOpt)}</p>
      </div>
    ) : null
))

class CreateSimulationSchedule extends Component {
  constructor (props) {
    super(props)

    this.form = React.createRef()

    this.validateRecipients = this.validateRecipients.bind(this)
  }

  getValues () {
    return this.form.current ? this.form.current.getValues() : {}
  }

  validateRecipients (field, value, errors, opt, { recipients: { learners = [], groups = [] } = {}, sendToAll }) {
    const valid = sendToAll || !_isEmpty(learners) || !_isEmpty(groups)
    if (!valid) {
      errors.push(I18n.t('noRecipientsError', { ...trOpt, sendToAll: I18n.t('sendToAll', trOpt) }))
    }
  }

  render () {
    const { updating, recipients, workingHours, spread, startDate, courseSubject, courseId, onChange, onSubmit, courseOptions, subjectOptions, uLearnEnabled, sendToAll } = this.props
    const footer = updating ? () => null : undefined
    const fields = [
      {
        id: 'sendToAll',
        label: I18n.t('sendToAll', trOpt),
        type: 'switch',
        defaultValue: sendToAll,
        extra: (
          <p className='base-font'>
            {I18n.t('sendToAllActiveUserInfo', trOpt)}
            <br />
            {I18n.t('sendToAllScheduleInfo', trOpt)}
          </p>
        ),
        validate: this.validateRecipients
      }, {
        id: 'recipients',
        label: I18n.t('recipients', trOpt),
        type: 'learnerAndGroupSelect',
        defaultValue: recipients,
        removeLabel: I18n.t('common.removeAllRecipients'),
        learnersWithEmailOnly: true,
        disabled: values => values.sendToAll,
        validate: this.validateRecipients,
        includeNoGroup: true
      }, {
        id: 'startDate',
        label: I18n.t('startDate', trOpt),
        type: 'datetime',
        required: true,
        minuteStep: 15,
        defaultValue: startDate,
        min: 'now'
      }, {
        id: 'spread',
        label: I18n.t('spread', trOpt),
        type: 'number',
        defaultValue: spread,
        required: true,
        step: 0.25
      }, {
        id: 'workingHours',
        label: I18n.t('uPhish.common.workingHours'),
        type: 'checkbox',
        defaultValue: workingHours
      }, {
        type: 'custom',
        component: CourseHeader,
        visible: uLearnEnabled
      }, {
        id: 'courseSubject',
        label: I18n.t('common.fields.subject'),
        type: 'select',
        defaultValue: courseSubject || '{all}',
        options: subjectOptions,
        visible: uLearnEnabled
      }, {
        id: 'courseId',
        label: I18n.t('common.courseLabel'),
        type: 'select',
        linkField: 'courseSubject',
        placeholder: I18n.t('coursePlaceholder', trOpt),
        options: courseOptions,
        mutateValue: values => values || [],
        defaultValue: courseId,
        visible: uLearnEnabled
      }
    ]

    return (
      <CreateSimulationForm
        ref={this.form}
        submitIcon='save'
        submitLabel={updating ? I18n.t('uPhish.common.updateSimulation') : I18n.t('uPhish.common.createSimulation')}
        {...{ fields, onChange, onSubmit, footer }}
      />
    )
  }
}

const CreateSimulationScheduleQuery = React.forwardRef(({ uLearn: uLearnEnabled, ...props }, ref) => {
  const { data: coursesData } = useQuery(GET_COURSES, {
    variables: { excludeGapAnalysis: true, restrictToPlan: true, withCompany: true },
    skip: !uLearnEnabled
  })
  const { courses = [] } = coursesData || {}
  const subjectOptions = [
    { value: '{all}', label: I18n.t('common.all') },
    ...COURSE_SUBJECT_OPTIONS.filter(({ value }) => courses.some(course => course.subject === value))
  ]
  const courseOptions = courses
  // Sort by difficulty then sort by displayName
    .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
    .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))

  return (
    <CreateSimulationScheduleContainer>
      <CreateSimulationSchedule {...{ ref, subjectOptions, courseOptions, uLearnEnabled }} {...props} />
    </CreateSimulationScheduleContainer>
  )
})

export default connect(state => _pick(getSettings(state), ['uLearn']))(CreateSimulationScheduleQuery)
