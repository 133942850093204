import React, { useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react'
import I18n from 'i18n-js'
import { message } from 'antd'
import { useQuery } from '@apollo/react-hooks'

import {
  UPDATE_COMPANY_TIMEZONE, GET_COMPANY_TIMEZONE,
  UPDATE_DEFAULT_TENANT_TIMEZONE, GET_DEFAULT_TENANT_TIMEZONE
} from '../Queries/Companies'
import { LoadingBlock } from '../common'
import { withRefreshSessionState } from '../../hocs'
import { SettingsFormElement } from './SettingsForm'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'

const trOpt = { scope: 'settings.timezone' }

const Timezone = React.forwardRef(({ refreshSessionState, defaultTenant, disableSubmit }, ref) => {
  const [, setForceRender] = useState(null)
  const { loading, data, refetch } = useQuery(defaultTenant ? GET_DEFAULT_TENANT_TIMEZONE : GET_COMPANY_TIMEZONE)
  const form = useRef(null)

  const onSuccess = useCallback(async () => {
    message.success(I18n.t('successMessage', trOpt))
    await refetch()
    await refreshSessionState()
  }, [refetch, refreshSessionState])

  const timezone = useMemo(() => {
    if (data) {
      const timezone = defaultTenant ? data.getDefaultTenantTimezone : data.company.timezone
      return timezone
    }
    return null
  }, [data, defaultTenant])

  useEffect(() => {
    if (form && form.current && timezone) {
      form.current.setInitialValues({ timezone })
    }
  }, [timezone, form])

  const hasChanged = useCallback(() => form.current?.hasChanged() === true, [form])

  useImperativeHandle(ref, () => ({
    hasChanged: hasChanged
  }), [hasChanged])

  const onChange = () => setForceRender(Date.now())

  if (loading) return <LoadingBlock fullScreen loading={loading} />

  const fields = [{
    id: 'timezone',
    type: 'timezone',
    label: I18n.t('common.timezone'),
    defaultValue: DEFAULT_TIMEZONE
  }]

  return (
    <SettingsFormElement
      headerId='settings-language-header'
      title={I18n.t('title', trOpt)}
      desc={<p>{I18n.t('description', trOpt)}</p>}
      formProps={{
        mutation: defaultTenant ? UPDATE_DEFAULT_TENANT_TIMEZONE : UPDATE_COMPANY_TIMEZONE,
        onSuccess,
        onChange,
        failureMessage: I18n.t('errorMessage', trOpt),
        submitLabel: I18n.t('common.save'),
        ref: form,
        fields: fields,
        skipResetFieldsOnSubmit: true
      }}
      {...{ disableSubmit }}
      hasChanged={hasChanged()}
    />
  )
})

export default withRefreshSessionState(Timezone)
