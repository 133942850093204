import React from 'react'
import I18n from 'i18n-js'

import { GoogleSyncActions } from '../GoogleSync'
import { SettingsFormElement } from './SettingsForm'

const trOpt = { scope: 'settings.googleSync' }

const GoogleSync = React.forwardRef((props, ref) => (
  <SettingsFormElement
    headerId='settings-google-sync-header'
    title={I18n.t('googleSync.common.googleSyncIntegration')}
    desc={<p>{I18n.t('description', trOpt)}</p>}
    form={<GoogleSyncActions />}
    hasFooter={false}
  />
))

export default GoogleSync
