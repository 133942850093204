import I18n from 'i18n-js'
import SettingsForm from '../SettingsForm'

const trOpt = { scope: 'settings.workingHours' }
const uPhishTrOpt = { scope: 'settings.uPhish' }

class WorkingHours extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'workingHours'
    ]
    Object.defineProperty(this, 'defaultValue', {
      get: () => ({
        workingHours: {
          start: '',
          end: ''
        }
      })
    })
  }

  get headerId () {
    return 'settings-working-hours-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      workingHours
    } = {
      ...this.defaultValue,
      ...settings
    }

    return [
      {
        id: 'workingHours',
        type: 'timeRange',
        label: I18n.t('workingHours', uPhishTrOpt),
        defaultValue: workingHours
      }
    ]
  }
}

export default WorkingHours
