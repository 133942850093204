import React from 'react'
import { Popover, Icon } from 'antd'
import I18n from 'i18n-js'

import LocalesCell from './LocalesCell'

const LocalesIconPopover = ({ localeType, locales, getPopupContainer }) => (
  <Popover
    placement='bottomRight'
    title={I18n.t('common.availableLanguages')}
    arrowPointAtCenter
    {...{ getPopupContainer }}
    content={
      <LocalesCell {...{ localeType, locales }} />
    }
  >
    <Icon type='global' />
  </Popover>
)

export default LocalesIconPopover
