import React from 'react'
import { Card, Icon } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

const trOpt = { scope: 'holding' }

const HoldingContainer = styled.div`
  text-align: center;
`

export const HoldingContent = ({ className }) => (
  <HoldingContainer className={className}>
    <h1>{I18n.t('main.freeTrialMessage.expiredMessage')} <Icon type='frown' /></h1>
    <p>{I18n.t('unfortunatelyYourFreeTrialHasExpired', trOpt)}</p>
    <p>{I18n.t('ifYouWouldLikeToDiscussPricing', trOpt)}</p>
  </HoldingContainer>
)

// TODO Match v1 page
const Holding = () => (
  <Card>
    <HoldingContent />
  </Card>
)

export default Holding
