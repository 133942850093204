/* global localStorage */
import I18n from 'i18n-js'
import _get from 'lodash/get'
import { message } from 'antd'

import { ACCOUNT_TYPES, NEXT_RISK_REPORT_STATUS, RISK_REPORT_STATUS_IDS } from '../constants/company'
import routes from '../constants/routes'
import { showErrors } from './ErrorHandler'
import { setSessionToken, postSessionChannelMessage } from './session'

const _managedByUsecure = (companyFieldId, values) => values[companyFieldId] === 'usecure' || values[companyFieldId] === null
export const managedByUsecure = values => _managedByUsecure('companyId', values)
export const companyManagedByUsecure = values => _managedByUsecure('parentCompanyId', values)

export const buildCompanyOptions = companies =>
  companies.map(({ id: value, name: label, linkFieldValue }) => ({ value, label, linkFieldValue }))
    .sort((a, b) => a.label.localeCompare(b.label))

export const isRiskReportAtStatus = (reportStatus, targetStatus) => {
  const reportIndex = RISK_REPORT_STATUS_IDS.indexOf(reportStatus)
  const targetIndex = RISK_REPORT_STATUS_IDS.indexOf(targetStatus)
  return reportIndex > -1 && targetIndex > -1 && reportIndex >= targetIndex
}

const assumeTrOpt = { scope: 'uService' }
export const assumeCompanyUser = async ({ executeAssumeCompanyUser, company, admin = false, accountType, assumeStorageId, targetRoute }) => {
  const { id: companyId, accountType: companyAccountType } = company
  try {
    const result = await executeAssumeCompanyUser({ variables: { companyId, admin } })
    const token = _get(result, 'data.assumeCompanyUser.token')
    if (!token) {
      throw new Error(I18n.t('noTokenReturned', assumeTrOpt))
    }
    // Set token
    setSessionToken(token)
    // Set account type for unassume reload
    if (assumeStorageId && accountType) {
      localStorage.setItem(assumeStorageId, accountType)
    }
    // Tell other tabs/windows to reload
    postSessionChannelMessage('sessionReloadPage')
    // Refresh the page
    window.location.href = targetRoute || (['distributor', 'msp'].includes(companyAccountType) ? routes.USERVICE : routes.HOME)
  } catch (e) {
    showErrors(e, I18n.t('anErrorOccurredLoggingIntoThe', { ...assumeTrOpt, accountType: (ACCOUNT_TYPES[companyAccountType] || 'company').toLowerCase() }))
  }
}

const getAccessControlKey = (baseKey, accessType, recordName, isUsecureAdmin = false) => {
  let key = `${baseKey}`
  if (accessType === 'usecure') {
    key += 'ManagedByUsecure'
  } else if (accessType === 'distributor') {
    key += isUsecureAdmin ? 'DistributorUsecureAdmin' : 'Distributor'
  } else if (accessType === 'msp') {
    key += isUsecureAdmin ? 'MSPUsecureAdmin' : 'MSP'
  }
  return I18n.t(`common.accessControl.${key}`, {
    ...ACCOUNT_TYPES,
    recordName: recordName || I18n.t('common.accessControl.record')
  })
}

export const getGlobalLabel = (accessType, recordName, isUsecureAdmin = false) => getAccessControlKey('globalLabel', accessType, recordName, isUsecureAdmin)
export const getGlobalExtra = (accessType, recordName, isUsecureAdmin = false) => getAccessControlKey('globalExtra', accessType, recordName, isUsecureAdmin)

// Company Access multiselect field doesn't have a state usecure accessType
// This function ensures getAccessControlKey returns the base key
const getCompanyAccessKey = (baseKey, accessType, recordName) =>
  getAccessControlKey(baseKey, accessType !== 'usecure' ? accessType : undefined, recordName)
export const getCompanyAccessLabel = accessType => getCompanyAccessKey('companyAccessLabel', accessType)
export const getCompanyAccessPlaceholder = accessType => getCompanyAccessKey('companyAccessPlaceholder', accessType)
export const getCompanyAccessExtra = (accessType, recordName = 'record') => getCompanyAccessKey('companyAccessExtra', accessType, recordName)

export const accessControlHelpers = { getGlobalLabel, getGlobalExtra, getCompanyAccessLabel, getCompanyAccessPlaceholder, getCompanyAccessExtra }

const invoiceTrOpt = { scope: 'uService.reprocessOutstandingInvoices' }
export const reprocessOutstandingInvoices = async ({ companyId, executeReprocessInvoices }) => {
  const hide = message.loading(I18n.t('loadingMessage', invoiceTrOpt), 0)
  let success = false
  let error
  try {
    const result = await executeReprocessInvoices({ variables: { companyId } })
    success = _get(result, 'data.reprocessOutstandingInvoices') === true
    throw Error(I18n.t('errorMessage', invoiceTrOpt))
  } catch (e) {
    error = e
  }
  hide()
  return new Promise(resolve => {
    // Artificial delay for UX reasons
    setTimeout(() => {
      if (success) {
        message.success(I18n.t('emailMessage', invoiceTrOpt))
      } else {
        showErrors(error, I18n.t('errorMessage', invoiceTrOpt))
      }
      resolve(success)
    }, 1000)
  })
}

const getRiskReportLastStatusBeforeTarget = (riskReport, targetStatus) => {
  const { history } = riskReport
  const targetIndex = RISK_REPORT_STATUS_IDS.indexOf(targetStatus)
  const historyIndexes = Object.keys(history).reduce((acc, currentStatus) => {
    const rRStatusIndex = RISK_REPORT_STATUS_IDS.indexOf(currentStatus)
    if (rRStatusIndex !== -1 && rRStatusIndex < targetIndex) acc.push(rRStatusIndex)
    return acc
  }, [])
  return RISK_REPORT_STATUS_IDS[Math.max(...historyIndexes)]
}

export const processRiskReport = (rawRiskReport, { preview = false } = {}) => {
  if (rawRiskReport) {
    // Revert report back to after either breach scan completion or domain scan completion if there isn't supporting status history to show the completed report at the current status
    // This covers situations when either the simulation is missing or uphish_complete status was never reached
    // These use cases can occur when the risk report's simulation is deleted or the prospect was upgraded before phishing campaign stage was completed
    // We can't show a complete risk report with the supporting data and history even at finished status
    // CL - This code will need to reworked if we move the risk report from a real time approach to an ETL one
    const statusBeforeFinished = getRiskReportLastStatusBeforeTarget(rawRiskReport, 'finished')
    // Risk report previews can show a complete risk report at uphish_finished provided there's a simulation in place
    const completedReportStatus = preview ? 'uphish_finished' : 'uphish_complete'
    if (!(rawRiskReport.simulation && isRiskReportAtStatus(statusBeforeFinished, completedReportStatus))) {
      let revertedStatus = statusBeforeFinished
      // Reverting using getRiskReportLastStatusBeforeTarget means that the risk report is reverted to the most recent status before the next stage appears.
      if (isRiskReportAtStatus(statusBeforeFinished, 'ubreach_complete')) {
        // Show breach scan completion
        // Returns ubreach_complete, uphish_requested, uphish_ready, uphish_started or uphish_finished
        revertedStatus = getRiskReportLastStatusBeforeTarget(rawRiskReport, NEXT_RISK_REPORT_STATUS.uphish_complete) || 'ubreach_complete'
      } else if (isRiskReportAtStatus(statusBeforeFinished, 'domain_complete')) {
        // Show domain scan completion
        // Returns domain_complete, ubreach_requested or ubreach_started
        revertedStatus = getRiskReportLastStatusBeforeTarget(rawRiskReport, NEXT_RISK_REPORT_STATUS.ubreach_complete) || 'domain_complete'
      }
      return { ...rawRiskReport, status: revertedStatus }
    }
  }
  return rawRiskReport
}
