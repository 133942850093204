import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Input, InputNumber, Modal, Radio as _Radio, message, Checkbox } from 'antd'
import { useMutation, useQuery } from '@apollo/react-hooks'
import styled, { css } from 'styled-components'
import I18n from 'i18n-js'
import moment from 'moment'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _toInteger from 'lodash/toInteger'
import _unset from 'lodash/unset'

import { showErrors, invalidatePoliciesQueryCache } from '../../helpers'
import {
  GET_POLICY, GET_POLICY_TEMPLATE,
  PUBLISH_DRAFT_POLICY, PUBLISH_DRAFT_POLICY_TEMPLATE,
  DISCARD_DRAFT_POLICY, DISCARD_DRAFT_POLICY_TEMPLATE
} from '../Queries/uPolicy'
import { renderContent } from '../LearnerCourse/common'
import { PolicyContent } from '../../views/uPolicy/LearnerPolicy'
import PolicyPDFViewer from '../Policies/PolicyPDFViewer'
import { LoadingBlock } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'

const trOpt = { scope: 'modals.savePolicyModal' }

const SaveModal = styled(Modal)`
  max-width: 1000px;
  ${({ inPreview }) => inPreview ? css`height: calc(100% - 80px);` : ''}
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const Radio = styled(_Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const PreviewPrompt = styled.div`
  padding: 30px 0;
  text-align: center;

  &> div {
    display: inline-block
  }
`

const PreviewCard = styled(Card)`
  flex: 1;
  overflow-y: auto;

  .ant-card-body {
    height: 100%;
    overflow: auto;
  }
`
const PreviewPDFCard = styled(PreviewCard)`
  border: none;

  .ant-card-body {
    padding: 0;
  }
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  text-align: right;

  .ant-btn {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const VersionPrefix = styled(Input)`
  width: 30px;

  .ant-input-group-addon {
    height: 32px;
  }

  .ant-input {
    display: none;
  }
`

const VersionInput = styled(InputNumber)`
  ${({ noBorder }) => noBorder ? css`border-left: 0;` : ''}
  width: 80px;
  
  .ant-input-number-input {
    text-align: center;
  }
`

const SavePolicyButtons = ({ finished = false, okDisabled = false, showBack = false, onOk = () => {}, onCancel = () => {}, onBack = () => {} }) => {
  const okIcon = finished ? 'check-circle' : 'right-circle'
  const okLabel = finished ? I18n.t('common.finish') : I18n.t('common.next')

  return (
    <ButtonContainer>
      <Button icon='close-circle' type='danger' onClick={onCancel}>{I18n.t('common.cancel')}</Button>
      {showBack && <Button icon='left-circle' onClick={onBack}>{I18n.t('common.goBack')}</Button>}
      <Button icon={okIcon} type='primary' onClick={onOk} disabled={okDisabled}>{okLabel}</Button>
    </ButtonContainer>
  )
}

const SavePolicyActions = ({
  policy, isTemplate, action,
  saveActions = ['saveDraft', 'updateDraft', 'update', 'publish', 'publishDraft'],
  setAction = () => {}, savePolicy = () => {}, setStep = () => {}, setVisible = () => {}
}) => {
  const [nextStep, setNextStep] = useState(null)

  const onChange = useCallback(e => {
    setAction(e.target.value)
  }, [setAction])
  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onOk = useCallback(() => {
    if (nextStep === 'save') {
      savePolicy()
    } else {
      setStep(nextStep)
    }
  }, [nextStep, setStep, savePolicy])

  useEffect(() => {
    let nextStep = null

    const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
    const hasDraft = !_isNil(_get(policy, 'draftPolicyDocument.id'))

    if (action === 'saveDraft' && hasDraft) {
      nextStep = 'previewOverwriteDraft'
    } else if (!isTemplate && (action === 'publish' || action === 'publishDraft')) {
      nextStep = isLive ? 'versionType' : 'versionNumber'
    } else if (action) {
      nextStep = 'save'
    }

    setNextStep(nextStep)
  }, [policy, isTemplate, action])

  const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
  const publishLabel = I18n.t(isTemplate || !isLive ? 'publishToLive' : 'publishNewVersion', trOpt)

  return (
    <>
      <h3>{I18n.t('savePrompt', trOpt)}</h3>
      <Radio.Group onChange={onChange} value={action}>
        {
          saveActions.includes('saveDraft') &&
            <Radio value='saveDraft'>{I18n.t('saveAsDraft', trOpt)}</Radio>
        }
        {
          saveActions.includes('updateDraft') &&
            <Radio value='updateDraft'>{I18n.t('saveAsDraft', trOpt)}</Radio>
        }
        {
          saveActions.includes('update') &&
            <Radio value='update'>{I18n.t('saveToLive', trOpt)}</Radio>
        }
        {
          saveActions.includes('publish') &&
            <Radio value='publish'>{publishLabel}</Radio>
        }
        {
          saveActions.includes('publishDraft') &&
            <Radio value='publishDraft'>{publishLabel}</Radio>
        }
      </Radio.Group>
      <SavePolicyButtons finished={nextStep === 'save'} okDisabled={nextStep === null} {...{ onOk, onCancel }} />
    </>
  )
}

const SavePolicyPreview = ({ policy, prompt = 'PROMPT', onOk = () => {}, onCancel = () => {} }) => {
  return (
    <PreviewContainer>
      <PreviewPrompt>
        <div>
          <Card>
            {
              _isString(prompt)
                ? <h3>{prompt}</h3>
                : prompt
            }
            <ButtonContainer>
              <Button onClick={onCancel}>{I18n.t('common.no')}</Button>
              <Button type='primary' onClick={onOk}>{I18n.t('common.yes')}</Button>
            </ButtonContainer>
          </Card>
        </div>
      </PreviewPrompt>
      {
        policy && policy.draftType === 'editor' && policy.draftDocument &&
          <PreviewCard>
            <PolicyContent dangerouslySetInnerHTML={{ __html: renderContent(JSON.stringify(policy.draftDocument)) }} />
          </PreviewCard>
      }
      {
        policy && policy.draftType === 'pdf' && policy.draftFile &&
          <PreviewPDFCard>
            <PolicyPDFViewer file={policy.draftFile} height='100%' />
          </PreviewPDFCard>
      }
    </PreviewContainer>
  )
}

const PublishDraftPolicyPreview = ({ policy, isTemplate, setStep = () => {}, savePolicy = () => {}, setVisible = () => {} }) => {
  const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
  const opt = { scope: `${trOpt.scope}.${isTemplate ? 'publishDraftPolicyTemplate' : 'publishDraftPolicy'}` }

  const onOk = useCallback(() => {
    if (isTemplate) {
      savePolicy()
    } else if (isLive) {
      setStep('versionType')
    } else {
      setStep('versionNumber')
    }
  }, [setStep, savePolicy, isLive, isTemplate])
  const onCancel = useCallback(() => setVisible(false), [setVisible])

  return (
    <SavePolicyPreview
      prompt={I18n.t('prompt', opt)}
      onOk={onOk}
      onCancel={onCancel}
      {...{ policy }}
    />
  )
}

export const PublishDraftPolicyModal = (props) => {
  const { isTemplate } = props
  const opt = { scope: `${trOpt.scope}.${props.isTemplate ? 'publishDraftPolicyTemplate' : 'publishDraftPolicy'}` }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', opt))
  }, [opt])

  return (
    <SavePolicyModal
      mutation={isTemplate ? PUBLISH_DRAFT_POLICY_TEMPLATE : PUBLISH_DRAFT_POLICY}
      title={I18n.t('title', opt)}
      includeNameInTitle
      initialStep='previewPublishDraft'
      failureMessage={I18n.t('errorMessage', opt)}
      onSuccess={onSuccess}
      {...props}
    />
  )
}

const DiscardDraftPolicyPreview = ({ policy, savePolicy = () => {}, setVisible = () => {} }) => {
  const opt = { scope: `${trOpt.scope}.${policy.template ? 'discardDraftPolicyTemplate' : 'discardDraftPolicy'}` }
  const onOk = useCallback(() => savePolicy(), [savePolicy])
  const onCancel = useCallback(() => setVisible(false), [setVisible])

  return (
    <SavePolicyPreview
      prompt={I18n.t('prompt', opt)}
      onOk={onOk}
      onCancel={onCancel}
      {...{ policy }}
    />
  )
}

const OverwriteDraftPolicyPreview = ({ policy, savePolicy = () => {}, setStep = () => {} }) => {
  const onOk = useCallback(() => savePolicy(), [savePolicy])
  const onCancel = useCallback(() => setStep('actions'), [setStep])

  return (
    <SavePolicyPreview
      prompt={
        <>
          <h3>{I18n.t(policy.template ? 'overwriteExistingPolicyTemplate' : 'overwriteExistingPolicy', trOpt)}</h3>
          <h3>{I18n.t('overwritePrompt', trOpt)}</h3>
        </>
      }
      onOk={onOk}
      onCancel={onCancel}
      {...{ policy }}
    />
  )
}

export const DiscardDraftPolicyModal = (props) => {
  const { isTemplate } = props
  const opt = { scope: `${trOpt.scope}.${isTemplate ? 'discardDraftPolicyTemplate' : 'discardDraftPolicy'}` }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', opt))
  }, [opt])

  return (
    <SavePolicyModal
      mutation={isTemplate ? DISCARD_DRAFT_POLICY_TEMPLATE : DISCARD_DRAFT_POLICY}
      title={I18n.t('title', opt)}
      includeNameInTitle
      initialStep='previewDiscardDraft'
      failureMessage={I18n.t('errorMessage', opt)}
      onSuccess={onSuccess}
      {...props}
    />
  )
}

const SavePolicyVersionType = ({ isLive, signatureTypeHasImmediateSendOut, prevStep, policy, versionType, setVersionType = () => {}, setStep = () => {}, savePolicy = () => {}, setVisible = () => {} }) => {
  const majorVersion = _get(policy, 'majorVersion', 1)
  const minorVersion = _get(policy, 'minorVersion', 0)
  const onOk = useCallback(() => setStep((!isLive && signatureTypeHasImmediateSendOut) ? 'publishNewCompulsoryWarning' : 'resend'), [setStep, isLive, signatureTypeHasImmediateSendOut])
  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onBack = useCallback(() => setStep(prevStep), [setStep, prevStep])
  const onChange = useCallback(e => {
    setVersionType(e.target.value)
  }, [setVersionType])

  return (
    <>
      <h3>{I18n.t('releaseTypePrompt', trOpt)}</h3>
      <Radio.Group onChange={onChange} value={versionType}>
        <Radio value='minor'>{I18n.t('minorVersionNo', { ...trOpt, version: `${majorVersion}.${minorVersion + 1}` })}</Radio>
        <Radio value='major'>{I18n.t('majorVersionNo', { ...trOpt, version: `${majorVersion + 1}.0` })}</Radio>
      </Radio.Group>
      <SavePolicyButtons showBack okDisabled={versionType === null} {...{ onOk, onBack, onCancel }} />
    </>
  )
}

const SavePolicyResend = ({ prevStep, isLive, compulsory, resend, setResend = () => {}, setStep = () => {}, savePolicy = () => {}, setVisible = () => {} }) => {
  const onOk = useCallback(() => savePolicy(), [savePolicy])
  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onBack = useCallback(() => setStep(prevStep), [setStep, prevStep])
  const onChange = useCallback(e => {
    setResend(e.target.value)
  }, [setResend])

  return (
    <>
      {
        !isLive &&
          <h3>{I18n.t('sendPolicyPrompt', trOpt)}</h3>
      }
      {
        isLive && compulsory &&
          <h3>{I18n.t('resendCompulsoryPolicyPrompt', trOpt)}</h3>
      }
      {
        isLive && !compulsory &&
          <h3>{I18n.t('resendPolicyPrompt', trOpt)}</h3>
      }
      <Radio.Group onChange={onChange} value={resend}>
        <Radio value='yes'>{I18n.t('common.yes')}</Radio>
        <Radio value='no'>{I18n.t('common.no')}</Radio>
      </Radio.Group>
      <SavePolicyButtons showBack finished okDisabled={resend === null} {...{ onOk, onBack, onCancel }} />
    </>
  )
}

const SaveCompulsoryPolicy = ({ prevStep, setStep = () => {}, savePolicy = () => {}, setVisible = () => {} }) => {
  const [agreeToStatement, setAgreeToStatement] = useState(false)
  const onOk = useCallback(() => savePolicy(), [savePolicy])
  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onBack = useCallback(() => setStep(prevStep), [setStep, prevStep])

  return (
    <>
      <h3>{I18n.t('publishNewCompulsoryWarningTitle', trOpt)}</h3>
      <p>{I18n.t('publishNewCompulsoryWarningSubtitle', trOpt)}</p>
      <Checkbox onChange={() => setAgreeToStatement(!agreeToStatement)}>{I18n.t('publishNewCompulsoryWarningCheckbox', trOpt)}</Checkbox>
      <SavePolicyButtons showBack finished okDisabled={!agreeToStatement} {...{ onOk, onBack, onCancel }} />
    </>
  )
}

const SavePolicyVersionNumber = ({
  prevStep, majorVersion, setMajorVersion = () => {}, minorVersion, setMinorVersion = () => {},
  setStep = () => {}, savePolicy = () => {}, setVisible = () => {},
  isLive, signatureTypeHasImmediateSendOut
}) => {
  const resendNext = isLive || signatureTypeHasImmediateSendOut
  const onOk = useCallback(() => {
    if (resendNext) {
      setStep((!isLive && signatureTypeHasImmediateSendOut) ? 'publishNewCompulsoryWarning' : 'resend')
    } else {
      savePolicy()
    }
  }, [resendNext, savePolicy, setStep, isLive, signatureTypeHasImmediateSendOut])
  const onCancel = useCallback(() => setVisible(false), [setVisible])
  const onBack = useCallback(() => setStep(prevStep), [setStep, prevStep])
  const onMajorChange = useCallback(value => {
    setMajorVersion(_toInteger(value))
  }, [setMajorVersion])
  const onMinorChange = useCallback(value => {
    setMinorVersion(_toInteger(value))
  }, [setMinorVersion])

  return (
    <>
      <h3>{I18n.t('setIntialVersionNo', trOpt)}</h3>
      <Input.Group compact>
        <VersionPrefix addonBefore='v' disabled />
        <VersionInput step={1} precision={0} min={0} value={majorVersion} onChange={onMajorChange} placeholder={I18n.t('major', trOpt)} />
        <Input
          style={{
            backgroundColor: '#fff',
            borderLeft: 0,
            paddingLeft: 4,
            paddingRight: 4,
            pointerEvents: 'none',
            textAlign: 'center',
            width: 15
          }}
          placeholder='.'
          disabled
        />
        <VersionInput noBorder step={1} precision={0} min={0} value={minorVersion} onChange={onMinorChange} placeholder={I18n.t('minor', trOpt)} />
      </Input.Group>
      <SavePolicyButtons finished={!resendNext} showBack {...{ onOk, onBack, onCancel }} />
    </>
  )
}

const SavePolicyModal = ({
  saveActions,
  id, initialStep = 'actions', isTemplate,
  mutation, variables, refetchQueries,
  title, includeNameInTitle,
  policySignatureSettings: companyPolicySignatureSettings,
  onSuccess, onFailure, failureMessage = I18n.t('common.anErrorOccurred'),
  visible = false, setVisible = () => {}, afterClose = () => {}
}) => {
  const { loading: loadingPolicy, data } = useQuery(
    isTemplate ? GET_POLICY_TEMPLATE : GET_POLICY,
    {
      skip: !visible || !id,
      variables: {
        id,
        forUpdate: true,
        includeLivePolicyDocument: true,
        includeDraftPolicyDocument: true
      }
    }
  )
  const policy = _get(data, isTemplate ? 'policyTemplate' : 'policy', {})
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(initialStep)
  const [action, setAction] = useState(null)
  const [versionType, setVersionType] = useState(null)
  const [majorVersion, setMajorVersion] = useState(1)
  const [minorVersion, setMinorVersion] = useState(0)
  const [resend, setResend] = useState(null)
  const [executeSavePolicy] = useMutation(mutation, { update: invalidatePoliciesQueryCache, refetchQueries })
  const closeModal = useCallback(async () => {
    setVisible(false)
  }, [setVisible])

  const handleFailure = useCallback(e => {
    if (_isFunction(onFailure)) {
      onFailure(e)
    } else {
      showErrors(e, _isString(onFailure) ? onFailure : failureMessage)
    }
  }, [onFailure, failureMessage])

  const savePolicy = useCallback(async saveAction => {
    try {
      setLoading(true)
      const saveVariables = {
        id,
        major: versionType === 'major',
        majorVersion,
        minorVersion,
        action: saveAction || action,
        resend: resend === 'yes',
        ...(variables || {})
      }
      _unset(saveVariables, 'signatureSettings.__typename')
      const result = await executeSavePolicy({
        variables: saveVariables
      })
      setLoading(false)
      setVisible(false)
      onSuccess(result)
    } catch (e) {
      handleFailure(e)
    } finally {
      setLoading(false)
    }
  }, [
    id, action, versionType, majorVersion, minorVersion, resend,
    variables, executeSavePolicy, onSuccess, handleFailure,
    setVisible, setLoading
  ])

  title = title ? `${title}${includeNameInTitle && policy && policy.name ? ` - ${policy.name}` : ''}`
    : I18n.t(isTemplate ? 'saveYourPolicyTemplate' : 'saveYourPolicy', trOpt)

  const afterCloseModal = useCallback(() => {
    setStep(initialStep)
    setAction(null)
    setVersionType(null)
    setMajorVersion(1)
    setMinorVersion(0)
    setResend(null)
    setLoading(false)
    afterClose()
  }, [setStep, setLoading, setVersionType, setResend, initialStep, afterClose])

  const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
  const useDefault = _get(variables || policy, 'signatureSettings.useDefault')
  const signatureSettings = useDefault ? companyPolicySignatureSettings : _get(variables || policy, 'signatureSettings')
  const { type, startDate } = signatureSettings || {}
  // New fixed term policies will only be sent on publish if the start date is today or earlier
  // The date comparison uses UTC to match the server which doesn't currently take a client's preferred timezone into account.
  const signatureTypeHasImmediateSendOut = (type === 'fixed' && startDate && moment.utc(startDate).isSameOrBefore(moment.utc(), 'day')) || type === 'lastSignature' || type === 'newUsers'
  const compulsory = type === 'fixed' || type === 'lastSignature' || type === 'newUsers'

  let body
  switch (step) {
    case 'actions':
      body = <SavePolicyActions {...{ policy, isTemplate, saveActions, action, setAction, savePolicy, setStep, setVisible }} />
      break
    case 'previewPublishDraft':
      body = <PublishDraftPolicyPreview {...{ policy, isTemplate, setStep, savePolicy, setVisible }} />
      break
    case 'previewDiscardDraft':
      body = <DiscardDraftPolicyPreview {...{ policy, savePolicy, setVisible }} />
      break
    case 'previewOverwriteDraft':
      body = <OverwriteDraftPolicyPreview {...{ policy, setStep, savePolicy, setVisible }} />
      break
    case 'versionType':
      body = <SavePolicyVersionType prevStep={initialStep} {...{ isLive, signatureTypeHasImmediateSendOut, policy, versionType, setVersionType, setStep, savePolicy, setVisible }} />
      break
    case 'versionNumber':
      body = <SavePolicyVersionNumber prevStep={initialStep} {...{ policy, type, signatureTypeHasImmediateSendOut, majorVersion, setMajorVersion, minorVersion, setMinorVersion, setStep, savePolicy, setVisible }} />
      break
    case 'resend':
      body = <SavePolicyResend prevStep={isLive ? 'versionType' : 'versionNumber'} {...{ isLive, compulsory, resend, setResend, setStep, savePolicy, setVisible }} />
      break
    case 'publishNewCompulsoryWarning':
      body = <SaveCompulsoryPolicy prevStep={isLive ? 'versionType' : 'versionNumber'} {...{ setStep, savePolicy, setVisible }} />
      break
    default:
      body = null
      break
  }

  const inPreview = ['previewPublishDraft', 'previewDiscardDraft', 'previewOverwriteDraft'].includes(step)
  const modalWidth = inPreview ? 'auto' : 500
  const modalBodyStyle = inPreview ? { paddingTop: 5, height: 'calc(100% - 60px)' } : undefined

  return (
    <SaveModal
      visible={visible}
      title={title}
      onCancel={closeModal}
      afterClose={afterCloseModal}
      footer={null}
      width={modalWidth}
      bodyStyle={modalBodyStyle}
      {...{ inPreview }}
    >
      <LoadingBlock fullScreen={false} loading={loading || loadingPolicy} />
      {body}
    </SaveModal>
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['policySignatureSettings'])
)(SavePolicyModal)
