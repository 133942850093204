import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.breachAlertEmail' }

class BreachAlertEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'uBreachPro'
    this.showSentTestButton = true

    const { accountType, defaultTenant } = this.props

    const subjectFields = [
      { id: 'adminSubject', extra: I18n.t('adminSubjectExtra', trOpt) },
      { id: 'learnerSubject', extra: I18n.t('learnerSubjectExtra', trOpt) }
    ]

    if (accountType === 'msp' && !defaultTenant) {
      subjectFields.push({ id: 'mspSubject', extra: I18n.t('mspSubjectExtra', trOpt) })
    }

    this.generateSubjectFields(subjectFields, trOpt)
  }

  get headerId () {
    return 'settings-breach-alert-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(BreachAlertEmail)
