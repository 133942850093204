import React, { useState, useCallback, useRef } from 'react'
import { Button, Dropdown, Icon, Menu } from 'antd'
import I18n from 'i18n-js'

import { downloadReports, downloadReportsWithExportServiceClient } from '../../helpers'
import ReportDownloadButton from './ReportDownloadButton'
import { PopUpContainer } from '../common'

const ReportDownloadDropdown = ({ reports = [], label = I18n.t('reports.downloadReports'), icon = 'down', placement }) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const popupContainerRef = useRef(null)
  const getPopupContainer = useCallback(() => {
    const { current: popupContainer } = popupContainerRef
    return popupContainer || document.body
  }, [popupContainerRef])
  const completeDownload = useCallback(async (downloads) => {
    setVisible(false)
    setLoading(true)
    await downloadReports(downloads.filter(({ jobData }) => !jobData))
    await downloadReportsWithExportServiceClient(downloads.filter(({ jobData }) => jobData))

    setLoading(false)
  }, [])
  const downloadReport = useCallback(async e => {
    completeDownload([reports[e.key]])
  }, [completeDownload, reports])
  const downloadAllReports = useCallback(async () => completeDownload(reports), [completeDownload, reports])
  const showMenu = useCallback(() => setVisible(true), [])
  const hideMenu = useCallback(() => setVisible(false), [])

  if (!reports || reports.length === 0) {
    return null
  } else if (reports.length === 1) {
    const [{ label: reportLabel }] = reports
    return (
      <ReportDownloadButton {...{ reports }}>{reportLabel}</ReportDownloadButton>
    )
  }

  return (
    <>
      <PopUpContainer ref={popupContainerRef} onClick={hideMenu} onMouseEnter={hideMenu} style={{ height: visible ? '100%' : 'auto' }} />
      <Dropdown
        {...{ visible, placement, getPopupContainer }}
        overlay={
          <Menu onClick={downloadReport}>
            {reports.map(({ label }, index) => (
              <Menu.Item key={index}>
                <Icon type='download' />
                {label}
              </Menu.Item>)
            )}
          </Menu>
        }
      >
        <Button.Group>
          <Button type='primary' disabled={loading} {...{ loading }} icon='download' onClick={downloadAllReports}>{label}</Button>
          <Button type='primary' disabled={loading} {...{ icon }} onMouseEnter={showMenu} />
        </Button.Group>
      </Dropdown>
    </>
  )
}

export default ReportDownloadDropdown
