import React, { useCallback, useState, useImperativeHandle, useEffect } from 'react'
import { Button, Card, Descriptions, Modal } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { Link, generatePath } from 'react-router-dom'
import moment from 'moment'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _sample from 'lodash/sample'

import { GET_RECOMMENDED_SIMULATION } from '../Queries/uPhish'
import { LoadingBlock, ErrorAlerts, ListHeader, ListHeaderPanel } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { apiUrl } from '../../apollo-client/common'
import SendTestEmailButton from '../uPhish/SendTestEmailButton'
import routes from '../../constants/routes'
import { getSessionToken } from '../../helpers/session'

const landingPageURL = window.__USECURE_CONFIG__.REACT_APP_LANDING_PAGE_URL

const DetailContainer = styled.div`
  padding: 10px 0;

  .ant-descriptions-item {
    vertical-align: top;

    &> span {
      display: block;
    }
  }

  .hide-label-item {
    &> .ant-descriptions-item-colon::after {
      content: none;
    }
  }
`

const DetailDescriptions = styled(Descriptions)`
  .ant-descriptions-row:nth-child(odd) > td {
    padding-bottom: 0;
  }
`

const PageIframe = styled.iframe`
  border: none;
  height: 800px;
  width: 100%;
  visibility: ${({ src }) => src ? 'visible' : 'hidden'};
`

const ViewModal = styled(Modal)`
  height: calc(100% - 110px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-top: 24px;
  }
  .ant-modal-title {
    font-size: 24px;
    line-height: 24px;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ViewRecordCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    font-family: unset;
  }
`

const ViewRecordContentContainer = styled.div`
  overflow: auto;

  > div {
    margin-right: 10px;
  }
`

const ViewModalFooter = styled.div`
  text-align: center;

  button + a {
    margin-left: 8px;
  }
`

const trOpt = { scope: 'riskReport.uPhish.recommendedSimulations.modal' }
const fieldTrOpt = { scope: `${trOpt.scope}.fields` }
const uPhishTrOpt = { scope: 'uPhish.common' }

const getEmailConfig = simulation => {
  const { subject, customSender, senderName, senderEmail, senderPrefix, senderDomain, availableLinks = [] } = _get(simulation, 'emailTemplate') || {}

  return {
    subject,
    sender: {
      custom: customSender,
      name: senderName,
      email: customSender ? senderEmail : '',
      prefix: customSender ? '' : senderPrefix,
      domain: customSender ? '' : senderDomain
    },
    link: `https://${_isEmpty(availableLinks) ? 'sup0rt.co.uk' : _sample(availableLinks)}`,
    ...(_get(simulation, 'config') || {})
  }
}

const ViewRecommendSimulationContent = ({
  id, simulation, companyId
}) => {
  const [landingLink, setLandingLink] = useState(null)
  const landingPageId = _get(simulation, 'landingPage.id')
  const landingPageTitle = _get(simulation, 'landingPage.pageTitle')
  useEffect(() => {
    setLandingLink(landingPageURL && landingPageId && companyId ? `${landingPageURL}?t=${landingPageId}&s=-1&l=-1&sc=${companyId}` : null)
  }, [landingPageId, companyId])

  const onLandingClick = useCallback(() => {
    if (landingLink) {
      window.open(landingLink, '_blank')
    }
  }, [landingLink])

  // Email
  // Freeform emails from config.content can't be displayed on this modal as their HTML is not stored in the recommended simulation data
  const { id: emailTemplateId, name, subject } = _get(simulation, 'emailTemplate') || {}
  const [emailLink, setEmailLink] = useState(null)
  useEffect(() => {
    const authToken = getSessionToken()
    if (emailTemplateId && authToken) {
      setEmailLink(`${apiUrl}/api/simulation-email-templates/${emailTemplateId}/preview?authToken=${authToken}`)
    } else {
      setEmailLink(null)
    }
  }, [emailTemplateId])

  const emailConfig = getEmailConfig(simulation)
  const { sender } = emailConfig

  // Send Test Email button
  const sendTestVariables = {
    emailTemplateId,
    startDate: moment().toISOString(),
    config: {
      ...emailConfig,
      subject: subject || I18n.t('uPhish.helpers.simulationEmailTemplateTest', { name })
    }
  }

  let senderName
  let senderEmail
  if (_isString(sender)) {
    senderEmail = sender
  } else if (_isObject(sender)) {
    const { custom, name, email, prefix, domain } = sender
    if (_isString(name) && name.length > 0) {
      senderName = name
    }

    if (custom === true) {
      senderEmail = email
    } else if (prefix && domain) {
      senderEmail = `${prefix}@${domain}`
    }
  }

  // This reducer render the detail items
  // It is in place to compensate Ant Design's Descriptions component's poor span handling, it doesn't wrap as expected when a row is full
  // The alternative would be to create our own
  let rowSpan = 0
  const items = [{
    id: 'senderName',
    span: 1,
    allowed: senderName
  }, {
    id: 'senderEmail',
    span: 1,
    allowed: senderEmail
  }, {
    id: 'subject',
    span: 3,
    allowed: subject
  }, {
    id: 'content',
    span: 3,
    allowed: emailLink
  }].filter(item => item.allowed)
    .reduce((acc, item, index, items) => {
      const { id, span } = item
      let itemSpan = span
      const nextItem = items[index + 1]
      if (index === items.length - 1 || (nextItem && (nextItem.span + rowSpan + span) > 3)) {
        itemSpan = 3 - rowSpan
      }
      rowSpan += itemSpan
      rowSpan = rowSpan >= 3 ? 0 : rowSpan

      const label = I18n.t(id, fieldTrOpt)
      const itemProps = { key: `${id}-${index}`, span: itemSpan, label }
      switch (id) {
        case 'subject':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('common.fields.emailSubject')}>{subject}</Descriptions.Item>
          )
          break
        case 'senderName':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('senderName', uPhishTrOpt)}>{senderName}</Descriptions.Item>
          )
          break
        case 'senderEmail':
          acc.push(
            <Descriptions.Item {...itemProps} label={I18n.t('senderEmailAddress', uPhishTrOpt)}>{senderEmail}</Descriptions.Item>
          )
          break
        case 'content':
          delete itemProps.label
          acc.push(
            <Descriptions.Item {...itemProps} className='hide-label-item'>
              <ListHeader>
                <ListHeaderPanel>
                  <h4>{label}</h4>
                </ListHeaderPanel>
                <ListHeaderPanel align='right'>
                  <SendTestEmailButton variables={sendTestVariables} />
                </ListHeaderPanel>
              </ListHeader>
              <Card>
                <PageIframe src={emailLink} />
              </Card>
            </Descriptions.Item>
          )
          break
        default:
          break
      }

      return acc
    }, [])

  return (
    <ViewRecordContentContainer>
      <div dangerouslySetInnerHTML={{ __html: simulation.description }} />
      <DetailContainer>
        <h3>{I18n.t('emailTemplateTitle', trOpt)}</h3>
        <DetailDescriptions column={3} layout='vertical'>
          {items}
        </DetailDescriptions>
      </DetailContainer>
      {
        landingLink &&
          <DetailContainer>
            <h3>{I18n.t('landingPageTitle', trOpt)}</h3>
            <ListHeader>
              <ListHeaderPanel>
                <h4>{landingPageTitle}</h4>
              </ListHeaderPanel>
              <ListHeaderPanel align='right'>
                <Button icon='global' onClick={onLandingClick}>{I18n.t('uPhish.viewLandingPageDetails.openInBrowser')}</Button>
              </ListHeaderPanel>
            </ListHeader>
            <Card>
              <PageIframe src={`${landingLink}&skipCache=true`} />
            </Card>
          </DetailContainer>
      }
    </ViewRecordContentContainer>
  )
}

const ViewRecommendSimulationModal = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [id, setId] = useState(null)

  useImperativeHandle(ref, () => ({
    open: id => {
      setVisible(true)
      setId(id)
    }
  }))

  const closeModal = () => setVisible(false)
  const afterClose = () => {
    setVisible(false)
    setId(null)
  }

  const { loading, error, data } = useQuery(GET_RECOMMENDED_SIMULATION, {
    variables: { id },
    skip: !id
  })
  const simulation = _get(data, 'recommendedSimulation') || {}

  let title = simulation.name || ''
  if (error) {
    title = I18n.t('defaultTitle', trOpt)
  } else if (loading) {
    title = I18n.t('common.loading')
  }

  return (
    <ViewModal
      width='90%'
      bodyStyle={{ paddingTop: 5, height: 'calc(100% - 132px)' }}
      visible={visible}
      onCancel={closeModal}
      afterClose={afterClose}
      title={title}
      footer={
        !loading && !error &&
          <ViewModalFooter>
            <Button type='danger' size='large' onClick={closeModal}>{I18n.t('common.cancel')}</Button>
            {
              id &&
                <Link to={generatePath(routes.UPHISH_CREATE_SIM_FROM_REC, { simulation_id: id })}>
                  <Button type='primary' size='large'>{I18n.t('useThisCampaign', trOpt)}</Button>
                </Link>
            }
          </ViewModalFooter>
      }
      keyboard={false}
    >
      <ViewRecordCard>
        {loading && <LoadingBlock fullScreen={false} loading />}
        {
          id && visible && !loading && (
            error
              ? <ErrorAlerts {...{ error }} defaultError={I18n.t('error', trOpt)} />
              : <ViewRecommendSimulationContent {...{ id, simulation, loading, error }} {...props} />
          )
        }
      </ViewRecordCard>
    </ViewModal>
  )
})

export default connect(
  state => _pick(selectors.session.get(state), ['userId', 'role', 'companyId', 'accountType'])
)(ViewRecommendSimulationModal)
