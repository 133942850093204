import React, { useCallback, useEffect, useState } from 'react'
import { Card, Icon, Tooltip } from 'antd'
import styled from 'styled-components'
import { rgba } from 'polished'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import { CATEGORY_NAMES } from '../../constants/uPhish'

import LocalesIconPopover from '../common/LocalesIconPopover'

const { Meta } = Card

const _TileCard = ({ className, children }) => <div {...{ className }}>{children}</div>
export const TileCard = styled(_TileCard)`
  .ant-card {
    ${({ selected, theme: { primary } }) => selected ? `box-shadow: 0 0 0 3px ${rgba(primary, 0.4)};` : ''};
    border-radius: 5px;
  }
  .ant-card-head {
    border-bottom: none;
    padding: 0 12px;
  }
  .ant-card-head-title {
    padding: 8px 0;
    text-align: right;
  }
`
const _TileCardImage = ({ className, src, onClick }) => (
  <div {...{ className, onClick }}>
    <div style={{ backgroundImage: src ? `url('${src}')` : null }} />
  </div>
)
export const TileCardImage = styled(_TileCardImage)`
  padding: 5px;

  &> div {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 180px;
  }
`

const TemplateTileAction = ({ templateId, id, title, icon, onClick: onClickProp = () => {}, disabled }) => {
  const onClick = useCallback(e => {
    e.stopPropagation()
    onClickProp(templateId)
  }, [templateId, onClickProp])

  return (
    <Tooltip key={id} title={title} placement='bottom'>
      <Icon
        style={disabled ? { cursor: 'not-allowed' } : {}}
        onClick={!disabled && onClick}
        type={icon}
      />
    </Tooltip>
  )
}

const TemplateTile = ({
  className, template, selected, actions: actionSpec, showCategory = false, getPopupContainer, showLocalesIcon = false,
  onClick, onView, onEdit, onDelete, onSendTest
}) => {
  const { id, name, previewThumb, category = null, locales } = template || {}
  const onTileClick = useCallback(() => onClick(id), [onClick, id])

  const [actions, setActions] = useState([])
  useEffect(() => {
    let actions = []
    if (_isArray(actionSpec) && actionSpec.length > 0) {
      actions = actionSpec.map(action => {
        if (_isString(action)) {
          switch (action) {
            case 'view':
              action = {
                id: action,
                title: I18n.t('common.view'),
                icon: 'eye',
                onClick: onView
              }
              break
            case 'edit':
              action = {
                id: action,
                title: I18n.t('common.edit'),
                icon: 'edit',
                onClick: onEdit
              }
              break
            case 'delete':
              action = {
                id: action,
                title: I18n.t('common.delete'),
                icon: 'delete',
                onClick: onDelete
              }
              break
            case 'sendTest':
              action = {
                id: action,
                title: I18n.t('uPhish.sendTestEmail.sendTestEmail'),
                icon: 'mail',
                onClick: onSendTest
              }
              break
            default:
              action = null
          }
        }
        if (_isObject(action)) {
          return (
            <TemplateTileAction templateId={id} {...action} />
          )
        }
        return null
      }).filter(action => !_isNil(action))
    }

    setActions(actions)
  }, [id, actionSpec, onView, onEdit, onDelete, onSendTest])

  return (
    <TileCard {...{ className, selected }}>
      <Card
        hoverable
        title={
          showLocalesIcon
            ? <LocalesIconPopover localeType='content' {...{ locales, getPopupContainer }} />
            : null
        }
        cover={
          <TileCardImage
            src={previewThumb || '/images/templates/custom-template.png'}
            onClick={onTileClick}
          />
        }
        {...{ actions }}
      >
        <Meta
          title={name}
          description={showCategory ? (CATEGORY_NAMES[category] || CATEGORY_NAMES.none) : null}
          onClick={onTileClick}
        />
      </Card>
    </TileCard>
  )
}

export default TemplateTile
