import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Alert, Select } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ListHeader, ListHeaderPanel, SearchBar, PopUpContainer as BasePopUpContainer } from '../common'
import FilterField, { FilterResetButton } from '../common/FilterField'
import LocaleFilterField from '../common/LocaleFilterField'

const { Option } = Select

export const TemplateAlert = styled(Alert)`
  margin-bottom: 15px;

  .ant-alert-message {
    margin-bottom: 0;
    margin-top: 3px;
  }
  .ant-alert-description {
    display: none;
  }
`
export const TemplateSelectedMessage = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-btn {
    position: relative;
    top: -5px;
  }
`

export const ALL_FILTER_VALUE = '[[ALL]]'
export const NONE_FILTER_VALUE = '[[NONE]]'

const PopUpContainer = styled(BasePopUpContainer)`
  .ant-select-tree {
    padding: 0 10px;
  }

  .ant-select-tree li span.ant-select-tree-switcher {
    display: none;
  }
`

const TemplateHeader = ({
  type, setType = () => {},
  searchText, setSearchText = () => {}, searchPlaceholder = I18n.t('common.search'),
  category = ALL_FILTER_VALUE, setCategory = () => {}, categories,
  contentLocales = [], setContentLocales = () => {},
  contentLocaleCounts = {}, showContentLocaleCounts = false, defaultContentLocales,
  loading = false
}) => {
  const popupContainerRef = useRef(null)
  const getPopupContainer = useCallback(() => {
    const { current: popupContainer } = popupContainerRef
    return popupContainer || document.body
  }, [popupContainerRef])
  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const onTypeChange = useCallback(type => setType(type), [setType])
  const onCategoryChange = useCallback(category => {
    setCategory(category)
  }, [setCategory])

  const onContentLocalesChange = useCallback(contentLocales => setContentLocales(contentLocales), [setContentLocales])
  const resetContentLocales = useCallback(() => setContentLocales(defaultContentLocales || []), [setContentLocales, defaultContentLocales])

  const [hasFilters, setHasFilters] = useState(false)
  const [allowContentLocaleReset, setAllowContentLocaleReset] = useState(false)
  const onResetClick = useCallback(() => {
    setType(ALL_FILTER_VALUE)
    setCategory(ALL_FILTER_VALUE)
    resetContentLocales()
  }, [setType, setCategory, resetContentLocales])

  useEffect(() => {
    const allowContentLocaleReset = (
      defaultContentLocales
        ? !(defaultContentLocales.every(locale => contentLocales.includes(locale)) && contentLocales.every(locale => defaultContentLocales.includes(locale))) // Content locales differ from default
        : contentLocales.length > 0 // Has content locales but no default
    )
    setHasFilters(
      [type, category].some(v => v !== ALL_FILTER_VALUE) || allowContentLocaleReset
    )
    setAllowContentLocaleReset(allowContentLocaleReset)
  }, [type, category, contentLocales, defaultContentLocales])

  return (
    <>
      <PopUpContainer ref={popupContainerRef} />
      <ListHeader>
        <ListHeaderPanel>
          <FilterField width={100}>
            <label>{I18n.t('common.type')}</label>
            <Select value={type} onChange={onTypeChange} disabled={loading}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              <Option value='default'>{I18n.t('common.default')}</Option>
              <Option value='custom'>{I18n.t('common.custom')}</Option>
            </Select>
          </FilterField>
          <FilterField width={230}>
            <label>{I18n.t('common.category')}</label>
            <Select value={category} onChange={onCategoryChange} disabled={loading}>
              <Option value={ALL_FILTER_VALUE}>{I18n.t('common.all')}</Option>
              {
                categories.map(({ value, label }) => (
                  <Option key={value} value={value === 'none' ? NONE_FILTER_VALUE : value}>{label}</Option>
                ))
              }
            </Select>
          </FilterField>
          <LocaleFilterField
            type='content'
            hasDefaultContentLocales={defaultContentLocales && defaultContentLocales.length > 0}
            {...{
              loading,
              showContentLocaleCounts,
              contentLocales,
              onContentLocalesChange,
              getPopupContainer,
              resetContentLocales,
              contentLocaleCounts,
              allowContentLocaleReset
            }}
          />
          {hasFilters && <FilterResetButton onClick={onResetClick} />}
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <SearchBar
            placeholder={searchPlaceholder}
            value={searchText}
            allowClear
            onChange={onSearchChange}
          />
        </ListHeaderPanel>
      </ListHeader>
    </>
  )
}

export default TemplateHeader
