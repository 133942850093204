import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { generatePath, Link, withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Card, Icon, Layout, Tooltip, message } from 'antd'
import compose from 'recompose/compose'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _isNil from 'lodash/isNil'
import _keyBy from 'lodash/keyBy'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _set from 'lodash/set'
import _uniq from 'lodash/uniq'
import _throttle from 'lodash/throttle'
import I18n from 'i18n-js'

import routes from '../../constants/routes'
import { CREATE_COURSE, GET_COURSE, UPDATE_COURSE } from '../Queries/Courses'
import { MutationFormInput, MutationFormTextArea } from '../MutationForm'
import MutationForm from '../MutationForm/MutationForm'
import { GET_COMPANIES } from '../Queries/Companies'
import { ErrorAlerts, FontAwesomeIcon, ListHeader, ListHeaderPanel, LoadingBlock } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import {
  buildCompanyOptions,
  getCompanyAccessExtra,
  getCompanyAccessLabel,
  getCompanyAccessPlaceholder,
  getGlobalExtra,
  getGlobalLabel,
  managedByUsecure
} from '../../helpers/company'
import { base64EncodeJSON, renderToString } from '../../helpers'
import LanguageDropdown from '../common/LanguageDropdown'
import { getCourseCategoryOptionsByLocale, getCourseSubjectOptionsByLocale } from '../../constants/courses'
import { DEFAULT_LANGUAGE, LANGUAGE_NAMES_BY_CODE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { getCoursesRefetchQueries } from '../../helpers/courses'
import { COURSE_BUILDER_ONLY_ROLES } from '../../constants/roles'
import IntercomHeader from '../IntercomHeader'

const Content = Layout.Content

const trOpt = { scope: 'editCourse.editCourseForm' }

const isIncludedInAutoEnrolAllowed = values => {
  return managedByUsecure(values) &&
    [1, 2, 3, 4].includes(values.difficulty) &&
    values.subject === 'InfoSec' &&
    values.global === true
}

const isLocaleFieldValueEmpty = localeValue => !(_isString(localeValue) && !_isEmpty(localeValue.replace(/\s/g, '')))
const mutateLocaleFieldValue = (values, fieldId) => base64EncodeJSON(_get(values, `details.${fieldId}`) || {})

const nameAndDescriptionValidator = (value, errors, opt, values) => {
  const { locale: sourceLocale = DEFAULT_LANGUAGE } = values || {}
  const locales = _uniq([sourceLocale, ...Object.keys(value.name || {}), ...Object.keys(value.description || {})])
  if (locales.length > 0) {
    locales.forEach(locale => {
      const isSource = locale === sourceLocale
      const localeName = _get(value, `name.${locale}`)
      const nameValid = !isLocaleFieldValueEmpty(localeName)
      const localeDescription = _get(value, `description.${locale}`)
      const descriptionValid = !isLocaleFieldValueEmpty(localeDescription)
      if ((!nameValid && descriptionValid) || (isSource && !nameValid) || (localeName && !nameValid)) {
        errors.push({ fieldId: 'name', text: I18n.t('pleaseAddACourseTitle', { ...trOpt, language: LANGUAGE_NAMES_BY_CODE[locale] }) })
      }
      if ((nameValid && !descriptionValid) || (isSource && !descriptionValid) || (localeDescription && !descriptionValid)) {
        errors.push({ fieldId: 'description', text: I18n.t('pleaseAddACourseDescription', { ...trOpt, language: LANGUAGE_NAMES_BY_CODE[locale] }) })
      }
    })
  }
}

const NameAndDescriptionFieldContainer = styled.div`
  margin-bottom: 24px;

  .ant-form-item {
    margin-bottom: 0;
  }
`

const NameMutationFormInput = styled(MutationFormInput)`
  &> .ant-form-item-label {
    display: block;

    &> label {
      display: block;

      &::after {
        display: none;
      }

      .ant-form-item-label {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

const NameAndDescriptionField = React.forwardRef(({
  id, value, visible, onChange = () => {}, errors = [], sourceLocale = DEFAULT_LANGUAGE
}, ref) => {
  const [locale, setLocale] = useState(sourceLocale)
  const name = _get(value, `name.${locale}`)
  const description = _get(value, `description.${locale}`)

  useImperativeHandle(ref, () => ({
    validate: nameAndDescriptionValidator
  }), [])

  const onFieldChange = useCallback((fieldId, fieldValue) => {
    _set(value, `${fieldId}.${locale}`, fieldValue)
    onChange(id, value)
  }, [id, locale, value, onChange])

  return (
    <NameAndDescriptionFieldContainer>
      <NameMutationFormInput {...{
        id: 'name',
        label: (
          <div className='ant-col ant-form-item-label'>
            <label className='ant-form-item-required' title={I18n.t('common.courseTitle')}>{I18n.t('common.courseTitle')}</label>
            <LanguageDropdown value={locale} onChange={setLocale} />
          </div>
        ),
        placeholder: I18n.t('common.courseTitle'),
        value: name,
        formItemStyle: { maxWidth: 600 },
        onChange: onFieldChange,
        visible,
        errors: errors.filter(e => e.fieldId === 'name').map(e => e.text)
      }}
      />
      <MutationFormTextArea {...{
        id: 'description',
        label: <label className='ant-form-item-required' title={I18n.t('courseDescription', trOpt)}>{I18n.t('courseDescription', trOpt)}</label>,
        value: description,
        type: 'textarea',
        formItemStyle: { maxWidth: 600 },
        textAreaStyle: { minHeight: 140 },
        onChange: onFieldChange,
        visible,
        errors: errors.filter(e => e.fieldId === 'description').map(e => e.text)
      }}
      />
    </NameAndDescriptionFieldContainer>
  )
})

const EditCourseErrors = ({ error, defaultError, isUpdate }) => (
  <Card>
    <h1>{isUpdate ? I18n.t('courses.common.editCourse') : I18n.t('courses.createCourse')}</h1>
    <ErrorAlerts {...{ error, defaultError }} />
  </Card>
)

const EditCourseMutationForm = styled(MutationForm)`
  .edit-course-icon {
    max-width: 800px;

    .ant-input {
      max-width: 250px;
    }
  }
`

const EditCourseIconExtra = styled.div`
  &> span {
    display: block;
  }
`

// Awkward method of detection for font-awesome icon support by whether i rendered with a height > 0px
// Ideally we'd check against a list of icon ids
let intv
const resetIconHeightCheck = () => {
  if (intv) {
    clearInterval(intv)
    intv = null
  }
}
const checkIconHeight = _throttle((iconRef, setValid) => {
  const start = Date.now()
  resetIconHeightCheck()
  intv = setInterval(() => {
    const { current: iconEl } = iconRef
    if (iconEl?.offsetHeight > 0 || Date.now() - start > 1000) {
      setValid(iconEl?.offsetHeight > 0)
      resetIconHeightCheck()
    }
  }, 100)
}, 500)

const _CourseIconPreview = ({ className, icon }) => {
  const [valid, setValid] = useState(false)
  const iconRef = useRef(null)

  useEffect(() => {
    if (icon) {
      setValid(true)
      checkIconHeight(iconRef, setValid, icon)
    }
  }, [iconRef, icon])

  return (
    <div className={className}>
      <FontAwesomeIcon ref={iconRef} icon={icon} />
      {!valid && (
        <Tooltip title={I18n.t('courseIconNotSupported', trOpt)} placement='bottom'>
          <Icon type='question-circle' />
        </Tooltip>
      )}
    </div>
  )
}
const CourseIconPreview = styled(_CourseIconPreview)`
  align-items: center;
  border: dashed 1px #e5e5e5;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 5px;
  width: 50px;

  i {
    font-size: 20px;

    &.anticon {
      color: ${({ theme }) => theme.red};
    }
  }
`

const EditCourseForm = ({
  courseId, role, companyId, accountType, locale, companyLocale: companyDefaultLocale,
  history, onSuccess: onSuccessProp, onFailure
}) => {
  const isUpdate = !_isNil(courseId)
  const isUsecureAdmin = role === 'usecure-admin'
  const isAdmin = isUsecureAdmin || role === 'admin'
  const isUsecureCourseBuilder = isUsecureAdmin || role === 'usecure-course-builder'
  const skipGetCompanies = isUsecureAdmin ? false : (accountType === 'tenant' || COURSE_BUILDER_ONLY_ROLES.includes(role))

  const { loading: courseLoading, error: courseError, data: courseData = {} } = useQuery(GET_COURSE, {
    variables: { courseId },
    skip: !isUpdate
  })
  const { loading: companiesLoading, error: companiesError, data: companiesData } = useQuery(GET_COMPANIES, {
    variables: { withAccountType: true, withParentCompanyId: true, descendants: !isUsecureAdmin },
    skip: skipGetCompanies
  })

  const loading = courseLoading || companiesLoading
  const { course } = courseData || {}
  const { companies = [] } = companiesData || {}

  const form = useRef(null)
  useEffect(() => {
    if (course && !isUsecureCourseBuilder && course.companyId !== companyId) {
      message.error(I18n.t('editCourse.common.youAreNotAllowedToEditThisCourse'))
      history.push(routes.BUILDER)
    }
  }, [course, isUsecureCourseBuilder, companyId, history])
  const editContentPath = isUpdate
    ? generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseId })
    : null

  const { companyMap, companySelect } = useMemo(() => {
    const companyMap = _keyBy(companies, 'id')
    let companySelect = []
    if (isUsecureAdmin) {
      companySelect = buildCompanyOptions(
        companies.map(company => {
          const linkFieldValue = ['usecure', null]
          if (company.parentCompanyId) {
            linkFieldValue.push(company.parentCompanyId)
            const parentCompany = companyMap[company.parentCompanyId]
            if (parentCompany && parentCompany.accountType === 'msp' && parentCompany.parentCompanyId) {
              linkFieldValue.push(parentCompany.parentCompanyId)
            }
          }
          return { ...company, linkFieldValue }
        })
      )
    } else {
      companySelect = buildCompanyOptions(companies)
    }

    return {
      companyMap,
      companySelect
    }
  }, [companies, isUsecureAdmin])

  const subjects = useMemo(() => getCourseSubjectOptionsByLocale(locale), [locale])
  const categories = useMemo(() => getCourseCategoryOptionsByLocale(locale), [locale])

  const onSuccess = useCallback(result => {
    if (_isFunction(onSuccessProp)) {
      onSuccessProp(result)
    }
    if (isUpdate) {
      message.success(I18n.t('successfullyEditedCourse', trOpt))
    } else {
      message.success(I18n.t('successfullyCreatedCourse', trOpt))
    }
  }, [isUpdate, onSuccessProp])

  const getAccessType = useCallback(values => {
    if (managedByUsecure(values)) {
      return 'usecure'
    }
    const viewAccountType = isUsecureAdmin ? _get(companyMap, `${values.companyId}.accountType`) : accountType
    return viewAccountType || null
  }, [companyMap, isUsecureAdmin, accountType])

  const isGlobalAllowed = useCallback(values => {
    return ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
  }, [getAccessType])

  const isCompanyAccessAllowed = useCallback(values => {
    return values.global !== true && ['usecure', 'distributor', 'msp'].includes(getAccessType(values))
  }, [getAccessType])

  const { tabs, fields, refetchQueries } = useMemo(() => {
    let localeValue = DEFAULT_LANGUAGE
    if (course && course.locale) {
      localeValue = course.locale
    } else if (companyDefaultLocale) {
      localeValue = companyDefaultLocale
    }

    const tabs = [{
      id: 'content',
      title: I18n.t('common.courseLabel')
    }]

    const fields = [
      {
        id: 'details',
        component: NameAndDescriptionField,
        type: 'custom',
        defaultValue: {
          name: _get(course, 'nameObject') || {},
          description: _get(course, 'descriptionObject') || {}
        },
        sourceLocale: localeValue,
        tab: 'content'
      },
      {
        label: I18n.t('courses.common.locale'),
        id: 'locale',
        type: 'select',
        required: true,
        defaultValue: localeValue,
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true,
        tab: 'content'
      },
      {
        label: I18n.t('courses.common.additionalLocales'),
        id: 'additionalLocales',
        type: 'multiSelect',
        defaultValue: _get(course, 'additionalLocales') || [],
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true,
        extra: I18n.t('additionalLocalesExtra', trOpt),
        tab: 'content'
      }
    ]

    if (isUsecureAdmin) {
      fields.push({
        label: I18n.t('courseDifficulty', trOpt),
        id: 'difficulty',
        required: true,
        defaultValue: _get(course, 'difficulty'),
        visible: managedByUsecure,
        type: 'number',
        tab: 'content'
      },
      {
        label: I18n.t('courseType', trOpt),
        id: 'subject',
        placeholder: I18n.t('courseType', trOpt),
        required: true,
        defaultValue: _get(course, 'subject'),
        visible: managedByUsecure,
        type: 'select',
        options: subjects,
        formItemStyle: { maxWidth: 150 },
        tab: 'content'
      },
      {
        label: I18n.t('courseCategory', trOpt),
        id: 'category',
        placeholder: I18n.t('courseCategory', trOpt),
        required: false,
        defaultValue: _get(course, 'category'),
        visible: managedByUsecure,
        type: 'select',
        options: categories,
        formItemStyle: { maxWidth: 250 },
        tab: 'content'
      })
    }
    fields.push({
      label: I18n.t('courseIcon', trOpt),
      id: 'icon',
      required: true,
      defaultValue: _get(course, 'icon'),
      extra: values => (
        <EditCourseIconExtra>
          <span
            dangerouslySetInnerHTML={{
              __html: I18n.t('chooseFromAnyIconListed', {
                ...trOpt,
                fontAwesomeLink: renderToString(<a href='https://fontawesome.com/v5/search?m=free' target='_blank' rel='noopener noreferrer'>{I18n.t('chooseFromAnyIconListedLink', trOpt)}</a>)
              })
            }}
          />
          <span>{I18n.t('courseIconExtraHelp', trOpt)}</span>
          <CourseIconPreview icon={values.icon} />
        </EditCourseIconExtra>
      ),
      className: 'edit-course-icon',
      tab: 'content'
    },
    {
      label: I18n.t('courseBackgroundImage', trOpt),
      id: 'backgroundImage',
      required: false,
      defaultValue: _get(course, 'backgroundImage'),
      type: 'image',
      tab: 'content'
    },
    {
      label: I18n.t('randomizeQuestionOrder', trOpt),
      id: 'randomizeQuestionOrder',
      required: false,
      type: 'checkbox',
      defaultValue: _get(course, 'randomizeQuestionOrder', false),
      extra: I18n.t('randomizeQuestionOrderExtra', trOpt),
      tab: 'content'
    },
    {
      label: I18n.t('questionLimit', trOpt),
      id: 'questionCount',
      required: false,
      type: 'number',
      defaultValue: _get(course, 'questionCount'),
      visible: ({ randomizeQuestionOrder }) => randomizeQuestionOrder,
      tab: 'content'
    })

    if (isUsecureAdmin) {
      fields.push({
        label: I18n.t('includedInAutoEnrol', trOpt),
        id: 'includedInAutoEnrol',
        type: 'checkbox',
        defaultValue: _get(course, 'includedInAutoEnrol') === true,
        mutateValue: (value, values) => value && isIncludedInAutoEnrolAllowed(values),
        visible: values => isIncludedInAutoEnrolAllowed(values),
        tab: 'content'
      })
    }

    if (isUsecureAdmin || (isAdmin && ['distributor', 'msp'].includes(accountType))) {
      tabs.push({
        id: 'access',
        title: I18n.t('common.accessControl.tabTitle'),
        visible: isUsecureAdmin ? true : values => isGlobalAllowed(values) || isCompanyAccessAllowed(values)
      })
      fields.push({
        label: I18n.t('common.accessControl.ownedBy'),
        id: 'companyId',
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...companySelect
        ],
        defaultValue: _get(course, 'companyId') || (isUsecureCourseBuilder ? 'usecure' : companyId),
        mutateValue: value => value === 'usecure' ? null : value,
        visible: isUsecureAdmin,
        formItemStyle: { maxWidth: 400 },
        tab: 'access'
      }, {
        label: values => getGlobalLabel(getAccessType(values), I18n.t('common.course'), isUsecureAdmin),
        id: 'global',
        required: false,
        type: 'checkbox',
        defaultValue: _get(course, 'global', false),
        mutateValue: (value, values) => isGlobalAllowed(values) ? value : false,
        visible: isGlobalAllowed,
        extra: values => getGlobalExtra(getAccessType(values), I18n.t('common.course'), isUsecureAdmin),
        tab: 'access'
      }, {
        id: 'companies',
        label: values => getCompanyAccessLabel(getAccessType(values)),
        type: 'multiSelect',
        required: false,
        placeholder: values => getCompanyAccessPlaceholder(getAccessType(values)),
        extra: values => getCompanyAccessExtra(getAccessType(values), I18n.t('common.course')),
        options: companySelect,
        defaultValue: _get(course, 'companies', []).map(({ id }) => id),
        mutateValue: (value, values) => isCompanyAccessAllowed(values) ? (value || []) : [],
        visible: isCompanyAccessAllowed,
        formItemStyle: { maxWidth: 800 },
        linkField: isUsecureAdmin ? 'companyId' : null,
        linkFieldValue: isUsecureAdmin ? 'ancestors' : null,
        tab: 'access'
      })
    }

    return {
      tabs,
      fields,
      refetchQueries: getCoursesRefetchQueries(isUsecureAdmin)
    }
  }, [
    course, companySelect, subjects, categories, companyId, companyDefaultLocale,
    isAdmin, isUsecureAdmin, isUsecureCourseBuilder, isGlobalAllowed, isCompanyAccessAllowed, getAccessType,
    accountType
  ])

  const mutateValues = formValues => {
    return {
      ..._omit(formValues, ['details']),
      name: mutateLocaleFieldValue(formValues, 'name'),
      description: mutateLocaleFieldValue(formValues, 'description'),
      questionCount: formValues.randomizeQuestionOrder ? formValues.questionCount : null
    }
  }

  if (loading) return <LoadingBlock loading={loading} fullScreen={false} showMenu />
  if (courseError) return <EditCourseErrors {...{ isUpdate }} error={courseError} defaultError={I18n.t('errorLoadingCourse', trOpt)} />
  if (companiesError) return <EditCourseErrors {...{ isUpdate }} error={companiesError} defaultError={I18n.t('common.companiesLoadError')} />

  return (
    <Card>
      <ListHeader vAlign='top'>
        <ListHeaderPanel>
          {
            isUpdate
              ? <IntercomHeader Size='h1' id='course-update-header'>{I18n.t('common.uLearn')} - {I18n.t('common.editItemName', { name: course.name })}</IntercomHeader>
              : <IntercomHeader Size='h1' id='course-create-header'>{I18n.t('common.uLearn')} - {I18n.t('courses.createCourse')}</IntercomHeader>
          }
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <Link to={routes.BUILDER}>
            <Button type='primary'>{I18n.t('editCourse.common.backToCourseBuilder')}</Button>
          </Link>
          {
            isUpdate
              ? (
                <Link to={editContentPath}>
                  <Button icon='play-square' type='primary'>{I18n.t('editCourseSlides', trOpt)}</Button>
                </Link>
              ) : null
          }
        </ListHeaderPanel>
      </ListHeader>
      <Layout>
        <Content>
          <EditCourseMutationForm
            ref={form}
            mutation={isUpdate ? UPDATE_COURSE : CREATE_COURSE}
            onSuccess={onSuccess}
            onFailure={onFailure}
            mutateValues={mutateValues}
            failureMessage={isUpdate ? I18n.t('failedToUpdateCourse', trOpt) : I18n.t('failedToCreateCourse', trOpt)}
            submitIcon='save'
            submitLabel={isUpdate ? I18n.t('courses.common.updateCourse') : I18n.t('courses.createCourse')}
            tabs={tabs}
            fields={fields}
            variables={{ courseId }}
            skipResetFieldsOnSubmit={!isUpdate}
            disableSubmitOnEnter
            disableSubmitIfInvalid={false}
            refetchQueries={refetchQueries}
          />
        </Content>
      </Layout>
    </Card>
  )
}

export default compose(
  withRouter,
  connect(state => _pick(selectors.session.get(state), ['role', 'companyId', 'accountType', 'locale', 'companyLocale']))
)(EditCourseForm)
