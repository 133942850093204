import React, { useState, useCallback, useMemo, useImperativeHandle, useEffect, useRef } from 'react'
import { Modal, message, Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'

import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import BreachesTable from '../../uBreach/BreachesTable'
import { WRITABLE_UBREACH_ACTIONS } from '../../../constants/actions'
import { ListHeader, ListHeaderPanel, ListViewActions, ErrorAlerts, SearchBar, LoadingBlock, ModalPager } from '../../common'
import { disableActions, searchRecords } from '../../../helpers/listPages'
import ViewRecordCard from '../../common/ViewRecordCard'
import MarkResolvedConfirm from './MarkResolvedConfirm'

const uBreachTrOpt = { scope: 'uBreach' }

const UserBreachesModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;
  
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ModalScroller = styled.div`
  height: 100%;
  overflow: auto;
`

const ModalPagerPlaceholder = styled.div`
  height: 32px;
`

const getRowActions = (disabledActions) => {
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', uBreachTrOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const getSelectionActions = (selection, disabledActions) => {
  if (selection.length === 1) return getRowActions(disabledActions)
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', uBreachTrOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const UserBreaches = ({ allRecords, loading, user, searchText, setSearchText, selectedIds, setSelectedIds, planValid, uBreachProEnabled, breachedServices, breachedUsers, refetchQueries, handleRefreshClick }) => {
  const markResolvedConfirmRef = useRef(null)
  const openMarkResolvedConfirm = useCallback((records) => {
    if (markResolvedConfirmRef.current) {
      markResolvedConfirmRef.current.open(records)
    }
  }, [markResolvedConfirmRef])
  const disabledActions = useMemo(() => !planValid ? WRITABLE_UBREACH_ACTIONS : null, [planValid])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const records = allRecords.map(breach => {
      const { id } = breach
      const record = {
        key: id,
        ...breach,
        breachId: id,
        email: user.email
      }
      record.actions = getRowActions([
        ...(disabledActions || []),
        ...(record.resolved ? ['markResolved'] : [])
      ])

      return record
    })
    return searchRecords({ records, searchText, searchKeys: ['breachTitle'] })
  }, [allRecords, searchText, disabledActions, user])

  const performAction = useCallback((action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.id === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.id))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }

    switch (action) {
      case 'markResolved':
        openMarkResolvedConfirm(
          actionRecords.map(record => ({ breachName: record.breachName, email: user.email, learnerId: user.learnerId }))
        )
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openMarkResolvedConfirm, user])

  const listActions = useMemo(() =>
    getSelectionActions(allRecords.filter(record => selectedIds.includes(record.key)), disabledActions),
  [selectedIds, allRecords, disabledActions])

  return (
    <ModalScroller>
      <ViewRecordCard>
        <div className='view-record__body'>
          <div className='view-record__header'>
            <div>
              <h1>{loading || !user ? I18n.t('common.loading') : user.email}</h1>
            </div>
          </div>
          <ListHeader>
            <ListHeaderPanel align='left' />
            <ListHeaderPanel align='right'>
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </ListHeaderPanel>
          </ListHeader>
          <ListHeader>
            <ListHeaderPanel align='left'>
              {I18n.t('totalBreachCount', { ...uBreachTrOpt, count: allRecords.length })}
            </ListHeaderPanel>
            <ListHeaderPanel align='right'>
              <SearchBar
                placeholder={I18n.t('common.searchUserBreaches', uBreachTrOpt)}
                value={searchText}
                allowClear
                onChange={onSearchChange}
              />
              {uBreachProEnabled && (
                <ListViewActions
                  actions={listActions}
                  performAction={performAction}
                  selectedIds={selectedIds}
                />
              )}
            </ListHeaderPanel>
          </ListHeader>
          {
            records && !loading && (
              <BreachesTable
                isLearner
                showActions={uBreachProEnabled}
                updateSelectedIds={setSelectedIds}
                {...{
                  breaches: records,
                  performAction,
                  selectedIds,
                  breachedServices,
                  breachedUsers
                }}
              />
            )
          }
        </div>
      </ViewRecordCard>
      <MarkResolvedConfirm ref={markResolvedConfirmRef} {...{ refetchQueries }} type='userBreaches' />
    </ModalScroller>
  )
}

const UserBreachesDetailsModal = React.forwardRef(({ planValid, uBreachProEnabled, breachedUsers = [], breachedServices = [], error, refetchQueries, handleRefreshClick }, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [pager, setPager] = useState({})
  const [searchText, setSearchText] = useState('')
  const [selectedIds, setSelectedIds] = useState([])
  const [allRecords, setAllRecords] = useState([])
  const [email, setEmail] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (email) {
      const user = breachedUsers.find(user => user.email === email)
      const breaches = user ? user.breaches.map(({ name, title, resolved, breachedData = [], hasExposedInfoData }) => {
        const breachedService = breachedServices.find(service => service.name === name)
        return {
          id: name,
          breachName: name,
          breachTitle: title,
          dataClasses: breachedService ? breachedService.dataClasses : [],
          severity: breachedService ? breachedService.category : null,
          breachDate: breachedService ? breachedService.breachDate : null,
          resolved: resolved,
          breachedData,
          hasExposedInfoData
        }
      }) : []
      setAllRecords(breaches)
      setUser(user)
    }
  }, [email, breachedUsers, breachedServices])

  useImperativeHandle(ref, () => ({
    open: async ({ record: { email }, pager }) => {
      setLoading(true)
      setVisible(true)

      setEmail(email)
      setPager(pager)
      setLoading(false)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setLoading(false)
    setAllRecords([])
    setEmail(null)
    setUser(null)
    setSearchText('')
    setSelectedIds([])
    setPager({})
  }

  return (
    <UserBreachesModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      afterClose={afterClose}
      width='90%'
      bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
      title={loading ? <ModalPagerPlaceholder /> : <ModalPager {...pager} />}
    >
      <LoadingBlock loading={loading} fullScreen={false} />
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourBreachesCouldNotBeLoaded', uBreachTrOpt)} />
          : (
            <UserBreaches
              {...{
                allRecords,
                email,
                searchText,
                setSearchText,
                selectedIds,
                setSelectedIds,
                planValid,
                loading,
                uBreachProEnabled,
                breachedServices,
                breachedUsers,
                user,
                refetchQueries,
                handleRefreshClick
              }}
            />
          )
      }
    </UserBreachesModal>
  )
})

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(UserBreachesDetailsModal)
