import gql from 'graphql-tag'

import { PROSPECT_RISK_REPORT_FIELDS } from './Companies'

export const CREATE_SIMULATION = gql`
  mutation ($name: String!, $landingPageId: ID!, $emailTemplateId: ID, $startDate: DateTime!, $config: JSON!, $recipients: SimulationRecipients!, $sendToAll: Boolean) {
    createSimulation (name: $name, landingPageId: $landingPageId, emailTemplateId: $emailTemplateId, startDate: $startDate, config: $config, recipients: $recipients, sendToAll: $sendToAll) {
      id
      name
      landingPageId
      emailTemplateId
      config
      status
      startDate
    }
  }
`

export const UPDATE_SIMULATION = gql`
  mutation ($id: ID!, $name: String!, $landingPageId: ID!, $emailTemplateId: ID, $startDate: DateTime!, $config: JSON!, $recipients: SimulationRecipients!, $sendToAll: Boolean) {
    updateSimulation (id: $id, name: $name, landingPageId: $landingPageId, emailTemplateId: $emailTemplateId, startDate: $startDate, config: $config, recipients: $recipients, sendToAll: $sendToAll) {
      id
      name
      landingPageId
      emailTemplateId
      config
      status
      startDate
    }
  }
`

export const DELETE_SIMULATIONS = gql`
  mutation($simulationIds: [ID!]!) {
    deleteSimulations(simulationIds: $simulationIds)
  }
`

export const GET_SIMULATIONS = gql`
query {
  simulations {
    id
    name
    status
    startDate
    endDate
    autoPhish
    recipientCount
    sentCount
    openCount
    visitCount
    compromiseCount
    reportCount
  }
}
`

export const GET_SIMULATION_FOR_UPDATE = gql`
  query ($id: ID!) {
    simulation(id: $id) {
      id
      name
      landingPageId
      emailTemplateId
      config
      status
      startDate
      queue {
        learner {
          id
          name
          lastName
        }
      }
    }
  }
`

export const GET_SIMULATION = gql`
  query ($id: ID!, $excludeInactiveUsers: Boolean) {
    simulation(id: $id, excludeInactiveUsers: $excludeInactiveUsers) {
      id
      name
      companyId
      status
      startDate
      endDate
      autoPhish
      recipientCount
      sentCount
      openCount
      visitCount
      compromiseCount
      reportCount
      config
      excludesInactiveUsers
      landingPageId
      landingPage {
        name
        content
        previewThumb
        custom
      }
      emailTemplateId
      emailTemplate {
        name
        previewThumb
        legacy
        content
        custom
      }
      queue {
        learner {
          id
          name
          firstName
          lastName
          email
          inactive
          isManager
        }
        scheduled
      }
      results {
        open
        visit
        compromise
        report
        learner {
          id
          name
          firstName
          lastName
          email
          inactive
          isManager
        }
        deliveryMethod
        createdAt
      }
    }
  }
`

export const GET_SIMULATION_DOMAINS = gql`
  query {
    simulationDomains
  }
`
export const GET_SIMULATION_TLDS = gql`
  query {
    simulationTLDs
  }
`

export const GET_SIMULATION_LANDING_PAGE_TEMPLATES = gql`
  query ($defaultOnly: Boolean, $restrictToPlan: Boolean, $restrictToOwn: Boolean) {
    simulationLandingPages (defaultOnly: $defaultOnly, restrictToPlan: $restrictToPlan, restrictToOwn: $restrictToOwn) {
      id
      name
      locales
      previewThumb
      custom
      category
      companyId
      company {
        id
        name
      }
    }
  }
`

export const GET_SIMULATION_LANDING_PAGE_TEMPLATE = gql`
  query ($id: ID!) {
    simulationLandingPage (id: $id) {
      id
      name
      locales
      content
      previewThumb
      pageTitle
      tile
      category
      emailTemplateIds
      emailTemplates {
        id
        name
        previewThumb
        locales
      }
      companyId
      company {
        id
        name
      }
      global
      companies {
        id
        name
      }
      custom
    }
  }
`

export const GET_SIMULATION_LANDING_PAGE_EMAIL_TEMPLATES = gql`
  query ($id: ID!) {
    simulationLandingPage (id: $id) {
      emailTemplates {
        id
        name
        locales
        previewThumb
        custom
        companyId
        company {
          id
          name
        }
      }
    }
  }
`

export const CREATE_SIMULATION_LANDING_PAGE_TEMPLATE = gql`
  mutation (
    $name: String!, $locales: [ContentLocale!], $content: JSON, $pageTitle: String, $tile: String, $category: SimulationCategory, $emailTemplates: [String!],
    $global: Boolean, $companies: [ID!], $companyId: ID
  ) {
    createSimulationLandingPage(
      name: $name, locales: $locales, content: $content, pageTitle: $pageTitle, tile: $tile, category: $category, 
      emailTemplates: $emailTemplates, global: $global, companies: $companies, companyId: $companyId
    ) {
      id
      name
      locales
      content
      previewThumb
      pageTitle
      tile
      category
      emailTemplateIds
      emailTemplates {
        id
        name
      }
      companyId
      company {
        id
        name
      }
      global
      companies {
        id
        name
      }
      custom
    }
  }
`

export const UPDATE_SIMULATION_LANDING_PAGE_TEMPLATE = gql`
  mutation (
    $id: ID!, $name: String!, $locales: [ContentLocale!], $content: JSON, $pageTitle: String, $tile: String, $category: SimulationCategory, 
    $emailTemplates: [String!], $global: Boolean, $companies: [ID!], $companyId: ID
  ) {
    updateSimulationLandingPage(
      id: $id, name: $name, locales: $locales, content: $content, pageTitle: $pageTitle, tile: $tile, category: $category, 
      emailTemplates: $emailTemplates, global: $global, companies: $companies, companyId: $companyId
    ) {
      id
      name
      locales
      content
      previewThumb
      pageTitle
      tile
      category
      emailTemplateIds
      emailTemplates {
        id
        name
      }
      companyId
      company {
        id
        name
      }
      global
      companies {
        id
        name
      }
      custom
    }
  }
`

export const GET_SIMULATION_EMAIL_TEMPLATES = gql`
  query ($defaultOnly: Boolean, $restrictToPlan: Boolean, $restrictToOwn: Boolean, $defaultAll: Boolean, $companyId: ID) {
    simulationEmailTemplates (defaultOnly: $defaultOnly, restrictToPlan: $restrictToPlan, restrictToOwn: $restrictToOwn, defaultAll: $defaultAll, companyId: $companyId) {
      id
      name
      locales
      previewThumb
      tile
      category
      custom
      companyId
      company {
        id
        name
      }
    }
  }
`

export const GET_SIMULATION_EMAIL_TEMPLATE = gql`
  query ($id: ID!) {
    simulationEmailTemplate (id: $id) {
      id
      name
      locales
      previewThumb
      tile
      category
      subject
      content
      legacy
      customSender
      senderName
      senderEmail
      senderPrefix
      senderDomain
      senderDomains
      availableSenderDomains
      links
      availableLinks
      custom
      companyId
      company {
        id
        name
      }
      global
      companies {
        id
        name
      }
    }
  }
`

export const CREATE_SIMULATION_EMAIL_TEMPLATE = gql`
  mutation (
    $name: String!, $locales: [ContentLocale!], $subject: String, $content: JSON, $legacy: Boolean, $customSender: Boolean, $senderName: String, $senderEmail: String, $senderPrefix: String, $senderDomain: String,
    $global: Boolean, $companies: [ID!], $companyId: ID, $senderDomains: [String!], $category: SimulationCategory, $tile: String
  ) {
    createSimulationEmailTemplate(
      name: $name, locales: $locales, subject: $subject, content: $content, legacy: $legacy, customSender: $customSender, senderName: $senderName, senderEmail: $senderEmail, senderPrefix: $senderPrefix, senderDomain: $senderDomain,
      global: $global, companies: $companies, companyId: $companyId, senderDomains: $senderDomains, category: $category, tile: $tile
    ) {
      id
      name
      locales
      previewThumb
      category
      subject
      content
      legacy
      customSender
      senderName
      senderEmail
      senderPrefix
      senderDomain
      senderDomains
      availableSenderDomains
      links
      availableLinks
      custom
      global
      companies {
        id
        name
      }
    }
  }
`

export const UPDATE_SIMULATION_EMAIL_TEMPLATE = gql`
  mutation (
    $id: ID!, $name: String, $locales: [ContentLocale!], $subject: String, $content: JSON, $legacy: Boolean, $customSender: Boolean, $senderName: String, $senderEmail: String, $senderPrefix: String, $senderDomain: String,
    $global: Boolean, $companies: [ID!], $companyId: ID, $senderDomains: [String!], $category: SimulationCategory, $tile: String
  ) {
    updateSimulationEmailTemplate(
      id: $id, name: $name, locales: $locales, subject: $subject, content: $content, legacy: $legacy, customSender: $customSender, senderName: $senderName, senderEmail: $senderEmail, senderPrefix: $senderPrefix, senderDomain: $senderDomain,
      global: $global, companies: $companies, companyId: $companyId, senderDomains: $senderDomains, category: $category, tile: $tile
    ) {
      id
      name
      locales
      previewThumb
      category
      subject
      content
      legacy
      customSender
      senderName
      senderEmail
      senderPrefix
      senderDomain
      senderDomains
      availableSenderDomains
      links
      availableLinks
      custom
      global
      companies {
        id
        name
      }
    }
  }
`

export const DELETE_SIMULATION_LANDING_PAGE_TEMPLATES = gql`
  mutation ($ids: [ID!]!) {
    deleteSimulationLandingPages (ids: $ids)
  }
`
export const DELETE_SIMULATION_EMAIL_TEMPLATES = gql`
  mutation ($ids: [ID!]!) {
    deleteSimulationEmailTemplates (ids: $ids)
  }
`

export const SEND_ALL_SIMULATION_EMAILS = gql`
  mutation ($simulationIds: [ID!]!) {
    sendAllSimulationEmails (simulationIds: $simulationIds)
  }
`

export const SEND_PHISH_TEST_EMAIL = gql`
  mutation ($simulationId: ID, $landingPageId: ID, $emailTemplateId: ID, $startDate: DateTime, $email: String, $firstName: String, $lastName: String, $config: JSON, $deliveryMethod: EmailDeliveryMethod) {
    sendPhishingTestEmail (
      simulationId: $simulationId, landingPageId: $landingPageId, emailTemplateId: $emailTemplateId, startDate: $startDate,
      email: $email, firstName: $firstName, lastName: $lastName, config: $config, deliveryMethod: $deliveryMethod
    )
  }
`

export const GET_PHISH_TEST_EMAIL_STATUS = gql`
  query ($jobId: Int!) {
    phishingEmailTestStatus(jobId: $jobId) {
      complete
      status
      deliveryMethod
      messageInjectionService
    }
  }
`

export const GET_SIMULATION_EMAIL_TEMPLATE_LAYOUTS = gql`
  query {
    emailTemplateLayouts {
      id
      name
      icon
      design
    }
  }
`

export const GET_SIMULATION_LANDING_PAGE_LAYOUTS = gql`
  query {
    landingPageLayouts {
      id
      name
      icon
      design
    }
  }
`

export const CHECK_SIMULATION_LANDING_PAGE_ACCESS = gql`
  query ($id: ID, $companyId: ID, $global: Boolean, $companies: [ID!], $emailTemplates: [ID!]!) {
    checkLandingPageAccess (id: $id, companyId: $companyId, global: $global, companies: $companies, emailTemplates: $emailTemplates) {
      conflict
      emailTemplates {
        id
        name
      }
    }
  }
`

export const CHECK_SIMULATION_LANDING_PAGE_LOCALES = gql`
  query ($id: ID, $companyId: ID, $locales: [ContentLocale!], $emailTemplates: [ID!]!) {
    checkLandingPageLocales (id: $id, companyId: $companyId, locales: $locales, emailTemplates: $emailTemplates) {
      mismatch
      emailTemplates {
        id
        name
        locales
      }
    }
  }
`

export const CHECK_SIMULATION_EMAIL_TEMPLATE_ACCESS = gql`
  query ($id: ID!, $companyId: ID, $global: Boolean, $companies: [ID!]) {
    checkEmailTemplateAccess (id: $id, companyId: $companyId, global: $global, companies: $companies) {
      conflict
      landingPages {
        id
        name
      }
    }
  }
`

export const CHECK_SIMULATION_EMAIL_TEMPLATE_LOCALES = gql`
  query ($id: ID!, $companyId: ID, $locales: [ContentLocale!]) {
    checkEmailTemplateLocales (id: $id, companyId: $companyId, locales: $locales) {
      mismatch
      landingPages {
        id
        name
        locales
      }
    }
  }
`

export const ATTACH_SIMULATION_TO_RISK_REPORT = gql`
  mutation ($simulationId: ID!, $returnBreachDataClassIds: Boolean = true) {
    attachSimulationToRiskReport(simulationId: $simulationId) {
      ${PROSPECT_RISK_REPORT_FIELDS}
    }
  }
`
export const DETACH_SIMULATION_FROM_RISK_REPORT = gql`
  mutation ($simulationId: ID!, $returnBreachDataClassIds: Boolean = true) {
    detachSimulationFromRiskReport(simulationId: $simulationId) {
      ${PROSPECT_RISK_REPORT_FIELDS}
    }
  }
`

export const GET_RECOMMENDED_SIMULATIONS = gql`
  query {
    recommendedSimulations {
      id
      name
      description
      tile
      landingPage {
        id
        tile
      }
      emailTemplate {
        id
        tile
      }
      config
    }
  }
`
export const GET_RECOMMENDED_SIMULATION = gql`
  query ($id: String!) {
    recommendedSimulation(id: $id) {
      id
      name
      description
      landingPageId
      landingPage {
        id
        pageTitle
      }
      emailTemplateId
      emailTemplate {
        id
        name
        subject
        content
        legacy
        customSender
        senderName
        senderEmail
        senderPrefix
        senderDomain
        senderDomains
        availableSenderDomains
        availableLinks
      }
      config
    }
  }
`

export const UPDATE_SIMULATION_EMAIL_TEMPLATE_LANGUAGES = gql`
  mutation ($ids: [ID!]!, $action: languageBulkUpdateAction, $locales: [ContentLocale!]!) {
    updateSimulationEmailTemplateLanguages(ids: $ids, action: $action, locales: $locales) {
      id
      name
      locales
      previewThumb
      tile
      category
      custom
      companyId
      company {
        id
        name
      }
    }
  }
`
export const UPDATE_SIMULATION_LANDING_PAGE_LANGUAGES = gql`
  mutation ($ids: [ID!]!, $action: languageBulkUpdateAction, $locales: [ContentLocale!]!) {
    updateSimulationLandingPageLanguages(ids: $ids, action: $action, locales: $locales) {
      id
      name
      locales
      previewThumb
      custom
      category
      companyId
      company {
        id
        name
      }
    }
  }
`

export const ARE_AUTO_PHISH_TEMPLATES_AVAILABLE = gql`
  query ($autoPhishSettings: JSON, $defaultTenant: Boolean) {
    areAutoPhishTemplatesAvailable(autoPhishSettings: $autoPhishSettings, defaultTenant: $defaultTenant)
  }
`
