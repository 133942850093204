import React, { useCallback } from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import { withTheme } from 'styled-components'
import I18n from 'i18n-js'

const SimulationsActions = ({ selectedSimulationIds = [], actions = [], performAction = () => {}, disabled = false, theme }) => {
  const handleMenuClick = useCallback(e => performAction(e.key, selectedSimulationIds), [performAction, selectedSimulationIds])
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.icon && <Icon type={action.icon} theme='twoTone' twoToneColor={theme.primary} />}  {action.label}</Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={disabled || selectedSimulationIds.length === 0}>
      <Button>{I18n.t('common.actions')} <Icon type='down' /></Button>
    </Dropdown>
  )
}

export default withTheme(SimulationsActions)
