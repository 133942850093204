import I18n from 'i18n-js'

import { GET_COURSES } from '../components/Queries/Courses'
import { COURSE_BUILDER_ONLY_ROLES } from '../constants/roles'
import { disableActions } from './listPages'

const trOpt = { scope: 'courses' }

export const getCoursesRefetchQueries = (isUsecureAdmin = false) =>
  [{
    // Course Builder Refetch
    query: GET_COURSES,
    variables: {
      restrictToOwn: true,
      withCompany: isUsecureAdmin
    }
  }, {
    // Course Library Refetch
    query: GET_COURSES,
    variables: {
      restrictToPlan: true,
      withCompany: true
    }
  }]

export const getCourseActions = ({ role, disabledActions }) => {
  const actions = [
    { key: 'editCourse', label: I18n.t('courses.common.editCourse'), icon: 'edit' },
    { key: 'editSlides', label: I18n.t('editSlides', trOpt), icon: 'play-square' },
    { key: 'previewCourse', label: I18n.t('previewCourse', trOpt), icon: 'eye' }
  ]
  //  Exclude duplicate and delete actions from course-builder roles
  if (!COURSE_BUILDER_ONLY_ROLES.includes(role)) {
    actions.push(
      { key: 'duplicateCourse', label: I18n.t('duplicateCourse', trOpt), icon: 'copy' },
      { key: 'deleteCourse', label: I18n.t('deleteCourse', trOpt), icon: 'delete' }
    )
  }
  if (role === 'usecure-admin') {
    actions.splice(2, 0, { key: 'translateCourse', label: I18n.t('translateCourse', trOpt), icon: 'rocket' })
  }
  return disableActions(actions, disabledActions)
}
