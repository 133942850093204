import React, { useMemo } from 'react'
import { Button, TreeSelect, Tooltip } from 'antd'
import styled, { css } from 'styled-components'
import I18n from 'i18n-js'

import { LANGUAGE_TREE_OPTIONS_BY_TYPE } from '../../constants/languages'
import FilterField from './FilterField'

// The restricts the language filter to those we have content for when true
const RESTRICT_LANGUAGES = window.__USECURE_CONFIG__.REACT_APP_RESTRICT_LANGUAGE_TO_CONTENT === 'true'

const LocaleTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`
const _LocaleCount = ({ className, count = 0, overflowCount }) => (
  <div {...{ className }} style={{ width: (4 * `${count}`.length) + 16 }}>
    <div>{overflowCount && count > overflowCount ? `${count}+` : count}</div>
  </div>
)
const LocaleCount = styled(_LocaleCount)`
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  color: ${({ theme }) => theme.secondary};
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 5px;
  padding: 8px;
  position: relative;
  top: -1px;

  &> div {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px;
  }
`
const LocaleTreeSelect = styled(TreeSelect)`
  ${({ hasDefaultContentLocales }) =>
    hasDefaultContentLocales
      ? css`
  .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0px;
  }
    ` : ''
  }

.ant-select-selection ${LocaleCount} {
    display: none;
  }
`
const LocaleResetAddon = styled(Button)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  flex: 0 0 30px;
  height: unset;
  line-height: 21px !important;

  &.ant-btn > .anticon {
    top: 3px;
  }
`
const LocaleFilter = styled.div`
  align-items: stretch;
  display: flex;

  /* antd wraps a disabled button in a span which breaks the add on styling */
  span:nth-child(2) {
    flex: 0 0 30px;
    height: unset;

    ${LocaleResetAddon}[disabled] {
      height: 100%;
    }
  }
`

const LocaleFilterField = ({
  loading, showContentLocaleCounts,
  contentLocales, onContentLocalesChange, getPopupContainer, hasDefaultContentLocales,
  resetContentLocales, contentLocaleCounts, allowContentLocaleReset,
  type = 'app'
}) => {
  const treeData = useMemo(() => {
    const treeData = [...(LANGUAGE_TREE_OPTIONS_BY_TYPE[type] || [])]
      .sort((a, b) => a.title.localeCompare(b.title))

    if (!loading && (RESTRICT_LANGUAGES || showContentLocaleCounts)) {
      return treeData.reduce((acc, node) => {
        let treeNode
        const count = contentLocaleCounts[node.value] || 0
        if (!RESTRICT_LANGUAGES || count > 0) {
          treeNode = showContentLocaleCounts
            ? ({
              ...node,
              title: (
                <LocaleTitle>
                  <span>{node.title}</span>
                  <LocaleCount
                    count={count}
                    overflowCount={10000}
                  />
                </LocaleTitle>
              ),
              titleStr: node.title
            })
            : node
        }
        if (treeNode) {
          acc.push(treeNode)
        }
        return acc
      }, [])
    }

    return treeData
  }, [loading, type, contentLocaleCounts, showContentLocaleCounts])

  return (
    <FilterField width={300} maxWidth={500}>
      <label>{I18n.t('common.languagesParenPlural')}</label>
      <LocaleFilter>
        <LocaleTreeSelect
          value={contentLocales}
          disabled={loading}
          onChange={onContentLocalesChange}
          multiple
          treeCheckable
          treeNodeFilterProp={showContentLocaleCounts ? 'titleStr' : 'title'}
          placeholder={I18n.t('common.fields.languagesPlaceHolder')}
          treeData={treeData}
          allowClear
          getPopupContainer={getPopupContainer}
          dropdownStyle={{ maxHeight: 400 }}
          showSearch
          hasDefaultContentLocales={hasDefaultContentLocales}
        />
        {
          hasDefaultContentLocales && (
            <Tooltip title={I18n.t('common.resetToDefaultLanguages')} placement='bottom' {...{ getPopupContainer }}>
              <LocaleResetAddon type='primary' icon='global' onClick={resetContentLocales} disabled={!allowContentLocaleReset} />
            </Tooltip>
          )
        }
      </LocaleFilter>
    </FilterField>
  )
}

export default LocaleFilterField
