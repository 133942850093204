import React, { useCallback, useRef } from 'react'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import routes from '../../constants/routes'
import BrandedGoogleAuthButton from './BrandedGoogleAuthButton'
import SyncActions from '../Sync/SyncActions'
import { GOOGLE_SYNC_GROUP_TYPES } from '../../constants/google'
import { GOOGLE_SYNC_GROUPS_PAGE } from '../Queries/Learners'
import GoogleSelectAuthFlowModal from '../Modals/GoogleSelectAuthFlowModal'

const GoogleSyncActions = ({ googleSync, locale, planValid }) => {
  const selectAuthRef = useRef(null)
  const onAuthClick = useCallback(async () => {
    if (planValid && selectAuthRef.current) {
      selectAuthRef.current.open({ app: 'sync', redirectUri: `${window.location.origin}${routes.GOOGLE_SYNC_SETUP}` })
    }
  }, [planValid])

  return (
    <SyncActions
      settings={googleSync}
      groupTypes={GOOGLE_SYNC_GROUP_TYPES}
      usePaginatedGroupLoad
      groupsQuery={GOOGLE_SYNC_GROUPS_PAGE}
      groupsQueryKey='googleSyncGroupsPage'
      setupRoute={routes.GOOGLE_SYNC_SETUP}
      signInButton={<BrandedGoogleAuthButton disabled={!planValid} onClick={onAuthClick} locale={locale} />}
      authFlowModal={<GoogleSelectAuthFlowModal ref={selectAuthRef} trOpt={{ scope: 'modals.googleSyncSelectAuthFlowModal' }} />}
      syncType='google'
      trOptScopeKey='googleSync'
      completeTrOpt={{ scope: 'googleSyncSetup.setupComplete' }}
      revokeButtonOptions={[
        {
          action: 'clearAll',
          trOption: { scope: 'googleSync.actions.googleRevokeAllConfiguration' }
        },
        {
          action: 'clearAuth',
          trOption: { scope: 'googleSync.actions.googleRevokeAuthentication' }
        }
      ]}
    />
  )
}

export default connect(
  state => ({
    ..._pick(selectors.settings.get(state), ['googleSync']),
    ..._pick(selectors.session.get(state), ['planValid']),
    locale: selectors.session.sessionLocale(state)
  })
)(GoogleSyncActions)
