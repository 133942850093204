import React, { useCallback, useState } from 'react'
import { Button, message } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from '@apollo/react-hooks'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _set from 'lodash/set'
import _unset from 'lodash/unset'

import SettingsForm from '../SettingsForm'
import { InputWithReset, SenderAddressField } from '../common'
import { UPDATE_COMPANY_EMAIL_SETTING, UPDATE_DEFAULT_TENANT_EMAIL_SETTING, SEND_EMAIL_THEME_TEST } from '../../Queries/Companies'
import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../../../constants/languages'
import { DEFAULT_EMAIL_SETTINGS } from '../../../constants/settings'
import { connect } from '../../../hocs'
import { session, settings } from '../../../state/selectors'
import { MultiLocaleLabel } from '../LanguageSelector'

export const EmailSettingsConnect = connect(
  state => ({
    ..._pick(session.get(state), ['locale', 'companyLocale', 'accountType']),
    ..._pick(settings.get(state), ['enableRiskReportTenants', 'enableProspects'])
  })
)

const trScope = 'settings.emailSettingsForm'
const trOpt = { scope: trScope }

const SendTestEmail = React.forwardRef(({ emailConfig, locale, visible }, ref) => {
  // The copy is 'Send Test Email' is taken from uPhish as an analogous process has already been translated
  // Support for keys specific to this screen is included should it be needed
  // trOpt is not used here as the scope and defaults options can't be used together hence the longhand key
  const [sendTest] = useMutation(SEND_EMAIL_THEME_TEST, { variables: { locale, emailConfig } })
  const [loading, setLoading] = useState(false)
  const onClick = useCallback(async () => {
    try {
      setLoading(true)
      await sendTest()
      message.success(I18n.t(`${trScope}.testEmailSent`, { defaults: [{ scope: 'uPhish.helpers.testEmailSent' }] }))
    } catch (e) {
      message.error(I18n.t(`${trScope}.testEmailError`, { defaults: [{ scope: 'uPhish.sendTestEmail.errorMessage' }] }))
    } finally {
      setLoading(false)
    }
  }, [sendTest])
  return (
    visible
      ? <Button type='primary' icon='mail' onClick={onClick} loading={loading}>{I18n.t(`${trScope}.sendTestEmail`, { defaults: [{ scope: 'uPhish.common.sendTestEmail' }] })}</Button>
      : null
  )
})

const ResetTheme = React.forwardRef(({ visible, onClick = () => {} }, ref) => (
  visible
    ? <Button type='primary' onClick={onClick} icon='undo' style={{ marginLeft: 5 }}>{I18n.t(`${trScope}.resetTheme`, { defaults: [{ scope: 'settings.theme.resetTheme' }] })}</Button>
    : null
))

class EmailSettingsForm extends SettingsForm {
  constructor (props) {
    super(props)

    this.defaultTenantSettingMutation = UPDATE_DEFAULT_TENANT_EMAIL_SETTING
    this.defaultTenantSettingsMutation = null
    this.companySettingMutation = UPDATE_COMPANY_EMAIL_SETTING
    this.companySettingsMutation = null
    this.showSentTestButton = false
    this.showTheme = true
    this.showUseDefaultThemeSwitch = true
    this.subjectFieldIds = []
    this.subjectFields = []
    this.usingWhiteTheme = false
    this.includeFormLocale = true

    this.state = {
      senderEmailAddress: null,
      themeLogo: null,
      themePrimaryColor: null,
      themeSecondaryColor: null,
      prevThemeLogo: null,
      prevThemePrimaryColor: null,
      prevThemeSecondaryColor: null,
      omitEmailHeader: false,
      useDefaultTheme: false,
      updatedMultiLocaleValues: {}
    }

    this.getFieldResetValueMultiLocale = this.getFieldResetValueMultiLocale.bind(this)
    this.getThemePrimaryColorLabel = this.getThemePrimaryColorLabel.bind(this)
    this.isThemeLogoVisible = this.isThemeLogoVisible.bind(this)
    this.isThemeVisible = this.isThemeVisible.bind(this)
    this.resetToDefaultTheme = this.resetToDefaultTheme.bind(this)
  }

  get isDefault () {
    return this.settingId === 'default'
  }

  resetToDefaultTheme () {
    const form = this.form.current
    if (form) {
      form.replaceValues({
        themeLogo: this.getThemeResetValue('themeLogo'),
        themePrimaryColor: this.getThemeResetValue('themePrimaryColor'),
        themeSecondaryColor: this.getThemeResetValue('themeSecondaryColor'),
        omitEmailHeader: this.getFieldValue('omitEmailHeader', { loadFromEmailDefault: true, loadFromSettings: false, loadFromMSP: true }) === true
      })
    }
  }

  getRefreshedLocaleFieldValue (id) {
    return _get(this.state.updatedMultiLocaleValues, [id, this.state.formLocale]) || this.getFieldDefaultValueMultiLocale(id)
  }

  refreshMultiLocaleFields () {
    const form = this.form.current
    if (form) {
      form.replaceValues(
        this.multiLocaleFieldIds.reduce((acc, id) => ({
          ...acc,
          [id]: this.getRefreshedLocaleFieldValue(id)
        }), {})
      )
    }
  }

  getThemePrimaryColorLabel ({ omitEmailHeader }) {
    return I18n.t(omitEmailHeader || this.usingWhiteTheme ? 'themePrimaryColorNoHeader' : 'themePrimaryColor', trOpt)
  }

  isThemeLogoVisible (values = {}) {
    return this.isThemeVisible(values) && !values.omitEmailHeader
  }

  isThemeVisible ({ useDefaultTheme } = {}) {
    return this.showTheme && this.showUseDefaultThemeSwitch ? !useDefaultTheme : this.showTheme
  }

  getFieldDefaultValue (fieldId, opt) {
    return this.getFieldValue(fieldId, { ...(opt || {}), loadFromSettings: true })
  }

  getThemeResetValue (fieldId) {
    return this.getThemeFieldDefaultValue(fieldId, { loadFromSettings: false, loadFromMSP: true })
  }

  getThemeFieldDefaultValue (fieldId, { loadFromSettings = true, loadFromMSP = false } = {}) {
    return this.getFieldValue(fieldId, { isLocaleObject: true, singleLanguage: true, loadFromEmailDefault: true, loadFromSettings, loadFromMSP })
  }

  getFieldDefaultValueSingleLocale (fieldId) {
    return this.getFieldDefaultValue(fieldId, { isLocaleObject: true, singleLanguage: true })
  }

  getFieldDefaultValueMultiLocale (fieldId, opt) {
    return this.getFieldDefaultValue(fieldId, { ...(opt || {}), isLocaleObject: true, singleLanguage: false })
  }

  getFieldResetValueMultiLocale (fieldId) {
    return this.getFieldValue(fieldId, { loadFromSettings: false, loadFromMSP: true, isLocaleObject: true, singleLanguage: false })
  }

  getLocaleFieldValue (fieldId, locale) {
    return this.getFieldDefaultValueMultiLocale(fieldId, { locale })
  }

  getFieldValue (fieldId, opt) {
    if (fieldId === 'senderEmailAddress') {
      return {
        prefix: this.getFieldDefaultValue('senderEmailAddressPrefix', opt),
        subdomain: this.getFieldDefaultValue('senderEmailAddressSubdomain', opt)
      }
    }

    const { loadFromSettings = false, loadFromEmailDefault = false, isLocaleObject = false, singleLanguage = false, locale: optLocale, loadFromMSP = false } = opt || {}
    const { formLocale } = this.state
    const { defaultTenant = false, settings = {} } = this.props
    const { parentDefaultSettings } = settings || {}
    const searchPath = []
    const useDefaultEmailValue = loadFromEmailDefault && !this.isDefault
    const locale = optLocale || formLocale

    switch (fieldId) {
      case 'senderName':
        searchPath.push('sender', 'name')
        break
      case 'senderEmailAddressPrefix':
        searchPath.push('sender', 'prefix')
        break
      case 'senderEmailAddressSubdomain':
        searchPath.push('sender', 'subdomain')
        break
      default:
        searchPath.push(fieldId)
        break
    }

    if (isLocaleObject) {
      searchPath.push(singleLanguage ? DEFAULT_LANGUAGE : locale)
    }

    return [
      (loadFromSettings ? _get(settings, ['emailSettings', this.settingId, ...searchPath]) : null), // Current Value
      (useDefaultEmailValue ? _get(settings, ['emailSettings', 'default', ...searchPath]) : null), // Current default email setting for this company for fallback use
      (loadFromMSP && !defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, ['emailSettings', this.settingId, ...searchPath]) : null), // Current value from MSP's Default Customer Setting
      (loadFromMSP && loadFromEmailDefault && this.isDefault && !defaultTenant && parentDefaultSettings ? _get(parentDefaultSettings, ['emailSettings', 'default', ...searchPath]) : null), // Default email settings value from MSP's Default customer settings
      (useDefaultEmailValue ? _get(DEFAULT_EMAIL_SETTINGS, ['default', ...searchPath]) : null), // Default email setting for fallback use
      _get(DEFAULT_EMAIL_SETTINGS, [this.settingId, ...searchPath]) // Default email settting for this setting
    ].find(value => _isBoolean(value) || (_isString(value) && !_isEmpty(value)))
  }

  generateSubjectFields (fields, labelTrOpt) {
    fields.forEach(field => {
      let id
      let labelKey
      let extra
      if (_isObject(field)) {
        id = field.id
        labelKey = field.label
        extra = field.extra
      } else if (_isString(field)) {
        id = field
      }

      if (id) {
        this.subjectFieldIds.push(id)
        this.subjectFields.push(this.createSubjectFieldConfig({ id, labelKey, labelTrOpt, extra }))
      }
    })
  }

  createSubjectFieldConfig ({ id, labelKey, labelTrOpt, extra }) {
    return {
      id: id,
      type: 'text',
      label: I18n.t(labelKey || id, labelTrOpt),
      required: true,
      component: InputWithReset,
      extra
    }
  }

  get multiLocaleFieldIds () {
    return ['senderName', ...this.subjectFieldIds]
  }

  async componentDidMount () {
    const { settings = {} } = this.props
    let useDefaultTheme = _get(settings, ['emailSettings', this.settingId, 'useDefaultTheme'])
    if (!_isBoolean(useDefaultTheme)) {
      useDefaultTheme = this.showUseDefaultThemeSwitch
    }
    const omitEmailHeader = _get(settings, ['emailSettings', this.settingId, 'omitEmailHeader'])
    await this.updateState({
      useDefaultTheme,
      omitEmailHeader
    })
    await super.componentDidMount()
  }

  get formValues () {
    const { settings = {} } = this.props
    const themeLogo = this.getThemeFieldDefaultValue('themeLogo')
    const themePrimaryColor = this.getThemeFieldDefaultValue('themePrimaryColor')
    const themeSecondaryColor = this.getThemeFieldDefaultValue('themeSecondaryColor')
    let useDefaultTheme = _get(settings, ['emailSettings', this.settingId, 'useDefaultTheme'])
    if (!_isBoolean(useDefaultTheme)) {
      useDefaultTheme = this.showUseDefaultThemeSwitch
    }
    const omitEmailHeader = _get(settings, ['emailSettings', this.settingId, 'omitEmailHeader'])

    return {
      senderName: this.getFieldDefaultValueMultiLocale('senderName'),
      senderEmailAddress: this.getFieldDefaultValueSingleLocale('senderEmailAddress'),
      ...this.subjectFieldIds.reduce((acc, id) => ({
        ...acc,
        [id]: this.getFieldDefaultValueMultiLocale(id)
      }), {}),
      themeLogo,
      themePrimaryColor,
      themeSecondaryColor,
      omitEmailHeader,
      useDefaultTheme
    }
  }

  get fields () {
    const {
      senderEmailAddress: senderEmailAddressUpdt,
      themeLogo: themeLogoUpdt,
      themePrimaryColor: themePrimaryColorUpdt,
      themeSecondaryColor: themeSecondaryColorUpdt,
      omitEmailHeader: omitEmailHeaderUpdt,
      useDefaultTheme: useDefaultThemeUpdt,
      formLocale
    } = this.state
    const { formValues } = this
    const { senderName, senderEmailAddress, themeLogo, themePrimaryColor, themeSecondaryColor, omitEmailHeader, useDefaultTheme } = formValues
    const { settings = {} } = this.props
    const appDomain = _get(settings, ['appDomain', DEFAULT_LANGUAGE]) || 'usecure.io'
    const { prefix, subdomain } = senderEmailAddressUpdt || senderEmailAddress || {}

    return [{
      id: 'senderName',
      type: 'text',
      component: InputWithReset,
      label: <MultiLocaleLabel label={I18n.t('senderName', trOpt)} />,
      required: true,
      defaultValue: senderName,
      defaultSetting: this.getFieldResetValueMultiLocale('senderName')
    }, {
      id: 'senderEmailAddress',
      type: 'custom',
      component: SenderAddressField,
      defaultValue: senderEmailAddress,
      appDomain,
      required: true
    },
    ...this.subjectFields.map(field => ({
      ...field,
      label: <MultiLocaleLabel label={field.label} />,
      defaultValue: formValues[field.id],
      defaultSetting: this.getFieldResetValueMultiLocale(field.id)
    })),
    {
      id: 'themeLogo',
      type: 'image',
      label: I18n.t('themeLogo', trOpt),
      defaultValue: themeLogo,
      required: true,
      visible: this.isThemeLogoVisible
    }, {
      id: 'themePrimaryColor',
      type: 'colour',
      label: this.getThemePrimaryColorLabel,
      defaultValue: themePrimaryColor,
      required: true,
      visible: this.isThemeVisible
    }, {
      id: 'themeSecondaryColor',
      type: 'colour',
      label: I18n.t('themeSecondaryColor', trOpt),
      defaultValue: themeSecondaryColor,
      required: true,
      visible: this.isThemeVisible
    }, {
      id: 'omitEmailHeader',
      type: 'switch',
      label: I18n.t('omitEmailHeader', trOpt),
      defaultValue: omitEmailHeader,
      visible: this.isThemeVisible
    }, {
      id: 'useDefaultTheme',
      type: 'switch',
      label: I18n.t('useDefaultTheme', trOpt),
      defaultValue: useDefaultTheme,
      visible: this.showTheme && this.showUseDefaultThemeSwitch
    }, {
      id: 'sendEmailTest',
      type: 'custom',
      component: SendTestEmail,
      visible: this.showSentTestButton,
      locale: formLocale,
      emailConfig: {
        emailId: this.settingId,
        sender: {
          name: this.createLocaleCopyObject('senderName'),
          prefix: {
            [DEFAULT_LANGUAGE]: prefix
          },
          subdomain: {
            [DEFAULT_LANGUAGE]: subdomain
          }
        },
        themeLogo: {
          [DEFAULT_LANGUAGE]: themeLogoUpdt || themeLogo
        },
        themePrimaryColor: {
          [DEFAULT_LANGUAGE]: themePrimaryColorUpdt || themePrimaryColor
        },
        themeSecondaryColor: {
          [DEFAULT_LANGUAGE]: themeSecondaryColorUpdt || themeSecondaryColor
        },
        omitEmailHeader: _isBoolean(omitEmailHeaderUpdt) ? omitEmailHeaderUpdt : (_isBoolean(omitEmailHeader) ? omitEmailHeader : false),
        useDefaultTheme: _isBoolean(useDefaultThemeUpdt) ? useDefaultThemeUpdt : (_isBoolean(useDefaultTheme) ? useDefaultTheme : false),
        subjectFieldIds: this.subjectFieldIds,
        ...this.subjectFieldIds.reduce((acc, id) => ({
          ...acc,
          [id]: this.createLocaleCopyObject(id)
        }), {})
      }
    }, {
      id: 'reset',
      type: 'custom',
      component: ResetTheme,
      onClick: this.resetToDefaultTheme,
      visible: this.isThemeVisible
    }]
  }

  get mutationName () {
    return this.props.defaultTenant === true ? 'updateDefaultTenantEmailSetting' : 'updateCompanyEmailSetting'
  }

  createLocaleCopyObject (fieldId) {
    const { updatedMultiLocaleValues } = this.state
    return LANGUAGE_CODES.reduce((acc, locale) => ({
      ...acc,
      [locale]: _get(updatedMultiLocaleValues, [fieldId, locale]) || this.getLocaleFieldValue(fieldId, locale)
    }), {})
  }

  mutateValues (values) {
    const { senderEmailAddress: { prefix, subdomain } = {}, themeLogo, themePrimaryColor, themeSecondaryColor, useDefaultTheme, omitEmailHeader } = values
    let emailSetting = {
      sender: {
        name: this.createLocaleCopyObject('senderName'),
        prefix: {
          [DEFAULT_LANGUAGE]: prefix
        }
      }
    }

    if (subdomain) {
      emailSetting.sender.subdomain = {
        [DEFAULT_LANGUAGE]: subdomain
      }
    }

    this.subjectFieldIds.forEach(id => {
      emailSetting[id] = this.createLocaleCopyObject(id)
    })

    if (this.showTheme) {
      let addTheme = true
      if (this.showUseDefaultThemeSwitch) {
        emailSetting.useDefaultTheme = useDefaultTheme
        addTheme = !useDefaultTheme
      }

      if (addTheme) {
        emailSetting.omitEmailHeader = omitEmailHeader
        if (!omitEmailHeader) {
          emailSetting.themeLogo = {
            [DEFAULT_LANGUAGE]: themeLogo
          }
        }
        emailSetting = {
          ...emailSetting,
          themePrimaryColor: {
            [DEFAULT_LANGUAGE]: themePrimaryColor
          },
          themeSecondaryColor: {
            [DEFAULT_LANGUAGE]: themeSecondaryColor
          }
        }
      }
    }

    return emailSetting
  }

  onSuccess (result) {
    super.onSuccess(result)
    this.setState({
      senderEmailAddress: null,
      themeLogo: null,
      themePrimaryColor: null,
      themeSecondaryColor: null,
      prevThemeLogo: null,
      prevThemePrimaryColor: null,
      prevThemeSecondaryColor: null,
      updatedMultiLocaleValues: {}
    })
  }

  onFormLocaleChange () {
    this.refreshMultiLocaleFields()
  }

  hasChanged () {
    const initialValues = _merge(
      _cloneDeep(_get(DEFAULT_EMAIL_SETTINGS, [this.settingId]) || {}), // cloning this as lodash merge mutates the destination object
      _get(this.props.settings, ['emailSettings', this.settingId])
    )
    _set(initialValues, 'sender.name', _pick(_get(initialValues, 'sender.name') || {}, LANGUAGE_CODES))
    this.subjectFieldIds.forEach(fieldId => {
      initialValues[fieldId] = _pick(initialValues[fieldId], LANGUAGE_CODES)
    })
    const currentValues = this.form.current?.variables.setting
    // The default subdomain is an empty string but the mutateValues only includes the subdomain if it's populated
    // This is replicated in initialValues using the code below
    // This code will need to be amended or removed if we add support for multilingual subdomains in future
    if (!_get(currentValues, ['sender', 'subdomain', DEFAULT_LANGUAGE])) {
      _unset(initialValues, 'sender.subdomain')
    }
    const hasChanged = !_isEqual(initialValues, currentValues)
    return hasChanged
  }

  onChange (id, value) {
    super.onChange(id, value)
    if (['themeLogo', 'themePrimaryColor', 'themeSecondaryColor', 'senderEmailAddress', 'omitEmailHeader'].includes(id)) {
      this.setState({ [id]: value })
    } else if (id === 'useDefaultTheme' && value === true) {
      const { themeLogo, themePrimaryColor, themeSecondaryColor } = this.state
      this.setState({
        themeLogo: this.getThemeResetValue('themeLogo'),
        themePrimaryColor: this.getThemeResetValue('themePrimaryColor'),
        themeSecondaryColor: this.getThemeResetValue('themeSecondaryColor'),
        prevThemeLogo: themeLogo,
        prevThemePrimaryColor: themePrimaryColor,
        prevThemeSecondaryColor: themeSecondaryColor,
        useDefaultTheme: true
      })
    } else if (id === 'useDefaultTheme' && value !== true) {
      const { prevThemeLogo, prevThemePrimaryColor, prevThemeSecondaryColor } = this.state
      this.setState({
        themeLogo: prevThemeLogo || this.getThemeFieldDefaultValue('themeLogo'),
        themePrimaryColor: prevThemePrimaryColor || this.getThemeFieldDefaultValue('themePrimaryColor'),
        themeSecondaryColor: prevThemeSecondaryColor || this.getThemeFieldDefaultValue('themeSecondaryColor'),
        prevThemeLogo: null,
        prevThemePrimaryColor: null,
        prevThemeSecondaryColor: null,
        useDefaultTheme: false
      })
    }

    if (this.multiLocaleFieldIds.includes(id)) {
      const { updatedMultiLocaleValues, formLocale } = this.state
      this.setState({
        updatedMultiLocaleValues: {
          ...updatedMultiLocaleValues,
          [id]: {
            ...(updatedMultiLocaleValues[id] || {}),
            [formLocale]: value
          }
        }
      })
    }
  }

  async componentDidUpdate (prevProps) {
    const form = this.form.current
    if (!form) {
      return
    }

    const { defaultTenant } = this.props
    const { defaultTenant: prevDefaultTenant } = prevProps
    const setting = _get(this.props, `settings.emailSettings.${this.settingId}`)
    const prevSetting = _get(prevProps, `settings.emailSettings.${this.settingId}`)
    // Replace form values if either updates have come in via a change to the settings global state e.g. a subscription
    // Or the user has gone from the partner settings variant of this page to platform one or vice versa
    if (defaultTenant !== prevDefaultTenant || !_isEqual(setting, prevSetting)) {
      await form.setInitialValues(this.formValues)
    }
  }
}

export default EmailSettingsForm
