import React from 'react'

import {
  GET_BREACH_REPORT_VIEWS,
  GET_BREACH_REPORT_START_DATE,
  CREATE_CUSTOM_BREACH_REPORT,
  UPDATE_CUSTOM_BREACH_REPORT,
  DELETE_CUSTOM_BREACH_REPORTS,
  INVALIDATE_BREACH_REPORT_CACHE
} from '../../components/Queries/Reports'
import BaseReportCentreList from './BaseReportCentreList'

const USE_BREACH_REPORT_DB_CACHE = window.__USECURE_CONFIG__.REACT_APP_USE_BREACH_REPORT_DB_CACHE === 'true'

const BreachReports = ({
  companyId,
  accountType,
  role,
  planValid
}) => (
  <BaseReportCentreList
    viewQuery={GET_BREACH_REPORT_VIEWS}
    viewQueryName='breachReportViews'
    startDateQuery={GET_BREACH_REPORT_START_DATE}
    startDateQueryName='breachReportStartDate'
    reportType='breachReport'
    queries={{
      viewQuery: GET_BREACH_REPORT_VIEWS,
      viewQueryName: 'breachReportViews',
      startDateQuery: GET_BREACH_REPORT_START_DATE,
      startDateQueryName: 'breachReportStartDate',
      createMutation: CREATE_CUSTOM_BREACH_REPORT,
      updateMutation: UPDATE_CUSTOM_BREACH_REPORT,
      deleteMutation: DELETE_CUSTOM_BREACH_REPORTS,
      invalidateMutation: INVALIDATE_BREACH_REPORT_CACHE
    }}
    useDBCache={USE_BREACH_REPORT_DB_CACHE}
    {...{
      companyId,
      accountType,
      role,
      planValid
    }}
  />
)

export default BreachReports
