import React, { useCallback, useRef } from 'react'
import { Button } from 'antd'
import I18n from 'i18n-js'

import ExportServiceDownloadModal from '../Modals/ExportServiceDownloadModal'

const ExportServiceDownloadButton = ({
  jobData,
  fileName,
  fileType,
  baseUrl,
  btnType = 'primary',
  children = I18n.t('reports.downloadSpreadsheet'),
  icon = 'download'
}) => {
  const exportServiceDownloadModalRef = useRef(null)
  const onClick = useCallback(async () => {
    if (exportServiceDownloadModalRef.current) {
      exportServiceDownloadModalRef.current.open(jobData)
    }
  }, [exportServiceDownloadModalRef, jobData])

  return (
    <>
      <ExportServiceDownloadModal
        ref={exportServiceDownloadModalRef}
        {...{ fileName, fileType, baseUrl }}
      />
      <Button icon={icon} {...{ onClick }} type={btnType}>{children}</Button>
    </>
  )
}

export default ExportServiceDownloadButton
