import { Badge, Card, Collapse, Divider, Empty } from 'antd'
import React, { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import I18n from 'i18n-js'
import { useQuery } from '@apollo/react-hooks'
import _intersection from 'lodash/intersection'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'

import { POLICY_TEMPLATES } from '../../components/Queries/uPolicy'
import { LoadingBlock, ErrorAlerts, LocalesIconPopover, ListHeader, ListHeaderPanel } from '../../components/common'
import LocaleFilterField from '../../components/common/LocaleFilterField'
import { useGlobalState } from '../../hooks'
import useTheme from '../../hooks/useTheme'

const trOpt = { scope: 'uPolicy.defaultTemplate' }
const catTrScope = `${trOpt.scope}.categoryHeaders`

const { Meta } = Card
const { Panel } = Collapse

const tileCard = ({ noBodyPadding, ...rest }) => (<Card {...rest} />)
const TileCard = styled(tileCard)`
  ${({ selected, theme: { primary } }) => selected ? `box-shadow: 0 0 0 3px ${rgba(primary, 0.4)};` : ''};
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px 10px;
  width: 300px;

  ${({ noBodyPadding }) => {
  // Only disables horizontal padding
    if (noBodyPadding) {
      return css`
  .ant-card-body {
    padding: 24px 0;

    .ant-card-meta {
      padding: 0 24px;
    }
  }
      `
    }
  }}

  .ant-card-head {
    border-bottom: none;
    min-height: unset;
    padding: 0 12px;
  }

  .ant-card-head-title {
    padding: 8px 0 4px;
    text-align: right;
  }
`
const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ align }) => {
    if (align === 'center') {
      return 'center'
    } else if (align === 'right') {
      return 'flex-end'
    }
    return 'flex-start'
  }};
`

export const TileCardImage = styled.div`
  background-color: ${({ theme }) => theme.primary};
  background-image: url('${({ src }) => src}');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  width: ${({ width = 'auto' }) => _isNumber(width) ? `${width}px` : width};
`

const TemplateTile = ({ id, name, image, locales, update }) => {
  const onClick = useCallback(() => update(id), [update, id])

  return (
    <TileCard
      title={<LocalesIconPopover localeType='content' {...{ locales }} />}
      cover={<TileCardImage src={image} />}
      onClick={onClick}
    >
      <Meta title={name} />
    </TileCard>
  )
}

const DefaultTemplates = ({ update }) => {
  const theme = useTheme()
  const { loading, error, data: { policyTemplatesByCategory = [] } = {} } = useQuery(POLICY_TEMPLATES)

  const { defaultContentLocales } = useGlobalState(
    useCallback(state => ({ defaultContentLocales: state?.session?.contentLocales }), [])
  )
  const [contentLocales, setContentLocales] = useState(defaultContentLocales || [])
  const [allowContentLocaleReset, setAllowContentLocaleReset] = useState(false)
  useEffect(() => {
    const allowContentLocaleReset = (
      defaultContentLocales
        ? !(defaultContentLocales.every(locale => contentLocales.includes(locale)) && contentLocales.every(locale => defaultContentLocales.includes(locale))) // Content locales differ from default
        : contentLocales.length > 0 // Has content locales but no default
    )
    setAllowContentLocaleReset(allowContentLocaleReset)
  }, [contentLocales, defaultContentLocales])

  const onContentLocalesChange = useCallback(contentLocales => setContentLocales(contentLocales), [setContentLocales])
  const resetContentLocales = useCallback(() => setContentLocales(defaultContentLocales || []), [setContentLocales, defaultContentLocales])

  const [categoryPanels, setCategoryPanels] = useState([])
  const [defaultActiveKey, setDefaultActiveKey] = useState(null)
  const [contentLocaleCounts, setContentLocaleCounts] = useState({})

  useEffect(() => {
    const hasContentLocales = _isArray(contentLocales) && !_isEmpty(contentLocales)
    const { categoryPanels, defaultActiveKey, contentLocaleCounts } = policyTemplatesByCategory.reduce((acc, { category, policies: allPolicies = [] }) => {
      allPolicies.forEach(({ locales }) => {
        if (_isArray(locales) && !_isEmpty(locales)) {
          locales.forEach(locale => {
            if (_isNil(acc.contentLocaleCounts[locale])) {
              acc.contentLocaleCounts[locale] = 1
            } else {
              acc.contentLocaleCounts[locale] += 1
            }
          })
        }
      })
      let policies = allPolicies
      if (hasContentLocales) {
        policies = allPolicies.filter(({ locales }) =>
          _isArray(locales) && !_isEmpty(locales) && _intersection(locales, contentLocales).length > 0
        )
      }
      if (policies.length > 0) {
        if (!acc.defaultActiveKey) {
          acc.defaultActiveKey = category
        }
        acc.categoryPanels.push(
          <Panel
            header={I18n.t(`${catTrScope}.${category}`, { defaults: [{ scope: 'uPolicy.common.policies' }] })}
            extra={<Badge count={policies.length} overflowCount={Infinity} style={{ backgroundColor: theme.primary, color: theme.secondary }} />}
            key={category}
          >
            {
              <TileContainer align='center'>
                {policies.map(({ id, name, tile: image, locales }) => (
                  <TemplateTile
                    key={id}
                    {...{ id, name, image, update, locales }}
                  />
                ))}
              </TileContainer>
            }
          </Panel>
        )
      }
      return acc
    }, {
      defaultActiveKey: null,
      categoryPanels: [],
      contentLocaleCounts: {}
    })
    setCategoryPanels(categoryPanels)
    setDefaultActiveKey(defaultActiveKey)
    setContentLocaleCounts(contentLocaleCounts)
  }, [contentLocales, policyTemplatesByCategory, theme, update])

  return (
    <div>
      <p>{I18n.t('youCanUseOneOfOurPreMadePolicices', trOpt)}</p>
      {loading && <LoadingBlock fullScreen={false} loading />}
      {error && <ErrorAlerts {...{ error }} defaultError={I18n.t('thePreMadePoliciesCouldNotBeLoaded', trOpt)} />}
      {!loading && !error && (
        <>
          <ListHeader>
            <ListHeaderPanel>
              <LocaleFilterField
                type='content'
                hasDefaultContentLocales={defaultContentLocales && defaultContentLocales.length > 0}
                showContentLocaleCounts
                {...{
                  loading,
                  contentLocales,
                  onContentLocalesChange,
                  resetContentLocales,
                  contentLocaleCounts,
                  allowContentLocaleReset
                }}
              />
            </ListHeaderPanel>
          </ListHeader>
          {categoryPanels.length > 0 && (
            <Collapse accordion defaultActiveKey={defaultActiveKey}>
              {categoryPanels}
            </Collapse>
          )}
          {categoryPanels.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={I18n.t('uPhish.templateTiles.emptyMessage')}
            />
          )}
          <Divider />
          <TileContainer>
            <TemplateTile
              id='create'
              name={I18n.t('createAPolicyFromScratch', trOpt)}
              image='/images/templates/create-template.png'
              {...{ update }}
            />
          </TileContainer>
        </>
      )}
    </div>
  )
}

export default DefaultTemplates
