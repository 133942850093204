import { message } from 'antd'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import I18n from 'i18n-js'

import { ENROL_ON_GAP_ANALYSIS } from '../components/Queries/Courses'
import { showErrors } from './'

const trOpt = { scope: 'modals.enrolLearnersOnGapAnalysis' }

const showMessage = (type, results = [], msg) => {
  const count = results.length
  if (count === 0) {
    return false
  }

  message[type](I18n.t(msg, { ...trOpt, count }))
  return true
}

export const processEnrolLearnersOnGapAnalysisResult = result => {
  try {
    const { new: newResults = [], reminder = [] } = _groupBy(_get(result, `data.enrollLearnersOnGapAnalysis.completed`, []), 'action')
    const { completed = [], no_new_records: noNew = [], creation_error: error = [], failure = [] } = _groupBy(_get(result, `data.enrollLearnersOnGapAnalysis.failures`, []), 'reason')
    const output = [
      showMessage('success', newResults, 'enrolledMessage'),
      showMessage('success', reminder, 'reminderMessage'),
      showMessage('warning', completed, 'completedMessage'),
      showMessage('error', [...noNew, ...error, ...failure], 'enrolErrorMessage')
    ]

    if (output.every(o => o === false)) {
      throw new Error('No results sent from server')
    }
  } catch (e) {
    console.error('enrolLearnersOnGapAnalysis - error', e)
    message.error(I18n.t('errorMessage', trOpt))
  }
}

const enrolLearnersOnGapAnalysis = async (learnerIds = [], refetchQueries, client) => {
  const learnerCount = learnerIds.length
  if (learnerCount === 0) {
    message.error(I18n.t('noUsersSelectedMessage', trOpt))
  }

  try {
    const result = await client.mutate({
      mutation: ENROL_ON_GAP_ANALYSIS,
      variables: {
        learnerIds
      },
      refetchQueries
    })
    processEnrolLearnersOnGapAnalysisResult(result)
  } catch (e) {
    console.error('enrolLearnersOnGapAnalysis - error', e)
    showErrors(e, I18n.t('errorMessage', trOpt))
  }
}

export default enrolLearnersOnGapAnalysis
