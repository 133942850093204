import React, { useState, useEffect, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'

import { GET_COURSE_SLIDES, UPDATE_COURSE_SLIDES } from '../components/Queries/Courses'
import { LoadingBlock, ErrorAlerts } from '../components/common'
import { CourseContentEditor } from '../components/EditCourse'
import { generatePath } from 'react-router-dom'
import routes from '../constants/routes'
import { creators as viewActions } from '../state/actions/view'
import { connect } from '../hocs'

const trOpt = { scope: 'editCourseContent' }

const EditCourseSlides = ({ match, history, setLoadingVisible }) => {
  const { course_id: courseId, locale: routeLocale } = match.params
  const [courseData, setCourseData] = useState(null)
  const [locale, setLocale] = useState(null)
  const [currentSlides, setCurrentSlides] = useState([])
  const [slides, setSlides] = useState([])

  const { loading, error, data, refetch: refetchSlides } = useQuery(GET_COURSE_SLIDES, {
    variables: { courseId, locale: routeLocale },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })
  const [saveSlides] = useMutation(UPDATE_COURSE_SLIDES)
  useEffect(() => {
    if (!data) return

    const { course } = data || {}
    if (course) {
      const { name: courseName, locale: sourceLocale, slides } = course
      setCourseData({ courseName, sourceLocale })
      if (routeLocale) {
        setLocale(routeLocale)
      } else {
        setLocale(sourceLocale)
      }
      setSlides(slides || [])
      setCurrentSlides([...(slides || [])]) // Use copy of array to avoid unexpected modifications
    }
  }, [data, routeLocale])

  const onLocaleChange = useCallback(locale => {
    history.push(generatePath(routes.BUILDER_EDIT_SLIDES_LOCALE, { course_id: courseId, locale }))
  }, [history, courseId])

  if (error) return <ErrorAlerts {...{ error }} defaultError={I18n.t('theCourseCouldNotBeLoaded', trOpt)} />

  return (
    <>
      <LoadingBlock fullScreen loading={loading} />
      <CourseContentEditor
        {...(courseData || {})}
        {...{ courseId, locale, onLocaleChange, currentSlides, slides, setSlides, saveSlides, setLoadingVisible, refetchSlides }}
      />
    </>
  )
}

export default connect(
  null,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading))
  })
)(EditCourseSlides)
