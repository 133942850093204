import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'
import _get from 'lodash/get'
import I18n from 'i18n-js'
import moment from 'moment'

import { disableActions } from './listPages'

const trScope = 'uPolicy.uPolicy'
const commonTrScope = 'uPolicy.common'

const POLICY_ACTION_KEYS = {
  edit: `${commonTrScope}.editPolicy`,
  editLive: `${commonTrScope}.editLivePolicy`,
  editDraft: `${commonTrScope}.editDraftPolicy`,
  discardDraft: [`${trScope}.discardDraftPolicy`, `${trScope}.discardDraft`],
  publishDraft: [`${trScope}.publishDraftPolicy`, `${trScope}.publishDraft`],
  duplicate: [`${trScope}.duplicatePolicy`, `${trScope}.duplicateName`],
  delete: `${trScope}.deletePolicy`
}

const TEMPLATE_ACTION_KEYS = {
  edit: `${commonTrScope}.editTemplate`,
  editLive: `${commonTrScope}.editLiveTemplate`,
  editDraft: `${commonTrScope}.editDraftTemplate`,
  discardDraft: [`${trScope}.discardDraftTemplate`, `${trScope}.discardDraft`],
  publishDraft: [`${trScope}.publishDraftTemplate`, `${trScope}.publishDraft`],
  duplicate: [`${trScope}.duplicateTemplate`, `${trScope}.duplicateName`],
  delete: `${trScope}.deleteTemplate`
}

const getActionCopy = (keys, action, name) => {
  const key = keys[action]
  if (_isString(key)) {
    return I18n.t(key, { name })
  } else if (_isArray(key) && key.length >= 2) {
    return I18n.t(key[0], { name, defaults: [{ scope: key[1], name }] })
  } else if (_isArray(key) && key.length === 1) {
    return I18n.t(key[0], { name })
  }
  return null
}

export const getPolicyActions = ({ policy, builder, unified, role, userCompanyId, disabledActions } = {}) => {
  const actions = []
  if (policy) {
    const isUsecureAdmin = role === 'usecure-admin'
    const name = I18n.t(policy.template ? 'uPolicy.common.template' : 'uPolicy.common.policy')
    const keys = policy.template ? TEMPLATE_ACTION_KEYS : POLICY_ACTION_KEYS
    const actionTrOpt = { scope: trScope, name }
    const isLive = !_isNil(_get(policy, 'livePolicyDocument.id'))
    if ((builder || unified) && (isUsecureAdmin || policy.companyId === userCompanyId)) {
      const hasDraft = !_isNil(_get(policy, 'draftPolicyDocument.id'))
      if (isLive) {
        actions.push({
          key: 'editPolicy',
          label: getActionCopy(keys, hasDraft ? 'editLive' : 'edit', name),
          icon: 'edit'
        })
      }
      if (hasDraft) {
        actions.push({ key: 'editPolicyDraft', label: getActionCopy(keys, 'editDraft', name), icon: 'edit' })
        if (isLive) {
          actions.push({ key: 'discardPolicyDraft', label: getActionCopy(keys, 'discardDraft', name), icon: 'file-exclamation' })
        }
        actions.push({ key: 'publishPolicyDraft', label: getActionCopy(keys, 'publishDraft', name), icon: 'upload', theme: 'outlined' })
      }

      actions.push({ key: 'duplicatePolicy', label: getActionCopy(keys, 'duplicate', name), icon: 'copy' })
      if (policy.template) {
        actions.push(
          { key: 'addLanguages', label: I18n.t('common.addLanguages'), icon: 'global', theme: 'outlined' },
          { key: 'removeLanguages', label: I18n.t('common.removeLanguages'), icon: 'global', theme: 'outlined' }
        )
      }
      actions.push({ key: 'deletePolicy', label: getActionCopy(keys, 'delete', name), icon: 'delete' })
    }
    if ((!builder || unified) && !policy.template && isLive) {
      let canSend = true
      if (isUsecureAdmin) {
        canSend = policy.companyId === userCompanyId || (!policy.companyId && policy.global === true) ||
          (policy.companies || []).some(company => company.id === userCompanyId)
      }
      if (canSend) {
        actions.unshift(
          { key: 'sendPolicy', label: I18n.t('sendName', actionTrOpt), icon: 'mail' },
          { key: 'uploadPolicySignatures', label: I18n.t('uploadNameSignatures', actionTrOpt), icon: 'file-done', theme: 'outlined' }
        )
      }
      actions.push({ key: 'exportPolicyData', label: I18n.t('exportNameData', actionTrOpt), icon: 'file' })
    }
  }

  return disableActions(actions, disabledActions)
}

export const getPolicyRecipientStatusText = statusId => {
  let statusText
  switch (statusId) {
    case 'signed':
      statusText = I18n.t('uPolicy.common.signed')
      break
    case 'sent':
    case 'pending':
    case 'notSent':
    case 'visited':
      statusText = I18n.t(`common.${statusId}`)
      break
    default:
      statusText = I18n.t(statusId, { scope: 'uPolicy.viewPolicyUsers', defaultValue: statusId })
      break
  }
  return statusText
}

export const generateSignatureSettingsObject = ({ type, intervalLength, intervalUnit, startDate } = {}) => {
  const value = { type }
  value.type = type
  if (type === 'fixed' || type === 'lastSignature') {
    value.intervalLength = intervalLength
    value.intervalUnit = intervalUnit
  }
  if (type === 'fixed') {
    value.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null
  }
  return value
}
