import gql from 'graphql-tag'

export const ADD_LEARNERS_TO_GROUPS = gql`
  mutation($learnerIds: [ID!]!, $groupIds: [ID!]!) {
    addLearnersToGroups(learnerIds: $learnerIds, groupIds: $groupIds) {
      results {
        success
        groupId
        learnerIds
        completed
        failures 
      }
    }
  } 
`
