/* global localStorage */
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Card } from 'antd'
import { withRouter, generatePath } from 'react-router-dom'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import LandingPageTiles from '../../components/uPhish/LandingPageTiles'
import { TemplateHeader, ALL_FILTER_VALUE } from '../../components/uPhish'
import ViewLandingPageModal from '../../components/Modals/ViewLandingPageModal'
import routes from '../../constants/routes'
import { ContentWrap } from '../../components/common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { WRITABLE_LIBRARY_CARD_ACTIONS } from '../../constants/actions'
import { CATEGORY_OPTIONS } from '../../constants/uPhish'
import { disableActions } from '../../helpers/listPages'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'uPhish.landingPageLibrary' }

const landingPageURL = window.__USECURE_CONFIG__.REACT_APP_LANDING_PAGE_URL

const LandingPageLibrary = ({ match, history, companyId: userCompanyId, userId, contentLocales: defaultContentLocales, planValid }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [viewModalVisible, setViewModalVisible] = useState(false)

  const afterModalClose = useCallback(() => {
    history.push(routes.UPHISH_LANDING_PAGES)
  }, [history])

  useEffect(() => {
    const routeId = _get(match, 'params.landing_page_id', null)
    setSelectedId(routeId)
    if (routeId) {
      setViewModalVisible(true)
    }
  }, [match])

  const [searchText, setSearchText] = useState('')
  const [type, setType] = useState(ALL_FILTER_VALUE)
  const [category, setCategory] = useState(ALL_FILTER_VALUE)
  const [contentLocales, setContentLocales] = useState(defaultContentLocales || [])
  const [contentLocaleCounts, setContentLocaleCounts] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [templatesLoading, setTemplatesLoading] = useState(true)

  const disabledActions = useMemo(() => !planValid ? WRITABLE_LIBRARY_CARD_ACTIONS : null, [planValid])

  const storageId = useMemo(() => `landing-page-library|${userCompanyId}|${userId}`, [userId, userCompanyId])
  useEffect(() => {
    try {
      const filterString = localStorage.getItem(storageId)
      if (filterString) {
        const filter = JSON.parse(filterString)
        Object.keys(filter).forEach(key => {
          const value = filter[key]
          switch (key) {
            case 'searchText':
              setSearchText(value)
              break
            case 'type':
              setType(value)
              break
            case 'category':
              setCategory(value)
              break
            case 'contentLocales':
              setContentLocales(value)
              break
            case 'currentPage':
              setCurrentPage(value)
              break
            case 'pageSize':
              setPageSize(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  useEffect(() => {
    try {
      localStorage.setItem(storageId, JSON.stringify({
        searchText, type, category, contentLocales, currentPage, pageSize
      }))
    } catch (e) {}
  }, [searchText, type, category, contentLocales, currentPage, pageSize, storageId])

  const onViewClick = useCallback(templateId => {
    if (landingPageURL && templateId && userCompanyId) {
      window.open(`${landingPageURL}?t=${templateId}&s=-1&l=-1&sc=${userCompanyId}`, '_blank')
    }
  }, [userCompanyId])
  const onCloneClick = useCallback(templateId => {
    history.push(generatePath(routes.UPHISH_LANDING_PAGE_CLONE, { landing_page_id: templateId }))
  }, [history])

  const categories = useMemo(() => CATEGORY_OPTIONS.sort((a, b) => {
    if (a.value === 'none') return -1
    if (b.value === 'none') return 1
    return a.label.localeCompare(b.label)
  }), [])

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size-='h1' id='uPhish-landing-page-library-header'>{I18n.t('common.uPhish')} - {I18n.t('landingPageLibrary', trOpt)}</IntercomHeader>
        <TemplateHeader
          {...{
            type,
            setType,
            searchText,
            setSearchText,
            category,
            categories,
            setCategory,
            contentLocales,
            setContentLocales,
            contentLocaleCounts,
            defaultContentLocales
          }}
          loading={templatesLoading}
          searchPlaceholder={I18n.t('searchLandingPages', trOpt)}
          showContentLocaleCounts
        />
        <LandingPageTiles
          landingPageId={selectedId}
          queryVariables={{ restrictToPlan: true }}
          viewRoute={routes.UPHISH_LANDING_PAGE_VIEW}
          filters={{
            searchText,
            type,
            category,
            contentLocales
          }}
          categories={categories}
          modal={{
            component: ViewLandingPageModal,
            visible: viewModalVisible,
            setVisible: setViewModalVisible,
            afterClose: afterModalClose
          }}
          actions={disableActions([{
            id: 'view',
            title: I18n.t('common.preview'),
            icon: 'eye',
            onClick: onViewClick
          }, {
            id: 'clone',
            title: I18n.t('common.clone'),
            icon: 'copy',
            onClick: onCloneClick
          }], disabledActions)}
          {...{
            setContentLocaleCounts,
            currentPage,
            setCurrentPage,
            pageSize,
            setPageSize,
            setTemplatesLoading
          }}
          showPagination
          paginationTotalTrKey='uPhish.common.landingPagePaginationTotal'
        />
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withRouter,
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['userId', 'companyId', 'contentLocales', 'planValid'])
    })
  )
)(LandingPageLibrary)
