import React, { useEffect, useCallback, useRef, useMemo } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Table, Button } from 'antd'
import { compose } from 'recompose'
import styled, { withTheme } from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'
import { Pie } from '@nivo/pie'

import { ErrorAlerts, PrintableWrap } from '../../../components/common'
import { withProvider, connect, withAppTitleAndIcons } from '../../../hocs'
import reportAccessClient from '../../../apollo-client/reportAccessClient'
import { renderParagraphsFragmentFromArray } from '../../../helpers'
import {
  RiskReportContainer, RiskReportBody, RiskReportLayout, RiskReportCard,
  RiskReportSectionHeader,
  BreachCard, BreachCardContainer, RiskReportTextCard, RiskReportHeader, RiskReportHeaderBody, RiskReportHeaderLogo, RiskReportHeaderInfo, RiskReportHeaderTitle, ErrorAlertWrapper, RiskReportPrintCover, RiskReportCardBody
} from '../../../components/Reports/RiskReport'
import { getBrowserLocale } from '../../../helpers/locale'
import { creators as sessionActions } from '../../../state/actions/session'
import { creators as settingsActions } from '../../../state/actions/settings'
import { creators as viewActions } from '../../../state/actions/view'
import { DEFAULT_LANGUAGE } from '../../../constants/languages'
import useReportAccessToken from '../../../hooks/useReportAccessToken'

import useIsPrinting from '../../../hooks/useIsPrinting'
import DownloadReportModal from '../../../components/Modals/DownloadReportModal'
import { DOMAIN_SCAN_REPORT_ACCESS, REPORT_ME } from '../../../components/Queries/Reports'
import { captureSentryError } from '../../../helpers/sentry'

import defaultTheme from '../../../theme/themes'
import { renderDataClasses } from '../../../components/uBreach/BreachesTable'
import { formatDate } from '../../../helpers/datetime'

const trOpt = { scope: 'reports.domainScanReport' }

const PieChart = ({ data = [] }) => {
  const isPrinting = useIsPrinting()

  const chartProps = useMemo(() => {
    const width = 768
    const height = 500
    const marginRight = width - height

    return {
      data,
      margin: {
        top: 0,
        right: marginRight,
        bottom: 0,
        left: 0
      },
      innerRadius: 0.5,
      padAngle: 0.7,
      cornerRadius: 3,
      colors: [defaultTheme.main.red, defaultTheme.main.orange, defaultTheme.main.primary],
      colorBy: 'id',
      sliceLabelsSkipAngle: 14,
      sliceLabelsTextColor: '#ffffff',
      borderWidth: 1,
      borderColor: 'inherit:darker(0.2)',
      animate: !isPrinting,
      isInteractive: !isPrinting,
      enableRadialLabels: false,
      valueFormat: value => I18n.t('common.percentage', { value }),
      legends: [
        {
          justify: false,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolShape: 'circle',
          translateX: 40,
          translateY: 0,
          itemsSpacing: 0,
          itemWidth: 0,
          itemHeight: 25,
          symbolSize: 18,
          anchor: 'right',
          direction: 'column'
        }
      ],
      theme: {
        fontSize: 18
      },
      width: width,
      height: height
    }
  }, [data, isPrinting])

  return (
    <Pie
      data={data}
      {...chartProps}
    />
  )
}

const BreachDomainReportIntro = styled.div`
  @media print {
    margin-top: 20px;
  }
`

const SeverityChartContainer = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 40px;
`

const DomainScanExposedInformationTable = ({ exposedInformation, services }) => {
  const columns = [
    {
      title: I18n.t('exposedInformation.email', trOpt),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: I18n.t('exposedInformation.breachName', trOpt),
      dataIndex: 'breachName',
      key: 'breachName'
    },
    {
      title: I18n.t('exposedInformation.password', trOpt),
      dataIndex: 'password',
      key: 'password'
    },
    {
      title: I18n.t('exposedInformation.dataClasses', trOpt),
      dataIndex: 'dataClasses',
      key: 'dataClasses',
      width: 450
    }
  ]

  const data = exposedInformation.map(({ email, password, breach }) => {
    const { title, name, dataClasses } = services.find(({ name }) => name === breach)
    return {
      key: `${email}_${name}`,
      email,
      breachName: title,
      password,
      dataClasses: renderDataClasses(dataClasses)
    }
  })

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  )
}

const BreachDomainReportSummaryCardContainer = styled(RiskReportLayout)`
  padding: 0 10px;

  ${RiskReportCard} {
    padding: 0 10px 30px;
  }

  @media print {
    padding: 0;
    ${RiskReportCard} {
      left: 20px;
      padding: 0 10px 20px;
      position: relative;
      width: calc(33% - 20px);

      ${RiskReportCardBody} {
        border: 1px #C4C4C4 solid;
      }
    }
  }
`

const BreachDomainReportSection = styled.div`
  width: 100%;
  @media print {
    margin-bottom: 20px;
  }
`

const DomainScanReportContentView = withTheme(({ theme, setLoadingVisible, setTheme, updateSession, setPageTitle, resetPageTitle, setLocale }) => {
  const scroller = useRef(null)
  const { reportAccessToken } = useReportAccessToken()
  const isPrinting = useIsPrinting()

  // Scroll to top after printing
  useEffect(() => {
    if (scroller.current && isPrinting) {
      scroller.current.scrollTop = 0
    }
  }, [isPrinting, scroller])

  const [getDomainScanReportAccess, {
    loading: domainScanReportAccessLoading,
    error: domainScanReportAccessError,
    data: domainScanReportAccess
  }] = useLazyQuery(DOMAIN_SCAN_REPORT_ACCESS, {
    onError: (error) => {
      captureSentryError(error, { msg: 'Performance Report - Access Report Data Load - ERROR' })
    }
  })
  const { data: { reportMe } = {}, loading: reportMeLoading, error: reportMeError } = useQuery(REPORT_ME, {
    skip: !reportAccessToken,
    onError: (error) => {
      captureSentryError(error, { msg: 'Domain Scan Report - Access Token Verification - ERROR' })
    },
    onCompleted: (data) => {
      if (!_isEmpty(data)) {
        const { reportMe } = data
        setTheme({
          ...(_get(reportMe, 'company.settings') || {}),
          parentDefaultSettings: _get(reportMe, 'company.parentCompany.settings.defaultTenantSettings')
        })
        updateSession({
          locale: getBrowserLocale(null) || _get(reportMe, 'company.locale') || DEFAULT_LANGUAGE
        })
        getDomainScanReportAccess()
      }
    }
  }, [reportAccessToken])

  const { services, exposedInformation, name, domain, severityBreakdown, cards, logo, reportType } = useMemo(() => {
    const {
      domainScanReportAccess: {
        services = [],
        exposedInformation = [],
        uniqueEmails,
        name,
        domain,
        logo
      } = {}
    } = domainScanReportAccess || {}

    const serviceCount = services.length
    const cards = [
      {
        label: I18n.t('summary.emailAddressesAffected', trOpt),
        value: uniqueEmails
      },
      {
        label: I18n.t('summary.breachedServicesFound', trOpt),
        value: serviceCount
      },
      {
        label: I18n.t('summary.emailBreachesDetected', trOpt),
        value: services.reduce((acc, { affected }) => acc + affected, 0)
      }
    ]

    const severityBreakdownTotals = services.reduce((acc, { severity, affected }) => {
      acc[severity] += affected
      return acc
    }, {
      high: 0,
      medium: 0,
      low: 0
    })
    const totalAffected = Object.values(severityBreakdownTotals).reduce((acc, value) => acc + value, 0)

    const severityBreakdown = Object.entries(severityBreakdownTotals).map(([id, value]) => {
      const percentage = value ? Math.round((value / totalAffected) * 100) : 0
      return {
        id,
        value: percentage,
        label: I18n.t(id, { scope: 'reports.breachReport.common.graphs.severityBreakdown.labels', percentage, value })
      }
    })

    return {
      services,
      exposedInformation,
      cards,
      severityBreakdown,
      name,
      domain,
      logo,
      reportType
    }
  }, [domainScanReportAccess])

  // Set initial language from browser
  useEffect(() => setLocale(getBrowserLocale()), [setLocale])

  useEffect(() => {
    setLoadingVisible(domainScanReportAccessLoading || reportMeLoading)
  }, [domainScanReportAccessLoading, reportMeLoading, setLoadingVisible])

  const loading = useMemo(() => domainScanReportAccessLoading || reportMeLoading, [domainScanReportAccessLoading, reportMeLoading])

  // Temporarily change page title to a filename string when printing
  useEffect(() => {
    if (isPrinting) {
      setPageTitle(I18n.t('titleWithDomain', { ...trOpt, domain }))
    } else {
      resetPageTitle()
    }
  }, [isPrinting, setPageTitle, resetPageTitle, domain])

  const chartContainerRef = useRef(null)
  const downloadReportModalRef = useRef(null)
  const openDownloadReportModal = useCallback(() => {
    if (downloadReportModalRef.current) {
      downloadReportModalRef.current.open({
        name: I18n.t('reports.downloadReport.fileNames.domainScanReport', { domain }),
        logo,
        domain,
        reportType
      }, reportAccessToken)
    }
  }, [downloadReportModalRef, domain, logo, reportType, reportAccessToken])

  return (
    <PrintableWrap>
      {(!reportMeError && reportMe && !domainScanReportAccessError && !reportMeLoading && !domainScanReportAccessLoading) && <div id='usecure-report-safe' />}
      <DownloadReportModal ref={downloadReportModalRef} />
      <RiskReportPrintCover companyName={name || reportMe?.company?.name} {...{ trOpt, logo }} />
      <RiskReportContainer>
        <RiskReportHeader className='hideOnPrint'>
          <RiskReportHeaderBody>
            <RiskReportHeaderLogo {...{ loading, logo }} />
            <RiskReportHeaderInfo>
              <RiskReportHeaderTitle>
                <span>{domain ? I18n.t('titleWithDomain', { ...trOpt, domain }) : I18n.t('title', trOpt)}</span>
                <Button icon='download' size='small' onClick={openDownloadReportModal}>{I18n.t('common.download')}</Button>
              </RiskReportHeaderTitle>
            </RiskReportHeaderInfo>
          </RiskReportHeaderBody>
        </RiskReportHeader>
        {(reportMeError || domainScanReportAccessError) && (
          <ErrorAlertWrapper>
            {reportMeError && <ErrorAlerts {...{ error: reportMeError }} defaultError={I18n.t('reportMeDefaultErrorMsg', trOpt)} />}
            {domainScanReportAccessError && <ErrorAlerts {...{ error: domainScanReportAccessError }} defaultError={I18n.t('defaultErrorMsg', trOpt)} />}
          </ErrorAlertWrapper>
        )}
        {!loading && !(reportMeError || domainScanReportAccessError) && domainScanReportAccess && (
          <RiskReportBody ref={scroller}>
            <RiskReportLayout>
              {
                services.length === 0 ? (
                  <RiskReportTextCard {...{ theme }}>
                    <h1>{I18n.t('empty.title', { ...trOpt, domain })}</h1>
                    {renderParagraphsFragmentFromArray(I18n.t('empty.description', trOpt))}
                  </RiskReportTextCard>
                ) : (
                  <>
                    <BreachDomainReportIntro>
                      <RiskReportTextCard {...{ theme }}>
                        <h1>{I18n.t('intro.title', { ...trOpt, domain })}</h1>
                        {renderParagraphsFragmentFromArray(I18n.t('intro.description', trOpt))}
                      </RiskReportTextCard>
                    </BreachDomainReportIntro>
                    <BreachDomainReportSection>
                      <RiskReportSectionHeader pageBreakBefore='auto'>{I18n.t('summary.sectionTitle', trOpt)}</RiskReportSectionHeader>
                      <BreachDomainReportSummaryCardContainer>
                        {cards.map(({ label, value }) => (
                          <RiskReportCard span={4} key={label}>
                            <h2>{value}</h2>
                            <p>{label}</p>
                          </RiskReportCard>
                        ))}
                      </BreachDomainReportSummaryCardContainer>
                    </BreachDomainReportSection>
                    <BreachDomainReportSection>
                      <RiskReportSectionHeader pageBreakBefore='auto'>{I18n.t('exposedInformation.sectionTitle', trOpt)}</RiskReportSectionHeader>
                      <RiskReportCard>
                        <DomainScanExposedInformationTable {...{ exposedInformation, services }} />
                      </RiskReportCard>
                    </BreachDomainReportSection>

                    <RiskReportSectionHeader>{I18n.t('severity.sectionTitle', trOpt)}</RiskReportSectionHeader>
                    <RiskReportCard>
                      <SeverityChartContainer ref={chartContainerRef}>
                        <PieChart
                          data={severityBreakdown}
                          colors={[defaultTheme.main.red, defaultTheme.main.orange, defaultTheme.main.primary]}
                          // containerRef={chartContainerRef}
                        />
                      </SeverityChartContainer>
                      <p>{I18n.t('severity.descriptions.low', trOpt)}</p>
                      <p>{I18n.t('severity.descriptions.medium', trOpt)}</p>
                      <p>{I18n.t('severity.descriptions.high', trOpt)}</p>
                    </RiskReportCard>

                    <RiskReportSectionHeader>{I18n.t('services.sectionTitle', trOpt)}</RiskReportSectionHeader>
                    <BreachCardContainer>
                      {services.map(service => {
                        const serviceProp = {
                          ..._pick(service, ['title', 'description', 'dataClasses', 'severity']),
                          count: service.affected,
                          addedDate: formatDate(service.addedDate)
                        }
                        return (
                          <BreachCard
                            key={service.name}
                            service={serviceProp}
                            theme={theme}
                            inEng
                            useDataClassColours
                          />
                        )
                      })}
                    </BreachCardContainer>
                  </>
                )
              }
            </RiskReportLayout>
          </RiskReportBody>
        )}
      </RiskReportContainer>
    </PrintableWrap>
  )
})

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading)),
      setTheme: settings => dispatch(settingsActions.updateTheme(settings)),
      updateSession: session => dispatch(sessionActions.noAuthUpdate(session)),
      setLocale: locale => dispatch(sessionActions.updateLocaleOnly(locale)),
      setPageTitle: title => dispatch(viewActions.title(title)),
      resetPageTitle: () => dispatch(viewActions.resetTitle())
    })
  ),
  withProvider(reportAccessClient),
  withAppTitleAndIcons({ noAuth: true })
)(DomainScanReportContentView)
