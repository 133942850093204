
import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { ExcludeInactiveToggle } from '../../components/common'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'reports.dashboard' }

const DashboardHeaderStyled = styled.div`
height: 100%;
background-color: white;
padding: 40px;
display: flex;
flex-direction: column;
`

const CompanyDetails = styled.div`
height: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`

const DashboardHeader = ({ companyName, companyDomain, totalUsers, totalGroups }) => {
  return (
    <DashboardHeaderStyled style={{ backgroundColor: 'white', gridArea: 'topbar' }}>
      <CompanyDetails>
        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <IntercomHeader id='company-name-header' Size='h3' style={{ margin: 0 }}>{companyName}</IntercomHeader>
          {companyDomain && <IntercomHeader id='company-domain-sub-header' Size='p' style={{ margin: 0 }}>{companyDomain}</IntercomHeader>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{
            borderRight: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '200px',
            marginRight: '20px'
          }}
          >
            <p style={{ margin: '0' }}>{I18n.t('totalUsers', trOpt)}</p>
            <h4 style={{ margin: '0' }}>{totalUsers || I18n.t('common.na')}</h4>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '200px',
              marginRight: '20px'
            }}
          >
            <p style={{ margin: '0' }}>{I18n.t('totalGroups', trOpt)}</p>
            <h4 style={{ margin: '0' }}>{totalGroups || I18n.t('common.na')}</h4>
          </div>
          <div style={{ marginBottom: 10, textAlign: 'right' }}>
            <ExcludeInactiveToggle />
          </div>
        </div>
      </CompanyDetails>
    </DashboardHeaderStyled>
  )
}

export default DashboardHeader
