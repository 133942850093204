import React, { useImperativeHandle, useState, useCallback, useEffect, useMemo } from 'react'
import { message, Modal, Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import ExportServiceClient from '../../helpers/ExportServiceClient'
import StatusIndicator from '../Reports/DownloadReportStatusIndicator'

// CL - DownloadReportModal and ExportServiceDownloadModal are very similar and could be combined. I've added comments where they differ.

const DownloadModal = styled(Modal)`
  max-width: 500px;
  top: 50px;
`

const FailedOkBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

// CL - The keys and text under this are identical to modals.downloadReportModal
const trOpt = { scope: 'modals.exportServiceDownloadModal' }

const ExportServiceDownloadModal = React.forwardRef(({
  fileName,
  fileType,
  baseUrl
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [jobData, setJobData] = useState(null)
  const [status, setStatus] = useState(null)

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setJobData(null)
    setStatus(null)
  }

  const onStatusChange = useCallback(({ status }) => {
    setStatus(status)
  }, [setStatus])

  const onFinish = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal])

  // CL - Specific to this modal
  const exportServiceClient = useMemo(() => {
    if (!jobData || !fileName || !fileType) return null

    return new ExportServiceClient({
      onStart: onStatusChange,
      onStatusChange,
      onFinish,
      fileType,
      fileName,
      baseUrl
    })
  }, [jobData, onStatusChange, onFinish, fileName, fileType, baseUrl])

  // CL - Specific to this modal
  useEffect(() => {
    if (!exportServiceClient && !jobData) return
    exportServiceClient.execute(jobData)
  }, [exportServiceClient, jobData])

  useImperativeHandle(ref, () => ({
    open: async (jobData) => {
      setJobData(jobData) // CL - Specific to this modal
      setStatus('pending')
      setVisible(true)
    }
  }), [setJobData, setVisible])

  return (
    <DownloadModal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
      closable={false}
      maskClosable={false}
    >
      <StatusIndicator {...{ status, trOpt }} />
      {status === 'failed' && (
        <FailedOkBtnContainer>
          <Button type='primary' onClick={closeModal}>{I18n.t('common.ok')}</Button>
        </FailedOkBtnContainer>
      )}
    </DownloadModal>
  )
})

export default ExportServiceDownloadModal
