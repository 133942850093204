import React, { Component } from 'react'
import { compose } from 'recompose'
import { Link, withRouter, generatePath } from 'react-router-dom'
import { Menu, Icon, Tag } from 'antd'
import styled, { css, withTheme } from 'styled-components'
import I18n from 'i18n-js'
import queryString from 'query-string'
import _isBoolean from 'lodash/isBoolean'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'

import routes, { uLearnAllowed, uPhishAllowed } from '../../constants/routes'
import { isRouteAllowed } from '../../helpers'
import { connect } from '../../hocs'
import LogOut from './LogOut'
import UnassumeCompanyUser, { isUnassumeCompanyUserAllowed } from './UnassumeCompanyUser'
import AssumeUserRole, { isAssumeUserRoleAllowed } from './AssumeUserRole'
import { getSessionAndSettings } from '../../state/selectors'
import { ACCOUNT_TYPES } from '../../constants/company'
import { CLIENT_ENV, ENABLE_REPORT_CENTRE, REPORT_CENTRE_PREVIEW } from '../../constants/environment'
import { LanguageDropdownI18nTest } from '../common/LanguageDropdown'
import { PreviewTag as _PreviewTag, PopUpContainer as BasePopUpContainer } from '../common'
import { isLocale, isLocaleEnglish } from '../../helpers/locale'

const trOpt = { scope: 'nav.navBar' }
// Manual Language Switcher for testing - not available in production build
const NAV_LANGUAGE_SWITCHER = window.__USECURE_CONFIG__.REACT_APP_NAV_LANGUAGE_SWITCHER === 'true' && CLIENT_ENV !== 'production'

const isRiskReportPreviewAllowed = ({ item, session, settings }) => {
  return isRiskReportAllowed({ item, session, settings }) && session.accountType === 'tenant'
}
const isRiskReportAllowed = ({ session }) => {
  const { accountType, prospectStatus, inDisguise, originalRole } = session || {}
  return accountType === 'prospect' ||
    (
      accountType === 'tenant' &&
      prospectStatus && prospectStatus !== 'finished' &&
      inDisguise && (originalRole === 'usecure-admin' || originalRole === 'admin')
    )
}

const isAutoEnrolAllowed = ({ session, settings }) =>
  session.accountType !== 'prospect' && uLearnAllowed({ session, settings }) &&
  (!session.partner || settings.platformAccess) // Hides option from partners without an NFR
const isPhishAlertAllowed = ({ session, settings }) =>
  settings.phishAlertEnabled === true && // Phish Alert Button feature flag
  session.accountType !== 'prospect' && uPhishAllowed({ session, settings }) &&
  (!session.partner || settings.platformAccess) // Hides option from partners without an NFR

// Map routes that appear to associated routes that don't
// e.g. the course edit page stems course builder so that is highlighted in the menu
const ROUTE_MAP = {
  [routes.BUILDER_CREATE]: routes.BUILDER,
  [routes.BUILDER_EDIT]: routes.BUILDER,
  [routes.BUILDER_PREVIEW_COURSE]: routes.BUILDER,
  [routes.BUILDER_PREVIEW_COURSE_LOCALE]: routes.BUILDER,
  [routes.BUILDER_PREVIEW_SLIDE]: routes.BUILDER,
  [routes.BUILDER_PREVIEW_SLIDE_LOCALE]: routes.BUILDER,
  [routes.BUILDER_EDIT_SLIDES]: routes.BUILDER,
  [routes.BUILDER_EDIT_SLIDES_LOCALE]: routes.BUILDER,
  [routes.BUILDER_EDIT_SLIDE]: routes.BUILDER,
  [routes.BUILDER_EDIT_SLIDE_LOCALE]: routes.BUILDER,
  [routes.UPHISH_SIM]: routes.UPHISH_SIMS,
  [routes.UPHISH_EDIT_SIM]: routes.UPHISH_SIMS,
  [routes.UPHISH_CLONE_SIM]: routes.UPHISH_SIMS,
  [routes.UPHISH_CREATE_SIM_FROM_REC]: routes.UPHISH_SIMS,
  [routes.UPHISH_LANDING_PAGE_VIEW]: routes.UPHISH_LANDING_PAGES,
  [routes.UPHISH_LANDING_PAGE_BUILDER_VIEW]: routes.UPHISH_LANDING_PAGE_BUILDER,
  [routes.UPHISH_LANDING_PAGE_CREATE]: routes.UPHISH_LANDING_PAGE_BUILDER,
  [routes.UPHISH_LANDING_PAGE_UPDATE]: routes.UPHISH_LANDING_PAGE_BUILDER,
  [routes.UPHISH_LANDING_PAGE_CLONE]: routes.UPHISH_LANDING_PAGE_BUILDER,
  [routes.UPHISH_EMAIL_TEMPLATE_VIEW]: routes.UPHISH_EMAIL_TEMPLATES,
  [routes.UPHISH_EMAIL_TEMPLATE_BUILDER_VIEW]: routes.UPHISH_EMAIL_TEMPLATE_BUILDER,
  [routes.UPHISH_EMAIL_TEMPLATE_CREATE]: routes.UPHISH_EMAIL_TEMPLATE_BUILDER,
  [routes.UPHISH_EMAIL_TEMPLATE_UPDATE]: routes.UPHISH_EMAIL_TEMPLATE_BUILDER,
  [routes.UPHISH_EMAIL_TEMPLATE_CLONE]: routes.UPHISH_EMAIL_TEMPLATE_BUILDER,
  [routes.UPOLICY_CREATE]: routes.UPOLICY,
  [routes.UPOLICY_CREATE_FROM_TEMPLATE]: routes.UPOLICY,
  [routes.UPOLICY_EDIT]: routes.UPOLICY,
  [routes.UPOLICY_EDIT_DRAFT]: routes.UPOLICY,
  [routes.UPOLICY_VIEW]: routes.UPOLICY,
  [routes.UPOLICY_TEMPLATE_VIEW]: routes.UPOLICY_TEMPLATES,
  [routes.UPOLICY_TEMPLATE_BUILDER_VIEW]: routes.UPOLICY_TEMPLATE_BUILDER,
  [routes.UPOLICY_TEMPLATE_CREATE]: routes.UPOLICY_TEMPLATE_BUILDER,
  [routes.UPOLICY_TEMPLATE_EDIT]: routes.UPOLICY_TEMPLATE_BUILDER,
  [routes.UPOLICY_TEMPLATE_EDIT_DRAFT]: routes.UPOLICY_TEMPLATE_BUILDER
}

const PreviewTag = styled(_PreviewTag)`
  border: 1px solid ${({ theme }) => theme.secondary};
`

export const StyledMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.nav};
  color: ${({ theme }) => theme.secondary};
  line-height: 70px;
  border-bottom: none;

  &> .ant-menu-item:hover {
    border-bottom-color: transparent;
    background-color: ${({ theme }) => theme.primary};
  }

  &> .ant-menu-submenu {
    background-color: ${({ theme }) => theme.nav};
    top: 0;
  }

  &> .ant-menu-item {
    top: 0;
  }
`

export const MenuItem = styled(Menu.Item)`
  background-color: ${({ theme }) => theme.nav};
  float: ${({ float = 'none' }) => float};
  ${({ autoheight }) =>
    autoheight === 'true'
      ? css`
  &.ant-menu-item{
    height: auto;
  }
  `
  : css``
  }

  &.ant-menu-item > a {
    color: ${({ theme }) => theme.secondary};

    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }

  &.ant-menu-item-active, &.ant-menu-item-selected, &:hover {
    border-bottom-color: transparent;
    background-color: ${({ theme }) => theme.primary} !important;
    color: ${({ theme }) => theme.secondary};
  }

  .usecure-menu-item {
    color: ${({ theme }) => theme.secondary};
  }
`

export const _SubMenu = ({ popupClassName, ...props }) => (
  <Menu.SubMenu popupClassName={`usecure-submenu-popup ${popupClassName}`} {...props}></Menu.SubMenu>
)

export const SubMenu = styled(_SubMenu)`
  background-color: ${({ theme }) => theme.nav};
  color: ${({ theme }) => theme.secondary};
  float: ${({ float = 'none' }) => float};

  &> .ant-menu {
    color: ${({ theme }) => theme.secondary};
    background-color: ${({ theme }) => theme.nav};
  }

  .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.primary};
  }

  &:hover, &.ant-menu-submenu-selected, &.ant-menu-submenu-active {
    color: ${({ theme }) => theme.primary} !important;
    border-bottom-color: ${({ theme }) => theme.primary} !important;
  }
`

export const LogoMenuItem = styled(Menu.Item)`
  padding: 0 15px 0 0;

  &.ant-menu-item > a {
    height: 70px;
  }

  &.ant-menu-item:hover {
    border-bottom-color: transparent;
    background-color: unset;
  }
`
export const Logo = styled.div`
  background-image: url('${({ theme }) => theme.appThemeLogo}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 100%;
  width: 110px;
`

export const NavBarImageIcon = styled.div`
  display: inline-block;
  position: relative;

  img {
    width: 100%;
  }

  .anticon-user,
  .anticon-setting {
    vertical-align: middle;
    margin-right: 0
  }

  .anticon-setting {
    font-size: 26px;
  }

  .anticon-user {
    font-size: 31px;
  }
`

const NavBarDisguiseIcon = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  height: 20px;
  justify-content: center;
  left: 5px;
  position: absolute;
  top:11px;
  width: 20px;
`

export const SessionLevelTag = styled(Tag)`
  padding-top: 4px;
`

export const UserNameAndLevel = styled.div`
  display: inline-block;
  line-height: 1;
  position: relative;
  top: 15px;
  vertical-align: top;

  .usecure-user-name {
    display: block;
    line-height: 1;
  }
  .usecure-company-name {
    display: block;
    line-height: 1;
    margin-top: 0.5em;
  }
  ${SessionLevelTag} {
    margin-top: 2px;
  }
`

const UserSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    padding-left: 0;
  }
`

export const UserSubMenuTitle = styled.div`
  &:before {
    border-left: solid 1px ${({ theme }) => theme.secondary};
    content: '';
    display: ${({ showDivider }) => showDivider ? 'inline' : 'none'};
    height: 25px;
    margin-right: 20px;
    position: relative;
    top: -1px;
  }

  ${NavBarImageIcon} {
    margin-right: 18px;
  }
`

export const TopMenuItemTitle = styled.span`
  position: relative;
  top: 3px;
`

const SettingsMenuIcon = styled(NavBarImageIcon)``
export const SettingsMenuTitle = styled.span`
  display: none;
`

const PopUpContainer = styled(BasePopUpContainer)`

  /* Styling for the overflow (...) menu */
  ul[id$="-overflowed-indicator$Menu"] {
    background-color: ${({ theme }) => theme.nav};
    border-radius: 4px;
    flex-direction: column;
    padding-bottom: 5px;
    padding-top: 5px;

    &:not(.ant-menu-hidden) {
      display: flex;
    }

    .ant-menu-submenu {
      width: 100%;
    }

    /* Forces account menu to the end of the overflow menu - due to float:right on settings and account menus */
    &> li{
      order: -1;

      &:nth-last-child(2) {
        order: 0;
      }
    }

    &> .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
      padding-right: 40px;
    }

    ${TopMenuItemTitle} {
      display: inline-block;
      margin-left: 5px;
    }

    ${UserSubMenuTitle} {
      line-height: 70px;
      margin-left: 15px;

      &:before {
        display: none;
      }
    }

    ${SettingsMenuIcon} {
      margin-left: 7px;
    }
    ${SettingsMenuTitle} {
      display: inline;
      margin-left: 21px;
    }
  }
`

class NavBar extends Component {
  constructor (props) {
    super(props)

    this.popupContainer = React.createRef()

    this.getPopupContainer = this.getPopupContainer.bind(this)
  }

  get mainMenu () {
    const { session: { role, companyId, partner, accountType, inDisguise = false, originalRole, locale, parentCompanyId, planValid }, settings: { platformAccess, enableProspects, helpCentreEnabled, uBreachProEnabled = false } } = this.props
    const isUsecureAdmin = role === 'usecure-admin'
    const showInvoices = this.isAllowed({ link: routes.USERVICE_INVOICE })
    const isMSPWithProspects = accountType === 'msp' && enableProspects
    const isEnglish = isLocaleEnglish(locale)
    const isFrench = isLocale(locale, 'fr')
    const isSpanish = isLocale(locale, 'es')
    const isGerman = isLocale(locale, 'de')
    const isDutch = isLocale(locale, 'nl')
    const isNorwegian = isLocale(locale, 'no')
    const isPortuguese = isLocale(locale, 'pt')
    const isSwedish = isLocale(locale, 'sv')

    const marketingAndSalesLink = (() => {
      let _locale = locale.substr(0, 2)

      if (_locale === 'de') {
        _locale = 'de-form'
      } else if (_locale === 'no') {
        _locale = 'nb'
      }

      return `https://help.usecure.io/${_locale}/collections/3144405`
    })()

    let riskReportMenuItem = {
      title: I18n.t('riskReport.openRiskReport'),
      externalLink: `${generatePath(routes.RISK_REPORT, { companyId })}?${queryString.stringify({ readOnly: true })}`,
      isAllowed: isRiskReportAllowed
    }
    if (accountType !== 'prospect') {
      riskReportMenuItem = {
        title: I18n.t('riskReport.title', { companyName: '' }), // Should give 'Risk Report' in en
        children: [
          {
            title: I18n.t('riskReport.riskReportPreview'),
            link: routes.RISK_REPORT_PREVIEW,
            isAllowed: isRiskReportPreviewAllowed
          },
          riskReportMenuItem
        ]
      }
    }

    const uLearnReportMenuItem = {
      title: I18n.tWithProductNames('reports.uLearnReport.uLearnReport'),
      link: routes.ULEARN_REPORT
    }

    const gapAnalysisReportMenuItem = {
      title: I18n.t('reports.uLearnReport.gapAnalysisReport'),
      link: routes.GAP_ANALYSIS_REPORT
    }

    const mainMenu = [
      {
        title: I18n.t('home', trOpt),
        link: accountType === 'prospect' ? routes.RISK_REPORT_PREVIEW : routes.HOME,
        children: []
      },
      {
        title: I18n.t('common.users'),
        link: routes.LEARNERS,
        children: []
      }, {
        key: 'uphish',
        title: I18n.t('common.uPhish'),
        children: [
          {
            title: I18n.t('viewSimulations', trOpt),
            link: routes.UPHISH_SIMS
          }, {
            title: I18n.t('uPhish.common.createSimulation'),
            link: routes.UPHISH_CREATE_SIM,
            disabled: !planValid
          }, {
            title: I18n.t('configureAutoPhish', trOpt),
            link: routes.UPHISH_AUTO_PHISH,
            disabled: !planValid
          }, {
            title: I18n.t('phishAlertButton', trOpt),
            link: decodeURIComponent(generatePath(routes.SETTINGS_SCREEN, { screenPath: 'phish/phish-alert-button' })),
            isAllowed: isPhishAlertAllowed,
            preview: window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_PREVIEW === 'true',
            disabled: !planValid
          }, {
            title: I18n.t('uPhish.landingPageLibrary.landingPageLibrary'),
            link: routes.UPHISH_LANDING_PAGES
          }, {
            title: I18n.t('uPhish.landingPageBuilder.landingPageBuilder'),
            link: routes.UPHISH_LANDING_PAGE_BUILDER
          }, {
            title: I18n.t('uPhish.emailTemplateLibrary.emailTemplateLibrary'),
            link: routes.UPHISH_EMAIL_TEMPLATES
          }, {
            title: I18n.t('uPhish.emailTemplateBuilder.emailTemplateBuilder'),
            link: routes.UPHISH_EMAIL_TEMPLATE_BUILDER
          }, {
            title: I18n.t('reports.simulationPerformance.title'),
            link: routes.SIMULATION_PERFORMANCE
          }
        ]
      }, {
        key: 'ulearn',
        title: I18n.t('common.uLearn'),
        children: [
          uLearnReportMenuItem,
          gapAnalysisReportMenuItem,
          {
            title: I18n.t('common.autoEnrol'),
            link: decodeURIComponent(generatePath(routes.SETTINGS_SCREEN, { screenPath: 'learn/auto-enrol' })),
            isAllowed: isAutoEnrolAllowed,
            disabled: !planValid
          },
          {
            title: I18n.t('courseLibrary.courseLibrary'),
            link: routes.COURSE_LIBRARY
          },
          {
            title: I18n.t('courses.courseBuilder'),
            link: routes.BUILDER
          }
        ]
      },
      {
        title: uBreachProEnabled ? I18n.t('common.uBreachPro') : I18n.t('common.uBreach'),
        link: routes.UBREACH
      },
      {
        key: 'upolicy',
        title: I18n.t('common.uPolicy'),
        children: [
          {
            title: I18n.t('uPolicy.common.templateLibrary'),
            link: routes.UPOLICY_TEMPLATES
          },
          {
            title: I18n.t('uPolicy.common.templateBuilder'),
            link: routes.UPOLICY_TEMPLATE_BUILDER
          },
          {
            title: I18n.t('viewPolicies', trOpt),
            link: routes.UPOLICY
          }
        ]
      },
      {
        key: 'reports',
        title: I18n.t('reports', trOpt),
        children: [
          {
            title: I18n.t('reports.reportCentre.title'),
            link: routes.REPORT_CENTRE,
            isAllowed: ENABLE_REPORT_CENTRE,
            preview: REPORT_CENTRE_PREVIEW
          },
          uLearnReportMenuItem,
          gapAnalysisReportMenuItem,
          {
            title: I18n.t('reports.simulationPerformance.title'),
            link: routes.SIMULATION_PERFORMANCE
          }, {
            title: I18n.t('reports.courseParticipation.title'),
            link: routes.COURSE_PARTICIPATION
          }
        ]
      },
      riskReportMenuItem
    ]
    if (helpCentreEnabled) {
      const helpCentreMenuItem = {
        key: 'helpCentre',
        title: I18n.t('helpCentre', trOpt),
        children: [
          {
            title: I18n.t('helpCentre.knowledgeBase'),
            externalLink: 'https://help.usecure.io/'
          },
          {
            title: I18n.t('helpCentre.whatsNew'),
            externalLink: 'https://blog.usecure.io/topic/product',
            isAllowed: isEnglish
          },
          {
            title: I18n.t('helpCentre.whatsNew'),
            externalLink: isFrench ? 'https://blog.usecure.io/fr' : 'https://blog.usecure.io/topic/product',
            isAllowed: !isEnglish
          }
        ]
      }

      if (accountType === 'msp' || accountType === 'distributor') {
        helpCentreMenuItem.children.push(
          {
            title: I18n.t('helpCentre.technicalResourceMenuItem'),
            externalLink: 'https://help.usecure.io/collections/2055756-msp-technical-resources'
          }, {
            title: I18n.t('helpCentre.marketingResources'),
            externalLink: 'https://help.usecure.io/collections/2390979-msp-marketing-resources',
            isAllowed: !(isSpanish || isFrench || isGerman || isDutch || isNorwegian || isPortuguese || isSwedish)
          }, {
            title: I18n.t('helpCentre.salesResources'),
            externalLink: 'https://help.usecure.io/collections/2390997-msp-sales-resources',
            isAllowed: !(isSpanish || isFrench || isGerman || isDutch || isNorwegian || isPortuguese || isSwedish)
          }, {
            title: I18n.t('helpCentre.marketingSalesResources'),
            externalLink: marketingAndSalesLink,
            isAllowed: isSpanish || isFrench || isGerman || isDutch || isNorwegian || isPortuguese || isSwedish
          }
        )
      } else {
        helpCentreMenuItem.children.push(
          {
            title: I18n.t('helpCentre.gettingStarted'),
            externalLink: 'https://help.usecure.io/collections/1746921-getting-started-with-usecure'
          }, {
            title: I18n.t('helpCentre.settingsCustomisation'),
            externalLink: 'https://help.usecure.io/collections/1873749-account-settings-customisation'
          }, {
            title: I18n.t('helpCentre.syncingWithM365'),
            externalLink: `https://help.usecure.io/collections/1746921-getting-started-with-usecure#${I18n.t('helpCentre.syncingM365HashId')}`
          }, {
            title: I18n.t('helpCentre.allowListingDomains'),
            externalLink: `https://help.usecure.io/collections/1746921-getting-started-with-usecure#${I18n.t('helpCentre.allowListingDomainsHashId')}`
          }
        )
      }
      mainMenu.push(helpCentreMenuItem)
    }

    let uService = {
      title: I18n.t('common.uService'),
      link: routes.USERVICE
    }
    let startRiskReport
    if (isUsecureAdmin) {
      uService = {
        title: I18n.t('common.uService'),
        link: routes.USERVICE
      }
    } else if (showInvoices || isMSPWithProspects) {
      uService = {
        key: 'uservice',
        title: I18n.t('common.uService'),
        children: []
      }

      if (isMSPWithProspects) {
        startRiskReport = {
          title: I18n.t('riskReport.title', { companyName: '' }), // Should give 'Risk Report' in en
          link: generatePath(routes.USERVICE_MSP_TYPE_CREATE, { accountType: 'prospects' })
        }
        uService.children.push({
          title: I18n.t('common.accountTypesPlural.tenant'),
          link: generatePath(routes.USERVICE_MSP_TYPE, { accountType: 'customers' })
        }, {
          title: I18n.t('common.accountTypesPlural.prospect'),
          link: generatePath(routes.USERVICE_MSP_TYPE, { accountType: 'prospects' })
        }, {
          title: I18n.t('riskReportSignUpSetup.title'),
          link: routes.USERVICE_RISK_REPORT_SIGNUP
        })
      } else {
        uService.children.push({
          title: I18n.t(`common.accountTypesPlural.${accountType === 'distributor' ? 'msp' : 'tenant'}`),
          link: routes.USERVICE
        })
      }

      if (showInvoices) {
        uService.children.push({
          title: I18n.t(accountType === 'msp' && parentCompanyId ? 'usageData' : 'invoices', trOpt),
          link: accountType === 'msp' ? routes.USERVICE_INVOICE : routes.USERVICE_INVOICE_DISTRIBUTOR
        })
      }
    }

    if (isUsecureAdmin) {
      mainMenu.push(uService)
    } else if (partner && !platformAccess) {
      const basicMSPMenu = [uService]
      if (startRiskReport) {
        basicMSPMenu.push(startRiskReport)
      }
      mainMenu.unshift(...basicMSPMenu)
    } else if (startRiskReport) {
      mainMenu.unshift(uService, startRiskReport)
    } else {
      mainMenu.unshift(uService)
    }

    // Make settings available to MSP or usecure admin disguised as prospect
    if (accountType === 'prospect' && inDisguise && (originalRole === 'usecure-admin' || originalRole === 'admin')) {
      mainMenu.push({
        title: I18n.t('settings', trOpt),
        link: routes.SETTINGS
      })
    }

    return mainMenu
  }

  isAllowed (item) {
    const { link, isAllowed } = item
    const { session, settings } = this.props

    let allowed = true
    if (link) {
      allowed = isRouteAllowed(session, settings, link)
    }
    if (allowed) {
      if (_isBoolean(isAllowed)) {
        allowed = isAllowed
      } else if (_isFunction(isAllowed)) {
        allowed = isAllowed({ item, session, settings, sessionType: 'admin' })
      }
    }

    return allowed
  }

  renderMainMenu () {
    return this.mainMenu.map((item, index) => {
      const { key, children = [] } = item
      if (children.length > 0) {
        return this.renderSubMenu(item, key || index)
      }

      return this.renderMenuItem(item)
    }).filter(item => !_isNil(item))
  }

  renderAccountMenu () {
    const { session: { name: userName = '', companyName = '', inDisguise = false, role = '', accountType, multipleDisguises = false } = {}, theme } = this.props

    let parentAccountType
    if (accountType === 'tenant' || accountType === 'prospect') {
      parentAccountType = ACCOUNT_TYPES.msp
    } else if (accountType === 'msp') {
      parentAccountType = ACCOUNT_TYPES.distributor
    }

    let sessionLevel
    if (accountType === 'distributor' || accountType === 'msp' ||
      (inDisguise && (accountType === 'tenant' || accountType === 'prospect'))
    ) {
      sessionLevel = I18n.t(accountType, { scope: 'common.accountTypes' })
    } else if (role === 'usecure-admin') {
      sessionLevel = I18n.t('usecureAdminSessionLevel', trOpt)
    }

    return this.renderSubMenu({
      title: (
        <UserSubMenuTitle showDivider={false}>
          <NavBarImageIcon>
            <Icon type='user' />
            {inDisguise && (
              <NavBarDisguiseIcon>
                <i className='fas fa-hard-hat' />
              </NavBarDisguiseIcon>
            )}
          </NavBarImageIcon>
          {
            sessionLevel ? (
              <UserNameAndLevel>
                <span className='usecure-user-name'>{userName}</span>
                <SessionLevelTag color={theme.primary}>{sessionLevel}</SessionLevelTag>
              </UserNameAndLevel>
            ) : <span>{userName}</span>
          }
        </UserSubMenuTitle>
      ),
      float: 'right',
      component: UserSubMenu,
      children: [
        {
          key: 'revert',
          content: (
            <div className='usecure-menu-item'>
              <div>{I18n.t('disguisedAs', { ...trOpt, companyName })}</div>
              {multipleDisguises && parentAccountType && <UnassumeCompanyUser label={I18n.t('goBackToAccountType', { ...trOpt, accountType: parentAccountType })} />}
              <UnassumeCompanyUser label={I18n.t('goBackToMyAccount', trOpt)} me />
            </div>
          ),
          isAllowed: isUnassumeCompanyUserAllowed,
          autoheight: true
        }, {
          key: 'assumeUserRole',
          content: (
            <div className='usecure-menu-item'>
              <AssumeUserRole admin />
            </div>
          ),
          isAllowed: isAssumeUserRoleAllowed,
          autoheight: true
        }, {
          title: I18n.t('accountSettings', trOpt),
          link: routes.ACCOUNT
        }, {
          title: I18n.t('paymentSettings.paymentSettings'),
          link: routes.PAYMENT
        }, {
          key: 'logout',
          content: (
            <LogOut className='usecure-menu-item' />
          )
        }
      ]
    }, 'account')
  }

  renderSettingsMenu () {
    const { session: { accountType }, settings: { platformAccess } } = this.props
    const settingsMenu = [
      this.renderSubMenu({
        title: (
          <>
            <NavBarImageIcon>
              <Icon type='setting' />
            </NavBarImageIcon>
            <SettingsMenuTitle>{I18n.t('settings', trOpt)}</SettingsMenuTitle>
          </>
        ),
        float: 'right',
        children: [
          {
            title: I18n.t('team', trOpt),
            link: routes.TEAM
          }, {
            title: I18n.t(accountType === 'msp' && platformAccess ? 'nfrSettings' : 'settings', trOpt),
            link: routes.SETTINGS
          }, {
            title: I18n.t('settings.settingsForm.defaultCustomerSettingsTitle'),
            link: routes.DEFAULT_CUSTOMER_SETTINGS
          }
        ]
      }, 'settings')
    ]
    if (NAV_LANGUAGE_SWITCHER) {
      settingsMenu.push(
        this.renderMenuItem({
          key: 'language',
          content: (
            <div className='usecure-menu-item'>
              <LanguageDropdownI18nTest includeDebugKeys />
            </div>
          ),
          float: 'right',
          isAllowed: NAV_LANGUAGE_SWITCHER
        })
      )
    }

    return settingsMenu
  }

  renderSubMenu (subMenu, key) {
    const { title, float, children = [], component: SubMenuComponent = SubMenu } = subMenu
    const childItems = children.map(item => this.renderMenuItem({ ...item, sub: true })).filter(item => !_isNil(item))
    if (childItems.length > 0) {
      return (
        <SubMenuComponent
          key={key}
          popupClassName={`usecure-nav-${key}`}
          title={<TopMenuItemTitle>{title}</TopMenuItemTitle>}
          {...{ float }}
        >{childItems}
        </SubMenuComponent>
      )
    }
  }

  renderMenuItem (item) {
    if (!this.isAllowed(item)) {
      return
    }

    const { title, link, key, content, externalLink, onClick, float, sub = false, preview, disabled } = item
    const autoheight = item.autoheight ? 'true' : 'false'
    const previewTag = preview ? <PreviewTag /> : null
    const titleContent = sub
      ? (
        <>
          {title}
          {previewTag}
        </>
      )
      : <TopMenuItemTitle>{title}{previewTag}</TopMenuItemTitle>

    if (_isFunction(onClick)) {
      return (
        <MenuItem autoheight={autoheight} key={link} float={float} disabled={Boolean(disabled)}>
          <div className='usecure-menu-item' onClick={onClick}>{titleContent}</div>
        </MenuItem>
      )
    } else if (link) {
      return (
        <MenuItem autoheight={autoheight} key={link} float={float} disabled={Boolean(disabled)}>
          <Link to={link}>{titleContent}</Link>
        </MenuItem>
      )
    } else if (externalLink) {
      return (
        <MenuItem autoheight={autoheight} key={externalLink} float={float} disabled={Boolean(disabled)}>
          <a href={externalLink} target='_blank' rel='noopener noreferrer'>{titleContent}</a>
        </MenuItem>
      )
    } else if (key && content) {
      return (
        <MenuItem autoheight={autoheight} key={key} float={float} disabled={Boolean(disabled)}>{content}</MenuItem>
      )
    }
  }

  showBillingExportVisible () {
    this.setBillingExportVisible(true)
  }

  setBillingExportVisible (billingExportVisible) {
    this.setState({ billingExportVisible })
  }

  get selectedKeys () {
    const { match: { url, path } } = this.props

    const selectedKeys = [url]
    const mappedRoute = ROUTE_MAP[path]
    if (mappedRoute) {
      selectedKeys.push(mappedRoute)
    }

    return selectedKeys
  }

  getPopupContainer () {
    const { current: popupContainer } = this.popupContainer
    return popupContainer || document.body
  }

  render () {
    return (
      <>
        <PopUpContainer ref={this.popupContainer} />
        <StyledMenu
          mode='horizontal'
          selectedKeys={this.selectedKeys}
          subMenuCloseDelay={0.01}
          subMenuOpenDelay={0.2}
          getPopupContainer={this.getPopupContainer}
        >
          <LogoMenuItem key='logo'>
            <Link to={routes.HOME}>
              <Logo />
            </Link>
          </LogoMenuItem>
          {this.renderMainMenu()}
          {this.renderAccountMenu()}
          {this.renderSettingsMenu()}
        </StyledMenu>
      </>
    )
  }
}

export default compose(
  connect(
    getSessionAndSettings
  ),
  withRouter,
  withTheme
)(NavBar)
