import React, { useMemo, useState, useEffect, useCallback } from 'react'
import I18n from 'i18n-js'
import styled, { withTheme } from 'styled-components'
import { Tabs, Select, Button } from 'antd'
import { useQuery } from 'react-apollo'
import _debounce from 'lodash/debounce'
import _isArray from 'lodash/isArray'
import Highlighter from 'react-highlight-words'

import { connect } from '../../hocs'
import { getSession } from '../../state/selectors/session'

import { ListHeader, ListHeaderPanel, ListHeaderTitle, SearchBar, ErrorAlerts, ActionTable } from '../../components/common'
import { textColumnSorter, numberColumnSorter } from '../../helpers/listPages'
import { UBREACH_PRO_USAGE_REPORT } from '../../components/Queries/Reports'
import { formatDate } from '../../helpers/datetime'
import ExportServiceDownloadButton from '../../components/common/ExportServiceDownloadButton'
import useLocalStorage from '../../hooks/useLocalStorage'

const trOpt = { scope: 'reports.reportCentre.uBreachProReport' }
const { TabPane } = Tabs
const { Option } = Select

const PeriodField = styled.div`
  label {
    margin-right: 10px;
  }

  .ant-select {
    width: 200px;
  }
`

const TableContainer = styled.div`
  .ant-table-row-level-0 td:first-child span,
  .ant-table-row-level-1 td:first-child span {
    display: none;
  }
  .name-container {
    display: inline;
  }
  .ant-table-row-level-1 .name-container {
    display: block;
    margin-left: 25px;
  }
`

const DetailView = withTheme(({
  data = [],
  companyId,
  loading,
  userId,
  accountType,
  searchText = '',
  onSearchChange = () => {},
  type = 'current',
  theme
}) => {
  const [period, setPeriod] = useState(null)
  const [expandAll, setExpandAll] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])
  const [filters, setFilters] = useState({})
  const storageId = useMemo(() => `uBreachProReport|${type}|${companyId}|${userId}`, [userId, companyId, type])

  useEffect(() => {
    let processedData = data

    const uBreachFilter = filters?.uBreachProEnabled === undefined || filters?.uBreachProEnabled === null ? null : filters.uBreachProEnabled[0] || null
    // JG: exemptFromBillingFilter works differently to uBreachFilter, as it should only act on tenants
    const exemptFromBillingFilter = _isArray(filters?.exemptFromBilling) && filters.exemptFromBilling.length > 0 ? filters.exemptFromBilling[0] : null

    if (type === 'history') {
      // Remove companies where they have no history record for the selected period
      processedData = processedData
        .filter(({ company: { id, accountType }, history }) => {
          // Always show MSPs
          if (accountType === 'msp') return true
          // Remove companies where they have no history record for the selected period
          return history && history.find(({ startDate, uBreachProEnabled }) => startDate === period && uBreachProEnabled)
        })
        .map(({ company: { name, id, accountType, parentCompanyId, deleted }, history }) => {
          const { domainCount } = history ? history.find(({ startDate }) => startDate === period) : { domainCount: '' }
          return {
            key: id,
            deleted,
            name,
            accountType,
            parentCompanyId,
            domainCount,
            id
          }
        })
    } else if (type === 'current') {
      processedData = processedData.map(({ company: { name, id, accountType, parentCompanyId, deleted }, uBreachProEnabled, domainCount, domains, moved, exemptFromBilling }) => ({
        key: id,
        deleted,
        name,
        uBreachProEnabled,
        domainCount,
        domains,
        accountType,
        parentCompanyId,
        exemptFromBilling,
        id,
        moved
      }))
      // Remove deleted and moved companies. They should only be shown on the history page
      processedData = processedData.filter(({ deleted, moved }) => !deleted && !moved)
      // Filter by uBreachProEnabled
      if (uBreachFilter !== null) processedData = processedData.filter(({ uBreachProEnabled }) => uBreachProEnabled === uBreachFilter)
      // Filter by exemptFromBilling
      if (exemptFromBillingFilter !== null) processedData = processedData.filter(({ exemptFromBilling, accountType }) => exemptFromBilling === !!exemptFromBillingFilter || accountType !== 'tenant')
    }
    processedData = processedData.sort((a, b) => a.name.localeCompare(b.name))

    // Add children to MSPs
    let mspRecords = processedData
      .filter(({ accountType }) => accountType === 'msp')
      .map(record => {
        const { id, domainCount: nfrDomainCount, domains: nfrDomains } = record
        const children = processedData.filter(({ parentCompanyId }) => parentCompanyId === id)
        return {
          ...record,
          children,
          domainCount: children.reduce((acc, { domainCount }) => acc + domainCount, 0),
          domains: [],
          nfrDomains,
          nfrDomainCount
        }
      })
    const mspIds = mspRecords.map(({ id }) => id)

    // Don't show MSPs that have no children on the history page
    if (type === 'history') mspRecords = mspRecords.filter(({ children }) => children.length > 0)

    // Remove children of MSPs from processedData
    const nonChildRecords = processedData.filter(({ parentCompanyId, accountType }) => !mspIds.includes(parentCompanyId) && accountType !== 'msp')

    processedData = [
      ...mspRecords,
      ...nonChildRecords
    ]

    setAllRecords(processedData)
  }, [data, period, searchText, type, filters])

  const columns = useMemo(() => {
    const columns = [
      {
        key: 'expand',
        width: 20
      },
      {
        title: I18n.t('tableHeadings.name', trOpt),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => textColumnSorter('name', a, b),
        render: (text, record) =>
          <div className='name-container'>
            {searchText && !record.children && text && String(text).toLowerCase().includes(searchText.toLowerCase()) ? (
              <Highlighter
                highlightStyle={{ backgroundColor: theme.primary, padding: '0 2px', borderRadius: 2, color: theme.white }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            ) : (
              text
            )}
          </div>
      }
    ]
    if (type === 'current') {
      columns.push({
        title: I18n.tWithProductNames('tableHeadings.breachProEnabled', trOpt),
        dataIndex: 'uBreachProEnabled',
        key: 'uBreachProEnabled',
        filters: [
          { value: true, text: I18n.t('common.yes') },
          { value: false, text: I18n.t('common.no') }
        ],
        filterMultiple: false,
        render: (text) => text ? I18n.t('common.yes') : I18n.t('common.no'),
        onFilter: (value, record) => record.uBreachProEnabled === value
      })
      columns.push({
        title: I18n.t('tableHeadings.exemptFromBilling', trOpt),
        dataIndex: 'exemptFromBilling',
        key: 'exemptFromBilling',
        filters: [
          { value: true, text: I18n.t('common.yes') },
          { value: false, text: I18n.t('common.no') }
        ],
        filterMultiple: false,
        render: (text, record) => record.accountType === 'tenant' ? text ? I18n.t('common.yes') : I18n.t('common.no') : ''
      })
    }
    columns.push({
      title: I18n.t('tableHeadings.domainCount', trOpt),
      dataIndex: 'domainCount',
      key: 'domainCount',
      sorter: (a, b) => numberColumnSorter('domainCount', a, b)
    })
    if (type === 'current') {
      columns.push({
        title: I18n.t('tableHeadings.domains', trOpt),
        dataIndex: 'domains',
        key: 'domains',
        render: (text) => text && text.join(', ')
      })
    }
    if (type === 'current' && accountType === 'distributor') {
      columns.push(
        {
          title: I18n.t('tableHeadings.nfrDomainCount', trOpt),
          dataIndex: 'nfrDomainCount',
          key: 'nfrDomainCount',
          sorter: (a, b) => numberColumnSorter('nfrDomainCount', a, b)
        },
        {
          title: I18n.t('tableHeadings.nfrDomains', trOpt),
          dataIndex: 'nfrDomains',
          key: 'nfrDomains',
          render: (text) => text && text.join(', ')
        }
      )
    }
    return columns
  }, [type, accountType, searchText, theme])

  const periodOptions = useMemo(() => {
    if (!data.length) return []
    const optionPairs = data.reduce((acc, { history }) => {
      if (!history) return acc

      for (const { startDate, endDate } of history) {
        if (!acc[startDate]) acc[startDate] = { startDate, endDate }
      }

      return acc
    }, {})

    const sortedKeys = Object.keys(optionPairs).sort((a, b) => new Date(a) - new Date(b))
    return sortedKeys.reduce((acc, key) => {
      const { startDate, endDate } = optionPairs[key]
      return [
        ...acc,
        {
          label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
          value: startDate
        }
      ]
    }, [])
  }, [data])

  useEffect(() => {
    if (!periodOptions.length) return
    if (!period) setPeriod(periodOptions.at(-1).value)
  }, [periodOptions, period, setPeriod])
  const onPeriodChange = useCallback(period => setPeriod(period), [setPeriod])

  const applyFilters = useCallback((searchText) => {
    let records = allRecords
    if (searchText) {
      const parentMatches = []
      records = records.filter(record => {
        const { name } = record
        // parent matches search
        const nameMatch = name && String(name).toLowerCase().includes(searchText.toLowerCase())

        // One or more of this children matches search
        const childMatch = record.children ? record.children.some(({ name }) =>
          name && String(name).toLowerCase().includes(searchText.toLowerCase())
        ) : false
        if (childMatch) {
          parentMatches.push(record.id)
        }
        return nameMatch || childMatch > 0
      })
      setExpandedRowKeys(parentMatches)
    }

    setRecords(records)
  }, [allRecords, setExpandedRowKeys, setRecords])
  const debouncedApplyFilters = useCallback(_debounce(applyFilters, 500), [applyFilters])
  useEffect(() => {
    debouncedApplyFilters(searchText)
  }, [debouncedApplyFilters, searchText])

  const toggleExpandAll = useCallback(() => {
    if (expandAll) {
      setExpandedRowKeys([])
      setExpandAll(false)
    } else {
      setExpandedRowKeys(data.map(({ company: { id } }) => id))
      setExpandAll(true)
    }
  }, [expandAll, setExpandedRowKeys, data])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel align='left'>
          {type === 'history' && (
            <PeriodField>
              <label>{I18n.t('period', trOpt)}:</label>
              <Select value={periodOptions.length === 0 ? null : period} onChange={onPeriodChange} disabled={periodOptions.length === 0}>
                {
                  periodOptions.map(({ value, label }, optIndex) => (<Option key={optIndex} value={value}>{label}</Option>))
                }
              </Select>
            </PeriodField>
          )}
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <SearchBar
            placeholder={I18n.t('uService.searchForACompany')}
            value={searchText}
            allowClear
            onChange={onSearchChange}
          />
          {accountType === 'distributor' && <Button type='primary' icon={expandAll ? 'minus-square' : 'plus-square'} onClick={toggleExpandAll}>{expandAll ? I18n.t('collapseAll', trOpt) : I18n.t('expandAll', trOpt)}</Button>}
        </ListHeaderPanel>
      </ListHeader>
      <TableContainer>
        <ActionTable
          columns={columns}
          dataSource={records}
          loading={loading}
          showActions={false}
          storageId={storageId}
          tableProps={{
            expandedRowKeys,
            onExpandedRowsChange: setExpandedRowKeys,
            indentSize: 0
          }}
          onFilterChange={setFilters}
        />
      </TableContainer>
    </>
  )
})

const UBreachProUsageReport = ({ companyId, userId, accountType }) => {
  const { data: { uBreachProUsageReport = [] } = {}, loading, error } = useQuery(UBREACH_PRO_USAGE_REPORT)
  const [searchText, setSearchText] = useState('')
  const [tabKey, setTabKey] = useState('current')

  const storageId = useMemo(() => `uBreach|breachesView|${companyId}|${userId}`, [userId, companyId])
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })

  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchText: setSearchText,
      tabKey: setTabKey
    })
  }, [updateFromLocalStorage, storageId])

  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      searchText,
      tabKey
    })
  }, [updateLocalStorage, searchText, tabKey])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const onTabChange = useCallback(key => {
    setTabKey(key)
  }, [setTabKey])

  const tabs = useMemo(() => {
    return [
      {
        key: 'current',
        title: I18n.t('current', trOpt),
        content: (
          <DetailView
            data={uBreachProUsageReport}
            type='current'
            {...{
              searchText,
              onSearchChange,
              loading,
              userId,
              companyId,
              accountType
            }}
          />
        )
      },
      {
        key: 'history',
        title: I18n.t('history', trOpt),
        content: (
          <DetailView
            data={uBreachProUsageReport}
            type='history'
            {...{
              searchText,
              onSearchChange,
              loading,
              userId,
              companyId,
              accountType
            }}
          />
        )
      }
    ]
  }, [uBreachProUsageReport, searchText, onSearchChange, loading, userId, companyId, accountType])

  return (
    <>
      <ListHeader>
        <ListHeaderPanel align='left'>
          <ListHeaderTitle>{I18n.tWithProductNames('title', trOpt)}</ListHeaderTitle>
        </ListHeaderPanel>
        <ListHeaderPanel align='right'>
          <ExportServiceDownloadButton
            icon='download'
            fileName='ubreach-pro-usage-report.xlsx' fileType='xlsx'
            jobData={{ reportType: 'uBreachProUsageReportExport' }}
          >
            {I18n.t('reports.common.downloadReport')}
          </ExportServiceDownloadButton>
        </ListHeaderPanel>
      </ListHeader>
      <ErrorAlerts error={[error]} defaultError={I18n.t('uBreachUsageReportLoadError', trOpt)} />
      <Tabs activeKey={tabKey} onChange={onTabChange}>
        {tabs.map(tab => (
          <TabPane tab={tab.title} key={tab.key}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}

export default connect(
  state => {
    const { companyId, userId, accountType } = getSession(state) || {}
    return { companyId, userId, accountType }
  }
)(UBreachProUsageReport)
