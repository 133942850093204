/* global FileReader */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Upload, Icon, message, Button } from 'antd'
import styled, { css } from 'styled-components'
import bytes from 'bytes'
import I18n from 'i18n-js'
import mime from 'mime-types'
import _isString from 'lodash/isString'
import _isNumber from 'lodash/isNumber'

import { apiUrl } from '../../apollo-client/common'
import MutationFormErrors from './MutationFormErrors'
import { getImageSize } from '../../helpers'
import { getSessionToken } from '../../helpers/session'

const trOpt = { scope: 'mutationForm.mutationFormImageUpload' }

const UploaderContainer = styled(Upload)`
  .ant-upload {
    min-width: 120px;
    min-height: 120px;
    ${
      ({ width, height }) => {
        if (_isNumber(width) && _isNumber(height)) {
          return css`
    height: ${height}px;
    width: ${width}px;
          `
        }
        return css`
    width:50%;
        `
      }
    }
  }
`

class MutationFormImageUpload extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false,
      loading: false,
      imageUrl: null,
      imageSizeLoaded: false,
      imageWidth: null,
      imageHeight: null
    }

    this.beforeUpload = this.beforeUpload.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.reset = this.reset.bind(this)
    this.validate = this.validate.bind(this)
  }

  // Returns size limit in bytes
  get sizeLimit () {
    const { sizeLimit } = this.props
    return _isString(sizeLimit) ? bytes(sizeLimit) : null
  }

  async getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  async beforeUpload (file) {
    // ToDo: Relax how strict this is && validate on the server
    const isImage = this.props.fileTypes.includes(file.type)
    if (!isImage) {
      message.error(this.props.fileTypeError || I18n.t('fileTypeError', trOpt))
    }

    const { sizeLimit } = this
    let withinLimit = true
    if (_isNumber(sizeLimit) && sizeLimit >= 0) {
      withinLimit = file.size <= sizeLimit
      if (!withinLimit) {
        message.error(I18n.t('mutationForm.common.fileSizeError', { size: bytes(sizeLimit) }))
      }
    }

    // Image size validation before upload
    let sizeValid = true
    if (this.requiresImageSizeCheck) {
      try {
        const dataUrl = await this.getBase64(file)
        const { imageWidth, imageHeight } = await this.getImageSize(dataUrl)
        const errors = this.getImageSizeErrors(imageWidth, imageHeight)
        sizeValid = errors.length === 0
        if (!sizeValid) {
          errors.forEach(error => message.error(error))
        }
      } catch (e) {
        sizeValid = false
        message.error(I18n.t('imageSizeLoadError', trOpt))
      }
    }

    if (!(isImage && withinLimit && sizeValid)) {
      // This will return a rejected promise and stop uploading
      throw new Error('Image upload invalid')
    }
  }

  get requiresImageSizeCheck () {
    return ['requiredWidth', 'requiredHeight', 'minWidth', 'maxWidth', 'minHeight', 'maxHeight'].some(v => _isNumber(this.props[v]))
  }

  validate (value, errors = [], opt, values) {
    // - Check that image dimensions requirements are met
    if (this.requiresImageSizeCheck) {
      errors.push(...this.getImageSizeErrors(this.state.imageWidth, this.state.imageHeight))
    }

    return errors
  }

  getImageSizeErrors (imageWidth, imageHeight) {
    const errors = []
    const { requiredWidth, requiredHeight, minWidth, maxWidth, minHeight, maxHeight } = this.props
    if (!_isNumber(imageWidth) || !_isNumber(imageHeight)) {
      errors.push(I18n.t('imageSizeLoadError', trOpt))
    } else {
      if (_isNumber(requiredWidth) && imageWidth !== requiredWidth) {
        errors.push(I18n.t('requiredWidthError', { ...trOpt, count: requiredWidth }))
      }
      if (_isNumber(minWidth) && imageWidth < minWidth) {
        errors.push(I18n.t('minWidthError', { ...trOpt, count: minWidth }))
      }
      if (_isNumber(maxWidth) && imageWidth > maxWidth) {
        errors.push(I18n.t('maxWidthError', { ...trOpt, count: maxWidth }))
      }
      if (_isNumber(requiredHeight) && imageHeight !== requiredHeight) {
        errors.push(I18n.t('requiredHeightError', { ...trOpt, count: requiredHeight }))
      }
      if (_isNumber(minHeight) && imageHeight < minHeight) {
        errors.push(I18n.t('minHeightError', { ...trOpt, count: minHeight }))
      }
      if (_isNumber(maxHeight) && imageHeight > maxHeight) {
        errors.push(I18n.t('maxHeightError', { ...trOpt, count: maxHeight }))
      }
    }

    return errors
  }

  async getImageSize (imageUrl, { triggerOnChange = false, updateState = false } = {}) {
    try {
      const { width: imageWidth, height: imageHeight } = await getImageSize(imageUrl)
      if (updateState) {
        this.setState({ imageWidth, imageHeight, imageSizeLoaded: true })
      }
      if (triggerOnChange) {
        // Re-runs validation
        this.props.onChange(this.props.id, imageUrl)
      }
      return { imageWidth, imageHeight }
    } catch (e) {
      console.error('MutationFormImageUpload.getImageSize ERROR', e)
      throw e
    }
  }

  async loadImageSize () {
    try {
      return this.getImageSize(this.state.imageUrl, { triggerOnChange: true, updateState: true })
    } catch (e) {
      message.error(I18n.t('imageSizeLoadError', trOpt))
    }
  }

  async handleChange (info) {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }

    if (info.file.status === 'done') {
      // Load image size validation
      let imageSizeLoaded = false
      let imageWidth = null
      let imageHeight = null
      if (this.requiresImageSizeCheck) {
        try {
          const dataUrl = await this.getBase64(info.file.originFileObj);
          ({ imageWidth, imageHeight } = await this.getImageSize(dataUrl))
          imageSizeLoaded = true
        } catch (e) {
          message.error(I18n.t('imageSizeLoadError', trOpt))
        }
      }

      this.setState({
        imageUrl: info.file.response.url,
        imageSizeLoaded,
        imageWidth,
        imageHeight,
        loading: false
      })

      this.props.onChange(this.props.id, info.file.response.url)
    }
  }

  async handleResetClick () {
    await this.props.onChange(this.props.id, this.props.resetValue)
    this.reset()
  }

  async handleRemoveClick () {
    await this.props.onChange(this.props.id, null)
    this.reset()
  }

  async componentDidUpdate (prevProps, prevState) {
    const { value } = this.props
    const { imageUrl, imageSizeLoaded } = this.state
    const { imageUrl: prevImageUrl } = prevState
    // - Set imageURL from value
    if (value && !imageUrl) {
      this.setState({ imageUrl: value })
    }
    // - Pull dimension data to add to state
    if (imageUrl && imageUrl !== prevImageUrl && !imageSizeLoaded && this.requiresImageSizeCheck) {
      await this.loadImageSize()
    }
  }

  reset () {
    this.setState({ imageUrl: null, imageSizeLoaded: false, imageWidth: null, imageHeight: null })
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, label, value, action, formItemStyle, disabled, errors = [], required, width, height, resetValue } = this.props
    const imageUrl = this.state.imageUrl ? this.state.imageUrl : value
    const showErrors = errors.length > 0

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className='ant-upload-text'>{I18n.t('common.upload')}</div>
      </div>
    )

    return (
      <Form.Item
        label={label} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <UploaderContainer
          id={id}
          name='courseBackground'
          listType='picture-card'
          className='course-background'
          showUploadList={false}
          action={action}
          headers={{ 'x-token': getSessionToken() }}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          disabled={disabled}
          width={width}
          height={height}
        >
          {imageUrl ? <img src={imageUrl} alt='avatar' width='100%' /> : uploadButton}
        </UploaderContainer>
        <div>
          {resetValue && <Button style={{ marginRight: 10 }} type='primary' icon='undo' onClick={this.handleResetClick}>{I18n.t('common.reset')}</Button>}
          <Button type='danger' disabled={!imageUrl} icon='delete' onClick={this.handleRemoveClick}>{I18n.t('common.removeImage')}</Button>
        </div>
      </Form.Item>
    )
  }
}

MutationFormImageUpload.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  action: PropTypes.string,
  formItemStyle: PropTypes.object,
  sizeLimit: PropTypes.string,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  fileTypeError: PropTypes.string
}
MutationFormImageUpload.defaultProps = {
  id: '',
  type: 'text',
  label: null,
  onChange: () => {},
  action: `${apiUrl}/upload-course-background`,
  sizeLimit: '2MB',
  fileTypes: [mime.types.jpeg, mime.types.png]
}

export default MutationFormImageUpload
