import React, { useCallback, useMemo } from 'react'
import { message, Modal, Table, Tooltip } from 'antd'
import { generatePath, withRouter } from 'react-router-dom'
import _get from 'lodash/get'
import I18n from 'i18n-js'
import styled from 'styled-components'

import CourseActions from './CourseActions'
import routes from '../../constants/routes'
import { COURSE_DIFFICULTY, COURSE_SUBJECTS } from '../../constants/courses'
import authenticatedClient from '../../apollo-client/authenticatedClient'
import { TRANSLATE_COURSE } from '../Queries/Courses'
import { showErrors } from '../../helpers'
import { LANGUAGES, NO_LANGUAGE_VALUE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { onListTableChange, onListTableSelectAll } from '../../helpers/listPages'
import { DifficultyTag } from './DifficultyTag'
import { LocalesCell } from '../common'
import { COURSE_BUILDER_ONLY_ROLES } from '../../constants/roles'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import TooltipIcon from '../common/TooltipIcon'

const trOpt = { scope: 'courses.courseTable' }

const _LocalesTitle = ({ className }) => (
  <div {...{ className }}>
    <span>{I18n.t('common.languagesParenPlural')}</span>
    <Tooltip title={I18n.t('titleTooltip', trOpt)}>
      <span><TooltipIcon /></span>
    </Tooltip>
  </div>
)
const LocalesTitle = styled(_LocalesTitle)`
  .anticon {
    color: #bfbfbf;
    font-size: 12px;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }
`

const CourseTable = ({
  courses = [], selectedCourseIds = [], updateSelectedCourseIds, updateSingleCourse, updateDuplicateCourseModal, updateDeleteCourseModal,
  history, loading, showOwner = false, role,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination
}) => {
  // Get all uniques
  const difficulties = useMemo(() =>
    [...new Set(courses.map(course => course.difficulty))]
      .sort((a, b) => a - b)
      .map(difficulty => ({
        text: COURSE_DIFFICULTY[difficulty] || difficulty,
        value: difficulty
      })), [courses])

  const subjects = useMemo(() =>
    [...new Set(courses.map(course => course.subject))]
      .map((subject) => {
        return {
          text: COURSE_SUBJECTS[subject] || subject,
          value: subject
        }
      })
      .sort((a, b) => a.text.localeCompare(b.text)),
  [courses])

  const owners = useMemo(() => {
    if (!showOwner || courses.length === 0) {
      return []
    }

    return [
      { value: 'usecure', text: I18n.t('common.managedByUsecure') },
      { value: 'client', text: I18n.t('clientCourses', trOpt) },
      ...courses
        .reduce((owners, { companyId, companyName }) => {
          if (companyId !== 'usecure' && companyId !== null &&
            !owners.some(o => o.value === companyId)) {
            owners.push({ value: companyId, text: companyName })
          }
          return owners
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text))
    ]
  }, [courses, showOwner])

  const performAction = useCallback((action, courseId) => {
    updateSingleCourse(courseId)
    switch (action) {
      case 'editCourse':
        history.push(generatePath(routes.BUILDER_EDIT, { course_id: courseId }))
        break
      case 'editSlides':
        history.push(generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseId }))
        break
      case 'duplicateCourse':
        updateDuplicateCourseModal(true)
        break
      case 'previewCourse':
        window.open(generatePath(routes.BUILDER_PREVIEW_COURSE, { course_id: courseId }), '_blank')
        break
      case 'deleteCourse':
        updateDeleteCourseModal(true)
        break
      case 'translateCourse':
        Modal.confirm({
          title: I18n.t('areYouSureYouWantToTranslate', trOpt),
          content: I18n.t('thisActionWillQueueUpATranslation', trOpt),
          onOk: async () => {
            try {
              await authenticatedClient.mutate({
                mutation: TRANSLATE_COURSE,
                variables: {
                  courseId,
                  languages: LANGUAGES
                }
              })
            } catch (e) {
              showErrors(e, I18n.t('translationError', trOpt))
            }
          }
        })
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [updateDuplicateCourseModal, updateDeleteCourseModal, updateSingleCourse, history])

  const renderActionsCell = useCallback((actions, course) => (
    <CourseActions
      actions={actions} courseId={course.id}
      performAction={performAction}
    />
  ), [performAction])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}

    const columns = [{
      title: I18n.t('common.fields.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortColumnKey === 'name' && order
    }]
    if (showOwner) {
      columns.push({
        title: I18n.t('common.fields.owner'),
        dataIndex: 'companyName',
        key: 'companyName',
        filters: owners,
        filterMultiple: true,
        onFilter: (value, record) => {
          if (value === 'client') {
            return record.companyId !== 'usecure'
          }
          return record.companyId === value
        },
        filteredValue: _get(filters, 'companyName', null),
        sorter: (a, b) => {
          if (a.companyId === 'usecure' && b.companyId !== 'usecure') {
            return -1
          } else if (a.companyId !== 'usecure' && b.companyId === 'usecure') {
            return 1
          } else if (a.companyId === 'usecure' && b.companyId === 'usecure') {
            return 0
          }
          return a.companyName.localeCompare(b.companyName)
        },
        sortOrder: sortColumnKey === 'companyName' && order
      })
    }
    if (role === 'usecure-admin' || role === 'usecure-course-builder') {
      columns.push({
        title: I18n.t('courses.common.difficulty'),
        dataIndex: 'difficulty',
        key: 'difficulty',
        sorter: (a, b) => a.difficulty - b.difficulty,
        sortOrder: sortColumnKey === 'difficulty' && order,
        defaultSortOrder: 'descend',
        filters: difficulties,
        filterMultiple: true,
        onFilter: (value, record) => record.difficulty === value,
        filteredValue: _get(filters, 'difficulty', null),
        render: (text, { difficulty }) => <DifficultyTag difficulty={difficulty} /> || difficulty
      }, {
        title: I18n.t('common.fields.subject'),
        dataIndex: 'subjectText',
        key: 'subject',
        sorter: (a, b) => a.subjectText.localeCompare(b.subjectText),
        sortOrder: sortColumnKey === 'subject' && order,
        filters: subjects,
        filterMultiple: true,
        onFilter: (value, record) => record.subject === value,
        filteredValue: _get(filters, 'subject', null)
      })
    }
    columns.push({
      title: <LocalesTitle />,
      dataIndex: 'locales',
      key: 'locales',
      filters: LANGUAGE_SELECT_OPTIONS.sort((a, b) => a.label.localeCompare(b.label)).map(({ value, label: text }) => ({ value, text })),
      filterMultiple: true,
      onFilter: (value, record) => value === NO_LANGUAGE_VALUE
        ? (!record.locales || record.locales.length === 0)
        : (record.locales && record.locales.includes(value)),
      filteredValue: _get(filters, 'locales', null),
      render: (locales, record) => <LocalesCell localeType='app' sourceLocale={record.locale} {...{ locales }} />
    }, {
      title: '',
      dataIndex: 'actions',
      align: 'right',
      render: renderActionsCell
    })

    return columns
  }, [renderActionsCell, subjects, difficulties, role, showOwner, owners, sorter, filters])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedCourseIds(selectedRowKeys), [updateSelectedCourseIds])
  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds: updateSelectedCourseIds, filters, columns, records: courses })
  }, [courses, updateSelectedCourseIds, filters, columns])

  const onTableChange = useCallback((pagination, newFilters, sorter) => {
    if (newFilters.companyName) {
      // Blank difficulty and subject filters if a client filter has been set
      if (
        newFilters.companyName.includes('client') ||
        newFilters.companyName.some(v => v !== 'usecure' && v !== 'client')
      ) {
        newFilters.difficulty = []
        newFilters.subject = []
      }
    }

    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: newFilters,
      updateFilters,
      onSelectAll
    })
  }, [updateFilters, updateSorter, updatePagination, filters, onSelectAll])

  return (
    <>
      <Table
        dataSource={courses} columns={columns}
        loading={loading}
        onChange={onTableChange}
        rowSelection={
          (COURSE_BUILDER_ONLY_ROLES.includes(role))
            ? null
            : { selectedRowKeys: selectedCourseIds, onChange: onSelectChange, onSelectAll }
        }
        pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
      />
    </>
  )
}

export default withRouter(CourseTable)
