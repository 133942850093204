import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Table } from 'antd'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isFunction from 'lodash/isFunction'

import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { ListTableActions } from './'
import { onListTableChange, onListTableSelectAll } from '../../helpers/listPages'
import useLocalStorage from '../../hooks/useLocalStorage'
import { processTableData } from '../../helpers'
import { getModalPagerProps } from '../../helpers/modal'
import LinkButtonOrText from './LinkButtonOrText'

/**
 * Standard component for rendering an ant.d Table, with pagination, sorting,
 * filtering (all with local storage persistence), optional actions column and
 * opening modals from table cells.
 *
 * @param {Array} columns
 * @param {Array} dataSource
 * @param {Boolean} [loading]
 * @param {Object} [tableProps] Any additional props to pass to the Table component
 * @param {Boolean} [showActions] Whether to show the actions column
 * @param {Array} [buttonActionKeys] The keys of actions to render as buttons in the actions column
 * @param {String} [recordActionKey] The key in the record object that contains the ID to pass to performAction
 * @param {Function} [performAction] The function to call when an action is performed. Required if showActions is true
 * @param {Array} [selectedIds] The IDs of the selected records (if any)
 * @param {Function} [updateSelectedIds] A function to call when the selected records change
 * @param {String} [storageId] The ID of the localstorage item to use for storing pagination, sort, and filter state
 * @param {Function} [onFilterChange] A function to call when the table filter state changes
 * @param {Function} [onPaginationChange] A function to call when the table pagination state changes
 * @param {Function} [onSorterChange] A function to call when the table sorter state changes
 */

const ActionTable = ({
  dataSource = [],
  columns = [],
  loading = false,
  tableProps = {},
  showActions = true,
  performAction = () => {},
  recordActionKey = 'key',
  buttonActionKeys = [],
  selectedIds,
  updateSelectedIds = () => {},
  storageId = null,
  onFilterChange = () => {},
  onPaginationChange = () => {},
  onSorterChange = () => {}
}) => {
  const [pagination, updatePagination] = useState({ ...LIST_PAGINATION_PROPS })
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  useEffect(() => onFilterChange(filters), [filters, onFilterChange])
  useEffect(() => onPaginationChange(pagination), [pagination, onPaginationChange])
  useEffect(() => onSorterChange(sorter), [sorter, onSorterChange])

  // Handle storing and retrieving pagination, sort, and filter state in local storage
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return

    updateFromLocalStorage({
      pagination: updatePagination,
      sorter: updateSorter,
      filters: updateFilters
    })
  }, [updateFromLocalStorage, storageId])

  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      pagination,
      sorter,
      filters
    })
  }, [updateLocalStorage, storageId, pagination, sorter, filters])

  const modalActionArgs = useMemo(() => columns.reduce((acc, column) => {
    // If the column has a triggerModalAction, add it to the list of modalActionArgs
    // This will add an action to trigger opening the appropriate modal
    const { triggerModalAction, triggerModal, useModalPager, modalRecordKey, additionalModalArgs } = column

    if (triggerModalAction) {
      acc.push({
        triggerModalAction,
        triggerModal,
        useModalPager,
        modalRecordKey,
        additionalModalArgs
      })
    }
    return acc
  }, []), [columns])

  const processedDataSource = useMemo(() => {
    let processedDataSource = [...dataSource]

    if (modalActionArgs.length) {
      processedDataSource = processedDataSource.map(record => {
        record.actions = [
          ...modalActionArgs.reduce((acc, { triggerModalAction, triggerModal }) => {
            const triggerModalRef = _isFunction(triggerModal) ? triggerModal(record) : triggerModal
            if (triggerModalRef) {
              acc.push(triggerModalAction)
            }
            return acc
          }, []),
          ...(record.actions || [])
        ]
        return record
      })
    }

    return processedDataSource
  }, [dataSource, modalActionArgs])

  const sortedColumns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    const allColumns = columns.map(column => {
      column.filteredValue = _get(filters || {}, `${column.dataIndex}`, null)
      if (column.sorter) column.sortOrder = sortColumnKey === column.dataIndex && order
      return column
    })
    return allColumns
  }, [columns, filters, sorter])

  // Handle opening modals from table cells, using the modalRef provided by a column definition
  const openModal = useCallback(({ modalRef, useModalPager, activeId, recordKey, record, additionalArgs = {} }) => {
    if (modalRef.current) {
      const modalArgs = { ...additionalArgs, record }

      if (useModalPager) {
        // Create an object with props required by ModalPager, based on the current table state
        const rows = processTableData(sortedColumns, processedDataSource)
        const pager = getModalPagerProps(rows, activeId, recordKey)

        // pager.next and pager.prev will be passed back to this function on click, so we need to
        // include the modalRef, useModalPager, etc props in the object
        pager.next = pager.next ? {
          modalRef,
          useModalPager,
          activeId: pager.next[recordKey],
          recordKey,
          record: pager.next,
          additionalArgs
        } : null

        pager.prev = pager.prev ? {
          modalRef,
          useModalPager,
          activeId: pager.prev[recordKey],
          recordKey,
          record: pager.prev,
          additionalArgs
        } : null

        modalArgs.pager = {
          ...pager,
          onClick: openModal
        }
      }
      modalRef.current.open(modalArgs)
    }
  }, [sortedColumns, processedDataSource])

  const performRowAction = useCallback(async (action, id) => {
    const triggerModalAction = modalActionArgs.find(a => a.triggerModalAction.key === action)

    if (triggerModalAction) {
      const { modalRecordKey = 'id' } = triggerModalAction
      const record = processedDataSource.find(({ [modalRecordKey]: recordId }) => recordId === id)
      const modalRef = _isFunction(triggerModalAction.triggerModal) ? triggerModalAction.triggerModal(record) : triggerModalAction.triggerModal

      openModal({
        modalRef,
        record,
        useModalPager: triggerModalAction.useModalPager,
        recordKey: triggerModalAction.modalRecordKey,
        activeId: record[triggerModalAction.modalRecordKey],
        additionalArgs: triggerModalAction.additionalModalArgs || {}
      })
    } else {
      performAction(action, [id])
    }
  }, [performAction, modalActionArgs, openModal, processedDataSource])

  const renderActionsCell = useCallback((actions, record) => (
    <ListTableActions
      actions={actions}
      id={record[recordActionKey]}
      performAction={performRowAction}
      onEmptyActions='skip'
      buttonActionKeys={buttonActionKeys}
    />
  ), [performRowAction, recordActionKey, buttonActionKeys])

  const allColumns = useMemo(() => {
    const allColumns = sortedColumns.map(originalColumn => {
      const column = { ...originalColumn }

      if (column.triggerModal) {
        // If a column has a triggerModal prop, it will open a modal when clicked.
        // column.triggerModal must be a ref, or a function that returns a ref, to the Modal component
        // column.modalRecordKey is the key in the record object that contains the ID used in creating the pager
        // column.useModalPager is a boolean that determines whether to use ModalPager
        // column.additionalModalArgs is an object containing any additional args to be passed to the modal when it is opened
        // column.triggerModalAction is an object containing the key and label of the action that will trigger the modal. If included
        //   the action will be added to the actions array in the record object
        column.render = (value, record, index) => {
          const triggerModalRef = _isFunction(column.triggerModal) ? column.triggerModal(record) : column.triggerModal
          const text = _isFunction(originalColumn.render) ? originalColumn.render(value, record, index) : value

          const linkComponent = (
            <LinkButtonOrText
              {...{
                text,
                handleClick: triggerModalRef ? openModal : null,
                clickArgs: {
                  modalRef: triggerModalRef,
                  record,
                  useModalPager: column.useModalPager,
                  recordKey: column.modalRecordKey,
                  activeId: record[column.modalRecordKey],
                  additionalArgs: column.additionalModalArgs || {}
                }
              }}
            />
          )

          // The postRender function can be used when there is a need to include something
          // that should not be rendered inside LinkButtonOrText. For example, Tooltips will
          // break if rendered inside LinkButtonOrText
          return _isFunction(column.postRender) ? column.postRender(linkComponent, value, record, index) : linkComponent
        }
      } else if (_isFunction(column.render) && _isFunction(column.postRender)) {
        // If a column has a postRender function, it will be called after the render function
        // and the result of the render function will be passed to it as the first argument
        column.render = (value, record, index) => column.postRender(originalColumn.render(value, record, index), value, record, index)
      }

      return column
    })

    if (showActions) {
      allColumns.push(
        {
          dataIndex: 'actions',
          key: 'actions',
          render: (actions, record) => renderActionsCell(actions, record),
          fixed: 'right'
        }
      )
    }
    return allColumns
  }, [renderActionsCell, showActions, sortedColumns, openModal])

  // This is to prevent the table from scrolling horizontally when there are no actions.
  // But it is required for `fixed: 'right'` in the actions column to work.
  const scroll = useMemo(() => showActions ? { x: true, y: false } : undefined, [showActions])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedIds(selectedRowKeys), [updateSelectedIds])

  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds, filters, allColumns, records: processedDataSource })
  }, [processedDataSource, updateSelectedIds, filters, allColumns])

  const rowSelection = useMemo(() => (
    // Only show the row selection checkbox if selectedIds is an array
    (_isArray(selectedIds) ? {
      selectedRowKeys: selectedIds,
      onChange: onSelectChange
    } : undefined)
  ), [selectedIds, onSelectChange])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updateFilters, updateSorter, filters, updatePagination, onSelectAll])

  return (
    <Table
      {...{
        loading,
        pagination,
        scroll,
        rowSelection,
        ...tableProps
      }}
      dataSource={processedDataSource}
      columns={allColumns}
      onChange={onTableChange}
    />
  )
}

export default ActionTable
