/* global Event, localStorage */
import React, { Component, useEffect, useRef, useState } from 'react'
import { message, Icon } from 'antd'
import styled, { css } from 'styled-components'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _isUndefined from 'lodash/isUndefined'
import _pick from 'lodash/pick'

import { MutationFormSubmitButton } from '../MutationForm'
import MutationForm from '../MutationForm/MutationForm'
import { UPDATE_COMPANY_SETTINGS, UPDATE_COMPANY_SETTING, UPDATE_DEFAULT_TENANT_SETTINGS, UPDATE_DEFAULT_TENANT_SETTING } from '../Queries/Companies'
import { DescriptionContainer, SettingsContainer as _SettingsContainer } from './common'
import { showErrors, renderParagraphsFragmentFromArray, renderListFragmentFromArray } from '../../helpers'
import { getSettingsFromCompany } from '../../helpers/getMe'
import { PreviewTag as _PreviewTag } from '../common'
import { postSessionChannelMessage } from '../../helpers/session'
import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../../constants/languages'
import LanguageSelector from './LanguageSelector'
import ChangedIndicator from '../EditCourse/ChangedIndicator'
import IntercomHeader from '../IntercomHeader'

const trOpt = { scope: 'settings.settingsForm' }

const PreviewTag = styled(_PreviewTag)`
  position: relative;
  top: -3px;
`
const SwitchChildIcon = styled(Icon)`
  margin-top: 4px;
`
const SettingsContainer = styled(_SettingsContainer)`
  padding-bottom: 75px;
  padding-right: 25px;
`
export const SettingsHeader = styled.div`
  padding-right: 25px;
`

export const SettingsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`
const SettingsFormScroller = styled.div`
  height: 100%;
  overflow-x: hidden;
  ${({ useTabs }) => useTabs
    ? css`
  overflow: hidden;

  ${SettingsContainer} {
    height: 100%;
    max-width: unset;
  }

  .settings-form {
    height: 100%;

    &> .ant-form {
      height: 100%;
    } 
  }

  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-content {
    height: calc(100% - 60px);
    overflow: auto;
  }
  .ant-tabs-tabpane {
    max-width: 1100px;
    padding: 12px 12px 0;
  }
  ` : css`
  overflow-y: auto;
  padding: ${({ hasDesc }) => hasDesc ? '15px 0 25px' : '0 0 25px'};
    `
  }
`

const SettingsFormFooterContainer = styled.div`
  background-color: #ffffff;
  padding-bottom: 25px;
  padding-top: 15px;
  text-align: right;
`
const FixedSettingsFormFooterContainer = styled(SettingsFormFooterContainer)`
  bottom: 0px;
  max-width: 1100px;
  padding-right: ${({ useTabs }) => useTabs ? 12 : 25}px;
  position: absolute;
  width: calc(100% - 100px);
`

export const SettingsFormFooter = ({ className, loading, disabled, footerProps }) => {
  const { fixedToBottom = true, useTabs = false } = footerProps || {}
  const Container = fixedToBottom ? FixedSettingsFormFooterContainer : SettingsFormFooterContainer
  return (
    <Container {...{ className, useTabs }}>
      <MutationFormSubmitButton submitLabel={I18n.t('common.save')} submitIcon='save' {...{ disabled, loading }} ghost={false} />
    </Container>
  )
}

export const SettingsFormElement = ({
  title, preview = false, desc, headerExtra, formProps, form, useTabs = false, hasChanged = false, disableSubmit, headerId = '',
  showForm = true, beforeForm = null, afterForm = null
}) => {
  const scroller = useRef(null)
  const container = useRef(null)
  const [fixedFooter, setFixedFooter] = useState(false)

  useEffect(() => {
    const checkContainerHeight = () => {
      let sHeight
      let cHeight
      if (scroller.current) {
        sHeight = scroller.current.offsetHeight
      }
      if (container.current) {
        cHeight = container.current.offsetHeight
      }
      setFixedFooter(_isNumber(cHeight) && _isNumber(sHeight) && cHeight + 50 > sHeight)
    }

    window.addEventListener('resize', checkContainerHeight)
    setTimeout(checkContainerHeight, 500) // Delayed initial call to catch rendering quirks affecting element dimensions i.e. how Safari handles % element heights.
    return () => {
      window.removeEventListener('resize', checkContainerHeight)
    }
  }, [scroller, container])

  return (
    <SettingsFormContainer>
      <SettingsHeader>
        {title && <IntercomHeader Size='h1' id={headerId}><ChangedIndicator changed={hasChanged} tooltip={I18n.t('changedIndicatorTooltip', trOpt)}>*</ChangedIndicator>{title}{preview && <PreviewTag />}</IntercomHeader>}
        {desc && <DescriptionContainer>{desc}</DescriptionContainer>}
        {headerExtra}
      </SettingsHeader>
      <SettingsFormScroller ref={scroller} hasDesc={!_isNil(desc)} useTabs={useTabs}>
        <SettingsContainer ref={container}>
          {beforeForm}
          {
            form || (
              <MutationForm
                className='settings-form'
                style={{ display: showForm ? 'block' : 'none' }}
                {...(formProps || {})}
                footer={formProps && formProps.footer ? formProps.footer : SettingsFormFooter}
                footerProps={{ fixedToBottom: fixedFooter || useTabs, useTabs }}
                {...{ disableSubmit }}
              />
            )
          }
          {afterForm}
        </SettingsContainer>
      </SettingsFormScroller>
    </SettingsFormContainer>
  )
}

const SettingsFormFieldExtraUL = styled.ul`
  padding-left: 22px;
`
export const SettingsFormFieldExtra = ({ copy }) => {
  if (_isString(copy)) {
    return copy
  } else if (_isArray(copy)) {
    return <SettingsFormFieldExtraUL>{renderListFragmentFromArray(copy)}</SettingsFormFieldExtraUL>
  }
  return null
}

class SettingsForm extends Component {
  constructor (props) {
    super(props)

    this.form = React.createRef()
    this.settingIds = []
    this.settingId = undefined
    this.defaultValue = {}
    this.useValuesObjectName = true
    this.resizeTriggerFields = []
    this.preview = false
    this.useTabs = false
    this.includeFormLocale = false
    this.formLocaleStorageId = null
    this.setInitialFormValuesOnMount = false

    this.defaultTenantSettingMutation = UPDATE_DEFAULT_TENANT_SETTING
    this.defaultTenantSettingsMutation = UPDATE_DEFAULT_TENANT_SETTINGS
    this.companySettingMutation = UPDATE_COMPANY_SETTING
    this.companySettingsMutation = UPDATE_COMPANY_SETTINGS

    this.mutateValues = this.mutateValues.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onFailure = this.onFailure.bind(this)
    this.onTabChange = this.onTabChange.bind(this)
    this.resetFields = this.resetFields.bind(this)
  }

  async updateState (update) {
    return new Promise(resolve => this.setState(update, () => resolve()))
  }

  get title () {
    return ''
  }

  get headerId () {
    return ''
  }

  get description () {
    return ''
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get showForm () {
    return true
  }

  // Subclasses with their own componentDidMount will either need to call super.componentDidMount or this method directly to initialise the form language selector
  // An alternative approach to this would be to move the formLocale state upto the Settings view - probably worth waiting until this is converted to a functional component
  initialiseFormLocale () {
    const { userId, companyId, locale: userLocale, companyLocale } = this.props
    this.formLocaleStorageId = `settings|formLocale|${companyId}|${userId}`
    const storedLocale = localStorage.getItem(this.formLocaleStorageId)

    let formLocale = DEFAULT_LANGUAGE
    // Set initial language being edited on form
    if (storedLocale && LANGUAGE_CODES.includes(storedLocale)) {
      formLocale = storedLocale
    } else if (userLocale && LANGUAGE_CODES.includes(userLocale)) {
      formLocale = userLocale
    } else if (companyLocale && LANGUAGE_CODES.includes(companyLocale)) {
      formLocale = companyLocale
    }
    if (this.form.current) {
      this.form.current.setField('formLocale', formLocale)
    }
    this.updateFormLocale(formLocale)
  }

  updateFormLocale (formLocale) {
    this.setState({ formLocale }, () => {
      this.persistFormLocale()
      this.onFormLocaleChange()
    })
  }

  // Empty method to be overridden in views
  onFormLocaleChange () {}

  // The form locale is persisted in local storage so it is available on other settings page and between reloads
  // It is only cleared if the user manually logs out
  persistFormLocale (formLocale = this.state.formLocale) {
    if (this.formLocaleStorageId) {
      localStorage.setItem(this.formLocaleStorageId, formLocale)
    }
  }

  get tabs () {
    return []
  }

  get fields () {
    return this._fields.map(field => {
      if (field.type === 'switch') {
        field.inlineLabel = true
        field.checkedChildren = <SwitchChildIcon type='check' />
        field.unCheckedChildren = <SwitchChildIcon type='close' />
        field.formItemStyle = { marginBottom: 5, ...(field.formItemStyle || {}) }
      }

      return field
    })
  }

  get _fields () {
    return []
  }

  get mutationName () {
    const { defaultTenant = false } = this.props
    let mutationName = defaultTenant ? 'updateDefaultTenantSetting' : 'updateCompanySetting'
    if (!this.settingId) {
      mutationName += 's'
    }
    return mutationName
  }

  updateSettingsFromResult (result) {
    postSessionChannelMessage('sessionRefresh')
    const { updateSettings } = this.props
    if (_isFunction(updateSettings)) {
      const company = _get(result, `data.${this.mutationName}`)
      if (company) {
        const settings = getSettingsFromCompany(company)
        if (settings) {
          updateSettings(settings)
        }
      }
    }
  }

  hasChanged () {
    return this.form.current?.hasChanged() === true
  }

  async resetFields () {
    if (this.form.current) {
      this.setState({ forceRender: Date.now() }) // this is an arbitrary variable to trigger an update
    }
  }

  async triggerSubmit () {
    if (this.form.current) {
      return this.form.current.onSubmit()
    }
  }

  onSubmit () {}

  onSuccess (result) {
    message.success(this.successMsg)
    this.updateSettingsFromResult(result)
  }

  onFailure (e) {
    showErrors(e, this.failureMsg)
  }

  onChange (id, value) {
    if (this.resizeTriggerFields && this.resizeTriggerFields.includes(id)) {
      window.dispatchEvent(new Event('resize'))
    }
    if (id === 'formLocale') {
      this.updateFormLocale(value)
    }
    this.setState({ forceRender: Date.now() }) // this is an arbitrary variable to trigger an update
  }

  onTabChange (id) {
    window.dispatchEvent(new Event('resize'))
  }

  get formValuesFromSettings () {
    if (this.settingId) {
      const setting = _get(this.props, `settings.${this.settingId}`)
      if (this.useValuesObjectName) {
        return setting
      }
      return {
        setting
      }
    }

    const { settings = {} } = this.props
    return _pick(settings, this.settingIds)
  }

  get formValuesFromDefault () {
    return this.settingId ? { setting: this.defaultValue } : this.defaultValue
  }

  get defaultTenant () {
    const { defaultTenant = false } = this.props
    return defaultTenant
  }

  getCurrentAndPreviousValuesFromProps (prevProps) {
    let setting
    let prevSetting
    if (this.settingId) {
      setting = _get(this.props, `settings.${this.settingId}`)
      prevSetting = _get(prevProps, `settings.${this.settingId}`, this.defaultValue)
    } else {
      const { settings = {} } = this.props
      const { settings: prevSettings = {} } = prevProps

      setting = _pick(settings, this.settingIds)
      setting = _isEmpty(setting) ? undefined : setting
      prevSetting = _pick(prevSettings, this.settingIds)
      prevSetting = _isEmpty(prevSetting) ? undefined : prevSetting
    }
    return { setting, prevSetting }
  }

  async setInitialFormValues () {
    if (this.form.current) {
      await this.form.current.setInitialValues(this.formValuesFromSettings)
    }
  }

  async componentDidMount () {
    // It would good to make this default behaviour but there is a lot of complexity in the setting forms and each will need to be retested extensively
    if (this.setInitialFormValuesOnMount) {
      await this.setInitialFormValues()
    }
    if (this.includeFormLocale) {
      this.initialiseFormLocale()
    }
  }

  async componentDidUpdate (prevProps) {
    const form = this.form.current
    if (!form) {
      return
    }

    let forceRender = false
    const { defaultTenant } = this.props
    const { defaultTenant: prevDefaultTenant } = prevProps
    const { setting, prevSetting } = this.getCurrentAndPreviousValuesFromProps(prevProps)
    if (!_isUndefined(setting) && (
      _isUndefined(prevSetting) || // Reset form after saving the previously empty setting
      ((defaultTenant !== prevDefaultTenant || !_isUndefined(prevSetting)) && !_isEqual(setting, prevSetting)) // Reset form after updating the setting
    )) {
      await form.setInitialValues(this.formValuesFromSettings)
      forceRender = true
    } else if (_isUndefined(setting) && (defaultTenant !== prevDefaultTenant)) {
      await form.setInitialValues(this.formValuesFromDefault)
      forceRender = true
    }
    if (forceRender) this.setState({ forceRender: Date.now() }) // this is an arbitrary variable to trigger an update
  }

  mutateValues (values) {
    return values
  }

  get mutationFormProps () {
    const { defaultTenant = false } = this.props
    const props = {
      ref: this.form,
      tabs: this.tabs,
      fields: this.fields,
      onChange: this.onChange,
      onSubmit: this.onSubmit,
      onSuccess: this.onSuccess,
      onFailure: this.onFailure,
      onTabChange: this.onTabChange,
      mutateValues: this.mutateValues,
      footer: SettingsFormFooter,
      hasChangedExcludedFieldIds: ['formLocale'], // todo: if this list grows make it a constant
      // If setInitialFormValuesOnMount is true then the settings values are applied to the form via MutationForm.setInitialValues as opposed the defaultValue of the fields
      // Skipping reset prevents the setting value from disappearing after the mutation has executed
      // componentDidUpdate will handle this when the settings global state changes after the onSuccess method completes
      skipResetFieldsOnSubmit: this.setInitialFormValuesOnMount
    }

    if (this.includeFormLocale) {
      props.fields.unshift({
        id: 'formLocale',
        label: I18n.t('common.language'),
        defaultValue: this.state.formLocale,
        type: 'custom',
        component: LanguageSelector
      })
    }

    if (this.settingId) {
      props.mutation = defaultTenant ? this.defaultTenantSettingMutation : this.companySettingMutation
      props.variables = { settingId: this.settingId }

      if (this.useValuesObjectName) {
        props.valuesObjectName = 'setting'
      }
    } else {
      props.mutation = defaultTenant ? this.defaultTenantSettingsMutation : this.companySettingsMutation
      props.valuesObjectName = 'settings'
    }

    return props
  }

  renderBeforeForm () {
    return null
  }

  renderAfterForm () {
    return null
  }

  render () {
    const { description: formDesc } = this
    const { disableSubmit } = this.props
    let desc = null
    if (_isString(formDesc) && !_isEmpty(formDesc)) {
      desc = <p>{formDesc}</p>
    } else if (_isArray(formDesc) && formDesc.every(d => _isString(d) && !_isEmpty(d))) {
      desc = renderParagraphsFragmentFromArray(formDesc)
    } else if (React.isValidElement(formDesc)) {
      desc = formDesc
    }

    return (
      <>
        <SettingsFormElement
          title={this.title}
          preview={this.preview}
          desc={desc}
          useTabs={this.useTabs}
          formProps={this.mutationFormProps}
          hasChanged={this.hasChanged()}
          headerId={this.headerId}
          {...{ disableSubmit }}
          showForm={this.showForm}
          beforeForm={this.renderBeforeForm()}
          afterForm={this.renderAfterForm()}
        />
      </>
    )
  }
}

export default SettingsForm
