/* global localStorage */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Card, Col, Modal, Row, Tabs } from 'antd'
import styled, { withTheme } from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { compose } from 'recompose'
import { Link, generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { GET_SIMULATION_EMAIL_TEMPLATE } from '../Queries/uPhish'
import { LoadingBlock, ErrorAlerts } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import ViewEmailTemplateDetails from '../uPhish/ViewEmailTemplateDetails'
import ModalPageAccess from '../common/ModalPageAccess'
import routes from '../../constants/routes'

const trOpt = { scope: 'modals.viewEmailTemplateModal' }
const { TabPane } = Tabs

const ViewModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ViewRecordCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    height: 100%;
  }

  .view-pol__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .view-pol__buttons {
    text-align: right;

    .ant-btn {
      margin-left: 5px;
    }
  }

  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 12px 12px 0;
  }
`

const ViewRecordContentContainer = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  padding-right: 10px;
`

const ViewEmailTemplateContent = ({
  template, loading, error, theme, role, builder,
  viewStorageId, userId, companyId, persist = true,
  accountType, planValid,
  refetch = () => {}
}) => {
  const [tabKey, setTabKey] = useState('details')
  const handleTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])
  const isUsecureAdmin = role === 'usecure-admin'
  const details = useRef(null)

  // get/set tabKey from local storage
  const storageId = useMemo(() =>
    (persist && viewStorageId && userId && companyId)
      ? `${viewStorageId}View|${companyId}|${userId}` : null,
  [persist, viewStorageId, userId, companyId])
  useEffect(() => {
    if (!storageId) {
      return
    }

    try {
      const storeString = localStorage.getItem(storageId)
      if (storeString) {
        const store = JSON.parse(storeString)
        Object.keys(store).forEach(key => {
          const value = store[key]
          switch (key) {
            case 'tabKey':
              setTabKey(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    if (storageId) {
      let store = {}
      try {
        const storeString = localStorage.getItem(storageId)
        if (storeString) {
          store = JSON.parse(storeString)
        }
        store = {
          ...store,
          tabKey
        }
        localStorage.setItem(storageId, JSON.stringify(store))
      } catch (e) {}
    }
  }, [
    storageId, tabKey
  ])

  const tabs = []
  tabs.push({
    tab: I18n.t('common.details'),
    key: 'details',
    content: (
      <ViewEmailTemplateDetails
        ref={details}
        {...{ template, companyId, builder }}
      />
    )
  })
  const showAccessTab = builder && (isUsecureAdmin || (['msp', 'distributor'].includes(accountType) && template.companyId === companyId))
  if (showAccessTab) {
    tabs.push({
      tab: I18n.t('common.accessControl.tabTitle'),
      key: 'access',
      content: <ModalPageAccess record={template} {...{ template, theme, role }} />
    })
  }

  let content = null
  if (tabs.length === 1) {
    content = (
      <ViewRecordContentContainer>
        {tabs[0].content}
      </ViewRecordContentContainer>
    )
  } else {
    content = (
      <Tabs activeKey={tabKey} onChange={handleTabChange}>
        {
          tabs.map(({ tab, key, content }) => (
            <TabPane key={key} tab={tab}>
              {content}
            </TabPane>
          ))
        }
      </Tabs>
    )
  }

  const { name } = template

  const handleRefreshClick = useCallback(() => {
    refetch()
    if (details.current) {
      details.current.reloadContent()
    }
  }, [refetch, details])

  return (
    <>
      <Row type='flex'>
        <Col span={18}>
          <h1>{loading ? I18n.t('common.loading') : name}</h1>
        </Col>
        <Col span={6}>
          <div className='view-pol__buttons'>
            {
              builder &&
                <Link to={generatePath(routes.UPHISH_EMAIL_TEMPLATE_UPDATE, { email_template_id: template.id || 'template' })}>
                  <Button icon='edit' ghost type='primary' disabled={loading || !template.id || !planValid}>{I18n.t('common.edit')}</Button>
                </Link>
            }
            <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
          </div>
        </Col>
      </Row>
      {content}
    </>
  )
}

const ViewEmailTemplateModal = ({
  id, title, type,
  visible = false, setVisible = () => {}, afterClose = () => {},
  builder,
  externalAccess = false,
  externalAccessKey,
  role,
  ...contentProps
}) => {
  const closeModal = useCallback(() => setVisible(false), [setVisible])

  const { loading, error, data, refetch } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE, {
    variables: { id },
    skip: !id,
    notifyOnNetworkStatusChange: true
  })
  const template = _get(data, 'simulationEmailTemplate', {})

  return (
    <ViewModal
      title={title}
      width='90%'
      bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
      visible={visible}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
      keyboard={false}
    >
      <ViewRecordCard>
        <div className='view-pol__body'>
          {loading && <LoadingBlock fullScreen={false} loading />}
          {
            error
              ? (
                <>
                  <h1>{I18n.t('title', trOpt)}</h1>
                  <ErrorAlerts {...{ error }} defaultError={I18n.t('errorMessage', trOpt)} />
                </>
              )
              : (
                <ViewEmailTemplateContent
                  loading={loading}
                  error={error}
                  {...{ template, refetch, builder, role }}
                  {...contentProps}
                />
              )
          }
        </div>
      </ViewRecordCard>
    </ViewModal>
  )
}

export default compose(
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'role', 'companyId', 'accountType', 'planValid'])
  ),
  withTheme
)(ViewEmailTemplateModal)
