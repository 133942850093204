import React, { Component, useCallback } from 'react'
import { message } from 'antd'
import { Query } from 'react-apollo'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import { compose } from 'recompose'
import I18n from 'i18n-js'

import { GET_ACCOUNT, GET_ME, UPDATE_ACCOUNT } from '../Queries/Users'
import MutationForm from '../MutationForm/MutationForm'
import VerifyEmailStatus from './VerifyEmailStatus'
import { ErrorAlerts, LoadingBlock } from '../common'
import { connect, withRefreshSessionState } from '../../hocs'
import { session } from '../../state/selectors'
import { COMMS_TYPE_OPTIONS } from '../../constants/users'
import { SettingsContainer } from '../Settings/common'
import { LANGUAGE_SELECT_OPTIONS, DEFAULT_LANGUAGE } from '../../constants/languages'
import { DEFAULT_TIMEZONE } from '../../constants/timezone'
import { postSessionChannelMessage, refreshSessionTokenUsingClient } from '../../helpers/session'
import IntercomHeader from '../IntercomHeader'
const addressFieldStyle = { marginBottom: 5 }
const trOpt = { scope: 'accountSettings.changeDetails' }

class ChangeDetailsForm extends Component {
  constructor (props) {
    super(props)

    this.title = I18n.t('title', trOpt)

    this.form = React.createRef()

    this.state = {
      loading: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  onSubmit () {
    this.setState({ loading: true })
  }

  async handleSuccess (result) {
    this.setState({ loading: false })
    const { data: { updateMe: details } } = result || {}
    message.success(I18n.t('accountUpdated', trOpt))
    const form = this.form.current
    if (form) {
      form.replaceValues(this.getAccountValues(details))
    }

    if (_isFunction(this.props.onSuccess())) {
      await this.props.onSuccess()
    }
  }

  get detailValues () {
    return this.getAccountValues()
  }

  getAccountValues (details = this.props.details) {
    const {
      firstName = '',
      lastName = '',
      profile,
      email = '',
      isVerified = null,
      commsType,
      locale,
      timezone
    } = details || {}

    const {
      addressLine1 = '',
      addressLine2 = '',
      postcode = ''
    } = profile || {}

    return {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      postcode,
      email,
      isVerified,
      commsType,
      locale,
      timezone
    }
  }

  componentDidUpdate (prevProps) {
    const form = this.form.current
    if (!form) {
      return
    }

    const { details, disabled } = this.props
    const { details: prevDetails } = prevProps

    if (!disabled && details && prevDetails && !_isEqual(details, prevDetails)) {
      form.replaceValues(this.detailValues)
    }
  }

  render () {
    const { loading } = this.state
    const disabled = this.props.disabled || loading
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      postcode,
      email,
      isVerified,
      commsType,
      locale,
      timezone
    } = this.detailValues

    const verifiedTag = <VerifyEmailStatus {...{ disabled, email, isVerified }} />

    const changeDetailsFields = [
      { id: 'firstName', label: I18n.t('common.fields.firstName'), placeholder: I18n.t('common.fields.firstName'), disabled, defaultValue: firstName },
      { id: 'lastName', label: I18n.t('common.fields.lastName'), placeholder: I18n.t('common.fields.lastName'), disabled, defaultValue: lastName },
      { id: 'addressLine1', label: I18n.t('address', trOpt), placeholder: I18n.t('firstLineOfAddress', trOpt), disabled, defaultValue: addressLine1, formItemStyle: addressFieldStyle },
      { id: 'addressLine2', placeholder: I18n.t('secondLineOfAddress', trOpt), disabled, defaultValue: addressLine2, formItemStyle: addressFieldStyle },
      { id: 'postcode', placeholder: I18n.t('postcode', trOpt), disabled, defaultValue: postcode },
      { id: 'email', label: I18n.t('common.fields.email'), placeholder: I18n.t('yourEmail', trOpt), type: 'email', required: true, disabled, defaultValue: email, autofill: false, extra: verifiedTag },
      {
        id: 'locale',
        label: I18n.t('common.preferredLanguage'),
        defaultValue: locale || DEFAULT_LANGUAGE,
        type: 'select',
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true
      },
      {
        id: 'timezone',
        label: I18n.t('common.timezone'),
        defaultValue: timezone || DEFAULT_TIMEZONE,
        type: 'timezone'
      }
    ]

    if (this.props.partner || this.props.role === 'usecure-admin') {
      changeDetailsFields.push({ id: 'commsType', label: I18n.t('howWouldYouBestDescribeYourself', trOpt), type: 'select', options: COMMS_TYPE_OPTIONS, required: true, defaultValue: commsType })
    }

    return (
      <>
        {loading && <LoadingBlock fullScreen loading={loading} />}
        {
          this.title && <IntercomHeader Size='h1' id='account-settings-change-details'>{this.title}</IntercomHeader>
        }
        <SettingsContainer>
          <MutationForm
            ref={this.form}
            mutation={UPDATE_ACCOUNT}
            onSuccess={this.handleSuccess}
            submitLabel={I18n.t('submitLabel', trOpt)}
            disableSubmitIfInvalid={false}
            fields={changeDetailsFields}
            refetchQueries={[{
              query: GET_ME
            }]}
          />
        </SettingsContainer>
      </>
    )
  }
}

const ChangeDetails = ({ session, refreshSessionState }) => {
  const onSuccess = useCallback(async () => {
    try {
      await refreshSessionTokenUsingClient()
      await refreshSessionState()
      postSessionChannelMessage('sessionRefresh')
    } catch (e) {
      console.error(e)
    }
  }, [refreshSessionState])

  return (
    <Query query={GET_ACCOUNT}>
      {
        ({ loading, error, data: { me: { user: details = {} } = {} } = {} }) => {
          return (
            <>
              {loading && <LoadingBlock fullScreen loading={loading} />}
              <ErrorAlerts {...{ error }} />
              <ChangeDetailsForm {...{ details, onSuccess }} partner={session.partner} role={session.role} disabled={loading || error} />
            </>
          )
        }
      }
    </Query>
  )
}

export default compose(
  connect(
    state => ({ session: session.get(state) })
  ),
  withRefreshSessionState
)(ChangeDetails)
