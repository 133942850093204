import React, { useCallback } from 'react'
import { Card, Icon, Tooltip, Typography, Modal, Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'

import { FontAwesomeIcon, LocalesIconPopover } from '../common'
import { COURSE_SUBJECTS, COURSE_CATEGORIES } from '../../constants/courses'
import { DifficultyTag } from './DifficultyTag'

const { Paragraph } = Typography

const { Meta } = Card
const trOpt = { scope: 'courses.courseTile' }

const StyleLinkButton = styled(Button)`
  padding: 0;
`

const DifficultyTagWrapper = styled.div`
  margin-bottom: 8px;
`

export const CourseCard = styled.div`
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-card-head {
    border-bottom: none;
    padding: 0 12px;
  }
  .ant-card-head-title {
    padding: 8px 0;
    text-align: right;
  }
  .ant-card-body {
    flex-grow: 1;
  }
  .course-card__icon.fa, .course-card__icon.fab {
    font-size: 15vw;
    min-height: 6vw;
    padding-top: 2vh;
    text-align: center;

    @media(min-width: 768px) {
      font-size: 10vw;
    }
    @media(min-width: 992px) {
      font-size: 7vw;
    }
    @media(min-width: 1200px) {
      font-size: 6vw;
    }
    @media(min-width: 1500px) {
      font-size: 75px;
      min-height: 95px;
    }
  }
  .course-card__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .course-card__desc p {
    font-family: unset;
    margin-bottom: 2px;
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.45);
  }
`

const CourseTileAction = ({ icon, title, action, onClick: onClickProp = () => {}, getPopupContainer, disabled }) => {
  const onClick = useCallback(() => onClickProp(action), [action, onClickProp])

  return (
    <Tooltip {...{ title, getPopupContainer }} placement='bottom'>
      <Icon
        style={disabled ? { cursor: 'not-allowed' } : {}}
        onClick={!disabled && onClick}
        type={icon}
        key={action}
      />
    </Tooltip>
  )
}

const CourseTile = ({
  id, name, icon = 'user-graduate', difficulty, subject, category, company, userCompanyId, description, availableLocales: locales,
  actions: actionIds = ['preview', 'enrol', 'bulk-enrol', 'duplicateCourse'],
  disabledActions = [],
  performAction = () => {},
  getPopupContainer
}) => {
  const { id: companyId } = company || {}
  const isUsecure = [1, 2, 3, 4].includes(difficulty)

  const custom = companyId === userCompanyId// Custom to this company, treat partner courses as default

  const onActionClick = useCallback(action => performAction(action, id), [id, performAction])

  const actions = actionIds.reduce((acc, action) => {
    switch (action) {
      case 'preview':
        acc.push(<CourseTileAction key='preview' title={I18n.t('common.preview')} action='preview' icon='eye' disabled={disabledActions.includes('preview')} onClick={onActionClick} {...{ getPopupContainer }} />)
        break
      case 'enrol':
        acc.push(<CourseTileAction key='enrol' title={I18n.t('common.enrolUsers')} action='enrol' disabled={disabledActions.includes('enrol')} icon='user-add' onClick={onActionClick} {...{ getPopupContainer }} />)
        break
      case 'bulk-enrol':
        acc.push(<CourseTileAction key='bulk-enrol' title={I18n.t('common.bulkEnrolUsers')} action='bulk-enrol' disabled={disabledActions.includes('bulk-enrol')} icon='usergroup-add' onClick={onActionClick} {...{ getPopupContainer }} />)
        break
      case 'duplicateCourse':
        acc.push(<CourseTileAction key='duplicateCourse' title={I18n.t('courses.duplicateCourse')} action='duplicateCourse' disabled={disabledActions.includes('duplicateCourse')} icon='copy' onClick={onActionClick} {...{ getPopupContainer }} />)
        break
      default:
        break
    }
    return acc
  }, [])

  const showCourseDescription = useCallback((name, description) => {
    Modal.info(
      {
        title: name,
        content: description
      }
    )
  }, [])

  return (
    <CourseCard>
      <Card
        title={
          <LocalesIconPopover localeType='app' {...{ locales, getPopupContainer }} />
        }
        cover={<FontAwesomeIcon {...{ icon }} className='course-card__icon' />}
        actions={actions}
      >
        <Meta
          title={
            <Tooltip
              title={name} placement='bottomLeft'
              {...{ getPopupContainer }} align={{ offset: [0, -10] }}
            >
              <div className='course-card__name'>{name}</div>
            </Tooltip>
          }
          description={
            <div className='course-card__desc'>
              <DifficultyTagWrapper>
                <DifficultyTag difficulty={difficulty} gapAnalysisLabel={I18n.t('initialAssessment', trOpt)} customLabel={I18n.t('allLevels', trOpt)} />
              </DifficultyTagWrapper>
              {isUsecure && subject && <p>{COURSE_SUBJECTS[subject] || subject}</p>}
              {category && <p>{COURSE_CATEGORIES[category] || category}</p>}
              {custom && <p>{I18n.t('customCourse', trOpt)}</p>}
              {description && (
                <>
                  <Paragraph ellipsis style={{ margin: 0 }}>
                    {description}
                  </Paragraph>
                  <StyleLinkButton type='link' onClick={() => showCourseDescription(name, description)}>{I18n.t('readMore', trOpt)}</StyleLinkButton>
                </>
              )}
            </div>
          }
        />
      </Card>
    </CourseCard>
  )
}

export default CourseTile
