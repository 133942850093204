import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from 'antd'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'

const Swatch = styled.div`
  background-color: ${({ color }) => color || 'transparent'};
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  width: 36px;
`

const PickerPopover = styled.div`
  position: absolute;
  top: 37px;
  z-index: 2;
  width: 100%;
`
const PickerCover = styled.div`
  bottom: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
`

const PickerContainer = styled.div`
  margin-bottom: 24px;
  position: relative;
`

class MutationFormColourPicker extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showPicker: false,
      color: props.value
    }

    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeComplete = this.handleChangeComplete.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleChange (color) {
    this.setState({
      color: color.hex
    })
  }

  handleChangeComplete (color) {
    this.props.onChange(this.props.id, color.hex)
  }

  handleClick () {
    const update = { showPicker: !this.state.showPicker }
    if (update.showPicker) {
      update.color = this.props.value
    }
    this.setState(update)
  }

  handleCloseClick () {
    this.setState({ showPicker: false })
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { label, value, disabled, useOverlay } = this.props
    const { showPicker } = this.state
    const color = showPicker ? this.state.color : value

    return (
      <PickerContainer style={{ height: showPicker && !useOverlay ? 250 : 'auto' }}>
        <Button onClick={this.handleClick} disabled={disabled}>
          <Swatch color={color} /> {label} <Icon type='down' />
        </Button>
        {
          showPicker && (
            <PickerPopover>
              <PickerCover onClick={this.handleCloseClick} />
              <ChromePicker color={color} onChange={this.handleChange} onChangeComplete={this.handleChangeComplete} disableAlpha />
            </PickerPopover>
          )
        }
      </PickerContainer>
    )
  }
}

MutationFormColourPicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  useOverlay: PropTypes.bool
}

MutationFormColourPicker.defaultProps = {
  id: '',
  label: null,
  onChange: () => { },
  value: null,
  useOverlay: true
}

export default MutationFormColourPicker
