import React from 'react'
import styled from 'styled-components'
import { Tag } from 'antd'
import I18n from 'i18n-js'

import { LANGUAGE_NAME_BY_CODE_TYPES } from '../../constants/languages'

const SourceTag = styled(Tag)`
  margin-bottom: 3px;
  margin-left: 3px;
`
const LocalesCell = ({ locales, sourceLocale, localeType = 'content' }) => {
  if (!(locales && locales.length > 0)) return <div>{I18n.t('common.none')}</div>

  const langNamesByCode = LANGUAGE_NAME_BY_CODE_TYPES[localeType] || LANGUAGE_NAME_BY_CODE_TYPES.all
  const showSourceLocale = sourceLocale && locales.length > 1
  return (
    <>
      {
        locales
          .map(locale => ({ name: langNamesByCode[locale] || locale, source: showSourceLocale && sourceLocale === locale }))
          .sort((a, b) => {
            if (a.source && !b.source) return -1
            if (!a.source && b.source) return 1
            return a.name.localeCompare(b.name)
          })
          .map(({ name, source }, index) => <div key={index}>{name}{source && <SourceTag>{I18n.t('courses.common.sourceLocaleTag')}</SourceTag>}</div>)
      }
    </>
  )
}

export default LocalesCell
