import React, { useCallback, useEffect, useState } from 'react'
import PDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { Button } from 'antd'
import html2canvas from 'html2canvas'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _times from 'lodash/times'

import { displayMoney } from '../../helpers'
import {
  CURRENCIES, INVOICE_COMPANY_NAME, INVOICE_COMPANY_ADDRESS_JSON_STR, INVOICE_SORT_CODE, INVOICE_ACCOUNT_NUMBER,
  INVOICE_DISTI_FINANCE_EMAIL, INVOICE_VAT_NO, INVOICE_MSP_FINANCE_EMAIL
} from '../../constants/billing'
import useGlobalState from '../../hooks/useGlobalState'
import { sessionLocale } from '../../state/selectors/session'
import { addLocaleFontToJsPdfDoc } from '../../helpers/jsPDF'

window.html2canvas = html2canvas

const PERIOD_FORMAT = 'YYYY-MM-DD'
const PRIMARY = '#1e7be4'

const printAddress = ({ doc, address, x, y, baseSize }) => {
  return ['header', 'name', 'line1', 'line2', 'line3', 'line4', 'postcode', 'country'].reduce((height, key) => {
    const value = address[key]
    if (_isString(value) && !_isEmpty(value)) {
      if (key === 'name') {
        doc.setFontSize(14)
        doc.setFont(undefined, 'bold')
      } else {
        doc.setFontSize(baseSize)
        doc.setFont(undefined, 'normal')
      }
      doc.text(value, x, y + height)
      if (key === 'name' || key === 'header') {
        height += 7.5
      } else {
        height += 5
      }
    }

    return height
  }, 0)
}

const trOpt = { scope: 'uService.invoiceDownload' }
const UServiceInvoiceDownload = ({ company, period, distributor = false, companies = [], currency, cost, costIsEstimate, subTotal, tax, totalCost, lastUpdatedAt, isDistiMSP = false, parentCompanyName, totalBreachDomains, totalBreachProCost, totalUsers, totalPlatformCost }) => {
  const [fromAddress, setFromAddress] = useState(null)
  const [toAddress, setToAddress] = useState(null)
  const { locale } = useGlobalState(
    state => ({ locale: sessionLocale(state) })
  )
  useEffect(() => {
    // Check that disti requires address on their invoice
    if (!(distributor && _get(company, 'billingSettings.showAddressOnInvoice') === true)) {
      setFromAddress(null)
      setToAddress(null)
      return
    }
    // Process default invoice from address (i.e. usecure's address) from env var
    let fromAddress
    if (_isString(INVOICE_COMPANY_ADDRESS_JSON_STR)) {
      try {
        const addressObj = JSON.parse(INVOICE_COMPANY_ADDRESS_JSON_STR)
        if (_isObject(addressObj) && !_isArray(addressObj)) {
          addressObj.header = I18n.t('from', trOpt)
          addressObj.name = INVOICE_COMPANY_NAME
          const addressObjKeys = Object.keys(addressObj)
          if (
            addressObjKeys.length > 0 &&
            ['name', 'line1'].every(key =>
              addressObjKeys.includes(key) && _isString(addressObj[key]) && !_isEmpty(addressObj[key])
            )
          ) {
            fromAddress = addressObj
          }
        }
      } catch (e) {
        console.error('Default company invoice address missing or invalid', e)
      }
    }

    // Process company invoice address settings to set to address
    let toAddress = {
      header: I18n.t('to', trOpt),
      name: _get(company, 'billingSettings.invoiceCompanyName') || company.name,
      line1: _get(company, 'billingSettings.addressLine1'),
      line2: _get(company, 'billingSettings.addressLine2'),
      line3: _get(company, 'billingSettings.addressLine3'),
      line4: _get(company, 'billingSettings.addressLine4'),
      postcode: _get(company, 'billingSettings.addressPostcode'),
      country: _get(company, 'billingSettings.addressCountry')
    }
    if (!['name', 'line1'].every(key => _isString(toAddress[key]) && !_isEmpty(toAddress[key]))) {
      toAddress = undefined
    }

    setFromAddress(fromAddress || null)
    setToAddress(toAddress || null)
  }, [distributor, company])

  const onClick = useCallback(async () => {
    const hasCost = _isInteger(cost) && CURRENCIES.includes(currency)
    const hasTax = hasCost && _isInteger(tax)
    const periodMoment = moment(period, PERIOD_FORMAT)
    const doc = new PDF()
    const baseColor = doc.getTextColor()
    const baseSize = 12
    let baseFont = 'helvetica'

    // Add and set font for locale - applied when locale's character set is not natively supported by jsPDF
    const localeFontName = await addLocaleFontToJsPdfDoc(doc, locale, { loadBold: true })
    if (localeFontName) {
      baseFont = localeFontName
    }

    doc.setFont(baseFont)
    doc.setFontSize(baseSize)
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    const margin = 14
    const left = margin
    const right = pageWidth - margin
    let y = 15
    doc.setFontSize(20)
    if (isDistiMSP) {
      doc.text(parentCompanyName, left, y)
      doc.text(I18n.t('titleDistiMSP', trOpt), right, y, { align: 'right' })
    } else {
      doc.setTextColor(PRIMARY)
      const usecure = 'usecure'
      const usecureWidth = doc.getTextWidth(usecure)
      doc.text('usecure', left, y)
      doc.setTextColor(baseColor)
      doc.text(' limited', left + usecureWidth, y)
      doc.text(I18n.t('title', trOpt), right, y, { align: 'right' })
    }
    doc.setFontSize(baseSize)
    y += 15
    const rightMargin = 5

    const invoiceNumberString = I18n.t('invoiceNumber', trOpt)
    const sortCodeString = I18n.t('sortCode', trOpt)
    const accountNumberString = I18n.t('accountNumber', trOpt)
    const financeEmailString = I18n.t('email', trOpt)
    const dueDateString = I18n.t('dueDate', trOpt)
    const vatNumberString = I18n.t('vatNumber', trOpt)

    const invoiceNumber = `${_get(company, 'name')}-${moment(period, PERIOD_FORMAT).format('MM')}-${moment(period, PERIOD_FORMAT).add(1, 'months').format('MM-YY')}`
    const dueDate = moment(period, PERIOD_FORMAT).add(1, 'months').add(60, 'days').format('Do MMMM YYYY')
    const nowValue = moment().format('Do MMMM YYYY')

    let rightHeaderLabelX
    if (distributor) {
      // Get the longest string from the list of strings we have + 5 for formatting
      // This just formats it nicer IMO
      rightHeaderLabelX = Math.max(...[
        nowValue, invoiceNumber, INVOICE_SORT_CODE, INVOICE_ACCOUNT_NUMBER, INVOICE_DISTI_FINANCE_EMAIL,
        dueDate, INVOICE_VAT_NO
      ].map(el => doc.getTextWidth(el))) + 5
    } else {
      rightHeaderLabelX = doc.getTextWidth(nowValue)
    }
    rightHeaderLabelX = right - rightHeaderLabelX - rightMargin

    doc.text(nowValue, right, y, { align: 'right' })
    doc.text(I18n.t('dateGenerated', trOpt), rightHeaderLabelX, y, { align: 'right' })

    if (distributor) {
      y += 5
      doc.text(invoiceNumber, right, y, { align: 'right' })
      doc.text(invoiceNumberString, rightHeaderLabelX, y, { align: 'right' })

      y += 5
      doc.text(INVOICE_SORT_CODE, right, y, { align: 'right' })
      doc.text(sortCodeString, rightHeaderLabelX, y, { align: 'right' })

      y += 5
      doc.text(INVOICE_ACCOUNT_NUMBER, right, y, { align: 'right' })
      doc.text(accountNumberString, rightHeaderLabelX, y, { align: 'right' })

      y += 5
      doc.text(INVOICE_DISTI_FINANCE_EMAIL, right, y, { align: 'right' })
      doc.text(financeEmailString, rightHeaderLabelX, y, { align: 'right' })

      y += 5
      doc.text(dueDate, right, y, { align: 'right' })
      doc.text(dueDateString, rightHeaderLabelX, y, { align: 'right' })

      if (hasTax) {
        y += 5
        doc.text(INVOICE_VAT_NO, right, y, { align: 'right' })
        doc.text(vatNumberString, rightHeaderLabelX, y, { align: 'right' })
      }
    } else if (!isDistiMSP) {
      doc.text(INVOICE_MSP_FINANCE_EMAIL, left, y)
      if (hasTax) {
        y += 5
        doc.text(vatNumberString, left, y)
        doc.text(INVOICE_VAT_NO, left + doc.getTextWidth(vatNumberString) + 5, y)
      }
      y += 15
    }

    const name = _get(company, 'name')
    if (isDistiMSP) {
      if (name) {
        doc.setFontSize(16)
        doc.text(name, left, y)
        doc.setFontSize(baseSize)
      }
    } else {
      const email = _get(company, 'billingSettings.billingEmail')
      if (name || email) {
        if (distributor) y -= 10 // align with 3rd line from the bottom of the right header
        doc.text(I18n.t('billTo', trOpt), left, y)
        if (name) {
          y += 5
          doc.text(name, left, y)
        }
        if (email) {
          y += 5
          doc.text(email, left, y)
        }
      }
    }
    y += 15
    doc.setFontSize(18)
    doc.text(`${moment(period, PERIOD_FORMAT).format('Do MMMM YYYY')} - ${moment(period, PERIOD_FORMAT).add(1, 'months').format('Do MMMM YYYY')}`, left, y)

    if (fromAddress && toAddress) {
      y += 10
      const fromAddressHeight = printAddress({ doc, address: fromAddress, x: left, y, baseSize })
      const toAddressHeight = printAddress({ doc, address: toAddress, x: (pageWidth / 2), y, baseSize })
      // Reset font
      doc.setFontSize(baseSize)
      doc.setFont(undefined, 'normal')
      y += (fromAddressHeight > toAddressHeight ? fromAddressHeight : toAddressHeight) + 5
    } else {
      doc.setFontSize(baseSize)
      y += 10
    }

    // Summary table
    y += 5
    doc.setFontSize(14)
    doc.text(I18n.t('uServiceInvoice.summary'), left, y)
    doc.setFontSize(baseSize)
    y += 5
    const summaryTableHead = [I18n.t('tableHeadings.item', trOpt), I18n.t('tableHeadings.units', trOpt)]
    if (hasCost) {
      summaryTableHead.push({
        content: I18n.t(costIsEstimate ? 'tableHeadings.estimatedUnitPrice' : 'tableHeadings.unitPrice', trOpt), styles: { halign: 'right' }
      }, {
        content: I18n.t('tableHeadings.amount', trOpt), styles: { halign: 'right' }
      })
    }
    const summaryTableBody = [
      [
        I18n.t('uServiceInvoice.platformUsage'),
        totalUsers,
        ...(hasCost ? [displayMoney(cost, currency), displayMoney(totalPlatformCost, currency)] : [])
      ]
    ]
    if (_isInteger(totalBreachDomains)) {
      summaryTableBody.push([
        I18n.t('common.uBreachPro'),
        totalBreachDomains,
        ...(hasCost ? ['', displayMoney(totalBreachProCost, currency)] : [])
      ])
    }
    doc.autoTable({
      startY: y,
      styles: { fontSize: 10 },
      headStyles: { fillColor: PRIMARY },
      columnStyles: {
        2: { halign: 'right' },
        3: { halign: 'right' },
        4: { halign: 'right' }
      },
      head: [summaryTableHead],
      body: summaryTableBody
    })
    y += isDistiMSP ? doc.autoTable.previous.finalY - 55 : doc.autoTable.previous.finalY - 85

    // Platform usage table
    y += 5
    doc.setFontSize(14)
    doc.text(I18n.t('uServiceInvoice.platformUsage'), left, y)
    doc.setFontSize(baseSize)
    y += 5
    const head = [I18n.t('tableHeadings.client', trOpt), I18n.t('common.users')]
    if (hasCost) {
      head.push({
        content: I18n.t(costIsEstimate ? 'tableHeadings.estimatedUnitPrice' : 'tableHeadings.unitPrice', trOpt), styles: { halign: 'right' }
      }, {
        content: I18n.t(hasTax ? 'tableHeadings.amountExclTax' : 'tableHeadings.amount', trOpt), styles: { halign: 'right' }
      })
    }
    if (hasTax) {
      head.push({
        content: I18n.t('tableHeadings.amountInclTax', trOpt), styles: { halign: 'right' }
      })
    }
    doc.autoTable({
      startY: y,
      styles: { font: baseFont, fontSize: 10 },
      headStyles: { fillColor: PRIMARY },
      columnStyles: {
        2: { halign: 'right' },
        3: { halign: 'right' },
        4: { halign: 'right' }
      },
      head: [head],
      body: companies.map(({ name, maxUsers, cost: amount, costInclTax: amountInclTax }) => {
        const row = [name, maxUsers]
        if (hasCost) {
          row.push(displayMoney(cost, currency), displayMoney(amount, currency))
        }
        if (hasTax) {
          row.push(displayMoney(amountInclTax, currency))
        }
        return row
      })
    })
    y = doc.previousAutoTable.finalY + 15

    // Total cost table
    // TODO Position this table to the right of the document
    if (hasCost) {
      const costTableRows = [
        [I18n.t('uServiceInvoice.subtotal'), displayMoney(hasTax ? subTotal : totalCost, currency)],
        [
          { content: I18n.t('uServiceInvoice.total'), styles: { fontStyle: 'bold' } },
          { content: displayMoney(totalCost, currency), styles: { fontStyle: 'bold' } }
        ]
      ]
      if (hasTax) {
        costTableRows.splice(1, 0, [I18n.t('uService.tax'), displayMoney(tax, currency)])
      }
      doc.autoTable({
        startY: y,
        tableWidth: (pageWidth - 28) / 3,
        styles: { font: baseFont, fontSize: 14 },
        columnStyles: {
          1: { halign: 'right' }
        },
        body: costTableRows
      })
      y = doc.previousAutoTable.finalY + 10

      if (costIsEstimate) {
        doc.setFontSize(8)
        doc.text(I18n.t(hasTax ? 'costPerUserDisclaimerInclTax' : 'costPerUserDisclaimerExclTax', trOpt), left, y)
        y += 10
      }
    }

    // Last Updated At on footer
    const pageCount = doc.internal.getNumberOfPages()
    let footerText = I18n.t('generated', { ...trOpt, datetime: moment().format('DD/MM/YY HH:mm') })
    if (lastUpdatedAt) {
      footerText = `${I18n.t('lastUpdated', { ...trOpt, datetime: lastUpdatedAt.format('DD/MM/YY HH:mm') })}; ${footerText}`
    }
    _times(pageCount, n => {
      doc.setPage(n + 1)
      doc.setFontSize(8)
      doc.setTextColor('#9ca2ad')
      doc.text(footerText, pageWidth / 2, pageHeight - 5, { align: 'center' })
    })

    doc.save(`invoice-${periodMoment.format('MMM-YYYY').toLowerCase()}.pdf`)
  }, [company, period, companies, cost, currency, subTotal, tax, totalCost, costIsEstimate, lastUpdatedAt, distributor, fromAddress, toAddress, isDistiMSP, parentCompanyName, locale, totalBreachDomains, totalBreachProCost, totalPlatformCost, totalUsers])

  return (
    <Button icon='download' onClick={onClick} disabled={!period}>{I18n.t('common.download')}</Button>
  )
}

export default UServiceInvoiceDownload
