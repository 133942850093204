import React from 'react'
import ReactDOM from 'react-dom'

import App from './views/App'
import { StateProvider } from './state'
import rootReducer, { initialState } from './state/reducers'
import './helpers/sentry'

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={rootReducer}>
    <App />
  </StateProvider>,
  document.getElementById('root')
)
