import React, { useState, useCallback, useEffect, useMemo } from 'react'
import I18n from 'i18n-js'
import { Button } from 'antd'

import routes from '../../constants/routes'
import { ListHeader, ListHeaderPanel } from '../common'
import CompaniesView from './CompaniesView'
import { ReportDownloadDropdown } from '../Reports'
import useLocalStorage from '../../hooks/useLocalStorage'

const trOpt = { scope: 'uService' }

const ReportDownload = ({ role, userAccountType, enableProspects, enableRiskReportTenants }) => {
  const reports = []
  if (role === 'usecure-admin' || userAccountType === 'distributor') {
    reports.push({ label: I18n.t('downloadAccountsOverview', trOpt), fileName: 'accounts-overview.xlsx', jobData: { reportType: 'accountsOverview' }, fileType: 'xlsx' })
    if (enableProspects || enableRiskReportTenants) {
      reports.push({ label: I18n.t('downloadProspectsReport', trOpt), file: 'prospects-report.xlsx', endpoint: '/uService/prospects-report.xlsx' })
    }
  }
  if (role === 'usecure-admin') {
    reports.push({ label: I18n.t('downloadSalesReport', trOpt), file: 'sales-report.xlsx', endpoint: '/uService/sales-report.xlsx' })
  }
  return (
    <ReportDownloadDropdown {...{ reports }} />
  )
}

const UServiceAllCompanies = ({
  title,
  loading,
  error,
  companies,
  companyId,
  userId,
  accountType,
  userAccountType,
  showExternalId,
  role,
  path,
  theme,
  enableProspects,
  enableRiskReportTenants,
  disableTenantDeletion,
  disableProspectDeletion,
  refetchQueries,
  upgradeProspectRefetchQueries,
  setPollingEnabled,
  disableTenantCreation,
  disableProspectCreation,
  enableBillingExport,
  refetch
}) => {
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const panelLeft = enableBillingExport ? ['billing-export'] : []
  panelLeft.push(<ReportDownload {...{ role, userAccountType, enableProspects, enableRiskReportTenants }} />)

  const panelRight = ['search']

  // Add create menu/button if allowed
  if (role === 'usecure-admin' && path === routes.USERVICE) {
    panelRight.push('addMenu')
  } else if (userAccountType === 'msp' && enableProspects && !disableTenantCreation && !disableProspectCreation) {
    // This is only relevant to the combined customer and prospect view for MSPs
    // As this view isn't currently in use, it does not support creation of only one account type
    panelRight.push('add-prospect')
  } else if (
    role === 'usecure-admin' ||
    userAccountType === 'distributor' || (
      userAccountType === 'msp' && (
        ((!accountType || accountType === 'tenant') && !disableTenantCreation) || // MSP are assumed to be viewing customers by default
        (accountType === 'prospect' && !disableProspectCreation)
      )
    )
  ) {
    panelRight.push('add')
  }

  const storageId = useMemo(() => `uService|${companyId}|${userId}${accountType ? `|${accountType}` : ''}`, [userId, companyId, accountType])
  const { updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchFilterText: updateSearchFilterText,
      filters: updateFilters,
      sorter: updateSorter,
      pagination: updatePagination
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      searchFilterText, filters, sorter, pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const handleClearAllFiltersClick = useCallback(() => {
    updateFilters(null)
    updateSearchFilterText(null)
  }, [])

  return (
    <>
      <ListHeader align={title ? null : 'right'}>
        {title}
        <ListHeaderPanel align='right'>
          <Button icon={loading ? 'loading' : 'filter'} ghost type='danger' disabled={loading} onClick={handleClearAllFiltersClick}>{I18n.t('common.clearAllFilters')}</Button>
          <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={refetch}>{I18n.t('common.refresh')}</Button>
        </ListHeaderPanel>
      </ListHeader>
      <CompaniesView
        {...{
          loading,
          error,
          companies,
          companyId,
          userId,
          searchFilterText,
          updateSearchFilterText,
          sorter,
          updateSorter,
          filters,
          updateFilters,
          pagination,
          updatePagination,
          accountType,
          userAccountType,
          panelLeft,
          panelRight,
          showExternalId,
          role,
          path,
          theme,
          enableProspects,
          enableRiskReportTenants,
          disableTenantDeletion,
          disableProspectDeletion,
          refetchQueries,
          upgradeProspectRefetchQueries,
          setPollingEnabled
        }}
        openAddModal={path === routes.USERVICE_MSP_TYPE_CREATE}
      />
    </>
  )
}

export default UServiceAllCompanies
