import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _pick from 'lodash/pick'

import { ASSUME_COMPANY_USER } from '../Queries/Users'
import { connect } from '../../hocs'
import { showErrors } from '../../helpers'
import selectors from '../../state/selectors'
import { postSessionChannelMessage, setSessionToken } from '../../helpers/session'

const trOpt = { scope: 'nav.assumeUserRole' }

const AssumeButton = styled(Button)`
  display: block;
  margin: 10px 0;
`

export const isAssumeUserRoleAllowed = ({ session }) =>
  session.role === 'usecure-admin' && !session.inDisguise

const AssumeUserRole = ({ companyId, admin = false }) => {
  const [assumeRole, { loading }] = useMutation(ASSUME_COMPANY_USER)

  const onClick = useCallback(async () => {
    try {
      const result = await assumeRole({ variables: { companyId, admin } })
      const token = _get(result, 'data.assumeCompanyUser.token')
      if (!token) {
        throw new Error(I18n.t('uService.noTokenReturned'))
      }
      // Set token
      setSessionToken(token)
      // Tell other tabs/windows to reload
      postSessionChannelMessage('sessionReloadPage')
      // Refresh the page
      window.location.reload()
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', trOpt))
    }
  }, [admin, assumeRole, companyId])

  return (
    <AssumeButton
      type='primary' icon='login'
      loading={loading} onClick={onClick}
    >{I18n.t(admin ? 'switchToAdmin' : 'switchToUser', trOpt)}
    </AssumeButton>
  )
}

export default connect(state => _pick(selectors.session.get(state), ['companyId']))(AssumeUserRole)
