import React from 'react'
import _get from 'lodash/get'
import _meanBy from 'lodash/meanBy'
import _isNil from 'lodash/isNil'
import I18n from 'i18n-js'
import moment from 'moment'

const gapAnalysisTrOpt = { scope: 'endUserPortal.dashboard.gapAnalysis' }
const riskScoreTrOpt = { scope: 'endUserPortal.dashboard.phishingSimulationsMessage' }

export const separateCoursesAndGapAnalysis = (rawCourseResults) => {
  const courseResults = rawCourseResults.filter((course) => _get(course, 'course.difficulty') !== 1)
  const gapAnalysisCourses = rawCourseResults.filter((course) => _get(course, 'course.difficulty') === 1)
  return { courseResults, gapAnalysisCourses }
}

/**
 * The courseResults array provided by the API treats the Gap Analysis questionaire as 12 separate courses. This returns the array with the Gap Analysis results combined into a single course.
 * @param {Array} rawCourseResults
 * @returns {Array} processedCourseResults
 */
export const processCourseResults = (rawCourseResults) => {
  const { courseResults, gapAnalysisCourses } = separateCoursesAndGapAnalysis(rawCourseResults)
  if (gapAnalysisCourses.length > 0) {
    const gapAnalysisStarted = gapAnalysisCourses.some(({ startDate }) => !_isNil(startDate))
    const gapAnalysisFinished = gapAnalysisCourses.every(({ finishDate }) => !_isNil(finishDate))
    const gapAnalysis = {
      course: {
        id: _get(gapAnalysisCourses, '0.course.id'),
        name: I18n.t('name', gapAnalysisTrOpt),
        description: I18n.t('description', gapAnalysisTrOpt),
        difficulty: 1,
        subject: 'InfoSec'
      },
      id: _get(gapAnalysisCourses, '0.id'),
      icon: _get(gapAnalysisCourses, '0.icon'),
      locale: _get(gapAnalysisCourses, '0.locale'),
      enrollDate: moment.min(gapAnalysisCourses.map(({ enrollDate }) => moment(enrollDate, 'YYYY-MM-DD'))).format('YYYY-MM-DD'),
      finished: gapAnalysisFinished,
      startDate: (
        gapAnalysisStarted
          ? (
            moment.min(
              gapAnalysisCourses
                .filter(({ startDate }) => !_isNil(startDate))
                .map(({ startDate }) => moment(startDate, 'YYYY-MM-DD'))
            ).format('YYYY-MM-DD')
          ) : null
      ),
      finishDate: (
        gapAnalysisFinished
          ? (
            moment.max(gapAnalysisCourses.map(({ finishDate }) => moment(finishDate, 'YYYY-MM-DD'))).format('YYYY-MM-DD')
          ) : null
      ),
      score: Math.floor(_meanBy(gapAnalysisCourses, 'score'))
    }
    courseResults.unshift(gapAnalysis)
  }
  return courseResults.map(result => {
    const isValid = [1, 2, 3, 4, 99].includes(result.course.difficulty)
    return { ...result, course: { ...result.course, difficulty: isValid ? result.course.difficulty : 99 } }
  })
}

export const extractOutstandingCourses = courseResults => processCourseResults(courseResults.filter(courseResult => courseResult.finished === false))

export const buildInfoPopUpMessage = () => (
  <>
    {I18n.t('initial', riskScoreTrOpt)}
    <ul>
      <li>{I18n.t('opened', riskScoreTrOpt)}</li>
      <li>{I18n.t('visited', riskScoreTrOpt)}</li>
      <li>{I18n.t('compromised', riskScoreTrOpt)}</li>
      <li>{I18n.t('reported', riskScoreTrOpt)}</li>
    </ul>
  </>
)

export const riskOverTimeDurations = [
  {
    id: '1month',
    fromVal: 1,
    fromUnit: 'months'
  },
  {
    id: '3month',
    fromVal: 3,
    fromUnit: 'months'
  },
  {
    id: '6month',
    fromVal: 6,
    fromUnit: 'months'
  }
]

export const getPhishRiskScoreRatio = (phishRiskScore, maxPhishScore, maxRiskScore) => {
  if (phishRiskScore === 0) return 0
  return (phishRiskScore / maxPhishScore) * maxRiskScore
}
const getStatusIndexHierarchy = (statuses) => statuses.reduce((acc, status, index) => ({ ...acc, [status]: index }), {})

const formatStatusForTableFilters = (statuses, trOpt) => statuses.map(value => ({
  get text () { return I18n.t(value, trOpt) },
  value
}))
export const getStatusHierarchyAndTableFilters = (statuses, trOpt) => {
  if (statuses) {
    const hierarchy = getStatusIndexHierarchy(statuses)
    const filters = formatStatusForTableFilters(statuses, trOpt)
    return { statusHierarchy: hierarchy, statusFilters: filters }
  }
  return { statusHierarchy: [], statusFilters: [] }
}
