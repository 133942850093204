import moment from 'moment'

import CourseReminder from '../uLearn/CourseReminder'
import { SEND_POLICY_REMINDERS_NOW } from '../../Queries/uPolicy'

const DEFAULT_THRESHOLD = moment.duration(7, 'days').asMilliseconds()

class PolicyReminder extends CourseReminder {
  constructor (props) {
    super(props)

    this.enabledSettingId = 'policyReminder'
    this.thresholdSettingId = 'policyReminderThresholdMs'
    this.sendReminderNowMutation = SEND_POLICY_REMINDERS_NOW
    this.sendReminderNowMutationResultId = 'sendPolicyReminders'

    this.trOpt = { scope: 'settings.policyReminder' }
  }

  get headerId () {
    return 'settings-policy-reminder-header'
  }

  getDefaultValue () {
    return {
      [this.enabledSettingId]: false,
      [this.thresholdSettingId]: DEFAULT_THRESHOLD
    }
  }
}

export default PolicyReminder
