import I18n from 'i18n-js'

import SettingsForm from './SettingsForm'
import { withConsumer } from '../../hocs'
import { renderListFromArray } from '../../helpers'

const trOpt = { scope: 'settings.riskReport' }

class RiskReport extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'riskReportUPhishCompletionType'
    ]
    this.defaultValue = {
      riskReportUPhishCompletionType: 'manual'
    }
  }

  get headerId () {
    return 'settings-risk-report-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get _fields () {
    const { settings = {} } = this.props
    const {
      riskReportUPhishCompletionType
    } = {
      ...this.defaultValue,
      ...settings
    }

    return [
      {
        id: 'riskReportUPhishCompletionType',
        type: 'select',
        label: I18n.t('uPhishCompletionType.label', trOpt),
        formItemStyle: { maxWidth: 500 },
        options: [{
          value: 'auto', label: I18n.t('uPhishCompletionType.auto', trOpt)
        }, {
          value: 'threshold', label: I18n.t('uPhishCompletionType.threshold', trOpt)
        }, {
          value: 'manual', label: I18n.t('uPhishCompletionType.manual', trOpt)
        }],
        defaultValue: riskReportUPhishCompletionType,
        extra: renderListFromArray(I18n.t('uPhishCompletionType.extra', trOpt))
      }
    ]
  }
}

export default withConsumer(RiskReport)
