import React, { useCallback, useState, useMemo } from 'react'
import { Modal as ModalComp, Divider, Table, Tag, Button, Icon, message, Col, Row } from 'antd'
import CSVReader from 'react-csv-reader'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { UPLOAD_LEARNERS, VALIDATE_UPLOAD_LEARNERS_DATA } from '../Queries/Learners'
import { invalidateLearnersQueryCache, showErrors, ErrorHandler } from '../../helpers'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { LoadingBlock } from '../common'
import { MicrosoftSyncActions } from '../MicrosoftSync'
import { GoogleSyncActions } from '../GoogleSync'
import { LANGUAGE_NAMES_BY_CODE } from '../../constants/languages'

const trOpt = { scope: 'modals.uploadLearnersModal' }

const OfficeLogo = () => (
  <svg width='96' height='96' viewBox='0 0 48 48'>
    <path fill='#e64a19' d='M7 12L29 4 41 7 41 41 29 44 7 36 29 39 29 10 15 13 15 33 7 36z' />
  </svg>
)
const OfficeIcon = props => <Icon component={OfficeLogo} {...props} />

const GoogleLogo = () => (
  <svg viewBox='0 0 512 512' height='96' width='96'><g fill='none' fillRule='evenodd'><path d='M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z' fill='#4285f4' /><path d='M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z' fill='#34a853' /><path d='M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z' fill='#fbbc05' /><path d='M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z' fill='#ea4335' /><path d='M20 20h472v472H20V20z' /></g></svg>
)
const GoogleIcon = props => <Icon component={GoogleLogo} {...props} />

const CsvLogo = () => (
  <svg viewBox='64 64 896 896' width='96' height='96' fill='currentColor'>
    <path
      d='M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z'
    />
  </svg>
)
const CsvIcon = props => <Icon component={CsvLogo} {...props} />

const ErrorsList = styled.div`
  margin-top: 30px;
`

const link = props => (<Button type='link' {...props} />)
const Link = styled(link)`
  border: none;
  padding: 0;
`

const UploadHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const _ModalComp = ({ autoContentHeight, ...props }) => <ModalComp {...props} />
const Modal = styled(_ModalComp)`
  height: calc(100% - 60px);
  max-width: 1100px;
  top: 50px;

  .ant-modal-content {
    height: ${({ autoContentHeight }) => autoContentHeight ? 'auto' : '90%'};
  }
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`
const ModalScroller = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5px;
  padding-right: 10px;
  position: relative;
`

const MethodSelection = styled.div`
  margin: 0 auto;
  padding: 50px 0;
`

const DeleteLearnerButton = ({ index, onClick: onClickProp = () => {} }) => {
  const onClick = useCallback(() => onClickProp(index), [index, onClickProp])

  return (
    <Button {...{ onClick }} type='danger' key={index}><Icon type='delete' /></Button>
  )
}

const CsvUpload = ({ handleCsvData, clearFile, showHeader, office365Enabled, googleSyncEnabled, reset }) => {
  return (
    <>
      {showHeader && (
        <UploadHeader>
          <h2>{I18n.t('csvTitle', trOpt)}</h2>
          {office365Enabled || googleSyncEnabled ? <Link type='link' onClick={reset}><Icon type='left' />{I18n.t('common.goBack')}</Link> : null}
        </UploadHeader>
      )}
      <CSVReader
        onFileLoaded={handleCsvData}
        inputId='csvUpload'
      />
      <br />
      <Button onClick={clearFile}>{I18n.t('clearFile', trOpt)}</Button>
      <Divider />

      <p>
        <span>{I18n.t('common.downloadTemplateCopy')}: </span>
        <a
          href='/upload-template/people-template.csv' target='_blank'
          rel='noopener noreferrer'
        >{I18n.t('common.downloadTemplate')}
        </a>
      </p>
    </>
  )
}

const Office365 = ({ resetSelectedMethod, office365Enabled }) => {
  return (
    <ModalBody>
      <UploadHeader>
        <h2>{I18n.t('office365.common.office365Integration')}</h2>
        {office365Enabled ? <Link onClick={resetSelectedMethod}><Icon type='left' />{I18n.t('common.goBack')}</Link> : null}
      </UploadHeader>
      <ModalScroller>
        <Row style={{ textAlign: 'center' }} gutter={16}>
          <Col span={24}>
            <MicrosoftSyncActions />
          </Col>
        </Row>
      </ModalScroller>
    </ModalBody>
  )
}

const GoogleSync = ({ resetSelectedMethod, googleSyncEnabled }) => {
  return (
    <ModalBody>
      <UploadHeader>
        <h2>{I18n.t('googleSync.common.googleSyncIntegration')}</h2>
        {googleSyncEnabled ? <Link onClick={resetSelectedMethod}><Icon type='left' />{I18n.t('common.goBack')}</Link> : null}
      </UploadHeader>
      <ModalScroller>
        <Row style={{ textAlign: 'center' }} gutter={16}>
          <Col span={24}>
            <GoogleSyncActions />
          </Col>
        </Row>
      </ModalScroller>
    </ModalBody>
  )
}

const SelectUploadMethod = ({ selectCSV, selectOffice365, office365Enabled, selectGoogleSync, googleSyncEnabled }) => {
  return (
    <MethodSelection>
      <Row style={{ textAlign: 'center' }} gutter={16}>
        <Col span={googleSyncEnabled && office365Enabled ? 8 : 12} style={{ cursor: 'pointer' }} onClick={selectCSV}>
          <CsvIcon />
          <p>{I18n.t('uploadViaCSV', trOpt)}</p>
        </Col>
        {
          office365Enabled && (
            <Col span={googleSyncEnabled ? 8 : 12} style={{ cursor: 'pointer' }} onClick={selectOffice365}>
              <OfficeIcon />
              <p>{I18n.t('office365.common.office365')}</p>
            </Col>
          )
        }
        {
          googleSyncEnabled && (
            <Col span={office365Enabled ? 8 : 12} style={{ cursor: 'pointer' }} onClick={selectGoogleSync}>
              <GoogleIcon />
              <p>{I18n.t('googleSync.common.googleSync')}</p>
            </Col>
          )
        }
      </Row>
    </MethodSelection>
  )
}
const UploadConfirm = ({
  learners, errors, office365Enabled, googleSyncEnabled, handleDelete, uploadLearners,
  handleCsvData, clearFile,
  reset, resetUpload
}) => {
  const chartData = useMemo(() =>
    learners.map((learner, index) => {
      return {
        key: index,
        name: `${learner.firstName} ${learner.lastName}`,
        email: learner.email || learner.learnerId,
        groups: learner.groups || [],
        managerEmail: learner.managerEmail || '',
        locale: learner.locale
      }
    }), [learners])

  const columns = useMemo(() => [
    {
      title: I18n.t('common.fields.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: I18n.t('learners.common.emailUserId'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: I18n.t('common.groups'),
      dataIndex: 'groups',
      key: 'groups',
      render: (groups = []) => (
        <span>
          {groups.map((group) => (
            <Tag key={group}>{group}</Tag>
          ))}
        </span>
      )
    },
    {
      title: I18n.t('learners.common.managerEmail'),
      dataIndex: 'managerEmail',
      key: 'managerEmail'
    },
    {
      title: I18n.t('learners.common.language'),
      dataIndex: 'locale',
      key: 'locale',
      render: locale => LANGUAGE_NAMES_BY_CODE[locale] || locale
    },
    {
      title: I18n.t('common.actions'),
      render: (text, record, index) => <DeleteLearnerButton {...{ index }} onClick={handleDelete} />
    }
  ], [handleDelete])

  const errorsUl = useMemo(() => {
    return errors.length > 0 ? (
      <ul>
        {errors.map((error, index) => (<li key={index}>{error}</li>))}
      </ul>
    ) : null
  }, [errors])

  return (
    <ModalBody>
      <UploadHeader>
        <h2>{I18n.t('csvTitle', trOpt)}</h2>
        {office365Enabled || googleSyncEnabled ? <Link type='link' onClick={reset}><Icon type='left' />{I18n.t('common.goBack')}</Link> : null}
      </UploadHeader>
      {learners.length === 0 && (
        <div>
          <CsvUpload {...{ handleCsvData, clearFile, office365Enabled, googleSyncEnabled, reset }} />
        </div>
      )}
      {
        learners.length > 0
          ? (
            <ModalScroller>
              {
                learners.length > 0
                  ? (
                    <div>
                      <Table columns={columns} dataSource={chartData} />
                      <p>{I18n.t('usersFound', { ...trOpt, count: learners.length })}</p>
                      <Button onClick={resetUpload}>{I18n.t('common.reset')}</Button>
                      <Button onClick={uploadLearners} type='primary' style={{ float: 'right' }}>{I18n.t('common.upload')}</Button>
                    </div>
                  ) : null
              }
              {
                errors.length > 0 && (
                  <ErrorsList>
                    <h4>{I18n.t('errorsTitle', trOpt)}</h4>
                    {errorsUl}
                  </ErrorsList>
                )
              }
            </ModalScroller>
          )
          : (
            <>
              <h4>{I18n.t('errorsTitle', trOpt)}</h4>
              <ModalScroller>
                {errorsUl}
              </ModalScroller>
            </>
          )
      }
    </ModalBody>
  )
}

const UploadLearnersContent = ({
  handleCsvData, clearFile, office365Enabled, googleSyncEnabled, reset,
  selectedMethod, resetSelectedMethod,
  selectCSV, selectOffice365, selectGoogleSync
}) => {
  let content
  if (!office365Enabled && !googleSyncEnabled) {
    return <CsvUpload {...{ handleCsvData, clearFile, office365Enabled, googleSyncEnabled, reset }} />
  }

  switch (selectedMethod) {
    case 'csv':
      content = <CsvUpload showHeader {...{ handleCsvData, clearFile, office365Enabled, googleSyncEnabled, reset }} />
      break
    case 'o365':
      content = <Office365 {...{ resetSelectedMethod, office365Enabled }} />
      break
    case 'google':
      content = <GoogleSync {...{ resetSelectedMethod, googleSyncEnabled }} />
      break
    default:
      content = <SelectUploadMethod {...{ selectCSV, selectOffice365, selectGoogleSync, office365Enabled, googleSyncEnabled }} />
      break
  }

  return content
}

const UploadLearnersModal = ({ office365 = {}, googleSync = {}, visible = false, setVisible = () => {}, refetchQueries, refetch = () => {}, onClose = () => {} }) => {
  const [errors, updateErrors] = useState([])
  const [learners, updateLearners] = useState([])
  const [selectedMethod, updateSelectedMethod] = useState(null)
  const [loading, updateLoading] = useState(false)

  const { enabled: office365Enabled = true } = office365 || {}
  const { enabled: googleSyncEnabled = true } = googleSync || {}

  const resetSelectedMethod = useCallback(() => updateSelectedMethod(null), [updateSelectedMethod])
  const selectCSV = useCallback(() => updateSelectedMethod('csv'), [updateSelectedMethod])
  const selectOffice365 = useCallback(() => updateSelectedMethod('o365'), [updateSelectedMethod])
  const selectGoogleSync = useCallback(() => updateSelectedMethod('google'), [updateSelectedMethod])

  const resetUpload = useCallback(() => {
    updateErrors([])
    updateLearners([])
  }, [updateErrors, updateLearners])
  const reset = useCallback(() => {
    resetUpload()
    resetSelectedMethod()
    onClose()
  }, [resetUpload, resetSelectedMethod, onClose])
  const closeModal = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const handleCsvData = useCallback(async data => {
    updateLoading(true)
    updateLearners([])
    updateErrors([])

    try {
      const result = await authenticatedClient.query({
        query: VALIDATE_UPLOAD_LEARNERS_DATA,
        variables: {
          data
        },
        fetchPolicy: 'no-cache'
      })
      const errors = _get(result, 'data.validateUploadLearnersData.errors', [])
      const hasErrors = errors.length > 0
      const allLearners = _get(result, 'data.validateUploadLearnersData.allLearners', [])
      const duplicateLearners = _get(result, 'data.validateUploadLearnersData.duplicateLearners', [])
      const newLearners = _get(result, 'data.validateUploadLearnersData.newLearners', [])
      if (_isArray(duplicateLearners) && duplicateLearners.length > 0) {
        errors.unshift(I18n.t('existingUserMessage', { ...trOpt, existing: duplicateLearners.length, total: allLearners.length }))
      }
      if (_isArray(newLearners) && newLearners.length > 0) {
        updateLearners(newLearners.map(learner => _omit(learner, ['__typename'])))
      } else if (!hasErrors) {
        errors.push(I18n.t('noNewUsersMessage', trOpt))
      }
      updateErrors(errors)
    } catch (e) {
      showErrors(e, I18n.t('uploadError', trOpt))
    } finally {
      updateLoading(false)
    }
  }, [updateErrors, updateLearners, updateLoading])

  const clearFile = useCallback(() => {
    updateErrors([])
    document.getElementById('csvUpload').value = ''
  }, [updateErrors])

  const handleDelete = useCallback(index => {
    const learnersUpdate = [...learners]
    learnersUpdate.splice(index, 1)
    updateLearners(learnersUpdate)
  }, [updateLearners, learners])

  const uploadLearners = useCallback(async () => {
    try {
      const res = await authenticatedClient.mutate({
        mutation: UPLOAD_LEARNERS,
        variables: { learners },
        refetchQueries,
        update: invalidateLearnersQueryCache
      })

      if (res.data.uploadLearners) {
        refetch(true)

        message.success(I18n.t('successMessage', trOpt))
        closeModal()
      }
    } catch (e) {
      const uploadErrors = new ErrorHandler(e, {
        processor: function () {
          this.errors = this.errors.map(error => {
            if (error.type === 'field_value_exists' && _get(error, 'original.path') === 'email') {
              error.message = I18n.t('userEmailExistsError', { ...trOpt, email: _get(error, 'original.value') })
            }
            return error
          })
        },
        defaultError: I18n.t('errorMessage', trOpt)
      }).getErrors()

      if (uploadErrors.length > 0) {
        updateErrors(uploadErrors.map(({ message }) => message))
      } else {
        // I don't think this line will ever be run now
        updateErrors([...errors, I18n.t('fallbackErrorMessage', trOpt)])
      }
    }
  }, [updateErrors, closeModal, errors, learners, refetch, refetchQueries])

  return (
    <Modal
      width='90%'
      bodyStyle={{ height: 'calc(100% - 50px)' }}
      visible={visible}
      title={I18n.t('learners.common.importUsers')}
      onCancel={closeModal}
      footer={null}
      afterClose={reset}
      destroyOnClose
      autoContentHeight={!(selectedMethod === 'o365' || selectedMethod === 'google' || learners.length > 0 || errors.length > 0)}
    >
      <LoadingBlock fullScreen={false} loading={loading} />
      {learners.length > 0 || errors.length > 0
        ? (
          <UploadConfirm
            {...{
              learners,
              errors,
              office365Enabled,
              googleSyncEnabled,
              handleDelete,
              uploadLearners,
              handleCsvData,
              clearFile,
              reset,
              resetUpload
            }}
          />
        )
        : (
          <UploadLearnersContent
            {...{
              handleCsvData,
              clearFile,
              office365Enabled,
              googleSyncEnabled,
              reset,
              selectedMethod,
              resetSelectedMethod,
              selectCSV,
              selectOffice365,
              selectGoogleSync
            }}
          />
        )}
    </Modal>
  )
}

export default connect(
  state => _pick(selectors.settings.get(state), ['office365', 'googleSync'])
)(UploadLearnersModal)
