import React from 'react'
import I18n from 'i18n-js'

import { MicrosoftSyncActions } from '../MicrosoftSync'
import { SettingsFormElement } from './SettingsForm'

const trOpt = { scope: 'settings.office365' }

const Office365 = React.forwardRef((props, ref) => (
  <SettingsFormElement
    headerId='settings-m365-sync-header'
    title={I18n.t('office365.common.office365Integration')}
    desc={<p>{I18n.t('description', trOpt)}</p>}
    form={<MicrosoftSyncActions />}
    hasFooter={false}
  />
))

export default Office365
