/* global localStorage */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Modal, Tabs } from 'antd'
import styled, { withTheme } from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { compose } from 'recompose'
import { withRouter, generatePath } from 'react-router-dom'
import I18n from 'i18n-js'
import { NetworkStatus } from 'apollo-client'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _pick from 'lodash/pick'

import { GET_POLICY, GET_POLICY_TEMPLATE, GET_PUBLIC_POLICY } from '../Queries/uPolicy'
import { LoadingBlock, ErrorAlerts, ExcludeInactiveToggle } from '../common'
import ViewPolicyDetails from '../../views/uPolicy/ViewPolicyDetails'
import ViewPolicyDocuments from '../../views/uPolicy/ViewPolicyDocuments'
import ViewPolicyUsers from '../../views/uPolicy/ViewPolicyUsers'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import routes from '../../constants/routes'
import ModalPageAccess from '../common/ModalPageAccess'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'

const trOpt = { scope: 'modals.viewPolicyModal' }
const commonTrOpt = { scope: 'uPolicy.common' }
const { TabPane } = Tabs

const ViewModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ViewPolicyHeader = styled.div`
  display: flex;
  justify-content: space-between;

  .view-pol-header-btns {
    text-align: right;

    .ant-btn {
      margin-left: 5px;
    }
  }
`

const ViewPolicyCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    height: 100%;
  }

  .view-pol__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-tabs {
    height: 100%;
  }
 
  .ant-tabs, .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tabpane {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 12px 12px 0;
  }
`

const ViewPolicyContentContainer = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  padding-right: 10px;
`

const ViewPolicyContent = ({
  policy, type, builder, loading, error, theme,
  userId, role, companyId, accountType, viewStorageId, persist = true,
  unified, query, refetchPolicy = () => {}, externalAccess, companyPolicySignatureSettings,
  history, pollingEnabled, setPollingEnabled, planValid
}) => {
  const [tabKey, setTabKey] = useState(type === 'policy' && !builder && !externalAccess ? 'recipients' : 'details')
  const [docsVersion, setDocsVersion] = useState('live')
  const [usersVersion, setUsersVersion] = useState('all')
  const [showAll, setShowAll] = useState(false)
  const [checkedGroups, updateCheckedGroups] = useState([])
  const [status, updateStatus] = useState('all')
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const handleTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])
  const isUsecureAdmin = role === 'usecure-admin'

  // get/set tabKey from local storage
  const storageId = useMemo(() =>
    (persist && viewStorageId && userId && companyId)
      ? `${viewStorageId}View|${companyId}|${userId}` : null,
  [persist, viewStorageId, userId, companyId])
  useEffect(() => {
    if (!storageId) {
      return
    }

    try {
      const storeString = localStorage.getItem(storageId)
      if (storeString) {
        const store = JSON.parse(storeString)
        Object.keys(store).forEach(key => {
          const value = store[key]
          switch (key) {
            case 'tabKey':
              setTabKey(value)
              break
            case 'docsVersion':
              setDocsVersion(value)
              break
            case 'usersVersion':
              setUsersVersion(value)
              break
            case 'showAll':
              setShowAll(value)
              break
            case 'checkedGroups':
              updateCheckedGroups(value)
              break
            case 'status':
              updateStatus(value)
              break
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'filters':
              updateFilters(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    if (storageId) {
      let store = {}
      try {
        const storeString = localStorage.getItem(storageId)
        if (storeString) {
          store = JSON.parse(storeString)
        }
        store = {
          ...store,
          tabKey,
          docsVersion,
          usersVersion,
          showAll,
          checkedGroups,
          status,
          searchFilterText,
          pagination,
          sorter,
          filters
        }
        localStorage.setItem(storageId, JSON.stringify(store))
      } catch (e) {}
    }
  }, [
    storageId, tabKey, docsVersion, usersVersion, showAll,
    checkedGroups, status, searchFilterText, pagination, sorter, filters
  ])

  const editAllowed = (builder || unified) && (policy.companyId === companyId || isUsecureAdmin)
  const showDocsTab = externalAccess || editAllowed
  const tabs = []
  tabs.push({
    tab: I18n.t('common.details'),
    key: 'details',
    content: (
      <ViewPolicyDetails
        showDocument={!showDocsTab}
        policySignatureSettings={companyPolicySignatureSettings}
        {...{ policy, type, role, companyId, builder, unified, externalAccess }}
      />
    )
  })
  if (showDocsTab) {
    tabs.push({
      tab: I18n.t('documentsTab', trOpt),
      key: 'documents',
      content: <ViewPolicyDocuments version={docsVersion} setVersion={setDocsVersion} {...{ policy, type, role, companyId, builder, unified }} />
    })
  }

  const isLive = editAllowed && !_isNil(_get(policy, 'livePolicyDocument.id'))
  const hasDraft = editAllowed && !_isNil(_get(policy, 'draftPolicyDocument.id'))
  const showUsersTab = type === 'policy' && !externalAccess && isLive
  const users = useRef(null)
  if (showUsersTab) {
    tabs.push({
      tab: I18n.t('common.recipients'),
      key: 'recipients',
      content: (
        <ViewPolicyUsers
          ref={users}
          version={usersVersion} setVersion={setUsersVersion}
          {...{
            loading,
            error,
            policy,
            query,
            showAll,
            setShowAll,
            checkedGroups,
            updateCheckedGroups,
            status,
            updateStatus,
            searchFilterText,
            updateSearchFilterText,
            pagination,
            updatePagination,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            pollingEnabled,
            setPollingEnabled
          }}
        />
      )
    })
  }
  const showAccessTab = (builder || unified) && !externalAccess && (
    isUsecureAdmin ||
    ((accountType === 'msp' || accountType === 'distributor') && policy.companyId === companyId && type === 'template')
  )
  if (showAccessTab) {
    tabs.push({
      tab: I18n.t('common.accessControl.tabTitle'),
      key: 'access',
      content: <ModalPageAccess record={policy} {...{ theme, role }} />
    })
  }

  useEffect(() => {
    if (!loading) {
      if ((tabKey === 'recipients' && !showUsersTab) ||
        (tabKey === 'documents' && !showDocsTab)
      ) {
        setTabKey('details')
      } else if (tabKey === 'access' && !showAccessTab) {
        setTabKey(type === 'policy' && !builder && !externalAccess ? 'recipients' : 'details')
      }
    }
  }, [tabKey, loading, policy, showDocsTab, showAccessTab, showUsersTab, type, isUsecureAdmin, companyId, accountType, builder, unified, externalAccess])

  let content = null
  if (tabs.length === 1) {
    content = (
      <ViewPolicyContentContainer>
        {tabs[0].content}
      </ViewPolicyContentContainer>
    )
  } else {
    content = (
      <Tabs activeKey={tabKey} onChange={handleTabChange}>
        {
          tabs.map(({ tab, key, content }) => (
            <TabPane key={key} tab={tab}>
              {content}
            </TabPane>
          ))
        }
      </Tabs>
    )
  }

  const { name } = policy

  const handleRefreshClick = useCallback(() => {
    refetchPolicy()
    if (users.current) {
      users.current.refetchLearners()
    }
  }, [refetchPolicy, users])

  const handleEditClick = useCallback(() => {
    if (policy) {
      const editPath = policy.template ? routes.UPOLICY_TEMPLATE_EDIT : routes.UPOLICY_EDIT
      history.push(generatePath(editPath, { policyId: policy.id }))
    }
  }, [history, policy])
  const handleEditDraftClick = useCallback(() => {
    if (policy) {
      const editPath = policy.template ? routes.UPOLICY_TEMPLATE_EDIT_DRAFT : routes.UPOLICY_EDIT_DRAFT
      history.push(generatePath(editPath, { policyId: policy.id }))
    }
  }, [history, policy])

  return (
    <>
      <ViewPolicyHeader>
        <h1>{loading ? I18n.t('common.loading') : name}</h1>
        <div>
          <div className='view-pol-header-btns'>
            {isLive && <Button icon='edit' ghost type='primary' disabled={loading || !planValid} onClick={handleEditClick}>{I18n.t(`edit${hasDraft ? 'Live' : ''}${type === 'template' ? 'Template' : 'Policy'}`, commonTrOpt)}</Button>}
            {hasDraft && <Button icon='edit' ghost type='primary' disabled={loading || !planValid} onClick={handleEditDraftClick}>{I18n.t(`editDraft${type === 'template' ? 'Template' : 'Policy'}`, commonTrOpt)}</Button>}
            <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t(`refresh${type === 'template' ? 'Template' : 'Policy'}`, trOpt)}</Button>
          </div>
          {!policy.template && <div style={{ textAlign: 'right', marginTop: 10 }}><ExcludeInactiveToggle /></div>}
        </div>
      </ViewPolicyHeader>
      {content}
    </>
  )
}

const ViewPolicyModal = ({
  id, title, type,
  visible = false, setVisible = () => {}, afterClose = () => {},
  role,
  accountType,
  builder,
  unified,
  externalAccess = false,
  externalAccessKey,
  excludeInactiveUsersInReports,
  ...contentProps
}) => {
  const [pollingEnabled, setPollingEnabled] = useState(true)
  const closeModal = useCallback(() => setVisible(false), [setVisible])
  const isPolicy = type === 'policy'
  const isTemplate = type === 'template'
  const loadAccess = role === 'usecure-admin' || (isTemplate && (accountType === 'msp' || accountType === 'distributor'))

  const query = externalAccess ? GET_PUBLIC_POLICY : (isTemplate ? GET_POLICY_TEMPLATE : GET_POLICY)
  let variables = { id }
  if (externalAccess) {
    variables.key = externalAccessKey
  } else {
    variables = {
      ...variables,
      withResults: isPolicy,
      includeResultLearner: isPolicy,
      withCompany: loadAccess,
      withAccess: loadAccess,
      includeLivePolicyDocument: true,
      includeDraftPolicyDocument: true,
      includePolicyDocuments: type === 'policy' && (builder || unified)
    }
  }
  if (!isTemplate) variables.excludeInactiveUsers = excludeInactiveUsersInReports

  const { loading: queryLoading, error, data, refetch, networkStatus } = useQuery(query, {
    variables,
    skip: !(id && visible),
    notifyOnNetworkStatusChange: true,
    pollInterval: pollingEnabled && id && visible ? QUERY_POLL_INTERVAL : 0
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll
  let policy
  let companyPolicySignatureSettings
  if (externalAccess) {
    policy = _get(data, 'publicPolicy.policy', {})
    companyPolicySignatureSettings = _get(data, 'publicPolicy.settings.policySignatureSettings', {})
  } else {
    policy = _get(data, isTemplate ? 'policyTemplate' : 'policy', {})
  }

  return (
    <ViewModal
      title={title}
      width='90%'
      bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
      visible={visible}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
      keyboard={false}
    >
      <ViewPolicyCard>
        <div className='view-pol__body'>
          {loading && <LoadingBlock fullScreen={false} loading />}
          {
            error
              ? (
                <>
                  <h1>{I18n.t('title', trOpt)}</h1>
                  <ErrorAlerts {...{ error }} defaultError={I18n.t('policyLoadError', commonTrOpt)} />
                </>
              )
              : (
                <ViewPolicyContent
                  query={{ query, variables }}
                  refetchPolicy={refetch}
                  {...{ policy, type, loading, error, role, accountType, builder, unified, externalAccess, companyPolicySignatureSettings, pollingEnabled, setPollingEnabled }}
                  {...contentProps}
                />
              )
          }
        </div>
      </ViewPolicyCard>
    </ViewModal>
  )
}

export default compose(
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['userId', 'role', 'companyId', 'accountType', 'planValid']),
      ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
    })
  ),
  withTheme,
  withRouter
)(ViewPolicyModal)
