import React, { useCallback, useState, useMemo, useEffect } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import _uniq from 'lodash/uniq'
import moment from 'moment'
import { Button } from 'antd'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ListHeader, ListHeaderPanel, ErrorAlerts, SearchBar } from '../common'
import DomainsTable from './DomainsTable'
import { searchRecords } from '../../helpers/listPages'
import useLocalStorage from '../../hooks/useLocalStorage'

const trOpt = { scope: 'uBreach' }

const DomainsView = ({
  companyId,
  userId,
  uBreachProSettings,
  breachedUsers = [],
  breachedServices = [],
  loading,
  error,
  refetchQueries,
  handleRefreshClick
}) => {
  const [searchText, setSearchText] = useState('')
  const [allRecords, setAllRecords] = useState([])

  const storageId = useMemo(() => `uBreach|domainsView|${companyId}|${userId}`, [userId, companyId])
  const tableStorageId = useMemo(() => `uBreach|domainsView|table|${companyId}|${userId}`, [userId, companyId])
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })

  useEffect(() => {
    if (!updateFromLocalStorage) return

    updateFromLocalStorage({
      searchText: setSearchText
    })
  }, [updateFromLocalStorage, storageId])

  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      searchText
    })
  }, [updateLocalStorage, searchText])

  useEffect(() => {
    const { breachDomains = [] } = uBreachProSettings || {}
    if (!breachDomains.length) return

    const domains = breachDomains.map(domain => {
      const breachedUsersForDomain = breachedUsers.filter(user => user.domain === domain)
      const breachNames = _uniq(breachedUsersForDomain.reduce((acc, { breaches }) => [...acc, ...breaches.map(({ name }) => name)], []))

      return {
        domain,
        breachCount: breachNames.length,
        breachIds: breachNames,
        resolvedCount: breachedUsersForDomain.reduce((acc, { breaches }) => acc + breaches.filter(({ resolved }) => resolved).length, 0),
        lastBreachDate: breachNames.reduce((acc, breachName) => {
          const { breachDate } = breachedServices.find(service => service.name === breachName)
          if (!acc) return breachDate
          return moment(breachDate) > moment(acc) ? breachDate : acc
        }, null),
        userBreachCount: breachedUsersForDomain.reduce((acc, { breaches }) => acc + breaches.length, 0)
      }
    })

    setAllRecords(domains)
  }, [breachedUsers, breachedServices, uBreachProSettings])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const records = allRecords.map(record => {
      const { domain } = record
      return {
        ...record,
        key: domain
      }
    })
    return searchRecords({ records, searchText, searchKeys: ['domain'] })
  }, [allRecords, searchText])

  return (
    <>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourDomainsCouldNotBeLoaded', trOpt)} />
          : (
            <>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
                </ListHeaderPanel>
              </ListHeader>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <SearchBar
                    placeholder={I18n.t('common.searchDomains', trOpt)}
                    value={searchText}
                    allowClear
                    onChange={onSearchChange}
                  />
                </ListHeaderPanel>
              </ListHeader>
              <DomainsTable
                {...{
                  breachedUsers,
                  breachedServices,
                  error,
                  refetchQueries,
                  handleRefreshClick
                }}
                domains={records}
                storageId={tableStorageId}
                showActions={false}
              />
            </>
          )
      }
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId', 'userId']),
    ..._pick(selectors.settings.get(state), ['uBreachProSettings'])
  })
)(DomainsView)
