import React, { Component, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import * as reactQuery from '@tanstack/react-query'
import { Button, Tooltip } from 'antd'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _endsWith from 'lodash/endsWith'

import CreateSimulationForm, { CreateSimulationFormFieldInfo } from './CreateSimulationForm'
import SendTestEmailMutateButton from './SendTestEmailMutateButton'
import { GET_SIMULATION_EMAIL_TEMPLATE, GET_SIMULATION_DOMAINS, GET_SIMULATION_EMAIL_TEMPLATE_LAYOUTS } from '../Queries/uPhish'
import { MutationFormFooter } from '../MutationForm'
import ValidationAlert, { ContentLinkAlert, SenderWarningAlert } from './ValidationAlert'
import { ErrorAlerts } from '../common'
import { DELIVERY_METHOD_OPTIONS, unLayerEmailFieldConfig } from '../../constants/uPhish'
import { validateEmail } from '../../helpers'

export const MONITOR_API_HOST = window.__USECURE_CONFIG__.REACT_APP_MONITOR_API_URL

const trOpt = { scope: 'uPhish.createSimulationConfigure' }
const commonTrOpt = { scope: 'uPhish.common' }
const formItemStyle = { maxWidth: 800 }

const requiresSenderWarning = ({ defaultDomains, senderDomain, customSender, senderEmail }) => {
  let showSenderWarning = false
  if (customSender === true) {
    showSenderWarning = true
    if (defaultDomains && senderEmail) {
      const senderEmailDomain = (senderEmail.split('@')[1] || '').trim()
      if (senderEmailDomain) {
        showSenderWarning = !defaultDomains.includes(senderEmailDomain)
      }
    }
  } else if (senderDomain && defaultDomains) {
    showSenderWarning = !defaultDomains.includes(senderDomain)
  }
  return showSenderWarning
}

class CreateSimulationConfigure extends Component {
  constructor (props) {
    super(props)

    this.form = React.createRef()
    this.state = {
      ready: false,
      showLegacyEditor: this.props.legacy,
      showSenderWarning: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.validateContent = this.validateContent.bind(this)
    this.validateSenderField = this.validateSenderField.bind(this)
  }

  getValues () {
    return this.form.current ? this.form.current.getValues() : {}
  }

  get isEmailValid () {
    const form = this.form.current
    if (!form) {
      return false
    }

    const emailFields = ['subject', 'link']

    if (this.props.emailTemplateId === 'freeform') {
      emailFields.push('content')
    }

    return this.validateSender(this.getValues()) &&
      form.props.fields
        .filter(({ id }) => emailFields.includes(id))
        .every(field => form.isFieldValid(field))
  }

  async handleChange (name, value) {
    const { onChange } = this.props
    await onChange(name, value)
    if (name === 'legacy') {
      await this.form.current.setField('content', null)
      return this.setState({ showLegacyEditor: value })
    }

    if (name === 'customSender') {
      const form = this.form.current
      if (!form) {
        return false
      }

      const values = form.getValues()
      const senderFields = ['senderEmail', 'senderPrefix', 'senderDomain']
      for (const field of senderFields) {
        await onChange(field, values[field])
      }
      this.setState({ showSenderWarning: true })
    }

    // Update landing page domain to match sender email domain change
    if (name === 'senderDomain') {
      const { fieldLinks } = this.formValuesAndConfig
      if (fieldLinks.includes(value)) {
        await this.form.current.setField('link', value)
      }
    }
  }

  renderFooter ({ submitLabel, disabled, footerAlign }) {
    const { updating = false, getSimulationData = () => ({}) } = this.props
    const { isEmailValid } = this
    if (updating) {
      return null
    }
    const sendTestButton = <SendTestEmailMutateButton disabled={!isEmailValid} {...{ getSimulationData }} />

    return (
      <MutationFormFooter footerAlign={footerAlign}>
        {
          isEmailValid
            ? sendTestButton
            : (
              <Tooltip title={I18n.t('sendTestInvalidMessage', trOpt)}>
                <div style={{ display: 'inline-block' }}>
                  <SendTestEmailMutateButton disabled={!this.isEmailValid} {...{ getSimulationData }} />
                </div>
              </Tooltip>
            )
        }
        <Button type='primary' ghost disabled={disabled} htmlType='submit' icon='right-circle'>{submitLabel}</Button>
      </MutationFormFooter>
    )
  }

  componentDidMount () {
    // Post render prod to show the correct disabled state on the footer buttons
    const interval = setInterval(() => {
      if (this.form.current) {
        this.setState({ ready: true })
        clearInterval(interval)
      }
    }, 100)

    const { defaultDomains } = this.props
    const { senderDomain, customSender, senderEmail } = this.formValuesAndConfig
    this.props.setShowSenderWarning(requiresSenderWarning({ defaultDomains, senderDomain, customSender, senderEmail }))
  }

  validateContent (field, value, errors) {
    if (!this.props.contentValid) {
      errors.push(I18n.t('phishingLinkMissingError', commonTrOpt))
    }
  }

  validateSender ({ customSender: custom, senderEmail: email, senderDomain: domain, senderPrefix: prefix } = {}) {
    const senderEmail = custom ? (email || '').trim() : ((prefix && domain) ? `${(prefix || '').trim()}@${(domain || '').trim()}`.trim() : '')
    return validateEmail(senderEmail)
  }

  validateSenderField (field, value, errors, opt, values) {
    if (!this.validateSender(values)) {
      if (values.customSender) {
        errors.push(I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError'))
      } else {
        errors.push(I18n.t('uPhish.common.errors.invalidSenderPrefix'))
      }
    }
  }

  get formValuesAndConfig () {
    const {
      name, subject, customSender, senderPrefix, senderDomain, senderName, senderEmail,
      content, legacy, link: linkProp, emailTemplateId,
      availableSenderDomains, tldAllowList, defaultDomains, simulationEmailTemplate,
      deliveryMethod
    } = this.props
    const isFreeform = emailTemplateId === 'freeform'

    const {
      availableSenderDomains: domains = [], availableLinks: links = [],
      subject: defaultSubject,
      customSender: defaultCustomSender,
      senderName: defaultSenderName,
      senderEmail: defaultSenderEmail,
      senderPrefix: defaultSenderPrefix
    } = simulationEmailTemplate || {}
    const fieldDomains = isFreeform ? (availableSenderDomains || defaultDomains) : domains
    const fieldLinks = isFreeform ? (availableSenderDomains || defaultDomains) : links
    let { senderDomain: defaultSenderDomain } = simulationEmailTemplate || {}
    let link = linkProp

    // Only use email template sender domain if it conforms with the user's TLD preferences if present
    if (defaultSenderDomain && _isArray(tldAllowList) && !_isEmpty(tldAllowList) && !tldAllowList.some(tld => _endsWith(defaultSenderDomain, tld))) {
      defaultSenderDomain = null
    }

    // If empty, set landing page domain (link) to match defaultSenderDomain provided its value is in the options list
    if (!link && defaultSenderDomain && fieldLinks.includes(defaultSenderDomain)) {
      link = defaultSenderDomain
    }

    return {
      name,
      subject: subject || defaultSubject,
      senderName: senderName || defaultSenderName,
      senderPrefix: senderPrefix || defaultSenderPrefix,
      senderDomain: senderDomain || defaultSenderDomain || null,
      senderEmail: senderEmail || defaultSenderEmail,
      customSender: _isBoolean(customSender) ? customSender : defaultCustomSender,
      link: link || null,
      content,
      legacy: legacy || false,
      fieldDomains,
      fieldLinks,
      deliveryMethod
    }
  }

  isDomainUnhealthy (domain) {
    const domains = this.props.domainHealthStatus.data?.domains

    if (domains === undefined) {
      return {
        outage: false,
        google: false,
        microsoft: false
      }
    }

    const domainStatus = domains.find(status => status.domain === domain)
    const outage = domainStatus && (!domainStatus.checks.dns || !domainStatus.checks.http || !domainStatus.checks.https)
    const google = domainStatus && (!domainStatus.checks.googleSafeBrowsing)
    const microsoft = domainStatus && (!domainStatus.checks.microsoftSmartscreen)

    return {
      outage,
      google,
      microsoft
    }
  }

  render () {
    const {
      name, subject, senderName, senderPrefix, senderDomain, senderEmail, customSender, link, content, legacy, fieldDomains, fieldLinks, deliveryMethod
    } = this.formValuesAndConfig
    const {
      emailTemplateId, onSubmit, getSimulationData = () => ({}),
      updating, contentValid, layouts,
      showSenderWarning, uPhishDeliveryMethod, isMessageInjectionConfigured = false
    } = this.props
    const { showLegacyEditor } = this.state
    const isFreeform = emailTemplateId === 'freeform'

    const fields = [
      {
        id: 'name',
        label: I18n.t('simulationName', commonTrOpt),
        type: 'text',
        defaultValue: name,
        placeholder: I18n.t('simulationNamePlaceholder', trOpt),
        required: true,
        formItemStyle
      }, {
        id: 'subject',
        label: I18n.t('common.fields.emailSubject'),
        type: 'text',
        required: true,
        defaultValue: subject,
        formItemStyle
      }, {
        id: 'senderName',
        type: 'text',
        label: I18n.t('senderName', commonTrOpt),
        defaultValue: senderName,
        extra: <CreateSimulationFormFieldInfo>{I18n.t('senderNameInfo', commonTrOpt)}</CreateSimulationFormFieldInfo>,
        formItemStyle
      }, {
        id: 'sender',
        label: I18n.t('senderEmailAddress', commonTrOpt),
        type: 'textWithSelect',
        required: true,
        divider: '@',
        formItemStyle: { maxWidth: 475 },
        input: {
          id: 'senderPrefix',
          defaultValue: senderPrefix,
          removeSpaces: true,
          validate: this.validateSenderField,
          style: { width: '50%' }
        },
        select: {
          id: 'senderDomain',
          options: fieldDomains.map(domain => ({ value: domain, label: domain })),
          defaultValue: senderDomain,
          showSearch: true,
          validate: this.validateSenderField,
          style: { width: 'calc(50% - 15px)' },
          placeholder: I18n.t('domainPlaceholder', trOpt)
        },
        extra: <CreateSimulationFormFieldInfo>{I18n.t('senderEmailAddressInfo', trOpt)}</CreateSimulationFormFieldInfo>,
        visible: values => values.customSender !== true
      }, {
        id: 'senderEmail',
        type: 'email',
        label: I18n.t('senderEmailAddress', commonTrOpt),
        required: true,
        visible: values => values.customSender === true,
        defaultValue: senderEmail,
        extra: <CreateSimulationFormFieldInfo>{I18n.t('emailSpamWarning', commonTrOpt)}</CreateSimulationFormFieldInfo>,
        validate: this.validateSenderField,
        formItemStyle
      }, {
        id: 'customSender',
        label: I18n.t('customSender', commonTrOpt),
        type: 'switch',
        defaultValue: customSender,
        formItemStyle
      }, {
        id: 'senderWarning',
        type: 'custom',
        component: SenderWarningAlert,
        visible: showSenderWarning
      }, {
        id: 'link',
        label: I18n.t(`${commonTrOpt.scope}.landingPageDomain`, { defaults: [{ scope: `${commonTrOpt.scope}.landingPage` }] }),
        type: 'select',
        required: true,
        options: fieldLinks.map(link => ({ value: link, label: link })),
        defaultValue: link,
        validate: (_, value, errors) => {
          if (this.isDomainUnhealthy(value).outage) {
            errors.push(`${I18n.t('monitorService.domainOutage', { domain: value })} ${I18n.t('monitorService.reselectionRequired')}`)
          }
        },
        extra: ({ link }) => {
          const unhealthy = this.isDomainUnhealthy(link)

          return (
            <>
              <ValidationAlert
                // Only show a domain warning, if we're not already showing an error.
                visible={!unhealthy.outage && unhealthy.google}
                type='warning'
                message={I18n.t('monitorService.blockedByGoogle.title', { domain: link })}
                description={<>{I18n.t('monitorService.blockedByGoogle.browsers')}<br /> {I18n.t('monitorService.reselectionRecommended')}</>}
              />
              <ValidationAlert
                // Only show a domain warning, if we're not already showing an error.
                visible={!unhealthy.outage && unhealthy.microsoft}
                type='warning'
                message={I18n.t('monitorService.blockedByMicrosoft.title', { domain: link })}
                description={<>{I18n.t('monitorService.blockedByMicrosoft.browsers')}<br /> {I18n.t('monitorService.reselectionRecommended')}</>}
              />
              <CreateSimulationFormFieldInfo>
                {I18n.t('landingPageLinkInfo.redirect', trOpt)}
                <br />
                {I18n.t('landingPageLinkInfo.spamWarning', trOpt)}
              </CreateSimulationFormFieldInfo>
            </>
          )
        },
        formItemStyle,
        placeholder: I18n.t('domainPlaceholder', trOpt)
      }, {
        id: 'deliveryMethod',
        label: I18n.t('preferredDeliveryMethod', commonTrOpt),
        type: 'select',
        required: isMessageInjectionConfigured,
        visible: isMessageInjectionConfigured,
        defaultValue: deliveryMethod || uPhishDeliveryMethod || 'smtp',
        options: DELIVERY_METHOD_OPTIONS,
        formItemStyle,
        extra: (
          <CreateSimulationFormFieldInfo>
            {I18n.t('preferredDeliveryMethodExtra', trOpt)}
            <br />
            {I18n.t('messageInjectionDisclaimer', commonTrOpt)}
          </CreateSimulationFormFieldInfo>
        )
      }
    ]

    if (isFreeform) {
      fields.splice(2, 0, {
        id: 'content',
        ...unLayerEmailFieldConfig,
        type: showLegacyEditor ? 'emailBody' : 'unlayer',
        required: true,
        defaultValue: content,
        validate: this.validateContent,
        layouts
      }, {
        id: 'contentLinkAlert',
        type: 'custom',
        component: ContentLinkAlert,
        visible: contentValid !== true
      }, {
        // This hidden switch field drives which editor is shown in conjunction with this.state.showLegacyEditor
        // The reason it's a field is in case we receive feedback asking for the slate editor back
        id: 'legacy',
        type: 'switch',
        label: I18n.t('legacyEditor', commonTrOpt),
        defaultValue: legacy || false,
        visible: false,
        formItemStyle
      })
    }

    return (
      <>
        <CreateSimulationForm
          submitLabel={I18n.t('common.continue')} ref={this.form}
          onChange={this.handleChange}
          {...{ fields, onSubmit }}
          footer={this.renderFooter}
        />
        {updating && <SendTestEmailMutateButton disabled={!this.isEmailValid} {...{ getSimulationData }} />}
      </>
    )
  }
}

const CreateSimulationConfigureQuery = React.forwardRef((props, ref) => {
  const { legacy, emailTemplateId, senderDomain, customSender, senderEmail } = props
  const isFreeform = emailTemplateId === 'freeform'

  const skipLayoutQuery = !isFreeform || legacy
  const skipEmailTemplateQuery = isFreeform

  const { loading: layoutsLoading, data: { emailTemplateLayouts: layouts = [] } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE_LAYOUTS, {
    skip: skipLayoutQuery
  })
  const { loading: domainsLoading, error: domainError, data: { simulationDomains: defaultDomains = [] } = {} } = useQuery(GET_SIMULATION_DOMAINS)
  const { loading: emailTemplateLoading, error: emailTemplateError, data: { simulationEmailTemplate } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATE, {
    skip: skipEmailTemplateQuery,
    variables: { id: emailTemplateId }
  })

  const domainHealthStatus = reactQuery.useQuery({
    queryKey: ['status'],
    queryFn: () => window.fetch(`${MONITOR_API_HOST}/status`).then(r => r.json())
  })

  const [showSenderWarning, setShowSenderWarning] = useState(false)
  useEffect(() => {
    setShowSenderWarning(requiresSenderWarning({ defaultDomains, senderDomain, customSender, senderEmail }))
  }, [defaultDomains, senderDomain, customSender, senderEmail])

  if (layoutsLoading || domainsLoading || emailTemplateLoading) return null
  if (domainError || emailTemplateError) {
    return (
      <>
        {domainError && <ErrorAlerts error={domainError} defaultError={I18n.t('domainsError', commonTrOpt)} />}
        {emailTemplateError && <ErrorAlerts error={emailTemplateError} defaultError={I18n.t('emailTemplateError', trOpt)} />}
      </>
    )
  }

  return <CreateSimulationConfigure {...{ ref, layouts, defaultDomains, simulationEmailTemplate, showSenderWarning, setShowSenderWarning, domainHealthStatus }} {...props} />
})

export default CreateSimulationConfigureQuery
