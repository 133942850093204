import React, { useImperativeHandle, useState, useCallback, useRef, useMemo } from 'react'
import { message, Modal } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _omit from 'lodash/omit'

import { validateEmail, waitForRefRender } from '../../helpers'
import MutationForm from '../MutationForm/MutationForm'
import { LoadingBlock } from '../common'
import { UPDATE_SCHEDULED_REPORT, CREATE_SCHEDULED_REPORT } from '../Queries/Reports'
import { withConsumer } from '../../hocs/withConsumer'

const commonTrOpt = { scope: 'reports.reportCentre.scheduledReports.common' }

const EditModal = styled(Modal)`
  max-width: 800px;
  top: 50px;
`

const EditScheduledReportModal = React.forwardRef(({
  client,
  refetchQueries = [],
  companyId,
  enabledSections = {}, // { uBreach: Boolean, uPhish: Boolean, uLearn: Boolean, uPolicy: Boolean }
  uBreachProEnabled = false
}, ref) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [scheduledReport, setScheduledReport] = useState(null)

  const trOpt = useMemo(() => {
    if (scheduledReport) return { scope: 'modals.editScheduledReportModal' }
    return { scope: 'modals.addScheduledReportModal' }
  }, [scheduledReport])

  const form = useRef(null)
  const resetForm = useCallback(() => {
    if (form && form.current) {
      form.current.resetFields()
    }
  }, [form])
  const setInitialFormValues = useCallback(async (values) => {
    if (form && form.current) {
      await form.current.setInitialValues(values)
    }
  }, [form])

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setScheduledReport(null)
    resetForm()
  }

  const onSuccess = useCallback(() => {
    message.success(I18n.t('successMessage', trOpt))
    closeModal()
  }, [closeModal, trOpt])

  useImperativeHandle(ref, () => ({
    open: async (scheduledReport = null) => {
      setVisible(true)
      setLoading(true)
      if (scheduledReport) {
        setScheduledReport(scheduledReport)
        await waitForRefRender(form)
        const { name, reportType, frequency, enabled, recipients, parameters } = scheduledReport
        await setInitialFormValues({
          name,
          reportType,
          frequency,
          enabled,
          recipients,
          ...(parameters.reportSections ? { reportSections: parameters.reportSections } : {})
        })
      }
      setLoading(false)
    }
  }), [form, setInitialFormValues])

  const mutateValues = values => {
    const parameters = {}
    if (values.reportType === 'performanceReport') {
      parameters.granularity = values.frequency === 'month' ? 'week' : 'month'
      // parameters.reportSections = values.reportSections, // Uncomment this line to enable report sections
    }

    return {
      ..._omit(values, ['reportSections']),
      parameters
    }
  }

  const fields = useMemo(() => [
    {
      id: 'name',
      label: I18n.t('reportName', commonTrOpt),
      type: 'text',
      required: true
    },
    {
      id: 'reportType',
      label: I18n.t('reportType', commonTrOpt),
      type: 'select',
      required: true,
      options: [
        { value: 'performanceReport', label: I18n.t('reportTypes.performanceReport', commonTrOpt) },
        ...(uBreachProEnabled ? [{ value: 'breachReport', label: I18n.t('reportTypes.breachReport', commonTrOpt) }] : [])
      ],
      defaultValue: 'performanceReport'
    },
    {
      id: 'frequency',
      label: I18n.t('frequency', commonTrOpt),
      type: 'select',
      required: true,
      options: [
        { value: 'month', label: I18n.t('frequencies.month', commonTrOpt) },
        { value: 'quarter', label: I18n.t('frequencies.quarter', commonTrOpt) },
        { value: 'year', label: I18n.t('frequencies.year', commonTrOpt) }
      ],
      defaultValue: 'month'
    },
    {
      id: 'enabled',
      label: I18n.t('enabled', commonTrOpt),
      type: 'switch',
      required: false,
      defaultValue: true,
      extra: I18n.t('enabledExtra', commonTrOpt)
    },
    {
      id: 'recipients',
      label: I18n.t('recipients', commonTrOpt),
      type: 'textTags',
      required: true,
      validateTag: value => {
        if (!validateEmail(value)) {
          return I18n.t('modals.sendTestSimulationEmailConfirm.invalidEmailError')
        }
      },
      defaultValue: []
    },
    {
      id: 'reportSections',
      label: I18n.t('reportSections', commonTrOpt),
      type: 'multiSelect',
      required: true,
      options: [
        { value: 'execSummary', label: I18n.t('sections.execSummary', commonTrOpt) },
        { value: 'users', label: I18n.t('sections.users', commonTrOpt) },
        ...(enabledSections.uLearn ? [{ value: 'uLearn', label: I18n.t('sections.uLearn', commonTrOpt) }] : []),
        ...(enabledSections.uPhish ? [{ value: 'uPhish', label: I18n.t('sections.uPhish', commonTrOpt) }] : []),
        ...(enabledSections.uBreach ? [{ value: 'uBreach', label: I18n.t('sections.uBreach', commonTrOpt) }] : []),
        ...(enabledSections.uPolicy ? [{ value: 'uPolicy', label: I18n.t('sections.uPolicy', commonTrOpt) }] : [])
      ],
      visible: false // Change to `visible: ({ reportType }) => reportType === 'performanceReport'` to enable report sections on performance report
    }
  ], [enabledSections, uBreachProEnabled])

  return (
    <EditModal
      title={I18n.t('title', trOpt)}
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      afterClose={afterClose}
      width='70%'
    >
      <LoadingBlock {...{ loading }} fullScreen={false} />
      <MutationForm
        ref={form}
        mutation={scheduledReport ? UPDATE_SCHEDULED_REPORT : CREATE_SCHEDULED_REPORT}
        variables={scheduledReport ? { id: scheduledReport.id, companyId } : { companyId }}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={scheduledReport ? I18n.t('common.save') : I18n.t('common.add')}
        {...{ refetchQueries, onSuccess, fields, mutateValues }}
        skipResetFieldsOnSubmit
      />
    </EditModal>
  )
})

export default withConsumer({ useHooks: true })(EditScheduledReportModal)
