import I18n from 'i18n-js'
import isoLanguages from '@cospired/i18n-iso-languages'
import isoCountries from 'i18n-iso-countries'
import localeCodes from 'locale-codes'
import _isString from 'lodash/isString'
import _uniq from 'lodash/uniq'

const USE_I18N_CONTENT_LANGUAGE_NAMES = (window.__USECURE_CONFIG__.REACT_APP_USE_I18N_CONTENT_LANGUAGE_NAMES || 'true') === 'true'

export const langTrOpt = { scope: 'common.languageNames' }

export const DEFAULT_LANGUAGE = 'en'

export const LANGUAGE_CODES = [
  'en', 'us', 'fr', 'fr-CA', 'de', 'nl', 'it', 'es', 'cs', 'zh', 'da', 'fi', 'no', 'pt', 'sv', 'en-ID', 'ro', 'en-CN', 'pl', 'en-AU',
  'fr-CH', 'de-CH', 'en-NZ', 'en-ZA', 'en-IE', 'en-MY', 'ms', 'th', 'vi', 'id'
]

// This is copied from the server course-results
export const LANGUAGES = LANGUAGE_CODES.map(code => ({
  code,
  get name () { return I18n.t(this.code, langTrOpt) }
}))

export const LANGUAGE_NAMES_BY_CODE = LANGUAGE_CODES.reduce((acc, locale) => {
  Object.defineProperty(acc, locale, {
    get: () => I18n.t(locale, langTrOpt)
  })
  return acc
}, {})

// Note: Getter required for localisation
export const LANGUAGE_SELECT_OPTIONS = LANGUAGES.map((lang) => { return { value: lang.code, get label () { return lang.name } } })
export const LANGUAGE_TREE_OPTIONS = LANGUAGES.map((lang) => { return { key: lang.code, isLeaf: true, value: lang.code, get title () { return lang.name } } })

export const BCP_47_REGEX = /^[a-z]{2}-[0-9A-Z]{2,}$/
// Alphabetised list of ISO 639-1 (e.g. en) codes and BCP-47 e.g. (e.g. en-GB)
const ALLOWED_CONTENT_LOCALE_TAGS = ['no', 'fil', 'zh', 'zh-CN', 'zh-HK', 'zh-SG', 'zh-TW']
export const ALL_CONTENT_LOCALES = localeCodes.all.reduce((acc, l) => {
  if (
    l['iso639-1'] || // Only include languages from the library with a ISO-639-1 code
    ALLOWED_CONTENT_LOCALE_TAGS.includes(l.tag) // With the exception of these languages which do not have ISO 639-1 code in locale-codes
  ) {
    acc.push(l.tag.replace(/[^0-9a-z-]/gi, ''))
  }
  return acc
}, [
  // Codes not available in locale-codes or tricky to exclude using above logic
  'en-CN', // English (China)
  'en-ID' // English (Indonesia)
])
  .sort()

const CONTENT_LANGUAGES_ENV_VAR = window.__USECURE_CONFIG__.REACT_APP_CONTENT_LANGUAGES
export const ALLOWED_CONTENT_LOCALES = _isString(CONTENT_LANGUAGES_ENV_VAR)
  ? _uniq(CONTENT_LANGUAGES_ENV_VAR.split(',')
    .reduce((acc, code) => {
      if (ALL_CONTENT_LOCALES.includes(code)) {
        acc.push(code)
      }
      return acc
    }, []))
  : []
export const CONTENT_LOCALES = ALLOWED_CONTENT_LOCALES.length > 0 ? ALLOWED_CONTENT_LOCALES : ALL_CONTENT_LOCALES
// The inbuilt Intl class could also be used to provide language names if needed
// const languageNamesInFrench = new Intl.DisplayNames(['fr'], { type: 'language', style: 'short' });
// languageNamesInFrench.of('fr-ca') gives “français canadien”
// Holding this is reserve in case the more controlled method below doesn't work
export const CONTENT_LANGUAGES = CONTENT_LOCALES.map(code => {
  return {
    code,
    get name () {
      // Use existing name translation if present
      // This logic assumes that every value in LANGUAGES has a translation in all our supported languages
      // If a locale exists in LANGUAGES but does not have common.languageNames for the current locale then it will be rendered in English
      // If we only use i18n-iso-languages/countries for these translations then the LANGUAGE_NAMES_BY_CODE logic can be removed
      let langName
      if (USE_I18N_CONTENT_LANGUAGE_NAMES) {
        if (this.code === 'en-US') {
          langName = LANGUAGE_NAMES_BY_CODE.us
        } else if (this.code === 'en-GB') {
          langName = LANGUAGE_NAMES_BY_CODE.en // common.languageNames.en is actually for en-GB
        } else if (this.code !== 'en') {
          langName = LANGUAGE_NAMES_BY_CODE[this.code]
        }
        if (langName) return langName
      }

      // Get "<Language> (<Country>)" for BCP-47 code or "<Language>" ISO-639 codes`
      let currentLocale = I18n.locale
      if (currentLocale === 'us') currentLocale = 'en-US'
      currentLocale = currentLocale.substring(0, 2)
      let langCode
      let countryCode
      if (BCP_47_REGEX.test(this.code)) {
        ([langCode, countryCode] = this.code.split('-'))
      } else {
        langCode = this.code
      }
      langName = isoLanguages.getName(langCode, currentLocale)
      if (langName && countryCode) {
        const countryName = isoCountries.getName(countryCode, currentLocale, { select: 'alias' })
        if (countryName) {
          return `${langName} (${countryName})`
        }
      } else if (langName) {
        return langName
      }

      // Use Intl Class as fallback
      const intlLanguageNames = new Intl.DisplayNames([currentLocale], { type: 'language', style: 'short' })
      let intlLanguageName
      try {
        intlLanguageName = intlLanguageNames.of(code.toLowerCase())
      } catch (e) {
        console.error(`No language name found for ${code.toLowerCase()} in Intl.DisplayNames`, e)
      }
      return intlLanguageName || this.code
    }
  }
})
export const CONTENT_LANGUAGE_OPTIONS = CONTENT_LANGUAGES.map((lang) => { return { value: lang.code, get label () { return lang.name } } })
export const CONTENT_LANGUAGE_TREE_OPTIONS = CONTENT_LANGUAGES.map((lang) => { return { key: lang.code, isLeaf: true, value: lang.code, get title () { return lang.name } } })
export const CONTENT_LANGUAGE_NAMES_BY_CODE = CONTENT_LANGUAGES.reduce((acc, lang) => {
  Object.defineProperty(acc, lang.code, {
    get: () => lang.name
  })
  return acc
}, {})

export const NO_LANGUAGE_VALUE = '%NO_LANGUAGE%'

export const LANGUAGE_NAME_BY_CODE_TYPES = {
  app: LANGUAGE_NAMES_BY_CODE, // all usecure language codes - follows 2 character standard for base languages minus us (i.e. en-US) and fr-CA
  content: CONTENT_LANGUAGE_NAMES_BY_CODE // Full language code list containing a mix of 2 and 5 character codes without any custom codes (ideally)
}

export const LANGUAGE_TREE_OPTIONS_BY_TYPE = {
  app: LANGUAGE_TREE_OPTIONS,
  content: CONTENT_LANGUAGE_TREE_OPTIONS
}
