import React, { useMemo, useEffect, useState } from 'react'
import I18n from 'i18n-js'
import { Divider, Button } from 'antd'
import styled from 'styled-components'

import { compose } from 'recompose'
import { connect } from '../../hocs'
import { creators as viewActions } from '../../state/actions/view'
import ExportServiceDownloadButton from '../../components/common/ExportServiceDownloadButton'

import { downloadFileFromGet, downloadFileFromPost } from '../../helpers//downloads'
import { useQuery } from 'react-apollo'
import { GET_SIMPLE_COMPANY } from '../../components/Queries/Companies'
import { ErrorAlerts } from '../../components/common'

export const DescriptionContainer = styled.div`
  margin-bottom: 20px;
`
const ReportContainer = styled.div`
  margin-bottom: 30px;
`

const UBREACH_V2_ENABLED = window.__USECURE_CONFIG__.REACT_APP_UBREACH_V2_ENABLED === 'true'

const trOpt = { scope: 'reports.reportCentre.excelReports' }
const DEFAULT_VISIBILITY = {
  uPhish: true,
  uLearn: true,
  uBreach: true,
  uPolicy: true,
  uService: false,
  uBreachPro: false
}
const ExcelReport = ({ companyId, name, description, download, file, component = null, endpoint, get = true, setLoadingVisible }) => {
  const downloadFile = async () => {
    setLoadingVisible(true)
    if (get) {
      await downloadFileFromGet({ endpoint, file, companyId, params: { companyId } })
    } else {
      await downloadFileFromPost({ endpoint, file, data: { isCompanyExport: true, companyId } })
    }
    setLoadingVisible(false)
  }
  return (
    <ReportContainer>
      <h3>{name}</h3>
      <p>{description}</p>
      {component || <Button icon='download' onClick={() => downloadFile()}>{download}</Button>}
    </ReportContainer>
  )
}
const ExcelReports = ({ companyId, setLoadingVisible, role }) => {
  const [visibility, setVisibility] = useState(DEFAULT_VISIBILITY)
  const { data: { simpleCompany: { accountType, settings, uBreachProEnabled } = {} } = {}, loading, error } = useQuery(GET_SIMPLE_COMPANY, {
    variables: { id: companyId }
  })
  useEffect(() => {
    setLoadingVisible(loading)
  }, [setLoadingVisible, loading])

  useEffect(() => {
    if (settings) {
      setVisibility({
        uLearn: settings.uLearn !== false,
        uPhish: settings.uPhish !== false,
        uBreach: settings.uBreachEnabled !== false,
        uBreachPro: uBreachProEnabled === true,
        uBreachProUsage: uBreachProEnabled === true && (accountType === 'distributor' || accountType === 'msp'),
        uPolicy: settings.uPolicy !== false,
        uService: (accountType === 'distributor' && role === 'admin') || role === 'usecure-admin'
      })
    }
  }, [settings, accountType, role, uBreachProEnabled])

  const excelReportsList = useMemo(() => {
    const excelReports = [
      {
        divider: true,
        title: I18n.t('common.users')
      }, {
        key: 'learnersReport',
        name: I18n.t('learnersReport.name', trOpt),
        description: I18n.t('learnersReport.description', trOpt),
        download: I18n.t('learnersReport.download', trOpt),
        file: 'users-report.xlsx',
        endpoint: '/api/reports/learners/users-report.xlsx'
      }, {
        key: 'groupManagers',
        name: I18n.t('groupManagers.name', trOpt),
        description: I18n.t('groupManagers.description', trOpt),
        download: I18n.t('groupManagers.download', trOpt),
        endpoint: '/api/reports/learners/group-managers-report.xlsx',
        file: 'group-managers-report.xlsx'
      }
    ]
    if (visibility.uPhish) {
      excelReports.push({
        divider: true,
        title: I18n.t('common.uPhish')
      }, {
        key: 'simulationReport',
        name: I18n.t('simulationReport.name', trOpt),
        description: I18n.t('simulationReport.description', trOpt),
        download: I18n.t('simulationReport.download', trOpt),
        endpoint: '/api/company/simulations/simulation-events.xlsx',
        file: 'simulation-events.xlsx',
        get: false
      }, {
        key: 'learnerSimulationExport',
        name: I18n.t('learnerSimulationExport.name', trOpt),
        description: I18n.t('learnerSimulationExport.description', trOpt),
        download: I18n.t('learnerSimulationExport.download', trOpt),
        endpoint: '/api/learner/simulation/export/simulations.xlsx',
        file: 'simulations.xlsx',
        get: false
      })
    }
    if (visibility.uLearn) {
      excelReports.push({
        divider: true,
        title: I18n.t('common.uLearn')
      }, {
        key: 'outstandingCourses',
        name: I18n.t('outstandingCourses.name', trOpt),
        description: I18n.t('outstandingCourses.description', trOpt),
        download: I18n.t('outstandingCourses.download', trOpt),
        file: 'outstanding.xlsx',
        endpoint: '/api/reports/course-participation/outstanding.xlsx'
      }, {
        key: 'participationGrid',
        name: I18n.t('participationGrid.name', trOpt),
        description: I18n.t('participationGrid.description', trOpt),
        download: I18n.t('participationGrid.download', trOpt),
        file: 'participation-grid.xlsx',
        endpoint: '/api/reports/course-participation/participation-grid.xlsx'
      }, {
        key: 'learnerCourseExport',
        name: I18n.t('learnerCourseExport.name', trOpt),
        description: I18n.t('learnerCourseExport.description', trOpt),
        download: I18n.t('learnerCourseExport.download', trOpt),
        endpoint: '/api/learner/course/export/courses.xlsx',
        file: 'courses.xlsx',
        get: false
      }, {
        key: 'uLearnReportExport',
        name: I18n.t('uLearnReportExport.name', trOpt),
        description: I18n.t('uLearnReportExport.description', trOpt),
        component: (
          <ExportServiceDownloadButton
            icon='download'
            fileName='courses-report.xlsx' fileType='xlsx'
            btnType={null}
            jobData={{ reportType: 'uLearnReportExport', companyId }}
          >
            {I18n.t('uLearnReportExport.download', trOpt)}
          </ExportServiceDownloadButton>
        )
      })
    }
    if (visibility.uBreach) {
      excelReports.push({
        divider: true,
        title: I18n.t('common.uBreach')
      })
      if (UBREACH_V2_ENABLED) {
        excelReports.push({
          key: 'breachesReport',
          name: I18n.t('breachesReport.name', trOpt),
          description: I18n.t('breachesReport.description', trOpt),
          component: (
            <ExportServiceDownloadButton
              icon='download'
              fileName='breaches{{ts}}.xlsx'
              fileType='xlsx'
              btnType={null}
              jobData={{ reportType: 'uBreachExport', companyId }}
            >
              {I18n.t('breachesReport.download', trOpt)}
            </ExportServiceDownloadButton>
          )
        })
      } else {
        excelReports.push({
          key: 'breachesReport',
          name: I18n.t('breachesReport.name', trOpt),
          description: I18n.t('breachesReport.description', trOpt),
          download: I18n.t('breachesReport.download', trOpt),
          endpoint: '/api/uBreach/breaches.xlsx',
          file: 'breaches{{ts}}.xlsx'
        })
      }
    }
    if (visibility.uBreachProUsage) {
      excelReports.push({
        divider: true,
        title: I18n.t('common.uBreachPro')
      }, {
        key: 'uBreachProUsageReport',
        name: I18n.tWithProductNames('uBreachProUsageReport.name', trOpt),
        description: I18n.tWithProductNames('uBreachProUsageReport.description', trOpt),
        component: (
          <ExportServiceDownloadButton
            icon='download'
            fileName='ubreach-pro-usage-report.xlsx' fileType='xlsx'
            btnType={null}
            jobData={{ reportType: 'uBreachProUsageReportExport' }}
          >
            {I18n.tWithProductNames('uBreachProUsageReport.download', trOpt)}
          </ExportServiceDownloadButton>
        )
      })
    }
    if (visibility.uPolicy) {
      excelReports.push({
        divider: true,
        title: I18n.t('common.uPolicy')
      }, {
        key: 'outstandingPolicyExport',
        name: I18n.t('outstandingPolicyExport.name', trOpt),
        description: I18n.t('outstandingPolicyExport.description', trOpt),
        download: I18n.t('outstandingPolicyExport.download', trOpt),
        endpoint: '/api/reports/uPolicy/outstanding-policy-report.xlsx',
        file: 'outstanding-policy-report.xlsx'
      }, {
        key: 'learnerPolicyExport',
        name: I18n.t('learnerPolicyExport.name', trOpt),
        description: I18n.t('learnerPolicyExport.description', trOpt),
        download: I18n.t('learnerPolicyExport.download', trOpt),
        endpoint: '/api/policies/export/policy_reports.xlsx',
        file: 'policy_reports-{{ts}}.xlsx',
        get: false
      })
    }

    if (visibility.uService) {
      if (role === 'usecure-admin') {
        excelReports.unshift({
          key: 'salesReport',
          name: I18n.t('salesReport.name', trOpt),
          description: I18n.t('salesReport.description', trOpt),
          download: I18n.t('salesReport.download', trOpt),
          file: 'sales-report.xlsx',
          endpoint: '/api/reports/uService/sales-report.xlsx'
        })
      }

      excelReports.unshift({
        key: 'prospectsReport',
        name: I18n.t('prospectsReport.name', trOpt),
        description: I18n.t('prospectsReport.description', trOpt),
        download: I18n.t('prospectsReport.download', trOpt),
        file: 'prospects-report.xlsx',
        endpoint: '/api/reports/uService/prospects-report.xlsx'
      })
      excelReports.unshift({
        key: 'accountsOverview',
        name: I18n.t('accountsOverview.name', trOpt),
        description: I18n.t('accountsOverview.description', trOpt),
        component: (
          <ExportServiceDownloadButton
            icon='download'
            fileName='accounts-overview.xlsx' fileType='xlsx'
            btnType={null}
            jobData={{ reportType: 'accountsOverview' }}
          >
            {I18n.t('accountsOverview.download', trOpt)}
          </ExportServiceDownloadButton>
        )
      })
      excelReports.unshift({
        divider: true,
        title: I18n.t('common.uService')
      })
    }

    return excelReports
  }, [visibility, companyId, role])
  return (
    <>{error ? <ErrorAlerts error={error} /> : (
      <>
        <div>
          <h1>{I18n.t('excelReports', trOpt)}</h1>
          <DescriptionContainer>{I18n.t('pageDescription', trOpt)}</DescriptionContainer>
        </div>
        {excelReportsList.map((report) => {
          if (report.divider) {
            return <Divider key={report.title}><h2>{report.title}</h2></Divider>
          } else {
            return <ExcelReport key={report.key} {...report} companyId={companyId} setLoadingVisible={setLoadingVisible} />
          }
        })}
      </>
    )}
    </>
  )
}

export default compose(
  connect(
    undefined,
    dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    })
  )
)(ExcelReports)
