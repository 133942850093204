/* global Event */
import React, { useCallback, useMemo, useRef, useEffect, useState, useImperativeHandle } from 'react'
import { Alert, Button, Form, Icon, Select, message } from 'antd'
import { useQuery, useApolloClient, useMutation } from '@apollo/react-hooks'
import I18n from 'i18n-js'
import styled from 'styled-components'
import moment from 'moment'
import { generatePath } from 'react-router-dom'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'

import { UPDATE_COMPANY_SETTING, UPDATE_DEFAULT_TENANT_SETTING } from '../../components/Queries/Companies'
import { GET_GROUP_TREE } from '../../components/Queries/Groups'
import { GET_SIMULATION_LANDING_PAGE_TEMPLATES, GET_SIMULATION_DOMAINS, ARE_AUTO_PHISH_TEMPLATES_AVAILABLE } from '../../components/Queries/uPhish'
import { GET_COURSES } from '../../components/Queries/Courses'
import { buildTree } from '../../components/Learners/helpers'
import { LoadingBlock, ErrorAlerts } from '../../components/common'
import { SettingsFormElement, SettingsHeader, SettingsFormContainer, SettingsFormFieldExtra } from '../../components/Settings/SettingsForm'
import { getSettingsFromCompany } from '../../helpers/getMe'
import { CONTENT_LANGUAGE_OPTIONS, CONTENT_LANGUAGE_NAMES_BY_CODE } from '../../constants/languages'
import { COURSE_SUBJECT_OPTIONS } from '../../constants/courses'
import selectors from '../../state/selectors'
import { renderToString } from 'react-dom/server'
import routes from '../../constants/routes'
import { renderParagraphsFragmentFromArray, renderListFragmentFromArray, showErrors, modalConfirmAsync, modalErrorAsync } from '../../helpers'
import { DialogModalContent } from '../../components/Modals/common'
import { DescriptionContainer } from '../../components/Settings/common'
import { DELIVERY_METHODS, DELIVERY_METHOD_OPTIONS } from '../../constants/uPhish'
import { useGlobalState } from '../../hooks'
import { isMessageInjectionAuthorised } from '../../helpers/messageInjection'
import IntercomHeader from '../../components/IntercomHeader'

const { Option } = Select
const trOpt = { scope: 'uPhish.autoPhish' }
const checkTrOpt = { scope: `${trOpt.scope}.templateCheck` }
const commonTrOpt = { scope: 'uPhish.common' }
const LOCALES_REQUIRED = window.__USECURE_CONFIG__.REACT_APP_AUTO_PHISH_LOCALES_REQUIRED === 'true'

const AutoPhishMutationForm = styled(SettingsFormElement)`
  .auto-phish-template-filter-select {
    .ant-form-item-control-wrapper {
      display: inline-block;
    }
    /* The width of this field was tested with the English and could result in truncated options in other languages */
    .ant-select {
      min-width: 240px;
    }
  }
`

// Returns true if value is string representing HH:mm time
const isTimeValue = value => _isString(value) && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)

const DisabledLocalesSelect = React.forwardRef(({ id, label, placeholder, options, contentLocales = [], visible }, ref) => {
  if (!visible) return null
  return (
    <Form.Item
      label={label}
      required={LOCALES_REQUIRED}
    >
      <Select
        name={id}
        optionFilterProp='label'
        value={contentLocales}
        required={LOCALES_REQUIRED}
        mode='multiple'
        disabled
        {...{ placeholder }}
      >
        {options.map((option, index) => (
          <Option key={index} value={option.value} disabled={option.disabled} label={option.label}>{option.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
})

const TemplateCheckDesc = styled.div`
  p {
    font-family: unset;
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    padding-left: 18px;
  }
`
const TemplateCheckAlert = React.forwardRef(({ visible, disabled, checkAvailableTemplates = () => {}, getFormValues = () => {} }, ref) => {
  const [loading, setLoading] = useState()
  const onClick = useCallback(async () => {
    setLoading(true)
    try {
      const autoPhishSettings = getFormValues()
      if (!autoPhishSettings) throw new Error(I18n.t('errorMessage', checkTrOpt))
      const available = await checkAvailableTemplates(autoPhishSettings)
      if (available) {
        message.success(I18n.t('successMessage', checkTrOpt))
      } else {
        message.warning(I18n.t('warningMessage', checkTrOpt))
      }
    } catch (e) {
      showErrors(e, I18n.t('errorMessage', checkTrOpt))
    }
    setLoading(false)
  }, [getFormValues, checkAvailableTemplates])
  if (!visible) return null
  return (
    <Alert
      style={{ marginBottom: 14 }}
      message={I18n.t('title', checkTrOpt)}
      description={
        <TemplateCheckDesc>
          <ul>{renderListFragmentFromArray(I18n.t('body', checkTrOpt))}</ul>
          <Button
            type='primary' icon='safety'
            {...{ loading, onClick, disabled }}
          >
            {I18n.t('button', checkTrOpt)}
          </Button>
        </TemplateCheckDesc>
      }
      type='info'
      showIcon
    />
  )
})

const CourseHeader = React.forwardRef(({ visible }, ref) => (
  visible
    ? (
      <div style={{ marginTop: 24 }}>
        <h4>{I18n.t('uPhish.createSimulationSchedule.inlineTrainingTitle')}</h4>
        <p className='base-font'>{I18n.t('uPhish.autoPhish.inlineTrainingInfo')}</p>
      </div>
    ) : null
))

const preSaveWarningModalSpec = {
  icon: <Icon type='exclamation-circle' />,
  get okText () { return I18n.t('common.continue') },
  get cancelText () { return I18n.t('common.cancel') }
}

const AutoPhishForm = ({ autoPhish, landingPageOptions, groupTreeOptions, workingHours, updateSettings, refetchQueries, domainOptions, defaultTenant, uLearn: uLearnEnabled, forwardedRef, disableSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [subjectOptions, setSubjectOptions] = useState([])
  const [courseOptions, setCourseOptions] = useState([])
  const [, setForceRender] = useState(null)

  const { contentLocales = [], uPhishDeliveryMethod = 'smtp', messageInjection } = useGlobalState(
    state => ({
      ..._pick(selectors.session.get(state), ['contentLocales']),
      ..._pick(selectors.settings.get(state), ['uPhishDeliveryMethod', 'messageInjection'])
    })
  )

  const form = useRef(null)
  useEffect(() => {
    if (form && form.current) {
      form.current.replaceValues(autoPhish, true)
    }
  }, [autoPhish, defaultTenant, form])
  const getFormValues = useCallback(() => {
    if (form && form.current) {
      return form.current.values
    }
  }, [form])
  const onChange = id => {
    if (id === 'enabled') {
      window.dispatchEvent(new Event('resize'))
    }
    setForceRender(Date.now())
  }
  const hasChanged = useCallback(() => form.current?.hasChanged() === true, [form])

  useImperativeHandle(forwardedRef, () => ({
    hasChanged: hasChanged
  }), [hasChanged])

  const { data: coursesData } = useQuery(GET_COURSES, {
    variables: { excludeGapAnalysis: true, restrictToPlan: true, withCompany: true },
    skip: !uLearnEnabled
  })

  useEffect(() => {
    const { courses = [] } = coursesData || {}
    setSubjectOptions([
      { value: '{all}', label: I18n.t('common.all') },
      ...COURSE_SUBJECT_OPTIONS.filter(({ value }) => courses.some(course => course.subject === value))
    ])
    setCourseOptions(courses
    // Sort by difficulty then sort by displayName
      .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
      .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))
    )
  }, [coursesData])

  const areWorkingHoursValid = useMemo(() => {
    const { start: startStr, end: endStr } = workingHours || {}
    let areWorkingHoursValid = isTimeValue(startStr) && isTimeValue(endStr)
    if (areWorkingHoursValid) {
      const workingStartToday = moment(startStr, 'HH:mm')
      const workingEndToday = moment(endStr, 'HH:mm')
      areWorkingHoursValid = workingStartToday.isBefore(workingEndToday, 'minutes')
    }
    return areWorkingHoursValid
  }, [workingHours])

  const client = useApolloClient()
  const checkAvailableTemplates = useCallback(async (autoPhishSettings) => {
    const result = await client.query({
      query: ARE_AUTO_PHISH_TEMPLATES_AVAILABLE,
      variables: { autoPhishSettings, defaultTenant },
      fetchPolicy: 'no-cache'
    })
    return _get(result, 'data.areAutoPhishTemplatesAvailable')
  }, [defaultTenant, client])

  const showDeliveryMethod = useMemo(() => !defaultTenant && isMessageInjectionAuthorised({ messageInjection }), [messageInjection, defaultTenant])
  const deliveryMethodOptions = useMemo(() => {
    return [
      {
        value: 'default',
        get label () {
          return I18n.t('defaultDeliveryMethod', { ...trOpt, deliveryMethod: DELIVERY_METHODS[uPhishDeliveryMethod] })
        }
      },
      ...DELIVERY_METHOD_OPTIONS
    ]
  }, [uPhishDeliveryMethod])

  const fields = useMemo(() => {
    const localesProps = {
      label: I18n.t('locales', trOpt),
      options: CONTENT_LANGUAGE_OPTIONS,
      placeholder: I18n.t('common.fields.languagesPlaceHolder')
    }
    return [
      {
        id: 'enabled',
        label: I18n.t('enableAutoPhish', trOpt),
        type: 'switch',
        defaultValue: autoPhish.enabled || false
      },
      {
        id: 'howOften',
        label: I18n.t('howManyWeeksBetweenSimulations', trOpt),
        type: 'number',
        required: true,
        max: 52,
        min: 1,
        defaultValue: autoPhish.howOften,
        visible: (values) => values.enabled === true
      },
      {
        id: 'workingHours',
        label: I18n.t('uPhish.common.workingHours'),
        type: 'checkbox',
        mutateValue: values => areWorkingHoursValid && (values || false),
        defaultValue: areWorkingHoursValid && autoPhish.workingHours,
        visible: (values) => values.enabled === true,
        disabled: !areWorkingHoursValid
      },
      {
        id: 'deliveryMethod',
        label: I18n.t('preferredDeliveryMethod', commonTrOpt),
        type: 'select',
        options: deliveryMethodOptions,
        defaultValue: autoPhish.deliveryMethod || 'default',
        visible: showDeliveryMethod ? ({ enabled }) => enabled === true : false,
        extra: (
          <SettingsFormFieldExtra
            copy={[
              I18n.t('preferredDeliveryMethodExtra', trOpt),
              I18n.t('messageInjectionDisclaimer', commonTrOpt)
            ]}
          />
        )
      },
      {
        id: 'excludeGroups',
        label: I18n.t('excludeGroupsFromReceivingSimulations', trOpt),
        type: 'treeSelect',
        treeData: groupTreeOptions,
        multiple: true,
        defaultValue: autoPhish.excludeGroups || null,
        visible: defaultTenant ? false : ({ enabled }) => enabled === true
      },
      {
        id: 'domainAllowList',
        label: I18n.t('domainAllowList', trOpt),
        type: 'multiSelect',
        extra: I18n.t('domainAllowListExtra', trOpt),
        options: domainOptions,
        defaultValue: autoPhish.domainAllowList,
        visible: ({ enabled }) => enabled === true
      },
      {
        id: 'useCompanyLocales',
        label: I18n.t('useCompanyLocales', trOpt),
        type: 'switch',
        defaultValue: autoPhish ? autoPhish.useCompanyLocales === true : (contentLocales.length > 0),
        visible: (values) => values.enabled === true,
        extra: (
          <span
            dangerouslySetInnerHTML={{
              __html: I18n.t('useCompanyLocalesExtra', {
                ...trOpt,
                languagesLink: renderToString(
                  <a
                    className='usecure-manual-rr-link'
                    href={decodeURIComponent(generatePath(defaultTenant ? routes.DEFAULT_CUSTOMER_SETTINGS_SCREEN : routes.SETTINGS_SCREEN, { screenPath: 'language' }))}
                  >
                    {I18n.t('common.language')}
                  </a>
                )
              })
            }}
          />
        )
      },
      {
        id: 'locales',
        type: 'multiSelect',
        ...localesProps,
        required: LOCALES_REQUIRED ? values => values.useCompanyLocales !== true : false,
        defaultValue: autoPhish.locales,
        visible: (values) => values.enabled === true && values.useCompanyLocales !== true
      },
      // This custom component is a dummy locales field listing company.contentLocales in a disabled state when useCompanyLocales is true
      // Saves writing some weird logic to replicate this with the actual field
      {
        id: 'companyContentLocales',
        type: 'custom',
        ...localesProps,
        contentLocales,
        component: DisabledLocalesSelect,
        visible: (values) => values.enabled === true && values.useCompanyLocales === true
      },
      {
        id: 'templateFiltering',
        label: I18n.t('templateFiltering', trOpt),
        type: 'select',
        className: 'auto-phish-template-filter-select',
        options: [{
          value: 'none', label: I18n.t('common.none')
        }, {
          value: 'include', label: I18n.t('include', trOpt)
        }, {
          value: 'exclude', label: I18n.t('exclude', trOpt)
        }],
        defaultValue: autoPhish.templateFiltering || 'none',
        visible: ({ enabled }) => enabled === true
      },
      {
        id: 'includeTemplates',
        label: I18n.t('includeTemplates', trOpt),
        type: 'multiSelect',
        required: true,
        options: landingPageOptions,
        defaultValue: autoPhish.includeTemplates || null,
        visible: ({ enabled, templateFiltering }) => enabled === true && templateFiltering === 'include',
        extra: I18n.t('includeTemplatesExtra', trOpt),
        allowClear: true,
        maxTagTextLength: 60
      },
      {
        id: 'excludeTemplates',
        label: I18n.t('excludeTemplates', trOpt),
        type: 'multiSelect',
        required: true,
        options: landingPageOptions,
        defaultValue: autoPhish.excludeTemplates || null,
        visible: ({ enabled, templateFiltering }) => enabled === true && templateFiltering === 'exclude',
        allowClear: true,
        maxTagTextLength: 60
      },
      {
        id: 'templateCheckAlert',
        type: 'custom',
        component: TemplateCheckAlert,
        disabled: loading,
        checkAvailableTemplates,
        getFormValues,
        visible: ({ enabled }) => enabled === true
      }, {
        type: 'custom',
        component: CourseHeader,
        visible: !uLearnEnabled ? false : ({ enabled }) => enabled === true
      }, {
        id: 'courseSubject',
        label: I18n.t('common.fields.subject'),
        type: 'select',
        defaultValue: '{all}',
        options: subjectOptions,
        visible: !uLearnEnabled ? false : ({ enabled }) => enabled === true
      }, {
        id: 'courseId',
        label: I18n.t('common.courseLabel'),
        type: 'select',
        linkField: 'courseSubject',
        placeholder: I18n.t('uPhish.createSimulationSchedule.coursePlaceholder'),
        options: courseOptions,
        mutateValue: values => values || null,
        defaultValue: autoPhish.courseId || null,
        visible: !uLearnEnabled ? false : ({ enabled }) => enabled === true
      }
    ]
  }, [
    autoPhish, landingPageOptions, groupTreeOptions, areWorkingHoursValid, domainOptions, defaultTenant, contentLocales,
    loading, checkAvailableTemplates, getFormValues, uLearnEnabled, subjectOptions, courseOptions, deliveryMethodOptions, showDeliveryMethod
  ])

  const mutateValues = useCallback(values => {
    const mutatedValues = _omit(values, ['companyContentLocales', 'templateCheckAlert', 'courseSubject'])
    if (values.useCompanyLocales) mutatedValues.locales = null
    if (!showDeliveryMethod) mutateValues.deliveryMethod = 'default'
    return mutatedValues
  }, [showDeliveryMethod])

  const [saveSettings] = useMutation(defaultTenant ? UPDATE_DEFAULT_TENANT_SETTING : UPDATE_COMPANY_SETTING, { refetchQueries })
  const onSuccess = useCallback(result => {
    message.success(I18n.t('successfullyUpdatedAutoPhishSettings', trOpt))
    if (_isFunction(updateSettings)) {
      const company = _get(result, ['data', defaultTenant ? 'updateCompanySetting' : 'updateDefaultTenantSetting'])
      if (company) {
        const settings = getSettingsFromCompany(company)
        if (settings) {
          updateSettings(settings)
        }
      }
    }
    if (form.current) form.current.resetFields()
  }, [updateSettings, defaultTenant])
  const onSubmit = useCallback(async (values, errors, variables) => {
    try {
      setLoading(true)

      if (values.enabled) {
        let completeSave = true
        if (values.useCompanyLocales === true && !(_isArray(contentLocales) && !_isEmpty(contentLocales))) {
          let content = I18n.t('noLanguagesPrompt.content', { ...trOpt, useCompanyLocales: I18n.t('useCompanyLocales', trOpt) })
          content = _isArray(content) ? [...content] : [content] // Paranoid code to avoid modifying the translation or an error if this is a string
          completeSave = await modalConfirmAsync({
            ...preSaveWarningModalSpec,
            title: I18n.t('noLanguagesPrompt.title', trOpt),
            content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
          })
        }

        if (completeSave) {
          const available = await checkAvailableTemplates(values)
          if (!available) {
            let content = I18n.t('noTemplatesPrompt.content', trOpt)
            content = _isArray(content) ? [...content] : [content] // Paranoid code to avoid modifying the translation or an error if this is a string
            if (defaultTenant) {
              // MSP Default Customer Settings - Show warning confirm dialog as the settings may work for a customer
              content.push(I18n.t('noTemplatesPrompt.defaultTenantWarning', trOpt))
              completeSave = await modalConfirmAsync({
                ...preSaveWarningModalSpec,
                title: I18n.t('noTemplatesPrompt.title', trOpt),
                content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
              })
            } else {
              // Customer and NFR Settings - Show error dialog as these settings will result in failed auto phish runs
              completeSave = false
              await modalErrorAsync({
                title: I18n.t('noTemplatesPrompt.errorTitle', trOpt),
                content: <DialogModalContent>{renderParagraphsFragmentFromArray(content)}</DialogModalContent>
              })
            }
          }
        }
        if (!completeSave) {
          throw new Error(I18n.t('cancelledMessage', trOpt))
        }
      }
      const results = await saveSettings({ variables })
      onSuccess(results)
    } finally {
      setLoading(false)
    }
  }, [checkAvailableTemplates, saveSettings, onSuccess, defaultTenant, contentLocales])

  return (
    <>
      <LoadingBlock loading={loading} fullScreen={false} />
      <AutoPhishMutationForm
        headerId='settings-auto-phish-header'
        title={I18n.t('title', trOpt)}
        desc={renderParagraphsFragmentFromArray(I18n.t('description', trOpt))}
        formProps={{
          ref: form,
          fields,
          variables: { settingId: 'autoPhish' },
          valuesObjectName: 'setting',
          mutateValues,
          onChange,
          onSubmit,
          refetchQueries,
          failureMessage: I18n.t('anErrorOccurredAttemptingToUpdate', trOpt),
          skipResetFieldsOnSubmit: true
        }}
        {...{ disableSubmit }}
        hasChanged={hasChanged()}
      />
    </>
  )
}

// This view is now part of settings so it no longer loads company settings itself instead relying on the global state values provided by the Settings view
const AutoPhish = React.forwardRef(({ defaultTenant, settings: companySettings, updateSettings, contentLocales: companyContentLocales, disableSubmit }, ref) => {
  const { loading: groupTreeLoading, error: groupsLoadError, data: groupsData = {} } = useQuery(GET_GROUP_TREE)
  const { loading: domainsLoading, error: domainsError, data: domainsData } = useQuery(GET_SIMULATION_DOMAINS)
  const { loading: landingPagesLoading, error: landingPagesError, data: landingPagesData } = useQuery(GET_SIMULATION_LANDING_PAGE_TEMPLATES, {
    variables: { restrictToPlan: true }
  })

  const autoPhish = _get(companySettings, 'autoPhish') || {}
  const uLearn = _get(companySettings, 'uLearn', false)
  const workingHours = _get(companySettings, 'workingHours') || {}

  const groupTreeOptions = useMemo(
    () => buildTree(groupsData?.groupTree ?? []),
    [groupsData]
  )

  const landingPageOptions = useMemo(
    () => (landingPagesData?.simulationLandingPages ?? []).map(({ id, name, locales }) => {
      const languageNames = _isArray(locales) && !_isEmpty(locales) ? locales.map(l => CONTENT_LANGUAGE_NAMES_BY_CODE[l]).sort((a, b) => a.localeCompare(b)) : []
      const languages = ` - ${_isEmpty(languageNames) ? I18n.t('common.none') : languageNames.join(', ')}`
      return {
        value: id,
        label: `${name}${languages}`
      }
    }),
    [landingPagesData]
  )
  const domainOptions = useMemo(
    () => (domainsData?.simulationDomains ?? []).map(domain => ({
      value: domain,
      label: domain
    })),
    [domainsData]
  )

  let body = null
  if (groupsLoadError) {
    body = <ErrorAlerts error={groupsLoadError} defaultError={I18n.t('common.groupsLoadError')} />
  } else if (landingPagesError) {
    body = <ErrorAlerts error={landingPagesError} defaultError={I18n.t('theLandingPageListCouldNotBeLoaded', trOpt)} />
  } else if (domainsError) {
    body = <ErrorAlerts error={domainsError} defaultError={I18n.t('domainsLoadError', trOpt)} />
  } else if (groupTreeLoading || landingPagesLoading || domainsLoading) {
    body = <LoadingBlock fullScreen={false} loading />
  }
  if (body) {
    return (
      <SettingsFormContainer>
        <SettingsHeader>
          <IntercomHeader Size='h1' id='auto-phish-header'>{I18n.t('title', trOpt)}</IntercomHeader>
          <DescriptionContainer>{renderParagraphsFragmentFromArray(I18n.t('description', trOpt))}</DescriptionContainer>
        </SettingsHeader>
        {body}
      </SettingsFormContainer>
    )
  }

  return (
    <AutoPhishForm
      {...{ autoPhish, uLearn, groupTreeOptions, landingPageOptions, workingHours, updateSettings, domainOptions, defaultTenant, disableSubmit, forwardedRef: ref }}
      contentLocales={(defaultTenant ? _get(companySettings, 'contentLocales') : companyContentLocales) || []}
    />
  )
})

export default AutoPhish
