import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Icon, Tooltip } from 'antd'
import I18n from 'i18n-js'

import LanguageDropdown from '../common/LanguageDropdown'
import { LANGUAGES } from '../../constants/languages'

const _MultiLocaleLabel = ({ label, className }) => (
  <span {...{ className }}>
    {label}
    <Tooltip title={I18n.t('settings.emailSettingsForm.multilingualTooltip')}>
      <Icon type='global' />
    </Tooltip>
  </span>
)
export const MultiLocaleLabel = styled(_MultiLocaleLabel)`
  .anticon {
    margin: 0 3px
  }
`

const _LanguageSelector = React.forwardRef(({ className, value, visible, id, onChange }, ref) => {
  const onLanguageChange = useCallback(code => onChange(id, code), [onChange, id])
  return (
    visible
      ? (
        <div {...{ className }}>
          <LanguageDropdown {...{ value }} onChange={onLanguageChange} languages={LANGUAGES} />
        </div>
      )
      : null
  )
})
const LanguageSelector = styled(_LanguageSelector)`
  text-align: right;
`

export default LanguageSelector
