/* global localStorage */
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Icon, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter, generatePath } from 'react-router'
import styled, { css, withTheme } from 'styled-components'
import _debounce from 'lodash/debounce'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'
import I18n from 'i18n-js'
import { NetworkStatus } from 'apollo-client'

import DashboardStatistic from '../../components/Dashboard/DashboardStatistic'
import { UPHISH_SIMULATION_PERFORMANCE } from '../../components/Queries/Reports'
import UPhishHeader from '../Reports/uPhish/UPhishHeader'
import { SimulationsTable, SimulationsActions } from '../../components/uPhish'
import routes from '../../constants/routes'
import { RISK_REPORT_STATUS_UPHISH } from '../../constants/company'
import { WRITABLE_SIMULATION_ACTIONS } from '../../constants/actions'
import { ContentWrap, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts } from '../../components/common'
import deleteSimulationConfirm from '../../components/Modals/DeleteSimulationConfirm'
import sendAllSimulationEmailsConfirm from '../../components/Modals/SendAllSimulationEmailsConfirm'
import riskReportSimulationConfirm from '../../components/Modals/RiskReportSimulationConfirm'
import SendTestSimulationEmailConfirm from '../../components/Modals/SendTestSimulationEmailConfirm'
import { downloadSimulationReport } from '../../helpers'
import { openSimulationLandingPage } from '../../helpers/uPhish'
import { connect, withRefreshSessionState } from '../../hocs'
import selectors from '../../state/selectors'
import { togglePollingBasedOnSelectedIds, updateSelectedIdsOnRecordSetUpdate, disableActions } from '../../helpers/listPages'
import { QUERY_POLL_INTERVAL } from '../../constants/apolloClient'
import { formatDateTime } from '../../helpers/datetime'

const trOpt = { scope: 'uPhish.simulations' }

const UPhishReportGrid = styled.div`
  display: grid;
  ${({ showPhishAlert }) =>
  showPhishAlert
  ? css`
  grid-template-areas: 
          "topbar topbar topbar topbar topbar" 
          "opened visited compromised reported total-simulations"
          "table table table table table";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  `
  : css`
  grid-template-areas: 
          "topbar topbar topbar topbar" 
          "opened visited compromised total-simulations"
          "table table table table";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  `
  }
  grid-template-rows: auto 260px minmax(774px, auto);
  grid-gap: 25px;
`

const UPhishItem = styled.div`
  background-color: white;
  padding: 30px 40px;
`

const UPhishEventStatistic = ({ type, count, total, hoverTextKey, color }) => {
  return (
    <UPhishItem style={{ gridArea: type }}>
      <DashboardStatistic
        title={I18n.t(type, trOpt)}
        link='/uPhish/simulations'
        isGauge
        percentage={Math.round((count * 100) / total)}
        extraTitle={I18n.t('total', trOpt)}
        extraData={count}
        hoverText={I18n.t(hoverTextKey, { count, total, ...trOpt })}
        color={color}
      />
    </UPhishItem>
  )
}

const getSimulationActions = (simulation, { prospectStatus, prospectSimulation } = {}, disabledActions) => {
  const { id, status } = simulation
  const actions = [
    { key: 'viewSimulation', label: I18n.t('viewSimulations', trOpt) },
    { key: 'cloneSimulation', label: I18n.t('uPhish.common.cloneSimulation') },
    { key: 'deleteSimulation', label: I18n.t('deleteSimulation', trOpt) },
    { key: 'openLandingPage', label: I18n.t('uPhish.common.viewLandingPage') },
    { key: 'sendTestEmail', label: I18n.t('uPhish.common.sendTestEmail') }
  ]

  if (status !== 'finished') {
    actions.push({ key: 'sendEmails', label: I18n.t('sendAllEmailsNow', trOpt) })
  }

  if (status === 'pending') {
    actions.splice(1, 0, { key: 'editSimulation', label: I18n.t('editSimulation', trOpt) })
  } else {
    actions.splice(1, 0, { key: 'downloadReport', label: I18n.t('downloadSimulationReport', trOpt) })
  }

  if (prospectStatus && RISK_REPORT_STATUS_UPHISH.includes(prospectStatus)) {
    const riskReportKey = id === prospectSimulation ? 'detachFromRiskReport' : 'attachToRiskReport'
    actions.push({ key: riskReportKey, label: I18n.t(riskReportKey, trOpt) })
  }

  return disableActions(actions, disabledActions)
}

const SimulationsView = ({
  simulations: rawSimulations = [],
  searchFilterText = '',
  updateSearchFilterText = () => {},
  history,
  loading,
  error,
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  routeSimulationId,
  refreshSessionState,
  prospectStatus,
  prospectSimulation,
  showPhishAlert,
  companyId,
  setPollingEnabled,
  planValid,
  excludeInactiveUsers
}) => {
  const [selectedSimulationIds, updateSelectedSimulationIds] = useState([])
  const tableRef = useRef(null)
  const sendTestModalRef = useRef(null)

  const disabledActions = useMemo(() => !planValid ? WRITABLE_SIMULATION_ACTIONS : null, [planValid])

  const allSimulations = useMemo(() => {
    return rawSimulations.map((simulation) => {
      const { id, status, name, recipientCount: recipients, sentCount: sent, openCount: opened, visitCount: visited, compromiseCount: compromised, reportCount: reported, autoPhish, startDate, endDate, deliveryMethod } = simulation

      return {
        key: id,
        id,
        name,
        recipients,
        autoPhish,
        status,
        startDate,
        startDateStr: formatDateTime(startDate),
        endDate,
        endDateStr: endDate ? formatDateTime(endDate) : '',
        sent,
        opened,
        visited,
        compromised,
        reported,
        onRiskReport: Boolean(prospectSimulation && prospectSimulation === id),
        deliveryMethod,
        actions: getSimulationActions(simulation, { prospectStatus, prospectSimulation }, disabledActions)
      }
    })
  }, [rawSimulations, prospectStatus, prospectSimulation, disabledActions])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])
  const openSimulation = useCallback(simulationId => {
    if (tableRef.current) {
      tableRef.current.openSimulation(simulationId)
    }
  }, [tableRef])
  const openSendTest = useCallback(simulationId => {
    if (sendTestModalRef.current) {
      const { deliveryMethod } = allSimulations.find(s => s.id === simulationId) || {}
      sendTestModalRef.current.open({ id: simulationId, variables: { deliveryMethod } })
    }
  }, [sendTestModalRef, allSimulations])

  const performAction = useCallback((action, simulationIds) => {
    simulationIds = _isArray(simulationIds) ? simulationIds : [simulationIds]
    switch (action) {
      case 'viewSimulation':
        openSimulation(simulationIds[0])
        break
      case 'editSimulation':
        history.push(generatePath(routes.UPHISH_EDIT_SIM, { simulation_id: simulationIds[0] }))
        break
      case 'cloneSimulation':
        history.push(generatePath(routes.UPHISH_CLONE_SIM, { simulation_id: simulationIds[0] }))
        break
      case 'deleteSimulation':
        deleteSimulationConfirm({ simulationIds, companyId, refreshSessionState, prospectStatus, prospectSimulation, excludeInactiveUsers })
        break
      case 'sendEmails':
        sendAllSimulationEmailsConfirm({ simulationIds, excludeInactiveUsers })
        break
      case 'downloadReport':
        downloadSimulationReport(simulationIds[0])
        break
      case 'sendTestEmail':
        openSendTest(simulationIds[0])
        break
      case 'openLandingPage':
        openSimulationLandingPage(simulationIds[0])
        break
      case 'attachToRiskReport':
        riskReportSimulationConfirm({ simulationId: simulationIds[0], detach: false, refreshSessionState, excludeInactiveUsers })
        break
      case 'detachFromRiskReport':
        riskReportSimulationConfirm({ simulationId: simulationIds[0], detach: true, refreshSessionState, excludeInactiveUsers })
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [history, openSimulation, openSendTest, refreshSessionState, companyId, prospectStatus, prospectSimulation, excludeInactiveUsers])

  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedSimulationIds, allSimulations),
  [allSimulations])
  // Suspend query polling if rows are selected
  useEffect(() => togglePollingBasedOnSelectedIds(selectedSimulationIds, setPollingEnabled), [selectedSimulationIds, setPollingEnabled])

  const [simulations, setSimulations] = useState([])
  const applySimulationFilters = useCallback((searchFilterText) => {
    let simulations = allSimulations

    if (searchFilterText) {
      const filterFields = ['name']
      simulations = simulations.filter(simulation =>
        filterFields.some(field => {
          const value = simulation[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setSimulations(simulations)
  }, [allSimulations])
  const debouncedApplySimulationFilters = useCallback(_debounce(applySimulationFilters, 500), [applySimulationFilters])
  useEffect(() => {
    debouncedApplySimulationFilters(searchFilterText)
  }, [debouncedApplySimulationFilters, searchFilterText])

  const selectedSimulations = useMemo(() => {
    return selectedSimulationIds.map(simulationId => allSimulations.find(l => l.id === simulationId)).filter(simulation => !_isNil(simulation))
  }, [allSimulations, selectedSimulationIds])

  const actions = useMemo(() => {
    if (selectedSimulations.length === 1) {
      return getSimulationActions(selectedSimulations[0], { prospectStatus, prospectSimulation }, disabledActions)
    }

    const actions = [
      { key: 'deleteSimulation', label: I18n.t(selectedSimulations.length === 1 ? 'deleteSimulation' : 'deleteSimulations', trOpt) }
    ]

    const anyFinished = selectedSimulations.some(({ status }) => status === 'finished')
    if (!anyFinished) {
      actions.push({ key: 'sendEmails', label: I18n.t('sendAllEmailsNow', trOpt) })
    }

    return disableActions(actions, disabledActions)
  }, [selectedSimulations, prospectStatus, prospectSimulation, disabledActions])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForASimulation', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )
              case 'actions':
                return (
                  <SimulationsActions key={index} disabled={loading} {...{ selectedSimulationIds, performAction, actions }} />
                )
              case 'add':
                return (
                  <Link key={index} to={routes.UPHISH_CREATE_SIM}>
                    <Button disabled={!planValid} type='primary'><Icon type='plus' /> {I18n.t('uPhish.common.createSimulation')}</Button>
                  </Link>
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [searchFilterText, onSearchChange, selectedSimulationIds, performAction, actions, loading, planValid])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <ListHeader>
        {panelLeftComponent}
        {panelRightComponent}
        <SendTestSimulationEmailConfirm type='simulation' ref={sendTestModalRef} />
      </ListHeader>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('yourSimulationsCouldNotBeLoaded', trOpt)} />
          : (
            <SimulationsTable
              ref={tableRef}
              {...{
                loading,
                simulations,
                selectedSimulationIds,
                updateSelectedSimulationIds,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                history,
                routeSimulationId,
                performAction,
                showPhishAlert
              }}
            />
          )
      }
    </>
  )
}

const Simulations = props => {
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(null)

  const { history, userId, companyId, refreshSessionState, prospectStatus, prospectSimulation, phishAlertEnabled, phishAlert, planValid, theme, excludeInactiveUsersInReports } = props
  const routeSimulationId = _get(props, 'match.params.simulation_id')
  const showPhishAlert = phishAlertEnabled === true && phishAlert?.enabled === true

  const storageId = useMemo(() => `simulations|${companyId}|${userId}`, [userId, companyId])
  useEffect(() => {
    try {
      const simulationsFilterString = localStorage.getItem(storageId)
      if (simulationsFilterString) {
        const simulationsFilter = JSON.parse(simulationsFilterString)
        Object.keys(simulationsFilter).forEach(key => {
          const value = simulationsFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    let simulationsFilter = {}
    try {
      const simulationsFilterString = localStorage.getItem(storageId)
      if (simulationsFilterString) {
        simulationsFilter = JSON.parse(simulationsFilterString)
      }
      simulationsFilter = { ...simulationsFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(simulationsFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const [pollingEnabled, setPollingEnabled] = useState(true)
  const {
    loading: queryLoading, error, refetch, networkStatus,
    data = {
      simulationReport: {
        totalMetrics: {
          opened: 0,
          visited: 0,
          total: 0,
          compromised: 0,
          reported: 0
        },
        simulations: []
      }
    }
  } = useQuery(UPHISH_SIMULATION_PERFORMANCE, {
    notifyOnNetworkStatusChange: true,
    pollInterval: pollingEnabled ? QUERY_POLL_INTERVAL : 0,
    variables: {
      excludeInactiveUsers: excludeInactiveUsersInReports
    }
  })
  const loading = queryLoading && networkStatus !== NetworkStatus.poll

  const handleRefreshClick = useCallback(() => refetch(), [refetch])

  const {
    simulations, totalMetrics: {
      opened, visited, total, compromised, reported
    }
  } = data?.simulationReport || {}

  return (
    <ContentWrap>
      <UPhishReportGrid {...{ showPhishAlert }}>
        <UPhishHeader />
        <UPhishEventStatistic type='opened' count={opened} total={total} hoverTextKey='simulationsOpened' color={theme.simulationOpen} />
        <UPhishEventStatistic type='visited' count={visited} total={total} hoverTextKey='simulationsVisited' color={theme.simulationVisit} />
        <UPhishEventStatistic type='compromised' count={compromised} total={total} hoverTextKey='simulationsCompromised' color={theme.simulationCompromise} />
        {showPhishAlert && <UPhishEventStatistic type='reported' count={reported} total={total} hoverTextKey='simulationsReported' color={theme.simulationReport} />}
        <UPhishItem style={{ gridArea: 'total-simulations' }}>
          <DashboardStatistic
            title={I18n.t('totalSimulations', trOpt)}
            link='/uPhish/simulations'
            value={total}
            extraTitle={I18n.t('unopened', trOpt)}
            extraData={total - opened}
            hoverText={I18n.t('totalSimulationsSent', { count: total, ...trOpt })}
            isValue
          />
        </UPhishItem>
        <Card style={{ gridArea: 'table' }}>
          <ListHeader align='right'>
            <ListHeaderPanel align='right'>
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </ListHeaderPanel>
          </ListHeader>
          <SimulationsView
            {...{
              loading,
              error,
              simulations,
              searchFilterText,
              updateSearchFilterText,
              history,
              sorter,
              updateSorter,
              filters,
              updateFilters,
              pagination,
              updatePagination,
              routeSimulationId,
              refreshSessionState,
              prospectStatus,
              prospectSimulation,
              companyId,
              showPhishAlert,
              setPollingEnabled,
              planValid,
              excludeInactiveUsers: excludeInactiveUsersInReports
            }}
          />
        </Card>
      </UPhishReportGrid>
    </ContentWrap>
  )
}

export default compose(
  withRouter,
  withRefreshSessionState,
  withTheme,
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['userId', 'companyId', 'prospectStatus', 'prospectSimulation', 'planValid']),
      ..._pick(selectors.settings.get(state), ['phishAlertEnabled', 'phishAlert']),
      ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
    })
  )
)(Simulations)
