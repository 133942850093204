import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'
import moment from 'moment'
import { Button, message } from 'antd'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ListHeader, ListHeaderPanel, ListViewActions, ErrorAlerts, SearchBar } from '../common'
import UserBreachesTable from './UserBreachesTable'
import { searchRecords, disableActions } from '../../helpers/listPages'
import useLocalStorage from '../../hooks/useLocalStorage'
import { WRITABLE_UBREACH_ACTIONS } from '../../constants/actions'
import MarkResolvedConfirm from '../Modals/uBreach/MarkResolvedConfirm'

const trOpt = { scope: 'uBreach' }

const getRowActions = (disabledActions) => {
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const getSelectionActions = (selection, disabledActions) => {
  if (selection.length === 1) return getRowActions(disabledActions)
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', trOpt) }
  ]

  return disableActions(actions, disabledActions)
}

const UserBreachesView = ({
  companyId,
  userId, // Current user, from global state
  domain = null, // If set, only show breaches where email domain matches
  breachedUsers = [],
  breachedServices = [],
  error,
  loading,
  refetchQueries,
  handleRefreshClick,
  planValid,
  uBreachProEnabled,
  getPopupContainer
}) => {
  const [searchText, setSearchText] = useState('')
  const [allRecords, setAllRecords] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  const markResolvedConfirmRef = useRef(null)
  const openMarkResolvedConfirm = useCallback((records) => {
    if (markResolvedConfirmRef.current) {
      markResolvedConfirmRef.current.open(records)
    }
  }, [markResolvedConfirmRef])

  const disabledActions = useMemo(() => !planValid ? WRITABLE_UBREACH_ACTIONS : null, [planValid])

  const storageId = useMemo(() => `uBreach|userBreachesView|${companyId}|${userId}`, [userId, companyId])
  const tableStorageId = useMemo(() => `uBreach|userBreachesView|table|${companyId}|${userId}`, [userId, companyId])
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })

  const performAction = useCallback((action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.email === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.email))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }
    switch (action) {
      case 'markResolved':
        openMarkResolvedConfirm(
          actionRecords.reduce((acc, record) => {
            const { email, learnerId, breaches } = record
            const breachedUserRecords = breaches.map(({ name: breachName }) => ({ email, learnerId, breachName }))
            return [
              ...acc,
              ...breachedUserRecords
            ]
          }, [])
        )
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openMarkResolvedConfirm])

  const listActions = useMemo(() =>
    getSelectionActions(allRecords.filter(record => selectedIds.includes(record.key)), disabledActions),
  [selectedIds, allRecords, disabledActions])

  useEffect(() => {
    if (!updateFromLocalStorage || domain) return
    updateFromLocalStorage({
      searchText: setSearchText
    })
  }, [updateFromLocalStorage, storageId, domain])

  useEffect(() => {
    if (!updateLocalStorage || domain) return
    updateLocalStorage({
      searchText
    })
  }, [updateLocalStorage, searchText, domain])

  useEffect(() => {
    if (!breachedUsers.length) return
    const filteredBreachedUsers = domain ? breachedUsers.filter(user => user.domain === domain) : breachedUsers
    const users = filteredBreachedUsers.map(({ breaches, domain, learnerId, firstName = '', lastName = '', email }) => {
      return {
        domain,
        learnerId,
        learnerName: `${firstName || ''} ${lastName || ''}`.trim(),
        email,
        breachCount: breaches.length,
        resolvedCount: breaches.filter(breach => breach.resolved).length,
        lastBreachDate: breaches.reduce((acc, { name: breachName }) => {
          const { breachDate } = breachedServices.find(service => service.name === breachName) || {}
          if (!breachDate) return acc
          return moment(breachDate) > moment(acc) ? breachDate : acc
        }, 0),
        breaches
      }
    })

    setAllRecords(users)
  }, [domain, breachedUsers, breachedServices])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const records = allRecords.map(r => {
      const { email } = r
      const record = {
        ...r,
        key: email
      }
      if (uBreachProEnabled) {
        record.actions = getRowActions([
          ...(disabledActions || []),
          ...(record.breachCount === record.resolvedCount ? ['markResolved'] : [])
        ])
      }
      return record
    })

    return searchRecords({ records, searchText, searchKeys: ['email', 'learnerName'] })
  }, [allRecords, searchText, disabledActions, uBreachProEnabled])

  return (
    <>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourUserBreachesCouldNotBeLoaded', trOpt)} />
          : (
            <>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
                </ListHeaderPanel>
              </ListHeader>
              <ListHeader>
                <ListHeaderPanel align='left' />
                <ListHeaderPanel align='right'>
                  <SearchBar
                    placeholder={I18n.t('common.searchUserBreaches', trOpt)}
                    value={searchText}
                    allowClear
                    onChange={onSearchChange}
                  />
                  {uBreachProEnabled && (
                    <ListViewActions
                      actions={listActions}
                      performAction={performAction}
                      selectedIds={selectedIds}
                    />
                  )}
                </ListHeaderPanel>
              </ListHeader>
              <UserBreachesTable
                {...{
                  domain,
                  breachedServices,
                  breachedUsers,
                  refetchQueries,
                  handleRefreshClick,
                  performAction,
                  selectedIds,
                  getPopupContainer
                }}
                updateSelectedIds={setSelectedIds}
                userBreaches={records}
                showActions={uBreachProEnabled}
                // Setting storageId to null when domain is set to prevent saving table state in the modals for domain view
                storageId={!domain && tableStorageId}
                isAggregate
              />
            </>
          )
      }
      <MarkResolvedConfirm ref={markResolvedConfirmRef} {...{ refetchQueries }} type='user' />
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId', 'userId', 'planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(UserBreachesView)
