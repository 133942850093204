/* global localStorage */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Layout, message, Card, Button, Icon } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import compose from 'recompose/compose'
import I18n from 'i18n-js'

import CourseTable from '../components/Courses/CourseTable'
import routes from '../constants/routes'
import { GET_COURSES } from '../components/Queries/Courses'
import CoursesActions from '../components/Courses/CoursesActions'
import DeleteCourseConfirm from '../components/Modals/DeleteCourseConfirm'
import DuplicateCourseModal from '../components/Courses/DuplicateCourseModal'
import { connect, withConsumer } from '../hocs'
import { ContentWrap, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts } from '../components/common'
import { getSessionAndSettings } from '../state/selectors'
import { COURSE_SUBJECTS } from '../constants/courses'
import { disableActions, updateSelectedIdsOnRecordSetUpdate } from '../helpers/listPages'
import { isRouteAllowed } from '../helpers'
import { getCourseActions } from '../helpers/courses'
import { COURSE_BUILDER_ONLY_ROLES } from '../constants/roles'
import { WRITABLE_COURSE_ACTIONS } from '../constants/actions'
import IntercomHeader from '../components/IntercomHeader'

const trOpt = { scope: 'courses' }

const Content = Layout.Content

const CoursesView = ({
  loading, error, courses: rawCourses = [], history,
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  showOwner = false,
  searchFilterText = '',
  updateSearchFilterText = () => {},
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  role,
  planValid
}) => {
  const [selectedCourseIds, updateSelectedCourseIds] = useState([])
  const [showDuplicateCourseOpen, updateDuplicateCourseModal] = useState(false)
  const [showDeleteCourse, updateDeleteCourseModal] = useState(false)
  const [singleCourse, updateSingleCourse] = useState(null)

  const disabledActions = useMemo(() => !planValid ? WRITABLE_COURSE_ACTIONS : null, [planValid])

  const allCourses = useMemo(() =>
    rawCourses.map((course) => ({
      key: course.id,
      id: course.id,
      name: course.name,
      difficulty: course.difficulty,
      subject: course.subject,
      subjectText: COURSE_SUBJECTS[course.subject] || course.subject,
      companyId: _get(course, 'company.id') || 'usecure',
      companyName: _get(course, 'company.name') || I18n.t('common.managedByUsecure'),
      locale: course.locale,
      locales: course.availableLocales || [],
      actions: getCourseActions({ role, disabledActions })
    })),
  [rawCourses, role, disabledActions])
  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedCourseIds, allCourses),
  [allCourses])

  const [courses, setCourses] = useState([])
  const applyCoursesFilters = useCallback(searchFilterText => {
    let courses = [...allCourses]

    if (searchFilterText) {
      const filterFields = ['name']
      courses = courses.filter(course =>
        filterFields.some(field => {
          const value = course[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setCourses(courses)
  }, [allCourses])
  const debouncedApplyCoursesFilters = useCallback(_debounce(applyCoursesFilters, 500), [applyCoursesFilters])
  useEffect(() => {
    debouncedApplyCoursesFilters(searchFilterText)
  }, [debouncedApplyCoursesFilters, searchFilterText])

  const actionCourseIds = useMemo(() => singleCourse ? [singleCourse] : selectedCourseIds, [singleCourse, selectedCourseIds])
  const actionCourses = useMemo(() => actionCourseIds.map(courseId => allCourses.find(c => c.id === courseId)), [actionCourseIds, allCourses])
  const actions = useMemo(() => {
    let actions = []

    if (selectedCourseIds.length === 1) {
      actions = getCourseActions({ role, disabledActions })
    } else if (selectedCourseIds.length > 1 && !COURSE_BUILDER_ONLY_ROLES.includes(role)) {
      // Exclude delete action from course-builder roles
      actions.push(
        { key: 'deleteCourse', label: I18n.t('deleteCourses', trOpt), icon: 'delete' }
      )
    }

    return disableActions(actions, disabledActions)
  }, [selectedCourseIds, role, disabledActions])

  const performActions = useCallback((action, courseIds) => {
    updateSingleCourse(null)
    switch (action) {
      case 'editCourse':
        history.push(generatePath(routes.BUILDER_EDIT, { course_id: courseIds[0] }))
        break
      case 'editSlides':
        history.push(generatePath(routes.BUILDER_EDIT_SLIDES, { course_id: courseIds[0] }))
        break
      case 'previewCourse':
        window.open(generatePath(routes.BUILDER_PREVIEW_COURSE, { course_id: courseIds[0] }), '_blank')
        break
      case 'duplicateCourse':
        updateDuplicateCourseModal(true)
        break
      case 'deleteCourse':
        updateDeleteCourseModal(true)
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [updateDuplicateCourseModal, updateDeleteCourseModal, updateSingleCourse, history])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForACourse', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )
              case 'actions':
                return (
                  <CoursesActions
                    key={index} selectedCourseIds={selectedCourseIds} actions={actions}
                    performAction={performActions}
                  />
                )
              case 'add':
                return (
                  <Link key={index} to={routes.BUILDER_CREATE}>
                    <Button disabled={!planValid} type='primary'>
                      <Icon type='plus' /> {I18n.t('createCourse', trOpt)}
                    </Button>
                  </Link>
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [onSearchChange, searchFilterText, selectedCourseIds, actions, performActions, planValid])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <DuplicateCourseModal
        visible={showDuplicateCourseOpen} setVisible={updateDuplicateCourseModal}
        courses={actionCourses}
      />
      <DeleteCourseConfirm
        visible={showDeleteCourse} setVisible={updateDeleteCourseModal}
        courseIds={actionCourseIds}
      />
      <>
        <ListHeader>
          {panelLeftComponent}
          {panelRightComponent}
        </ListHeader>
        <ErrorAlerts {...{ error }} />
        <CourseTable
          {...{
            courses,
            selectedCourseIds,
            updateSelectedCourseIds,
            updateSingleCourse,
            updateDuplicateCourseModal,
            updateDeleteCourseModal,
            loading,
            showOwner,
            sorter,
            updateSorter,
            filters,
            updateFilters,
            pagination,
            updatePagination,
            role
          }}
        />
      </>
    </>
  )
}

const Courses = ({ client, history, session, settings }) => {
  const { userId, companyId, role, planValid } = session || {}
  const showOwner = role === 'usecure-admin'
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [filters, updateFilters] = useState(showOwner ? { companyName: ['usecure'] } : null)

  const storageId = useMemo(() => `courses|${companyId}|${userId}`, [userId, companyId])
  useEffect(() => {
    try {
      const simulationsFilterString = localStorage.getItem(storageId)
      if (simulationsFilterString) {
        const simulationsFilter = JSON.parse(simulationsFilterString)
        Object.keys(simulationsFilter).forEach(key => {
          const value = simulationsFilter[key]
          switch (key) {
            case 'searchFilterText':
              updateSearchFilterText(value)
              break
            case 'filters':
              updateFilters(value)
              break
            case 'sorter':
              updateSorter(value)
              break
            case 'pagination':
              updatePagination(value)
              break
            default:
              break
          }
        })
      }
    } catch (e) {}
  }, [storageId])

  const updateLocalStorage = useCallback((data = {}) => {
    let simulationsFilter = {}
    try {
      const simulationsFilterString = localStorage.getItem(storageId)
      if (simulationsFilterString) {
        simulationsFilter = JSON.parse(simulationsFilterString)
      }
      simulationsFilter = { ...simulationsFilter, ...data }
      localStorage.setItem(storageId, JSON.stringify(simulationsFilter))
    } catch (e) {}
  }, [storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const { loading, error, data } = useQuery(GET_COURSES, {
    variables: { restrictToOwn: true, withCompany: showOwner }
  })
  const { courses = [] } = data || {}

  const panelRight = ['search']
  if (!COURSE_BUILDER_ONLY_ROLES.includes(role)) {
    panelRight.push('actions')
  }
  if (isRouteAllowed(session, settings, routes.BUILDER_CREATE)) {
    panelRight.push('add')
  }

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='course-builder-header'>{I18n.t('common.uLearn')} - {I18n.t('courseBuilder', trOpt)}</IntercomHeader>
        <Layout>
          <Content>
            <CoursesView {...{
              loading,
              error,
              panelRight,
              courses,
              client,
              showOwner,
              searchFilterText,
              updateSearchFilterText,
              history,
              sorter,
              updateSorter,
              filters,
              updateFilters,
              pagination,
              updatePagination,
              role,
              planValid
            }}
            />
          </Content>
        </Layout>
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withConsumer,
  connect(state => getSessionAndSettings(state))
)(Courses)
