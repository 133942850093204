import React, { useState, useCallback, useEffect } from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Alert, Button, Card, Form, message } from 'antd'
import styled, { withTheme } from 'styled-components'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import I18n from 'i18n-js'

import { LoadingBlock, ErrorAlerts } from '../components/common'
import { ADD_PAYMENT_CARD, GET_PAYMENT_DETAILS } from '../components/Queries/Companies'
import { GET_ME } from '../components/Queries/Users'
import { CURRENCIES_INFO } from '../constants/billing'
import { showErrors, renderToString } from '../helpers'
import { StartStripe } from '../helpers/stripe'
import { withRefreshSessionState } from '../hocs'

const trOpt = { scope: 'paymentSettings' }
// This class doesn't call loadStripe until its getPromise method is called
const startStripe = new StartStripe()

const PaymentSettingsWrap = styled.div`
  position: relative;
`

const PaymentSettingsSection = styled.div`
  margin-bottom: 30px;
`

const CardDetailsPlaceholder = styled.div`
  height: 75px;
`

const CardDetailsAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`
const CardDetailsFormContainer = styled.div`
  width: 50%;
  max-width: 460px;
`

const CardDetailsFormButtons = styled.div`
  .ant-btn {
    margin-right: 10px;
  }
`

const CardDetailsForm = compose(
  withTheme
)(
  ({
    status, theme, loading = false, updateLoading = () => {}, onComplete = () => {}, refreshSessionState = () => {}
  }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [allowSubmit, setAllowSubmit] = useState(false)
    const [ready, setReady] = useState(false)
    const [complete, setComplete] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [executeAddPayment] = useMutation(ADD_PAYMENT_CARD, {
      refetchQueries: [
        { query: GET_ME },
        { query: GET_PAYMENT_DETAILS }
      ]
    })

    const handleCancel = useCallback(() => onComplete(), [onComplete])
    const handleReady = useCallback(() => {
      setReady(true)
      updateLoading(false)
    }, [updateLoading])
    const handleChange = useCallback((e) => {
      const { error, complete } = e
      setComplete(complete)
      setErrorMessage(error ? error.message : '')
    }, [])

    const addPaymentCard = useCallback(async token => {
      updateLoading(true)
      try {
        await executeAddPayment({
          variables: { token }
        })
        await refreshSessionState()
        message.success(I18n.t('cardDetailsAdded', trOpt))
        onComplete('loading')
      } catch (e) {
        showErrors(e, I18n.t('failedToAddCardDetails', trOpt))
      }
      updateLoading(false)
    }, [updateLoading, onComplete, executeAddPayment, refreshSessionState])

    const handleSubmit = useCallback(async evt => {
      evt.preventDefault()
      if (!allowSubmit) {
        return
      }

      if (stripe && elements) {
        const fullToken = await stripe.createToken(elements.getElement(CardElement))
        const token = _get(fullToken, 'token.id')
        if (token) {
          await addPaymentCard(token)
        } else {
          message.error(I18n.t('anErrorHasOccurredSending', trOpt))
        }
      } else {
        // Stripe JS hasn't loaded yet - a user shouldn't be able to trigger this
        message.error(I18n.t('pleaseWaitTheFormIsReady', trOpt))
      }
    }, [addPaymentCard, stripe, allowSubmit, elements])

    useEffect(() => setAllowSubmit(!loading && ready && complete), [loading, ready, complete])
    // Set loading on mount
    useEffect(() => updateLoading(true), [updateLoading])

    const isUpdate = status === 'update'

    return (
      <CardDetailsFormContainer>
        <Form onSubmit={handleSubmit}>
          <Form.Item validateStatus={errorMessage ? 'error' : undefined} help={errorMessage}>
            <CardElement
              style={{
                base: {
                  fontFamily: theme.baseFont,
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.65)',
                  letterSpacing: 'normal',
                  '::placeholder': {
                    color: '#C8C8C8'
                  }
                },
                invalid: {
                  color: theme.red
                }
              }}
              onChange={handleChange}
              onReady={handleReady}
            />
          </Form.Item>
          <CardDetailsFormButtons>
            <Button type='primary' loading={loading} htmlType='submit' disabled={!allowSubmit}>{isUpdate ? I18n.t('updateCard', trOpt) : I18n.t('saveCard', trOpt)}</Button>
            {isUpdate ? <Button type='danger' disabled={loading || !ready} onClick={handleCancel}>{I18n.t('common.cancel')}</Button> : null}
          </CardDetailsFormButtons>
        </Form>
      </CardDetailsFormContainer>
    )
  }
)

const CardDetails = ({ loading, queryLoading, updateLoading, card, refreshSessionState }) => {
  const [ready, updateReady] = useState(false)
  const [initialLoading, updateInitialLoading] = useState(!_isUndefined(card))
  const [status, updateStatus] = useState('initializing')
  useEffect(() => {
    if (queryLoading && !initialLoading) {
      updateInitialLoading(true)
      updateReady(false)
    } else if (initialLoading && !queryLoading && !ready) {
      updateReady(true)
    }
  }, [queryLoading, initialLoading, ready, updateInitialLoading, updateReady])
  useEffect(() => {
    if (queryLoading || !ready) {
      updateStatus('initializing')
    } else if (card) {
      updateStatus('view')
      updateLoading(false)
    } else if (ready) {
      updateStatus('new')
    }
  }, [queryLoading, ready, card, updateStatus, updateLoading])

  const onUpdateClick = useCallback(() => {
    updateStatus('update')
  }, [updateStatus])

  let content = (
    <CardDetailsPlaceholder />
  )
  const onComplete = useCallback((status = 'view') => updateStatus(status), [])
  if (status === 'view') {
    const { brand, last4, expiryDate } = card || {}
    content = (
      <div>
        <p>{I18n.t('endingIn', { ...trOpt, brand: brand.toUpperCase(), last4 })}</p>
        <p>{I18n.t('expires', trOpt)} {expiryDate}</p>
        <Button onClick={onUpdateClick}>{I18n.t('common.update')}</Button>
      </div>
    )
  } else if (status === 'update' || status === 'new') {
    content = (
      <Elements stripe={startStripe.getPromise()}>
        <CardDetailsForm {...{ status, loading, updateLoading, onComplete, refreshSessionState }} />
      </Elements>
    )
  }

  return (
    <PaymentSettingsSection>
      <h4>{I18n.t('cardDetails', trOpt)}</h4>
      {content}
    </PaymentSettingsSection>
  )
}

const PaymentSettings = ({ refreshSessionState }) => {
  const [loading, updateLoading] = useState(true)
  const { loading: queryLoading, error, data: { paymentDetails: { card, currency } = {} } = {} } = useQuery(GET_PAYMENT_DETAILS)
  const { expiryDate } = card || {}
  let willExpire = false
  let hasExpired = false
  if (card) {
    const now = moment()
    const expiry = moment(expiryDate, 'MM/YYYY')
    willExpire = now.isSame(expiry, 'month')
    hasExpired = now.isAfter(expiry, 'month')
  }

  return (
    <PaymentSettingsWrap>
      <LoadingBlock loading={queryLoading || loading} fullScreen={false} />
      {
        hasExpired &&
          <CardDetailsAlert
            message={I18n.t('yourCardHasExpired', trOpt)}
            type='error'
            showIcon
            banner
          />
      }
      {
        willExpire &&
          <CardDetailsAlert
            message={I18n.t('yourCardWillExpireThisMonth', trOpt)}
            type='warning'
            showIcon
          />
      }
      <Card>
        <h1>{I18n.t('paymentSettings', trOpt)}</h1>
        <ErrorAlerts {...{ error }} defaultError={I18n.t('yourPaymentSettingsCouldNotBeLoaded', trOpt)} />
        <CardDetails {...{ queryLoading, loading, updateLoading, card, refreshSessionState }} />
        <PaymentSettingsSection>
          <h4>{I18n.t('uService.currency')}</h4>
          {currency
            ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: I18n.t('yourBillingCurrencyIs', {
                    ...trOpt,
                    label: renderToString(<strong>{CURRENCIES_INFO[currency].label}</strong>)
                  })
                }}
              />
            )
            : <p>{I18n.t('youDoNotHaveACurrencySet', trOpt)}</p>}
          <p>{I18n.t('ifYouWishToChangeYourBillingCurrency', trOpt)}</p>
        </PaymentSettingsSection>
      </Card>
    </PaymentSettingsWrap>
  )
}

export default withRefreshSessionState(PaymentSettings)
