import gql from 'graphql-tag'

export const GET_LEARNERS = gql`
  query($groupIds: [ID], $inactive: Boolean, $withBreaches: Boolean = false, $isManager: Boolean, $isLineManager: Boolean, $withRiskScore: Boolean = false) {
    learners(groupIds: $groupIds, inactive: $inactive, withBreaches: $withBreaches, isManager: $isManager, isLineManager: $isLineManager) {
      id
      name
      firstName
      lastName
      email
      learnerId
      inactive
      syncRecordId
      syncType
      manager {
        id
        name
        email
        learnerId
      }
      riskScoreRAG @include(if: $withRiskScore)
      riskScoreLevel @include(if: $withRiskScore)
      breaches @include(if: $withBreaches) {
        name
        breachDate
        addedDate
        dataClasses
      }
    }
  }
`

export const GET_LEARNER = gql`
  query ($id: ID) {
    learner (id: $id) {
      id
      name
      firstName
      lastName
      email
      learnerId
      managerId
      locale
      syncRecordId
      syncType
      groups {
        id
        name
      }
      excludedFromAutoEnrol
    }
  }
`

export const GET_DELETED_LEARNERS = gql`
  query {
    deletedLearners {
      id
      firstName
      lastName
      name
      email
      learnerId
      deletedAt
    }
  }
`

export const RESTORE_DELETED_LEARNERS = gql`
  mutation($learnerIds: [ID!]!) {
      restoreDeletedLearners(learnerIds: $learnerIds) {
        success
        errors {
          learnerId
          emailUserId
          errorCodes {
            errorCode
            table
          }
        }
      }
  }
`

export const GET_OUTSTANDING_ACTIVITIES = gql`
  query ($learnerId: String!, $companyId: ID!, $onlyPending: Boolean!) {
    outstandingActivities(learnerId: $learnerId, companyId: $companyId) {
      learnerId
      locale
      courseResults (onlyPending: $onlyPending) {
        id
        finished
        enrollDate
        startDate
        finishDate
        retries
        score
        course {
          id
          name
          difficulty
          icon
          description
          locale    
        }
      }
      policyResults (onlyPending: $onlyPending) {
        id
        open
        visit
        signed
        policy {
          id
          name
          category
        }
      }
    }
  }
`
export const GET_LEARNER_DATA = gql`
  query($id: ID, $withRiskScore: Boolean = false) {
    learnerData(id: $id) {
      learner {
        id,
        name
        riskScoreRAG @include(if: $withRiskScore)
        riskScoreLevel @include(if: $withRiskScore)
        inactive
        email
        learnerId
        syncRecordId
        syncType
        courseResults {
          id
          course {
            id
            name
            difficulty
            subject
          },
          enrollDate
          startDate
          finishDate
          score
          auto
        }
        courseScheduleData {
          enabled
          scheduledCourses {
            courseId
            course {
              id
              name
              difficulty
            }
            sendOutDate
          }
        }
      }
      uLearnPerformance
      categories
    }
  }
`

export const GET_LEARNER_COURSES = gql`
  query ($learnerId: ID!) {
    learner (id: $learnerId) {
      courseResults {
        courseId
        finishDate
      }
    }
  }
`

export const CREATE_LEARNER = gql`
  mutation($email:String, $firstName:String!, $lastName:String!, $groupIds: [ID], $learnerId: String, $managerId: ID, $locale: LanguageCode, $excludedFromAutoEnrol: Boolean) {
    createLearner(email:$email, firstName:$firstName, lastName:$lastName, groupIds:$groupIds, learnerId: $learnerId, managerId: $managerId, locale: $locale, excludedFromAutoEnrol: $excludedFromAutoEnrol) {
      id
      name
      firstName,
      lastName,
      email,
      locale,
      managerId
      groups {
        id
        name
      }
      excludedFromAutoEnrol
    }
  }
`

export const EDIT_LEARNER = gql`
  mutation($id:ID!, $email:String, $firstName:String!, $lastName:String!, $groupIds: [ID], $learnerId: String, $managerId: ID, $locale: LanguageCode, $excludedFromAutoEnrol: Boolean) {
    editLearner(id:$id, email:$email, firstName:$firstName, lastName:$lastName, groupIds: $groupIds, learnerId: $learnerId, managerId: $managerId, locale: $locale, excludedFromAutoEnrol: $excludedFromAutoEnrol) {
      id
      name
      firstName
      lastName
      email
      learnerId
      managerId
      locale
      groups {
        id
        name
      }
      excludedFromAutoEnrol
    }
  }
`

export const GET_LEARNERS_AUTOCOMPLETE = gql`
  query {
    learners (inactive: false) {
      id
      name
      email
      learnerId
    }
  }
`

export const EDIT_LEARNERS = gql`
  mutation($learnerIds:[ID!]!, $groupIds: [ID!]!) {
    editLearners(learnerIds: $learnerIds, groupIds: $groupIds) {
      id
      firstName,
      lastName,
      email
    }
  }
`

export const DELETE_LEARNERS = gql`
  mutation($learnerIds: [ID!]!) {
    deleteLearners(learnerIds: $learnerIds)
  }
`

export const DETACH_LEARNERS_FROM_SYNC = gql`
  mutation($learnerIds: [ID!]!) {
    detachLearnersFromSync(learnerIds: $learnerIds)
  }
`

export const UPLOAD_LEARNERS = gql`
  mutation($learners: [UploadedLearner]!) {
    uploadLearners(learners: $learners)
  }
`

export const VALIDATE_UPLOAD_LEARNERS_DATA = gql`
  query($data: [[String!]!]!) {
    validateUploadLearnersData(data: $data) {
      allLearners {
        firstName
        lastName
        email
        learnerId
        groups
        locale
      }
      newLearners {
        firstName
        lastName
        email
        learnerId
        groups
        locale
        managerEmail
      }
      duplicateLearners {
        firstName
        lastName
        email
        learnerId
        groups
        locale
      }
      errors
    }
  }
`

export const SET_LEARNER_ACTIVE = gql`
  mutation($learnerIds: [ID!]!) {
    markLearnersActive(learnerIds: $learnerIds) {
      id
      firstName,
      lastName,
      email,
      learnerId,
      inactive
      groups {
        id
        name
      }
    }
  }
`

export const SET_LEARNER_INACTIVE = gql`
  mutation($learnerIds: [ID!]!) {
    markLearnersInactive(learnerIds: $learnerIds) {
      id
      firstName,
      lastName,
      email,
      learnerId,
      inactive
      groups {
        id
        name
      }
    }
  }
`

export const MS_SYNC_GROUPS = gql`
  query ($type: MicrosoftSyncGroupType) {
    microsoftSyncGroups(type: $type) {
      id
      name
      type
      uniqueName
    }
  }
`

export const MS_SYNC_GROUPS_PAGE = gql`
  query ($type: MicrosoftSyncGroupType, $accessToken: String, $pageUrl: String) {
    microsoftSyncGroupsPage(type: $type, accessToken: $accessToken, pageUrl: $pageUrl) {
      groups {
        id
        name
        type
        uniqueName
      }
      accessToken
      nextPageUrl
    }
  }
`

export const QUEUE_SYNC = gql`
  mutation ($dummy: Boolean, $force: Boolean, $syncType: SyncType) {
    queueSync(dummy: $dummy, force: $force, syncType: $syncType)
  }
`

export const GOOGLE_SYNC_GROUPS = gql`
  query ($type: GoogleSyncGroupType) {
    googleSyncGroups(type: $type) {
      id
      name
      type
      uniqueName
    }
  }
`

export const GOOGLE_SYNC_GROUPS_PAGE = gql`
  query ($type: GoogleSyncGroupType, $accessToken: String, $pageUrl: String) {
    googleSyncGroupsPage(type: $type, accessToken: $accessToken, pageUrl: $pageUrl) {
      groups {
        id
        name
        type
        uniqueName
      }
      accessToken
      nextPageUrl
    }
  }
`

export const GET_SIMPLE_LEARNER = gql`
  query ($companyId: ID!, $learnerId: String, $email: String) {
    simpleLearner (companyId: $companyId, learnerId: $learnerId, email: $email) {
      id
      name
      learnerId
      email
    }
  }
`
export const GET_LEARNER_ME = gql`
  query {
    learnerMe {
      endUser {
        id
        name
        firstName
        lastName
        company {
          id
          name
          domain
          accountType
          planValid
          settings
          locale
          uBreachProEnabled
        }
        email
        learnerId
        locale
      }
      sessionExpiresAt
      role
    }
  }
`

export const GET_END_USER_SESSION_TYPE = gql`
  query {
    endUserSessionType
  }
`

export const UPDATE_LEARNERS_LANGUAGE = gql`
  mutation($learnerIds: [ID!]!, $locale: LanguageCode) {
      updateLearnersLanguage(learnerIds: $learnerIds, locale: $locale)
  }
`

export const END_USER_LINK_REQUEST = gql`
  mutation($email: String!) {
    endUserLinkRequest( email: $email )
  }
`
export const ADMIN_MAGIC_LINK_REQUEST = gql`
  mutation($email: String!) {
    generateAdminMagicLinkToken( email: $email ) {
      token
    }
  }
`
export const END_USER_SIGN_IN = gql`
  mutation($shortLifeToken: String!) {
    endUserSignIn( shortLifeToken: $shortLifeToken ) {
      token
    }
  }
`
export const USER_ID_ACCESS_REQUEST = gql`
  mutation ($companyId: ID!, $learnerId: String!) {
    userIdAccessRequest( companyId: $companyId, learnerId: $learnerId ) {
      token
    }
  }
`
export const UPDATE_LEARNER_ME = gql`
  mutation ($firstName: String, $lastName: String, $email: String, $learnerId: String, $locale: LanguageCode) {
    updateEndUser ( firstName: $firstName, lastName: $lastName, email: $email, learnerId: $learnerId, locale: $locale ) {
      endUser { 
        id
        name
        firstName
        lastName
        email
        learnerId
        locale
      }
    }
  }
`

export const EXTEND_END_USER_SESSION = gql`
  mutation {
    extendEndUserSession {
      token
    }
  }
`

export const GET_LEARNER_LOCALE = gql`
  query {
    learnerMe {
      endUser {
        id
        locale
      }
    }
  }
`
