/* global localStorage */
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Button, Table, Input, Icon, Tag } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import Highlighter from 'react-highlight-words'
import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import { ACCOUNT_TYPES, ACCOUNT_TYPE_IDS, MSP_BILLING_TYPE_KEYS } from '../../constants/company'
import routes from '../../constants/routes'
import { onListTableChange } from '../../helpers/listPages'
import { LIST_PAGINATION_PROPS } from '../../constants/list'
import { formatDateTime } from '../../helpers/datetime'
import { ListTableActions } from '../common'
import _RiskReportStatus from './RiskReportStatus'

const trOpt = { scope: 'uService' }
const BUTTON_ACTION_KEYS = ['edit', 'adminLogin']

const ColumnSearchContainer = styled.div`
  max-width: 300px;
  min-width: 190px;
  padding: 8px;
`

const ColumnSearchInput = styled(Input)`
  display: block;
  margin-bottom: 8px;
`

const SignUpName = styled.span`
  margin-right: 8px;
`

const ColumnSearchButtons = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-btn {
    width: calc(50% - 4px);
    &> span, &> i {
      position: relative;
      top: 2px;
    }
  }
`

const RiskReportStatus = styled(_RiskReportStatus)`
  justify-content: flex-start;
  margin-bottom: 5px;
`
const RecipientLine = styled.span`
  display: block;
  font-size: ${({ sub }) => sub ? '12px' : 'inherit'};
`

const SearchFilterDropdown = React.forwardRef(({ title, setSelectedKeys, selectedKeys, confirm, clearFilters }, ref) => {
  const handleChange = useCallback(e => {
    setSelectedKeys(e.target.value ? [e.target.value] : [])
  }, [setSelectedKeys])
  const handleSearch = useCallback(() => confirm(), [confirm])
  const handleReset = useCallback(() => clearFilters(), [clearFilters])

  return (
    <ColumnSearchContainer>
      <ColumnSearchInput
        ref={ref}
        placeholder={`${I18n.t('common.search')} ${title}`}
        value={selectedKeys ? selectedKeys[0] : null}
        onChange={handleChange}
        onPressEnter={handleSearch}
      />
      <ColumnSearchButtons>
        <Button
          type='primary'
          size='small'
          icon='search'
          onClick={handleSearch}
        >
          {I18n.t('common.search')}
        </Button>
        <Button
          type='primary'
          ghost
          size='small'
          icon='undo'
          onClick={handleReset}
        >
          {I18n.t('common.reset')}
        </Button>
      </ColumnSearchButtons>
    </ColumnSearchContainer>
  )
})

const CompaniesTable = ({
  companies = [], loading = false,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination, disablePagination = false,
  companyId, userId, accountType, listAccountType, showExternalId,
  role,
  path,
  userAccountType,
  performAction = () => { },
  enableProspects,
  enableRiskReportTenants,
  showAutoEnrol = false,
  theme,
  showCompanyCount
}) => {
  const assumeStorageId = useMemo(() => `uServiceAssume|${companyId}|${userId}`, [userId, companyId])
  useEffect(() => localStorage.removeItem(assumeStorageId), [assumeStorageId])
  const onTableChange = useCallback((pagination, filters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      filters,
      updateFilters
    })
  }, [updateFilters, updateSorter, updatePagination])

  const handlePerformAction = useCallback(async (action, id) => {
    performAction(action, [id])
  }, [performAction])

  // A ref is required for every searchable column filter
  const domainSearchInputRef = useRef(null)
  const parentCompanySearchInputRef = useRef(null)
  const externalIdSearchInputRef = useRef(null)

  const getColumnSearchProps = useCallback(({ dataIndex, title }) => {
    let ref = null
    if (dataIndex === 'domain') {
      ref = domainSearchInputRef
    } else if (dataIndex === 'parentCompany') {
      ref = parentCompanySearchInputRef
    } else if (dataIndex === 'externalId') {
      ref = externalIdSearchInputRef
    }
    return {
      filteredValue: filters ? filters[dataIndex] : null,
      filterDropdown: props => <SearchFilterDropdown {...{ dataIndex, title, ref }} {...props} />,
      filterIcon: filtered => <Icon type='search' style={{ color: filtered ? theme.primary : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible && ref && ref.current) {
          setTimeout(() => ref.current.select())
        }
      },
      render: text => {
        const filter = filters ? filters[dataIndex] : null
        return !_isEmpty(filter) ? (
          <Highlighter
            highlightStyle={{ backgroundColor: theme.primary, color: theme.secondary, padding: '0 5px', borderRadius: 2 }}
            searchWords={filter}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : text
      }
    }
  }, [filters, theme, domainSearchInputRef, parentCompanySearchInputRef])

  const showMenuActions = useMemo(() =>
    companies.some(({ actions }) => {
      let hasMenuActions = false
      if (_isArray(actions) && !_isEmpty(actions)) {
        hasMenuActions = actions.filter(action => !BUTTON_ACTION_KEYS.includes(action.key)).length > 0
      }
      return hasMenuActions
    }),
  [companies])

  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}
    const mspUser = userAccountType === 'msp'
    const unified = path === routes.USERVICE && role === 'usecure-admin'
    const listingClients = listAccountType === 'distributor' || listAccountType === 'msp' || listAccountType === 'tenant'
    const listingPartners = listAccountType === 'distributor' || listAccountType === 'msp'

    const columns = [{
      title: I18n.t('common.fields.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortColumnKey === 'name' && order,
      render: (name, company) => {
        return company.signUp === true
          ? (
            <>
              <SignUpName>{name}</SignUpName>
              <Tag>{I18n.t('signUp', trOpt)}</Tag>
            </>
          ) : name
      }
    }]

    if (showExternalId) {
      columns.push({
        title: I18n.t('externalId', trOpt),
        key: 'externalId',
        dataIndex: 'externalId',
        ...getColumnSearchProps({ dataIndex: 'externalId', title: I18n.t('externalId', trOpt) })
      })
    }

    if (unified || listingClients) {
      columns.push({
        title: I18n.t('modals.editCompanyModal.domain'),
        dataIndex: 'domain',
        key: 'domain',
        sorter: (a, b) => (a.domain || '').localeCompare(b.domain || ''),
        sortOrder: sortColumnKey === 'domain' && order,
        ...getColumnSearchProps({ dataIndex: 'domain', title: I18n.t('modals.editCompanyModal.domain') })
      })

      // showAutoEnrol prop to set auto enrol column visibility - this is false by default and not set outside this component at present
      if (showAutoEnrol) {
        columns.push({
          title: I18n.t('common.autoEnrol'),
          key: 'autoEnrol',
          dataIndex: 'autoEnrol'
        })
      }
    }

    if (mspUser || unified) {
      const accountTypes = mspUser ? ['tenant'] : ['distributor', 'msp', 'tenant']
      if (enableProspects) {
        accountTypes.push('prospect')
      }
      if (accountTypes.length > 1) {
        columns.push({
          title: I18n.t('accountType', trOpt),
          dataIndex: 'accountType',
          key: 'accountType',
          filters: accountTypes.map(type => ({ value: type, text: ACCOUNT_TYPES[type] })),
          filteredValue: filters ? filters.accountType : null,
          onFilter: (value, record) => record.accountType.includes(value),
          sorter: (a, b) => {
            const aIndex = ACCOUNT_TYPE_IDS.indexOf(a.accountType)
            const bIndex = ACCOUNT_TYPE_IDS.indexOf(b.accountType)
            return aIndex - bIndex
          },
          sortOrder: sortColumnKey === 'accountType' && order,
          render: accountType => ACCOUNT_TYPES[accountType] || accountType
        })
      }
      if (unified) {
        columns.push({
          title: I18n.t('parentCompany', trOpt),
          dataIndex: 'parentCompany',
          key: 'parentCompany',
          sorter: (a, b) => (a.parentCompany || '').localeCompare(b.parentCompany || ''),
          sortOrder: sortColumnKey === 'parentCompany' && order,
          ...getColumnSearchProps({ dataIndex: 'parentCompany', title: I18n.t('parentCompany', trOpt) })
        })
      }
    }
    if (unified || listingPartners) {
      columns.push({
        title: I18n.t(unified ? 'activeClients' : (listAccountType === 'distributor' ? 'activeMSPs' : 'activeCustomers'), trOpt),
        key: 'childCompanyCount',
        dataIndex: 'childCompanyCount',
        sorter: (a, b) => a.childCompanyCount - b.childCompanyCount,
        sortOrder: sortColumnKey === 'childCompanyCount' && order
      })
    }

    if (accountType === 'prospect') {
      columns.push({
        title: I18n.t('common.contact'),
        key: 'contactName',
        dataIndex: 'contactName',
        render (value, company) {
          if (!company.riskReportContactName) return company.riskReportContactEmail
          return (
            <div>
              <RecipientLine>{company.riskReportContactName || ''}</RecipientLine>
              <RecipientLine sub>{company.riskReportContactEmail || ''}</RecipientLine>
            </div>
          )
        }
      }, {
        title: I18n.t('addedAt', trOpt),
        key: 'addedAt',
        dataIndex: 'addedAt',
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt, 'minute'),
        sortOrder: sortColumnKey === 'addedAt' && order,
        render (value, company) {
          return formatDateTime(company.createdAt)
        }
      }, {
        title: I18n.t('lastUpdatedAt', trOpt),
        key: 'lastUpdatedAt',
        dataIndex: 'lastUpdatedAt',
        sorter: (a, b) => {
          if (!a.riskReportLastUpdatedAt && b.riskReportLastUpdatedAt) return -1
          if (a.riskReportLastUpdatedAt && !b.riskReportLastUpdatedAt) return 1
          if (!a.riskReportLastUpdatedAt && !b.riskReportLastUpdatedAt) return 0
          return moment(a.riskReportLastUpdatedAt).diff(b.riskReportLastUpdatedAt, 'minute')
        },
        sortOrder: sortColumnKey === 'lastUpdatedAt' && order,
        render (value, company) {
          if (!company.riskReportLastUpdatedAt) return ''
          return formatDateTime(company.riskReportLastUpdatedAt)
        }
      })
    }

    columns.push({
      title: I18n.t('activePeople', trOpt),
      key: 'activePeople',
      dataIndex: 'activePeople',
      sorter: (a, b) => a.activePeople - b.activePeople,
      sortOrder: sortColumnKey === 'activePeople' && order
    })

    columns.push({
      title: I18n.t('common.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status, company) => {
        const { accountType, riskReportStatus, riskReportStatusLabel } = company
        if (accountType === 'prospect' || (enableRiskReportTenants && accountType === 'tenant' && riskReportStatus && riskReportStatus !== 'finished')) {
          return (
            <>
              {accountType === 'tenant' && <div>{status}</div>}
              <RiskReportStatus status={riskReportStatus} />
              <div>{riskReportStatusLabel}</div>
            </>
          )
        }
        return status
      }
    })

    if (listingPartners) {
      columns.push({
        title: I18n.t('usecureFeatures', trOpt),
        key: 'platformAccess',
        dataIndex: 'platformAccess'
      })
    }
    if (accountType === 'msp') {
      columns.push({
        title: I18n.t('billingType', trOpt),
        key: 'billingType',
        dataIndex: 'billingType',
        render: billingType => I18n.t(MSP_BILLING_TYPE_KEYS[billingType || 'none'])
      })
    }

    columns.push({
      key: 'actions',
      dataIndex: 'actions',
      render: (actions, company) => (
        companyId !== company.id
          ? (
            <ListTableActions
              actions={actions}
              id={company.id}
              performAction={handlePerformAction}
              buttonActionKeys={BUTTON_ACTION_KEYS}
              onEmptyActions={showMenuActions ? 'disable' : 'skip'}
            />
          )
          : null
      )
    })

    return columns
  }, [
    sorter, companyId, listAccountType, accountType, showExternalId, filters, getColumnSearchProps, path, role, userAccountType,
    handlePerformAction, enableProspects, enableRiskReportTenants, showMenuActions, showAutoEnrol
  ])

  const companyCount = useMemo(() => {
    const filterKeys = filters ? Object.keys(filters).filter(key => _isArray(filters[key]) && !_isEmpty(filters[key])) : []
    if (_isEmpty(filterKeys)) {
      return companies.length
    }
    return companies.filter(company =>
      filterKeys.every(filterKey => {
        const value = company[filterKey]
        const filterValue = filters[filterKey]
        let match = false
        if (value) {
          if (filterKey === 'accountType') {
            match = filterValue.includes(value)
          } else {
            match = value.toString().toLowerCase().includes(filterValue[0].toLowerCase())
          }
        }
        return match
      })
    ).length
  }, [companies, filters])

  return (
    <>
      {showCompanyCount && <h4>{I18n.t('companyCount', { ...trOpt, count: companyCount })}</h4>}
      <Table
        columns={columns} dataSource={companies} loading={loading}
        onChange={onTableChange}
        pagination={disablePagination ? false : { ...pagination, ...LIST_PAGINATION_PROPS }}
      />
    </>
  )
}

export default CompaniesTable
