import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'

const SimulationActions = ({ simulationId, actions = [], performAction = () => {} }) => {
  const handleMenuClick = e => performAction(e.key, simulationId)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item key={action.key} disabled={Boolean(action.disabled)}>{action.label}</Menu.Item>
        ))
      }

    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <Icon type='right' />
      </Button>
    </Dropdown>
  )
}

export default SimulationActions
