import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import _isNil from 'lodash/isNil'
import _debounce from 'lodash/debounce'
import _pick from 'lodash/pick'
import { Layout, message, Button, Icon, Select } from 'antd'
import I18n from 'i18n-js'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'

import UploadLearnersModal from '../Modals/UploadLearnersModal'
import EditGroupModal from '../Modals/EditGroupModal'
import SendPolicyLearnerModal from '../Modals/SendPolicyLearnerModal'
import SendPolicyLearnerConfirm from '../Modals/SendPolicyLearnerConfirm'
import SignPolicyOfflineConfirm from '../Modals/SignPolicyOfflineConfirm'
import UnenrolLearnersFromCourseModal from '../Modals/UnenrolLearnersFromCourseModal'
import GroupTree from '../Learners/GroupTree'
import { enrolLearnersOnGapAnalysisConfirm, EditLearnerModal, EnrolOnCourseModal, LearnersTable, LearnersActions, AddLearnersToGroupModal, setLearnerStatusConfirm, restoreUsersConfirm } from '../Learners'
import AddDropdown from '../Learners/AddDropdown'
import { downloadLearnerCourseExport, downloadLearnerSimulationExport } from '../../helpers'
import { filterLearnerActions, getLearnerActions } from '../../helpers/Learners'
import { togglePollingBasedOnSelectedIds, updateSelectedIdsOnRecordSetUpdate, disableActions } from '../../helpers/listPages'
import { ListHeader, ListHeaderPanel, SearchBar } from '../common'
import { connect } from '../../hocs'
import { getSettings } from '../../state/selectors/settings'
import { getSession } from '../../state/selectors/session'
import downloadPolicyExport from '../../helpers/downloadPolicyExport'
import { GET_LEARNERS, GET_DELETED_LEARNERS } from '../Queries/Learners'
import { GET_GROUP_TREE } from '../Queries/Groups'
import { WRITABLE_LEARNER_ACTIONS } from '../../constants/actions'
import ChangeLanguageModal from '../Modals/ChangeLanguageModal'
import RemoveLearnersFromPolicyConfirm from '../Modals/RemoveLearnersFromPolicyConfirm'
import UnenrolLearnersFromGapAnalysisConfirm from '../Modals/UnenrolLearnersFromGapAnalysisConfirm'
import DeleteLearnerConfirm from '../Modals/DeleteLearnerConfirm'
import DetachLearnerConfirm from '../Modals/DetachLearnerConfirm'

const trOpt = { scope: 'learners.learnersView' }

const { Content, Sider } = Layout
const { Option } = Select

const StatusFormField = styled.div`
  label {
    margin-right: 10px;
  }

  .ant-select {
    min-width: 105px;
  }
`

const GroupsHeading = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  width: 200px;

  h3 {
    margin: 0;
  }
`

const GroupsHeadingButtons = styled.div`
  .ant-btn {
    margin-left: 5px;
    &:first-child {
       margin-left: 0;
    }

    .anticon {
      margin-top: 4px;
    }
  }
`

const GroupTreeContainer = styled.div`
  margin-right: 5px;
  overflow-x: auto;
`

const getSelectedLearnerIds = (singleLearner, selectedLearnerIds) =>
  (singleLearner ? [singleLearner] : selectedLearnerIds)
const getSelectedLearners = (singleLearner, selectedLearnerIds, learners) =>
  getSelectedLearnerIds(singleLearner, selectedLearnerIds).map(learnerId => learners.find(l => l.key === learnerId)).filter(learner => !_isNil(learner))

const LearnersView = ({
  loading, error, learners: rawLearners = [],
  columns,
  checkedGroups = [], updateCheckedGroups = () => {},
  status = 'all', updateStatus = () => {},
  refetchQueries: refetchQueriesProp = [],
  panelLeft = ['status'],
  panelRight = ['search', 'actions', 'import', 'add'],
  showGroups = true,
  searchFilterText = '',
  updateSearchFilterText = () => {},
  pagination,
  updatePagination = () => {},
  sorter,
  updateSorter = () => {},
  filters,
  updateFilters = () => {},
  defaultSortByName = true,
  uLearn: uLearnEnabled = true,
  uPhish: uPhishEnabled = true,
  uPolicy: uPolicyEnabled = true,
  uBreachEnabled = true,
  enableRiskScore = false,
  policy,
  showActions,
  useSelection,
  size,
  groupTreeRef,
  allowedActions,
  routeId,
  useModalRouting = false,
  history,
  setPollingEnabled,
  planValid,
  renderSingleActionAsButton = false
}) => {
  const [selectedGroup, updateSelectedGroup] = useState(null)
  const [selectedLearnerIds, updateSelectedLearnerIds] = useState([])
  const [singleLearner, updateSingleLearner] = useState(null)
  const [showEnrolOnCourseModal, updateShowEnrolOnCourseModal] = useState(false)
  const [showChangeLanguageModal, updateShowChangeLanguageModal] = useState(false)
  const [showAddLearnerToGroupModal, updateShowAddLearnerToGroupModal] = useState(false)
  const [showSendPolicyModal, updateShowSendPolicyModal] = useState(false)
  const [showSignPolicyModal, updateShowSignPolicyModal] = useState(false)
  const [showRemoveFromPolicy, setRemoveFromPolicy] = useState(false)

  const disabledActions = useMemo(() => !planValid ? WRITABLE_LEARNER_ACTIONS : null, [planValid])

  const allLearners = useMemo(() => {
    const learners = rawLearners.map((rawLearner) => {
      const learner = {
        ...rawLearner,
        key: rawLearner.key || rawLearner.id,
        id: rawLearner.id,
        name: rawLearner.name || `${rawLearner.firstName} ${rawLearner.lastName}`.trim(),
        email: rawLearner.email || rawLearner.learnerId,
        attachedToSync: rawLearner.syncRecordId !== null || rawLearner.syncType !== null
      }
      learner.actions = getLearnerActions({ learner, uLearnEnabled, uPhishEnabled, uPolicyEnabled, policy, allowedActions, disabledActions })
      return learner
    })
    if (defaultSortByName) {
      learners.sort((a, b) => a.name.localeCompare(b.name))
    }

    return learners
  }, [rawLearners, defaultSortByName, uLearnEnabled, uPhishEnabled, uPolicyEnabled, policy, allowedActions, disabledActions])
  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedLearnerIds, allLearners),
  [allLearners])
  // Suspend query polling if rows are selected
  // Performing a single learner action also suspends polling
  useEffect(() => togglePollingBasedOnSelectedIds(getSelectedLearnerIds(singleLearner, selectedLearnerIds), setPollingEnabled), [selectedLearnerIds, setPollingEnabled, singleLearner])
  // Reset single learner so that polling resumes
  const onActionFinish = useCallback(() => updateSingleLearner(null), [])

  const [learners, setLearners] = useState([])
  const applyLearnerFilters = useCallback(searchFilterText => {
    let learners = allLearners
    if (searchFilterText) {
      const filterFields = ['name', 'email']
      learners = learners.filter(learner =>
        filterFields.some(field => {
          const value = learner[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }
    setLearners(learners)
  }, [allLearners])
  const debouncedApplyLearnerFilters = useCallback(_debounce(applyLearnerFilters, 500), [applyLearnerFilters])
  useEffect(() => {
    debouncedApplyLearnerFilters(searchFilterText)
  }, [debouncedApplyLearnerFilters, searchFilterText])

  const { selectedLearners, selectedLearnerRows } = useMemo(() => {
    const selectedLearnerRows = getSelectedLearners(singleLearner, selectedLearnerIds, learners)
    const selectedLearners = _uniqBy(selectedLearnerRows, 'id')// Required by ViewPolicyUsers as it can display multiple rows per learner
    return { selectedLearnerRows, selectedLearners }
  }, [learners, selectedLearnerIds, singleLearner])

  const learnersActions = useMemo(() => {
    if (selectedLearnerIds.length === 1) {
      return getLearnerActions({ learner: selectedLearnerRows[0], uLearnEnabled, uPhishEnabled, uPolicyEnabled, policy, allowedActions, disabledActions })
    }

    const actions = [
      { key: 'deleteLearners', label: I18n.t('deleteUsers', trOpt) },
      { key: 'markLearnersActive', label: I18n.t('markAsActive', trOpt) },
      { key: 'markLearnersInactive', label: I18n.t('markAsInactive', trOpt) },
      { key: 'addLearnersToGroup', label: I18n.t('addUsersToGroup', trOpt) },
      {
        key: 'changeLanguage', label: I18n.t('changeLanguage', trOpt)
      },
      {
        key: 'restoreUsers', label: I18n.t('restoreUsers', trOpt)
      }
    ]
    if (uLearnEnabled) {
      actions.push({
        key: 'enrollOnCourse', label: I18n.t('enrollOnCourse', trOpt)
      }, {
        key: 'enrollOnGapAnalysis', label: I18n.t('enrollOnGapAnalysis', trOpt)
      }, {
        key: 'unenrolFromCourse', label: I18n.t('unenrolFromCourse', trOpt)
      }, {
        key: 'unenrolGapAnalysis', label: I18n.t('unenrolGapAnalysis', trOpt)
      }, {
        key: 'exportCourseData', label: I18n.t('exportCourseData', trOpt)
      })
    }
    if (uPolicyEnabled) {
      actions.push({ key: 'sendPolicy', label: I18n.t('uPolicy.common.sendPolicy') })
      if (policy) {
        actions.push({ key: 'signPolicy', label: I18n.t('markPolicyAsSigned', trOpt) })
        if (selectedLearnerRows.some(learner => ['visited', 'pending'].includes(learner.policyStatus))) { // This corresponds to a row for policy result with learner data
          actions.push({ key: 'removeLearnersFromPolicy', label: I18n.t('removeUsersFromPolicy', trOpt) })
        }
      }
      actions.push({ key: 'exportPolicyData', label: I18n.t('common.exportPolicyData') })
    }
    if (uPhishEnabled) {
      actions.push({ key: 'exportSimulationData', label: I18n.t('exportSimulationData', trOpt) })
    }

    const attachedToSync = selectedLearnerRows.some(learner => learner.syncRecordId !== null || learner.syncType !== null)
    if (attachedToSync) {
      actions.push({ key: 'detachLearnersFromSync', label: I18n.t('detachLearnersFromSync', trOpt) })
    }

    const filteredLearnerActions = filterLearnerActions(actions, allowedActions)
    return disableActions(filteredLearnerActions, disabledActions)
  }, [selectedLearnerRows, selectedLearnerIds, uLearnEnabled, uPhishEnabled, uPolicyEnabled, policy, allowedActions, disabledActions])

  const { refetchQueries, groupTreeVariables } = useMemo(() => {
    const variables = {
      groupIds: checkedGroups.length > 0 ? checkedGroups : null,
      inactive: status === 'all' ? null : status === 'inactive',
      isManager: status === 'managers' ? true : null,
      isLineManager: status === 'lineManagers' ? true : null,
      withRiskScore: enableRiskScore
    }
    const groupTreeVariables = _pick(variables, ['inactive', 'isManager'])

    return {
      variables,
      groupTreeVariables,
      refetchQueries: [
        ...(refetchQueriesProp || []),
        { query: GET_LEARNERS },
        { query: GET_LEARNERS, variables },
        { query: GET_GROUP_TREE },
        { query: GET_GROUP_TREE, variables: { includeLearnerCount: true, includeNoGroup: true, ...groupTreeVariables } },
        { query: GET_GROUP_TREE, variables: { includeLearnerCount: true } },
        { query: GET_DELETED_LEARNERS }
      ]
    }
  }, [checkedGroups, status, refetchQueriesProp, enableRiskScore])

  const unenrolRef = useRef(null)
  const openUnenrolFromCourseModal = useCallback(learnerId => {
    if (unenrolRef.current) {
      // This would ideally use selectedLearners state var but that hasn't fully updated by the time this function is called
      // The updateSingleLearner call in performAction doesn't complete the state in time
      // If all the modals opened by ref and passed data at that point this wouldn't be an issue
      unenrolRef.current.open(getSelectedLearners(learnerId, selectedLearnerIds, learners))
    }
  }, [unenrolRef, selectedLearnerIds, learners])

  const unenrolGapAnalysisRef = useRef(null)
  const openUnenrolGapAnalysis = useCallback((learnerIds) => {
    if (unenrolGapAnalysisRef.current) {
      unenrolGapAnalysisRef.current.open(learnerIds)
    }
  }, [unenrolGapAnalysisRef])

  const deleteLearnerRef = useRef(null)
  const openDeleteLearnerConfirm = useCallback((learnerIds) => {
    if (deleteLearnerRef.current) {
      deleteLearnerRef.current.open(learnerIds)
    }
  }, [deleteLearnerRef])

  const detachLearnerRef = useRef(null)
  const openDetachLearnerConfirm = useCallback((learnerIds) => {
    if (detachLearnerRef.current) {
      detachLearnerRef.current.open(learnerIds)
    }
  }, [detachLearnerRef])

  const editLearnerRef = useRef(null)
  const openEditLearner = useCallback((learnerId) => {
    if (editLearnerRef.current) {
      editLearnerRef.current.open(learnerId)
    }
  }, [editLearnerRef])

  const performAction = useCallback(async (action, learnerId = null) => {
    let learnerIds = []
    // ViewPolicyUsers can have multiple rows per user so it's keyed on policyResult.id rather than learner.id
    // However each row's id property corresponds to learner.id
    // learnerIds needs to be a de-dup'd array of learner ids at the point hence the processing below
    if (learnerId) {
      learnerIds = [learnerId]
    } else {
      learnerIds = _uniq(
        selectedLearnerIds.map(learnerId => learners.find(l => l.key === learnerId)).filter(learner => !_isNil(learner)).map(l => l.id)
      )
    }
    updateSingleLearner(learnerId)

    switch (action) {
      case 'enrollOnCourse':
        updateShowEnrolOnCourseModal(true)
        break
      case 'unenrolFromCourse':
        openUnenrolFromCourseModal(learnerId)
        break
      case 'editLearner':
        openEditLearner(learnerIds[0])
        break
      case 'deleteLearners':
        openDeleteLearnerConfirm(learnerIds)
        break
      case 'markLearnersActive':
        setLearnerStatusConfirm(learnerIds, { refetchQueries, onClose: onActionFinish })
        break
      case 'markLearnersInactive':
        setLearnerStatusConfirm(learnerIds, { refetchQueries, setInactive: true, onClose: onActionFinish })
        break
      case 'addLearnersToGroup':
        updateShowAddLearnerToGroupModal(true)
        break
      case 'changeLanguage':
        updateShowChangeLanguageModal(true)
        break
      case 'enrollOnGapAnalysis':
        enrolLearnersOnGapAnalysisConfirm(learnerIds)
        break
      case 'exportCourseData':
        await downloadLearnerCourseExport(learnerIds)
        onActionFinish()
        break
      case 'exportSimulationData':
        await downloadLearnerSimulationExport(learnerIds)
        onActionFinish()
        break
      case 'sendPolicy':
        updateShowSendPolicyModal(true)
        break
      case 'signPolicy':
        if (policy) {
          updateShowSignPolicyModal(true)
        } else {
          message.error(I18n.t('common.actionCouldNotBePerformed'))
        }
        break
      case 'exportPolicyData':
        await downloadPolicyExport({ policyIds: policy ? [policy.id] : undefined, learnerIds })
        onActionFinish()
        break
      case 'removeLearnersFromPolicy':
        setRemoveFromPolicy(true)
        break
      case 'restoreUsers':
        restoreUsersConfirm(learnerIds, { refetchQueries, onClose: onActionFinish })
        break
      case 'unenrolGapAnalysis':
        openUnenrolGapAnalysis(learnerIds)
        break
      case 'detachLearnersFromSync':
        openDetachLearnerConfirm(learnerIds)
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [
    selectedLearnerIds, learners, refetchQueries, policy,
    openUnenrolFromCourseModal, openUnenrolGapAnalysis, openDeleteLearnerConfirm, openDetachLearnerConfirm, onActionFinish,
    openEditLearner
  ])

  const onStatusChange = useCallback(status => updateStatus(status), [updateStatus])

  const actions = [
    { key: 'addLearner', label: I18n.t('common.user') },
    { key: 'addGroup', label: I18n.t('group', trOpt) }
  ]

  const [showAddGroupModalModal, updateShowAddGroupModal] = useState(false)
  const [showEditGroupModal, updateShowEditGroupModal] = useState(false)
  const [showUploadLearnersModal, updateShowUploadLearnersModal] = useState(false)

  const performAddAction = useCallback((action) => {
    switch (action) {
      case 'addLearner':
        openEditLearner()
        break
      case 'addGroup':
        updateShowAddGroupModal(true)
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [openEditLearner, updateShowAddGroupModal])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const makeAddGroupModalVisible = useCallback(() => updateShowAddGroupModal(true), [updateShowAddGroupModal])
  const makeEditGroupModalVisible = useCallback(() => updateShowEditGroupModal(true), [updateShowEditGroupModal])
  const makeUploadLearnersModalVisible = useCallback(() => updateShowUploadLearnersModal(true), [updateShowUploadLearnersModal])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel className={`learner-view-header-panel-${align}`} align={align} width={align === 'left' && showGroups ? 250 : undefined}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'status':
                return (
                  <StatusFormField key={index}>
                    <label>{I18n.t('common.status')}:</label>
                    <Select value={status} onChange={onStatusChange} style={{ width: '190px' }}>
                      <Option value='active'>{I18n.t('common.active')}</Option>
                      <Option value='inactive'>{I18n.t('common.inactive')}</Option>
                      <Option value='lineManagers'>{I18n.t('lineManagers', trOpt)}</Option>
                      <Option value='managers'>{I18n.t('managers', trOpt)}</Option>
                      <Option value='all'>{I18n.t('allUsers', trOpt)}</Option>
                    </Select>
                  </StatusFormField>
                )
              case 'search':
                return (
                  <SearchBar
                    className='learner-search'
                    key={index}
                    placeholder={I18n.t('searchForAUser', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                  />
                )
              case 'actions':
                return (
                  <LearnersActions key={index} selectedLearnerIds={selectedLearnerIds} actions={learnersActions} performAction={performAction} renderSingleActionAsButton={renderSingleActionAsButton} />
                )
              case 'import':
                return (
                  <Button disabled={!planValid} key={index} onClick={makeUploadLearnersModalVisible}><Icon type='upload' />{I18n.t('learners.common.importUsers')}</Button>
                )
              case 'add':
                return (
                  <AddDropdown disabled={!planValid} key={index} actions={actions} performAction={performAddAction} />
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [showGroups, status, onStatusChange, searchFilterText, onSearchChange, selectedLearnerIds, learnersActions, performAction, makeUploadLearnersModalVisible, actions, performAddAction, planValid, renderSingleActionAsButton])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <ListHeader>
        {panelLeftComponent}
        {panelRightComponent}
      </ListHeader>
      <Layout>
        {
          showGroups
            ? (
              <Sider width={200} style={{ background: '#fff' }}>
                <GroupsHeading>
                  <h3>{I18n.t('yourGroups', trOpt)}</h3>
                  <GroupsHeadingButtons>
                    <Button disabled={!planValid} onClick={makeAddGroupModalVisible} size='small' shape='circle' icon='plus' />
                    <Button onClick={makeEditGroupModalVisible} size='small' shape='circle' icon='edit' disabled={!selectedGroup || !planValid} />
                  </GroupsHeadingButtons>
                </GroupsHeading>
                <GroupTreeContainer>
                  <GroupTree ref={groupTreeRef} checkedGroups={checkedGroups} updateCheckedGroups={updateCheckedGroups} updateSelectedGroup={updateSelectedGroup} {...groupTreeVariables} />
                </GroupTreeContainer>
              </Sider>
            )
            : null
        }
        <Layout>
          <Content>
            <LearnersTable
              {...{
                error,
                loading,
                learners,
                columns,
                selectedLearnerIds,
                updateSelectedLearnerIds,
                performAction,
                pagination,
                updatePagination,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                uLearnEnabled,
                showActions,
                useSelection,
                size,
                routeId,
                useModalRouting,
                history,
                renderSingleActionAsButton,
                setPollingEnabled
              }}
            />
          </Content>
        </Layout>
      </Layout>
      <>
        <EditGroupModal
          setVisible={showEditGroupModal ? updateShowEditGroupModal : updateShowAddGroupModal}
          visible={showEditGroupModal || showAddGroupModalModal}
          groupId={showEditGroupModal ? selectedGroup : undefined}
          {...{ checkedGroups, updateCheckedGroups, updateSelectedGroup, groupTreeVariables }}
          refetchQueries={refetchQueries}
        />
        <UploadLearnersModal
          setVisible={updateShowUploadLearnersModal} visible={showUploadLearnersModal} refetchQueries={refetchQueries}
          onClose={onActionFinish}
        />
        <UnenrolLearnersFromGapAnalysisConfirm
          ref={unenrolGapAnalysisRef}
          refetchQueries={refetchQueries}
          onClose={onActionFinish}
        />
        <DeleteLearnerConfirm
          ref={deleteLearnerRef}
          {...{ refetchQueries, uLearnEnabled, uBreachEnabled, uPhishEnabled, uPolicyEnabled, enableRiskScore }}
          onClose={onActionFinish}
        />
        <DetachLearnerConfirm
          ref={detachLearnerRef}
          {...{ refetchQueries }}
          onClose={onActionFinish}
        />
        <EnrolOnCourseModal
          learners={selectedLearners} visible={showEnrolOnCourseModal} setVisible={updateShowEnrolOnCourseModal}
          onClose={onActionFinish}
        />
        <UnenrolLearnersFromCourseModal ref={unenrolRef} refetchQueries={refetchQueries} onClose={onActionFinish} />
        {
          policy
            ? (
              <>
                <SendPolicyLearnerConfirm
                  policy={policy}
                  learners={selectedLearners}
                  visible={showSendPolicyModal} setVisible={updateShowSendPolicyModal}
                  refetchQueries={refetchQueries}
                  onClose={onActionFinish}
                />
                <SignPolicyOfflineConfirm
                  policy={policy}
                  learners={selectedLearners}
                  visible={showSignPolicyModal} setVisible={updateShowSignPolicyModal}
                  refetchQueries={refetchQueries}
                  onClose={onActionFinish}
                />
                <RemoveLearnersFromPolicyConfirm
                  policy={policy}
                  learners={selectedLearners}
                  visible={showRemoveFromPolicy}
                  setVisible={setRemoveFromPolicy}
                  refetchQueries={refetchQueries}
                  onClose={onActionFinish}
                />
              </>
            )
            : (
              <SendPolicyLearnerModal
                learners={selectedLearners}
                visible={showSendPolicyModal} setVisible={updateShowSendPolicyModal}
                onClose={onActionFinish}
              />
            )
        }
        <AddLearnersToGroupModal
          learners={selectedLearners}
          visible={showAddLearnerToGroupModal} setVisible={updateShowAddLearnerToGroupModal} refetchQueries={refetchQueries}
          onClose={onActionFinish}
        />
        <EditLearnerModal
          ref={editLearnerRef}
          refetchQueries={refetchQueries}
          onClose={onActionFinish}
        />
        <ChangeLanguageModal
          learners={selectedLearners}
          visible={showChangeLanguageModal} setVisible={updateShowChangeLanguageModal} refetchQueries={refetchQueries}
          onClose={onActionFinish}
        />
      </>
    </>
  )
}

export default connect(
  state => ({
    ..._pick(getSettings(state), ['uLearn', 'uPhish', 'uPolicy', 'uBreachEnabled', 'enableRiskScore']),
    ..._pick(getSession(state), ['planValid'])
  })
)(LearnersView)
