import _omit from 'lodash/omit'

import { createLocaleGetterObject, createLocaleGetterOptions } from '../helpers/locale'

const commsTypeTrScope = 'users.common.commsType'
const roleTrScope = 'users.common.roles'

export const COMMS_TYPES = ['All', 'Sales', 'Marketing', 'Technical']
export const COMMS_TYPE_SHORT_LABELS = createLocaleGetterObject({
  All: 'common.all',
  Sales: `${commsTypeTrScope}.sales`,
  Marketing: `${commsTypeTrScope}.marketing`,
  Technical: `${commsTypeTrScope}.technical`
})
export const COMMS_TYPE_LABELS = createLocaleGetterObject({
  All: 'aBitOfEverything',
  Sales: 'sales',
  Marketing: 'marketing',
  Technical: 'technical'
}, { scope: commsTypeTrScope })
export const COMMS_TYPE_OPTIONS = createLocaleGetterOptions(COMMS_TYPE_LABELS)

const ROLE_ID_TO_TR_KEY_MAP = {
  admin: 'admin',
  'course-builder': 'courseBuilder',
  'usecure-admin': 'usecureAdmin'
}
// Roles visible to everyone
export const ROLES = ['admin', 'course-builder']
export const ROLE_LABELS = createLocaleGetterObject(_omit(ROLE_ID_TO_TR_KEY_MAP, ['usecure-admin']), { scope: roleTrScope })
export const ROLES_OPTIONS = createLocaleGetterOptions(ROLE_LABELS)
// Full Role-set visible to usecure admins
export const ALL_ROLES = [...ROLES, 'usecure-admin']
export const ALL_ROLE_LABELS = createLocaleGetterObject(ROLE_ID_TO_TR_KEY_MAP, { scope: roleTrScope })
export const ALL_ROLE_OPTIONS = createLocaleGetterOptions(ALL_ROLE_LABELS)
