import React from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'
import { withTheme } from 'styled-components'

const CoursesActions = ({ selectedCourseIds = [], actions = [], performAction = () => {}, theme }) => {
  const handleMenuClick = e => performAction(e.key, selectedCourseIds)
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item disabled={Boolean(action.disabled)} key={action.key}>{action.icon && <Icon type={action.icon} theme='twoTone' twoToneColor={theme.primary} />}  {action.label}</Menu.Item>
        ))
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={selectedCourseIds.length === 0}>
      <Button>Actions <Icon type='down' /></Button>
    </Dropdown>
  )
}

export default withTheme(CoursesActions)
