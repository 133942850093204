import axios from 'axios'
import { message } from 'antd'
import moment from 'moment'
import queryString from 'query-string'
import Plain from 'slate-plain-serializer'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import _sample from 'lodash/sample'
import I18n from 'i18n-js'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _isString from 'lodash/isString'
import _pick from 'lodash/pick'
import _sortBy from 'lodash/sortBy'

import { base64EncodeJSON } from './base64EncodeUnicode'
import { GET_SIMULATION_EMAIL_TEMPLATE, SEND_PHISH_TEST_EMAIL, GET_SIMULATION, GET_PHISH_TEST_EMAIL_STATUS } from '../components/Queries/uPhish'
import authenticatedClient from '../apollo-client/authenticatedClient'
import { showErrors } from './'
import { CLIENT_ENV, ENABLE_MESSAGE_INJECTION } from '../constants/environment'
import QueueJobStatusHandler from './QueueJobStatusHandler'

const trOpt = { scope: 'uPhish.helpers' }

const landingPageURL = window.__USECURE_CONFIG__.REACT_APP_LANDING_PAGE_URL

export const getRecipientIds = recipients =>
  recipients
    .map(recipient => {
      if (typeof recipient === 'object') {
        return recipient.id
      }
      return recipient
    })
    .filter(recipient => !_isNil(recipient))

export const getSimulationMutationVariables = simulation => {
  const {
    id,
    landingPageId,
    name,
    subject,
    customSender,
    senderPrefix,
    senderDomain,
    senderName,
    senderEmail,
    content,
    legacy,
    link,
    recipients: { learners = [], groups = [] } = {},
    sendToAll,
    workingHours,
    spread,
    startDate,
    courseId,
    deliveryMethod = 'smtp'
  } = simulation
  let {
    emailTemplateId
  } = simulation
  const recipients = {
    groups: getRecipientIds(groups),
    learners: getRecipientIds(learners)
  }

  const config = {
    subject,
    sender: {
      custom: customSender,
      name: senderName
    },
    link: `https://${link}`,
    workingHours,
    spread,
    courseId,
    deliveryMethod
  }

  if (customSender) {
    config.sender = {
      ...config.sender,
      email: senderEmail
    }
  } else {
    config.sender = {
      ...config.sender,
      prefix: senderPrefix,
      domain: senderDomain
    }
  }

  if (emailTemplateId === 'freeform') {
    emailTemplateId = null
    if (legacy) {
      config.legacy = true
      config.content = content
    } else {
      config.legacy = false
      config.content = content.encoded || base64EncodeJSON(content)
    }
  }

  return {
    id,
    name,
    landingPageId,
    emailTemplateId,
    startDate,
    recipients,
    sendToAll,
    config
  }
}

export const clearLandingPageServiceCache = async (id, type) => {
  let success = false
  try {
    if (landingPageURL && id && type) {
      const response = await axios.get(`${landingPageURL}/api/cache/clear/${type}/${id}`)
      success = _get(response, 'data.success') === true
    }
  } catch { }
  return success
}

export const clearLandingPageContentCache = async (id, { skipMessage = false } = {}) => {
  const success = await clearLandingPageServiceCache(id, 'content')
  if (!skipMessage) {
    if (success) {
      message.success(I18n.t('theCachedCopyOfTheLandingPageWasCleared', trOpt))
    } else {
      message.error(I18n.t('theCachedCopyOfTheLandingPageWasNotCleared', trOpt))
    }
  }
  return success
}
export const clearLandingPageSimulationCache = async (id, { skipMessage = false } = {}) => {
  const success = await clearLandingPageServiceCache(id, 'simulation')
  if (!skipMessage) {
    if (success) {
      message.success(I18n.t('theCachedCopyOfTheSimulationWasCleared', trOpt))
    } else {
      message.error(I18n.t('theCachedCopyOfTheSimulationWasNotCleared', trOpt))
    }
  }
  return success
}

export const sendTestEmail = async variables => {
  let success = false
  let hideInProgress
  try {
    const handler = new QueueJobStatusHandler({
      mutation: SEND_PHISH_TEST_EMAIL,
      mutationName: 'sendPhishingTestEmail',
      query: GET_PHISH_TEST_EMAIL_STATUS,
      queryName: 'phishingEmailTestStatus',
      client: authenticatedClient
    })
    if (!ENABLE_MESSAGE_INJECTION) variables.deliveryMethod = 'smtp' // Force SMTP on tests
    const { status, deliveryMethod, messageInjectionService } = await handler.execute(variables, {
      onJobStart: () => {
        hideInProgress = message.loading(I18n.t('testEmailInProgress', trOpt), 0)
      }
    })
    if (hideInProgress) hideInProgress()
    success = status === 'completed'
    if (success && !ENABLE_MESSAGE_INJECTION) {
      // Use generic message without delivery method
      message.success(I18n.t('testEmailSent', trOpt))
    } else if (success) {
      if (deliveryMethod === 'messageInjection' && messageInjectionService === 'google') {
        message.success(I18n.t('testEmailSentGoogle', trOpt))
      } else if (deliveryMethod === 'messageInjection' && messageInjectionService === 'microsoft') {
        message.success(I18n.t('testEmailSentMicrosoft', trOpt))
      } else {
        message.success(I18n.t('testEmailSentSMTP', trOpt))
      }
    } else if (status === 'jobTimeout') {
      throw new Error(I18n.t('testEmailTimeoutError', trOpt))
    } else {
      throw new Error(I18n.t('queueError', trOpt))
    }
  } catch (e) {
    console.error('sendTestEmail - ERROR', e)
    if (hideInProgress) hideInProgress()
    showErrors(e, I18n.t('anErrorOccurredSendingTheTestEmail', trOpt))
  }
  return success
}

export const sendTestEmailForTemplate = async (id, email, firstName, lastName, deliveryMethod) => {
  let success = false
  try {
    const { data: { simulationEmailTemplate } = {} } = await authenticatedClient.query({
      query: GET_SIMULATION_EMAIL_TEMPLATE,
      variables: { id }
    })
    const { name, subject, customSender, senderName, senderEmail, senderPrefix, senderDomain, availableLinks = [] } = simulationEmailTemplate
    await sendTestEmail({
      emailTemplateId: id,
      startDate: moment().toISOString(),
      config: {
        subject: subject || I18n.t('simulationEmailTemplateTest', { ...trOpt, name }),
        sender: {
          custom: customSender,
          name: senderName,
          email: customSender ? senderEmail : '',
          prefix: customSender ? '' : senderPrefix,
          domain: customSender ? '' : senderDomain
        },
        link: `https://${_isEmpty(availableLinks) ? 'sup0rt.co.uk' : _sample(availableLinks)}`
      },
      email,
      firstName,
      lastName,
      deliveryMethod
    })
    success = true
  } catch (e) {
    console.error(`sendTestEmailForTemplate.onSendTest[${id}] - ERROR`, e)
    showErrors(e, I18n.t('anErrorOccurredSendingTheTestEmail', trOpt))
  }
  return success
}

export const sendTestEmailForSimulation = async (simulationId, email, firstName, lastName, deliveryMethod) => {
  let success = false
  try {
    const { data: { simulation } = {} } = await authenticatedClient.query({
      query: GET_SIMULATION,
      variables: { id: simulationId }
    })
    if (_has(simulation, 'landingPage.name') && (_has(simulation, 'emailTemplate.name') || _has(simulation, 'config.content'))) {
      await sendTestEmail({ simulationId, email, firstName, lastName, deliveryMethod })
      success = true
    } else {
      throw new Error(I18n.t('anErrorOccurredSendingTheTestEmail', trOpt))
    }
  } catch (e) {
    console.error(`sendTestEmailForTemplate.onSendTest[${simulationId}] - ERROR`, e)
    showErrors(e, I18n.t('anErrorOccurredSendingTheTestEmail', trOpt))
  }
  return success
}

export const openSimulationLandingPage = async id => {
  try {
    const { data: { simulation } = {} } = await authenticatedClient.query({
      query: GET_SIMULATION,
      variables: { id }
    })

    const landingLink = _has(simulation, 'landingPage.name') ? generateEmailLink(simulation) : null
    if (!landingLink) {
      throw Error(I18n.t('emptyLandingPageLinkError', trOpt))
    }
    window.open(landingLink, '_blank')
  } catch (e) {
    showErrors(e, I18n.t('anErrorOccurredOpeningTheLandingPage', trOpt))
  }
}

export const generateEmailLink = simulation => {
  const link = CLIENT_ENV === 'production' ? _get(simulation, 'config.link', landingPageURL) : landingPageURL
  if (!link) {
    return
  }

  const { id, companyId, landingPageId } = simulation || {}
  const queryVars = {
    s: id || -1,
    l: -1
  }
  if (queryVars.s === -1) {
    queryVars.sc = companyId
    queryVars.t = landingPageId
  }

  return `${link}/?${queryString.stringify(queryVars)}`
}

const addSenderSimulationProps = (sender, simulation = {}) => {
  if (_isString(sender)) {
    const [senderPrefix, senderDomain] = (sender || '').split('@')
    simulation.customSender = false
    simulation.senderPrefix = senderPrefix
    simulation.senderDomain = senderDomain
  } else if (_isObject(sender)) {
    const { custom, name, email, prefix, domain } = sender
    simulation.customSender = custom
    simulation.senderName = name

    if (custom === true && email) {
      simulation.senderEmail = email
    } else {
      simulation.senderPrefix = prefix
      simulation.senderDomain = domain
    }
  }

  return simulation
}

export const getEmailTemplateSourceSimulationProps = (emailTemplate, simulation = {}) => {
  if (emailTemplate.content) {
    simulation.emailTemplateId = 'freeform'
    const {
      id,
      subject,
      availableSenderDomains,
      customSender: custom,
      senderName: name,
      senderEmail: email,
      senderPrefix: prefix, senderDomain: domain,
      content
    } = emailTemplate || {}
    simulation.sourceEmailTemplateId = id
    simulation.content = content
    simulation = addSenderSimulationProps({ custom, name, email, prefix, domain }, simulation)
    if (subject && !simulation.subject) {
      simulation.subject = subject
    }
    if (_isArray(availableSenderDomains) && !_isEmpty(availableSenderDomains)) {
      simulation.availableSenderDomains = availableSenderDomains
    }
  }

  return simulation
}

export const getUpdateSimulationProps = (rawSimulation, type = 'update') => {
  // Parse rawSimulation to fit CreateSimulation State
  const hasCustomContent = rawSimulation.emailTemplateId === null && _has(rawSimulation, 'config.content')
  const { config = {}, queue } = rawSimulation || {}
  let simulation = {
    ..._pick(rawSimulation, ['id', 'name', 'emailTemplateId', 'landingPageId', 'startDate']),
    ..._pick(config, ['content', 'spread', 'legacy', 'subject', 'workingHours', 'courseId', 'sendToAll'])
  }

  let initialStep
  if (type === 'clone' || type === 'createRec') {
    if (!simulation.landingPageId) {
      initialStep = 'landing'
    } else if (!simulation.emailTemplateId && !hasCustomContent) {
      initialStep = 'email'
    } else {
      initialStep = 'configure'
    }
  }
  if (simulation.emailTemplateId === null && hasCustomContent) {
    simulation.emailTemplateId = 'freeform'
  }

  if (config) {
    const { sender } = config || {}
    simulation = addSenderSimulationProps(sender, simulation)

    if (config.link) {
      simulation.link = config.link.replace('https://', '')
    }

    simulation.deliveryMethod = config.deliveryMethod
  }

  // Use email template as source for freeform email
  if (type === 'createRec' && _get(rawSimulation, 'config.useEmailSource') === true && _has(rawSimulation, 'emailTemplate.content')) {
    simulation = getEmailTemplateSourceSimulationProps(_get(rawSimulation, 'emailTemplate'), simulation)

    simulation.emailTemplateId = 'freeform'
    simulation.content = _get(rawSimulation, 'emailTemplate.content')
    const {
      id,
      subject,
      availableSenderDomains,
      customSender: custom,
      senderName: name,
      senderEmail: email,
      senderPrefix: prefix, senderDomain: domain
    } = _get(rawSimulation, 'emailTemplate') || {}
    simulation.sourceEmailTemplateId = id
    simulation = addSenderSimulationProps({ custom, name, email, prefix, domain }, simulation)
    if (subject && !simulation.subject) {
      simulation.subject = subject
    }
    if (_isArray(availableSenderDomains) && !_isEmpty(availableSenderDomains)) {
      simulation.availableSenderDomains = availableSenderDomains
    }
  }

  if (queue) {
    simulation.recipients = {
      groups: [],
      learners: _sortBy(queue, ['learner.lastName']).map(({ learner: { id, name: label } = {} }) => ({ id, label }))
    }
  }

  return {
    initialStep,
    simulation
  }
}

export const isEmailTemplateContentValid = (rawContent, { legacy = false }) => {
  let content
  if (legacy) {
    content = rawContent ? Plain.serialize(rawContent) : ''
  } else {
    content = rawContent?.html
    // html isn't generated until the editor has been rendered so stringifying JSON instead
    if (!content && rawContent?.design) {
      content = JSON.stringify(rawContent.design)
    }
  }

  return Boolean(
    content &&
    [
      /\[\[(.*)\]\]/, /%phishing_link%/, /%phishing_url%/, // Contains phishing link
      /usecure-uphish-qr-code/, /u_content_custom_qr_code/ // Contains QR code representing the phishing link
    ].some(patt => patt.test(content))
  )
}

export default {
  clearLandingPageServiceCache,
  clearLandingPageContentCache,
  clearLandingPageSimulationCache,
  generateEmailLink,
  getRecipientIds,
  getSimulationMutationVariables,
  openSimulationLandingPage,
  sendTestEmailForTemplate,
  getEmailTemplateSourceSimulationProps,
  getUpdateSimulationProps,
  isEmailTemplateContentValid
}
