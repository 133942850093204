import React from 'react'
import { Descriptions, Tag } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import I18n from 'i18n-js'

const i18nBase = 'common.modalPageAccess'

const ModalPageAccess = ({ record, theme, role }) => {
  const { global, companyId, company, companies = [] } = record
  const { name: companyName } = company || {}
  const isUsecureAdmin = role === 'usecure-admin'

  let globalLabel = I18n.t(`${i18nBase}.availableToAllYourClients`)
  if (isUsecureAdmin) {
    globalLabel = companyId ? I18n.t(`${i18nBase}.availableToAllOfTheOwnersClients`) : I18n.t(`${i18nBase}.availableToAllClients`)
  }

  return (
    <div>
      <Descriptions layout='vertical'>
        {isUsecureAdmin && <Descriptions.Item label={I18n.t('common.fields.owner')}>{companyName || I18n.t('common.managedByUsecure')}</Descriptions.Item>}
        <Descriptions.Item label={globalLabel} span={isUsecureAdmin ? 2 : 3}>{global ? I18n.t('common.yes') : I18n.t('common.no')}</Descriptions.Item>
        {
          !global &&
            <Descriptions.Item label={I18n.t('common.accessControl.companyAccessLabel')} span={3}>
              {
                _isEmpty(companies)
                  ? <h4>{I18n.t(`${i18nBase}.noCompaniesSelected`)}</h4>
                  : companies.map(({ id, name }) => (
                    <Tag key={id} color={theme.primary}>{name}</Tag>
                  ))
              }
            </Descriptions.Item>
        }
      </Descriptions>
    </div>
  )
}

export default ModalPageAccess
