import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import I18n from 'i18n-js'
import { GET_LEARNER_ME } from '../components/Queries/Learners'
import { DEFAULT_LANGUAGE } from '../constants/languages'
/**
 * @param {client, noCache = true, throwError = false}
 * @returns { endUser, sessionExpiresAt }
 */
export const getLearnerMe = async ({ client, noCache = true, throwError = false } = {}) => {
  try {
    const queryResult = await client.query({
      query: GET_LEARNER_ME,
      fetchPolicy: noCache ? 'no-cache' : 'cache-first'
    })
    const endUser = _get(queryResult, 'data.learnerMe.endUser')
    const sessionExpiresAt = _get(queryResult, 'data.learnerMe.sessionExpiresAt')
    const role = _get(queryResult, 'data.learnerMe.role')
    if (throwError && _isNil(endUser) && _isNil(sessionExpiresAt)) {
      throw new Error(I18n.t('common.somethingWentWrong'))
    }
    return { endUser, sessionExpiresAt, role }
  } catch (e) {
    if (throwError) {
      throw e
    }
  }
  return null
}

export const getSessionAndSettingsFromLearnerMe = async (client, throwErrorOnFailedGetMe = false) => {
  const { endUser, sessionExpiresAt, role } = await getLearnerMe({ client, throwError: throwErrorOnFailedGetMe })
  return getEndUserSessionAndSettingsFromData(endUser, sessionExpiresAt, role)
}

export const getSessionFromLearnerMeData = (endUser, sessionExpiresAt, role) => {
  // Setting locale based on equivalent admin logic in getSessionFromMeData
  const { locale: userLocale, company: { locale: companyLocale } = {} } = endUser
  let locale
  if (userLocale) {
    locale = userLocale
  } else if (companyLocale) {
    locale = companyLocale
  } else {
    locale = DEFAULT_LANGUAGE // This comes from client/src/constants/languages
  }

  return {
    learnerId: endUser.id,
    name: endUser.name,
    firstName: endUser.firstName,
    lastName: endUser.lastName,
    companyId: endUser.company.id,
    companyName: endUser.company.name,
    locale: locale,
    expires: sessionExpiresAt,
    email: endUser.email,
    role: role
  }
}
export const getEndUserSettings = endUser => {
  const { uBreachProEnabled = false } = endUser.company
  const companySettings = _get(endUser, 'company.settings')
  const settings = {
    locale: companySettings.locale,
    uLearn: true,
    uPhish: true,
    uBreachEnabled: true,
    uPolicy: true,
    uBreachProEnabled,
    ...companySettings
  }
  return settings
}
export const getEndUserSessionAndSettingsFromData = (endUser, sessionExpiresAt, role) => (
  {
    session: getSessionFromLearnerMeData(endUser, sessionExpiresAt, role),
    settings: getEndUserSettings(endUser)
  }
)
