import React, { useMemo, useCallback } from 'react'
import I18n from 'i18n-js'
import { Button, Select, message, Form } from 'antd'
import { getTimezone } from 'countries-and-timezones'

import MutationFormErrors from './MutationFormErrors'
import { SUPPORTED_TIMEZONES } from '../../constants/timezone'
import { getBrowserTimezone } from '../../helpers/timezone'

const trOpt = { scope: 'mutationForm.mutationFormTimezone' }
const { Option } = Select

const MutationFormTimezone = React.forwardRef(({
  id,
  value,
  onChange,
  label,
  extra,
  formItemStyle,
  required,
  showErrors,
  errors
}, ref) => {
  const handleChange = useCallback((timezone) => {
    onChange(id, timezone)
  }, [id, onChange])

  const onDetectClick = useCallback(timezone => {
    const detectedTimezone = getBrowserTimezone()
    if (detectedTimezone) {
      onChange(id, detectedTimezone)
    } else {
      message.error(I18n.t('detectTimezoneError', trOpt))
    }
  }, [id, onChange])

  const options = useMemo(() => {
    return SUPPORTED_TIMEZONES.map((timezone) => {
      const { utcOffsetStr: offset } = getTimezone(timezone)
      return (
        <Option key={timezone} value={timezone}>{I18n.t('timezoneLabel', { ...trOpt, offset, timezone: timezone.replace('_', ' ') })}</Option>
      )
    })
  }, [])

  return (
    <Form.Item
      {...{ label, extra, required }}
      style={formItemStyle}
      validateStatus={showErrors ? 'error' : undefined}
      help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
    >
      <Select
        {...{ value }}
        name={id}
        onChange={handleChange}
        showSearch
      >
        {options}
      </Select>
      <Button type='default' onClick={onDetectClick}>{I18n.t('detectTimezone', trOpt)}</Button>
    </Form.Item>
  )
})

export default MutationFormTimezone
