import React, { Component, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useQuery } from 'react-apollo'
import { Alert, Button, Col, Divider, Icon, message, Row, Select, TimePicker, Tooltip } from 'antd'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'
import _omit from 'lodash/omit'
import _uniq from 'lodash/uniq'
import _isEqual from 'lodash/isEqual'

import SettingsForm, { SettingsFormFieldExtra } from '../SettingsForm'
import authenticatedClient from '../../../apollo-client/authenticatedClient'
import { GET_DEFAULT_AUTO_ENROL_COURSES, REGENERATE_COURSE_SCHEDULE, RUN_AUTO_ENROL_NOW } from '../../Queries/Courses'
import { UsecureError, createErrorObject, renderParagraphsFragmentFromArray, showErrors } from '../../../helpers'
import MutationFormErrors from '../../MutationForm/MutationFormErrors'
import { Loader, LoadingBlock } from '../../common'
import { modalConfirmAsync } from '../../../helpers/modal'
import { DialogModalContent } from '../../Modals/common'
import { formatDateTime } from '../../../helpers/datetime'
import { UPDATE_AUTO_ENROL_DEFAULT_TENANT_SETTINGS, UPDATE_AUTO_ENROL_SETTINGS } from '../../Queries/Companies'
import { SETTING_DELETE_VALUE } from '../../../constants/settings'

const trOpt = { scope: 'settings.autoEnrol' }
const { Option } = Select

const DEFAULT_AUTO_ENROL_INTERVAL = {
  everyWeeks: 4,
  dayOfWeek: 3,
  hourOfDay: 10,
  minuteOfHour: 30
}

const DAY_KEYS = [1, 2, 3, 4, 5, 6, 0] // moment dayOfWeek 0-6 => Sun-Mon
const WEEK_OPTIONS = Array.from({ length: 12 }, (x, i) => i + 1).map((week) => <Option key={week} value={week}>{week}</Option>)

const ErrorColumn = styled(Col)`
  margin-top: 10px;
`

const SubSettingsContainer = styled.div`
  .ant-divider {
    padding-bottom: 15px; 
    .ant-divider-inner-text {
      color: ${({ theme }) => theme.primary};
    }
  }
  
  .ant-row {
    margin-bottom: 15px
  }
  
  .ant-select {
    width: auto;
    &.auto-enrol-weeks {
      width: 60px;
    }
  }
  .ant-select, .ant-time-picker {
    margin: 0 10px;
  }
  
  .startingHour {
    margin-right: 0!important;
  }
  .startingMinute {
    margin-left: 0!important;
  } 
`

const calculateNextAutoSubscription = (autoSubscribeInterval, prevAutoSubscription) => {
  const { everyWeeks, dayOfWeek, hourOfDay, minuteOfHour } = autoSubscribeInterval || {}

  if (![everyWeeks, dayOfWeek, hourOfDay, minuteOfHour].every(_isNumber)) {
    return
  }

  let nextAutoSubscription = moment().set({
    day: dayOfWeek,
    hour: hourOfDay,
    minutes: minuteOfHour
  })

  // if the previous send out was less than everyWeeks ago then add to that date
  if (moment().diff(moment(prevAutoSubscription), 'weeks') < everyWeeks) {
    nextAutoSubscription = moment(prevAutoSubscription)
      .add(everyWeeks, 'weeks')
      .day(dayOfWeek)
      .hour(hourOfDay)
      .minutes(minuteOfHour)
  }

  if (nextAutoSubscription.toDate() < new Date()) {
    nextAutoSubscription.add(1, 'weeks')
  }

  return nextAutoSubscription.toDate()
}

class AutoSubscriptionIntervalConfig extends Component {
  constructor (props) {
    super(props)

    this.state = {
      autoSubscribeIntervalChanged: false,
      forcingSubscriptionLoading: false,
      forcingSubscriptionSent: false,
      regenerateScheduleLoading: false
    }

    this.handleForceSubscribeClick = this.handleForceSubscribeClick.bind(this)
    this.handleEveryWeeksChange = this.handleEveryWeeksChange.bind(this)
    this.handleDayOfWeekChange = this.handleDayOfWeekChange.bind(this)
    this.handleTimeChange = this.handleTimeChange.bind(this)
    this.reset = this.reset.bind(this)
  }

  validate (value = this.props.value, errors = [], opt, values) {
    if (values.autoSubscription) {
      const { everyWeeks, dayOfWeek, hourOfDay, minuteOfHour } = value || {}
      if (![everyWeeks, dayOfWeek, hourOfDay, minuteOfHour].every(_isNumber)) {
        errors.push(I18n.t('emptyIntervalError', trOpt))
      }
    }

    return errors
  }

  get nextAutoSubscription () {
    return this.props.nextAutoSubscription
  }

  get prevAutoSubscription () {
    return this.props.prevAutoSubscription
  }

  get autoSubscribeInterval () {
    const { everyWeeks, dayOfWeek, hourOfDay, minuteOfHour } = { ...DEFAULT_AUTO_ENROL_INTERVAL, ...(this.props.value || {}) }
    return { everyWeeks, dayOfWeek, hourOfDay, minuteOfHour }
  }

  handleEveryWeeksChange (e) {
    this.updateAutoSubscribeIntervalKey('everyWeeks', e)
  }

  handleDayOfWeekChange (e) {
    this.updateAutoSubscribeIntervalKey('dayOfWeek', e)
  }

  handleTimeChange (value) {
    this.updateAutoSubscribeInterval({
      hourOfDay: value ? value.hours() : null,
      minuteOfHour: value ? value.minutes() : null
    })
  }

  updateAutoSubscribeIntervalKey (key, value) {
    this.updateAutoSubscribeInterval({ [key]: value })
  }

  updateAutoSubscribeInterval (update) {
    this.props.onChange(this.props.id, {
      ...this.props.value,
      ...update
    })
    this.setState({ autoSubscribeIntervalChanged: true })
  }

  reset () {
    this.setState({ autoSubscribeIntervalChanged: false })
  }

  async handleForceSubscribeClick () {
    const confirmed = await modalConfirmAsync({
      content: (
        <DialogModalContent>
          {renderParagraphsFragmentFromArray(I18n.t('forceSubscribeConfirmMessage', trOpt))}
        </DialogModalContent>
      )
    })
    if (!confirmed) return

    this.props.setLoading(true)
    this.setState({ forcingSubscriptionLoading: true, forcingSubscriptionSent: false })

    const hide = message.loading(I18n.t('forceSubscribeLoadingMessage', trOpt), 0)
    let success = false
    let error
    try {
      const result = await authenticatedClient.mutate({
        mutation: RUN_AUTO_ENROL_NOW
      })
      success = _get(result, 'data.runAutoEnrolNow.success') === true
      const errors = _get(result, 'data.runAutoEnrolNow.errors')

      if (!success) {
        error = _isArray(errors) && !_isEmpty(errors) ? createErrorObject(errors) : new Error()
      }
    } catch (e) {
      error = e
    }
    hide()
    this.setState({ forcingSubscriptionLoading: false, forcingSubscriptionSent: success })
    return new Promise(resolve => {
      // Artificial delay for UX reasons
      this.props.setLoading(false)
      setTimeout(() => {
        if (success) {
          message.success(I18n.t('forceSubscribeSuccessMessage', trOpt))
        } else {
          showErrors(error, I18n.t('forceSubscribeErrorMessage', trOpt))
        }
        resolve(success)
      }, 1000)
    })
  }

  getNextSubscription () {
    const userChangedInterval = this.state.autoSubscribeIntervalChanged
    const nextDate = this.nextAutoSubscription

    if (userChangedInterval || !nextDate) {
      return calculateNextAutoSubscription(this.autoSubscribeInterval, this.prevAutoSubscription)
    } else {
      return nextDate
    }
  }

  get nextSendoutText () {
    const next = this.getNextSubscription()
    if (next) {
      return formatDateTime(next)
    } else {
      return '∞'
    }
  }

  get prevSendoutText () {
    const prev = this.prevAutoSubscription
    if (prev) {
      return formatDateTime(prev)
    } else {
      return I18n.t('never', trOpt)
    }
  }

  get intervalTime () {
    const { hourOfDay: hours, minuteOfHour: minutes } = this.autoSubscribeInterval
    return [hours, minutes].every(_isNumber) ? moment().set({ hours, minutes }) : undefined
  }

  renderForceSubscribeButton () {
    if (this.props.defaultTenant) return null
    const { forceSubscribeAllowed = false, disabled } = this.props
    const { forcingSubscriptionLoading, forcingSubscriptionSent } = this.state
    const button = (
      <Button
        type='primary' icon='mail' onClick={this.handleForceSubscribeClick}
        loading={forcingSubscriptionLoading} disabled={!forceSubscribeAllowed || forcingSubscriptionSent || disabled}
      >
        {I18n.t('subscribePeopleNow', trOpt)}
      </Button>
    )
    let body = button
    let tooltipMessage
    if (!forceSubscribeAllowed) {
      tooltipMessage = I18n.t('saveFirstTooltip', trOpt)
    } else if (forcingSubscriptionSent) {
      tooltipMessage = I18n.t('forceSubscribeSentTooltip', trOpt)
    }
    if (tooltipMessage) {
      body = <Tooltip title={tooltipMessage} placement='bottom'>{button}</Tooltip>
    }

    return (
      <Row>
        <Col span={24}>
          <div>
            <p>{I18n.t('forceSubscribeInfo', trOpt)}</p>
            {body}
          </div>
        </Col>
      </Row>
    )
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { defaultTenant = false, errors = [] } = this.props
    const { everyWeeks, dayOfWeek } = this.autoSubscribeInterval
    const fieldErrors = [...errors]
    if (_isNumber(everyWeeks) && everyWeeks < 4) {
      fieldErrors.push(I18n.t('everyWeeksWarning', trOpt))
    }

    return (
      <SubSettingsContainer>
        <Divider>{I18n.t('configTitle', trOpt)}</Divider>
        {
          defaultTenant ? null
            : (
              <Row>
                <Col span={24}>
                  <Alert
                    message={I18n.t('sendOuts', trOpt)}
                    description={
                      <span>
                        <p>{I18n.t('prevSendout', { ...trOpt, text: this.prevSendoutText })}</p>
                        <p>{I18n.t('nextSendout', { ...trOpt, text: this.nextSendoutText })}</p>
                      </span>
                    }
                    type='info'
                    icon={<Icon type='clock-circle' />}
                    showIcon
                  />
                </Col>
              </Row>
            )
        }
        <Row>
          <Col span={24}>
            <span>{I18n.t('every', trOpt)}</span>
            <Select
              className='auto-enrol-weeks'
              value={everyWeeks}
              onChange={this.handleEveryWeeksChange}
            >
              {WEEK_OPTIONS}
            </Select>
            <span>{I18n.t('weeksOn', trOpt)}</span>
            <Select
              value={moment.weekdays(dayOfWeek)}
              dropdownMatchSelectWidth={false}
              onChange={this.handleDayOfWeekChange}
            >
              {DAY_KEYS.map(key => <Option key={key} value={key}>{moment.weekdays(key)}</Option>)}
            </Select>
            <span>{I18n.t('startingAt', trOpt)}</span>
            <TimePicker
              format='HH:mm' minuteStep={15} allowClear={false}
              onChange={this.handleTimeChange}
              value={this.intervalTime} defaultOpenValue={this.intervalTime}
            />
          </Col>
          {
            fieldErrors.length > 0 && (
              <ErrorColumn className='has-error' span={24}>
                <MutationFormErrors visible errors={fieldErrors} />
              </ErrorColumn>
            )
          }
        </Row>
        {this.renderForceSubscribeButton()}
      </SubSettingsContainer>
    )
  }
}

const CourseBlacklistRow = styled(Row)`
  &.ant-row {
    margin-bottom: 20px;
  }

  .ant-select {
    margin: 0;
    width: 100%;
  }
`

const CourseBlacklist = React.forwardRef(({ visible, onChange, value, id, errors = [] }, ref) => {
  const [courses, setCourses] = useState([])
  const { loading, data } = useQuery(GET_DEFAULT_AUTO_ENROL_COURSES)

  useEffect(() => {
    const rawCourses = _get(data, 'getDefaultAutoEnrolCourses')
    let courses = []
    if (_isArray(rawCourses) && !_isEmpty(rawCourses)) {
      courses = [...rawCourses]
        .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
    }
    setCourses(courses)
  }, [data])

  const onCourseSelect = useCallback((courseList) => {
    onChange(id, courseList)
  }, [onChange, id])

  useImperativeHandle(ref, () => ({
    validate: (courseList, errors, { submitting = false } = {}) => {
      // No course data to perform difficulty check against so skip check
      // However missing data should block form submission
      if (submitting || (_isArray(courses) && !_isEmpty(courses))) {
        const difficultiesInAllowedCourses = _uniq(
          courses.reduce((acc, course) => {
            if (!courseList.includes(course.id)) {
              acc.push(course.difficulty)
            }
            return acc
          }, [])
        ).sort()

        // Throw error if any of the difficulty levels aren't present in the allowed courses
        if (!_isEqual([2, 3, 4], difficultiesInAllowedCourses)) {
          errors.push(I18n.t('mustHaveAtLeastOneCourseAllowed', trOpt))
        }
      }
    }
  }), [courses])

  if (!visible) {
    return null
  }

  return (
    <SubSettingsContainer>
      <Divider>{I18n.t('courseBlacklist', trOpt)}</Divider>
      <CourseBlacklistRow>
        <Col span={24}>
          <p>{I18n.t('selectCoursesToExcludeFromAutoEnrol', trOpt)}</p>
          {loading ? <Loader /> : (
            <Select
              mode='multiple'
              allowClear
              placeholder={I18n.t('learners.enrolOnCourseModal.selectCourses')}
              optionFilterProp='label'
              onChange={onCourseSelect}
              value={value}
            >
              {courses.map(item => <Option key={item.id} label={item.displayName}>{item.displayName}</Option>)}
            </Select>
          )}

        </Col>
        {
          errors.length > 0 && (
            <ErrorColumn className='has-error' span={24}>
              <MutationFormErrors visible errors={errors} />
            </ErrorColumn>
          )
        }
      </CourseBlacklistRow>
    </SubSettingsContainer>
  )
})

const AutoEnrolInvalidAlert = React.forwardRef(({ visible }, ref) => (
  visible
    ? (
      <Alert
        message={I18n.t('invalidSettingsTitle', trOpt)}
        description={I18n.t('sendoutDateTimeMissingMessage', trOpt)}
        type='error'
        showIcon
      />
    )
    : null
))

const courseReenrollmentHeader = React.forwardRef(({ visible }, ref) => {
  if (!visible) return null
  return (
    <SubSettingsContainer>
      <Divider>{I18n.t('courseReenrollmentTitle', trOpt)}</Divider>
      <Row>
        <Col span={24}>
          <p>{I18n.t('courseReenrollmentInfo', trOpt)}</p>
        </Col>
      </Row>
    </SubSettingsContainer>
  )
})

const RegenerateCourseSchedule = React.forwardRef(({ visible, regenerateCourseSchedule = () => {}, regenerateScheduleAllowed = false, disabled }, ref) => {
  const [loading, setLoading] = useState(false)
  const onClick = useCallback(async () => {
    const confirmed = await modalConfirmAsync()
    if (!confirmed) return

    setLoading(true)
    await regenerateCourseSchedule()
    setLoading(false)
  }, [regenerateCourseSchedule])
  if (!visible) return null

  const button = (
    <Button
      type='primary' icon='calendar' onClick={onClick}
      loading={loading} disabled={!regenerateScheduleAllowed || disabled}
    >
      {I18n.t('regenerateSchedule', trOpt)}
    </Button>
  )
  let body = button
  if (!regenerateScheduleAllowed) {
    body = <Tooltip title={I18n.t('saveFirstTooltip', trOpt)} placement='bottom'>{button}</Tooltip>
  }

  return (
    <SubSettingsContainer>
      <Divider>{I18n.t('regenerateSchedule', trOpt)}</Divider>
      <Row>
        <Col span={24}>
          <div>
            <p>{I18n.t('regenerateScheduleInfo', trOpt)}</p>
            {body}
          </div>
        </Col>
      </Row>
    </SubSettingsContainer>
  )
})

class AutoEnrol extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'autoSubscription',
      'autoSubscribeInterval',
      'nextAutoSubscription',
      'prevAutoSubscription',
      'blacklistedCourses',
      'courseReenrollment'
    ]

    this.companySettingsMutation = UPDATE_AUTO_ENROL_SETTINGS
    this.defaultTenantSettingsMutation = UPDATE_AUTO_ENROL_DEFAULT_TENANT_SETTINGS

    this.defaultValue = {
      blacklistedCourses: [],
      autoSubscription: false,
      nextAutoSubscription: null,
      prevAutoSubscription: null,
      autoSubscribeInterval: DEFAULT_AUTO_ENROL_INTERVAL,
      courseReenrollment: true
    }

    this.state = {
      changed: false,
      loading: false,
      courseDenyListUpdated: false,
      courseReenrollmentUpdated: false
    }

    this.resizeTriggerFields = ['autoSubscription']
    this.regenerateCourseSchedule = this.regenerateCourseSchedule.bind(this)
    this.setLoading = this.setLoading.bind(this)
  }

  get title () {
    return I18n.t('common.autoEnrol')
  }

  get headerId () {
    return 'auto-enrol-header'
  }

  get description () {
    return (
      <>
        <p>{I18n.t('description1', trOpt)}</p>
        <p>{I18n.t('description2', trOpt)}</p>
      </>
    )
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }

  get mutationName () {
    return this.props.defaultTenant ? 'updateDefaultTenantAutoEnrolSettings' : 'updateCompanyAutoEnrolSettings'
  }

  mutateValues (values) {
    if (values.autoSubscription !== true) {
      return {
        ..._omit(values, [
          'invalidSettingsAlert', 'regenerateCourseSchedule', 'courseReenrollmentHeader',
          'autoSubscription', 'initialAutoSubscription', 'prevAutoSubscription', 'nextAutoSubscription', 'failedAutoSubscription'
        ]),
        autoSubscription: false,
        initialAutoSubscription: SETTING_DELETE_VALUE,
        prevAutoSubscription: SETTING_DELETE_VALUE,
        nextAutoSubscription: SETTING_DELETE_VALUE,
        failedAutoSubscription: SETTING_DELETE_VALUE
      }
    }
    return {
      ..._omit(values, ['invalidSettingsAlert', 'regenerateCourseSchedule', 'courseReenrollmentHeader']),
      initialAutoSubscription: values.nextAutoSubscription
    }
  }

  setLoading (loading) {
    this.setState({ loading })
  }

  async regenerateCourseSchedule () {
    this.setLoading(true)
    const hide = message.loading(I18n.t('regenerateScheduleLoadingMessage', trOpt), 0)
    let success = false
    let error
    try {
      const result = await authenticatedClient.mutate({
        mutation: REGENERATE_COURSE_SCHEDULE
      })
      success = _get(result, 'data.regenerateCourseSchedule') === true
    } catch (e) {
      error = e
    }
    hide()
    return new Promise(resolve => {
      // Artificial delay for UX reasons
      this.setLoading(false)
      setTimeout(() => {
        if (success) {
          message.success(I18n.t('regenerateScheduleSuccessMessage', trOpt))
        } else {
          showErrors(error, I18n.t('regenerateScheduleErrorMessage', trOpt))
        }
        resolve(success)
      }, 1000)
    })
  }

  onChange (id, value) {
    super.onChange(id, value)
    const stateUpdate = { changed: true }
    if (id === 'blacklistedCourses') {
      stateUpdate.courseDenyListUpdated = !_isEqual(value, _get(this.props, 'settings.blacklistedCourses'))
    } else if (id === 'courseReenrollment') {
      stateUpdate.courseReenrollmentUpdated = value !== _get(this.props, 'settings.courseReenrollment')
    }
    this.setState(stateUpdate)
  }

  get _fields () {
    const { settings = {}, defaultTenant = false } = this.props
    const { changed } = this.state
    const {
      autoSubscription,
      nextAutoSubscription,
      prevAutoSubscription,
      autoSubscribeInterval,
      blacklistedCourses,
      courseReenrollment
    } = {
      ...this.defaultValue,
      ...settings
    }
    const {
      autoSubscription: currentAutoSubscription,
      initialAutoSubscription: currentInitialAutoSubscription,
      nextAutoSubscription: currentNextAutoSubscription
    } = settings

    const { disableSubmit } = this.props

    // configFieldVisible will make the auto enrol configuration fields visible on the default customer settings version of the page
    // This allows MSPs to set auto enrol defaults for new customers but control the launch of their training programme by enabling auto enrol when ready
    const configFieldVisible = defaultTenant ? true : values => values.autoSubscription === true

    return [
      {
        id: 'autoSubscription',
        type: 'switch',
        label: I18n.t('autoSubscription', trOpt),
        defaultValue: autoSubscription,
        extra: defaultTenant && (
          <SettingsFormFieldExtra copy={I18n.t('autoSubscriptionDefaultTenantExtra', trOpt)} />
        )
      }, {
        id: 'invalidSettingsAlert',
        type: 'custom',
        component: AutoEnrolInvalidAlert,
        visible: defaultTenant ? false : values => values.autoSubscription && currentAutoSubscription === true && (!currentInitialAutoSubscription || !currentNextAutoSubscription)
      }, {
        id: 'autoSubscribeInterval',
        type: 'custom',
        defaultValue: autoSubscribeInterval,
        visible: configFieldVisible,
        validWhenHidden: false,
        component: AutoSubscriptionIntervalConfig,
        prevAutoSubscription,
        nextAutoSubscription,
        defaultTenant,
        forceSubscribeAllowed: currentAutoSubscription === true && currentInitialAutoSubscription && !changed,
        disabled: disableSubmit,
        setLoading: this.setLoading
      },
      {
        id: 'blacklistedCourses',
        type: 'custom',
        defaultValue: blacklistedCourses,
        visible: configFieldVisible,
        validWhenHidden: false,
        component: CourseBlacklist,
        blacklistedCourses,
        autoSubscription
      },
      {
        id: 'nextAutoSubscription',
        type: 'datetime',
        defaultValue: nextAutoSubscription,
        mutateValue: (value, values) => calculateNextAutoSubscription(values.autoSubscribeInterval, prevAutoSubscription),
        visible: false
      },
      {
        id: 'courseReenrollmentHeader',
        type: 'custom',
        component: courseReenrollmentHeader,
        visible: configFieldVisible
      },
      {
        id: 'courseReenrollment',
        type: 'switch',
        label: I18n.t('courseReenrollment', trOpt),
        defaultValue: courseReenrollment,
        visible: configFieldVisible
      },
      {
        id: 'regenerateCourseSchedule',
        type: 'custom',
        component: RegenerateCourseSchedule,
        visible: values => !defaultTenant && values.autoSubscription === true,
        regenerateCourseSchedule: this.regenerateCourseSchedule,
        regenerateScheduleAllowed: !changed,
        disabled: disableSubmit
      }
    ]
  }

  render () {
    return (
      <>
        <LoadingBlock loading={this.state.loading} fullScreen={false} />
        {super.render()}
      </>
    )
  }

  async onSubmit (values) {
    const { everyWeeks } = values?.autoSubscribeInterval || {}
    // Show confirm dialog if Auto Enrol frequency is less 4 weeks - only applies when Auto Enrol is enabled or on the MSP Default Customer Settings page
    if ((this.props.defaultTenant === true || values?.autoSubscription === true) && _isNumber(everyWeeks) && everyWeeks < 4) {
      const confirm = await modalConfirmAsync({
        icon: <Icon type='exclamation-circle' />,
        content: I18n.t('everyWeeksWarning', trOpt)
      })
      if (!confirm) {
        throw new UsecureError('save_cancelled', { level: 'silent' })
      }
    }
  }

  onFailure (e) {
    // Do not show silent UsecureErrors i.e. the save_cancelled error above
    showErrors(e, this.failureMsg, {
      showDefaultIfEmpty: !(e?.isUsecure === true && e?.level === 'silent')
    })
  }

  onSuccess (result) {
    super.onSuccess(result)

    // Regenerate course schedule if course deny list has changed
    // Performed separately from the settings save to avoid potential timeout issues on larger clients (500+)
    // We can run this via a script if it fails
    if (!this.props.defaultTenant && (this.state.courseDenyListUpdated || this.state.courseReenrollmentUpdated)) {
      this.regenerateCourseSchedule()
    }

    this.setState({
      changed: false,
      courseDenyListUpdated: false,
      courseReenrollmentUpdated: false
    })
  }
}

export default AutoEnrol
