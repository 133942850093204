import React from 'react'

import { IntroDetail, IntroWrap } from './LearnerCourseIntro'
import { HoldingContent } from '../../views/Holding'

// TODO Check whether it's okay that this shows the same content as the main app e.g. the office phone number
const LearnerCourseholding = () => {
  return (
    <IntroWrap>
      <IntroDetail>
        <HoldingContent />
      </IntroDetail>
    </IntroWrap>
  )
}

export default LearnerCourseholding
