import React, { useCallback, useState } from 'react'
import { Modal as _Modal, message, Alert, Button } from 'antd'
import { Query } from 'react-apollo'
import styled from 'styled-components'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { ENROL_ON_COURSE, GET_COURSES } from '../Queries/Courses'
import { GET_LEARNER_COURSES } from '../Queries/Learners'
import { ErrorAlerts } from '../common'
import { COURSE_SUBJECT_OPTIONS } from '../../constants/courses'
import { invalidateLearnersAndLearnerDataQueryCache } from '../../helpers'

const trOpt = { scope: 'learners.enrolOnCourseModal' }

const Modal = styled(_Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    margin-right: 15px;

    h1 {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`

const EnrolOnCourseModalFooter = styled.div`
  display: flex;
`
const EnrolOnCourseModalFooterAlertColumn = styled.div`
  flex: 1;

  .ant-alert {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const EnrolOnCourseModalFooterButtonColumn = styled.div`
  padding-left: 10px;
  text-align: right;
`

const EnrolOnCourseModal = ({ learners = [], visible = false, setVisible = () => {}, onClose = () => {} }) => {
  const form = React.createRef()
  const learnerIds = learners.map(learner => learner.id, 10)
  const singleLearner = learnerIds.length === 1
  const learnerName = singleLearner ? learners[0].name : undefined

  const [submitLabel, updateSubmitLabel] = useState(I18n.t('enrol', trOpt))
  const [enrolledMessage, updateEnrolledMessage] = useState(null)
  const [finishedMessage, updateFinishedMessage] = useState(null)
  // Holding Apollo errors in state prevents the nesting of Query components masking errors
  // For example, if GET_COURSES errors but GET_LEARNER_COURSES does not then we don't always see the GET_COURSES error as it becomes undefined
  const [learnerCoursesErr, updateLearnerCoursesErr] = useState(null)
  const [coursesErr, updateCoursesErr] = useState(null)

  const closeModal = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.resetFields()
    }
    updateSubmitLabel(I18n.t('enrol', trOpt))
    updateEnrolledMessage(null)
    updateFinishedMessage(null)
    updateLearnerCoursesErr(null)
    updateCoursesErr(null)
    onClose()
  }, [setVisible, form, onClose])
  const onCancel = useCallback(() => closeModal(), [closeModal])

  let title
  if (singleLearner && learnerName) {
    title = I18n.t('enrolUserNameOnCourses', { ...trOpt, learnerName })
  } else if (singleLearner) {
    title = I18n.t('enrolUserOnCourses', trOpt)
  } else {
    title = I18n.t('enrolUsersOnCourses', trOpt)
  }

  return (
    <Modal
      visible={visible} onCancel={onCancel} footer={null}
      title={<h1>{title}</h1>}
      width='600px'
    >
      <Query query={GET_LEARNER_COURSES} variables={{ learnerId: learnerIds[0] }} skip={!(visible && singleLearner)} onError={updateLearnerCoursesErr}>
        {({ loading: learnerCoursesLoading, error: learnerCoursesError, data: learnerCoursesData = {} }) => (
          <Query
            query={GET_COURSES} skip={!visible} variables={{ excludeGapAnalysis: true, restrictToPlan: true, withCompany: true }} onError={updateCoursesErr}
          >
            {({ loading: coursesLoading, error: coursesError, data: coursesData = {} }) => {
              const { courses = [] } = coursesData
              const subjectOptions = [
                { value: '{all}', label: I18n.t('common.all') },
                ...COURSE_SUBJECT_OPTIONS.filter(({ value }) => courses.some(course => course.subject === value))
              ]
              const courseOptions = courses
              // Sort by difficulty then sort by displayName
                .sort((a, b) => a.difficulty - b.difficulty || a.displayName.toLowerCase() > b.displayName.toLowerCase())
                .map(course => ({ value: course.id, label: course.displayName, linkFieldValue: course.subject }))

              const variables = {
                learnerIds
              }
              const fields = [
                {
                  id: 'subject',
                  label: I18n.t('common.fields.subject'),
                  type: 'select',
                  required: true,
                  defaultValue: 'InfoSec',
                  options: subjectOptions
                },
                {
                  id: 'courseIds',
                  label: I18n.t('courses', trOpt),
                  type: 'multiSelect',
                  required: true,
                  linkField: 'subject',
                  placeholder: I18n.t('selectCourses', trOpt),
                  options: courseOptions,
                  mutateValue: values => values || []
                }
              ]

              const reduceCourseNames = (key, actionKey, learnerName, courseNames = []) => {
                return I18n.t(key, {
                  ...trOpt,
                  learnerName,
                  action: I18n.t(actionKey, { ...trOpt, count: courseNames.length }),
                  count: courseNames.length,
                  // Assumes list syntax similar to English
                  // We could probably do with a helper for this that takes locale as an arg to handle other syntax
                  courses: courseNames.reduce((message, name, index, names) => {
                    if (names.length > 1 && index === names.length - 1) {
                      message += ` ${I18n.t('common.and')}`
                    } else if (index > 0) {
                      message += ','
                    }
                    message += ` "${name}"`
                    return message
                  }, '')
                })
              }

              const updateMessagesandLabel = (selectedCourses) => {
                let submitLabel = I18n.t('enrol', trOpt)
                let enrolledMessage
                let finishedMessage
                if (singleLearner && selectedCourses.length > 0) {
                  const { learner: { courseResults: learnerCourses = [] } = {} } = learnerCoursesData
                  const enrolledCourses = learnerCourses.filter(l => l.finishDate === null).map(l => l.courseId)
                  const finishedCourses = learnerCourses.filter(l => l.finishDate !== null).map(l => l.courseId)

                  const enrolledCourseNames = []
                  const finishedCourseNames = []
                  selectedCourses.forEach(courseId => {
                    const course = courses.find(c => `${c.id}` === courseId)
                    if (enrolledCourses.includes(courseId)) {
                      enrolledCourseNames.push(course.displayName)
                    } else if (finishedCourses.includes(courseId)) {
                      finishedCourseNames.push(course.displayName)
                    }
                  })

                  const allEnrolled = enrolledCourseNames.length === selectedCourses.length
                  if (enrolledCourseNames.length > 0) {
                    enrolledMessage = reduceCourseNames('alreadyEnrolledMessage', 'reminderMessage', learnerName, enrolledCourseNames)
                  }
                  const allFinished = finishedCourseNames.length === selectedCourses.length
                  if (finishedCourseNames.length > 0) {
                    finishedMessage = reduceCourseNames('previouslyCompletedMessage', 'reenrolMessage', learnerName, finishedCourseNames)
                  }

                  if (allEnrolled) {
                    submitLabel = I18n.t('common.sendReminder')
                  } else if (allFinished) {
                    submitLabel = I18n.t('enrolAgain', trOpt)
                  }
                }
                updateSubmitLabel(submitLabel)
                updateEnrolledMessage(enrolledMessage)
                updateFinishedMessage(finishedMessage)
              }

              const onChange = (name, values) => {
                if (name === 'courseIds') {
                  updateMessagesandLabel(values)
                }
              }

              const onSuccess = ({ data: { enrollLearnersOnCourses: { success = false, completed = [], courseIds = [], learnerIds = [] } = {} } = {} }) => {
                const learnerCount = learnerIds.length
                // TODO Refine notification text to match v1
                courseIds.forEach(courseId => {
                  const course = courses.find(course => course.id === `${courseId}`)
                  const courseName = course ? course.displayName : I18n.t('unknownCourse', trOpt)
                  const completedCount = completed[courseId].length

                  if (completedCount === learnerCount) {
                    message.success(I18n.t('allEnrolled', { ...trOpt, count: completedCount, courseName }))
                  } else if (completedCount === 0) {
                    message.error(I18n.t('noneEnrolled', { ...trOpt, count: learnerCount, courseName }))
                  } else {
                    message.warning(I18n.t('partiallyEnrolled', { ...trOpt, completedCount, count: learnerCount, courseName }))
                  }
                })

                if (success) {
                  closeModal()
                }
              }

              const renderFooter = ({ submitLabel, loading }) => (
                <EnrolOnCourseModalFooter>
                  <EnrolOnCourseModalFooterAlertColumn>
                    {enrolledMessage ? <Alert message={enrolledMessage} /> : null}
                    {finishedMessage ? <Alert message={finishedMessage} /> : null}
                  </EnrolOnCourseModalFooterAlertColumn>
                  <EnrolOnCourseModalFooterButtonColumn>
                    <Button type='primary' ghost disabled={loading} htmlType='submit'>{submitLabel}</Button>
                  </EnrolOnCourseModalFooterButtonColumn>
                </EnrolOnCourseModalFooter>
              )

              return (
                <div>
                  <ErrorAlerts error={[learnerCoursesErr, coursesErr]} />
                  <MutationForm
                    mutation={ENROL_ON_COURSE}
                    onChange={onChange}
                    onSuccess={onSuccess}
                    failureMessage={I18n.t('anErrorOccurredEnrollingOntoThisSelectedCourse', trOpt)}
                    submitLabel={submitLabel} variables={variables}
                    disableSubmitIfInvalid={false}
                    fields={fields}
                    ref={form}
                    footer={renderFooter}
                    update={invalidateLearnersAndLearnerDataQueryCache}
                  />
                </div>
              )
            }}
          </Query>
        )}
      </Query>
    </Modal>
  )
}

export default EnrolOnCourseModal
