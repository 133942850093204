/* global localStorage */
import React, { Component } from 'react'
import { Card, Modal, Steps, Button, message } from 'antd'
import styled from 'styled-components'
import { Value } from 'slate'
import moment from 'moment'
import { Link } from 'react-router-dom'
import I18n from 'i18n-js'
import { compose } from 'recompose'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _endsWith from 'lodash/endsWith'

import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import {
  CreateSimulationConfigure, CreateSimulationSchedule,
  CreateSimulationEmailTemplates, CreateSimulationLandingPages,
  EmailTemplateForm, LandingPageForm, UpdateSimulationMutateButton
} from '../../components/uPhish'
import CreateSimulationConfirm from '../../components/Modals/CreateSimulationConfirm'
import { GET_SIMULATION_EMAIL_TEMPLATE } from '../../components/Queries/uPhish'
import { LoadingBlock } from '../../components/common'
import routes from '../../constants/routes'
import { getEmailTemplateSourceSimulationProps, isEmailTemplateContentValid } from '../../helpers/uPhish'
import { isMessageInjectionAuthorised } from '../../helpers/messageInjection'

const trOpt = { scope: 'uPhish.createSimulation' }

const { Step } = Steps

const CreateSimulationContent = styled.div`
  padding-bottom: 100px;
`

const CreateSimulationFooter = styled.div`
  background-color: #ffffff;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 100px 20px 15px;
  position: fixed;
  width: 100%;
`

const StyledSteps = styled(Steps)`
  margin-bottom: 15px;
  max-width: 1100px;
  min-width: 725px;
`

const UpdateButtonWrap = styled.div`
  display: flex;

  &> * {
    margin-left: 5px;
  }
`

const defaultState = {
  /**
   * landing, email, configure, schedule
   */
  step: 'landing',
  stepHistory: ['landing'],
  showForm: false,
  showConfirm: false,
  contentValid: false,

  id: null,
  landingPageId: null,
  emailTemplateId: null,
  sourceEmailTemplateId: null,
  name: null,
  subject: null,
  customSender: false,
  senderPrefix: null,
  senderDomain: null,
  senderName: null,
  senderEmail: null,
  content: null,
  legacy: false,
  link: null,
  recipients: {
    learners: [],
    groups: []
  },
  sendToAll: false,
  workingHours: false,
  spread: 6,
  startDate: moment().toISOString(),
  courseSubject: '{all}',
  courseId: null,
  availableSenderDomains: null,
  loading: false,
  contentLocales: [],
  deliveryMethod: null
}

class CreateSimulation extends Component {
  constructor (props) {
    super(props)

    this.state = { ...this.defaultState }

    this.steps = ['landing', 'email', 'configure', 'schedule']

    this.getSimulationData = this.getSimulationData.bind(this)
    this.handleConfigureSubmit = this.handleConfigureSubmit.bind(this)
    this.handleEmailTemplateCreateCancel = this.handleEmailTemplateCreateCancel.bind(this)
    this.handleEmailTemplateCreateSuccess = this.handleEmailTemplateCreateSuccess.bind(this)
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this)
    this.handleLandingPageCreateCancel = this.handleLandingPageCreateCancel.bind(this)
    this.handleLandingPageCreateSuccess = this.handleLandingPageCreateSuccess.bind(this)
    this.handleScheduleSubmit = this.handleScheduleSubmit.bind(this)
    this.handleStepChange = this.handleStepChange.bind(this)
    this.resetState = this.resetState.bind(this)
    this.setShowConfirm = this.setShowConfirm.bind(this)
    this.updateEmailTemplate = this.updateEmailTemplate.bind(this)
    this.useEmailTemplateSource = this.useEmailTemplateSource.bind(this)
    this.updateLandingPage = this.updateLandingPage.bind(this)
    this.goToEmail = this.goToEmail.bind(this)
    this.goToConfigure = this.goToConfigure.bind(this)
    this.setContentLocales = this.setContentLocales.bind(this)

    this.view = React.createRef()
    this.templateForm = React.createRef()
  }

  get defaultState () {
    return {
      ...defaultState,
      contentLocales: this.props.contentLocales || []
    }
  }

  get id () {
    return this.props.id || 'uPhish-create-simulation-header'
  }

  get updating () {
    return this.props.update === true
  }

  get cloning () {
    return this.props.clone === true
  }

  get creating () {
    return !this.cloning && !this.updating
  }

  updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        this.updateLocalStorage()
        resolve()
      })
    })
  }

  // Reset to default
  async resetState () {
    await this.updateState(this.defaultState)
    if (this.cloning) {
      await this.startSimulationUpdate()
    }
    localStorage.removeItem(this.storageId)
  }

  get storageId () {
    const { pageId = 'createSimulation', userId, companyId, simulationId } = this.props
    // Local storage isn't used when updating but there's a different storage id just in case this changes
    return this.updating ? `${pageId}|${simulationId}|${companyId}|${userId}` : `${this.cloning ? 'createSimulation' : pageId}|${companyId}|${userId}`
  }

  updateLocalStorage () {
    if (!this.updating) {
      const { simulationId } = this.props
      const storedCopy = { ...this.state }
      if (this.cloning) {
        storedCopy.sourceSimulationId = simulationId
      }
      localStorage.setItem(this.storageId, JSON.stringify(_omit(storedCopy, ['loading'])))
    }
  }

  async goToStep (step) {
    const { stepHistory } = this.state
    const completedLanding = this.isLandingComplete
    const completedEmail = completedLanding && this.isEmailComplete

    if (stepHistory.includes(step) ||
      (step === 'email' && completedLanding) ||
      (step === 'configure' && completedEmail) ||
      (step === 'schedule' && this.isConfigureComplete)
    ) {
      stepHistory.push(step)
      await this.updateState({ step, stepHistory })
      window.scrollTo(0, 0)
    }
  }

  findStepIndex (step) {
    return this.steps.indexOf(step)
  }

  get stepIndex () {
    return this.findStepIndex(this.state.step)
  }

  handleStepChange (stepIndex) {
    const step = this.steps[stepIndex]
    if (step) {
      this.goToStep(step)
    }
  }

  showForm (showForm) {
    this.updateState({ showForm })
    // Scroll to the top if true
    if (showForm) {
      window.scrollTo(0, 0)
    }
  }

  async updateLandingPage (landingPageId) {
    if (landingPageId === 'create') {
      this.showForm(true)
    } else {
      await this.updateState({ landingPageId })
      this.goToStep('email')
    }
  }

  handleLandingPageCreateCancel () {
    this.goToStep('landing')
    this.showForm(false)
  }

  async handleLandingPageCreateSuccess (result) {
    const landingPageId = _get(result, 'data.createSimulationLandingPage.id')
    await this.updateLandingPage(landingPageId)
    await this.showForm(false)
  }

  get isLandingComplete () {
    return Boolean(this.state.landingPageId)
  }

  async setContentLocales (contentLocales) {
    return this.updateState({ contentLocales })
  }

  async updateEmailTemplate (emailTemplateId) {
    if (emailTemplateId === 'create') {
      this.showForm(true)
    } else if (emailTemplateId === 'freeform') {
      await this.updateState({
        emailTemplateId,
        sourceEmailTemplateId: null,
        availableSenderDomains: null,
        subject: null,
        customSender: false,
        senderPrefix: null,
        senderDomain: null,
        senderName: null,
        senderEmail: null,
        content: null
      })
      this.goToStep('configure')
    } else {
      try {
        await this.updateState({ loading: true })
        const { tldAllowList } = this.props
        const { data: { simulationEmailTemplate } = {} } = await this.props.client.query({
          query: GET_SIMULATION_EMAIL_TEMPLATE,
          variables: { id: emailTemplateId }
        })
        const {
          availableSenderDomains = [],
          subject,
          customSender,
          senderName,
          senderEmail,
          senderPrefix
        } = simulationEmailTemplate || {}
        let { senderDomain } = simulationEmailTemplate || {}

        // Only use email template sender domain if it conforms with the user's TLD preferences if present
        if (senderDomain && _isArray(tldAllowList) && !_isEmpty(tldAllowList) && !tldAllowList.some(tld => _endsWith(senderDomain, tld))) {
          senderDomain = null
        }

        await this.updateState({
          emailTemplateId,
          sourceEmailTemplateId: null,
          availableSenderDomains,
          subject,
          customSender,
          senderPrefix,
          senderDomain,
          senderName,
          senderEmail,
          content: null
        })
        this.goToStep('configure')
      } catch (e) {
        message.error(I18n.t('uPhish.createSimulationConfigure.emailTemplateError'))
      } finally {
        await this.updateState({ loading: false })
      }
    }
  }

  async useEmailTemplateSource (sourceEmailTemplateId) {
    try {
      await this.updateState({ loading: true })
      const { data: { simulationEmailTemplate } = {} } = await this.props.client.query({
        query: GET_SIMULATION_EMAIL_TEMPLATE,
        variables: { id: sourceEmailTemplateId }
      })
      await this.updateState({
        sourceEmailTemplateId,
        ...getEmailTemplateSourceSimulationProps(simulationEmailTemplate)
      })
      this.goToStep('configure')
    } catch (e) {
      message.error(I18n.t('uPhish.createSimulationConfigure.emailTemplateError'))
    } finally {
      await this.updateState({ loading: false })
    }
  }

  async handleEmailTemplateCreateSuccess (result) {
    const emailTemplateId = _get(result, 'data.createSimulationEmailTemplate.id')
    await this.updateEmailTemplate(emailTemplateId)
    await this.showForm(false)
  }

  handleEmailTemplateCreateCancel () {
    this.goToStep('email')
    this.showForm(false)
  }

  goToEmail () {
    this.goToStep('email')
  }

  goToConfigure () {
    this.goToStep('configure')
  }

  get isEmailComplete () {
    return Boolean(this.state.emailTemplateId)
  }

  async handleFormFieldChange (name, value) {
    await this.updateState({ [name]: value })

    if (name === 'content') {
      return this.updateContentValid()
    }
  }

  async updateContentValid (content) {
    const { emailTemplateId } = this.state
    let contentValid = true
    if (emailTemplateId === 'freeform') {
      contentValid = this.isContentValid(content)
    }
    return this.updateState({ contentValid })
  }

  async handleConfigureSubmit (data) {
    await this.updateState(data)
    this.goToStep('schedule')
  }

  isContentValid (rawContent = this.state.content) {
    return isEmailTemplateContentValid(rawContent, { legacy: this.state.legacy })
  }

  get isConfigureComplete () {
    const { name, subject, customSender, senderPrefix, senderDomain, senderEmail, link, contentValid } = this.state
    const senderValid = Boolean(customSender ? senderEmail : (senderPrefix && senderDomain))
    return Boolean(name && subject && senderValid && link && contentValid)
  }

  getSimulationData () {
    const viewState = this.view.current ? this.view.current.getValues() : {}
    const data = _pick({ ...this.state, ...viewState }, [
      'id',
      'landingPageId',
      'emailTemplateId',
      'name',
      'subject',
      'customSender',
      'senderPrefix',
      'senderDomain',
      'senderName',
      'senderEmail',
      'content',
      'legacy',
      'link',
      'recipients',
      'sendToAll',
      'workingHours',
      'spread',
      'startDate',
      'courseId',
      'availableSenderDomains',
      'deliveryMethod'
    ])

    if (!this.isMessageInjectionConfigured) {
      data.deliveryMethod = 'smtp'
    }

    return data
  }

  setShowConfirm (showConfirm) {
    this.setState({ showConfirm })
  }

  async handleScheduleSubmit (data) {
    await this.updateState(data)
    if (!this.updating) {
      if (this.isComplete) {
        // Show confirmation modal if simulation is ready to send
        this.setShowConfirm(true)
      } else {
        message.error(I18n.t('incompleteFormError', trOpt))
      }
    }
  }

  get isScheduleComplete () {
    const { recipients, spread, startDate, sendToAll } = this.state
    return (sendToAll || (recipients && (recipients.learners.length > 0 || recipients.groups.length > 0))) &&
      typeof spread === 'number' &&
      startDate && moment(startDate).isSameOrAfter(moment())
  }

  get isComplete () {
    return this.isLandingComplete && this.isEmailComplete && this.isConfigureComplete && this.isScheduleComplete
  }

  get isMessageInjectionConfigured () {
    return isMessageInjectionAuthorised({ messageInjection: this.props.messageInjection })
  }

  renderCurrentStepView () {
    const { step, landingPageId, emailTemplateId, showForm, contentValid, contentLocales } = this.state
    let view

    switch (step) {
      case 'landing':
        view = showForm
          ? (
            <LandingPageForm
              ref={this.templateForm}
              showCancel embedded
              onSuccess={this.handleLandingPageCreateSuccess}
              onCancel={this.handleLandingPageCreateCancel}
            />
          ) : (
            <CreateSimulationLandingPages
              landingPageId={landingPageId}
              contentLocales={contentLocales}
              setContentLocales={this.setContentLocales}
              defaultContentLocales={this.props.contentLocales}
              update={this.updateLandingPage} goToNextStep={this.goToEmail}
            />
          )
        break
      case 'email':
        view = showForm
          ? (
            <EmailTemplateForm
              ref={this.templateForm}
              showCancel embedded
              landingPageId={landingPageId}
              onSuccess={this.handleEmailTemplateCreateSuccess}
              onCancel={this.handleEmailTemplateCreateCancel}
            />
          ) : (
            <CreateSimulationEmailTemplates
              landingPageId={landingPageId} emailTemplateId={emailTemplateId}
              contentLocales={contentLocales}
              setContentLocales={this.setContentLocales}
              defaultContentLocales={this.props.contentLocales}
              update={this.updateEmailTemplate} updateSource={this.useEmailTemplateSource} goToNextStep={this.goToConfigure}
            />
          )
        break
      case 'configure':
        view = (
          <>
            <h4>{I18n.t('configureYourSimulation', trOpt)}</h4>
            <CreateSimulationConfigure
              {...{ emailTemplateId, contentValid }}
              {..._pick(this.state, ['name', 'subject', 'customSender', 'senderPrefix', 'senderDomain', 'senderName', 'senderEmail', 'content', 'legacy', 'link', 'availableSenderDomains', 'deliveryMethod'])}
              tldAllowList={this.props.tldAllowList}
              uPhishDeliveryMethod={this.props.uPhishDeliveryMethod}
              isMessageInjectionConfigured={this.isMessageInjectionConfigured}
              ref={this.view}
              updating={this.updating}
              getSimulationData={this.getSimulationData}
              onChange={this.handleFormFieldChange}
              onSubmit={this.handleConfigureSubmit}
            />
          </>
        )
        break
      case 'schedule':
        view = (
          <>
            <h4>{I18n.t('schedule', trOpt)}</h4>
            <CreateSimulationSchedule
              {..._pick(this.state, ['recipients', 'workingHours', 'spread', 'startDate', 'courseSubject', 'courseId', 'sendToAll'])}
              ref={this.view}
              updating={this.updating}
              onChange={this.handleFormFieldChange}
              onSubmit={this.handleScheduleSubmit}
            />
          </>
        )
        break
      default:
        view = null
        break
    }

    return view
  }

  async startSimulationUpdate () {
    await this.updateState(this.props.simulation)
    const { emailTemplateId, legacy, content } = this.state
    if (emailTemplateId === 'freeform') {
      const contentValue = legacy ? Value.fromJSON(content) : content
      await this.updateContentValid(contentValue)
    }

    const { initialStep } = this.props
    const stepIndex = initialStep ? this.steps.indexOf(initialStep) : -1
    if (initialStep && stepIndex !== -1) {
      await this.updateState({ step: initialStep, stepHistory: this.steps.slice(0, stepIndex + 1) })
    }
  }

  async waitForTemplateForm () {
    return new Promise((resolve, reject) => {
      const start = Date.now()
      let rendered = false
      const wait = setInterval(() => {
        const timedOut = (Date.now() - start) > 5000
        rendered = !_isNil(_get(this, 'templateForm.current'))
        if (rendered || timedOut) {
          clearInterval(wait)
          if (rendered) {
            resolve()
          } else {
            reject(new Error(I18n.t('templateFormHasNotRendered', trOpt)))
          }
        }
      }, 50)
    })
  }

  async componentDidMount () {
    if (this.updating) {
      return this.startSimulationUpdate()
    }

    let previousCreateSimulation
    try {
      const previousCreateSimulationString = localStorage.getItem(this.storageId)
      if (previousCreateSimulationString) {
        previousCreateSimulation = JSON.parse(previousCreateSimulationString)
      }
    } catch (e) {}

    if (previousCreateSimulation) {
      // Set state using previous creation attempt
      await this.updateState(previousCreateSimulation)
      const { step, showForm } = previousCreateSimulation
      const templateInProgress = showForm && (step === 'landing' || step === 'email')
      if (templateInProgress) {
        try {
          await this.waitForTemplateForm()
          const { current: templateForm } = this.templateForm
          if (templateForm) {
            await templateForm.load()
          }
        } catch (e) {
          message.error(I18n.t(step === 'landing' ? 'customLandingPageError' : 'customEmailTemplateError', trOpt))
        }
      }
      const { sourceSimulationId } = previousCreateSimulation
      let content
      if (this.cloning && sourceSimulationId && sourceSimulationId !== this.props.simulationId) {
        content = I18n.t('previousCloneDifferentSimulationMessage', trOpt)
      } else if (this.creating && sourceSimulationId) {
        content = I18n.t('previousCloneSimulationMessage', trOpt)
      } else if (this.cloning && !sourceSimulationId) {
        content = I18n.t('previousCreateSimulationMessage', trOpt)
      }

      Modal.confirm({
        title: I18n.t('common.resumeConfirmDialog.title'),
        content,
        okText: I18n.t('common.resumeConfirmDialog.ok'),
        cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
        onCancel: async () => {
          await this.resetState()
          if (templateInProgress) {
            const { current: templateForm } = this.templateForm
            if (templateForm) {
              await templateForm.reset()
            }
          }
        }
      })
    } else if (this.cloning) {
      return this.startSimulationUpdate()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { emailTemplateId } = this.state
    const { emailTemplateId: prevEmailTemplateId } = prevState

    if (emailTemplateId !== prevEmailTemplateId) {
      let contentValid = true
      if (emailTemplateId === 'freeform') {
        contentValid = this.isContentValid()
      }
      this.updateState({ contentValid })
    }
  }

  hasStepBeenVisited (step) {
    return this.state.stepHistory.includes(step)
  }

  render () {
    const { title = I18n.t('uPhish.common.createSimulation') } = this.props
    const { step, showForm, loading } = this.state
    const disableSteps = showForm && (step === 'email' || step === 'landing')

    return (
      <>
        <LoadingBlock fullScreen loading={loading} />
        <Card>
          <CreateSimulationContent>
            <h1 id={this.id}>{I18n.t('common.uPhish')} - {title}</h1>
            {this.renderCurrentStepView()}
          </CreateSimulationContent>
        </Card>
        <CreateSimulationFooter>
          <StyledSteps current={this.stepIndex} onChange={this.handleStepChange}>
            <Step title={I18n.t('chooseYourTheme', trOpt)} disabled={disableSteps} status={step !== 'landing' && (this.updating || this.hasStepBeenVisited('landing')) && !this.isLandingComplete ? 'error' : undefined} />
            <Step title={I18n.t('chooseYourEmail', trOpt)} disabled={disableSteps} status={step !== 'email' && (this.updating || this.hasStepBeenVisited('email')) && !this.isEmailComplete ? 'error' : undefined} />
            <Step title={I18n.t('configure', trOpt)} disabled={disableSteps} status={step !== 'configure' && (this.updating || this.hasStepBeenVisited('configure')) && !this.isConfigureComplete ? 'error' : undefined} />
            <Step title={I18n.t('schedule', trOpt)} disabled={disableSteps} status={step !== 'schedule' && (this.updating || this.hasStepBeenVisited('schedule')) && !this.isScheduleComplete ? 'error' : undefined} />
          </StyledSteps>
          {
            this.updating
              ? (
                <UpdateButtonWrap>
                  <UpdateSimulationMutateButton
                    disabled={!this.isComplete}
                    storageId={this.storageId}
                    simulation={this.getSimulationData()}
                  />
                  <Link to={routes.UPHISH_SIMS}>
                    <Button>{I18n.t('common.cancel')}</Button>
                  </Link>
                </UpdateButtonWrap>
              )
              : null
          }
        </CreateSimulationFooter>
        {
          !this.updating
            ? (
              <CreateSimulationConfirm
                storageId={this.storageId}
                simulation={this.getSimulationData()} visible={this.state.showConfirm}
                updateVisible={this.setShowConfirm}
              />
            )
            : null
        }
      </>
    )
  }
}

export default compose(
  connect(
    state => ({
      ..._pick(selectors.session.get(state), ['userId', 'companyId', 'contentLocales']),
      ..._pick(selectors.settings.get(state), ['tldAllowList', 'uPhishDeliveryMethod', 'messageInjection'])
    })
  ),
  withConsumer
)(CreateSimulation)
