import I18n from 'i18n-js'
import isoLanguages from '@cospired/i18n-iso-languages'
import isoCountries from 'i18n-iso-countries'
import _uniq from 'lodash/uniq'

import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../constants/languages'
import en from './en'
import es from './es'
import fr from './fr'
import cs from './cs'
import zh from './zh'
import de from './de'
import nl from './nl'
import it from './it'
import us from './us'
import pt from './pt'
import sv from './sv'
import no from './no'

// Import moment localisations
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/fr-ca'
import 'moment/locale/cs'
import 'moment/locale/zh-cn'
import 'moment/locale/de'
import 'moment/locale/nl'
import 'moment/locale/it'
import 'moment/locale/en-au'
import 'moment/locale/da'
import 'moment/locale/fi'
import 'moment/locale/pt'
import 'moment/locale/sv'
import 'moment/locale/ro'
import 'moment/locale/pl'
import 'moment/locale/fr-ch'
import 'moment/locale/de-ch'
import 'moment/locale/en-nz'
import 'moment/locale/en-ie'

import { setProductNames } from '../helpers/locale'

// Set default moment locale
moment.locale('en-gb')

I18n.defaultLocale = DEFAULT_LANGUAGE
I18n.locale = DEFAULT_LANGUAGE
I18n.fallbacks = true

I18n.translations = {
  en,
  es,
  fr,
  cs,
  zh,
  de,
  nl,
  it,
  us,
  pt,
  sv,
  no
}

// Chinese Pluralisation - use "other" for any count value
I18n.pluralization.zh = function () {
  return ['other']
}

setProductNames() // Set default product names

// Show the key instead of the copy
I18n.locales.debugKeys = []
I18n.missingTranslation = function (scope, options) {
  // guess intended string
  if (this.missingBehaviour === 'guess') {
    // get only the last portion of the scope
    var s = scope.split('.').slice(-1)[0]
    // replace underscore with space && camelcase with space and lowercase letter
    return (this.missingTranslationPrefix.length > 0 ? this.missingTranslationPrefix : '') +
        s.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g,
          function (match, p1, p2) { return p1 + ' ' + p2.toLowerCase() })
  }

  var fullScope = this.getFullScope(scope, options)
  var localeForTranslation = (options != null && options.locale != null) ? options.locale : this.currentLocale()
  // usecure - Return for key if using debugKeys locale
  if (localeForTranslation === 'debugKeys') {
    return `[${fullScope}]`
  }

  var fullScopeWithLocale = [localeForTranslation, fullScope].join(options.separator || this.defaultSeparator)
  return '[missing "' + fullScopeWithLocale + '" translation]'
}

I18n.tWithProductNames = (scope, options) => {
  const productNameOpt = { scope: 'common', locale: options?.locale }
  return I18n.t(scope, {
    ...options,
    uLearnProductName: I18n.t('uLearn', productNameOpt),
    uPhishProductName: I18n.t('uPhish', productNameOpt),
    uBreachProductName: I18n.t('uBreach', productNameOpt),
    uBreachProProductName: I18n.t('uBreachPro', productNameOpt),
    uPolicyProductName: I18n.t('uPolicy', productNameOpt),
    uServiceProductName: I18n.t('uService', productNameOpt)
  })
}

// Load language and country name translations for App Languages
_uniq(
  LANGUAGE_CODES
    .reduce((acc, locale) => {
      if (locale === 'us') {
        locale = 'en-US'
      }
      acc.push(locale.substr(0, 2))
      return acc
    }, [])
)
  .forEach(locale => {
    try {
      isoLanguages.registerLocale(require(`@cospired/i18n-iso-languages/langs/${locale}.json`))
    } catch (e) {
      console.error(`Error loading language name translation for ${locale}`, e)
    }
    try {
      isoCountries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`))
    } catch (e) {
      console.error(`Error loading country name translation for ${locale}`, e)
    }
  })
