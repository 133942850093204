import I18n from 'i18n-js'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'

import { disableActions } from './listPages'

const trOpt = { scope: 'learners.learnersView' }

export const getLearnerActions = ({ learner, uLearnEnabled = true, uPhishEnabled = true, uPolicyEnabled = true, policy, allowedActions, disabledActions }) => {
  const { attachedToSync = false } = learner || {}
  const actions = [
    { key: 'editLearner', label: I18n.t('common.editUser') },
    { key: 'deleteLearners', label: I18n.t('deleteUser', trOpt) },
    { key: 'markLearnersActive', label: I18n.t('markAsActive', trOpt) },
    { key: 'markLearnersInactive', label: I18n.t('markAsInactive', trOpt) },
    { key: 'restoreUsers', label: I18n.t('restoreUser', trOpt) }
  ]
  if (uLearnEnabled) {
    actions.push({
      key: 'addLearnersToGroup', label: I18n.t('addUsersToGroup', trOpt)
    }, {
      key: 'enrollOnCourse', label: I18n.t('enrollOnCourse', trOpt)
    }, {
      key: 'enrollOnGapAnalysis', label: I18n.t('enrollOnGapAnalysis', trOpt)
    }, {
      key: 'unenrolFromCourse', label: I18n.t('unenrolFromCourse', trOpt)
    }, {
      key: 'unenrolGapAnalysis', label: I18n.t('unenrolGapAnalysis', trOpt)
    }, {
      key: 'exportCourseData', label: I18n.t('exportCourseData', trOpt)
    })
  }
  if (uPolicyEnabled) {
    actions.push({ key: 'sendPolicy', label: I18n.t('uPolicy.common.sendPolicy') })
    if (policy) {
      actions.push({ key: 'signPolicy', label: I18n.t('markPolicyAsSigned', trOpt) })
      if (['visited', 'pending'].includes(learner.policyStatus)) { // This corresponds to a row for policy result with learner data
        actions.push({ key: 'removeLearnersFromPolicy', label: I18n.t('removeUserFromPolicy', trOpt) })
      }
    }
    actions.push({ key: 'exportPolicyData', label: I18n.t('common.exportPolicyData') })
  }
  if (uPhishEnabled) {
    actions.push({ key: 'exportSimulationData', label: I18n.t('exportSimulationData', trOpt) })
  }

  if (attachedToSync) {
    actions.push({ key: 'detachLearnersFromSync', label: I18n.t('detachLearnersFromSync', trOpt) })
  }

  const filteredActions = filterLearnerActions(actions, allowedActions)
  return disableActions(filteredActions, disabledActions)
}

export const filterLearnerActions = (actions, allowedActions) => {
  if (_isArray(allowedActions) && !_isEmpty(allowedActions)) {
    return actions.filter(action => allowedActions.includes(action.key))
  }
  // restoreUsers is only allowed if explicitly asked for
  return actions.filter(action => action.key !== 'restoreUsers')
}
