/* global window */
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { Badge, Card, Icon, Select, Table, Tooltip } from 'antd'
import _get from 'lodash/get'
import styled, { withTheme } from 'styled-components'
import { lighten } from 'polished'
import queryString from 'query-string'
import I18n from 'i18n-js'
import { useApolloClient } from '@apollo/react-hooks'

import { COURSE_PARTICIPATION_COURSES, COURSE_PARTICIPATION_LEARNER_COUNT, COURSE_PARTICIPATION_LEARNERS } from '../../components/Queries/Reports'
import { GET_GROUP_TREE } from '../../components/Queries/Groups'
import { FontAwesomeIcon, LoadingBlock, ErrorAlerts } from '../../components/common'
import { getThemeColorForPercentage, downloadReports } from '../../helpers'
import { ReportDownloadDropdown } from '../../components/Reports'
import { freezeTableHeader } from '../../hocs'
import { COURSE_DIFFICULTY, COURSE_SUBJECTS } from '../../constants/courses'
import IntercomHeader from '../../components/IntercomHeader'

const trOpt = { scope: 'reports.courseParticipation' }
const { Option } = Select

const DOWNLOAD_REPORTS = [
  { file: 'outstanding.xlsx', endpoint: '/course-participation/outstanding.xlsx', get label () { return I18n.t('downloadOutstandingCourses', trOpt) } },
  { file: 'participation-grid.xlsx', endpoint: '/course-participation/participation-grid.xlsx', get label () { return I18n.t('downloadParticipationGrid', trOpt) } }
]

const SUBJECT_NAMES = ['InfoSec', 'Video', 'Compliance', 'Custom']

const downloadReportsOnMount = () => {
  const { download } = queryString.parse(window.location.search)
  if (download === 'true') {
    downloadReports(DOWNLOAD_REPORTS)
  }
}

const mapParticipationToGroups = (groups, courses, learners, isExport) => {
  return groups.map(group => {
    const { id, name } = group
    const groupLearners = learners.filter(learner => group.id === 'NO_GROUP' ? learner.groups.length === 0 : learner.groups.includes(group.id))
    const children = mapParticipationToGroups(group.children, courses, learners, isExport)
    const descendantCoursePartication = children.reduce((participationMap, child) => {
      Object.keys(child.courseParticipation).forEach(courseId => {
        const { overall } = child.courseParticipation[courseId]
        participationMap[courseId] = participationMap[courseId] || { enrolled: 0, finished: 0, totalScore: 0 }
        participationMap[courseId].enrolled += overall.enrolled
        participationMap[courseId].finished += overall.finished
        participationMap[courseId].totalScore += overall.totalScore
      })

      return participationMap
    }, {})

    const learnerCount = {
      self: groupLearners.length,
      descendants: children.reduce((learnerCount, child) => {
        return learnerCount + child.learnerCount.self + child.learnerCount.descendants
      }, 0)
    }
    learnerCount.overall = learnerCount.self + learnerCount.descendants

    return {
      id,
      name,
      learnerCount,
      courseParticipation: courses.reduce((participationMap, course) => {
        const { enrolled, finished, totalScore } = groupLearners.reduce((values, learner) => {
          const courseResult = learner.courseResults[course.id]
          if (courseResult) {
            values.enrolled += 1
            if (courseResult.finished) {
              values.finished += 1
              values.totalScore += courseResult.score || 0
            }
          }
          return values
        }, { enrolled: 0, finished: 0, totalScore: 0 })

        const averageScore = totalScore / finished
        const completion = enrolled > 0 ? ((finished / enrolled) * 100) : 0
        const self = {
          enrolled,
          finished,
          totalScore,
          averageScore,
          completion
        }

        const descendants = descendantCoursePartication[course.id] || { enrolled: 0, finished: 0, totalScore: 0 }

        const overall = {
          enrolled: self.enrolled + descendants.enrolled,
          finished: self.finished + descendants.finished,
          totalScore: self.totalScore + descendants.totalScore
        }
        overall.averageScore = overall.totalScore / overall.finished
        overall.completion = overall.enrolled > 0 ? ((overall.finished / overall.enrolled) * 100) : 0

        participationMap[course.id] = {
          self,
          descendants,
          overall
        }
        return participationMap
      }, {}),
      ...(isExport
        ? {
          gapAnalysisParticipation: generateGroupGapAnalysisParticipation(groupLearners, children),
          courseResultsEnrolled: calculateGroupCourseResultTotal('courseResultsEnrolled', groupLearners, children),
          courseResultsFinished: calculateGroupCourseResultTotal('courseResultsFinished', groupLearners, children)
        } : {}
      ),
      children
    }
  })
}

const calculateGroupCourseResultTotal = (valueId, groupLearners, children) => {
  const self = groupLearners.reduce((acc, { [valueId]: value = 0 }) => acc + value, 0)

  const descendants = children.reduce((acc, child) => {
    const { overall = 0 } = child[valueId] || {}
    return acc + overall
  }, 0)

  return {
    self,
    descendants,
    overall: self + descendants
  }
}

const generateGroupGapAnalysisParticipation = (groupLearners, children) => {
  const self = groupLearners.reduce((acc, learner) => {
    if (learner.gapAnalysisResult) {
      acc.enrolled += 1
      if (learner.gapAnalysisResult.finished) {
        acc.finished += 1
      }
    }
    return acc
  }, { enrolled: 0, finished: 0 })

  const descendants = children.reduce((participation, child) => {
    const { overall: { enrolled, finished } = {} } = child.gapAnalysisParticipation || {}
    participation.enrolled += enrolled
    participation.finished += finished

    return participation
  }, { enrolled: 0, finished: 0 })

  const overall = {
    enrolled: self.enrolled + descendants.enrolled,
    finished: self.finished + descendants.finished,
    totalScore: self.totalScore + descendants.totalScore
  }

  return {
    self,
    descendants,
    overall
  }
}

const LEARNER_PAGE_SIZE = 300

const loadCourseParticipationData = async ({ client, setData, setError, setLoading, setLoadingProgress }) => {
  try {
    setLoading(true)
    setLoadingProgress(0)
    const { data: { groupTree } } = await client.query({ query: GET_GROUP_TREE })
    setLoadingProgress(10)
    const { data: { courseParticipationCourses: courseData } } = await client.query({ query: COURSE_PARTICIPATION_COURSES })
    setLoadingProgress(20)
    const { data: { courseParticipationLearnerCount: learnerCount } } = await client.query({ query: COURSE_PARTICIPATION_LEARNER_COUNT })
    setLoadingProgress(30)

    const learners = []
    const pages = Math.ceil(learnerCount / LEARNER_PAGE_SIZE)
    for (let page = 0; page < pages; page += 1) {
      setLoadingProgress(30 + Math.round(((page + 1) / pages) * 70))
      const offset = page * LEARNER_PAGE_SIZE
      const { data: { courseParticipationLearners: learnersPage } } = await client.query({ query: COURSE_PARTICIPATION_LEARNERS, variables: { offset, limit: LEARNER_PAGE_SIZE } })
      learners.push(...learnersPage)
    }

    // This provides an overall participation figure across all learners
    // The v1 equivalent appears to use duplicate results if a learner appears in more than one group
    const overallParticipation = learners.reduce((overallParticipation, learner) => {
      Object.keys(learner.courseResults).forEach(courseId => {
        const { finished } = learner.courseResults[courseId]
        overallParticipation[courseId] = overallParticipation[courseId] || { enrolled: 0, finished: 0 }
        overallParticipation[courseId].enrolled += 1
        if (finished) {
          overallParticipation[courseId].finished += 1
        }
      })
      return overallParticipation
    }, {})

    const courses = courseData.map(course => {
      const { enrolled = 0, finished = 0 } = overallParticipation[course.id] || {}
      return {
        ...course,
        enrolled,
        finished,
        completion: enrolled > 0 ? ((finished / enrolled) * 100) : 0
      }
    })

    const groupTreeData = mapParticipationToGroups(
      [...groupTree, { id: 'NO_GROUP', name: I18n.t('noGroup', trOpt), children: [] }], // New array to avoid repeated NO_GROUP pushes
      courses, learners
    )

    setData({
      groupTree: groupTreeData,
      courses,
      learners
    })
  } catch (e) {
    setError(e)
  } finally {
    setLoadingProgress(100)
    setLoading(false)
  }
}

const FullHeightCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%
  }
`

const CourseParticipationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 40px);
`

// Dummy row used to set name width to avoid column width constantly changing when toggling groups
const addRowProps = record => {
  const { type } = record
  if (type === 'dummy') {
    return { className: 'usecure-dummy-table-row' }
  }
  if (['learner', 'group'].includes(type)) {
    let { depth } = record
    if (type === 'learner' && depth > 0) {
      depth -= 1
    }
    return { style: { backgroundColor: lighten(depth * 0.02, '#F7F7F7') } }
  }
  return {}
}

const generateRows = ({ displayMode, activeGroups, groups, courses, learners, sort }) => {
  if (displayMode === 'learners') {
    return generateLearnerRows({ courses, learners, sort })
  }

  return generateGroupRows({ activeGroups, groups, courses, learners, sort })
}

const rowSorter = (rows, sort) => {
  switch (sort) {
    case 'participationAsc':
    case 'participationDesc':
      rows.sort((a, b) => {
        let order = 0
        if (a.enrolled < b.enrolled) {
          order = -1
        } else if (a.enrolled > b.enrolled) {
          order = 1
        }
        return (sort === 'participationDesc' ? -1 : 1) * order
      })
      rows.sort((a, b) => {
        let order = 0
        if (a.overallParticipation < b.overallParticipation) {
          order = -1
        } else if (a.overallParticipation > b.overallParticipation) {
          order = 1
        }
        return (sort === 'participationDesc' ? -1 : 1) * order
      })
      break
    case 'nameAsc':
    case 'nameDesc':
    default:
      rows.sort((a, b) => (sort === 'nameDesc' ? -1 : 1) * a.name.localeCompare(b.name))
  }
}

const generateLearnerRows = ({ courses, learners, depth = 0, keyPrefix = '', sort }) => {
  const learnerRows = learners.map(learner => {
    const { courseResultMap, enrolled, finished } = courses.reduce(({ courseResultMap, finished, enrolled }, course) => {
      const courseResult = _get(learner, `courseResults[${course.id}]`, null)
      if (courseResult) {
        enrolled += 1
        if (courseResult.finished) {
          finished += 1
        }
      }
      return {
        enrolled,
        finished,
        courseResultMap: { ...courseResultMap, [`course_${course.id}`]: courseResult }
      }
    }, {
      enrolled: 0,
      finished: 0,
      courseResultMap: {}
    })

    return {
      key: `${keyPrefix}learner_${learner.id}`,
      id: learner.id,
      type: 'learner',
      name: learner.name,
      depth,
      ...courseResultMap,
      enrolled,
      finished,
      overallParticipation: enrolled > 0 ? (finished / enrolled) * 100 : 0
    }
  })

  rowSorter(learnerRows, sort)
  return learnerRows
}

const generateGroupRows = ({ activeGroups, groups, courses, learners, depth = 0, visible = true, sort }) => {
  const groupRows = groups.map(group => {
    const active = activeGroups[group.id]
    const { courseCompletionMap, enrolled, finished } = courses.reduce(({ courseCompletionMap, finished, enrolled }, course) => {
      const courseParticipation = _get(group, `courseParticipation[${course.id}].overall`, null)
      if (courseParticipation) {
        enrolled += courseParticipation.enrolled
        finished += courseParticipation.finished
      }
      return {
        enrolled,
        finished,
        courseCompletionMap: { ...courseCompletionMap, [`course_${course.id}`]: courseParticipation }
      }
    }, {
      enrolled: 0,
      finished: 0,
      courseCompletionMap: {}
    })

    return {
      key: `group_${group.id}`,
      id: group.id,
      type: 'group',
      name: group.name,
      depth,
      active,
      ...courseCompletionMap,
      enrolled,
      finished,
      overallParticipation: enrolled > 0 ? (finished / enrolled) * 100 : 0
    }
  })
  const groupChildrenMap = groups.reduce((map, group) => ({ ...map, [group.id]: group.children }), {})

  rowSorter(groupRows, sort)

  return groupRows.reduce((rows, groupRow) => {
    const { id } = groupRow
    const active = activeGroups[groupRow.id]
    if (depth === 0 || active || visible) {
      rows.push(groupRow)
      if (active) {
        // Add child groups
        rows.push(...generateGroupRows({ activeGroups, groups: groupChildrenMap[id], courses, learners, depth: depth + 1, visible: true, sort }))
        // Add Group Learners
        rows.push(...generateLearnerRows({ courses, learners: learners.filter(learner => id === 'NO_GROUP' ? learner.groups.length === 0 : learner.groups.includes(id)), depth: depth + 1, keyPrefix: `group_${id}_`, sort }))
      }
    }

    return rows
  }, [])
}

const IndentedGroupName = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-left: ${({ depth }) => depth * 10}px;
`
const GroupNameIcon = styled(Icon)`
  color: ${({ theme }) => theme.primary};
  font-weight: bold;
  margin-left: 8px;
`
const GroupName = ({ children, id, active, depth = 0, onClick = () => {} }) => {
  const onNameClick = () => onClick(id)
  return (
    <IndentedGroupName depth={depth} onClick={onNameClick}>
      <span>{children}</span>
      <GroupNameIcon type={active ? 'down' : 'right'} />
    </IndentedGroupName>
  )
}

const LearnerName = styled.div`
  margin-left: ${({ depth }) => depth * 10}px;
`

const CompletionPercentageSpan = styled.span`
  color: ${({ color = 'inherit' }) => color};
  display: block;
  text-align: center;
`
const CompletionPercentage = ({ value, theme }) => {
  value = Math.floor(value)
  return <CompletionPercentageSpan color={getThemeColorForPercentage(value, theme, true)}>{`${value}%`}</CompletionPercentageSpan>
}

const LearnerCourseResultContainer = styled.div`
  text-align: center;
`
const LearnerCourseResultGrade = styled.div`
  height: 21px;
  text-align: center;
`
const LearnerCourseResultLine = styled.div`
  background-color: ${({ finished, outstanding, theme }) => finished && !outstanding ? theme.primary : 'transparent'};
  border: 1px ${({ outstanding, finished, theme }) => `${finished && outstanding ? 'dashed' : 'solid'} ${theme.primary}`};
  height: 4px;
  margin: 0 auto;
  width: 25px;
`
const LearnerCourseResultTick = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.primary};
`
const LearnerCourseResultCircle = styled(FontAwesomeIcon)`
  color: #eaeaea;
`
const LearnerCourseResult = ({ subject, finished, outstanding, score }) => {
  return (
    <LearnerCourseResultContainer>
      {
        subject === 'Compliance'
          ? (
            finished
              ? <LearnerCourseResultTick icon='check' />
              : <LearnerCourseResultCircle icon='circle' />
          )
          : (
            <>
              <LearnerCourseResultGrade>{finished ? I18n.t('common.percentage', { value: score }) : ''}</LearnerCourseResultGrade>
              <LearnerCourseResultLine {...{ outstanding, finished }} />
            </>
          )
      }
    </LearnerCourseResultContainer>
  )
}

const GroupPercentageBadge = styled(Badge)`
  display: block;
  text-align: center;

  .ant-badge-status-dot {
    height: 10px;
    width: 10px;
  }

  .ant-badge-status-text {
    display: none;
  }
`

const getGroupDescendants = (groupId, groups, found = false) => {
  return groups.reduce((ids, group) => {
    if (found || group.id === groupId) {
      if (group.id !== groupId) {
        ids.push(group.id)
      }
      ids.push(...getGroupDescendants(groupId, group.children, true))
    }
    return ids
  }, [])
}

const CourseParticipationFilters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  &> * {
    margin-left: 10px;
  }
`

const CourseParticipationFilterSelect = styled(Select)`
  width: ${({ width }) => isNaN(width) ? 'auto' : `${width}px`};
`
const CourseParticipationFilter = ({ label, value, width, options = [], onChange = () => {} }) => {
  const onFilterChange = value => onChange(value)
  return (
    <div>
      <div>{label}</div>
      <CourseParticipationFilterSelect width={width} onChange={onFilterChange} value={value}>
        {options.map(option => {
          const value = typeof option === 'object' ? option.value : option
          const label = typeof option === 'object' ? option.label : option
          return (
            <Option key={value} value={value}>{label}</Option>
          )
        })}
      </CourseParticipationFilterSelect>
    </div>
  )
}

const CourseParticipationKeys = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  &> * {
    margin-left: 10px;
  }
`
const CourseParticipationKey = styled.div`
  align-items: center; 
  display: flex;

  span {
    margin-left: 10px;
  }
`

const CourseParticipationFooter = styled.div`
  background-color: #ffffff;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  width: 100%;
`

const StyledCourseParticipationTable = styled(Table)`
  .usecure-dummy-table-row {
    visibility: hidden;

    td {
      height: 0;
      margin: 0;
      padding: 0;

      ${IndentedGroupName} {
        height: 0;
        line-height: 0;
      }
    }
  }
`

const CourseParticipationTable = freezeTableHeader()(StyledCourseParticipationTable)

const CourseParticipation = ({ theme, data }) => {
  useEffect(() => {
    downloadReportsOnMount()
  }, [])

  const { groupTree = [], courses = [], learners = [] } = data || {}

  const hasGroups = !(groupTree.length === 0 || (groupTree.length === 1 && groupTree[0].id === 'NO_GROUP'))
  const [subject, updateSubject] = useState('InfoSec')
  const [difficulty, updateDifficulty] = useState(2)
  const [displayMode, updateDisplayMode] = useState(hasGroups ? 'groups' : 'learners')
  const [activeGroups, updateActiveGroups] = useState({})
  const [sort, updateSort] = useState('nameAsc')
  const table = useRef(null)

  const updateTableHeaderCellWidths = useCallback(() => {
    const updateHeaderCellWidths = _get(table, 'current.updateHeaderCellWidthsWithDelay')
    if (updateHeaderCellWidths) {
      updateHeaderCellWidths()
    }
  }, [table])

  const onGroupNameClick = useCallback(id => {
    if (activeGroups[id]) {
      delete activeGroups[id]
      const groupIds = [
        id,
        ...getGroupDescendants(id, groupTree)
      ]
      groupIds.forEach(groupId => delete activeGroups[groupId])
    } else {
      activeGroups[id] = true
    }
    updateActiveGroups({ ...activeGroups })
    updateTableHeaderCellWidths()
  }, [activeGroups, updateActiveGroups, groupTree, updateTableHeaderCellWidths])

  const renderName = useCallback((value, record) => {
    const { type, active, depth, id } = record
    let cell
    switch (type) {
      case 'group':
        cell = (
          <GroupName
            onClick={onGroupNameClick}
            {...{ id, active, depth }}
          >
            {value === 'No Group' ? I18n.t('noGroup', trOpt) : value}
          </GroupName>
        )
        break
      case 'learner':
        cell = <LearnerName depth={depth}>{value}</LearnerName>
        break
      case 'completion':
        cell = <span>{value}</span>
        break
      case 'dummy':
        cell = (
          <GroupName {...{ id, active, depth }}>{value}</GroupName>
        )
        break
      default:
        cell = null
    }
    return cell
  }, [onGroupNameClick])

  const renderCourseCell = useCallback((value, record) => {
    const { type } = record
    let cell
    switch (type) {
      case 'completion':
        cell = <CompletionPercentage {...{ value, theme }} />
        break
      case 'group':
        const { finished, enrolled, averageScore, completion = 0 } = value || {} // eslint-disable-line no-case-declarations
        if (enrolled > 0) {
          cell = (
            <Tooltip title={
              <div>
                <div>{I18n.t(enrolled === 0 ? 'peopleEnrolledZero' : enrolled === 1 ? 'peopleEnrolledOne' : 'peopleEnrolledOther', { ...trOpt, count: finished, enrolledCount: enrolled })}</div>
                {averageScore ? <div>{I18n.t('averageScoreTooltip', { ...trOpt, averageScore: Math.round(averageScore) })}</div> : null}
              </div>
            }
            >
              <GroupPercentageBadge color={getThemeColorForPercentage(completion, theme, true)} />
            </Tooltip>
          )
        } else {
          cell = null
        }
        break
      case 'learner':
        if (value) {
          cell = <LearnerCourseResult subject={subject} {...value} />
        }
        break
      default:
        cell = null
    }

    return cell
  }, [theme, subject])

  const renderOverallParticipation = useCallback((value, record) => {
    if (['completion', 'group', 'learner'].includes(record.type)) {
      return <CompletionPercentage {...{ value, theme }} />
    }

    return null
  }, [theme])

  const selectedCourses = useMemo(() => courses.filter(course => course.subject === subject && (subject !== 'InfoSec' || course.difficulty === difficulty)), [courses, subject, difficulty])
  const columns = useMemo(
    () =>
      [
        {
          title: '',
          dataIndex: 'name',
          render: renderName,
          width: 200
        },
        {
          title: I18n.t('common.courseLabel'),
          dataIndex: 'courseHeading',
          width: 120
        },
        ...selectedCourses.map(course => ({
          title: (
            <Tooltip title={course.name}>
              <div style={{ minWidth: 10, minHeight: 10 }}>
                <FontAwesomeIcon icon={course.icon || 'question-circle'} />
              </div>
            </Tooltip>
          ),
          dataIndex: `course_${course.id}`,
          render: renderCourseCell,
          align: 'center'
        })),
        {
          title: '%',
          dataIndex: 'overallParticipation',
          render: renderOverallParticipation,
          align: 'center'
        }
      ],
    [selectedCourses, renderName, renderCourseCell, renderOverallParticipation]
  )

  const courseCompletionMap = useMemo(() => selectedCourses.reduce((completionMap, course) => ({ ...completionMap, [`course_${course.id}`]: course.completion }), {}), [selectedCourses])
  const overallParticipation = useMemo(() => selectedCourses.length > 0 ? selectedCourses.reduce((total, course) => total + course.completion, 0) / selectedCourses.length : 0, [selectedCourses])

  const groupAndLearnerRows = useMemo(
    () => generateRows({ displayMode, activeGroups, groups: groupTree, courses: selectedCourses, learners, sort }),
    [displayMode, activeGroups, groupTree, selectedCourses, learners, sort]
  )

  const rows = useMemo(
    () => [
      {
        key: 'completion',
        type: 'completion',
        name: displayMode === 'groups' ? I18n.t('common.groups') : I18n.t('common.users'),
        courseHeading: I18n.t('completion', trOpt),
        ...courseCompletionMap,
        overallParticipation
      },
      ...groupAndLearnerRows
    ],
    [groupAndLearnerRows, overallParticipation, courseCompletionMap, displayMode]
  )

  useEffect(() => updateTableHeaderCellWidths(), [rows, updateTableHeaderCellWidths])

  const subjects = useMemo(() =>
    SUBJECT_NAMES
      .filter(subject => courses.some(course => course.subject === subject))
      .map(value => ({ value, label: COURSE_SUBJECTS[value] })),
  [courses])
  const difficultyOptions = useMemo(
    () =>
      [1, 2, 3, 4].filter(difficulty => courses.some(course => course.difficulty === difficulty))
        .map(value => ({ value, label: COURSE_DIFFICULTY[value] })),
    [courses])
  const isCompliance = subject === 'Compliance'

  return (
    <FullHeightCard>
      <IntercomHeader Size='h1' id='reports-course-participation-header'>{I18n.t('common.uLearn')} - {I18n.t('reports.courseParticipation.title')}</IntercomHeader>
      <CourseParticipationContainer>
        <div>
          <CourseParticipationFilters>
            <CourseParticipationFilter
              width={120}
              value={subject} label={I18n.t('common.fields.subject')} onChange={updateSubject}
              options={subjects}
            />
            {
              subject === 'InfoSec'
                ? (
                  <CourseParticipationFilter
                    width={125}
                    value={difficulty} label={I18n.t('courseLevel', trOpt)} onChange={updateDifficulty}
                    options={difficultyOptions}
                  />
                )
                : null
            }
            {
              hasGroups
                ? (
                  <CourseParticipationFilter
                    width={105}
                    value={displayMode} label={I18n.t('displayMode', trOpt)} onChange={updateDisplayMode}
                    options={[
                      { value: 'groups', label: I18n.t('common.groups') },
                      { value: 'learners', label: I18n.t('common.users') }
                    ]}
                  />
                )
                : null
            }
            <CourseParticipationFilter
              width={160}
              value={sort} label={I18n.t('sortBy', trOpt)} onChange={updateSort}
              options={
                ['nameDesc', 'nameAsc', 'participationDesc', 'participationAsc']
                  .map(value => ({ value, label: I18n.t(value, trOpt) }))
              }
            />
          </CourseParticipationFilters>
          <CourseParticipationKeys>
            <CourseParticipationKey>
              {
                isCompliance
                  ? <LearnerCourseResultCircle icon='circle' />
                  : <LearnerCourseResultLine />
              }
              <span>{I18n.t('personHasBeenEnrolled', trOpt)}</span>
            </CourseParticipationKey>
            {
              !isCompliance && (
                <CourseParticipationKey>
                  <LearnerCourseResultLine finished outstanding />
                  <span>{I18n.t('personHasBeenReEnolled', trOpt)}</span>
                </CourseParticipationKey>
              )
            }
            <CourseParticipationKey>
              {
                isCompliance
                  ? <LearnerCourseResultTick icon='check' />
                  : <LearnerCourseResultLine finished />
              }
              <span>{I18n.t('personHasCompleted', trOpt)}</span>
            </CourseParticipationKey>
          </CourseParticipationKeys>
        </div>
        <CourseParticipationTable
          ref={table} columns={columns} dataSource={rows} pagination={false}
          onRow={addRowProps}
        />
        <CourseParticipationFooter>
          <ReportDownloadDropdown reports={DOWNLOAD_REPORTS} icon='up' placement='topLeft' />
        </CourseParticipationFooter>
      </CourseParticipationContainer>
    </FullHeightCard>
  )
}

const CourseParticipationWrapper = ({ theme, data, error, loading, loadingProgress }) => (
  !loading && !error
    ? <CourseParticipation {...{ theme, data, error, loading }} />
    : (
      <FullHeightCard>
        <h1>{I18n.t('reports.courseParticipation.title')}</h1>
        {error && <ErrorAlerts {...{ error }} defaultError={I18n.t('reports.loadError')} />}
        <LoadingBlock fullScreen {...{ theme, loading }} showProgress progress={loadingProgress} />
      </FullHeightCard>
    )
)

const CourseParticipationLoader = ({ theme }) => {
  const client = useApolloClient()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)

  useEffect(() => {
    if (client) {
      loadCourseParticipationData({ client, setData, setError, setLoading, setLoadingProgress })
    }
  }, [client])
  return <CourseParticipationWrapper {...{ theme, data, error, loading, loadingProgress }} />
}

export default withTheme(CourseParticipationLoader)
