import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import _isArray from 'lodash/isArray'
import _isNil from 'lodash/isNil'

import MutationFormErrors from './MutationFormErrors'

const { Option } = Select

class MutationFormSelect extends Component {
  constructor (props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (value) {
    this.props.onChange(this.props.id, value)
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, type = 'select', label, required, placeholder, linkField, showSearch, extra, formItemStyle, allowClear, errors = [], className, sortOptions = false, maxTagTextLength } = this.props
    const isMultiSelect = type === 'multiSelect'
    const mode = isMultiSelect ? 'multiple' : 'default'
    const showErrors = errors.length > 0
    let { value, options, linkFieldValue } = this.props
    let { disabled } = this.props

    if (isMultiSelect && !(value && _isArray(value))) {
      // Set value to empty array on multiselect unless value is an array
      value = []
    } else if (_isNil(value)) {
      // antd Select placeholder only shows when value is undefined
      value = undefined
    }

    if (linkField) {
      if (linkFieldValue) {
        linkFieldValue = _isArray(linkFieldValue) ? linkFieldValue : [linkFieldValue]
        if (!linkFieldValue.includes('{all}')) {
          options = options.filter(option => {
            if (_isArray(option.linkFieldValue)) {
              return linkFieldValue.some(linkValue => option.linkFieldValue.includes(linkValue))
            }
            return linkFieldValue.includes(option.linkFieldValue)
          })
        }
      } else if (!disabled) {
        disabled = true
      }
    }

    return (
      <Form.Item
        label={label} extra={extra} className={className} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <Select
          name={id}
          onChange={this.handleChange}
          optionFilterProp='label'
          {...{ value, required, mode, placeholder, disabled, showSearch, allowClear, maxTagTextLength }}
        >
          {
            (
              sortOptions
                ? [...options].sort((a, b) => a.label.localeCompare(b.label))
                : options
            )
              .map((option, index) => (
                <Option key={index} value={option.value} disabled={option.disabled} label={option.label}>{option.label}</Option>
              ))
          }
        </Select>
      </Form.Item>
    )
  }
}

MutationFormSelect.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  linkField: PropTypes.string,
  linkFieldValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]))
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]))
  ]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string
  })),
  showSearch: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node, PropTypes.func]),
  sortOptions: PropTypes.bool,
  maxTagTextLength: PropTypes.number
}

MutationFormSelect.defaultProps = {
  id: '',
  type: 'select',
  label: null,
  required: false,
  linkField: null,
  linkFieldValue: null,
  value: '',
  onChange: () => { },
  options: [],
  showSearch: true,
  extra: null,
  sortOptions: false
}

export default MutationFormSelect
