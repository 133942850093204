import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Form, Input } from 'antd'
import I18n from 'i18n-js'

import DragAndDropList from './DragAndDropList'
import EditCourseMultipleChoiceAnswer from './EditCourseMultipleChoiceAnswer'
import { DEFAULT_LANGUAGE } from '../../constants/languages'

const { TextArea } = Input

const trOpt = { scope: 'editCourse.editCourseMultipleChoice' }

class EditCourseMultipleChoice extends Component {
  constructor (props) {
    super(props)

    this.handleMultipleChange = this.handleMultipleChange.bind(this)
    this.handleQuestionChange = this.handleQuestionChange.bind(this)
    this.updateAnswers = this.updateAnswers.bind(this)

    this.answerTemplate = {
      id: null,
      text: '',
      correct: false,
      feedback: ''
    }
  }

  handleMultipleChange (event) {
    this.updateContent({ allowMultipleAnswers: event.target.checked })
  }

  handleQuestionChange (event) {
    const question = event.target.value
    const title = question && question.length > 252 ? `${question.substr(0, 252)}...` : question
    this.props.updateTitle(title)
    this.updateContent({ question })
  }

  updateContent (update) {
    this.props.updateContent({ ...this.props.content, ...update })
  }

  updateAnswers (answers) {
    this.updateContent({ answers })
  }

  render () {
    const { content, updateInProgress } = this.props
    const { question, answers, allowMultipleAnswers } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('question', trOpt)}>
          <TextArea
            onChange={this.handleQuestionChange}
            value={question}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={this.handleMultipleChange}
            checked={allowMultipleAnswers}
          >{I18n.t('allowMultipleAnswers', trOpt)}
          </Checkbox>
        </Form.Item>
        <DragAndDropList
          list={answers || []}
          title={I18n.t('answers', trOpt)}
          itemName={I18n.t('answer', trOpt)}
          itemComponent={EditCourseMultipleChoiceAnswer}
          itemTemplate={this.answerTemplate}
          updateList={this.updateAnswers}
          updateInProgress={updateInProgress}
        />
      </div>
    )
  }
}

EditCourseMultipleChoice.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseMultipleChoice.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseMultipleChoice
