import I18n from 'i18n-js'

import _isNumber from 'lodash/isNumber'

import { numberColumnSorter, textColumnSorter } from './listPages'
import {
  transformCardData,
  transformAreaChartData,
  transformBarChartData,
  transformPieChartData,
  transformLineChartData,
  transformTableChartData,
  getTitleTextWithPeriod
} from './reports'

export const getPerformanceReportSections = ({
  performanceReport, riskScoreConfig,
  reportSections: reportSectionsArg = ['execSummary', 'users', 'uBreach', 'uPhish', 'uLearn', 'uPolicy'],
  periodType: periodTypeArg, trOpt
}) => {
  const {
    visibility: { riskScoreEnabled, uBreachEnabled, uLearnEnabled, uPhishEnabled, uPhishReportsEnabled, uPolicyEnabled },
    granularity,
    periodType: reportPeriodType
  } = performanceReport
  const periodType = periodTypeArg || reportPeriodType || 'custom'
  // Report section visibility determined by both visibility data and the reportSections argument provided to this function
  const reportSections = []
  if (reportSectionsArg.includes('execSummary') && (riskScoreEnabled || uBreachEnabled || uLearnEnabled || uPhishEnabled || uPolicyEnabled)) {
    reportSections.push('execSummary')
  }
  if (reportSectionsArg.includes('users')) {
    reportSections.push('users')
  }
  if (reportSectionsArg.includes('uBreach') && uBreachEnabled) {
    reportSections.push('uBreach')
  }
  if (reportSectionsArg.includes('uPhish') && uPhishEnabled) {
    reportSections.push('uPhish')
  }
  if (reportSectionsArg.includes('uLearn') && uLearnEnabled) {
    reportSections.push('uLearn')
  }
  if (reportSectionsArg.includes('uPolicy') && uPolicyEnabled) {
    reportSections.push('uPolicy')
  }

  return reportSections.map(reportType => {
    switch (reportType) {
      case 'execSummary': {
        const components = []
        if (riskScoreEnabled) {
          const cards = [{
            // Overall risk score at the end of the period
            title: I18n.t('execSummary.riskScore.cards.overall.title', trOpt),
            ...transformCardData({ ...performanceReport.overallRiskScore?.overall }),
            denominator: riskScoreConfig.maxRiskScore
          }]
          if (uBreachEnabled) {
            cards.push({
              // uBreach risk score at the end of the period
              title: I18n.t('execSummary.riskScore.cards.uBreach.title', trOpt),
              ...transformCardData({ ...performanceReport.uBreachRiskScore?.overall }),
              denominator: riskScoreConfig.maxBreachScore
            })
          }
          if (uPhishEnabled) {
            cards.push({
              // uPhish risk score at the end of the period
              title: I18n.t('execSummary.riskScore.cards.uPhish.title', trOpt),
              ...transformCardData({ ...performanceReport.uPhishRiskScore?.overall }),
              denominator: riskScoreConfig.maxPhishScore
            })
          }
          if (uLearnEnabled) {
            cards.push({
              // uLearn risk score at the end of the period
              title: I18n.t('execSummary.riskScore.cards.uLearn.title', trOpt),
              ...transformCardData({ ...performanceReport.uLearnRiskScore?.overall }),
              denominator: riskScoreConfig.maxULearnRiskScore
            })
          }

          components.push({
            title: I18n.t('execSummary.riskScore.title', trOpt),
            text: I18n.t('execSummary.riskScore.description', trOpt),
            graph: {
              // Overall risk score at the end of each period
              title: I18n.t('execSummary.riskScore.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.overallRiskScore, granularity }),
              options: { maxValue: riskScoreConfig.maxRiskScore, showAllAxisLeftTickValues: true }
            },
            cards
          })
        }
        if (uPhishEnabled) {
          components.push({
            title: I18n.t('execSummary.uPhish.title', trOpt),
            text: I18n.t('execSummary.uPhish.description', trOpt),
            graph: {
              // Compromise rate, across all simulations, at the end of each period
              title: I18n.t('execSummary.uPhish.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uPhishCompromiseRate, granularity, isPercentage: true })
            },
            cards: [
              {
                // Compromise rate, across all simulations, at the end of the period
                title: I18n.t('execSummary.uPhish.cards.overallCompromiseRate.title', trOpt),
                ...transformCardData({ ...performanceReport.uPhishCompromiseRate?.overall, isPercentage: true })
              }
            ]
          })
        }
        if (uLearnEnabled) {
          components.push({
            title: I18n.t('execSummary.uLearn.title', trOpt),
            text: I18n.t('execSummary.uLearn.description', trOpt),
            graph: {
              // Average score at the end of each period
              title: I18n.t('execSummary.uLearn.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uLearnAverageScore, granularity, isPercentage: true })
            },
            cards: [
              {
                // Average score at the end of the period
                title: I18n.t('execSummary.uLearn.cards.avgScore.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnAverageScore?.overall, isPercentage: true })
              },
              {
                // Overall completion rate at the end of the period
                title: I18n.t('execSummary.uLearn.cards.completionRate.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnCompletionRate?.overall, isPercentage: true })
              }
            ]
          })
        }
        if (uPolicyEnabled) {
          components.push({
            title: I18n.t('execSummary.uPolicy.title', trOpt),
            text: I18n.t('execSummary.uPolicy.description', trOpt),
            graph: {
              // % of policies that have been signed / total number of sent at end of each period
              title: I18n.t('execSummary.uPolicy.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uPolicySignedRequestRate, granularity, isPercentage: true })
            },
            cards: [
              {
                // No. policies that have been sent this period
                title: I18n.t('execSummary.uPolicy.cards.sent.title', trOpt),
                ...transformCardData({ ...performanceReport.uPolicySent.overall })
              },
              {
                // No. policies that have been signed this period
                title: I18n.t('execSummary.uPolicy.cards.signed.title', trOpt),
                ...transformCardData({ ...performanceReport.uPolicySigned.overall })
              },
              {
                // No. policies that have been created this period
                title: I18n.t('execSummary.uPolicy.cards.newPolicies.title', trOpt),
                ...transformCardData({ ...performanceReport.uPolicyNewPolicies.overall, withIcon: false, isNeutral: true })
              },
              {
                // No. policies that have been updated this period
                title: I18n.t('execSummary.uPolicy.cards.updatedPolicies.title', trOpt),
                ...transformCardData({ ...performanceReport.uPolicyUpdatedPolicies.overall, withIcon: false, isNeutral: true })
              }
            ]
          })
        }
        if (uBreachEnabled) {
          components.push({
            title: I18n.t('execSummary.uBreach.title', trOpt),
            text: I18n.t('execSummary.uBreach.description', trOpt),
            graph: {
              // New breaches during each period
              title: I18n.t('execSummary.uBreach.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uBreachBreachesNew, granularity })
            },
            cards: [
              {
                // No. of new breaches this period
                title: I18n.t('execSummary.uBreach.cards.newBreaches.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachBreachesNew?.overall })
              },
              {
                // Total breaches at end of period
                title: I18n.t('execSummary.uBreach.cards.totalBreaches.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachBreaches?.overall })
              }
            ]
          })
        }
        return {
          header: I18n.t('execSummary.title', trOpt),
          components
        }
      }
      case 'uBreach': {
        const components = [
          {
            title: I18n.t('uBreach.breaches.title', trOpt),
            text: I18n.t('uBreach.breaches.description', trOpt),
            graph: {
              // Total breaches at end of each period
              title: I18n.t('uBreach.breaches.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uBreachBreaches, granularity })
            },
            cards: [
              {
                // Total breaches at end of period
                title: I18n.t('uBreach.breaches.cards.total.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachBreaches?.overall })
              },
              {
                // No. of new breaches this period
                title: I18n.t('uBreach.breaches.cards.new.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachBreachesNew?.overall })
              }
            ]
          },
          {
            title: I18n.t('uBreach.learnersBreached.title', trOpt),
            text: I18n.t('uBreach.learnersBreached.description', trOpt),
            graph: {
              // Total no. of learners that have been breached at the end each period
              title: I18n.t('uBreach.learnersBreached.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uBreachBreachedLearners, granularity })
            },
            cards: [
              {
                // Total no. of learners that have been breached at the end this period
                title: I18n.t('uBreach.learnersBreached.cards.total.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachBreachedLearners?.overall })
              }
            ]
          }
        ]
        if (riskScoreEnabled) {
          components.unshift({
            title: I18n.t('uBreach.riskScore.title', trOpt),
            text: I18n.t('uBreach.riskScore.description', trOpt),
            graph: {
              // uBreach risk score at the end of each period
              title: I18n.t('uBreach.riskScore.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uBreachRiskScore, granularity }),
              options: { maxValue: riskScoreConfig.maxBreachScore, showAllAxisLeftTickValues: true }
            },
            cards: [
              {
                // uBreach risk score at the end of the period
                title: I18n.t('uBreach.riskScore.cards.overall.title', trOpt),
                ...transformCardData({ ...performanceReport.uBreachRiskScore?.overall }),
                denominator: riskScoreConfig.maxBreachScore
              }
            ]
          })
        }
        return {
          header: I18n.t('uBreach.title', trOpt),
          components
        }
      }
      case 'uPhish': {
        const components = [
          {
            title: I18n.t('uPhish.simulations.title', trOpt),
            text: I18n.t('uPhish.simulations.description', trOpt),
            graph: {
              // Simulations with duing the period
              title: getTitleTextWithPeriod({ periodType, key: 'uPhish.simulations.graph.title', trOpt }),
              ...transformBarChartData({ data: performanceReport.uPhishSimulations.values, keys: ['sent', 'opens', 'visits', 'compromises'], trOpt: { scope: 'reports.performanceReport.uPhish.simulations.graph' } })
            },
            cards: [
              {
                // No. emails sent in period
                title: getTitleTextWithPeriod({ periodType, key: 'uPhish.simulations.cards.sent.title', trOpt }),
                ...transformCardData({ ...performanceReport.uPhishSentNew?.overall, withIcon: true })
              },
              {
                title: getTitleTextWithPeriod({ periodType, key: 'uPhish.simulations.cards.opens.title', trOpt }),
                ...transformCardData({ ...performanceReport.uPhishOpensNew?.overall, withIcon: true })
              },
              {
                title: getTitleTextWithPeriod({ periodType, key: 'uPhish.simulations.cards.visits.title', trOpt }),
                ...transformCardData({ ...performanceReport.uPhishVisitsNew?.overall, withIcon: true })
              },
              {
                // Overall compromise rate simulations shown in the graph
                // CL - This card was previous the compromise rate within this period.
                // The calculate was compromisesNew / sentNew which is based on activity this period
                // However to make sense the statistic should be "emails sent this month that were compromised" / "emails sent this month"
                // Unfortunately we can't calculate that from the ETL data as there's no way to track an individual email with using the raw simulationResult
                title: getTitleTextWithPeriod({ periodType, key: 'uPhish.simulations.cards.compromisesNew.title', trOpt }),
                ...transformCardData({ ...performanceReport.uPhishCompromisesNew?.overall, withIcon: true })
              }
            ]
          }
        ]
        if (riskScoreEnabled) {
          components.unshift({
            title: I18n.t('uPhish.riskScore.title', trOpt),
            text: I18n.t('uPhish.riskScore.description', trOpt),
            graph: {
              // Uphish risk score at the end of each period
              title: I18n.t('uPhish.riskScore.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uPhishRiskScore, granularity }),
              options: { maxValue: riskScoreConfig.maxPhishScore, showAllAxisLeftTickValues: true }
            },
            cards: [
              {
                // Uphish risk score at the end of the period
                title: I18n.t('uPhish.riskScore.cards.overall.title', trOpt),
                ...transformCardData({ ...performanceReport.uPhishRiskScore?.overall }),
                denominator: riskScoreConfig.maxPhishScore
              }
            ]
          })
        }
        if (uPhishReportsEnabled) {
          components.push({
            title: I18n.t('uPhish.reports.title', trOpt),
            text: I18n.t('uPhish.reports.description', trOpt),
            graph: {
              // No. of new reports in each period
              title: getTitleTextWithPeriod({ periodType, key: 'uPhish.reports.graph.title', trOpt }),
              ...transformAreaChartData({ data: performanceReport.uPhishReportsNew, granularity })
            },
            cards: [
              {
                // No. of new reports in the period
                title: I18n.t('uPhish.reports.cards.newReports.title', trOpt),
                ...transformCardData({ ...performanceReport.uPhishReportsNew?.overall })
              }
            ]
          })
        }
        return {
          header: I18n.t('uPhish.title', trOpt),
          components
        }
      }
      case 'uLearn': {
        const components = [
          {
            title: I18n.t('uLearn.averageScore.title', trOpt),
            text: I18n.t('uLearn.averageScore.description', trOpt),
            graph: {
              // Average score across all courses at end of each period
              title: I18n.t('uLearn.averageScore.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uLearnAverageScore, granularity, isPercentage: true })
            },
            cards: [
              {
                // Average score across all courses at end of the period
                title: I18n.t('uLearn.averageScore.cards.overall.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnAverageScore?.overall, isPercentage: true })
              }
            ]
          },
          {
            title: I18n.t('uLearn.enrollments.title', trOpt),
            text: I18n.t('uLearn.enrollments.description', trOpt),
            graph: {
              // No. of completed courses in each period
              title: getTitleTextWithPeriod({ periodType, key: 'uLearn.enrollments.graph.title', trOpt }),
              ...transformAreaChartData({ data: performanceReport.uLearnCompletedNew, granularity })
            },
            cards: [
              {
                // No. of courses enrolled in the period
                title: getTitleTextWithPeriod({ periodType, key: 'uLearn.enrollments.cards.enrolledNew.title', trOpt }),
                ...transformCardData({ ...performanceReport.uLearnEnrolledNew?.overall })
              },
              {
                // No. of courses enrolled in total
                title: I18n.t('uLearn.enrollments.cards.enrolled.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnEnrolled?.overall })
              },
              {
                // No. of completed courses in the period
                title: getTitleTextWithPeriod({ periodType, key: 'uLearn.enrollments.cards.completedNew.title', trOpt }),
                ...transformCardData({ ...performanceReport.uLearnCompletedNew?.overall })
              },
              {
                // No. of completed courses in total
                title: I18n.t('uLearn.enrollments.cards.completed.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnCompleted?.overall })
              }
            ]
          },
          {
            title: I18n.t('uLearn.completionRate.title', trOpt),
            text: I18n.t('uLearn.completionRate.description', trOpt),
            graph: {
              // Completion rate across all courses at end of each period
              title: I18n.t('uLearn.completionRate.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uLearnCompletionRate, granularity, isPercentage: true })
            },
            cards: [
              {
                // Completion rate across all courses at end of the period
                title: I18n.t('uLearn.completionRate.cards.overall.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnCompletionRate?.overall, isPercentage: true })
              }
            ]
          }
        ]
        if (riskScoreEnabled) {
          components.unshift({
            title: I18n.t('uLearn.riskScore.title', trOpt),
            text: I18n.t('uLearn.riskScore.description', trOpt),
            graph: {
              // uLearn Risk Score at the end of each period
              title: I18n.t('uLearn.riskScore.graph.title', trOpt),
              ...transformAreaChartData({ data: performanceReport.uLearnRiskScore, granularity }),
              options: { maxValue: riskScoreConfig.maxULearnRiskScore, showAllAxisLeftTickValues: true }
            },
            cards: [
              {
                // uLearn Risk Score at the end of the period
                title: I18n.t('uLearn.riskScore.cards.overall.title', trOpt),
                ...transformCardData({ ...performanceReport.uLearnRiskScore?.overall }),
                denominator: riskScoreConfig.maxULearnRiskScore
              }
            ]
          })
        }
        return {
          header: I18n.t('uLearn.title', trOpt),
          components
        }
      }
      case 'users':
        return {
          header: I18n.t('users.title', trOpt),
          components: [
            {
              title: I18n.t('users.users.title', trOpt),
              text: I18n.t('users.users.description', trOpt),
              graph: {
                // Total users, removed users, and new users in each period
                title: I18n.t('users.users.graph.title', trOpt),
                ...transformLineChartData(
                  {
                    data: [
                      performanceReport.usersNew,
                      performanceReport.usersRemoved,
                      performanceReport.usersTotal
                    ],
                    granularity,
                    trOpt: { scope: 'reports.performanceReport.users.users.graph' }
                  }
                )
              },
              cards: [
                {
                  // Total users at end of the period
                  title: I18n.t('users.users.cards.totalUsers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersTotal?.overall, withIcon: false, isNeutral: true })
                },
                {
                  // New users in the period
                  title: I18n.t('users.users.cards.newUsers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersNew?.overall, withIcon: false, isNeutral: true })
                },
                {
                  // Removed users in the period
                  title: I18n.t('users.users.cards.removedUsers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersRemoved?.overall, withIcon: false, isNeutral: true })
                },
                {
                  // Total number of groups at end of the period
                  title: I18n.t('users.users.cards.totalGroups.title', trOpt),
                  ...transformCardData({ ...performanceReport.groupsTotal?.overall, withIcon: false, isNeutral: true })
                }
              ]
            },
            {
              title: I18n.t('users.managers.title', trOpt),
              text: I18n.t('users.managers.description', trOpt),
              graph: {
                // Groups with / without managers in period
                title: I18n.t('users.managers.graph.title', trOpt),
                ...transformPieChartData(
                  {
                    data: [
                      {
                        id: 'withoutManagers',
                        value: performanceReport.groupsTotal?.overall.value - performanceReport.groupsWithManager?.overall.value || 0
                      },
                      {
                        id: 'withManagers',
                        value: performanceReport.groupsWithManager?.overall.value || 0
                      }
                    ],
                    trOpt: { scope: 'reports.performanceReport.users.managers.graph' }
                  }
                )
              },
              cards: [
                {
                  // Total number of group managers at end of the period
                  title: I18n.t('users.managers.cards.groupManagers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersGroupManagers?.overall, withIcon: false, isNeutral: true })
                },
                {
                  // Total number of direct managers at end of the period
                  title: I18n.t('users.managers.cards.directManagers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersLearnerManagers?.overall, withIcon: false, isNeutral: true })
                }
              ]
            },
            {
              title: I18n.t('users.inactiveUsers.title', trOpt),
              text: I18n.t('users.inactiveUsers.description', trOpt),
              graph: {
                // Acvtive vs Inactive users in the period
                title: I18n.t('users.inactiveUsers.graph.title', trOpt),
                ...transformPieChartData(
                  {
                    data: [
                      {
                        id: 'usersActive',
                        value: performanceReport.usersActive?.overall.value || 0
                      },
                      {
                        id: 'usersInactive',
                        value: performanceReport.usersInactive?.overall.value || 0
                      }
                    ],
                    trOpt: { scope: 'reports.performanceReport.users.inactiveUsers.graph' }
                  }
                )
              },
              cards: [
                {
                  // Total number of active users at end of the period
                  title: I18n.t('users.inactiveUsers.cards.activeUsers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersActive?.overall, withIcon: false, isNeutral: true })
                },
                {
                  // Total number of inactive users at end of the period
                  title: I18n.t('users.inactiveUsers.cards.inactiveUsers.title', trOpt),
                  ...transformCardData({ ...performanceReport.usersInactive?.overall, withIcon: false, isNeutral: true })
                }
              ]
            }
          ]
        }
      case 'uPolicy':
        return {
          header: I18n.t('uPolicy.title', trOpt),
          components: [
            {
              title: I18n.t('uPolicy.outstanding.title', trOpt),
              text: I18n.t('uPolicy.outstanding.description', trOpt),
              graph: {
                // No. outstanding policies in each period
                title: I18n.t('uPolicy.outstanding.graph.title', trOpt),
                ...transformAreaChartData({ data: performanceReport.uPolicyOutstanding, granularity })
              },
              cards: [
                {
                  // No. outstanding policies at end of the period
                  title: I18n.t('uPolicy.outstanding.cards.overall.title', trOpt),
                  ...transformCardData({ ...performanceReport.uPolicyOutstanding?.overall })
                }
              ]
            },
            {
              title: I18n.t('uPolicy.policies.title', trOpt),
              text: I18n.t('uPolicy.policies.description', trOpt),
              useFixedColumnLayout: true,
              graph: {
                // All policies created since start of account
                ...transformTableChartData(
                  {
                    data: performanceReport.uPolicyPolicies.values,
                    columns: [
                      {
                        title: I18n.t('uPolicy.policies.graph.name', trOpt),
                        key: 'name',
                        dataIndex: 'label',
                        sorter: (a, b) => a.label.localeCompare(b.label)
                      },
                      {
                        title: I18n.t('uPolicy.policies.graph.signedRequestRate', trOpt),
                        key: 'signedRequestRate',
                        dataIndex: 'signedRequestRate',
                        render: (value) => _isNumber(value) ? I18n.t('common.percentage', { value }) : I18n.t('common.na'),
                        sorter: (a, b) => a.signedRequestRate - b.signedRequestRate
                      },
                      {
                        title: I18n.t('uPolicy.policies.graph.sent', trOpt),
                        key: 'sent',
                        dataIndex: 'sent',
                        sorter: (a, b) => a.sent - b.sent
                      },
                      {
                        title: I18n.t('uPolicy.policies.graph.signed', trOpt),
                        key: 'signed',
                        dataIndex: 'signed',
                        sorter: (a, b) => a.signed - b.signed
                      }
                    ],
                    trOpt: { scope: 'reports.performanceReport.uPolicy.policies.graph' },
                    // Apply default sort to data - signedRequestRate DESC, name ASC
                    sorter: (a, b) => {
                      const signedRateSortValue = -1 * numberColumnSorter('signedRequestRate', a, b)
                      return signedRateSortValue === 0 ? textColumnSorter('label', a, b) : signedRateSortValue
                    }
                  }
                )
              },
              cards: []
            }
          ]
        }
      default:
        return null
    }
  })
}
