import React from 'react'
import I18n from 'i18n-js'
import _round from 'lodash/round'
import { Icon, Modal, Alert, Progress, Divider, Tag, Select } from 'antd'
import GaugeChart from 'react-gauge-chart'
import { Link, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { RoundedCard, ContentHeader, Row, Column, ConditionalLayout } from './GridFlexiLayout'
import useTheme from '../../hooks/useTheme'
import routes from '../../constants/routes'
import { LineChart } from '../../components/Graphs'
import { RISK_SCORE_COLORS } from '../../constants/riskScore'
import { riskOverTimeDurations, getPhishRiskScoreRatio } from '../../helpers/endUser'
import { calculateRiskScorePercentage, RiskLevelStatus } from '../RiskScore'
import _isEmpty from 'lodash/isEmpty'
import _isArray from 'lodash/isArray'
import { DifficultyTag } from '../Courses/DifficultyTag'
import { formatDate } from '../../helpers/datetime'
import IntercomHeader from '../IntercomHeader'

const { Option } = Select
const trOpt = { scope: 'endUserPortal.dashboard' }
const InlineSelect = styled(Select)`
  margin-top: -6px;
`
const OutstandingItemWrapper = styled(RoundedCard)`
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-card-body {
    padding: 15px 24px !important
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
  }
  .ant-card-extra {
    margin-left: 20px;
  }
`
const OutstandingPolicyWrapper = styled(OutstandingItemWrapper)`
  .ant-card-body {
    display: none;
  }
`
const HeaderIcon = styled(Icon)`
  color: ${({ theme }) => theme.primary};
  font-size: '18px';
`
const StatsText = styled.h1`
margin-bottom: 0;
font-size: 36px;
line-height: 1.2;
`

const ShortDivider = styled(Divider)`
  margin: 10px 0;
`
const RiskScoreTooltipContainer = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  color: inherit;
  font-size: inherit;
  padding: 5px 9px;
`
const chipSpan = ({ color, ...rest }) => <span {...rest} />
const formatRiskScoreDate = value => formatDate(value, { includeDay: false })
const RiskScoreTooltipChip = styled(chipSpan)`
  background: ${({ color }) => color};
  display: inline-block;
  height: 12px;
  margin-right: 7px;
  width: 12px;
`
const RiskScoreTooltip = ({ point }) => {
  const { data: { color, level, x, y } } = point || {}
  return (
    <RiskScoreTooltipContainer>
      <RiskScoreTooltipChip color={color} />
      <span>{formatRiskScoreDate(x)} - {y} - {level}</span>
    </RiskScoreTooltipContainer>
  )
}
export const SectionHeader = ({ id = '', title, size = 'span', action = null, link = null, outline = null, trOpt = null, children, flex = 1 }) => {
  return (
    <ContentHeader flex={flex}>
      <IntercomHeader id={id} Size={size}>{trOpt ? I18n.t(title, trOpt) : I18n.t(title)}</IntercomHeader>
      <div>
        <Row>
          {children}
          <div>
            {action && <HeaderIcon type='info-circle' onClick={() => action()} />}
            {link && <Link to={link}><HeaderIcon type='right-circle' /></Link>}
            {outline && <small>{outline}</small>}
          </div>
        </Row>
      </div>
    </ContentHeader>
  )
}
export const showInfoModal = (title, infoMsg) => {
  Modal.info({
    title: title,
    icon: <Icon type='info-circle' />,
    content: infoMsg
  })
}

export const RiskScoreCard = ({ className, title, score, children, maxRiskScore, percent, outline = null, overlay = null, level = null, headerId = 'risk-score-header' }) => {
  const theme = useTheme()
  return (
    <RoundedCard className={className} flex={1}>
      {overlay && overlay}
      <div style={{ zIndex: 1 }}>
        <SectionHeader id={headerId} title={title} outline={outline} />
        <GaugeChart
          style={{ minWidtH: '100px', maxWidth: '250px', margin: 'auto' }}
          nrOfLevels={5}
          colors={[theme.riskScoreVeryLow, theme.riskScoreLow, theme.riskScoreMedium, theme.riskScoreHigh, theme.riskScoreVeryHigh]}
          arcWidth={0.3}
          animate
          percent={percent / 100}
          hideText
        />
        {(level !== null && level.level) && <h3 style={{ textAlign: 'center' }}>{level.status}</h3>}
        <h3 style={{ textAlign: 'center' }}>{`${score}/${maxRiskScore}`}</h3>
      </div>
      {children}
    </RoundedCard>
  )
}

export const ProgressCard = ({ locale = 'en-GB', percent, title, outline, trOpt, withTrOpt = true, link, footerVal, footer, strokeColour, companyId = null }) => {
  let preprocessPercentage = percent
  if (preprocessPercentage !== 0) preprocessPercentage = preprocessPercentage / 100
  let linkPath = link
  if (link !== null && companyId !== null) {
    linkPath = generatePath(link, { companyId })
  }

  return (
    <RoundedCard>
      <SectionHeader title={title} trOpt={withTrOpt ? trOpt : null} link={linkPath} />
      <br />
      <StatsText>{new Intl.NumberFormat(locale, { style: 'percent' }).format(preprocessPercentage)}</StatsText>
      <Progress percent={percent} strokeWidth={9} showInfo={false} strokeColor={strokeColour} />
      <small>{I18n.t(outline, trOpt)}</small>
      <>
        <ShortDivider />
        <small>{I18n.t(footer, { scope: trOpt.scope, val: footerVal })}</small>
      </>
    </RoundedCard>
  )
}
const calculatePercentage = (total, value) => {
  let percent = 0
  if (total !== 0 || value !== 0) {
    percent = _round((value / total) * 100)
  }
  return percent
}

const getStrokeColourFromPercentage = (percent, lowerTheBetter, theme) => {
  let strokeColour = theme.riskScoreVeryLow
  if (percent <= 25) {
    strokeColour = lowerTheBetter ? theme.riskScoreLow : theme.riskScoreHigh
  } else if (percent > 25 && percent < 75) {
    strokeColour = theme.riskScoreMedium
  } else if (percent >= 75) {
    strokeColour = lowerTheBetter ? theme.riskScoreHigh : theme.riskScoreLow
  }
  return strokeColour
}
export const getPercentAndStrokeColour = (total, value, lowerTheBetter, theme) => {
  const percent = calculatePercentage(total, value)
  const strokeColour = getStrokeColourFromPercentage(percent, lowerTheBetter, theme)
  return { percent, strokeColour }
}
export const AverageScoreProgress = ({ locale, courseResults, averageScore, theme, trOpt, linkRequired = true, companyId }) => {
  const strokeColour = getStrokeColourFromPercentage(averageScore, false, theme)
  return (
    <ProgressCard
      locale={locale}
      percent={averageScore || 0}
      strokeColour={strokeColour}
      trOpt={trOpt}
      title='averageScore'
      footerVal={courseResults.length}
      outline='averageScoreOutline'
      footer='totalCourses'
      link={linkRequired ? routes.PORTAL_LEARN : null}
      companyId={companyId}
    />
  )
}

export const PolicyProgress = ({ locale, policyResults, outstandingPolicies, theme, trOpt, link = null, companyId }) => {
  const numberCompletedCourses = policyResults.length - outstandingPolicies.length
  const { percent, strokeColour } = getPercentAndStrokeColour(policyResults.length, numberCompletedCourses, false, theme)
  return (
    <ProgressCard
      locale={locale}
      percent={percent}
      strokeColour={strokeColour}
      title='uPolicy.common.policies'
      trOpt={trOpt}
      withTrOpt={false}
      footerVal={policyResults.length}
      outline='policiesProgressBarOutline'
      footer='totalSent'
      link={link}
      companyId={companyId}
    />
  )
}

//  started at startDate is not null and finished is false
export const StartedCourse = ({ locale, courseResults, theme, trOpt }) => {
  const startedCourses = courseResults.filter((course) => course.status === 'started')
  const { percent, strokeColour } = getPercentAndStrokeColour(courseResults.length, startedCourses.length, false, theme)
  return (
    <ProgressCard
      locale={locale}
      percent={percent}
      strokeColour={strokeColour}
      title='startedCoursesTitle'
      trOpt={trOpt}
      withTrOpt
      footerVal={startedCourses.length}
      outline='startedCoursesOutline'
      footer='numberOfStartCourses'
    />
  )
}
export const CompletedCourses = ({ locale, courseResults, theme, trOpt }) => {
  const completedCourses = courseResults.filter((course) => course.finished === true)
  const { percent, strokeColour } = getPercentAndStrokeColour(courseResults.length, completedCourses.length, false, theme)
  return (
    <ProgressCard
      locale={locale}
      percent={percent}
      strokeColour={strokeColour}
      title='completedCoursesTitle'
      trOpt={trOpt}
      withTrOpt
      footerVal={completedCourses.length}
      outline='completedCoursesOutline'
      footer='numberOfCompletedCourses'
    />
  )
}

export const setStatusTagColour = (status, theme) => {
  switch (status) {
    case 'started':
      return theme.amber
    case 'opened':
      return theme.amber
    case 'completed':
      return theme.green
    case 'signed':
      return theme.green
    default:
      return theme.red
  }
}
export const TableTag = ({ status, color, trOpt }) => {
  return (
    <Tag color={color} key={status}>
      {I18n.t(`${status}`, trOpt)}
    </Tag>
  )
}

export const RiskOverTimeChart = ({ settings, riskScoreOverTime, durationKey, setDurationKey }) => {
  const theme = useTheme()
  const formatRiskScoreDate = value => formatDate(value, { includeDay: false })
  const ChangeDuration = (duration) => {
    setDurationKey(duration)
  }
  const buildRiskScoreInfoPopUpMessage = () => {
    const riskScoreTrOpt = { scope: 'endUserPortal.dashboard.riskScoreOverTimeMessage' }
    return (
      <>
        {I18n.t('initial', riskScoreTrOpt)}
        <ul>
          {settings.uPhish && <li>{I18n.t('uPhishInfo', riskScoreTrOpt)}</li>}
          {settings.uLearn && <li>{I18n.t('uLearnInfo', riskScoreTrOpt)}</li>}
          {settings.uBreachEnabled && <li>{I18n.t('uBreachInfo', riskScoreTrOpt)}</li>}
        </ul>
      </>
    )
  }
  if (riskScoreOverTime) {
    const durationTrOpt = { scope: 'endUserPortal.dashboard.duration' }
    const data = riskScoreOverTime[0].data
    const riskScoreOverTimeTrOpt = { scope: 'endUserPortal.dashboard', duration: I18n.t(durationKey, durationTrOpt) }
    return (
      <RoundedCard>
        <SectionHeader title='riskScoreOverTimeHeader' trOpt={riskScoreOverTimeTrOpt} action={() => showInfoModal(I18n.t('riskScoreOverTimeHeader', riskScoreOverTimeTrOpt), buildRiskScoreInfoPopUpMessage())}>
          <InlineSelect value={durationKey} onChange={ChangeDuration}>
            {riskOverTimeDurations.map((duration, index) => (
              <Option key={index} value={duration.id}>{I18n.t(duration.id, durationTrOpt)}</Option>
            ))}
          </InlineSelect>
        </SectionHeader>
        <div style={{ height: '30em', minWidth: 0 }}>
          <LineChart
            data={riskScoreOverTime}
            colors={[theme.copy]}
            margin={{
              top: 20,
              right: 20,
              bottom: 50,
              left: 50
            }}
            pointColor='white'
            pointBorderColor={p => theme[RISK_SCORE_COLORS[data[p.index].level]]}
            pointSize={8}
            pointBorderWidth={8}
            animate
            yScale={{ type: 'linear', stacked: false, min: 0, max: 900 }}
            tooltip={data => <RiskScoreTooltip {...data} />}
            enableArea={false}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 10,
              tickRotation: -45,
              legendPosition: 'middle',
              format: formatRiskScoreDate
            }}
            axisLeft={{
              tickPadding: 10
            }}
          />
        </div>
      </RoundedCard>
    )
  } else {
    return null
  }
}

const shortenListAndCount = (fullList) => {
  if (_isArray(fullList)) {
    let count = fullList.length
    let list = fullList
    if (count > 3) {
      count = 3
      list = list.slice(0, 3)
    }
    return { count, list }
  }
  return { count: 0, list: [] }
}

const trOptCourseTile = { scope: 'courses.courseTile' }
const OutstandingCourseCardExtra = ({ difficulty, link }) => {
  return (
    difficulty ? (
      <Row gap='5px'>
        <DifficultyTag difficulty={difficulty} gapAnalysisLabel={I18n.t('initialAssessment', trOptCourseTile)} customLabel={I18n.t('allLevels', trOptCourseTile)} />
        <Link to={link} target='_blank'>{I18n.t('learnerCourse.takeCourse')}</Link>
      </Row>
    ) : (
      <Link to={link} target='_blank'>{I18n.t('learnerCourse.takeCourse')}</Link>
    )
  )
}

export const OutstandingCourseCard = ({ course, learnerId, flex }) => {
  const { name, description, id, difficulty } = course
  const link = generatePath(routes.LEARNER_COURSE, { learnerId: learnerId, courseId: id })
  return (
    <OutstandingItemWrapper flex={flex} title={name} extra={<OutstandingCourseCardExtra difficulty={difficulty} link={link} />}>
      <p>{description}</p>
    </OutstandingItemWrapper>
  )
}
export const CourseCards = ({ outstandingCourses, learnerId, flex = 1 }) => {
  if (outstandingCourses && learnerId && !_isEmpty(outstandingCourses)) {
    return outstandingCourses.map((outstandingCourse, index) => <OutstandingCourseCard key={index} course={outstandingCourse.course} learnerId={learnerId} flex={flex} />)
  } else {
    return (
      <Alert
        type='info'
        showIcon
        message={I18n.t('emptyOutstandingCourses', trOpt)}
      />
    )
  }
}
export const OutstandingCourses = ({ outstandingCourses, learnerId, isRowBool }) => {
  const { count, list } = shortenListAndCount(outstandingCourses)
  const OutstandingTrOpt = { scope: 'endUserPortal.dashboard', count: count, total: outstandingCourses.length }
  return (
    <Column>
      <SectionHeader title='outstandingCourseTitle' trOpt={OutstandingTrOpt} icon='right-circle' action={() => showInfoModal(I18n.t('outstandingCourse', trOpt), I18n.t('outstandingCourseMessage', trOpt))} />
      <ConditionalLayout isRow={isRowBool} className='align' wrap='wrap' alignContent='flex-start'>
        <CourseCards {...{ outstandingCourses: list, learnerId }} />
      </ConditionalLayout>
    </Column>
  )
}

export const OutstandingPolicyCard = ({ policy, learnerId, flex }) => {
  const { name, id } = policy
  const link = generatePath(routes.LEARNER_POLICY, { learnerId: learnerId, policyId: id })
  return (
    <OutstandingPolicyWrapper
      flex={flex}
      title={name}
      extra={<Link to={link} target='_blank'>{I18n.t('uPolicy.learnerPolicy.reviewPolicyLink')}</Link>}
    />
  )
}
export const PolicyCards = ({ outstandingPolicies, learnerId, flex = 1 }) => {
  if (outstandingPolicies && learnerId && !_isEmpty(outstandingPolicies)) {
    return outstandingPolicies.map((outstandingPolicy, index) => <OutstandingPolicyCard key={index} policy={outstandingPolicy.policy} learnerId={learnerId} flex={flex} />)
  } else {
    return (
      <Alert
        type='info'
        showIcon
        message={I18n.t('emptyOutstandingPolicies', trOpt)}
      />
    )
  }
}
export const OutstandingPolicies = ({ outstandingPolicies, learnerId, isRowBool }) => {
  const trOpt = { scope: 'endUserPortal.dashboard' }
  const { count, list } = shortenListAndCount(outstandingPolicies)
  const OutstandingTrOpt = { scope: 'endUserPortal.dashboard', count: count, total: outstandingPolicies.length }
  return (
    <>
      <Column className='align' alignContent='flex-start'>
        <SectionHeader title='outstandingPolicyTitle' trOpt={OutstandingTrOpt} icon='right-circle' action={() => showInfoModal(I18n.t('outstandingPolicy', trOpt), I18n.t('outstandingPolicyMessage', trOpt))} />
        <ConditionalLayout isRow={isRowBool} className='align' wrap='wrap' alignContent='flex-start'>
          <PolicyCards {...{ outstandingPolicies: list, learnerId }} />
        </ConditionalLayout>
      </Column>
    </>
  )
}

export const PhishRiskScoreCard = ({ phishStats, riskScores, config }) => {
  if (riskScores && config) {
    const phishRisScoreCard = {
      headerId: 'uPhish-risk-score-header',
      title: 'common.phishRiskScore',
      score: riskScores.phishRiskScore || 0,
      maxRiskScore: config.maxPhishScore,
      percent: riskScores.level ? calculateRiskScorePercentage(getPhishRiskScoreRatio(riskScores.phishRiskScore, config.maxPhishScore, config.maxRiskScore), riskScores.level, config) : 0
    }
    return (
      <RiskScoreCard {...phishRisScoreCard}>
        <Divider />
        <small>{(phishStats && phishStats.total !== 0) ? `${I18n.t('uPhish.common.totalSentSimulations')} - ${phishStats.total}` : I18n.t('noSimulations', trOpt)}</small>
      </RiskScoreCard>
    )
  } else {
    return null
  }
}
export const TotalRiskScoreCard = ({ className, riskScores, config, overlay = null }) => {
  const theme = useTheme()
  if (riskScores && config && theme) {
    const riskScore = riskScores.riskScore || 0
    const totalRiskScore = {
      title: 'common.riskScore',
      score: riskScore,
      maxRiskScore: config.maxRiskScore,
      percent: riskScores.level ? calculateRiskScorePercentage(riskScores.riskScore || 0, riskScores.level, config) : 0,
      overlay,
      level: RiskLevelStatus({ riskScores, theme })
    }

    return (
      <RiskScoreCard className={className} {...totalRiskScore}>
        {riskScores.level && (
          <>
            <Divider style={{ marginBottom: '14px' }} />
            <small>{I18n.t('lastRiskScoreRecord', { ...trOpt, date: formatDate(riskScores.date) })}</small>
          </>
        )}
      </RiskScoreCard>
    )
  } else {
    return null
  }
}
