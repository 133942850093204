import React, { Component, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Card, Icon, Modal, Tooltip } from 'antd'
import _cloneDeep from 'lodash/cloneDeep'
import _isFunction from 'lodash/isFunction'
import _omit from 'lodash/omit'
import uuid from 'uuid/v4'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { FontAwesomeIcon } from '../common'
import { TRANSLATE_COURSE } from '../Queries/Courses'
import { showErrors } from '../../helpers'
import { LANGUAGES } from '../../constants/languages'

const DragHandle = styled.div`
    align-items: center;
    align-self: flex-start;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    width: 50px;
`

const DefaultItemComponent = ({ index, item }) => (
  <h3>Card #{index} - {item.id}</h3>
)

const dragAndDropListItemCard = ({ itemMargin, ...rest }) => (<Card {...rest} />)
const DragAndDropListItemCard = styled(dragAndDropListItemCard)`
  margin: ${({ itemMargin }) => itemMargin};
`

const DragAndDropListItem = ({ index, item, courseId, dragging, dragHandleProps, duplicate, showDuplicate, onEditClick: onEdit, onPreviewClick: onPreview, showPreview, showTranslate, update, remove, translate, itemComponent: ItemComponent = DefaultItemComponent, itemMargin, ...rest }) => {
  const { id } = item
  const [editing, setEditing] = useState(false)
  const onEditClick = useCallback(() => {
    if (_isFunction(onEdit)) {
      onEdit(item)
    } else {
      setEditing(true)
    }
  }, [setEditing, onEdit, item])
  const onTranslateClick = useCallback(() => translate({ slideId: id, courseId: courseId }), [translate, id, courseId])
  const onRemoveClick = useCallback(() => remove(id), [remove, id])
  const onDuplicateClick = useCallback(() => duplicate(id), [duplicate, id])
  const onPreviewClick = useCallback(() => {
    if (_isFunction(onPreview)) {
      onPreview(item)
    }
  }, [onPreview, item])
  const actions = [
    <Tooltip key='edit' title={I18n.t('common.edit')} placement='bottom'>
      <Icon type='edit' onClick={onEditClick} />
    </Tooltip>,
    <Tooltip key='delete' title={I18n.t('common.delete')} placement='bottom'>
      <Icon type='delete' onClick={onRemoveClick} />
    </Tooltip>
  ]

  if (showDuplicate) {
    actions.splice(1, 0,
      <Tooltip key='duplicate' title={I18n.t('common.duplicate')} placement='bottom'>
        <Icon type='copy' onClick={onDuplicateClick} />
      </Tooltip>
    )
  }

  if (showTranslate) {
    actions.splice(1, 0,
      <Tooltip key='translate' title={I18n.t('editCourse.common.translate')} placement='bottom'>
        <Icon type='rocket' onClick={onTranslateClick} />
      </Tooltip>
    )
  }

  if (showPreview && _isFunction(onPreview)) {
    actions.splice(1, 0,
      <Tooltip key='preview' title={I18n.t('common.preview')} placement='bottom'>
        <Icon type='eye' onClick={onPreviewClick} />
      </Tooltip>
    )
  }

  return (
    <DragAndDropListItemCard
      {...{ actions, itemMargin }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <ItemComponent {...{ index, item, editing, setEditing, dragging, dragHandleProps, update, ...rest }} />
        </div>
        <DragHandle {...dragHandleProps}>
          <FontAwesomeIcon icon='arrows-alt-v' />
        </DragHandle>
      </div>
    </DragAndDropListItemCard>
  )
}

const trOpt = { scope: 'editCourse.dragAndDropList' }

class DragAndDropList extends Component {
  constructor (props) {
    super(props)

    this.handleDragEnd = this.handleDragEnd.bind(this)
    this.handleAddListItem = this.handleAddListItem.bind(this)
    this.duplicateListItem = this.duplicateListItem.bind(this)
    this.updateListItem = this.updateListItem.bind(this)
    this.removeListItem = this.removeListItem.bind(this)
    this.translateOverride = this.translateOverride.bind(this)
  }

  get list () {
    return this.props.list || []
  }

  updateList (list) {
    this.props.updateList(list)
  }

  findListItemIndex (id) {
    return this.list.findIndex(item => item.id === id)
  }

  generateListItemId () {
    const list = this.list
    let id

    while (!id) {
      const possibleId = uuid()
      const alreadyUsed = list.some(item => item.id === possibleId)
      if (!alreadyUsed) {
        id = possibleId
      }
    }

    return id
  }

  updateListItem (item) {
    const list = this.list
    const index = this.findListItemIndex(item.id)
    if (index !== -1) {
      list[index] = item
      this.updateList(list)
    }
  }

  handleAddListItem () {
    const { itemTemplate } = this.props
    if (!itemTemplate) {
      return
    }

    const list = this.list
    const newItem = {
      ...(_cloneDeep(itemTemplate)),
      id: this.generateListItemId()
    }
    list.push(newItem)
    this.updateList(list)

    if (typeof this.props.onAdd === 'function') {
      this.props.onAdd(newItem)
    }
  }

  duplicateListItem (id) {
    const list = this.list
    const index = this.findListItemIndex(id)
    if (index !== -1) {
      const originalItem = list[index]
      const newItem = { ..._cloneDeep(originalItem), id: this.generateListItemId() }
      if (typeof this.props.modifyDuplicateItem === 'function') {
        this.props.modifyDuplicateItem(newItem, originalItem)
      }
      list.splice(index + 1, 0, newItem)
      this.updateList(list)
    }
  }

  translateOverride ({ slideId, courseId }) {
    Modal.confirm({
      title: I18n.t('areYouSureYouWantToTranslate', trOpt),
      content: I18n.t('thisActionWillQueueUpATranslation', trOpt),
      onOk: async () => {
        try {
          await authenticatedClient.mutate({
            mutation: TRANSLATE_COURSE,
            variables: {
              slideId,
              courseId,
              languages: LANGUAGES
            }
          })
        } catch (e) {
          showErrors(e, I18n.t('anErrorOccurredQueuingTheSlideTranslation', trOpt))
        }
      }
    })
  }

  removeListItem (id) {
    const list = this.list
    const index = this.findListItemIndex(id)
    if (index !== -1) {
      list.splice(index, 1)
      this.updateList(list)
    }
  }

  moveListItemByIndex (sourceIndex, targetIndex) {
    const list = this.list
    const [item] = list.splice(sourceIndex, 1)
    list.splice(targetIndex, 0, item)
    this.updateList(list)
  }

  handleDragEnd (result) {
    if (result.source && result.destination) {
      this.moveListItemByIndex(result.source.index, result.destination.index)
    }
  }

  showItemPreviewOption (item) {
    const { showPreview, showItemPreviewOption } = this.props
    return showPreview && (_isFunction(showItemPreviewOption) ? showItemPreviewOption(item) : true)
  }

  render () {
    const { title, showAdd = true, itemName, showDuplicate, itemComponent, droppableId = 'DragAndDropList', onEditClick, onPreviewClick, courseId } = this.props
    const restOfItemProps = _omit(this.props, ['title', 'list', 'updateList', 'onEditClick', 'onAdd', 'showAdd', 'showDuplicate', 'itemComponent', 'droppableId'])
    const headerJustifyContent = title && showAdd ? 'space-between' : (showAdd ? 'flex-end' : 'flex-start')

    return (
      <div>
        {
          title || showAdd
            ? (
              <div style={{ display: 'flex', justifyContent: headerJustifyContent }}>
                {title ? <h4>{title}</h4> : null}
                {showAdd ? <Button style={{ textTransform: 'capitalize' }} type='primary' onClick={this.handleAddListItem} icon='plus'>{I18n.t(itemName ? 'common.addItemName' : 'common.add', { name: itemName })}</Button> : null}
              </div>
            )
            : null
        }
        <div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <Droppable droppableId={droppableId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {
                    this.list.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={provided.draggableProps.style}
                          >
                            <DragAndDropListItem
                              {...restOfItemProps}
                              {...{ index, item, showDuplicate, itemComponent, onEditClick, courseId, onPreviewClick }}
                              showPreview={this.showItemPreviewOption(item)}
                              duplicate={this.duplicateListItem}
                              update={this.updateListItem}
                              remove={this.removeListItem}
                              translate={this.translateOverride}
                              dragging={snapshot.isDragging}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
}

DragAndDropList.propTypes = {
  itemMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

DragAndDropList.defaultProps = {
  itemMargin: '10px 0'
}

export default DragAndDropList
