import React, { useCallback } from 'react'
import { Dropdown, Button, Icon, Menu } from 'antd'

const CourseActionButton = ({ courseResult, action = {}, performAction = () => {} }) => {
  const onClick = useCallback(async () => performAction(action.key, courseResult), [action, courseResult, performAction])
  return (
    <Button onClick={onClick}>{action.label}</Button>
  )
}

const CourseActions = ({ courseResult, actions = [], performAction = () => {} }) => {
  const handleMenuClick = useCallback(async e => performAction(e.key, courseResult), [courseResult, performAction])
  if (actions.length === 0) return null
  if (actions.length === 1) {
    return <CourseActionButton disabled={Boolean(actions[0].disabled)} action={actions[0]} {...{ courseResult, performAction }} />
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      {
        actions.map(action => (
          <Menu.Item disabled={Boolean(action.disabled)} key={action.key}>{action.label}</Menu.Item>
        ))
      }

    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button>
        <Icon type='right' />
      </Button>
    </Dropdown>
  )
}

export default CourseActions
