import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

import MutationFormErrors from './MutationFormErrors'

const TextArea = Input.TextArea

class MutationFormTextArea extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hasReceivedFocus: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
  }

  handleChange (event) {
    const { name, value } = event.target
    this.props.onChange(name, value)
  }

  handleFocus () {
    this.setState({ hasReceivedFocus: true })
  }

  render () {
    if (!this.props.visible) {
      return null
    }

    const { id, type = 'textarea', label, required, placeholder, autofill, value, formItemStyle, textAreaStyle, errors = [], className } = this.props
    const showErrors = errors.length > 0

    // Prevent browser autofil of password
    const autoComplete = type === 'password' && !autofill ? 'new-password' : undefined
    const readOnly = !autofill ? !this.state.hasReceivedFocus : undefined

    return (
      <Form.Item
        className={className} label={label} style={formItemStyle} required={required}
        validateStatus={showErrors ? 'error' : undefined}
        help={showErrors ? <MutationFormErrors visible={showErrors} errors={errors} /> : null}
      >
        <TextArea
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          name={id}
          style={textAreaStyle}
          {...{ value, required, type, placeholder, autoComplete, readOnly }}
        />
      </Form.Item>
    )
  }
}

MutationFormTextArea.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  autofill: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
}

MutationFormTextArea.defaultProps = {
  id: '',
  type: 'text',
  label: null,
  required: false,
  placeholder: '',
  autofill: true,
  value: '',
  onChange: () => { }
}

export default MutationFormTextArea
