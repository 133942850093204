import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'

import routes, { matchRoute } from '../constants/routes'
import { isRiskReportAtStatus } from './company'

const isRouteAllowed = (session, settings, path, sessionType = 'admin') => {
  if (!(session.ready && settings.ready)) {
    return false
  }
  path = path && Array.isArray(path) ? path : [path]
  return path.some(p => _isRouteAllowed(session, settings, p, sessionType))
}

const ROLES = ['admin', 'usecure-admin']
const isRoleAllowed = (session, roleRequired) => {
  if (!roleRequired) return true
  if (_isArray(roleRequired)) {
    return roleRequired.includes(session.role)
  }
  return ROLES.indexOf(session.role) >= ROLES.indexOf(roleRequired)
}

const _isRouteAllowed = (session, settings, path, sessionType) => {
  const route = matchRoute(path, { exact: true })
  const { roleRequired, allowed: allowedFunct } = route || {}

  const hasUser = (sessionType === 'admin' && session.type === 'admin' && session.userId) ||
  (sessionType === 'endUser' && session.type === 'endUser' && session.learnerId)

  let allowed = Boolean(route && session && hasUser && isRoleAllowed(session, roleRequired))

  if (allowed && typeof allowedFunct === 'function') {
    allowed = allowedFunct({ session, settings })
  }
  if (allowed && session.partner && !settings.platformAccess) {
    allowed = !(_isBoolean(route.platformAccess) ? route.platformAccess : true)
  }
  if (allowed && path !== routes.HOLDING) {
    allowed = session.planValid || (!session.planValid && session.inDisguise)
  }
  if (allowed && session.accountType === 'prospect') {
    if (route.prospectStatus) {
      // Check prospect is at the route status or after allow the route
      allowed = isRiskReportAtStatus(session.prospectStatus, route.prospectStatus)
    } else {
      allowed = false
    }
  }
  return allowed
}

export default isRouteAllowed
