import I18n from 'i18n-js'

import EmailSettingsForm, { EmailSettingsConnect } from './EmailSettingsForm'

const trOpt = { scope: 'settings.googleSyncEmail' }

class GoogleSyncEmail extends EmailSettingsForm {
  constructor (props) {
    super(props)

    this.settingId = 'googleSyncReport'
    this.showSentTestButton = true
    this.generateSubjectFields([{ id: 'subject', label: 'common.fields.emailSubject' }])
  }

  get headerId () {
    return 'settings-google-sync-emails-header'
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('successMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('errorMessage', trOpt)
  }
}

export default EmailSettingsConnect(GoogleSyncEmail)
